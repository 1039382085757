import { insertNodes, Value } from '@udecode/plate-common';
import { ColoredTypography } from 'components/ColoredTypography';
import FlexContainer from 'components/FlexContainer';
import { useAiWriterExpandedSidebarStore } from 'features/aiWriter/AiWriterSidebar/sidebar/aiWriterExpandedSidebarStore';
import { AiWriterSidebarPanel } from 'features/aiWriter/AiWriterSidebar/sidebar/AiWriterSidebarPanel';
import { ExpandSidebarButton } from 'features/aiWriter/AiWriterSidebar/sidebar/ExpandSidebarButton';
import SeoAddAllButton from 'features/aiWriter/AiWriterSidebar/steps/seoAnalysis/common/SeoAddAllButton';
import { SeoSearchResult } from 'features/aiWriter/AiWriterSidebar/steps/seoAnalysis/common/SeoSearchResult/SeoSearchResult';
import useReturnToMainSeoStep from 'features/aiWriter/AiWriterSidebar/steps/seoAnalysis/useReturnToMainSeoStep';
import useEditor from 'features/aiWriter/AiWriterTextEditor/hooks/useEditor';
import { updateCurrentProjectInBackgroundThunk } from 'features/aiWriter/store/actions/project/thunks/updateCurrentProjectInBackground';
import { getAverageWordCount, getSeoAnalysisResults } from 'features/aiWriter/store/selectors';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import styled from 'styled-components';

export const SeoAnalysisSearchResultsSubStep = () => {
  const editor = useEditor();
  const dispatch = useAppDispatch();

  const isSidebarExpanded = useAiWriterExpandedSidebarStore(state => state.isSidebarExpanded);
  const setIsSidebarExpanded = useAiWriterExpandedSidebarStore(state => state.setIsSidebarExpanded);

  const seoAnalysisResults = useAppSelector(getSeoAnalysisResults);
  const averageWordCount = useAppSelector(getAverageWordCount);

  const returnToMainSeoStep = useReturnToMainSeoStep();

  const addAllToEditor = useCallback(() => {
    if (!editor) {
      return;
    }

    if (!seoAnalysisResults) {
      return;
    }

    const content = seoAnalysisResults.reduce(
      (prev, curr) => [
        ...prev,
        { type: 'h3', children: [{ text: curr.title }] },
        { type: 'paragraph', children: [{ text: curr.description }] }
      ],
      [] as Value
    );

    insertNodes(editor, content);

    dispatch(updateCurrentProjectInBackgroundThunk());
  }, [editor, seoAnalysisResults, dispatch]);

  if (!seoAnalysisResults) {
    return null;
  }

  return (
    <AiWriterSidebarPanel
      title="aiWriter.inspirations.seoAnalysis.title"
      onBack={returnToMainSeoStep}
      customButton={
        <ExpandSidebarButton
          isExpanded={isSidebarExpanded}
          onExpand={() => setIsSidebarExpanded(true)}
          onShrink={() => setIsSidebarExpanded(false)}
        />
      }
    >
      <FlexContainer gap="medium">
        <FlexContainer gap="small">
          <ColoredTypography variant="subtitle1" color="blackHighEmphasis">
            <FormattedMessage id="aiWriter.inspirations.seoAnalysis.search_results.competitors" />
          </ColoredTypography>
          <StatsContainer>
            <FlexContainer direction="row" justifyContent="space-between">
              <ColoredTypography color="blackHighEmphasis">
                <FormattedMessage id="aiWriter.inspirations.seoAnalysis.search_results.stats.average_words" />
              </ColoredTypography>
              <ColoredTypography color="blackHighEmphasis" fontWeight={500}>
                {averageWordCount}
              </ColoredTypography>
            </FlexContainer>
          </StatsContainer>
        </FlexContainer>
        <SeoAddAllButton onClick={addAllToEditor} />
        {seoAnalysisResults.map(result => (
          <SeoSearchResult key={result.link} result={result} />
        ))}
      </FlexContainer>
    </AiWriterSidebarPanel>
  );
};

const StatsContainer = styled.div`
  padding: ${({ theme }) => `${theme.spacings.small} ${theme.spacings.medium}`};

  background-color: ${({ theme }) => theme.colors.surfaceCard};
`;
