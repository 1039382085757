import { TranslateRounded } from '@mui/icons-material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { ReactComponent as CommandIcon } from 'assets/icons/icon-command.svg';
import { ReactComponent as ContinueWritingIcon } from 'assets/icons/icon-continue-writing.svg';
import { ReactComponent as ExpandIcon } from 'assets/icons/icon-expand.svg';
import { ReactComponent as ImproveIcon } from 'assets/icons/icon-improve.svg';
import { ReactComponent as SummarizeIcon } from 'assets/icons/icon-summarize.svg';
import useContinueWritingFromSelectionOrContextAction from 'features/aiWriter/AiWriterTextEditor/hooks/hotkey-actions/useContinueWritingFromSelectionOrContextAction';
import { CustomFlashActionsSubMenu } from 'features/aiWriter/slashCommands/CustomFlashActionsSubMenu';
import { SecondaryAwesomeIcon } from 'features/aiWriter/slashCommands/FlashActionsInput';
import { useGetEditorsNodeState } from 'features/aiWriter/slashCommands/hooks/useGetEditorsNodeState';
import useQuickActionInEditor from 'features/aiWriter/slashCommands/hooks/useQuickActionInEditor';
import { TranslateToSubMenu } from 'features/aiWriter/slashCommands/TranslateToSubMenu';
import { getCurrentModelLanguageAndCountry } from 'features/aiWriter/store/selectors';
import { AppLanguage } from 'features/language/store/types';
import { ReactNode } from 'react';
import { GAEvents } from 'services/tracking/GAEvents';
import { useAppSelector } from 'store/hooks';

export type SubMenuType = 'translate' | 'customActions';

export type FlashAction =
  | 'brainstormIdeas'
  | 'createOutline'
  | 'socialMediaPost'
  | 'continue'
  | 'customActions'
  | 'rewrite'
  | 'summarize'
  | 'duSieWechseln'
  | 'expand'
  | 'improve'
  | 'command'
  | 'translate';

export type Option = {
  index: number;
  name: FlashAction;
  label: string;
  /**
   * Input action are action that apply prompt
   * to flash action input
   */
  isInputAction?: boolean;
  /**
   * Immediate action are called right after menu click
   * to editor's document
   */

  isImmediateAction?: boolean;
  prompt?: string;
  isSubmenu?: boolean;
  icon: ReactNode;
  subMenuType?: SubMenuType;

  restrictedForLanguage?: AppLanguage;

  handler?: (text: string) => void;
};

export type Category = {
  name: string;
  label: string;
};

export function useGetDynamicBaseFlashActionOptions() {
  const handleGenerateTextStream = useQuickActionInEditor();
  const continueWritingAction = useContinueWritingFromSelectionOrContextAction();

  const { currentModelCountry: country, currentModelLanguage: language } = useAppSelector(
    getCurrentModelLanguageAndCountry
  );

  const emptyDocumentOptions = new Map<Category, Option[]>([
    [
      { name: 'createWithAi', label: 'aiWriter.flashActions_input.category.createWithAi' },
      [
        {
          index: 0,
          name: 'brainstormIdeas',
          label: 'aiWriter.flashActions_input.options.brainstormIdeas',
          icon: <SecondaryAwesomeIcon />,
          isInputAction: true,
          prompt: 'aiWriter.flashActions_input.prompt.brainstormIdeas',
          handler: (text: string) =>
            handleGenerateTextStream({ text, promptParam: 'brainstormIdeas' })
        },
        {
          index: 1,
          name: 'createOutline',
          label: 'aiWriter.flashActions_input.options.createOutline',
          icon: <SecondaryAwesomeIcon />,
          isInputAction: true,
          prompt: 'aiWriter.flashActions_input.prompt.createOutline',
          handler: (text: string) =>
            handleGenerateTextStream({ text, promptParam: 'createOutline' })
        },
        {
          index: 2,
          name: 'socialMediaPost',
          label: 'aiWriter.flashActions_input.options.socialMediaPost',
          icon: <SecondaryAwesomeIcon />,
          isInputAction: true,
          prompt: 'aiWriter.flashActions_input.prompt.socialMediaPost',
          handler: (text: string) =>
            handleGenerateTextStream({ text, promptParam: 'socialMediaPost' })
        }
      ]
    ]
  ]);

  const filledDocumentButEmptyNodeOptions = new Map<Category, Option[]>([
    [
      { name: 'writeWithAi', label: 'aiWriter.flashActions_input.category.writeWithAi' },
      [
        {
          index: 0,
          name: 'continue',
          label: 'aiWriter.flashActions_input.options.continue',
          icon: <ContinueWritingIcon />,
          isImmediateAction: true,
          handler: () => {
            GAEvents.flashActionExecuted({
              action: 'continue',
              language,
              country,
              isInline: true
            });
            continueWritingAction({ isHotKeyUsage: false });
          }
        },
        {
          index: 1,
          name: 'customActions',
          label: 'aiWriter.flashActions_input.options.customActions',
          icon: <MoreHorizIcon />,
          isSubmenu: true,
          subMenuType: 'customActions'
        }
      ]
    ],
    [
      { name: 'createWithAi', label: 'aiWriter.flashActions_input.category.createWithAi' },
      [
        {
          index: 2,
          name: 'brainstormIdeas',
          label: 'aiWriter.flashActions_input.options.brainstormIdeas',
          icon: <SecondaryAwesomeIcon />,
          isInputAction: true,
          prompt: 'aiWriter.flashActions_input.prompt.brainstormIdeas',
          handler: (text: string) =>
            handleGenerateTextStream({ text, promptParam: 'brainstormIdeas' })
        },
        {
          index: 3,
          name: 'createOutline',
          label: 'aiWriter.flashActions_input.options.createOutline',
          icon: <SecondaryAwesomeIcon />,
          isInputAction: true,
          prompt: 'aiWriter.flashActions_input.prompt.createOutline',
          handler: (text: string) =>
            handleGenerateTextStream({ text, promptParam: 'createOutline' })
        },
        {
          index: 4,
          name: 'socialMediaPost',
          label: 'aiWriter.flashActions_input.options.socialMediaPost',
          icon: <SecondaryAwesomeIcon />,
          isInputAction: true,
          prompt: 'aiWriter.flashActions_input.prompt.socialMediaPost',
          handler: (text: string) =>
            handleGenerateTextStream({ text, promptParam: 'socialMediaPost' })
        }
      ]
    ]
  ]);

  const filledNodeOptions = new Map<Category, Option[]>([
    [
      {
        name: 'writeWithAiOnSelection',
        label: 'aiWriter.flashActions_input.category.writeWithAiOnSelection'
      },
      [
        {
          index: 0,
          name: 'continue',
          label: 'aiWriter.flashActions_input.options.continue',
          isImmediateAction: true,
          icon: <ContinueWritingIcon />,
          handler: () => {
            GAEvents.flashActionExecuted({
              action: 'continue',
              language,
              country,
              isInline: true
            });
            continueWritingAction({ isHotKeyUsage: false });
          }
        },
        {
          index: 1,
          name: 'rewrite',
          label: 'aiWriter.flashActions_input.options.rewrite',
          icon: <SecondaryAwesomeIcon />
        },
        {
          index: 2,
          name: 'summarize',
          label: 'aiWriter.flashActions_input.options.summarize',
          icon: <SummarizeIcon />
        },
        {
          index: 3,
          name: 'expand',
          label: 'aiWriter.flashActions_input.options.expand',
          icon: <ExpandIcon />
        },
        {
          index: 4,
          name: 'improve',
          label: 'aiWriter.flashActions_input.options.improve',
          icon: <ImproveIcon />
        },
        {
          index: 5,
          name: 'command',
          label: 'aiWriter.flashActions_input.options.command',
          icon: <CommandIcon />
        },
        {
          index: 6,
          name: 'translate',
          label: 'aiWriter.flashActions_input.options.translate',
          icon: <TranslateRounded />,
          isSubmenu: true,
          subMenuType: 'translate'
        },
        {
          index: 7,
          name: 'customActions',
          label: 'aiWriter.flashActions_input.options.customActions',
          icon: <MoreHorizIcon />,
          isSubmenu: true,
          subMenuType: 'customActions'
        },
        {
          index: 8,
          name: 'duSieWechseln',
          label: 'Du/Sie wechseln',
          icon: <SecondaryAwesomeIcon />,
          restrictedForLanguage: AppLanguage.German
        }
      ]
    ]
  ]);

  const subMenuList: Array<{
    menuComponent: typeof CustomFlashActionsSubMenu | typeof TranslateToSubMenu;
    type: SubMenuType;
  }> = [
    {
      menuComponent: TranslateToSubMenu,
      type: 'translate'
    },
    {
      menuComponent: CustomFlashActionsSubMenu,
      type: 'customActions'
    }
  ];

  const { isEditorEmpty, isCurrentNodeEmptyAndDocumentFilled } = useGetEditorsNodeState();

  const options = isEditorEmpty
    ? emptyDocumentOptions
    : isCurrentNodeEmptyAndDocumentFilled
    ? filledDocumentButEmptyNodeOptions
    : filledNodeOptions;

  return { options, subMenuList };
}
