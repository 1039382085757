import Exploring from 'features/explorer';
import { useNavigateToOnboarding } from 'features/onboardingJourney/useNavigateToOnboarding';
import React from 'react';

const ExplorerPage = (): React.ReactElement => {
  useNavigateToOnboarding();

  return <Exploring />;
};

export default ExplorerPage;
