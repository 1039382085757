import { Button, Tooltip } from '@mui/material';
import LeftArrowIcon from 'assets/icons/icon-arrow-left.svg';
import { useSteps } from 'features/aiWriter/AiWriterBlogPostBuilder/hooks/useSteps';
import {
  SelectGoalStep,
  SelectLanguage,
  SelectOutlineStep,
  SelectTitleStep,
  TonalityStep
} from 'features/aiWriter/AiWriterBlogPostBuilder/steps';
import { ElementsStep } from 'features/aiWriter/AiWriterBlogPostBuilder/steps/ElementsStep';
import { GenerateContentStep } from 'features/aiWriter/AiWriterBlogPostBuilder/steps/GenerateContentStep';
import { BlogBuilderSteps, FormValues } from 'features/aiWriter/AiWriterBlogPostBuilder/types';
import { IntlMessageKeys } from 'features/i18n/IntlMessageKeys';
import { Form, useFormikContext } from 'formik';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import gtmIds from 'services/tracking/GTMIds';
import { useAppSelector } from 'store/hooks';
import { RootState } from 'store/store';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

type StepTranslations = {
  primaryAction: IntlMessageKeys;
  helpText?: IntlMessageKeys;
};

const translations: Record<BlogBuilderSteps, StepTranslations> = {
  [BlogBuilderSteps.Language]: {
    primaryAction: 'common.confirm'
  },
  [BlogBuilderSteps.Goal]: {
    primaryAction: 'common.continue'
  },
  [BlogBuilderSteps.Tonality]: {
    primaryAction: 'common.continue'
  },
  [BlogBuilderSteps.Elements]: {
    primaryAction: 'common.continue'
  },
  [BlogBuilderSteps.Title]: {
    primaryAction: 'common.continue',
    helpText: 'blog_post_builder.steps.helperText'
  },
  [BlogBuilderSteps.Outline]: {
    primaryAction: 'blog_post_builder.steps.outline.continueWithFullArticleButton',
    helpText: 'blog_post_builder.steps.outline.helperText'
  },
  [BlogBuilderSteps.GenerateText]: {
    primaryAction: 'blog_post_builder.steps.generate_content.okButton'
  }
};

const redirectAfterTextGenerationDelay = 4000;

type Props = {
  skipLanguageStep?: boolean;
};

export const AiWriterBlogPostBuilder = ({ skipLanguageStep }: Props) => {
  const tr = useTr();
  const { currentStep, isNextStepBtnDisabled, onPreviousStep, onPrimaryAction, goToStep } =
    useSteps(skipLanguageStep ? BlogBuilderSteps.Goal : BlogBuilderSteps.Language);

  const textGenerationFinished =
    currentStep === BlogBuilderSteps.GenerateText && !isNextStepBtnDisabled;

  useSyncWithInspirationPanel();
  useAutoTriggerAfterTimeout({
    shouldTrigger: textGenerationFinished,
    delay: redirectAfterTextGenerationDelay,
    action: onPrimaryAction
  });

  const stepTranslation = translations[currentStep];

  const handleGoToKeywords = useCallback(() => {
    goToStep(BlogBuilderSteps.Goal);
  }, [goToStep]);

  const renderedStep = useMemo(() => {
    switch (currentStep) {
      case BlogBuilderSteps.Language:
        return <SelectLanguage />;
      case BlogBuilderSteps.Goal:
        return <SelectGoalStep />;
      case BlogBuilderSteps.Tonality:
        return <TonalityStep />;
      case BlogBuilderSteps.Elements:
        return <ElementsStep onGoToKeywordsClick={handleGoToKeywords} />;
      case BlogBuilderSteps.Title:
        return <SelectTitleStep />;
      case BlogBuilderSteps.Outline:
        return <SelectOutlineStep />;
      case BlogBuilderSteps.GenerateText:
        return <GenerateContentStep isFinished={!isNextStepBtnDisabled} />;
    }
  }, [currentStep, handleGoToKeywords, isNextStepBtnDisabled]);

  return (
    <Form>
      <Steps>{renderedStep}</Steps>
      <Actions>
        <Tooltip title={tr('common.back')}>
          <Button type="button" color="primary" onClick={onPreviousStep}>
            <img src={LeftArrowIcon} />
          </Button>
        </Tooltip>

        <Button
          id={gtmIds.blogPostBuilder.primaryAction}
          type="submit"
          color="primary"
          variant="contained"
          disabled={isNextStepBtnDisabled}
          onClick={onPrimaryAction}
        >
          <FormattedMessage id={stepTranslation.primaryAction} />
        </Button>
      </Actions>
      <ButtonHelperText>
        {stepTranslation.helpText && tr(stepTranslation.helpText)}
      </ButtonHelperText>
    </Form>
  );
};

function useSyncWithInspirationPanel() {
  const { setFieldValue } = useFormikContext<FormValues>();
  const { title, outline } = useAppSelector((state: RootState) => state.aiWriter.builder.blogPost);

  useEffect(() => {
    setFieldValue('title', title);
  }, [title, setFieldValue]);

  useEffect(() => {
    setFieldValue('outline', outline);
  }, [outline, setFieldValue]);
}

function useAutoTriggerAfterTimeout({
  shouldTrigger,
  delay,
  action
}: {
  shouldTrigger: boolean;
  delay: number;
  action: () => void;
}) {
  const timeoutRef = useRef<number>();

  useEffect(() => {
    if (shouldTrigger && !timeoutRef.current) {
      timeoutRef.current = window.setTimeout(() => {
        action();
      }, delay);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = undefined;
      }
    };
  }, [shouldTrigger, action, delay]);
}

const Steps = styled.div`
  margin-bottom: ${({ theme }) => theme.spacings.large};
  margin-left: ${({ theme }) => theme.spacings.xxxlarge};
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacings.xmedium};
`;

const ButtonHelperText = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.caption};
  color: ${({ theme }) => theme.colors.blackDisabled};
  margin-left: ${({ theme }) => theme.spacings.xxxlarge};
  margin-bottom: ${({ theme }) => theme.spacings.large};
`;

export default AiWriterBlogPostBuilder;
