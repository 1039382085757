import VideoGuideModal from 'components/GuideVideoModal';
import {
  AddDimensionWithWordsModal,
  AddListWithWordsModal
} from 'components/layout/toolbar/modals/AddWithWordsModal';
import {
  AddWordsToDimension,
  AddWordsToList
} from 'components/layout/toolbar/modals/AddWordsModal';
import YouTubeVideoModal from 'components/layout/toolbar/modals/components/youTubeVideoModal';
import {
  ExportListToDimensionModal,
  RenameDimensionModal
} from 'components/layout/toolbar/modals/DimensionWithDescriptionModal';
import ExploreListModal from 'components/layout/toolbar/modals/ExploreListModal';
import { HelpAndInfoModal } from 'components/layout/toolbar/modals/HelpAndInfoModal';
import ListFromTableModal from 'components/layout/toolbar/modals/ListFromTableModal';
import { AddNewListModal, RenameListModal } from 'components/layout/toolbar/modals/ListNameModal';
import MergeListsModal from 'components/layout/toolbar/modals/MergeListsModal';
import RemoveDimensionModal from 'components/layout/toolbar/modals/RemoveDimensionModal';
import RemoveListModal from 'components/layout/toolbar/modals/RemoveListModal';
import RemoveMultipleDimensionsModal from 'components/layout/toolbar/modals/RemoveMultipleDimensionsModal';
import {
  RemoveDimensionWordsModal,
  RemoveListWordsModal
} from 'components/layout/toolbar/modals/RemoveWordsModal';
import { DeleteAccountPreventionModal } from 'components/profile/components/pricing-plan-card/DeleteAccountPreventionModal';
import { PricingBusinessPreventionModal } from 'components/profile/components/pricing-plan-card/PricingBusinessPreventionModal';
import { PricingDowngradePreventionModal } from 'components/profile/components/pricing-plan-card/PricingDowngradePreventionModal';
import EditProfileModal from 'components/profile/modals/editProfile/EditProfileModal';
import { AiImagesLimitReachedModal } from 'features/aiImages/AiImagesPage/AiImagesLimitReachedModal';
import { ShareAiImageModal } from 'features/aiImages/AiImagesPage/ShareAiImageModal';
import { UpgradeImagesModal } from 'features/aiImages/AiImagesPage/UpgradeImagesModal';
import TesterBriefConfigModal from 'features/aiTester/modals/briefConfig/BriefConfigModal';
import TesterGoalConfigModal from 'features/aiTester/modals/goalConfig/GoalConfigModal';
import TesterSetupModal from 'features/aiTester/modals/setup/SetupModal';
import ConfirmDeletionModal from 'features/aiWriter/AiWriterProjectOverview/ConfirmDeletionModal';
import { RenameProjectModal } from 'features/aiWriter/AiWriterProjectOverview/RenameProjectModal';
import { ManageTagsModal } from 'features/aiWriter/AiWriterProjectOverview/tags/ManageTagsModal';
import { ChatResetModal } from 'features/aiWriter/AiWriterSidebar/steps/chat/ChatResetModal';
import { FlashActionsModal } from 'features/aiWriter/AiWriterSidebar/steps/chat/FlashActionsModal';
import { GptLimitReachedModal } from 'features/aiWriter/chat/GptLimitReachedModal';
import { TemplatesModal } from 'features/aiWriter/commandTemplates/TemplatesModal';
import { SeoModal } from 'features/aiWriter/modals/SeoModal';
import { SetupModal as AiWriterSetupModal } from 'features/aiWriter/modals/setup/SetupModal';
import { UnsplashLicenceModal } from 'features/aiWriter/modals/UnsplashLicenceModal';
import { CancelSubscriptionModal } from 'features/churnModal/cancelSubscriptionModal';
import { DeleteAccountModal } from 'features/churnModal/deleteAccountModal';
import { EmailVerificationRequiredModal } from 'features/email-verification/EmailVerificationRequiredModal';
import GoalModal from 'features/explorer/modals/GoalModal';
import ModelGoalModal from 'features/explorer/modals/ModelGoalModal';
import ModelModal from 'features/explorer/modals/ModelModal';
import { ApplyInformationModal } from 'features/information/apply-information/ApplyInformationModal';
import { InformationModal } from 'features/information/InformationModal';
import { ModularWorkflowSettingsModal } from 'features/modular-workflow/builder/ModularWorkflowSettingsModal';
import { ModularWorkflowStepsModal } from 'features/modular-workflow/builder/ModularWorkflowStepsModal';
import { NotificationsModal } from 'features/notifications/NotificationsModal';
import { PersonalityCreationModal } from 'features/personality/creation/PersonalityCreationModal';
import { PersonalityLibraryModal } from 'features/personality/PersonalityLibraryModal';
import PlagiarismRescanModal from 'features/plagiarism-check/PlagiarismRescanModal';
import AccessExpiredModal from 'features/pricing/modals/AccessExpiredModal';
import { FreeCreditsLimitReachedModal } from 'features/pricing/modals/FreeCreditsLimitReachedModal';
import { WordsLimitReachedModal } from 'features/pricing/modals/WordsLimitReachedModal';
import { TrialExpiryReminderModal } from 'features/pricing/TrialExpiryReminderModal';
import { InviteUserModal } from 'features/profilePage/InviteUserModal';
import PerformanceModal from 'features/subjectLines/modals/PerformanceModal';
import { TeamMemberNeedsRefreshModal } from 'features/team/TeamMemberNeedsRefreshModal';
import { TeamSubscriptionCanceledWarningModal } from 'features/team/TeamSubscriptionCanceledWarning';
import { OutputTypeModal } from 'features/textGenerator/outputTypeModal/OutputTypeModal';
import { UpdateSubscriptionFromWordsLimitUsedModal } from 'features/updateSubscriptionFromWordsLimitUsedModal/UpdateSubscriptionFromWordsLimitUsedModal';
import { UpdateSubscriptionModal } from 'features/updateSubscriptionModal/UpdateSubscriptionModal';
import { CategoryRenameModal } from 'pages/brand-hub/components/CategoryRenameModal';
import { ConfirmCategoryDeletionModal } from 'pages/brand-hub/components/ConfirmCategoryDeletionModal';
import { MobileWarningModal } from 'pages/mobileWarning/MobileWarningModal';

export const modalComponents = {
  ACCESS_EXPIRED: AccessExpiredModal,
  ACCOUNT_DELETE: DeleteAccountModal,
  ADD_DIMENSION_WITH_WORDS: AddDimensionWithWordsModal,
  ADD_LIST_WITH_WORDS: AddListWithWordsModal,
  ADD_NEW_LIST: AddNewListModal,
  ADD_WORDS_TO_DIMENSION: AddWordsToDimension,
  ADD_WORDS_TO_LIST: AddWordsToList,
  AI_WRITER_SETUP_MODAL: AiWriterSetupModal,
  CANCEL_SUBSCRIPTION: CancelSubscriptionModal,
  EDIT_PROFILE: EditProfileModal,
  EMAIl_VERIFICATION_REQUIRED: EmailVerificationRequiredModal,
  EXPLORE_LIST: ExploreListModal,
  EXPLORING_GOAL_MODAL: GoalModal,
  EXPLORING_MODEL_GOAL_MODAL: ModelGoalModal,
  EXPLORING_MODEL_MODAL: ModelModal,
  HELP_AND_INFO: HelpAndInfoModal,
  LIST_TO_DIMENSION: ExportListToDimensionModal,
  MERGE_LISTS: MergeListsModal,
  NEW_LIST_FROM_TABLE: ListFromTableModal,
  PERFORMANCE: PerformanceModal,
  REMOVE_DIMENSION: RemoveDimensionModal,
  REMOVE_DIMENSION_WORDS: RemoveDimensionWordsModal,
  REMOVE_LIST: RemoveListModal,
  REMOVE_LIST_WORDS: RemoveListWordsModal,
  REMOVE_MULTIPLE_DIMENSIONS: RemoveMultipleDimensionsModal,
  RENAME_DIMENSION: RenameDimensionModal,
  RENAME_LIST: RenameListModal,
  TESTING_BRIEF_MODAL: TesterBriefConfigModal,
  TESTING_GOAL_MODAL: TesterGoalConfigModal,
  TESTING_SETUP_MODAL: TesterSetupModal,
  UPDATE_SUBSCRIPTION: UpdateSubscriptionModal,
  UPDATE_SUBSCRIPTION_FROM_WORDS_LIMIT_USED: UpdateSubscriptionFromWordsLimitUsedModal,
  VIDEO_GUIDE: VideoGuideModal,
  YOUTUBE_VIDEO: YouTubeVideoModal,
  UNSPLASH: UnsplashLicenceModal,
  SEO: SeoModal,
  OUTPUT_TYPES: OutputTypeModal,
  COMMAND_TEMPLATES: TemplatesModal,
  PERSONALITY_LIBRARY: PersonalityLibraryModal,
  PERSONALITY_CREATION: PersonalityCreationModal,
  CHAT_RESET_WARNING: ChatResetModal,
  SHARE_AI_IMAGE: ShareAiImageModal,
  NOTIFICATIONS: NotificationsModal,
  PLAGIARISM_RESCAN: PlagiarismRescanModal,
  CONFIRM_DELETION: ConfirmDeletionModal,
  RENAME_PROJECT: RenameProjectModal,
  INVITE_USER: InviteUserModal,
  PRICING_DOWNGRADE: PricingDowngradePreventionModal,
  DELETE_ACCOUNT_PREVENTION: DeleteAccountPreventionModal,
  TEAM_MEMBER_NEEDS_REFRESH: TeamMemberNeedsRefreshModal,
  TEAM_SUBSCRIPTION_CANCELED_WARNING: TeamSubscriptionCanceledWarningModal,
  AI_IMAGES_LIMIT_REACHED: AiImagesLimitReachedModal,
  WORDS_LIMIT_REACHED: WordsLimitReachedModal,
  FREE_CREDITS_LIMIT_REACHED: FreeCreditsLimitReachedModal,
  FLASH_ACTIONS: FlashActionsModal,
  AI_IMAGES_UPGRADE: UpgradeImagesModal,
  GPT_LIMIT_REACHED: GptLimitReachedModal,
  MOBILE_WARNING: MobileWarningModal,
  BUSINESS_UPGRADE_PREVENTION: PricingBusinessPreventionModal,
  MODULAR_WORKFLOW_BUILDER_STEPS: ModularWorkflowStepsModal,
  MODULAR_WORKFLOW_SETTINGS: ModularWorkflowSettingsModal,
  INFORMATION: InformationModal,
  APPLY_INFORMATION: ApplyInformationModal,
  TAGS_MODAL: ManageTagsModal,
  TRIAL_EXPIRY_REMINDER: TrialExpiryReminderModal,
  CONFIRM_CATEGORY_DELETION: ConfirmCategoryDeletionModal,
  CATEGORY_RENAME: CategoryRenameModal
};

export type ModalType = keyof typeof modalComponents;
