import Toast from 'components/toasts/Toast';
import { AiWriterProjectPreview } from 'features/aiWriter/store/types';
import { aiWriterProjectPreviewFromApi } from 'features/aiWriter/store/utils/mappers/aiWriterProjectPreviewMappers';
import { useCallback, useEffect, useState } from 'react';
import AiWriterAPI from 'services/api/aiWriter';
import { handleApiCommonErrors } from 'services/api/utils/handleApiCommonErrors';
import useRunInTask from 'utils/hooks/useRunInTask';

export default function useAiWriterDocPreview(hash: string) {
  const [project, setProject] = useState<AiWriterProjectPreview>();
  const [isLoading, runInTask] = useRunInTask();

  const fetchProject = useCallback(async () => {
    try {
      const response = await runInTask(() => AiWriterAPI.preview(hash));

      if (response.status) {
        setProject(aiWriterProjectPreviewFromApi({ ...response.data }));
      } else {
        Toast.backendError(handleApiCommonErrors(response.message));
      }
    } catch (e) {
      // We don't show error toast, cause user is informed about error by: ''Page is unavailable card''
      return null;
    }
  }, [hash, runInTask]);

  useEffect(() => {
    fetchProject();
  }, [runInTask, fetchProject]);

  return [project, isLoading] as const;
}
