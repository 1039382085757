import { Button, TextField } from '@mui/material';
import { CloseModal } from 'components/modals/types';
import FormattedMessage from 'features/i18n/FormattedMessage';
import {
  ContentToAnalyze,
  personalityCreationModalHeight,
  ViewMode
} from 'features/personality/creation/PersonalityCreationModal';
import {
  ModalBodyWithColumnDirectionAndSpaceBetween,
  ModalWithDividedHeaderLayout
} from 'features/theme-2024/ModalWithDividedHeaderLayout';
import { ChangeEvent, useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce/lib';
import useTr from 'utils/hooks/useTr';

type Props = {
  contentToAnalyze: URLString;
  setView: (view: ViewMode) => void;
  setShouldAnalyze: (value: boolean) => void;
  setContentToAnalyze: (value: ContentToAnalyze) => void;
  onBackClick: () => void;
} & CloseModal;

type URLString = string;

export function ShareUrlView(props: Props) {
  const {
    contentToAnalyze,
    setContentToAnalyze,
    setShouldAnalyze,
    setView,
    onBackClick,
    closeModal
  } = props;

  const [text, setText] = useState(contentToAnalyze ?? '');
  const [debouncedText] = useDebounce(text, 1000);

  // empty input should not trigger the error state
  const [isUrlValid, setIsUrlValid] = useState(true);

  const translate = useTr();

  const validateUrl = (url: URLString) => {
    if (url === '') {
      setIsUrlValid(true);

      return;
    }

    try {
      new URL(url);
      setIsUrlValid(true);
    } catch (_) {
      setIsUrlValid(false);
    }
  };

  useEffect(() => {
    validateUrl(debouncedText);
  }, [debouncedText]);

  const handleAnalyzeClick = () => {
    setContentToAnalyze(text);
    setShouldAnalyze(true);
    setView(ViewMode.analysis_completed);
  };

  const handleChangeText = (e: ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);
  };

  const handleBackClick = () => {
    onBackClick();
    setView(ViewMode.choice);
  };

  const isNextActionDisabled = !text || !isUrlValid;

  return (
    <ModalWithDividedHeaderLayout
      title={
        <FormattedMessage id="aiWriter.inspirations.chat.personalities.library_modal.create_personality" />
      }
      //subtitle="personality_creation.share_URL_view.subtitle"
      helpLink="aiWriter.inspirations.aiwriter.main.personality.help_link"
      closeModal={closeModal}
      height={personalityCreationModalHeight}
      slots={{
        bodyContainer: ModalBodyWithColumnDirectionAndSpaceBetween
      }}
      footer={
        <>
          <Button onClick={handleBackClick} variant="text">
            <FormattedMessage id="common.cancel" />
          </Button>

          <Button
            onClick={handleAnalyzeClick}
            variant="contained"
            color="primary"
            disabled={isNextActionDisabled}
          >
            <FormattedMessage id="common.analyze" />
          </Button>
        </>
      }
    >
      <div>
        <TextField
          value={text}
          onChange={handleChangeText}
          fullWidth
          error={!isUrlValid}
          helperText={!isUrlValid && translate('personality_creation.share_URL_view.input_error')}
          placeholder={translate('personality_creation.share_URL_view.input_placeholder')}
        />
      </div>
    </ModalWithDividedHeaderLayout>
  );
}
