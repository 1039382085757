import { useQuery } from '@tanstack/react-query';
import Toast from 'components/toasts/Toast';
import { handleApiCommonErrors } from 'services/api/utils/handleApiCommonErrors';
import { httpGetBillingPortalUrls } from 'services/backofficeIntegration/http/endpoints/customer/httpGetBillingPortal';
import { isObject } from 'utils/isObject';

export function useStripePortalUrl() {
  const { data } = useQuery({
    queryKey: httpGetBillingPortalUrls.makeQueryKey(),
    queryFn: httpGetBillingPortalUrls.callEndpoint,
    onError: err => {
      if (isObject(err) && isObject(err.data) && typeof err.data.message === 'string') {
        const errorId = handleApiCommonErrors(err.data.message);
        Toast.backendError(errorId);
      } else {
        Toast.apiError();
      }
    }
  });

  return {
    stripePortalUrl: data?.billing_portal_url ?? '',
    stripePortalPaymentMethodChangeUrl: data?.payment_method_change_url ?? '',
    stripePortalBillingAddressChangeUrl: data?.billing_address_change_url ?? ''
  };
}
