import { TextTypeOption } from 'features/textGenerator/EnhancedOutputTypeAutocomplete/TextTypesDropdownComponent';
import { useGetOutputTypeOptionsByLanguageAndCountry } from 'features/textGenerator/utils/useGetPromptsOthers';
import { useMemo } from 'react';

type CategorizedTextTypeOptions = Record<string, TextTypeOption[]>;

export const useGetTextTypeLabel = (
  textType: string,
  modelCountry: string,
  modelLanguage: string
) => {
  const outputTypeOptions = useGetOutputTypeOptionsByLanguageAndCountry(
    modelLanguage,
    modelCountry
  );
  const options = useMemo(() => {
    const outputTypes = outputTypeOptions.reduce((prev, curr) => {
      return {
        ...prev,
        [curr.category.label]: [
          ...(prev[curr.category.label] ?? []),
          {
            value: curr.outputType.id,
            label: curr.outputType.label,
            category: curr.category.label,
            youTubeVideoId: curr.outputType.youTubeVideoId ?? undefined
          }
        ]
      };
    }, {} as CategorizedTextTypeOptions);

    return Object.values(outputTypes).reduce(
      (prev, value) => [...prev, ...value],
      [] as TextTypeOption[]
    );
  }, [outputTypeOptions]);

  return options.find(option => option.value === textType)?.label ?? null;
};
