import { Typography } from '@mui/material';
import { ColoredTypography } from 'components/ColoredTypography';
import FlexContainer from 'components/FlexContainer';
import { useModal } from 'components/modals';
import { StyledDivider } from 'components/profile/components/common';
import PlanConfirmButton from 'components/profile/components/pricing-plan-card/PlanConfirmButton';
import PlanPerkList from 'components/profile/components/pricing-plan-card/PlanPerkList';
import {
  Headline,
  HeadlineContainer,
  PaymentHint,
  PlanCard,
  PricingContainer,
  StyledLink,
  SublineContainer,
  SublineTypography
} from 'components/profile/components/pricing-plan-card/PricingPlanCard';
import { usePlanConfirmMutation } from 'components/profile/components/pricing-plan-card/usePlanConfirmMutation';
import { CouponCode } from 'features/pricing/CouponCode';
import { FormattedPricing } from 'features/pricing/FormattedPricing';
import { FormattedWordLimit } from 'features/pricing/FormattedWordLimit';
import { PricingPlan } from 'features/pricing/types';
import { upperFirst } from 'lodash';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { ACLPricing, CustomerActiveSubscriptionState } from 'services/api/customer/types';
import useTr from 'utils/hooks/useTr';

interface Props {
  pricingPlan: ACLPricing;
  isBillingInfoPresent: boolean;
  isActivePlan: boolean;
  subscriptionState: CustomerActiveSubscriptionState;
  fromPopupModal?: boolean;
  detailsComponent?: React.ReactNode;
  coupon?: CouponCode;
  onScrollToSectionClick?: () => void;
}

export const BusinessPlanCard = ({
  pricingPlan,
  isBillingInfoPresent,
  isActivePlan,
  subscriptionState,
  coupon,
  onScrollToSectionClick
}: Props): ReactElement => {
  const translate = useTr();

  const { hideModal } = useModal();

  const { mutate: confirmPlan, isLoading } = usePlanConfirmMutation();

  const handlePlanConfirmClick = () => {
    confirmPlan({ pricingId: pricingPlan.id, isActivePlan, subscriptionState });
    hideModal();
  };

  return (
    <PlanCard>
      <HeadlineContainer>
        <Typography variant="h5">{upperFirst(pricingPlan.name)}</Typography>
      </HeadlineContainer>

      <PricingContainer>
        <Typography variant="h3" component="span">
          <FormattedPricing pricing={pricingPlan} discount={coupon} />
        </Typography>
        <ColoredTypography variant="caption" color="textSecondary">
          <FormattedMessage id="profile.plan.enterprise_subscription_period" defaultMessage="" />
        </ColoredTypography>
      </PricingContainer>
      <PaymentHint>
        <ColoredTypography variant="caption" color="textSecondary">
          <FormattedMessage id="profile.plan.enterprise_credit_card_hint" />
        </ColoredTypography>
      </PaymentHint>

      <SublineContainer>
        <SublineTypography variant="subtitle1">
          <FormattedMessage id="profile.plan.enterprise_subline" />
        </SublineTypography>
      </SublineContainer>

      <StyledDivider />

      <FlexContainer gap="three">
        <Headline variant="body2">
          <FormattedWordLimit planType={pricingPlan.type} />
        </Headline>
        <PlanConfirmButton
          isBillingInfoPresent={isBillingInfoPresent}
          isActivePlan={isActivePlan}
          isLoading={isLoading}
          subscriptionState={subscriptionState}
          isFreePlan={pricingPlan.isFree}
          onCtaClick={handlePlanConfirmClick}
          isProPlan={false}
          isTrial={!!pricingPlan.isTrial}
        />
        <StyledLink
          href={translate('external_links.enterprise_pricing_plan_get_in_touch')}
          target="_blank"
          rel="noreferrer"
        >
          <FormattedMessage id="profile.plan.enterprise_get_in_touch" />
        </StyledLink>

        {onScrollToSectionClick && (
          <StyledLink onClick={onScrollToSectionClick}>
            <FormattedMessage id="profile.plan.compare_button" />
          </StyledLink>
        )}

        <PlanPerkList planType={PricingPlan.enterprise} />
      </FlexContainer>
    </PlanCard>
  );
};
