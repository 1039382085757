import { Typography } from '@mui/material';
import { textGeneratorTips, TipLoader } from 'components/TipLoader';
import * as CommonStyled from 'features/aiWriter/AiWriterBlogPostBuilder/steps/common.styles';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { useState } from 'react';
import Confetti from 'react-confetti';
import styled from 'styled-components';
import { useTimeout } from 'utils/hooks/useTimeout';
import useWindowSize from 'utils/hooks/useWindowSize';

// Show a hint after 45 seconds
const longWaitingTime = 45_000;

type Props = {
  isFinished: boolean;
};

export const GenerateContentStep = ({ isFinished }: Props) => {
  const [showLongWaitingTimeHint, setShowLongWaitingTimeHint] = useState(false);

  useTimeout(() => setShowLongWaitingTimeHint(true), longWaitingTime);

  return (
    <>
      <CommonStyled.Title variant="subtitle1">
        <FormattedMessage id="blog_post_builder.steps.generate_content.title" />
      </CommonStyled.Title>

      <StepContent isFinished={isFinished} isLongWaiting={showLongWaitingTimeHint} />
    </>
  );
};

type StepContentProps = {
  isFinished: boolean;
  isLongWaiting: boolean;
};

function StepContent({ isFinished, isLongWaiting }: StepContentProps) {
  if (isFinished) {
    return (
      <Typography>
        <FormattedMessage id="blog_post_builder.steps.generate_content.content" />
        <ConfettiCannon />
      </Typography>
    );
  }

  return (
    <LimitedSpaceContainer>
      <TipLoader
        alternatingMessages={[
          'blog_post_builder.steps.outline.loader.average_time',
          'blog_post_builder.steps.outline.loader.creativity_progress'
        ]}
        tips={textGeneratorTips}
      />

      {isLongWaiting && (
        <LongWaitingTimeHintRoot>
          <Typography>
            <FormattedMessage id="blog_post_builder.steps.generate_content.long_waiting_time_hint" />
          </Typography>
        </LongWaitingTimeHintRoot>
      )}
    </LimitedSpaceContainer>
  );
}

function ConfettiCannon() {
  const { width, height } = useWindowSize();
  if (!width || !height) {
    return null;
  }

  return (
    <Confetti
      width={width}
      height={height}
      numberOfPieces={250}
      gravity={0.1}
      initialVelocityX={5}
      initialVelocityY={8}
      wind={0}
      recycle={false}
      // Position it vertically centered in the topper fourth of the screen
      confettiSource={{
        w: 10,
        h: 10,
        x: width / 2,
        y: height / 4
      }}
    />
  );
}

const LimitedSpaceContainer = styled.div`
  // This improves the positioning of the loader because it’s centered in the whole area,
  // but the headline is left-aligned.
  // This doesn't makes it as beautiful as f*ck but aligns it a lil bit more
  width: 350px;
`;

const LongWaitingTimeHintRoot = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 20rem;
  padding: ${({ theme }) => `${theme.spacings.xsmall} ${theme.spacings.small}`};
  margin-top: ${({ theme }) => theme.spacings.small};

  background-color: ${({ theme }) => theme.colors.primary50};
  border-radius: ${({ theme }) => theme.borderRadius.small};
`;
