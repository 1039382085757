import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import oneTimeInfoImage from 'assets/contentFlash/flashScore/flashScore-figure.png';
import FlexContainer from 'components/FlexContainer';
import LocalStorageKey from 'config/localStorageKey';
import FormattedMessage from 'features/i18n/FormattedMessage';
import styled from 'styled-components';
import { useLocalStorage } from 'utils/hooks/useLocalStorage';

export const OneTimeInfoCard = () => {
  const [hasSeenOneTimeInfo, setHasSeenOneTimeInfo] = useLocalStorage(
    LocalStorageKey.AiWriterFlashScoreOneTimeInfoSeen,
    false
  );

  const handleClose = () => {
    setHasSeenOneTimeInfo(true);
  };

  if (hasSeenOneTimeInfo) {
    return null;
  }

  return (
    <Root>
      <FlexContainer direction="column" gap="medium">
        <FlexContainer direction="row" justifyContent="space-between">
          <Content>
            <Title>
              <FormattedMessage
                id="aiWriter.inspirations.flashScore.one_time_info.headline"
                values={{
                  br: <br />,
                  highlight: (text: string) => <Higlighting>{text}</Higlighting>
                }}
              />
            </Title>

            <Body>
              <FormattedMessage id="aiWriter.inspirations.flashScore.one_time_info.content_1" />
            </Body>
            <Caption>
              <FormattedMessage id="aiWriter.inspirations.flashScore.one_time_info.content_2" />
            </Caption>
          </Content>
          <ImageContainer>
            <StyledImage src={oneTimeInfoImage} />
          </ImageContainer>
        </FlexContainer>

        <ButtonContainer>
          <IconButton onClick={handleClose} size="small">
            <CloseIcon />
          </IconButton>
        </ButtonContainer>
      </FlexContainer>
    </Root>
  );
};

const StyledImage = styled.img`
  max-width: 100%;
  height: auto;
  width: 204px;
`;

const Root = styled.div`
  position: relative;

  background-color: ${({ theme }) => theme.colors.palette.pinkRose__500main};
  border-radius: ${({ theme }) => theme.borderRadius.one};
`;

const Content = styled(FlexContainer).attrs({
  gap: 'one'
})`
  padding: ${({ theme }) =>
    `${theme.spacings.three} 0px ${theme.spacings.three} ${theme.spacings.three}`};
`;

const ImageContainer = styled.div`
  z-index: 1;

  top: 0;
  right: 0;
`;

const ButtonContainer = styled.div`
  position: absolute;
  z-index: 2;
  top: 18px;
  right: 20px;
`;

const Higlighting = styled.span`
  color: ${({ theme }) => theme.colors.white};
`;

const Title = styled(Typography).attrs({
  variant: 'h6'
})``;

const Body = styled(Typography).attrs({
  variant: 'body2'
})``;

const Caption = styled(Typography).attrs({
  variant: 'caption'
})`
  color: ${({ theme }) => theme.colors.textSecondary};
`;
