import Toast from 'components/toasts/Toast';
import { createFavorite } from 'features/aiWriter/store/actions/favorites/actions';
import { CreateFavoriteRequestPayload } from 'features/aiWriter/store/actions/favorites/types';
import { aiWriterFavoriteFromApi } from 'features/aiWriter/store/utils/mappers/aiWriterFavoriteMappers';
import { trackingWrapper } from 'features/tracking/wrapper/TrackingWrapper';
import FavoritesAPI from 'services/api/favorites';
import { handleApiCommonErrors } from 'services/api/utils/handleApiCommonErrors';
import { AppThunk } from 'store/store';
import { getCurrentTab } from 'store/utils/tabUtils';

export const createFavoriteThunk =
  (payload: CreateFavoriteRequestPayload): AppThunk<void> =>
  async (dispatch, getState) => {
    const { aiWriter } = getState();
    const currentTab = getCurrentTab(aiWriter);
    if (!currentTab) {
      return;
    }

    const { id: projectId, generateTextConfig } = currentTab;
    const { audienceId } = generateTextConfig;

    trackingWrapper.track('aiWriterSidebarTextGeneratorFavoriteSuggestion', {
      documentId: projectId,
      text: payload.text,
      generationId: payload.generationId,
      textItemId: payload.textItemId,
      audienceId
    });

    dispatch(createFavorite.request(payload));

    try {
      const response = await FavoritesAPI.create({
        text: payload.text,
        project_id: projectId,
        generation_id: payload.generationId,
        text_item_id: payload.textItemId
      });

      if (response.status) {
        dispatch(
          createFavorite.success({
            textInspirationId: payload.id,
            favorite: aiWriterFavoriteFromApi(response.data)
          })
        );
      } else {
        const errorId = handleApiCommonErrors(response.data.message);

        Toast.backendError(errorId);
      }
    } catch (e) {
      dispatch(createFavorite.failure({ id: payload.id }));
      Toast.commonError();
    }
  };
