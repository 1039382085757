import { useMarkTaskAsCompleted } from 'features/onboardingJourney/checklist/useMarkTaskAsCompleted';
import { trackingWrapper } from 'features/tracking/wrapper/TrackingWrapper';
import {
  ChecklistDto,
  TaskType
} from 'services/backofficeIntegration/http/endpoints/onboardingChecklist/httpGetChecklist';
import { GAEvents } from 'services/tracking/GAEvents';
import { create } from 'zustand';

type OnboardingChecklistState = {
  isViewable: boolean;
  isChecklistComplete: boolean;
  finishedTaskCount: number;
  taskCount: number;
  tasks: Task[];
  isWiggling: boolean;
  useCase: string;

  setTaskIsCompleted: (alias: TaskType, isCompleted: boolean) => void;
  setChecklistIsComplete: (isComplete: boolean) => void;
  setData: (data: ChecklistDto) => void;
  increaseFinishedTaskCount: () => void;
  setWiggling: (isWiggling: boolean) => void;
  setUseCase: (useCase: string) => void;
};

export type Task = {
  id: number;
  alias: TaskType;
  isCompleted: boolean;
};

export const useOnboardingChecklistStore = create<OnboardingChecklistState>(set => ({
  isViewable: false,
  isChecklistComplete: true,
  tasks: [],
  finishedTaskCount: 0,
  taskCount: 0,
  isWiggling: false,
  useCase: '',

  setTaskIsCompleted: (alias: TaskType, isCompleted: boolean) => {
    set(state => {
      const task = state.tasks.find(task => task.alias === alias);

      if (task) {
        task.isCompleted = isCompleted;
      }

      return { tasks: state.tasks };
    });
  },
  setChecklistIsComplete: (isComplete: boolean) => set({ isChecklistComplete: isComplete }),

  setData: (data: ChecklistDto) =>
    set({
      isViewable: data.is_viewable,
      finishedTaskCount: data.finished_lists_count,
      isChecklistComplete: data.finished_lists_count === data.task_lists_count,
      taskCount: data.task_lists_count,
      tasks: data.task_lists.map(task => ({ ...task, isCompleted: task.is_checked }))
    }),
  increaseFinishedTaskCount: () =>
    set(state => ({ finishedTaskCount: state.finishedTaskCount + 1 })),
  setWiggling: (isWiggling: boolean) => set({ isWiggling }),
  setUseCase: (useCase: string) => set({ useCase })
}));

export const useGetOnboardingChecklistProgress = () => {
  const finishedTaskCount = useOnboardingChecklistStore(state => state.finishedTaskCount);
  const taskCount = useOnboardingChecklistStore(state => state.taskCount);

  if (taskCount === 0) {
    return 0;
  }

  return Math.round((finishedTaskCount / taskCount) * 100);
};

export const useGetOnboardingUseCase = () => useOnboardingChecklistStore(state => state.useCase);

export const setOnboardingUseCase = (useCase: string) =>
  useOnboardingChecklistStore.getState().setUseCase(useCase);

export const setOnboardingChecklistData = (data: ChecklistDto) =>
  useOnboardingChecklistStore.getState().setData(data);

const setChecklistIsComplete = (isComplete: boolean) =>
  useOnboardingChecklistStore.getState().setChecklistIsComplete(isComplete);

const completeTask = (alias: TaskType) =>
  useOnboardingChecklistStore.getState().setTaskIsCompleted(alias, true);

export const useCompleteTask = (alias: TaskType) => {
  const isChecklistComplete = useOnboardingChecklistStore(state => state.isChecklistComplete);
  const finishedTaskCount = useOnboardingChecklistStore(state => state.finishedTaskCount);
  const taskCount = useOnboardingChecklistStore(state => state.taskCount);
  const increaseFinishedTaskCount = useOnboardingChecklistStore(
    state => state.increaseFinishedTaskCount
  );
  const task = useOnboardingChecklistStore(state => state.tasks).find(task => task.alias === alias);
  const setWiggling = useOnboardingChecklistStore(state => state.setWiggling);

  const { mutate } = useMarkTaskAsCompleted();

  return () => {
    if (isChecklistComplete || !task || task.isCompleted) {
      return;
    }

    completeTask(alias);
    mutate(task.id);
    increaseFinishedTaskCount();

    GAEvents.completeChecklistTask(alias);
    trackingWrapper.track('onboardingChecklistTaskComplete', {
      task: alias
    });

    setWiggling(true);

    setTimeout(() => {
      setWiggling(false);
    }, 1000);

    if (finishedTaskCount + 1 >= taskCount) {
      setChecklistIsComplete(true);

      GAEvents.completeAllChecklistTasks();
      trackingWrapper.track('onboardingChecklistCompleteAllTasks');
    }
  };
};
