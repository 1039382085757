import { createReducer } from '@reduxjs/toolkit';

import { fetchPrompts } from './actions/prompts/actions';
import { TextGeneratorState } from './types';
import { createTextGeneratorState } from './utils/createTextGeneratorState';

const textGeneratorReducer = createReducer<TextGeneratorState>(
  createTextGeneratorState(),
  builder =>
    builder
      .addCase(fetchPrompts.request, state => {
        state.isFetchPromptLoading = true;
      })
      .addCase(fetchPrompts.success, (state, { payload }) => {
        state.prompts = payload;
        state.isFetchPromptLoading = false;
      })
      .addCase(fetchPrompts.failure, state => {
        state.isFetchPromptLoading = false;
      })
);

export default textGeneratorReducer;
