import { handleGetAllEmbeddingModelsErrorsWithHyperlink } from 'services/api/embeddingModels/errors';
import { GetModelsResponse } from 'services/api/embeddingModels/types';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { reportErrors } from 'utils/reportErrors';

import Toast from '../../../components/toasts/Toast';
import LocalStorageKey from '../../../config/localStorageKey';
import ModelsAPI from '../../../services/api/embeddingModels';
import { createTabThunk as createExplorerTab } from '../../explorer/store/thunks';
import { getEmbeddingModels } from './slice';

const modelsInUse = [
  {
    storageKey: LocalStorageKey.ExplorerModel,
    mapperStorageKey: LocalStorageKey.ExplorerModelMapper,
    id: localStorage.getItem(LocalStorageKey.ExplorerModel)
  },
  {
    storageKey: LocalStorageKey.BrandEquityModel,
    id: localStorage.getItem(LocalStorageKey.BrandEquityModel)
  }
];

function* getEmbeddingModelsSaga() {
  const isInitialRequest = yield* select(state => !state.models.isReady);
  try {
    const response: GetModelsResponse = yield* call(ModelsAPI.getAll);

    if (response.status) {
      const models = response.data;
      yield* put(getEmbeddingModels.success(models));

      if (isInitialRequest) {
        modelsInUse.forEach(({ storageKey, mapperStorageKey, id }) => {
          const model = models.find(model => model.id === id);

          if (!model || model.loading_status !== 'loaded') {
            localStorage.removeItem(storageKey);
            if (mapperStorageKey) localStorage.removeItem(mapperStorageKey);
          } else if (mapperStorageKey) {
            const mapper = localStorage.getItem(mapperStorageKey);
            if (mapper) {
              const existingMapper = model.mappers.find(({ name }) => name === mapper);
              if (existingMapper) return;
            }
            if (model.mappers[0]) {
              localStorage.setItem(mapperStorageKey, model.mappers[0].name);
            }
          }
        });

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        yield* put(createExplorerTab() as any);
      }
    } else {
      yield* put(getEmbeddingModels.failure());
      Toast.backendError(...handleGetAllEmbeddingModelsErrorsWithHyperlink(response.data.message));
    }
  } catch (error) {
    yield* put(getEmbeddingModels.failure());
    Toast.apiError();
    reportErrors('saga', error as Error);
  }
}

export const modelsSagas = [
  takeLatest(getEmbeddingModels.request.toString(), getEmbeddingModelsSaga)
];
