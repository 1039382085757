import { Search } from '@mui/icons-material';
import { Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AutocompleteTagsInput from 'components/AutocompleteInput/AutocompleteTagsInput';
import { Form, FormikProps } from 'formik';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import styled, { css } from 'styled-components';
import { WordTag } from 'types/WordTag';

import AutoSaveFormik from '../../components/forms/AutoSaveFormik';
import { useModal } from '../../components/modals';
import Tooltip from '../../components/tooltips/Tooltip';
import LocalStorageKey from '../../config/localStorageKey';
import useTr from '../../utils/hooks/useTr';
import { getEmbeddingModelMapperVariablesSelector } from '../embeddingModels/store/selectors';
import Filters from './Filters';
import { getCurrentTabModelData, getIsTabInitialized } from './store/selectors';
import { setTabModel } from './store/slice';
import { ConceptFlashGoal, GoalValues, ModelGoalValues, ModelValues } from './store/types';
import { prepareGoalConfig } from './store/utils';
import TabInitialization from './TabInitialization';

const FormGrid = styled.div`
  display: grid;
  grid-template-columns: fit-content(14rem) 730px auto;
  column-gap: 1rem;
  row-gap: 0.25rem;

  @media (max-width: 768px) {
    margin-top: 1rem;
    grid-gap: 0.5rem;
    grid-template-columns: repeat(auto-fit, 100%);
  }
`;

const ActionsContainer = styled.div<{ justifyFlexEnd: boolean }>`
  grid-column-start: 2;
  display: flex;
  justify-content: ${props => (props.justifyFlexEnd ? 'flex-end' : 'flex-start')};
`;

const useButtonStyles = makeStyles({
  root: {
    padding: '6px 8px',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    width: '100%',
    minWidth: 'auto'
  }
});

export type ConfigFormValues = ConceptFlashGoal & {
  seedWords: WordTag[];
  listId: number | undefined;
};

const ConfigForm = ({
  values,
  isValid,
  errors,
  submitForm,
  setFieldValue,
  setValues
}: FormikProps<ConfigFormValues>) => {
  const tr = useTr();
  const dispatch = useAppDispatch();
  const { showModal } = useModal();
  const isInitialized = useAppSelector(getIsTabInitialized);
  const model = useAppSelector(getCurrentTabModelData);
  const getMapperVariables = useAppSelector(getEmbeddingModelMapperVariablesSelector);
  const { id: embeddingModelId, language: modelLanguage } = model || {};
  const buttonClasses = useButtonStyles();

  const openConfigModal = () => {
    const { wordAttributes, seoParams, dimensions, manualDimensions } = values;
    showModal({
      modalType: 'EXPLORING_GOAL_MODAL',
      modalProps: {
        size: 1100,
        onSubmit: (modalValues: GoalValues) => {
          const { dimensions, manualDimensions, wordAttributes } = modalValues;
          setValues({
            ...values,
            ...modalValues,
            wordAttributes,
            goalConfig: prepareGoalConfig(wordAttributes, dimensions, manualDimensions)
          });
        },
        initialValues: {
          wordAttributes,
          seoParams,
          dimensions,
          manualDimensions
        }
      }
    });
  };

  const onSetGoal = useCallback(
    ({ wordAttributes, dimensions, manualDimensions, seoParams }: GoalValues) => {
      setFieldValue('wordAttributes', wordAttributes);
      setFieldValue('dimensions', dimensions);
      setFieldValue('manualDimensions', manualDimensions);
      setFieldValue('seoParams', seoParams);
      setFieldValue('goalConfig', prepareGoalConfig(wordAttributes, dimensions, manualDimensions));
    },
    [setFieldValue]
  );

  const onSetModel = useCallback(
    ({ model: { modelId, modelMapper } }: ModelValues) => {
      const mapperVariables = getMapperVariables(modelId, modelMapper);
      dispatch(
        setTabModel({
          embeddingModelId: modelId,
          modelMapperId: modelMapper,
          hasValence: mapperVariables.includes('valence')
        })
      );
      localStorage.setItem(LocalStorageKey.ExplorerModel, modelId);
      localStorage.setItem(LocalStorageKey.ExplorerModelMapper, modelMapper);
    },
    [getMapperVariables, dispatch]
  );

  const onSetGoalAndModel = useCallback(
    ({ model, ...goalValues }: ModelGoalValues) => {
      onSetModel({ model });
      onSetGoal(goalValues);
    },
    [onSetModel, onSetGoal]
  );

  return isInitialized ? (
    <Form>
      <FormGrid>
        {!values.listId && (
          <>
            <div className="d-flex align-items-center">
              <FormattedMessage id="exploring.form.title" />
            </div>
            <div className="input-group flex-nowrap">
              <AutocompleteTagsInput
                css={css`
                  max-width: 40rem;
                  border-bottom-right-radius: 0 !important;
                  border-top-right-radius: 0 !important;
                `}
                inputProps={{ autoFocus: true }}
                value={values.seedWords}
                onChange={newSeedWords => {
                  setFieldValue('seedWords', newSeedWords);
                }}
                eventHandler={submitForm}
                model={embeddingModelId}
              />
              <div className="input-group-append">
                <Filters
                  name="filters"
                  selectedFilters={values.filters.length}
                  modelLanguage={modelLanguage}
                  clearFilters={() => setFieldValue('filters', [])}
                />
              </div>
              <div className="input-group-append">
                <Tooltip
                  isUntargetable={!isValid}
                  content={
                    !isValid ? (
                      Object.entries(errors).map(([field, error]) => (
                        <div key={field}>{error as unknown as string}</div>
                      ))
                    ) : (
                      <FormattedMessage id="common.explore" />
                    )
                  }
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={!isValid}
                    aria-label={tr('common.explore')}
                    classes={buttonClasses}
                  >
                    <Search />
                  </Button>
                </Tooltip>
              </div>
            </div>
          </>
        )}
        <ActionsContainer justifyFlexEnd={!values.listId}>
          <Button color="primary" onClick={openConfigModal}>
            <FormattedMessage id="goal_score.goals" />
          </Button>
        </ActionsContainer>
      </FormGrid>
      <AutoSaveFormik waitTime={500} />
    </Form>
  ) : (
    <TabInitialization
      seedWords={values.seedWords}
      onChange={newSeedWords => setFieldValue('seedWords', newSeedWords)}
      submitForm={submitForm}
      onSetGoalAndModel={onSetGoalAndModel}
      onSetModel={onSetModel}
      onSetGoal={onSetGoal}
    />
  );
};

export default ConfigForm;
