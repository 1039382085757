import { CircularProgress } from '@mui/material';
import { ColoredTypography } from 'components/ColoredTypography';
import React, { forwardRef, PropsWithChildren } from 'react';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled from 'styled-components';

type Props = {
  id?: string;
  gtmId?: string;
  actions?: React.ReactNode;
  hoverActions?: React.ReactNode;
  isLoading?: boolean;
  isDisabled?: boolean;
  subElements?: React.ReactNode;

  onClick?: () => void;
};

const TextTile = forwardRef<HTMLDivElement | null, PropsWithChildren<Props>>(
  (
    { id, gtmId, actions, hoverActions, isLoading, isDisabled, subElements, children, onClick },
    ref
  ) => {
    const onTileClick = () => {
      if (!isLoading && !isDisabled) {
        onClick?.();
      }
    };

    return (
      <Root>
        <Element
          {...withGtmInteraction(gtmId)}
          id={id} //Deprecated way of gtm tag usage #tech-debt: https://app.clickup.com/t/862je7864
          onClick={onTileClick}
          ref={ref}
          $disabled={isDisabled || isLoading}
          $hasClickHandler={!!onClick}
        >
          <Content variant="body1">
            {children}
          </Content>

          <Extra>
            {isLoading ? (
              <CircularProgress size={24} />
            ) : (
              <>
                {hoverActions && <HoverActions>{hoverActions}</HoverActions>}
                {actions && <Actions>{actions}</Actions>}
              </>
            )}
          </Extra>
        </Element>
        <SubElement>{subElements}</SubElement>
      </Root>
    );
  }
);

const HoverActions = styled.div`
  visibility: hidden;
`;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  user-select: none;
  width: 100%;
`;
const SubElement = styled.div``;

const Actions = styled.div``;

const Element = styled.div<{ $disabled?: boolean; $hasClickHandler?: boolean }>`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacings.small};

  width: 100%;
  padding: ${({ theme }) => theme.spacings.four};

  cursor: ${({ $disabled, $hasClickHandler }) =>
    $disabled || !$hasClickHandler ? 'default' : 'pointer'};
  background-color: ${({ theme, $disabled }) => $disabled ? theme.colors.commonWhiteMain : theme.colors.actionHover};
  border-radius: ${({ theme }) => theme.borderRadius.one};
  transition: background-color 0.1s linear;

  &:hover {
    background-color: ${({ theme, $disabled }) =>
      $disabled ? theme.colors.surfaceCard : theme.colors.actionDisabledbackground};

    ${HoverActions} {
      visibility: visible;
      display: flex;
      align-items: center;
    }
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.blackPressedOverlay};
    box-shadow: none;
  }
`;

const Content = styled(ColoredTypography)`
  // Until we support markdown or another data-transfer format,
  // this allows to support linebreaks very easily.
  white-space: pre-wrap;

  && {
    flex: 1 0 0;
  }
`;

const Extra = styled.div`
  display: flex;
  flex: 0 0 auto;
  justify-content: flex-end;
`;

export default TextTile;
