import { makeCurrentUserQueryKeyPrefix } from 'features/currentUser/currentUserQueries';
import { ApiPrompt } from 'services/api/wordEmbedding/types';
import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { queryEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

export type GetPromptsSuccessDto = BaseSuccessDto<ApiPrompt[]>;

export const httpGetPrompts = queryEndpoint({
  makeQueryKey: () => {
    return [...makeCurrentUserQueryKeyPrefix(), 'textGeneration', 'prompts', 'list'] as const;
  },
  callEndpoint: () => {
    return backofficeClient
      .get<GetPromptsSuccessDto>('/word-embedding/get_prompts')
      .then(response => response.data);
  }
});
