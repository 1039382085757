import useTr, { TranslationFunction } from 'utils/hooks/useTr';

import * as CommonStyled from './common.styles';
import { TextInputField } from './components/TextInputField';

function outlineValidator(value: string, tr: TranslationFunction) {
  let error;
  const lines = value.split('\n');
  if (lines.length > 10) {
    error = tr('blog_post_builder.steps.outline.error');
  }
  return error;
}

export const SelectOutlineStep = () => {
  const tr = useTr();

  return (
    <>
      <CommonStyled.Title variant="subtitle1">
        {tr('blog_post_builder.steps.outline.title')}
      </CommonStyled.Title>
      <TextInputField
        name="outline"
        placeholder={tr('blog_post_builder.steps.outline.placeholder')}
        validate={(value: string) => outlineValidator(value, tr)}
      />
    </>
  );
};
