import { Chip, Divider, Link, Paper, Typography } from '@mui/material';
import backgroundDoodleOrnament from 'assets/background-doodle.png';
import registerSuccessImage from 'assets/drawings/register-success.svg';
import { ReactComponent as GmailIcon } from 'assets/icons/gmail-logo.svg';
import appleMailIconImage from 'assets/icons/iphone-mail-logo.png';
import { ReactComponent as OutlookIcon } from 'assets/icons/outlook-logo.svg';
import FlexContainer from 'components/FlexContainer';
import { Ornament } from 'components/Ornament';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled from 'styled-components';
import { mobileWindowWidthCSS } from 'utils/hooks/useMobileVersion';

type RegisterSuccessCardProps = {
  email?: string;
  resendButton: ReactElement;
};

export function RegisterSuccessCard(props: RegisterSuccessCardProps) {
  const { email, resendButton } = props;

  return (
    <Root>
      <FlexContainer gap="large" alignItems="center">
        <FlexContainer gap="small" alignItems="center">
          <Typography variant="h5" fontWeight="bold">
            <FormattedMessage id="register.success.verify_email.header" />
          </Typography>
          <Typography variant="body1">
            <FormattedMessage id="register.success.verify_email.description" />
          </Typography>
        </FlexContainer>

        <img src={registerSuccessImage} alt="" />

        {email && (
          <Typography variant="body1">
            <FormattedMessage
              id="register.success.mail_info"
              values={{
                email,
                b: (chunks: string) => <b>{chunks}</b>
              }}
            />
          </Typography>
        )}

        <Footer>
          <Divider flexItem>
            <FormattedMessage id="register.success.divider" />
          </Divider>

          <MailChips />

          <Typography component="div" variant="body1">
            <FormattedMessage id="register.success.verificationEmail.explanation" /> {resendButton}
          </Typography>
        </Footer>
      </FlexContainer>

      <Ornament src={backgroundDoodleOrnament} bottom="0%" right="0%" />
    </Root>
  );
}

function MailChips() {
  return (
    <FlexContainer direction="row" gap="medium" justifyContent="center">
      <Chip
        {...withGtmInteraction(gtmIds.verifyAccount.goToEmail)}
        label="Gmail"
        icon={<GmailIcon height={14} width={18} />}
        component="a"
        clickable
        href="https://mail.google.com/mail/u/0/#search/from%3Anoreply%40neuro-flash.com"
        rel="noreferrer"
        target="_blank"
      />
      <Chip
        {...withGtmInteraction(gtmIds.verifyAccount.goToEmail)}
        label="Outlook"
        icon={<OutlookIcon height={18} width={22} />}
        component="a"
        clickable
        href="https://outlook.live.com"
        rel="noreferrer"
        target="_blank"
      />
      <Chip
        {...withGtmInteraction(gtmIds.verifyAccount.goToEmail)}
        label="Apple"
        icon={<img src={appleMailIconImage} alt="Apple iPhone Mail" height={18} />}
        component="a"
        clickable
        href="https://www.icloud.com/mail"
        rel="noreferrer"
        target="_blank"
      />
    </FlexContainer>
  );
}

RegisterSuccessCard.ResendButton = ResendButton;

function ResendButton(props: { onClick: () => void }) {
  return (
    <Link component="button" onClick={props.onClick}>
      <FormattedMessage id="register.success.verificationEmail.resend" />
    </Link>
  );
}

const Root = styled(Paper).attrs({
  elevation: 2
})`
  position: relative;

  height: fit-content;
  width: 650px;
  padding: ${({ theme }) => theme.spacings.large};

  @media (max-width: ${mobileWindowWidthCSS}) {
    width: 100%;
    padding: ${({ theme }) => theme.spacings.medium};
  } ;
`;

const Footer = styled(FlexContainer).attrs({
  gap: 'large',
  justifyContent: 'center',
  alignItems: 'center'
})`
  width: 100%;
`;
