// #tech-debt: https://app.clickup.com/t/38vnp8r

import ErrorIcon from '@mui/icons-material/Error';
import FlexContainer from 'components/FlexContainer';
import { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';

type Props = {
  children: ReactNode;
};

const NotificationHint = ({ children }: Props): ReactElement => (
  <Root direction="row" alignItems="center">
    <StyledInfoIcon width={24} height={24} />
    <div>{children}</div>
  </Root>
);

const Root = styled(FlexContainer)`
  padding: ${({ theme }) => theme.spacings.medium};
  margin-bottom: ${({ theme }) => theme.spacings.medium};

  background: ${({ theme }) => theme.colors.infoToolbarBackground};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
`;

const StyledInfoIcon = styled(ErrorIcon)`
  margin-right: ${({ theme }) => theme.spacings.small};
`;

export default NotificationHint;
