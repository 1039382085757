import { Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { useAvailablePlans } from 'features/pricing/hook/useAvailablePlans';
import { PricingPeriodSelect } from 'features/pricing/PricingPeriodSelect';
import { PricingPlanButton } from 'features/pricing/PricingPlanButton';
import { Discount } from 'features/pricing/types';
import { Field, FieldProps } from 'formik';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { SubscriptionPeriod } from 'services/api/customer/types';
import { PricingContextParams } from 'services/api/pricing';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

type Props = {
  discount?: Discount;
  pricingName?: string;
  // we need to pass the context for API to get the correct pricings
  pricingContext?: PricingContextParams['context'];
};

export const PricingSelect = ({ discount, pricingName = 'pricing', pricingContext }: Props) => {
  const translate = useTr();

  const [subscriptionPeriod, setSubscriptionPeriod] = useState<SubscriptionPeriod>('year');

  const { data } = useAvailablePlans({ period: subscriptionPeriod, context: pricingContext });

  const { standardPlan, proPlan, businessPlan } = data ?? {};

  return (
    <div>
      <Header>
        <PricingPeriodSelect
          subscriptionPeriod={subscriptionPeriod}
          onSubscriptionPeriodChange={setSubscriptionPeriod}
        />
      </Header>

      <Field name={pricingName}>
        {({ field: { value, name }, form: { setFieldValue } }: FieldProps) => (
          <FlexContainer gap="small">
            {standardPlan && (
              <PricingPlanButton
                discount={discount}
                pricing={standardPlan}
                onClick={plan => setFieldValue(name, plan)}
                selected={standardPlan.id === value?.id}
              />
            )}

            {proPlan && (
              <PricingPlanButton
                key={proPlan.id}
                name={proPlan.name}
                discount={discount}
                pricing={proPlan}
                onClick={plan => setFieldValue(name, plan)}
                selected={proPlan.id === value?.id}
              />
            )}

            {businessPlan && (
              <PricingPlanButton
                key={businessPlan.id}
                name={businessPlan.name}
                discount={discount}
                pricing={businessPlan}
                onClick={plan => setFieldValue(name, plan)}
                selected={businessPlan.id === value?.id}
              />
            )}
          </FlexContainer>
        )}
      </Field>
      <Extra variant="caption">
        <FormattedMessage id="register.pricing.explanation" />
      </Extra>
      <Typography variant="subtitle2">
        <FormattedMessage
          id="register.pricing.contact"
          values={{
            contact: (msg: string) => (
              <Link
                href={translate('training_session.link')}
                target="_blank"
                rel="noopener noreferrer"
              >
                {msg}
              </Link>
            ),
            plans: (msg: string) => (
              <Link
                href={translate('external_links.app_landingpage_pricing_plans')}
                target="_blank"
                rel="noopener noreferrer"
              >
                {msg}
              </Link>
            )
          }}
        />
      </Typography>
    </div>
  );
};

const Header = styled(FlexContainer)`
  margin-bottom: ${({ theme }) => theme.spacings.three};
`;

const Extra = styled(Typography)`
  && {
    display: block;

    margin-top: ${({ theme }) => theme.spacings.small};
    margin-bottom: ${({ theme }) => theme.spacings.large};

    color: ${({ theme }) => theme.colors.blackMediumEmphasis};
  }
`;

const Link = styled.a`
  color: ${({ theme }) => theme.colors.primary};
`;
