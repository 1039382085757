import { Menu } from '@mui/material';
import { MenuProps } from '@mui/material/Menu';
import { DefaultTheme } from '@mui/styles/defaultTheme';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

export type CommonAction = {
  onClose: () => void;
};

const useMenuStyles = makeStyles<DefaultTheme, { width: string }>({
  list: {
    width: props => props.width
  }
});

type Props = Omit<MenuProps, 'open'> & {
  trigger: React.ReactNode;
  anchorElement: HTMLElement | null;
  isOpen: boolean;
  width?: string;
  close: () => void;
};

export const MenuDropdown = ({
  trigger,
  anchorElement,
  isOpen,
  anchorOrigin,
  transformOrigin,
  children,
  close,
  width = '280px !important',
  disableScrollLock = true,
  ...menuProps
}: Props) => {
  const classes = useMenuStyles({ width });

  return (
    <>
      {trigger}
      <Menu
        classes={classes}
        open={isOpen}
        anchorEl={anchorElement}
        onClose={close}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        disableScrollLock={disableScrollLock}
        {...menuProps}
      >
        {children}
      </Menu>
    </>
  );
};
