import { getRoutePath } from 'config/routes';
import { getHasToken, getIsApiCustomer } from 'features/customer/store/selectors';
import { Location } from 'history';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useAppSelector } from 'store/hooks';
import { isObject } from 'utils/isObject';

export type PreviousLocationStateType = Location & {
  state?: {
    from?: { pathname?: string; search?: string; hash?: string };
  };
};

const useCreateTargetCustomerUrl = () => {
  const { state: locationState } = useLocation() as PreviousLocationStateType;
  if (isObject(locationState)) {
    return `${locationState?.from?.pathname}${locationState?.from?.search}${locationState?.from?.hash}`;
  }
  return getRoutePath('aiWriter');
};

export default function useRedirectWhenAuthenticated() {
  const navigate = useNavigate();
  const userHasToken = useAppSelector(getHasToken);
  const isApiCustomer = useAppSelector(getIsApiCustomer);

  const apiCustomerTargetUrl = getRoutePath('home');

  // Extract the target URL from the location state
  const normalCustomerTargetUrl = useCreateTargetCustomerUrl();

  useEffect(() => {
    if (!userHasToken) {
      return;
    }

    if (isApiCustomer) {
      navigate(apiCustomerTargetUrl);
      return;
    }

    navigate(normalCustomerTargetUrl);
  }, [userHasToken, navigate, isApiCustomer, normalCustomerTargetUrl, apiCustomerTargetUrl]);
}
