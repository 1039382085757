import { Table, TableBody, TableRow, Tooltip } from '@mui/material';
import {
  displayFeaturePresence,
  FirstCell,
  StyledCell,
  StyledTable,
  UnderlinedText
} from 'components/profile/components/pricing-plan-card/FeatureComparisonSection';
import { FormattedMessage } from 'react-intl';

const createNeuroflashSuiteData = (
  name: string,
  standard: boolean,
  pro: boolean,
  enterprise: boolean
) => {
  return { name, standard, pro, enterprise };
};

const neuroflashSuiteData = [
  createNeuroflashSuiteData('content_flash', true, true, true),
  createNeuroflashSuiteData('image_flash', true, true, true),
  createNeuroflashSuiteData('performance_flash', false, false, true),
  createNeuroflashSuiteData('research_flash', false, false, false)
];

export const NeuroflashSuiteTable = () => {
  return (
    <StyledTable>
      <Table>
        <TableBody>
          {neuroflashSuiteData.map(row => (
            <TableRow key={row.name}>
              <FirstCell component="th" scope="row">
                <Tooltip
                  title={
                    <FormattedMessage
                      id={`features_comparison.neuroflash_suite.${row.name}.tooltip`}
                    />
                  }
                  placement="top"
                >
                  <UnderlinedText>
                    <FormattedMessage id={`features_comparison.neuroflash_suite.${row.name}`} />
                  </UnderlinedText>
                </Tooltip>
              </FirstCell>
              <StyledCell>{displayFeaturePresence(row.standard)}</StyledCell>
              <StyledCell>{displayFeaturePresence(row.pro)}</StyledCell>
              <StyledCell>{displayFeaturePresence(row.enterprise)}</StyledCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </StyledTable>
  );
};
