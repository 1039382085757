import { LabelRounded } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import {
  CircularProgress,
  IconButton,
  TablePagination,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import FlexContainer from 'components/FlexContainer';
import { SearchField } from 'components/SearchField';
import { useConfirmDeletionModal } from 'features/aiWriter/AiWriterProjectOverview/ConfirmDeletionModal';
import { NoDocumentsPlaceholder } from 'features/aiWriter/AiWriterProjectOverview/NoDocumentsPlaceholder';
import { ProjectsGrid } from 'features/aiWriter/AiWriterProjectOverview/ProjectsGrid';
import { ProjectsTable } from 'features/aiWriter/AiWriterProjectOverview/ProjectsTable';
import { AiWriterProjectTagFilter } from 'features/aiWriter/AiWriterProjectOverview/tags/AiWriterProjectTagFilter';
import { AiWriterProjectTagsDropdownMenu } from 'features/aiWriter/AiWriterProjectOverview/tags/AiWriterProjectTagsDropdownMenu';
import { TeamMemberFilter } from 'features/aiWriter/AiWriterProjectOverview/TeamMemberFilter';
import { useDeleteAiWriterProjects } from 'features/aiWriter/AiWriterProjectOverview/useDeleteAiWriterProjects';
import { updateProjects } from 'features/aiWriter/store/actions/project/actions';
import { ChangeEvent, MouseEvent, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { AiWriterProjectTagType } from 'services/api/aiWriter/types';
import { TeamMemberDto } from 'services/backofficeIntegration/http/dtos/TeamMemberDto';
import {
  httpGetProjects,
  PaginatedListParams,
  SortByDto,
  SortDirection
} from 'services/backofficeIntegration/http/endpoints/aiWriter/httpGetProjects';
import { useAppDispatch } from 'store/hooks';
import styled, { css } from 'styled-components';
import { useDebounce } from 'use-debounce/lib';
import { useMenu } from 'utils/hooks/useMenu';

type View = 'grid' | 'table';

const sortByOptions: Array<SortByDto> = ['updated_at', 'locale', 'name'];
type SortByOption = typeof sortByOptions[number];

export type SortConfiguration = {
  column: SortByOption;
  direction?: SortDirection;
};

export type ProjectId = string;

export function NewProjectsSection() {
  const dispatch = useAppDispatch();

  const [view, setView] = useState<View>('table');

  const [sorting, setSorting] = useState<SortConfiguration>({
    column: 'updated_at',
    direction: 'desc'
  });
  const [selectedProjectIds, setSelectedProjectIds] = useState<ProjectId[]>([]);
  const areProjectsSelected = selectedProjectIds.length !== 0;

  const [isFilteringActive, setIsFilteringActive] = useState(false);
  const [filteredTeamMember, setFilteredTeamMember] = useState<TeamMemberDto | undefined>();
  const [filteredTag, setFilteredTag] = useState<AiWriterProjectTagType | undefined>();

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [debouncedSearchQuery] = useDebounce(searchQuery, 300);

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);

  // this is needed to properly show the results after the list is filtered
  // e.g. when there is only a few or one result from the filtering we should request the endpoint
  // with offset 0 to show the first page with results
  const offset = filteredTag ? 0 : limit * page;

  const queryParams: PaginatedListParams = {
    offset,
    limit,
    q: debouncedSearchQuery || undefined,
    sort: sorting.column,
    direction: sorting.direction,
    author_id: filteredTeamMember?.customer_id,
    tag_ids: filteredTag ? [filteredTag.id] : undefined
  };

  const { isLoading, data, isError } = useQuery({
    queryKey: httpGetProjects.makeQueryKey(queryParams),
    queryFn: () => httpGetProjects.callEndpoint(queryParams),
    keepPreviousData: true,
    onSuccess: data => {
      dispatch(updateProjects(data.data));
    }
  });

  const { mutate: deleteProjects, isLoading: isDeleteLoading } = useDeleteAiWriterProjects();

  const confirmDeletion = useConfirmDeletionModal();

  const {
    anchorEl: tagsAnchorEl,
    isOpen: isTagsMenuOpen,
    onTriggerClick: onTagsTriggerClick,
    onMenuClose: onTagsMenuClose
  } = useMenu();

  function handleChangePage(_event: unknown | null, page: number) {
    setPage(page);
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  function handleSearchChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setSearchQuery(e.target.value);
    setPage(0);
  }

  const handleSetView = (_event: MouseEvent<HTMLElement>, view: View) => {
    setView(view);
  };

  const handleDeleteSelected = () => {
    confirmDeletion({
      onDelete: () => {
        deleteProjects(selectedProjectIds);
        handleClearSelected();
      }
    });
  };

  const handleClearSelected = () => {
    setSelectedProjectIds([]);
  };

  const handleToggleFiltering = () => {
    if (isFilteringActive) {
      setFilteredTeamMember(undefined);
    }

    setIsFilteringActive(!isFilteringActive);
  };

  if (isError) {
    return null;
  }

  const projects = data?.data;
  const total = data?.total;
  const oneSelectedProject =
    selectedProjectIds.length === 1
      ? projects?.find(p => selectedProjectIds[0] === p.id)
      : undefined;

  function renderProjects() {
    if (!projects) {
      return null;
    }

    if (view === 'table') {
      return (
        <ProjectsTable
          projects={projects}
          sorting={sorting}
          selectedProjectIds={selectedProjectIds}
          onSortChange={setSorting}
          setSelectedProjectIds={setSelectedProjectIds}
        />
      );
    }

    return <ProjectsGrid projects={projects} />;
  }

  function renderSectionLabel() {
    if (isDeleteLoading) {
      return (
        <FlexContainer justifyContent="center" alignItems="center">
          <CircularProgress />
        </FlexContainer>
      );
    }

    if (areProjectsSelected) {
      return (
        <FlexContainer direction="row" alignItems="center" gap="small">
          <IconButton onClick={handleClearSelected}>
            <ClearIcon />
          </IconButton>
          <Typography variant="subtitle1">
            <FormattedMessage
              id="aiWriter.project_overview.new_projects.selected"
              values={{
                count: selectedProjectIds.length
              }}
            />
          </Typography>
          {selectedProjectIds.length === 1 && (
            <IconButton onClick={onTagsTriggerClick}>
              <LabelRounded />
            </IconButton>
          )}
          <IconButton onClick={handleDeleteSelected}>
            <DeleteIcon />
          </IconButton>
        </FlexContainer>
      );
    }

    return (
      <Typography variant="subtitle1">
        <FormattedMessage id="common.all_documents" />
      </Typography>
    );
  }

  return (
    <FlexContainer gap="two">
      <FlexContainer direction="row" alignItems="center" justifyContent="space-between">
        {renderSectionLabel()}

        <FlexContainer direction="row" gap="small">
          <FilterButton $isActive={isFilteringActive} onClick={handleToggleFiltering}>
            <FilterListIcon />
          </FilterButton>
          <SearchField value={searchQuery} onChange={handleSearchChange} />
          <ToggleButtonGroup value={view} exclusive onChange={handleSetView} size="small">
            <RoundedToggleButton value="table">
              <ViewListIcon />
            </RoundedToggleButton>
            <RoundedToggleButton value="grid">
              <ViewModuleIcon />
            </RoundedToggleButton>
          </ToggleButtonGroup>
        </FlexContainer>
      </FlexContainer>

      {isFilteringActive && (
        <FlexContainer direction="row" gap="small">
          <TeamMemberFilter value={filteredTeamMember} setValue={setFilteredTeamMember} />
          <AiWriterProjectTagFilter value={filteredTag} setValue={setFilteredTag} />
        </FlexContainer>
      )}

      {isLoading && (
        <FlexContainer justifyContent="center" alignItems="center">
          <CircularProgress />
        </FlexContainer>
      )}

      {projects?.length !== 0 ? (
        <>
          {renderProjects()}

          <TablePagination
            count={total ?? 0}
            page={page}
            rowsPerPage={limit}
            onPageChange={handleChangePage}
            rowsPerPageOptions={[5, 10, 25]}
            onRowsPerPageChange={handleChangeRowsPerPage}
            component="div"
          />
        </>
      ) : (
        <NoDocumentsPlaceholder />
      )}
      <AiWriterProjectTagsDropdownMenu
        anchorEl={tagsAnchorEl}
        isOpen={isTagsMenuOpen}
        handleCloseMenu={onTagsMenuClose}
        tags={oneSelectedProject?.tags}
        project={oneSelectedProject}
      />
    </FlexContainer>
  );
}

const FilterButton = styled(IconButton)<{ $isActive: boolean }>`
  ${({ theme, $isActive }) => {
    if ($isActive) {
      return css`
        color: ${theme.colors.primary};
        background-color: ${theme.colors.primary50};
      `;
    }
  }}
`;

const RoundedToggleButton = styled(ToggleButton)`
  border-radius: ${({ theme }) => theme.borderRadius.one};
`;
