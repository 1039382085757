import { useMutation } from '@tanstack/react-query';
import Toast from 'components/toasts/Toast';
import { ToastErrorWithLink } from 'components/toasts/ToastErrorWithLink';
import {
  addOptimisticQuestion,
  addStreamedResponse,
  getInformationList,
  revertOptimisticQuestion,
  updateActiveConversation,
  updateStreamedResponse,
  useChatCurrentGptModel,
  useStoredActiveConversationId,
  useStoredPersonalityId,
  useSyncedProjectId
} from 'features/aiWriter/AiWriterSidebar/steps/chat/chatStore';
import { Message, MessageDraft } from 'features/aiWriter/AiWriterSidebar/steps/chat/Message';
import { useMessageStreamingIndicatorStore } from 'features/aiWriter/AiWriterSidebar/steps/chat/messageStreamingIndicatorStore';
import { invalidateActiveConversationQuery } from 'features/aiWriter/AiWriterSidebar/steps/chat/useActiveConversationQuery';
import { invalidateMessagesQuery } from 'features/aiWriter/AiWriterSidebar/steps/chat/useMessagesQuery';
import { useShowGptLimitReachedModal } from 'features/aiWriter/chat/GptLimitReachedModal';
import { invalidateWordsUsageQueries } from 'features/wordsUsage/invalidateWordsUsageQueries';
import { toast } from 'react-toastify';
import { termsOfUse } from 'services/api/utils/errorHyperlinks';
import {
  GptModel,
  httpCreateConversation
} from 'services/backofficeIntegration/http/endpoints/aiWriter/httpCreateConversation';
import {
  ErrorHandler,
  httpSendMessage
} from 'services/backofficeIntegration/http/endpoints/aiWriter/httpSendMessage';
import { invalidateCustomerAllLimitationsQueries } from 'services/backofficeIntegration/http/endpoints/customer/httpGetAllLimitations';
import { createUuidV4 } from 'utils/createUuidV4';
import useTr from 'utils/hooks/useTr';

function createConversation(
  projectId: string,
  conversationId: string,
  personalityId: number | null,
  gptModel: GptModel | null
) {
  return httpCreateConversation.callEndpoint({
    projectId,
    conversationId,
    personalityId,
    gptModel
  });
}

function sendMessage(
  message: Message,
  projectId: string,
  conversationId: string,
  onError: ErrorHandler,
  setMessageStreamIndicator: (isMessageBeingStreamed: boolean) => void
) {
  return httpSendMessage.callStreamEndpoint({
    message,
    projectId,
    conversationId,
    onUpdate: chunk => {
      updateStreamedResponse(chunk);
    },
    onStart: partialMessage => {
      addStreamedResponse(partialMessage);
      setMessageStreamIndicator(true);
    },
    onError: onError,
    onEnd: () => {
      setMessageStreamIndicator(false);
    }
  });
}

const streamingErrorCodes = {
  ERROR_NO_GPT4_BALANCE: 'ERROR_NO_GPT4_BALANCE',
  ERROR_ALL_WORDS_USED: 'ERROR_ALL_WORDS_USED',
  ERROR_CONVERSATION_BLOCKED: 'ERROR_CONVERSATION_BLOCKED',
  ERROR_CONVERSATION_NOT_FOUND: 'ERROR_CONVERSATION_NOT_FOUND',
  ERROR_CONVERSATION_INVALID_CURSOR: 'ERROR_CONVERSATION_INVALID_CURSOR',
  ERROR_CONVERSATION_SIMULTANEOUS_BEFORE_AFTER_CURSOR:
    'ERROR_CONVERSATION_SIMULTANEOUS_BEFORE_AFTER_CURSOR',
  ERROR_CONVERSATION_STREAMING_FAILED: 'ERROR_CONVERSATION_STREAMING_FAILED',
  ERROR_CONVERSATION_RESPONSE_GENERATION_FAILED: 'ERROR_CONVERSATION_RESPONSE_GENERATION_FAILED',
  ERROR_ACCESS_DISABLED: 'ERROR_ACCESS_DISABLED',
  ERROR_RESPONSE_NOT_SUCCESS: 'ERROR_RESPONSE_NOT_SUCCESS',
  ERROR_RESPONSE_MESSAGE_NOT_DEFINED: 'ERROR_RESPONSE_MESSAGE_NOT_DEFINED',
  ERROR_CONVERSATION_NOT_ACTIVE: 'ERROR_CONVERSATION_NOT_ACTIVE',
  ERROR_TEXT_GENERATION_FAIR_USAGE_REACHED: 'ERROR_TEXT_GENERATION_FAIR_USAGE_REACHED',
  ERROR_GPT4_ACCESS_DISABLED: 'ERROR_GPT4_ACCESS_DISABLED'
};

const useHandleStreamingErrorMessages = (): ErrorHandler => {
  const tr = useTr();
  const contactLink = tr('common.contact_link');
  const showGptLimitReachedModal = useShowGptLimitReachedModal();

  return ({ errorCode }: { errorCode: string }) => {
    switch (errorCode) {
      case streamingErrorCodes.ERROR_CONVERSATION_BLOCKED:
      case streamingErrorCodes.ERROR_CONVERSATION_NOT_FOUND:
      case streamingErrorCodes.ERROR_RESPONSE_NOT_SUCCESS:
      case streamingErrorCodes.ERROR_RESPONSE_MESSAGE_NOT_DEFINED:
      case streamingErrorCodes.ERROR_CONVERSATION_NOT_ACTIVE:
      case streamingErrorCodes.ERROR_GPT4_ACCESS_DISABLED:
        // this handler can be called multiple times with the same error code
        // by using the toastId with the error code we can prevent duplicate toasts
        return Toast.error(`aiWriter.chat.error.${errorCode}`, {}, { toastId: errorCode });
      case streamingErrorCodes.ERROR_CONVERSATION_INVALID_CURSOR:
      case streamingErrorCodes.ERROR_CONVERSATION_SIMULTANEOUS_BEFORE_AFTER_CURSOR:
      case streamingErrorCodes.ERROR_CONVERSATION_STREAMING_FAILED:
      case streamingErrorCodes.ERROR_CONVERSATION_RESPONSE_GENERATION_FAILED:
        return toast.error(
          <ToastErrorWithLink message={`aiWriter.chat.error.${errorCode}`} link={contactLink} />,
          { toastId: errorCode }
        );
      case streamingErrorCodes.ERROR_ACCESS_DISABLED:
      case streamingErrorCodes.ERROR_TEXT_GENERATION_FAIR_USAGE_REACHED:
        return toast.error(
          <ToastErrorWithLink message={`aiWriter.chat.error.${errorCode}`} link={termsOfUse} />,
          { toastId: errorCode }
        );
      case streamingErrorCodes.ERROR_ALL_WORDS_USED:
      case streamingErrorCodes.ERROR_NO_GPT4_BALANCE:
        return showGptLimitReachedModal();
      default:
        return;
    }
  };
};

export function useSendMessage() {
  const projectId = useSyncedProjectId();
  const activeConversationId = useStoredActiveConversationId();
  const currentGptModel = useChatCurrentGptModel();
  const personalityId = useStoredPersonalityId();
  const noConversation = !activeConversationId;
  const conversationId = noConversation ? createUuidV4() : activeConversationId;

  const gptModel = currentGptModel;

  const informationList = getInformationList();
  const informationIds =
    informationList && informationList.length ? informationList.map(({ id }) => id) : undefined;

  const handleStreamingErrors = useHandleStreamingErrorMessages();

  const setIsMessageBeingStreamed = useMessageStreamingIndicatorStore(
    state => state.setIsMessageBeingStreamed
  );

  return useMutation({
    mutationFn: async (messageDraft: MessageDraft) => {
      const { text, id, source } = messageDraft;
      const message: Message = {
        text,
        id,
        source,
        created_at: Date.now(),
        information_ids: informationIds
      };
      addOptimisticQuestion(message);
      if (noConversation) {
        await createConversation(projectId, conversationId, personalityId, gptModel);
      }

      return sendMessage(
        message,
        projectId,
        conversationId,
        handleStreamingErrors,
        setIsMessageBeingStreamed
      );
    },
    onSuccess: () => {
      if (noConversation) {
        updateActiveConversation({ conversationId });
        invalidateActiveConversationQuery({ projectId });
      }

      invalidateMessagesQuery({ conversationId });
      invalidateWordsUsageQueries();
      invalidateCustomerAllLimitationsQueries();
    },
    onError: () => {
      revertOptimisticQuestion();
    }
  });
}
