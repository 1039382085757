import { SvgIcon, Tooltip } from '@mui/material';
import { ReactComponent as FlashScoreIcon } from 'assets/icons/icon-flash-score.svg';
import {
  EditorFormatButton,
  EditorFormatButtonProps,
  EditorIconFormatButton
} from 'features/aiWriter/AiWriterTextEditor/EditorIconFormatButton';
import { trackEditorFormatAction } from 'features/aiWriter/AiWriterTextEditor/trackEditorFormatAction';
import { getIconColor } from 'features/aiWriter/AiWriterTextEditor/utils/getIconColor';
import { enableInspirationsPanel } from 'features/aiWriter/store/actions/builder/actions';
import { setInspirationsCurrentStep } from 'features/aiWriter/store/actions/config/actions';
import { AiWriterSidebarStep } from 'features/aiWriter/store/types';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { IntlMessageKeys } from 'features/i18n/IntlMessageKeys';
import { isEqual } from 'lodash';
import { ComponentProps, forwardRef } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';

type Props = ComponentProps<typeof EditorIconFormatButton> & {
  styleVariant?: EditorFormatButtonProps;
  onClick?: () => void;
  isActive?: boolean;
  tooltipText?: IntlMessageKeys;
};

export const FlashScoreButton = forwardRef<HTMLButtonElement, Props>(
  ({ onClick, tooltipText, styleVariant, isActive, ...props }, ref) => {
    const dispatch = useAppDispatch();

    const inspirationsCurrentStep = useAppSelector(state => state.aiWriter.inspirationsCurrentStep);

    const calculatedIsActive =
      isActive !== undefined
        ? isActive
        : isEqual(inspirationsCurrentStep, {
            step: AiWriterSidebarStep.flashScore,
            subStep: 'main'
          });

    const handleClick = () => {
      trackEditorFormatAction('flashScore');

      dispatch(enableInspirationsPanel());
      dispatch(
        setInspirationsCurrentStep({ step: AiWriterSidebarStep.flashScore, subStep: 'main' })
      );
    };

    if (styleVariant === 'icon') {
      return (
        <EditorIconFormatButton
          ref={ref}
          {...props}
          onClick={onClick ?? handleClick}
          $isActive={calculatedIsActive}
        >
          <Tooltip
            arrow={true}
            title={<FormattedMessage id={tooltipText ?? 'aiWriter.toolbar.flashScore'} />}
            placement="bottom"
          >
            <SvgIcon color={getIconColor(calculatedIsActive)} fontSize="small">
              <FlashScoreIcon />
            </SvgIcon>
          </Tooltip>
        </EditorIconFormatButton>
      );
    }

    return (
      <EditorFormatButton
        ref={ref}
        {...props}
        onClick={onClick ?? handleClick}
        $isActive={calculatedIsActive}
      >
        <SvgIcon color={getIconColor(calculatedIsActive)} fontSize="small">
          <FlashScoreIcon />
        </SvgIcon>
        <FormattedMessage id="aiWriter.toolbar.flashScore" />
      </EditorFormatButton>
    );
  }
);
