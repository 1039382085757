import { rgba } from 'polished';
import { borderRadiusPalette } from 'styles/new-theme/borderRadiusPalette';
import { darkModeColorPalette } from 'styles/new-theme/darkModeColorPalette';
import { NewThemeConfig, newThemeConfig } from 'styles/new-theme/newThemeConfig';
import { spacingPalette } from 'styles/new-theme/spacingPalette';
import { typographyPalette } from 'styles/new-theme/typographyPalette';

export const newThemeConfigDarkMode: NewThemeConfig = {
  ...newThemeConfig,

  colors: {
    palette: darkModeColorPalette,

    primary__50: darkModeColorPalette.blue__50,
    primary__100: darkModeColorPalette.blue__100,
    primary__200: darkModeColorPalette.blue__200,
    primary__300: darkModeColorPalette.blue__300,
    primary__400: darkModeColorPalette.blue__400,
    primary__500main: darkModeColorPalette.blue__500main,
    primary__600: darkModeColorPalette.blue__600,
    primary__700: darkModeColorPalette.blue__700,
    primary__800: darkModeColorPalette.blue__800,
    primary__900: darkModeColorPalette.blue__900,

    secondary__50: darkModeColorPalette.green__50,
    secondary__100: darkModeColorPalette.green__100,
    secondary__200: darkModeColorPalette.green__200,
    secondary__300: darkModeColorPalette.green__300,
    secondary__400: darkModeColorPalette.green__400,
    secondary__500main: darkModeColorPalette.green__500main,
    secondary__600: darkModeColorPalette.green__600,
    secondary__700: darkModeColorPalette.green__700,
    secondary__800: darkModeColorPalette.green__800,
    secondary__900: darkModeColorPalette.green__900,

    primaryColorContrast: darkModeColorPalette.white,
    primaryColorDark: darkModeColorPalette.blue__700,
    primaryColorFocus: rgba(darkModeColorPalette.blue__500main, 0.12),
    primaryColorFocusvisible: rgba(darkModeColorPalette.blue__500main, 0.3),
    primaryColorHover: rgba(darkModeColorPalette.blue__500main, 0.08),
    primaryColorLight: darkModeColorPalette.blue__300,
    primaryColorMain: darkModeColorPalette.blue__500main,
    primaryColorOutlinedborder: rgba(darkModeColorPalette.blue__500main, 0.5),
    primaryColorSelected: rgba(darkModeColorPalette.blue__500main, 0.16),

    secondaryColorContrast: darkModeColorPalette.white,
    secondaryColorDark: darkModeColorPalette.green__700,
    secondaryColorFocus: rgba(darkModeColorPalette.green__500main, 0.2),
    secondaryColorFocusvisible: rgba(darkModeColorPalette.green__500main, 0.3),
    secondaryColorHover: rgba(darkModeColorPalette.green__500main, 0.08),
    secondaryColorLight: darkModeColorPalette.green__300,
    secondaryColorMain: darkModeColorPalette.green__500main,
    secondaryColorOutlinedborder: rgba(darkModeColorPalette.green__500main, 0.5),
    secondaryColorSelected: rgba(darkModeColorPalette.green__500main, 0.16),

    nativeScrollbarBg: darkModeColorPalette.dark__200,

    accentOrangeContrast: darkModeColorPalette.white,
    accentOrangeDark: darkModeColorPalette.orange__700,
    accentOrangeFocus: rgba(darkModeColorPalette.orange__500main, 0.2),
    accentOrangeFocusvisible: rgba(darkModeColorPalette.orange__500main, 0.3),
    accentOrangeHover: rgba(darkModeColorPalette.orange__500main, 0.08),
    accentOrangeLight: darkModeColorPalette.orange__200,
    accentOrangeMain: darkModeColorPalette.orange__500main,
    accentOrangeOutlinedborder: rgba(darkModeColorPalette.orange__500main, 0.5),
    accentOrangeSelected: rgba(darkModeColorPalette.orange__500main, 0.16),

    accentRedContrast: darkModeColorPalette.white,
    accentRedDark: darkModeColorPalette.red__800,
    accentRedFocus: rgba(darkModeColorPalette.red__500main, 0.2),
    accentRedFocusvisible: rgba(darkModeColorPalette.red__500main, 0.3),
    accentRedHover: rgba(darkModeColorPalette.red__500main, 0.08),
    accentRedLight: darkModeColorPalette.red__300,
    accentRedMain: darkModeColorPalette.red__500main,
    accentRedOutlinedborder: rgba(darkModeColorPalette.red__500main, 0.5),
    accentRedSelected: rgba(darkModeColorPalette.red__500main, 0.16),

    accentRoseContrast: darkModeColorPalette.white,
    accentRoseDark: darkModeColorPalette.pinkRose__700,
    accentRoseFocus: rgba(darkModeColorPalette.pinkRose__500main, 0.2),
    accentRoseFocusvisible: rgba(darkModeColorPalette.pinkRose__500main, 0.3),
    accentRoseHover: rgba(darkModeColorPalette.pinkRose__500main, 0.2),
    accentRoseLight: darkModeColorPalette.pinkRose__200,
    accentRoseMain: darkModeColorPalette.pinkRose__500main,
    accentRoseOutlinedborder: rgba(darkModeColorPalette.pinkRose__500main, 0.5),
    accentRoseSelected: rgba(darkModeColorPalette.pinkRose__500main, 0.16),

    accentYellowContrast: darkModeColorPalette.white,
    accentYellowDark: darkModeColorPalette.yellow__800,
    accentYellowFocus: rgba(darkModeColorPalette.yellow__500main, 0.2),
    accentYellowFocusvisible: rgba(darkModeColorPalette.yellow__500main, 0.3),
    accentYellowHover: rgba(darkModeColorPalette.yellow__500main, 0.08),
    accentYellowLight: darkModeColorPalette.yellow__200,
    accentYellowMain: darkModeColorPalette.yellow__500main,
    accentYellowOutlinedborder: rgba(darkModeColorPalette.yellow__500main, 0.5),
    accentYellowSelected: rgba(darkModeColorPalette.yellow__500main, 0.16),

    actionActive: rgba(darkModeColorPalette.dark__900main, 0.7),
    actionAvailable: rgba(darkModeColorPalette.dark__900main, 0.5),
    actionDisabled: rgba(darkModeColorPalette.dark__900main, 0.38),
    actionDisabledbackground: rgba(darkModeColorPalette.dark__900main, 0.12),
    actionFocus: rgba(darkModeColorPalette.dark__900main, 0.12),
    actionHover: rgba(darkModeColorPalette.dark__900main, 0.04),
    actionSelected: rgba(darkModeColorPalette.dark__900main, 0.08),

    backgroundDefault: darkModeColorPalette.white,
    backgroundPaperElevation0: darkModeColorPalette.white,
    backgroundPaperElevation1: darkModeColorPalette.white,
    backgroundPaperElevation10: darkModeColorPalette.white,
    backgroundPaperElevation11: darkModeColorPalette.white,
    backgroundPaperElevation12: darkModeColorPalette.white,
    backgroundPaperElevation13: darkModeColorPalette.white,
    backgroundPaperElevation14: darkModeColorPalette.white,
    backgroundPaperElevation15: darkModeColorPalette.white,
    backgroundPaperElevation16: darkModeColorPalette.white,
    backgroundPaperElevation17: darkModeColorPalette.white,
    backgroundPaperElevation18: darkModeColorPalette.white,
    backgroundPaperElevation19: darkModeColorPalette.white,
    backgroundPaperElevation2: darkModeColorPalette.white,
    backgroundPaperElevation20: darkModeColorPalette.white,
    backgroundPaperElevation21: darkModeColorPalette.white,
    backgroundPaperElevation22: darkModeColorPalette.white,
    backgroundPaperElevation23: darkModeColorPalette.white,
    backgroundPaperElevation24: darkModeColorPalette.white,
    backgroundPaperElevation3: darkModeColorPalette.white,
    backgroundPaperElevation4: darkModeColorPalette.white,
    backgroundPaperElevation5: darkModeColorPalette.white,
    backgroundPaperElevation6: darkModeColorPalette.white,
    backgroundPaperElevation7: darkModeColorPalette.white,
    backgroundPaperElevation8: darkModeColorPalette.white,
    backgroundPaperElevation9: darkModeColorPalette.white,

    commonBlackFocus: rgba(darkModeColorPalette.dark__900main, 0.12),
    commonBlackFocusvisible: rgba(darkModeColorPalette.dark__900main, 0.3),
    commonBlackHover: rgba(darkModeColorPalette.dark__900main, 0.04),
    commonBlackMain: darkModeColorPalette.dark__900main,
    commonBlackOutlinedborder: rgba(darkModeColorPalette.dark__900main, 0.5),
    commonBlackSelected: rgba(darkModeColorPalette.dark__900main, 0.08),

    commonBrandColorsBrandBeige: darkModeColorPalette.beige__100main,
    commonBrandColorsBrandBlue: darkModeColorPalette.blue__500main,
    commonBrandColorsBrandDark: darkModeColorPalette.dark__900main,
    commonBrandColorsBrandGreen: darkModeColorPalette.green__500main,
    commonBrandColorsBrandOrange: darkModeColorPalette.orange__500main,
    commonBrandColorsBrandRed: darkModeColorPalette.red__500main,
    commonBrandColorsBrandRose: darkModeColorPalette.pinkRose__500main,

    commonWhiteFocus: rgba(darkModeColorPalette.white, 0.12),
    commonWhiteFocusvisible: rgba(darkModeColorPalette.white, 0.3),
    commonWhiteHover: rgba(darkModeColorPalette.white, 0.04),
    commonWhiteMain: darkModeColorPalette.white,
    commonWhiteOutlinedborder: rgba(darkModeColorPalette.white, 0.5),
    commonWhiteSelected: rgba(darkModeColorPalette.white, 0.08),

    componentsAlertErrorBackground: darkModeColorPalette.red__100,
    componentsAlertErrorColor: darkModeColorPalette.red__900,
    componentsAlertInfoBackground: darkModeColorPalette.pinkRose__400,
    componentsAlertInfoColor: darkModeColorPalette.pinkRose__900,
    componentsAlertSuccessBackground: darkModeColorPalette.green__50,
    componentsAlertSuccessColor: darkModeColorPalette.green__700,
    componentsAlertWarningBackground: darkModeColorPalette.yellow__300,
    componentsAlertWarningColor: darkModeColorPalette.orange__900,

    componentsAppbarDefaultfill: darkModeColorPalette.dark__500,
    componentsAvatarFill: darkModeColorPalette.orange__500main,
    componentsBackdropFill: darkModeColorPalette.dark__600,
    componentsBreadcrumbsCollapsefill: darkModeColorPalette.dark__200,

    componentsChipDefaultclosefill: darkModeColorPalette.dark__600,
    componentsChipDefaultenabledborder: darkModeColorPalette.beige__200,
    componentsChipDefaultfocusfill: darkModeColorPalette.beige__300,
    componentsChipDefaulthoverfill: darkModeColorPalette.beige__100main,

    componentsIconActive: '#626262',
    componentsIconInactive: '#a19d9d',

    componentsInputfieldFilledEnabledfill: rgba(darkModeColorPalette.beige__100main, 0.3),
    componentsInputfieldFilledHoverfill: rgba(darkModeColorPalette.dark__900main, 0.09),
    componentsInputfieldOutlinedEnabledborder: rgba(darkModeColorPalette.dark__900main, 0.23),
    componentsInputfieldOutlinedHoverborder: darkModeColorPalette.black,
    componentsInputfieldStandardEnabledborder: darkModeColorPalette.dark__900main,
    componentsInputfieldStandardHoverborder: darkModeColorPalette.black,

    componentsRatingActivefill: darkModeColorPalette.orange__300,
    componentsRatingEnabledborder: rgba(darkModeColorPalette.dark__900main, 0.23),
    componentsSnackbarFill: darkModeColorPalette.pinkRose__500main,
    componentsStepperConnector: darkModeColorPalette.dark__500,
    componentsSwitchKnobfillenabled: darkModeColorPalette.dark__600,
    componentsSwitchKnowfilldisabled: darkModeColorPalette.dark__500,
    componentsSwitchSlidefill: darkModeColorPalette.black,
    componentsTooltipFill: darkModeColorPalette.dark__600,

    contrast: darkModeColorPalette.black,
    dark: darkModeColorPalette.orange__900,
    divider: rgba(darkModeColorPalette.dark__900main, 0.12),
    elevationOutlined: darkModeColorPalette.dark__500,

    errorContrast: darkModeColorPalette.white,
    errorDark: darkModeColorPalette.red__900,
    errorFocusvisible: rgba('#d32f2f', 0.3),
    errorHover: rgba('#d32f2f', 0.04),
    errorLight: darkModeColorPalette.red__400,
    errorMain: darkModeColorPalette.red__700,
    errorOutlinedborder: rgba('#d32f2f', 0.5),
    errorSelected: rgba('#d32f2f', 0.08),

    focus: '#fc7924',
    focusvisible: '#fc7924',
    hover: '#fc7924',

    infoContrast: darkModeColorPalette.white,
    infoDark: darkModeColorPalette.pinkRose__900,
    infoFocusvisible: rgba(darkModeColorPalette.pinkRose__400, 0.3),
    infoHover: rgba(darkModeColorPalette.pinkRose__400, 0.08),
    infoLight: darkModeColorPalette.pinkRose__400,
    infoMain: darkModeColorPalette.pinkRose__500main,
    infoOutlinedborder: rgba(darkModeColorPalette.pinkRose__400, 0.5),
    infoSelected: rgba(darkModeColorPalette.pinkRose__400, 0.16),

    light: darkModeColorPalette.orange__100,
    main: darkModeColorPalette.orange__500main,

    outlinedborder: '#fc7924',

    selected: '#fc7924',

    successContrast: darkModeColorPalette.white,
    successDark: darkModeColorPalette.green__900,
    successFocusvisible: rgba('#58a6ea', 0.3),
    successHover: rgba('#58a6ea', 0.04),
    successLight: darkModeColorPalette.green__50,
    successMain: darkModeColorPalette.green__400,
    successOutlinedborder: rgba('#58a6ea', 0.5),
    successSelected: rgba('#58a6ea', 0.08),

    textDisabled: darkModeColorPalette.dark__400,
    textFocus: darkModeColorPalette.dark__400,
    textFocusvisible: darkModeColorPalette.dark__500,
    textHover: rgba(darkModeColorPalette.dark__900main, 0.04),
    textPrimary: darkModeColorPalette.dark__900main,
    textSecondary: darkModeColorPalette.dark__700,
    textSelected: rgba(darkModeColorPalette.dark__900main, 0.08),

    warningContrast: darkModeColorPalette.white,
    warningDark: darkModeColorPalette.orange__900,
    warningFocusvisible: rgba(darkModeColorPalette.orange__500main, 0.3),
    warningHover: rgba(darkModeColorPalette.orange__500main, 0.04),
    warningLight: darkModeColorPalette.yellow__200,
    warningMain: darkModeColorPalette.orange__400,
    warningOutlinedborder: rgba(darkModeColorPalette.orange__500main, 0.5),
    warningSelected: rgba(darkModeColorPalette.orange__500main, 0.08)
  },

  typography: {
    ...typographyPalette
  },
  /**
   * @deprecated Please us the typography property to have access to more than just font-size
   */
  fontSizes: {
    h1: typographyPalette.h1.fontSize,
    h2: typographyPalette.h2.fontSize,
    h3: typographyPalette.h3.fontSize,
    h4: typographyPalette.h4.fontSize,
    h5: typographyPalette.h5.fontSize,
    h6: typographyPalette.h6.fontSize,
    body1: typographyPalette.body1.fontSize,
    body1Bold: typographyPalette.body1Bold.fontSize,
    body2: typographyPalette.body2.fontSize,
    subtitle1: typographyPalette.subtitle1.fontSize,
    subtitle2: typographyPalette.subtitle2.fontSize,
    overline: typographyPalette.overline.fontSize,
    caption: typographyPalette.caption.fontSize
  },
  spacings: {
    ...spacingPalette,

    /**
     * @deprecated Use levels (like 1, 2, ... 12) from `spacing` instead
     */
    small: spacingPalette.one,
    /**
     * @deprecated Use levels (like 1, 2, ... 12) from `spacing` instead
     */
    medium: spacingPalette.two,
    /**
     * @deprecated Use levels (like 1, 2, ... 12) from `spacing` instead
     */
    large: spacingPalette.three,
    /**
     * @deprecated Use levels (like 1, 2, ... 12) from `spacing` instead
     */
    extraLarge: spacingPalette.four
  },
  borderRadius: {
    ...borderRadiusPalette,

    /**
     * @deprecated Use levels (like 1, 2, ... 12) from `borderRadius` instead
     */
    none: borderRadiusPalette.five,
    /**
     * @deprecated Use levels (like 1, 2, ... 12) from `borderRadius` instead
     */
    small: borderRadiusPalette.four,
    /**
     * @deprecated Use levels (like 1, 2, ... 12) from `borderRadius` instead
     */
    medium: borderRadiusPalette.three,
    /**
     * @deprecated Use levels (like 1, 2, ... 12) from `borderRadius` instead
     */
    large: borderRadiusPalette.two,
    /**
     * @deprecated Use levels (like 1, 2, ... 12) from `borderRadius` instead
     */
    extraLarge: borderRadiusPalette.one,

    componentButton: borderRadiusPalette.one,
    componentMenu: borderRadiusPalette.three,
    componentCard: borderRadiusPalette.one,
    componentChip: borderRadiusPalette.one,
    componentContainer: borderRadiusPalette.one,
    componentTextField: borderRadiusPalette.two,
    componentSelectField: borderRadiusPalette.one,
    componentAlert: borderRadiusPalette.one,
    componentTab: borderRadiusPalette.four,
    componentTooltip: borderRadiusPalette.three
  },
  shadow: {
    card: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    popOver:
      '0 2px 4px rgba(0, 0, 0, 0.14), 0 4px 5px rgba(0, 0, 0, 0.12), 0 1px 10px rgba(0, 0, 0, 0.2)',
    textSuggestionHover:
      '0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)',
    elevation2:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)',
    elevation6:
      '0px 1px 18px 0px rgba(0, 0, 0, 0.12), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 3px 5px -1px rgba(0, 0, 0, 0.20)',
    inviteFriendCard: `0px 2px 4px 0px ${rgba(
      darkModeColorPalette.blue__500main,
      0.3
    )}, 0px 1px 20px 0px ${rgba(darkModeColorPalette.blue__500main, 0.3)}`
  }
};
