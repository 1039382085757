import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  CircularProgress,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import CountryFlag from 'components/base/CountryFlag';
import Toast from 'components/toasts/Toast';
import { getRoutePath } from 'config/routes';
import { formatDistanceToNow } from 'date-fns';
import { predefineMessageDraft } from 'features/aiWriter/AiWriterSidebar/steps/chat/chatStore';
import { useDeleteTemplateMutation } from 'features/aiWriter/commandTemplates/useDeleteTemplate';
import { useShowTemplatesModal } from 'features/aiWriter/commandTemplates/useShowTemplatesModal';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { languageToDateFnsLocaleMapper } from 'features/language/languageToDateFnsLocaleMapper';
import { getCurrentLanguage } from 'features/language/store/selectors';
import { useCreateNewChatProject } from 'pages/brand-hub/useCreateNewChatProject';
import { useGetInitGptModel } from 'pages/brand-hub/useGetInitGptModel';
import { ChangeEvent } from 'react';
import { useNavigate } from 'react-router';
import { GptModel } from 'services/backofficeIntegration/http/endpoints/aiWriter/httpCreateConversation';
import {
  invalidateCommandTemplatesQuery,
  TemplateDto
} from 'services/backofficeIntegration/http/endpoints/textGeneration/commandTemplates/httpGetCommandTemplates';
import { GAEvents } from 'services/tracking/GAEvents';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import useDropdown from 'utils/hooks/useDropdown';

type Props = {
  templates: TemplateDto[];
  page: number;
  limit: number;
  total: number;
  onChangePage: (_event: unknown | null, page: number) => void;
  onChangeRowsPerPage: (event: ChangeEvent<HTMLInputElement>) => void;
};

export function BrandHubTemplatesTable({
  templates,
  page,
  limit,
  total,
  onChangePage,
  onChangeRowsPerPage
}: Props) {
  const appLanguage = useAppSelector(getCurrentLanguage);

  const showTemplatesModal = useShowTemplatesModal();
  const createNewChatProject = useCreateNewChatProject();

  const initGptModel = useGetInitGptModel();

  const navigate = useNavigate();

  async function handleTemplateSelect(template: TemplateDto) {
    predefineMessageDraft({
      text: template.template,
      gptModel: initGptModel,
      personalityId: null
    });

    await navigate(getRoutePath('aiWriter'));

    await createNewChatProject();
    GAEvents.promptInBrandHubSent();
  }

  function handleClick(event: React.MouseEvent<HTMLTableRowElement>, template: TemplateDto) {
    event.stopPropagation();
    event.preventDefault();

    showTemplatesModal({
      closeOnBackClick: true,
      preselectedView: 'customization',
      templateToEdit: template,
      onTemplateSelect: handleTemplateSelect
    });
    GAEvents.promptInBrandHubOpened();
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="subtitle1">
                <FormattedMessage id="flashHub.templates.table.name" />
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle1">
                <FormattedMessage id="flashHub.templates.table.category" />
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle1">
                <FormattedMessage id="flashHub.templates.table.language" />
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle1">
                <FormattedMessage id="flashHub.templates.table.author" />
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle1">
                <FormattedMessage id="flashHub.templates.table.date" />
              </Typography>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {templates.map(template => (
            <ClickableRow key={template.id} hover onClick={event => handleClick(event, template)}>
              <TableCell>{template.title}</TableCell>
              <TableCell>{template.category_name}</TableCell>
              <TableCell>
                <StyledFlag
                  language={template.language}
                  country={countryDtsMapper(template.country)}
                />
              </TableCell>
              <TableCell>{template.source}</TableCell>
              <TableCell>
                {formatDistanceToNow(new Date(template.updated_at), {
                  addSuffix: true,
                  locale: languageToDateFnsLocaleMapper(appLanguage)
                })}
              </TableCell>
              <TableCell>
                <TemplateMenu template={template} initGptModel={initGptModel} />
              </TableCell>
            </ClickableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        count={total}
        page={page}
        rowsPerPage={limit}
        onPageChange={onChangePage}
        rowsPerPageOptions={[5, 10, 25]}
        onRowsPerPageChange={onChangeRowsPerPage}
        component="div"
      />
    </TableContainer>
  );
}

const countryDtsMapper = (country: string) => {
  if (country === 'gb') {
    return 'uk';
  }

  return country;
};

const ClickableRow = styled(TableRow)`
  cursor: pointer;
`;

const StyledFlag = styled(CountryFlag)`
  width: 1.5rem;
  height: 1.125rem;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  margin-right: ${({ theme }) => theme.spacings.xsmall};
`;

type TemplateMenuProps = {
  template: TemplateDto;
  initGptModel: GptModel;
};

function TemplateMenu({ template, initGptModel }: TemplateMenuProps) {
  const { anchorElement, isOpen, open, close } = useDropdown();

  const { mutate: deleteTemplate, isLoading: isDeleting } = useDeleteTemplateMutation();

  const customerId = useAppSelector(state => state.customer.id);
  const isOwned = template.author_id === customerId;

  const showTemplatesModal = useShowTemplatesModal();

  const createNewChatProject = useCreateNewChatProject();

  const navigate = useNavigate();

  const handleTemplateSelect = async () => {
    predefineMessageDraft({
      text: template.template,
      gptModel: initGptModel,
      personalityId: null
    });

    await navigate(getRoutePath('aiWriter'));

    await createNewChatProject();
  };

  const handleOptionsClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    open(e);
  };

  const handleDeleteClick = (e: React.MouseEvent<HTMLLIElement>) => {
    e.stopPropagation();

    deleteTemplate(
      { commandTemplateId: template.id },
      {
        onSuccess: () => {
          invalidateCommandTemplatesQuery();
        },
        onError: () => {
          Toast.apiError();
        }
      }
    );
    close();
  };

  const handleEditClick = (e: React.MouseEvent<HTMLLIElement>) => {
    e.stopPropagation();
    close();

    showTemplatesModal({
      closeOnBackClick: true,
      preselectedView: 'edit',
      templateToEdit: template
    });
  };

  const handleAddClick = async () => {
    close();

    if (!isOwned) {
      return;
    }

    showTemplatesModal({
      closeOnBackClick: true,
      preselectedView: 'customization',
      templateToEdit: template,
      onTemplateSelect: handleTemplateSelect
    });
  };

  const isLoading = isDeleting;

  if (isLoading) {
    return <CircularProgress />;
  }

  const handleCloseMenu = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
    close();
  };

  return (
    <>
      <IconButton onClick={handleOptionsClick}>
        <MoreVertIcon />
      </IconButton>

      <StyledMenu anchorEl={anchorElement} open={isOpen} onClose={handleCloseMenu}>
        <MenuItem onClick={handleAddClick}>
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          <FormattedMessage id="flashHub.templates.table.more.add" />
        </MenuItem>
        {isOwned && [
          <MenuItem key="edit" onClick={handleEditClick}>
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            <FormattedMessage id="common.edit" />
          </MenuItem>,
          <MenuItem key="delete" onClick={handleDeleteClick}>
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <FormattedMessage id="common.delete" />
          </MenuItem>
        ]}
      </StyledMenu>
    </>
  );
}

const StyledMenu = styled(Menu)`
  & .MuiPaper-root {
    width: 13rem;
  }
`;
