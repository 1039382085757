import axios from 'axios';
import LocalStorageKey from 'config/localStorageKey';
import cuid from 'cuid';
import { AdwordsAttributeType } from 'services/api/adwords/types';
import { handleScoreWordsErrors } from 'services/api/wordEmbedding/errors';
import { ScoreWordsRequest } from 'services/api/wordEmbedding/types';
import { AppThunk } from 'store/store';
import { getDimensionWords, prepareMappers } from 'store/utils/dataUtils';
import { getAdwordsLocation } from 'utils/adWordsUtils';
import { reportErrors } from 'utils/reportErrors';
import { defaultGoalConfig } from 'utils/tabUtils';
import { createLookupTable } from 'utils/utils';

import Toast from '../../../components/toasts/Toast';
import AdwordsAPI from '../../../services/api/adwords';
import WordEmbeddingAPI from '../../../services/api/wordEmbedding';
import {
  getEmbeddingModelDataSelector,
  getEmbeddingModelMapperVariablesSelector
} from '../../embeddingModels/store/selectors';
import { getActiveTab } from './selectors';
import { createTab, rateWords } from './slice';
import { ConceptFlashTab, ConceptFlashTabData, RateWordsPayload } from './types';
import { createEmptyTab, getAvailableGraphColoring, prepareExplorerData } from './utils';

type AdwordsParams = {
  seoParams: AdwordsAttributeType[];
  modelCountry: string;
  chartData: ConceptFlashTabData[];
  lookupTable: Record<string, number>;
};

async function getAdwordsParams({
  modelCountry,
  seoParams,
  chartData,
  lookupTable
}: AdwordsParams) {
  try {
    const adwordsScores = await AdwordsAPI.getAttributes({
      words: chartData.slice(0, 100).map(item => item.word),
      attributes: seoParams,
      location: getAdwordsLocation(modelCountry)
    });

    if (adwordsScores.status) {
      const { data } = adwordsScores.data.data;
      if (data.some(result => result.message)) {
        Toast.warning('seo.missing_data');
      }
      data.forEach(item => {
        const wordIndex = lookupTable[item.keyword];
        if (chartData[wordIndex]) {
          chartData[wordIndex] = {
            ...chartData[wordIndex],
            average_cpc: item.averageCpc / 1000000,
            competition: item.competition,
            search_volume: item.searchVolume
          };
        }
      });
    } else {
      if (adwordsScores.message) {
        Toast.error(`adWords.error.${adwordsScores.message}`);
      }
    }
  } catch (error) {
    Toast.warning('seo.error');
    reportErrors('saga', error as Error);
  }
}

const { CancelToken } = axios;
let cancelPrevRequest: (() => void) | undefined;

export const rateWordsThunk =
  ({
    model: newModelId,
    modelMapperId: newModelMapperId,
    goalConfig = defaultGoalConfig,
    wordAttributes = [{ value: 'seed_score' }, { value: 'model_rank_score' }],
    seedWords = [],
    dimensions = [],
    manualDimensions = [],
    filters = [],
    seoParams = [],
    newTab,
    listId
  }: RateWordsPayload): AppThunk =>
  async (dispatch, getState) => {
    if (cancelPrevRequest) {
      cancelPrevRequest();
      dispatch(rateWords.cancel());
    }
    dispatch(rateWords.request());

    let tab: ConceptFlashTab;

    let wordAttributesToSubmit = wordAttributes?.map(attr => attr.value);

    if (newTab) {
      const getMapperVariables = getEmbeddingModelMapperVariablesSelector(getState());
      const modelSupportsValence =
        newModelId && newModelMapperId
          ? getMapperVariables(newModelId, newModelMapperId).includes('valence')
          : false;

      if (!modelSupportsValence && wordAttributesToSubmit?.includes('valence')) {
        wordAttributesToSubmit = wordAttributesToSubmit.filter(i => i !== 'valence');
      }

      tab = createEmptyTab(modelSupportsValence);
    } else {
      tab = getActiveTab(getState());
    }

    const {
      id: currentTabId,
      embeddingModelId: currentTabModelId,
      modelMapperId: currentTabMapperId
    } = tab;
    const embeddingModelId = newModelId || currentTabModelId;
    const modelMapperId = newModelMapperId || currentTabMapperId;
    const { country: modelCountry } = getEmbeddingModelDataSelector(getState())(embeddingModelId);

    const id = !newTab && currentTabId ? currentTabId : cuid();
    const dimensionsPayload = getDimensionWords(dimensions, manualDimensions);
    const requestPayload: ScoreWordsRequest = {
      embeddingModelId,
      dimensions: dimensionsPayload,
      whitelistWords: [],
      mappers: prepareMappers(wordAttributesToSubmit, modelMapperId),
      seedWords,
      whitelistIds: filters,
      goalConfig
    };
    let tabName = seedWords.map(({ word }) => word).join(', ');

    if (listId) {
      const { label, words } = getState().lists.lists[listId];
      requestPayload.seedWords = words;
      requestPayload.whitelistWords = words;
      requestPayload.goalConfig = {
        ...goalConfig,
        seed_score: 0
      };
      tabName = label;
    }

    try {
      const response = await WordEmbeddingAPI.scoreWords(requestPayload, {
        cancelToken: new CancelToken(cancel => {
          cancelPrevRequest = cancel;
        })
      });

      if (response.status) {
        const data = prepareExplorerData(response.data);
        const lookupTable = createLookupTable(data);

        if (seoParams.length) {
          await getAdwordsParams({ seoParams, chartData: data, lookupTable, modelCountry });
        }

        const requestId = response.es_document_id ?? '';
        const selectedRows = data.reduce<string[]>((acc, item, index) => {
          if (item.select) {
            acc.push(`${index}`);
          }
          return acc;
        }, []);

        dispatch(
          rateWords.success({
            ...tab,
            goalConfig: requestPayload.goalConfig,
            seedWords: listId ? [] : seedWords,
            filters: listId ? [] : filters,
            fromList: !!listId,
            isInitialized: true,
            name: tabName,
            listId,
            id,
            data,
            embeddingModelId: embeddingModelId,
            requestId,
            seoParams,
            manualDimensions,
            dimensions,
            lookupTable,
            wordAttributes,
            selectedRows,
            modelMapperId,
            palette: wordAttributesToSubmit?.includes('valence') ? 'redGreen' : 'redBlue',
            graphColoring: getAvailableGraphColoring(wordAttributesToSubmit)
          })
        );

        cancelPrevRequest = undefined;
      } else if (!axios.isCancel(response)) {
        dispatch(rateWords.failure());
        Toast.backendError(handleScoreWordsErrors(response.data.message));
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        dispatch(rateWords.failure());
        Toast.apiError();
        reportErrors('saga', error as Error);
      }
    }
  };

export const createTabThunk = (): AppThunk<void> => (dispatch, getState) => {
  const getMapperVariables = getEmbeddingModelMapperVariablesSelector(getState());
  const embeddingModelId = localStorage.getItem(LocalStorageKey.ExplorerModel) || '';
  const modelMapperId = localStorage.getItem(LocalStorageKey.ExplorerModelMapper) || '';
  const variables = getMapperVariables(embeddingModelId, modelMapperId);

  dispatch(createTab(createEmptyTab(variables.includes('valence'))));
};
