import { ApiCommonErrorCode, ApiErrors } from '../types';
import mkApiErrorMessage from './mkApiErrorMessage';
import { defaultError } from './mkHandleApiErrorMessage';

const mkApiCommonErrors = (errorCode: ApiCommonErrorCode) =>
  mkApiErrorMessage('common', 'every', errorCode);

export const apiCommonErrors: ApiErrors<ApiCommonErrorCode> = {
  ERROR_API_KEY_OR_TOKEN_MISSING: mkApiCommonErrors('ERROR_API_KEY_OR_TOKEN_MISSING'),
  ERROR_VERIFIED_INVALID_SERVICE: mkApiCommonErrors('ERROR_VERIFIED_INVALID_SERVICE'),
  ERROR_API_KEY_DISABLED: mkApiCommonErrors('ERROR_API_KEY_DISABLED'),
  ERROR_API_KEY_INVALID: mkApiCommonErrors('ERROR_API_KEY_INVALID'),
  ERROR_API_KEY_MISSING: mkApiCommonErrors('ERROR_API_KEY_MISSING'),
  ERROR_API_KEY_OR_CODE_MISSING: mkApiCommonErrors('ERROR_API_KEY_OR_CODE_MISSING'),
  ERROR_API_TOKEN_DISABLED: mkApiCommonErrors('ERROR_API_TOKEN_DISABLED'),
  ERROR_API_TOKEN_INVALID: mkApiCommonErrors('ERROR_API_TOKEN_INVALID'),
  ERROR_API_TOKEN_INVALID_SERVICE: mkApiCommonErrors('ERROR_API_TOKEN_INVALID_SERVICE'),
  ERROR_API_TOKEN_MISSING: mkApiCommonErrors('ERROR_API_TOKEN_MISSING'),
  ERROR_FORBIDDEN: mkApiCommonErrors('ERROR_FORBIDDEN'),
  ERROR_PRICING_ENTITIES_LIMIT_ACHIEVED: mkApiCommonErrors('ERROR_PRICING_ENTITIES_LIMIT_ACHIEVED'),
  ERROR_PRICING_REQUESTS_LIMIT_ACHIEVED: mkApiCommonErrors('ERROR_PRICING_REQUESTS_LIMIT_ACHIEVED'),
  ERROR_API_TOKEN_EXPIRED: mkApiCommonErrors('ERROR_API_TOKEN_EXPIRED'),
  MAINTENANCE_MODE: mkApiCommonErrors('MAINTENANCE_MODE'),
  ERROR_SUBSCRIPTION_IS_INVALID: mkApiCommonErrors('ERROR_SUBSCRIPTION_IS_INVALID'),
  ERROR_API_VALIDATION_FAILED: mkApiCommonErrors('ERROR_API_VALIDATION_FAILED'),
  TOO_MANY_REQUEST_ATTEMPTS: mkApiCommonErrors('TOO_MANY_REQUEST_ATTEMPTS'),
  ERROR_SUBSCRIPTION_IS_PAUSED: mkApiCommonErrors('ERROR_SUBSCRIPTION_IS_PAUSED'),
  ERROR_TEMPLATE_NOT_FOUND: mkApiCommonErrors('ERROR_TEMPLATE_NOT_FOUND'),
  ERROR_TEMPLATE_ACCESS_DENIED: mkApiCommonErrors('ERROR_TEMPLATE_ACCESS_DENIED'),
  ERROR_TEMPLATE_ALREADY_LIKED: mkApiCommonErrors('ERROR_TEMPLATE_ALREADY_LIKED'),
  ERROR_TEMPLATE_NOT_LIKED: mkApiCommonErrors('ERROR_TEMPLATE_NOT_LIKED'),
  ERROR_AI_TESTER_PROJECT_NOT_FOUND: mkApiCommonErrors('ERROR_AI_TESTER_PROJECT_NOT_FOUND'),
  ERROR_AI_TESTER_PROJECT_ACCESS_DENIED: mkApiCommonErrors('ERROR_AI_TESTER_PROJECT_ACCESS_DENIED'),
  ERROR_ACCESS_DISABLED: mkApiCommonErrors('ERROR_ACCESS_DISABLED'),
  ERROR_COUPON_IS_REQUIRED: mkApiCommonErrors('ERROR_COUPON_IS_REQUIRED'),
  ERROR_COUPON_NOT_AVAILABLE: mkApiCommonErrors('ERROR_COUPON_NOT_AVAILABLE'),
  ERROR_STRIPE: mkApiCommonErrors('ERROR_STRIPE'),
  ERROR_TOO_MANY_REGISTRATION_REQUEST: mkApiCommonErrors('ERROR_TOO_MANY_REGISTRATION_REQUEST'),
  ERROR_PROMO_CODE_IS_REQUIRED: mkApiCommonErrors('ERROR_PROMO_CODE_IS_REQUIRED'),
  ERROR_NO_SUCH_PROMO_CODE: mkApiCommonErrors('ERROR_NO_SUCH_PROMO_CODE'),
  ERROR_AI_WRITER_PROJECT_NOT_FOUND: mkApiCommonErrors('ERROR_AI_WRITER_PROJECT_NOT_FOUND'),
  ERROR_AI_WRITER_PROJECT_FORBIDDEN: mkApiCommonErrors('ERROR_AI_WRITER_PROJECT_FORBIDDEN'),
  ERROR_TASK_NOT_FOUND: mkApiCommonErrors('ERROR_TASK_NOT_FOUND'),
  ERROR_TASK_LIST_NOT_FOUND: mkApiCommonErrors('ERROR_TASK_LIST_NOT_FOUND'),
  ERROR_FAVORITE_GENERATION_NOT_FOUND: mkApiCommonErrors('ERROR_FAVORITE_GENERATION_NOT_FOUND'),
  ERROR_FAVORITE_GENERATION_FORBIDDEN: mkApiCommonErrors('ERROR_FAVORITE_GENERATION_FORBIDDEN'),
  ERROR_WHITELIST_NOT_FOUND: mkApiCommonErrors('ERROR_WHITELIST_NOT_FOUND'),
  ERROR_SEOBILITY_REQUEST_FAILED: mkApiCommonErrors('ERROR_SEOBILITY_REQUEST_FAILED'),
  ERROR_REFERRAL_REQUEST_FAILED: mkApiCommonErrors('ERROR_REFERRAL_REQUEST_FAILED'),
  ERROR_UNSPLASH_REQUEST_FAILED: mkApiCommonErrors('ERROR_UNSPLASH_REQUEST_FAILED'),
  'BE.team.access_denied': mkApiCommonErrors('BE.team.access_denied'),
  'BE.team.not_found': mkApiCommonErrors('BE.team.not_found'),
  'BE.team.customer_not_found': mkApiCommonErrors('BE.team.customer_not_found'),
  'BE.team.action_already_applied': mkApiCommonErrors('BE.team.action_already_applied'),
  'BE.team.delete_member_failed': mkApiCommonErrors('BE.team.delete_member_failed'),
  'BE.team.invite_already_accepted': mkApiCommonErrors('BE.team.invite_already_accepted')
};

export const apiCommonErrorsFallback = apiCommonErrors;

export const handleApiCommonErrors = (errorCode: string | undefined): string => {
  if (errorCode) {
    return apiCommonErrors[errorCode] ?? defaultError;
  }

  return defaultError;
};
