import refreshIcon from 'assets/icons/icon-refresh-magic-feather-gray.svg';
import { IconButton, IconButtonSize } from 'components/iconButtons/IconButton';
import React from 'react';

type Props = {
  id?: string;
  isLoading?: boolean;
  tooltip?: React.ReactNode;
  size?: IconButtonSize;

  onClick: () => void;
};

export const RefreshIconButton = ({ id, tooltip, isLoading, size = 'large', onClick }: Props) => (
  <IconButton
    id={id}
    onClick={onClick}
    disabled={isLoading}
    isLoading={isLoading}
    tooltip={tooltip}
    icon={<img src={refreshIcon} alt="refresh" />}
    size={size}
  />
);

