import { aiWriterProjectWithShortTextFromApi } from 'features/aiWriter/store/utils/mappers/aiWriterProjectMappers';
import { makeCurrentUserQueryKeyPrefix } from 'features/currentUser/currentUserQueries';
import { ApiAiWriterProjectWithShortText } from 'services/api/aiWriter/types';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { queryEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';
import { PaginatedListSuccessDto } from 'services/backofficeIntegration/http/paginatedListEndpoint';
import { queryClient } from 'utils/ReactQueryClient';

type SuccessDto = PaginatedListSuccessDto<ApiAiWriterProjectWithShortText>;

export type SortByDto = 'updated_at' | 'locale' | 'name';
export type SortDirection = 'asc' | 'desc';

export type PaginatedListParams = {
  offset?: number;
  limit?: number;
  q?: string;
  sort?: SortByDto;
  direction?: SortDirection;
  author_id?: number;
  tag_ids?: number[];
};

export const httpGetProjects = queryEndpoint({
  makeQueryKey: (params: PaginatedListParams) => {
    return [
      ...makeCurrentUserQueryKeyPrefix(),
      'projects',
      'ai-writer',
      ...Object.values(params)
    ] as const;
  },
  callEndpoint: (params: PaginatedListParams) => {
    return backofficeClient.get<SuccessDto>('/projects/ai-writer', { params }).then(response => {
      const data = {
        ...response.data,
        data: response.data?.data.map(aiWriterProjectWithShortTextFromApi)
      };

      return data;
    });
  }
});

export function invalidateProjectsQuery() {
  return queryClient.invalidateQueries({
    queryKey: [...makeCurrentUserQueryKeyPrefix(), 'projects', 'ai-writer']
  });
}
