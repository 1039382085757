import Toast from 'components/toasts/Toast';
import { generateTexts } from 'features/textGenerator/actions/generateTexts';
import { generatedTextsFromGenerateTextOutputEntries } from 'features/textGenerator/utils/generateTextEntryMapper';
import { useGetNTimesByOutputTypeCached } from 'features/textGenerator/utils/useGetPromptsOthers';
import { useCallback } from 'react';

import useAudienceConfig from '../../audiences/hooks/useAudienceConfig';
import { scoreSubjectLines } from '../utils/scoreSubjectLines';

export const useGenerateSubjectLines = ({ audienceId }: { audienceId: number }) => {
  const { model, audienceModelId } = useAudienceConfig({ audienceId });

  const outputType = 'subject_lines';
  const nTimes = useGetNTimesByOutputTypeCached(outputType);

  return useCallback(
    async ({
      emailBody,
      keywords,
      suggestions
    }: {
      emailBody?: string;
      keywords?: string;
      suggestions?: string[];
    }) => {
      try {
        const { outputs } = await generateTexts(
          { audienceModelId, outputType, nTimes },
          {
            text: emailBody,
            keywords,
            suggestions
          }
        );

        const scoredSubjectLines = await scoreSubjectLines({
          inspirations: generatedTextsFromGenerateTextOutputEntries(outputs),
          embeddingModelId: model.id,
          audienceModelId
        });

        return scoredSubjectLines;
      } catch (e) {
        Toast.apiError();
        return Promise.reject();
      }
    },
    [model, audienceModelId, nTimes]
  );
};
