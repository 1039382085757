import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Box, ClickAwayListener } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import { getRoutePath, routesConfig, SideBarData } from 'config/routes';
import { setCurrentTab as setCurrentAiTesterTab } from 'features/aiTester/store/actions/tabs/actions';
import { setCurrentTab as setCurrentAiWriterTab } from 'features/aiWriter/store/actions/tabs/actions';
import { canUpgradePricingPlan, getIsApiCustomer } from 'features/customer/store/selectors';
import { getSideBarFeatures } from 'features/features/store/selectors';
import { SidebarItemAccount } from 'features/pageSidebar/sidebarItems/actions/SidebarItemAccount';
import { SidebarItemHelpCenter } from 'features/pageSidebar/sidebarItems/actions/SidebarItemHelpCenter';
import { SidebarItemNotificationCenter } from 'features/pageSidebar/sidebarItems/actions/SidebarItemNotificationCenter';
import { SidebarItemReferralBonusPage } from 'features/pageSidebar/sidebarItems/actions/SidebarItemReferralBonusPage';
import { SidebarItemUpgradeButton } from 'features/pageSidebar/sidebarItems/actions/SidebarItemUpgradeButton';
import { SidebarLogo } from 'features/pageSidebar/sidebarItems/SidebarLogo';
import { SidebarNavItem } from 'features/pageSidebar/sidebarItems/SidebarNavItem';
import { useSidebarStore } from 'features/pageSidebar/useSidebarStore';
import { TrialExpiryProgressBar } from 'features/pricing/TrialExpiryProgressBar';
import gtmIds from 'services/tracking/GTMIds';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import styled, { css } from 'styled-components';

const drawerWidth = 312;
const drawerWidthMinimized = 64;

export function Sidebar() {
  const dispatch = useAppDispatch();

  const isSidebarExpanded = useSidebarStore(state => state.isExpanded);
  const isSidebarEnabled = useSidebarStore(state => state.isEnabled);
  const collapseSidebar = useSidebarStore(state => state.collapseSidebar);
  const toggleSidebar = useSidebarStore(state => state.toggleSidebar);

  const activeFeatures = useGetActiveFeatures();

  const isApiCustomer = useAppSelector(getIsApiCustomer);
  const showUpgrade = useAppSelector(canUpgradePricingPlan);

  const handleSidebarFeatureClick = (config: SideBarData) => {
    // If the user clicks on ai writer or tester,
    // close the current document and show the document overview
    // TODO: Remove as soon as we've unique URLs per document
    if (config.path === getRoutePath('aiWriter')) {
      dispatch(setCurrentAiWriterTab(''));
    }
    if (config.path === getRoutePath('aiTester')) {
      dispatch(setCurrentAiTesterTab(''));
    }

    collapseSidebar();
  };

  if (!isSidebarEnabled) {
    return null;
  }

  return (
    <ClickAwayListener
      onClickAway={() => {
        if (isSidebarExpanded) {
          collapseSidebar();
        }
      }}
    >
      <Box sx={{ display: 'flex' }}>
        <Drawer
          // Note: This prevents the backdrop from working, only "temporary" drawers supports it
          //       We're using a click away listener instead
          variant="permanent"
          open={isSidebarExpanded}
          $isOpen={isSidebarExpanded}
        >
          <DrawerHeader>
            <SidebarLogo />

            {isSidebarExpanded && (
              <StyledIconButton onClick={toggleSidebar}>
                <ChevronLeftIcon />
              </StyledIconButton>
            )}
          </DrawerHeader>

          <DrawerBody>
            <ScrollableList>
              {activeFeatures.map(config => (
                <SidebarNavItem
                  gtmId={gtmIdsMap[config.path]}
                  key={config.path}
                  to={config.path}
                  icon={config.icon}
                  name={config.title}
                  tooltip={config.title}
                  onClick={() => handleSidebarFeatureClick(config)}
                />
              ))}
            </ScrollableList>

            <ScrollableList>
              {isSidebarExpanded && <TrialExpiryProgressBar />}
              {showUpgrade && <SidebarItemUpgradeButton />}
              {isSidebarExpanded && <SidebarItemReferralBonusPage />}
              {!isApiCustomer && <SidebarItemNotificationCenter />}
              <SidebarItemHelpCenter />
              <SidebarItemAccount />
            </ScrollableList>
          </DrawerBody>
        </Drawer>
      </Box>
    </ClickAwayListener>
  );
}

function useGetActiveFeatures() {
  const features = useAppSelector(getSideBarFeatures);

  const activeFeatures = features.filter((config): config is SideBarData => !!config);

  return activeFeatures;
}

const gtmIdsMap = {
  [routesConfig.aiWriter.path]: gtmIds.sidebar.contentFlash,
  [routesConfig.aiImages.path]: gtmIds.sidebar.imageFlash,
  [routesConfig.aiTester.path]: gtmIds.sidebar.performanceFlash,
  [routesConfig.exploring.path]: gtmIds.sidebar.researchFlash
};

const openedMixin = css`
  width: ${drawerWidth}px;
`;

const closedMixin = css`
  width: calc(${drawerWidthMinimized}px + 1px);
`;

const Drawer = styled(MuiDrawer)<{ $isOpen?: boolean }>`
  width: ${drawerWidth}px;
  flex-shrink: 0;
  white-space: nowrap;
  box-sizing: border-box;

  ${({ $isOpen }) => ($isOpen ? openedMixin : closedMixin)};

  & .MuiDrawer-paper {
    ${({ $isOpen }) => ($isOpen ? openedMixin : closedMixin)};
    transition: width 0.3s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.3s ease-in-out,
      background-color 0.3s ease-in-out;
    will-change: width, box-shadow, background-color;
    overflow-x: hidden;
    // increased to a value higher than toolbar's z-index to ensure sidebar is not ever overlayed by the toolbar
    z-index: 1010;

    padding: ${({ theme }) => theme.spacings.two};
    border-radius: 0;

    border-right-width: 0;
    box-shadow: ${({ theme, $isOpen }) => ($isOpen ? theme.shadow.card : 'none')};
    background-color: ${({ $isOpen, theme }) => ($isOpen ? theme.colors.white : 'transparent')};
  }
`;

const ScrollableList = styled(List)`
  overflow-y: hidden;
  overflow-x: hidden;
  padding: 0;
`;

const DrawerHeader = styled('div')`
  min-height: 48px;
  display: flex;
  align-items: center;
  align-self: stretch;
`;

const DrawerBody = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const StyledIconButton = styled(IconButton)`
  margin-left: auto;
`;
