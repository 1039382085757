import { getRoutePath } from 'config/routes';
import { SidebarNavItem } from 'features/pageSidebar/sidebarItems/SidebarNavItem';
import { useSidebarStore } from 'features/pageSidebar/useSidebarStore';
import React from 'react';
import gtmIds from 'services/tracking/GTMIds';

export const SidebarItemReferralBonusPage = (): React.ReactElement => {
  const collapseSidebar = useSidebarStore(state => state.collapseSidebar);

  return (
    <SidebarNavItem
      id={gtmIds.sidebar.referralBonusPageButton}
      icon={<>🎁</>}
      name="layout.sidebar.actions.bonus_referral_button"
      to={getRoutePath('bonusReferral')}
      onClick={collapseSidebar}
    />
  );
};
