import { insertNodes } from '@udecode/plate-common';
import FlexContainer from 'components/FlexContainer';
import { useAiWriterExpandedSidebarStore } from 'features/aiWriter/AiWriterSidebar/sidebar/aiWriterExpandedSidebarStore';
import { AiWriterSidebarPanel } from 'features/aiWriter/AiWriterSidebar/sidebar/AiWriterSidebarPanel';
import { ExpandSidebarButton } from 'features/aiWriter/AiWriterSidebar/sidebar/ExpandSidebarButton';
import SeoAddAllButton from 'features/aiWriter/AiWriterSidebar/steps/seoAnalysis/common/SeoAddAllButton';
import SeoStatisticsSection from 'features/aiWriter/AiWriterSidebar/steps/seoAnalysis/common/SeoStatisticsSection';
import useReturnToMainSeoStep from 'features/aiWriter/AiWriterSidebar/steps/seoAnalysis/useReturnToMainSeoStep';
import useEditor from 'features/aiWriter/AiWriterTextEditor/hooks/useEditor';
import { updateCurrentProjectInBackgroundThunk } from 'features/aiWriter/store/actions/project/thunks/updateCurrentProjectInBackground';
import { getSeoAnalysisSuggestions } from 'features/aiWriter/store/selectors';
import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const SeoAnalysisStatisticsSubStep = () => {
  const editor = useEditor();
  const dispatch = useAppDispatch();
  
  const isSidebarExpanded = useAiWriterExpandedSidebarStore(state => state.isSidebarExpanded);
  const setIsSidebarExpanded = useAiWriterExpandedSidebarStore(state => state.setIsSidebarExpanded);

  const seoAnalysisSuggestions = useAppSelector(getSeoAnalysisSuggestions);

  const returnToMainSeoStep = useReturnToMainSeoStep();

  const addAllToEditor = useCallback(() => {
    if (!editor) {
      return;
    }

    if (!seoAnalysisSuggestions) {
      return;
    }

    const content = seoAnalysisSuggestions.more.map(suggestion => suggestion.word).join(' ');

    insertNodes(editor, [{ type: 'paragraph', children: [{ text: content }] }], {
      at: [editor.children.length]
    });

    dispatch(updateCurrentProjectInBackgroundThunk());
  }, [editor, seoAnalysisSuggestions, dispatch]);

  if (!seoAnalysisSuggestions) {
    return null;
  }

  return (
    <AiWriterSidebarPanel
      title="aiWriter.inspirations.seoAnalysis.statistics.title"
      onBack={returnToMainSeoStep}
      customButton={
        <ExpandSidebarButton
          isExpanded={isSidebarExpanded}
          onExpand={() => setIsSidebarExpanded(true)}
          onShrink={() => setIsSidebarExpanded(false)}
        />
      }
    >
      <FlexContainer gap="medium">
        <SeoAddAllButton onClick={addAllToEditor} />
        <SeoStatisticsSection
          more={seoAnalysisSuggestions.more}
          less={seoAnalysisSuggestions.less}
          perfect={seoAnalysisSuggestions.ok}
        />
      </FlexContainer>
    </AiWriterSidebarPanel>
  );
};
