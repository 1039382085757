import AudienceAPI from 'services/api/audience';
import { GetAllAudiencesResponse } from 'services/api/audience/types';
import { handleApiCommonErrors } from 'services/api/utils/handleApiCommonErrors';
import { call, put, takeLatest } from 'typed-redux-saga';
import { reportErrors } from 'utils/reportErrors';

import Toast from '../../../components/toasts/Toast';
import { getAudiences } from './slice';

function* getAudiencesSaga() {
  try {
    const response: GetAllAudiencesResponse = yield* call(AudienceAPI.getAll);

    if (response.status) {
      yield* put(getAudiences.success(response.data));
    } else {
      yield* put(getAudiences.failure());
      Toast.backendError(handleApiCommonErrors(response.data.message));
    }
  } catch (error) {
    yield* put(getAudiences.failure());
    Toast.apiError();
    reportErrors('saga', error as Error);
  }
}

export const audiencesSagas = [takeLatest(getAudiences.request.toString(), getAudiencesSaga)];
