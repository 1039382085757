import Toast from 'components/toasts/Toast';
import { hasForbiddenDocumentLanguage } from 'features/aiWriter/AiWriterSidebar/steps/flashScore/useHasForbiddenDocumentLanguage';
import { rewriteTextInspirations } from 'features/aiWriter/store/actions/inspirations/actions';
import { scoreTextInspirations } from 'features/aiWriter/store/actions/inspirations/thunks/scoreTextInspirations';
import { RewriteTextInspirationsPayload } from 'features/aiWriter/store/actions/inspirations/types';
import getAudienceModelById from 'features/audiences/utils/getAudienceModelById';
import { setWordsLimitReached } from 'features/customer/store/actions';
import {
  getGetEmbeddingModelLanguageAndCountryById,
  getGetFirstGenericEmbeddingModelMapperById
} from 'features/embeddingModels/store/selectors';
import { generateTexts } from 'features/textGenerator/actions/generateTexts';
import { getGetPromptConfigByLanguageCountryAndOutputType } from 'features/textGenerator/store/selectors';
import isValidGenerateTextConfig from 'features/textGenerator/store/utils/isValidGenerateTextConfig';
import { generatedTextsFromGenerateTextOutputEntries } from 'features/textGenerator/utils/generateTextEntryMapper';
import { trackingWrapper } from 'features/tracking/wrapper/TrackingWrapper';
import { AppThunk } from 'store/store';
import { getCurrentTab } from 'store/utils/tabUtils';

export const rewriteTextInspirationsThunk =
  (payload: RewriteTextInspirationsPayload): AppThunk<void> =>
  async (dispatch, getState) => {
    const state = getState();
    const { aiWriter, audiences: audiencesState } = state;
    const currentTab = getCurrentTab(aiWriter);

    if (!currentTab) {
      return;
    }

    const { embeddingModelId, generateTextConfig, id: tabId } = currentTab;

    if (!isValidGenerateTextConfig(generateTextConfig)) {
      return;
    }

    const mapper = getGetFirstGenericEmbeddingModelMapperById(state)(embeddingModelId);
    const emotionalityMapperName = mapper?.name ?? 'de_emotions_standard';

    const { audienceId } = generateTextConfig;
    const outputType = 'rewrite';

    const audienceModelId = getAudienceModelById(audiencesState, audienceId);

    if (!audienceModelId) {
      return;
    }

    trackingWrapper.track('aiWriterSidebarTextGeneratorMoreFromSuggestion', {
      documentId: tabId,
      text: payload.text,
      textItemId: payload?.textItemId,
      generationId: payload?.generationId,
      audienceModelId
    });

    dispatch(rewriteTextInspirations.request(payload));

    const { language: modelLanguage, country: modelCountry } =
      getGetEmbeddingModelLanguageAndCountryById(state)(embeddingModelId);
    const promptConfig = getGetPromptConfigByLanguageCountryAndOutputType(state)(
      modelLanguage,
      modelCountry,
      outputType
    );

    if (!promptConfig) {
      dispatch(rewriteTextInspirations.failure({ inspirationId: payload.id, tabId }));
      Toast.commonError();
      return;
    }

    try {
      const { outputs } = await generateTexts(
        { projectId: tabId, audienceModelId, outputType, nTimes: promptConfig.nTimes },
        { text: payload.text },
        {
          onCustomError: [
            'ERROR_ALL_WORDS_USED',
            () => {
              dispatch(setWordsLimitReached(true));
            }
          ]
        }
      );

      const inspirations = generatedTextsFromGenerateTextOutputEntries(outputs);

      // Score all inspirations
      const isFlashScoreSupportedForDocumentLanguage = !hasForbiddenDocumentLanguage(modelLanguage);
      if (isFlashScoreSupportedForDocumentLanguage) {
        const outputScores = await scoreTextInspirations(
          embeddingModelId,
          emotionalityMapperName,
          inspirations
        );
        if (outputScores && outputScores.length > 0) {
          for (let index = 0; index < inspirations.length; index++) {
            inspirations[index].flashScore = outputScores[index];
          }
        }
      }

      dispatch(rewriteTextInspirations.success({ inspirationId: payload.id, tabId, inspirations }));
    } catch (e) {
      dispatch(rewriteTextInspirations.failure({ inspirationId: payload.id, tabId }));
    }
  };
