import { AiWriterProject, AiWriterTab } from '../../types';

export const aiWriterTabFromProject = (project: AiWriterProject): AiWriterTab => ({
  ...project,
  isLoading: {},
  isSaving: false,

  seoConfig: {
    searchEngine: '',
    keyword: ''
  }
});

export const aiWriterTabToProject = (tab: AiWriterTab): AiWriterProject => ({
  ...tab,
  isLoading: false
});
