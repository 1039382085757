import LocalStorageKey from 'config/localStorageKey';
import { allowedPricingPlans } from 'features/aiWriter/chat/GptSelect';
import { getPricingPlan } from 'features/pricing/store/selectors';
import {
  GPT_MODELS,
  GptModel
} from 'services/backofficeIntegration/http/endpoints/aiWriter/httpCreateConversation';
import { useAppSelector } from 'store/hooks';

export function useGetInitGptModel() {
  const storedGptModel =
    (localStorage.getItem(LocalStorageKey.DefaultGptModel) as GptModel) ?? GPT_MODELS.GPT_3_5;
  const pricingPlan = useAppSelector(getPricingPlan);
  const isAllowedToSelectGpt4 = allowedPricingPlans.includes(pricingPlan);
  const initGptModel = isAllowedToSelectGpt4 ? storedGptModel : GPT_MODELS.GPT_3_5;

  return initGptModel;
}
