import { CloseModal } from 'components/modals/types';
import { setInitTextLibraryCategory } from 'features/aiWriter/store/actions/config/actions';
import FormattedMessage from 'features/i18n/FormattedMessage';
import {
  CategoriesList,
  CustomCategories
} from 'features/textGenerator/outputTypeModal/CategoriesList';
import {
  OutputTypeChangeHandler,
  OutputTypesList
} from 'features/textGenerator/outputTypeModal/OutputTypesList';
import { useGetOutputTypeOptionsByLanguageAndCountry } from 'features/textGenerator/utils/useGetPromptsOthers';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import {
  ModalWithDividedHeaderLayout,
  TwoColumnModalBody
} from '../../theme-2024/ModalWithDividedHeaderLayout';

type ModalProps = CloseModal & CustomModalProps;

export type CustomModalProps = {
  initCategory?: string;
  initQuery?: string;
  modelLanguage: string;
  modelCountry: string;
  onOutputTypeChange: OutputTypeChangeHandler;
};

export type Category = {
  id: string;
  label: string;
};

export function useGetCategoriesByLanguageCountry(modelLanguage: string, modelCountry: string) {
  const outputTypeOptions = useGetOutputTypeOptionsByLanguageAndCountry(
    modelLanguage,
    modelCountry
  );
  return Array.from(
    outputTypeOptions
      .reduce((accumulator, { category }) => {
        accumulator.set(category.id, category);
        return accumulator;
      }, new Map<string, Category>())
      .values()
  ).sort((a, b) => a.label.localeCompare(b.label));
}

export function OutputTypeModal(props: ModalProps) {
  const { initQuery, initCategory, closeModal, modelLanguage, modelCountry, onOutputTypeChange } =
    props;

  const [selectedCategory, setSelectedCategory] = useState<string>(
    initCategory ?? CustomCategories.ALL_CATEGORY
  );

  const categories = useGetCategoriesByLanguageCountry(modelLanguage, modelCountry);

  const dispatch = useAppDispatch();
  const initTextLibraryCategory = useAppSelector(state => state.aiWriter.initTextLibraryCategory);

  useEffect(() => {
    if (initTextLibraryCategory) {
      const initCategory = categories.find(category => category.label === initTextLibraryCategory);
      if (initCategory) {
        setSelectedCategory(initCategory.id);
        dispatch(setInitTextLibraryCategory(undefined));
      } else {
        setSelectedCategory(CustomCategories.ALL_CATEGORY);
        dispatch(setInitTextLibraryCategory(undefined));
      }
    }
  }, [categories, dispatch, initTextLibraryCategory, setSelectedCategory]);

  const handleSelectCategory = (categoryId: string) => {
    setSelectedCategory(categoryId);
  };

  return (
    <ModalWithDividedHeaderLayout
      closeModal={closeModal}
      title={<FormattedMessage id="output_type_modal.header" />}
      slots={{
        bodyContainer: TwoColumnModalBody
      }}
      height={740}
    >
      <CategoriesList
        categories={categories}
        selectedCategory={selectedCategory}
        handleSelectCategory={handleSelectCategory}
      />

      <OutputTypesList
        selectedCategory={selectedCategory}
        initQuery={initQuery}
        modelLanguage={modelLanguage}
        modelCountry={modelCountry}
        onOutputTypeChange={type => {
          onOutputTypeChange(type);
          closeModal();
        }}
      />
    </ModalWithDividedHeaderLayout>
  );
}
