import { Chip, IconButton, Tooltip } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import Title from 'features/aiWriter/AiWriterSidebar/AiWriterSidebarPanel/Title';
import { useAiWriterExpandedSidebarStore } from 'features/aiWriter/AiWriterSidebar/sidebar/aiWriterExpandedSidebarStore';
import { AiWriterSidebarPanel } from 'features/aiWriter/AiWriterSidebar/sidebar/AiWriterSidebarPanel';
import { ExpandSidebarButton } from 'features/aiWriter/AiWriterSidebar/sidebar/ExpandSidebarButton';
import { ColoredHelpIcon } from 'features/aiWriter/AiWriterSidebar/steps/extraIcons';
import { FlashScoreAnalyzeView } from 'features/aiWriter/AiWriterSidebar/steps/flashScore/FlashScoreAnalyzeView';
import { FlashScoreFetchingIcon } from 'features/aiWriter/AiWriterSidebar/steps/flashScore/FlashScoreFetchingIcon';
import FormattedMessage from 'features/i18n/FormattedMessage';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

export function FlashScoreStep() {
  const isSidebarExpanded = useAiWriterExpandedSidebarStore(state => state.isSidebarExpanded);
  const setIsSidebarExpanded = useAiWriterExpandedSidebarStore(state => state.setIsSidebarExpanded);

  return (
    <AiWriterSidebarPanel
      renderTitle={() => (
        <FlexContainer direction="row" alignItems="center" gap="small">
          <Title title="aiWriter.inspirations.flashScore.title" />
          <BetaChip label="Beta" size="small" variant="filled" />
        </FlexContainer>
      )}
      infoButton={<InfoButton />}
      customButton={
        <>
          <FlexContainer style={{ marginRight: 4 }}>
            <FlashScoreFetchingIcon />
          </FlexContainer>
          <ExpandSidebarButton
            isExpanded={isSidebarExpanded}
            onExpand={() => setIsSidebarExpanded(true)}
            onShrink={() => setIsSidebarExpanded(false)}
          />
        </>
      }
    >
      <RootLayout>
        <FlashScoreAnalyzeView />
      </RootLayout>
    </AiWriterSidebarPanel>
  );
}

function InfoButton() {
  const translate = useTr();

  return (
    <Tooltip title={<FormattedMessage id="aiWriter.inspirations.flashScore.icon_tooltip_title" />}>
      <IconButton
        component="a"
        size="small"
        target="_blank"
        href={translate('aiWriter.inspirations.flashScore.icon_tooltip_link')}
      >
        <ColoredHelpIcon />
      </IconButton>
    </Tooltip>
  );
}

const RootLayout = styled(FlexContainer).attrs({ gap: 'small' })`
  height: 100%;
`;

const BetaChip = styled(Chip)`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
`;
