import { Link, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import useTr from 'utils/hooks/useTr';

export const TermsOfUse = (): ReactElement => {
  const translate = useTr();

  return (
    <Typography sx={{ marginBottom: '24px' }} variant="body2">
      <FormattedMessage
        id="ai_images.generator_page.terms_of_use"
        values={{
          a: (content: string) => (
            <Link
              href={translate('ai_images.generator_page.terms_of_use.link')}
              target="_blank"
              rel="noreferrer"
            >
              {content}
            </Link>
          )
        }}
      />
    </Typography>
  );
};
