import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { Tab, Tabs, Typography } from '@mui/material';
import { TutorialButton } from 'components/buttons/TutorialButton';
import { ColoredTypography } from 'components/ColoredTypography';
import { RouteConfig, routesConfig, shouldPageBeVisible } from 'config/routes';
import {
  getCustomerRole,
  getCustomerTeamName,
  getCustomerTeams,
  getIsApiCustomer
} from 'features/customer/store/selectors';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { useNavigateToOnboarding } from 'features/onboardingJourney/useNavigateToOnboarding';
import { useEnableSidebarOnMount } from 'features/pageSidebar/useSidebarStore';
import { BrandHubBrandVoiceTab } from 'pages/brand-hub/brandVoiceTab';
import { BrandHubInformationTab } from 'pages/brand-hub/informationTab';
import { BrandHubTemplatesTab } from 'pages/brand-hub/templatesTab';
import { BrandHubWorkflowsTab } from 'pages/brand-hub/workflowsTab';
import { ElementType, SyntheticEvent, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { useCurrentRoutePath } from 'utils/hooks/useCurrentRoutePath';

const brandHubRoutes = {
  workflows: routesConfig.brandHubOverviewWorkflows,
  brandVoice: routesConfig.brandHubOverviewBrandVoice,
  templates: routesConfig.brandHubOverviewTemplates,
  information: routesConfig.brandHubOverviewInformation
} as const;

export function BrandHubOverviewPage() {
  const pathname = useCurrentRoutePath();
  const customerName = useAppSelector(state => state.customer.username);
  const customerGroup = useAppSelector(state => state.customer.groupId);
  const userRole = useAppSelector(getCustomerRole);
  const isApiCustomer = useAppSelector(getIsApiCustomer);
  const customerTeams = useAppSelector(getCustomerTeams);
  const workspaceName = useAppSelector(getCustomerTeamName);

  const [currentRoute, setCurrentRoute] = useState<RouteConfig>(brandHubRoutes.brandVoice);

  useEnableSidebarOnMount();

  useNavigateToOnboarding();

  useEffect(() => {
    if (pathname === currentRoute.path) {
      return;
    }

    const route = Object.values(brandHubRoutes).find(routeConfig => routeConfig.path === pathname);
    if (route) {
      setCurrentRoute(route);
    }
  }, [pathname, currentRoute]);

  const handleChange = (event: SyntheticEvent, newUrl: string) => {
    const route = Object.values(brandHubRoutes).find(routeConfig => routeConfig.path === newUrl);
    if (route) {
      setCurrentRoute(route);
    }
  };

  return (
    <Root>
      <Header>
        <Typography variant="h4">
          <FormattedMessage
            id="flashHub.overview.header.title"
            values={{
              name: workspaceName ?? customerName
            }}
          />
        </Typography>
        <ColoredTypography variant="body1" color="blackMediumEmphasis">
          <FormattedMessage id="flashHub.overview.header.subtitle" />
        </ColoredTypography>
      </Header>

      <TabContext value={currentRoute.path}>
        <Tabs value={currentRoute.path} onChange={handleChange} aria-label="basic tabs example">
          {shouldPageBeVisible(
            brandHubRoutes.workflows,
            customerGroup,
            userRole,
            isApiCustomer,
            customerTeams
          ) && (
            <StyledTab
              label={<FormattedMessage id="flashHub.overview.tab.workflows" />}
              component={Link}
              to={brandHubRoutes.workflows.path}
              value={brandHubRoutes.workflows.path}
            />
          )}
          {shouldPageBeVisible(
            brandHubRoutes.brandVoice,
            customerGroup,
            userRole,
            isApiCustomer,
            customerTeams
          ) && (
            <StyledTab
              label={<FormattedMessage id="flashHub.overview.tab.brandVoice" />}
              component={Link}
              to={brandHubRoutes.brandVoice.path}
              value={brandHubRoutes.brandVoice.path}
            />
          )}
          {shouldPageBeVisible(
            brandHubRoutes.information,
            customerGroup,
            userRole,
            isApiCustomer,
            customerTeams
          ) && (
            <StyledTab
              label={<FormattedMessage id="flashHub.overview.tab.information" />}
              component={Link}
              to={brandHubRoutes.information.path}
              value={brandHubRoutes.information.path}
            />
          )}
          {shouldPageBeVisible(
            brandHubRoutes.templates,
            customerGroup,
            userRole,
            isApiCustomer,
            customerTeams
          ) && (
            <StyledTab
              label={<FormattedMessage id="flashHub.overview.tab.templates" />}
              component={Link}
              to={brandHubRoutes.templates.path}
              value={brandHubRoutes.templates.path}
            />
          )}
        </Tabs>

        <Content>
          <StyledTabPanel value={brandHubRoutes.workflows.path}>
            <BrandHubWorkflowsTab />
          </StyledTabPanel>
          <StyledTabPanel value={brandHubRoutes.brandVoice.path}>
            <BrandHubBrandVoiceTab />
          </StyledTabPanel>
          <StyledTabPanel value={brandHubRoutes.information.path}>
            <BrandHubInformationTab />
          </StyledTabPanel>
          <StyledTabPanel value={brandHubRoutes.templates.path}>
            <BrandHubTemplatesTab />
          </StyledTabPanel>
        </Content>
      </TabContext>

      <TutorialButton videoId="flashHub.overview.video_link" />
    </Root>
  );
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xmedium};
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: ${({ theme }) => theme.spacings.small};

  margin-top: ${({ theme }) => theme.spacings.xmedium};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacings.three};
`;

const StyledTabPanel = styled(TabPanel)`
  width: 100%;
  padding: ${({ theme }) =>
    `${theme.spacings.four} ${theme.spacings.four} ${theme.spacings.four} 0`};
`;

const StyledTab = styled(Tab)<{
  // fix for `component` typing
  // see: https://github.com/mui/material-ui/issues/13921
  component: ElementType;
  to: string;
}>`
  text-transform: none;
`;
