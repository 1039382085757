import { SpinnerDimmer } from 'components/base/SpinnerDimmer';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { ApiCommonErrorCode } from 'services/api/types';
import { handleApiCommonErrors } from 'services/api/utils/handleApiCommonErrors';
import { Whitelist } from 'services/api/whitelist/types';
import styled from 'styled-components';

import Button from '../../components/base/Button';
import Dropdown from '../../components/Dropdown/Dropdown';
import CheckboxField from '../../components/forms/CheckboxField';
import Tooltip from '../../components/tooltips/Tooltip';
import WhitelistAPI from '../../services/api/whitelist';
import useEndpoint from '../../utils/hooks/useEndpoint';

const DropdownContent = styled.div`
  max-height: 20rem;
  overflow: auto;
`;

type Props = {
  modelLanguage: string;
  name: string;
  selectedFilters: number;
  clearFilters: () => void;
};

const Filters = ({ modelLanguage, name, selectedFilters, clearFilters }: Props) => {
  const fetchFilters = useCallback(
    () => WhitelistAPI.getAll({ language: modelLanguage || undefined }),
    [modelLanguage]
  );
  const [filters, isLoading] = useEndpoint<Whitelist[], ApiCommonErrorCode>({
    initialState: [],
    endpoint: fetchFilters,
    mapResponseFn: d => d,
    errorHandler: handleApiCommonErrors
  });

  return (
    <Tooltip
      content={
        <FormattedMessage
          id="exploring.form.filters_selected"
          values={{ selected: selectedFilters }}
        />
      }
      isUntargetable
    >
      <Dropdown
        type="button"
        icon="filter"
        color={selectedFilters !== 0 ? 'azure' : 'secondary'}
        triggerClassName="btn-square"
        position="bottom-start"
        items={
          <div className="py-1 px-3">
            <SpinnerDimmer loader active={isLoading}>
              <DropdownContent>
                {filters.length ? (
                  filters.map(filter => (
                    <CheckboxField
                      rawLabel={filter.label}
                      key={filter.id}
                      name={name}
                      value={`${filter.id}`}
                    />
                  ))
                ) : (
                  <FormattedMessage id="exploring.form.no_filters" />
                )}
              </DropdownContent>
              <Button color="secondary" block onClick={clearFilters}>
                <FormattedMessage id="exploring.form.clear_filters" />
              </Button>
            </SpinnerDimmer>
          </div>
        }
      />
    </Tooltip>
  );
};

export default Filters;
