import { Button, Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { getRoutePath, routesConfig, shouldPageBeVisible } from 'config/routes';
import { updateCurrentProjectInBackgroundThunk } from 'features/aiWriter/store/actions/project/thunks/updateCurrentProjectInBackground';
import { loadTabThunk } from 'features/aiWriter/store/actions/tabs/thunks/loadTabThunk';
import { useGetProjectById } from 'features/aiWriter/useGetProjectById';
import { unnamed } from 'features/aiWriter/utils/unnamed';
import {
  getCustomerRole,
  getCustomerTeams,
  getIsApiCustomer
} from 'features/customer/store/selectors';
import { useGlobalConfettiStore } from 'features/global-confetti/useGlobalConfettiStore';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { useDocumentIdFromRunningWorkflow } from 'features/modular-workflow/runner/useDocumentIdFromRunningWorkflow';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { invalidateModularWorkflowRunData } from 'services/backofficeIntegration/http/endpoints/modularWorkflow/httpGetModularWorkflowRunData';
import { GAEvents } from 'services/tracking/GAEvents';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { useTimeout } from 'utils/hooks/useTimeout';

type Props = {
  runningWorkflowId: number;
  runningWorkflowName: string;
};

export const WorkflowDoneState = ({ runningWorkflowId, runningWorkflowName }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const setIsConfettiActive = useGlobalConfettiStore(state => state.setIsActive);

  const customerGroup = useAppSelector(state => state.customer.groupId);
  const userRole = useAppSelector(getCustomerRole);
  const isApiCustomer = useAppSelector(getIsApiCustomer);
  const customerTeams = useAppSelector(getCustomerTeams);

  const documentId = useDocumentIdFromRunningWorkflow(runningWorkflowId);

  const { isLoading: isFetchingProject, fetchProject } = useGetProjectById();

  // Auto-redirect after 3 seconds
  useTimeout(() => {
    handleOpenDocumentClick();
  }, 3000);

  const handleOpenDocumentClick = async () => {
    if (!documentId || isFetchingProject) {
      return;
    }

    // Load project data and open the document afterwards
    fetchProject(
      { id: documentId },
      {
        onSuccess: project => {
          // This ensures the workflow data is fresh on next visit, which is relevant if the user
          // doesn't reload the page but instantly runs the same workflow again
          invalidateModularWorkflowRunData();

          navigate(getRoutePath('aiWriter'));

          dispatch(
            loadTabThunk({
              ...project,
              name: project.name || unnamed
            })
          );
          dispatch(updateCurrentProjectInBackgroundThunk());
        }
      }
    );
  };

  const handleBackToOverviewClick = () => {
    // Redirect to brand hub, if the user has access to it
    if (
      shouldPageBeVisible(
        routesConfig.brandHubOverviewWorkflows,
        customerGroup,
        userRole,
        isApiCustomer,
        customerTeams
      )
    ) {
      navigate(getRoutePath('brandHubOverviewWorkflows'));
      return;
    }

    navigate(getRoutePath('aiWriter'));
  };

  useEffect(() => {
    setIsConfettiActive(true);

    GAEvents.workflowFinished({
      name: runningWorkflowName
    });

    return () => {
      setIsConfettiActive(false);
    };
  }, [runningWorkflowName, setIsConfettiActive]);

  return (
    <Root>
      <Content>
        <div>
          <Typography fontSize={180} lineHeight={1}>
            🎉
          </Typography>
        </div>
        <div>
          <Typography variant="subtitle2" fontSize="larger" color="black">
            <FormattedMessage id="modular_workflow.runner.finished.done_label" />
          </Typography>
        </div>

        <FlexContainer direction="column" alignItems="center" gap="small">
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenDocumentClick}
            disabled={isFetchingProject}
          >
            <FormattedMessage id="modular_workflow.runner.finished.cta_open_document" />
          </Button>
          <Button variant="text" color="primary" onClick={handleBackToOverviewClick}>
            <FormattedMessage id="modular_workflow.runner.finished.cta_back_to_overview" />
          </Button>
        </FlexContainer>
      </Content>
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  // Horizontal centered doesn't looks nice on higher screens, so we use a larger top space instead
  margin-top: 150px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  width: auto;
  height: auto;
  min-width: 300px;
  min-height: 150px;
  padding: ${({ theme }) => theme.spacings.large};
  border-radius: ${({ theme }) => theme.borderRadius.medium};

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  grid-row-gap: ${({ theme }) => theme.spacings.medium};
  justify-items: center;
`;
