import { aiWriterProjectFromApi } from 'features/aiWriter/store/utils/mappers/aiWriterProjectMappers';
import { makeCurrentUserQueryKeyPrefix } from 'features/currentUser/currentUserQueries';
import { ApiAiWriterProject } from 'services/api/aiWriter/types';
import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { queryEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';
import { queryClient } from 'utils/ReactQueryClient';

type GetProjectByIdProp = {
  id: string;
};

type SuccessDto = BaseSuccessDto<ApiAiWriterProject>;

export const httpGetProjectById = queryEndpoint({
  makeQueryKey: (params: GetProjectByIdProp) => {
    return [
      ...makeCurrentUserQueryKeyPrefix(),
      'projects',
      'ai-writer',
      ...Object.values(params)
    ] as const;
  },
  callEndpoint: ({ id }: GetProjectByIdProp) => {
    return backofficeClient.get<SuccessDto>(`/projects/ai-writer/${id}`).then(response => {
      return aiWriterProjectFromApi(response.data.data);
    });
  }
});

export const invalidateGetProjectByIdQuery = (id: string) => {
  const queryKey = httpGetProjectById.makeQueryKey({ id });
  return queryClient.invalidateQueries({ queryKey });
};
