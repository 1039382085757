import { CircularProgress } from '@mui/material';
import { BusinessPlanCard } from 'components/profile/components/pricing-plan-card/BusinessPlanCard';
import { PricingPlanCard } from 'components/profile/components/pricing-plan-card/PricingPlanCard';
import SepaPaymentFailedHint from 'components/profile/components/pricing-plan-card/SepaPaymentFailedHint';
import SubscriptionInGraceHint from 'components/profile/components/pricing-plan-card/SubscriptionInGraceHint';
import SubscriptionPaymentPendingHint from 'components/profile/components/pricing-plan-card/SubscriptionPaymentPendingHint';
import { useAvailablePlans } from 'features/pricing/hook/useAvailablePlans';
import useIsSepaPaymentFailed from 'features/pricing/hook/useIsSepaPaymentFailed';
import useSubscriptionState from 'features/pricing/hook/useSubscriptionState';
import { isSubscriptionInGrace } from 'features/pricing/utils/isSubscriptionInGrace';
import { isSubscriptionPaymentPending } from 'features/pricing/utils/isSubscriptionPaymentPending';
import { SubscriptionPeriod } from 'services/api/customer/types';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { mobileWindowWidthCSS } from 'utils/hooks/useMobileVersion';

interface Props {
  period: SubscriptionPeriod;
  fromPopupModal?: boolean;
  onScrollToSectionClick?: () => void;
}

export function PricingCardCollection({ period, onScrollToSectionClick }: Props) {
  const { cardBrand, cardLastFour } = useAppSelector(state => state.customer);

  const isBillingInfoPresent = cardBrand !== null && cardLastFour !== null;
  const { currentPricingPlan, currentSubscriptionState } = useSubscriptionState();

  const { data } = useAvailablePlans({ period });

  const { standardPlan, proPlan, businessPlan } = data ?? {};

  const isSepaPaymentFailed = useIsSepaPaymentFailed();

  if (!currentSubscriptionState) {
    return <CircularProgress size={24} />;
  }

  if (!currentPricingPlan) {
    return null;
  }

  return (
    <PricingCardCollectionContainer>
      {isSubscriptionPaymentPending(currentSubscriptionState) && <SubscriptionPaymentPendingHint />}

      {isSubscriptionInGrace(currentSubscriptionState) && <SubscriptionInGraceHint />}

      {isSepaPaymentFailed && <SepaPaymentFailedHint />}

      <PlanCardContainer>
        {standardPlan && (
          <PricingPlanCard
            pricingPlan={standardPlan}
            isBillingInfoPresent={isBillingInfoPresent}
            isActivePlan={currentPricingPlan.id === standardPlan.id}
            subscriptionState={currentSubscriptionState}
            onScrollToSectionClick={onScrollToSectionClick}
          />
        )}
        {proPlan && (
          <PricingPlanCard
            pricingPlan={proPlan}
            isBillingInfoPresent={isBillingInfoPresent}
            isActivePlan={currentPricingPlan.id === proPlan.id}
            subscriptionState={currentSubscriptionState}
            onScrollToSectionClick={onScrollToSectionClick}
          />
        )}
        {businessPlan && (
          <BusinessPlanCard
            pricingPlan={businessPlan}
            isBillingInfoPresent={isBillingInfoPresent}
            isActivePlan={currentPricingPlan.id === businessPlan.id}
            subscriptionState={currentSubscriptionState}
            onScrollToSectionClick={onScrollToSectionClick}
          />
        )}
      </PlanCardContainer>
    </PricingCardCollectionContainer>
  );
}

const PricingCardCollectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const PlanCardContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: ${({ theme }) => theme.spacings.four};
  flex: 1;

  @media (max-width: ${mobileWindowWidthCSS}) {
    flex-direction: column;
  }
`;
