import LogoAnimation from 'assets/loadingAnimation/logo-animation.lottie';
import { HalfMask } from 'components/AppLoader';
import FullMask from 'components/FullMask';
import Toast from 'components/toasts/Toast';
import LocalStorageKey from 'config/localStorageKey';
import { getRoutePath } from 'config/routes';
import { login } from 'features/customer/store/actions';
import { getIsAuthenticated } from 'features/customer/store/selectors';
import { CustomerDataPayload } from 'features/customer/store/types';
import { getUserData, saveUserDataToStorage } from 'features/customer/store/utils';
import { LottieComponent } from 'features/lottie/LottieComponent';
import getFirstFreePlanId from 'features/pricing/utils/getFirstFreePlanId';
import { decodeSocialLoginState, UrlState } from 'features/social/mkSocialLoginState';
import { AnimatePresence } from 'framer-motion';
import posthog from 'posthog-js';
import { ReactElement, useEffect } from 'react';
import { useLocation, useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import adcellClient from 'services/adcell';
import { CustomerData } from 'services/api/customer/types';
import SocialAPI, {
  getSocialProviderRedirectUri,
  isSocialProviderrSupported
} from 'services/api/social';
import { handleSocialErrors } from 'services/api/social/errors';
import firstPromoterClient from 'services/firstPromoterClient';
import useExecuteRecaptcha from 'services/recaptcha/hooks/useRecaptcha';
import { GAEvents } from 'services/tracking/GAEvents';
import {
  TrackingEventLoginAttributes,
  TrackingEventProviderType,
  TrackingEventRegistrationAttributes
} from 'services/tracking/types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import getTimezone from 'utils/getTimezone';
import { parseUrlQuery } from 'utils/urlUtils';

type RouteParams = {
  provider: string;
};

type UrlQuery = {
  code?: string;
  state?: string;
};

const trackRegistrationAndLogin = (
  userData: CustomerDataPayload,
  statusCode: number,
  type: TrackingEventProviderType
) => {
  const eventData: TrackingEventLoginAttributes | TrackingEventRegistrationAttributes = {
    userId: userData.id,
    userEmail: userData.email,
    type,
    pricingId: null
  };

  if (statusCode === 201) {
    GAEvents.userRegistration(eventData);

    posthog?.capture('Sign-up', eventData);

    // Honor promotions
    firstPromoterClient.sendReferralEmail(userData.email);
  }

  // YES, this fall through is correct because after registration the user will be logged in,
  // and we want to track the login event as well
  GAEvents.userLogin(eventData);
};

export const HandleSocialLogin = (): ReactElement => {
  const { provider } = useParams<RouteParams>();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const executeRecaptcha = useExecuteRecaptcha('register');
  const isAuthenticated = useAppSelector(getIsAuthenticated);

  useEffect(() => {
    // Due to timing issues, it is possible that this whole route is remounted
    // during login, so we need to make sure that we do not run this code twice
    if (isAuthenticated) {
      return;
    }

    (async () => {
      if (!location.search || location.search.length === 0) {
        navigate(getRoutePath('login'));
        return;
      }

      if (!provider || !isSocialProviderrSupported(provider)) {
        navigate(getRoutePath('login'));
        return;
      }

      // Getting the code from the query
      const parsedUrlQuery = parseUrlQuery<UrlQuery>(location.search);

      function verifyCsrfState(query: UrlQuery): boolean {
        const state = localStorage.getItem(LocalStorageKey.OauthCsrfState);
        // This solution is not ideal because it works only once
        // however we need to remove the state to make sure that we do not break other social login providers
        // that do not use state
        localStorage.removeItem(LocalStorageKey.OauthCsrfState);
        return !state ? true : query.state === state;
      }

      if (!parsedUrlQuery || !parsedUrlQuery.code || !verifyCsrfState(parsedUrlQuery)) {
        navigate(getRoutePath('login'));
        return;
      }

      // TODO: Should be done in the API as a fallback if no pricing was given
      const firstFreePlanId = await getFirstFreePlanId();
      if (!firstFreePlanId) {
        navigate(getRoutePath('login'));
        return;
      }

      const passedState = parsedUrlQuery.state;
      let decodedSocialLoginState: UrlState | null = null;
      if (passedState && passedState.length > 0) {
        try {
          decodedSocialLoginState = decodeSocialLoginState(passedState);
        } catch {
          // eslint-disable-next-line no-console
          console.error('Invalid state', passedState);
        }
      }

      // Let API resolve the code to a token and create a user
      const response = await SocialAPI.loginWithSocialProvider(provider, parsedUrlQuery.code, {
        timezone: getTimezone(),
        pricing: firstFreePlanId,
        bid: adcellClient.getSavedBid(),
        newsletter_subscribed: decodedSocialLoginState?.newsletterSubscribed ?? false,
        campaign_url: decodedSocialLoginState?.campaignUrl,
        redirectUrl: getSocialProviderRedirectUri(provider)
      });
      if (!response || !response.status) {
        Toast.backendError(handleSocialErrors(response.message));
        navigate(getRoutePath('login'));
        return;
      }

      // TODO: Move into saga
      // Create our customer object
      const userData = getUserData({ ...response.data, tax_id: '', tax_type: '' } as CustomerData);

      // Track registration and or login
      trackRegistrationAndLogin(userData, response.statusCode, provider);

      // Validate using recaptcha
      await executeRecaptcha(userData.id);

      // Required for tracking
      navigate({ search: `?ref=${firstFreePlanId}` });

      // Store data in local storage & redux (this triggers the redirect to our app)
      saveUserDataToStorage(userData, response.data.is_first_login);
      dispatch(login.success(userData));

      if (decodedSocialLoginState?.isPaid) {
        navigate(getRoutePath('socialRegister'));
      }

      if (decodedSocialLoginState?.campaignUrl) {
        navigate(decodedSocialLoginState.campaignUrl);
      }
    })();
    // Only run this once on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AnimatePresence>
      <FullMask
        $backgroundColor="#fff"
        $zIndex={40000}
        key="mask"
        style={{ opacity: 1 }}
        initial={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <HalfMask>
          <LottieComponent src={LogoAnimation} options={{ speed: 1.5 }} />
        </HalfMask>
      </FullMask>
    </AnimatePresence>
  );
};
