import { useSurveyAnswers } from 'features/onboardingJourney/newUserSurvey/useSurveyAnswers';
import { useSurveyQuestions } from 'features/onboardingJourney/newUserSurvey/useSurveyQuestions';

export const useLanguageCountryFromSurveyAnswer = () => {
  const surveyAnswers = useSurveyAnswers();
  const surveyQuestions = useSurveyQuestions();

  // Get the relevant question options
  const languageOptions = surveyQuestions.data?.find(
    question => question.slug === 'preferred_language'
  )?.options;
  // Should not happen, but just in case
  if (!languageOptions || !Array.isArray(languageOptions) || languageOptions.length === 0) {
    return null;
  }

  // Find the question option by user selection
  const selectedLanguageOption = languageOptions.find(
    option => option.option_text === surveyAnswers.data?.preferred_language
  );
  if (!selectedLanguageOption) {
    return null;
  }

  const languageCountryCode = selectedLanguageOption.option_key
    // Format: preferred_language.en_US
    .replace('preferred_language.', '')
    .toLowerCase()
    .split('_');
  if (languageCountryCode.length !== 2) {
    return null;
  }

  return {
    language: languageCountryCode[0],
    country: languageCountryCode[1]
  };
};
