import { Chip } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { range } from 'lodash';
import { useState } from 'react';
import styled from 'styled-components';

type Props = {
  numberOfSuggestions: number;
  onClick: (val: number | null) => void;
};

export const NumberOfSuggestionsChips = ({ numberOfSuggestions, onClick }: Props) => {
  const [selected, setSelected] = useState<number | null>(null);

  const handleClick = (newSuggestionNumber: number) => {
    if (newSuggestionNumber === selected) {
      setSelected(null);
      onClick(null);
      return;
    }

    setSelected(newSuggestionNumber);
    onClick(newSuggestionNumber + 1);
  };

  const isActive = (val: number) => selected === val;

  return (
    <FlexContainer direction="row" gap="small">
      {range(numberOfSuggestions).map(suggestionNumber => (
        <PrimaryChip
          key={suggestionNumber}
          // They are zero-based
          label={suggestionNumber + 1}
          $selected={isActive(suggestionNumber)}
          onClick={() => handleClick(suggestionNumber)}
        />
      ))}
    </FlexContainer>
  );
};

export const PrimaryChip = styled(Chip)<{ $selected: boolean }>`
  color: ${({ $selected, theme }) => $selected && theme.colors.commonWhiteMain};
  background-color: ${({ $selected, theme }) => $selected && theme.colors.primaryColorMain};
`;
