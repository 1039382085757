import { safeEnum } from 'utils/safeEnum';

const UsageIndicatorType = safeEnum.make({
  textGeneration: 'textGeneration',
  chat: 'chat',
  aiImages: 'aiImages',
  unsplashImages: 'unsplashImages',
  plagiarismCheck: 'plagiarismCheck',
  seoAnalysis: 'seoAnalysis',
  gpt4: 'gpt4'
} as const);

type UsageIndicatorType = safeEnum.infer<typeof UsageIndicatorType>;

export { UsageIndicatorType };
