import { MenuItem, Typography } from '@mui/material';
import React from 'react';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled from 'styled-components';

type Props = {
  icon?: React.ReactNode;
  disabled?: boolean;
  selected?: boolean;
  gtmId?: string;
  children: React.ReactNode;

  onClose?: () => void;
  onClick?: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
};

export const MenuDropdownItem = ({
  icon,
  disabled,
  gtmId,
  children,
  onClose,
  onClick,
  selected
}: Props) => {
  return (
    <Root
      {...withGtmInteraction(gtmId)}
      $isDisabled={disabled}
      onClick={
        disabled
          ? undefined
          : e => {
              onClick?.(e);
              onClose?.();
            }
      }
      selected={selected}
    >
      <Content $isDisabled={disabled}>{children}</Content>
      {icon && <Icon>{icon}</Icon>}
    </Root>
  );
};

const Root = styled(MenuItem)<{ $isDisabled?: boolean }>`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 50px;
    padding: ${({ theme }) => `${theme.spacings.small} ${theme.spacings.medium}`};

    cursor: ${({ $isDisabled }) => ($isDisabled ? 'auto' : 'pointer')};
  }
`;

const Content = styled(Typography)<{ $isDisabled?: boolean }>`
  && {
    width: 100%;
    color: ${({ theme }) => theme.colors.blackHighEmphasis};

    opacity: ${({ $isDisabled }) => ($isDisabled ? 0.5 : 1)};
    pointer-events: ${({ $isDisabled }) => ($isDisabled ? 'none' : 'auto')};
  }
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
`;
