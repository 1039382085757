import { getCurrentTabTextWord } from 'features/aiTester/store/utils/getters/getCurrentTabTextWord';
import getAudienceModelFromConfig from 'features/audiences/utils/getAudienceModelFromConfig';
import { generateTexts } from 'features/textGenerator/actions/generateTexts';
import { getGetNTimesByOutputType } from 'features/textGenerator/store/selectors';
import { textsFromGenerateTextOutputEntries } from 'features/textGenerator/utils/generateTextEntryMapper';
import getScoreTextConfig from 'features/textGenerator/utils/getScoreTextConfig';
import WordEmbeddingAPI from 'services/api/wordEmbedding';
import { ScoredWord } from 'services/api/wordEmbedding/types';
import { AppThunk } from 'store/store';

import { getTesterActiveTab } from '../../../selectors';
import { TokenData } from '../../../types';
import { fetchWordSynonyms } from '../actions';
import { FetchWordSynonymsRequestPayload } from '../types';

export const fetchWordSynonymsThunk =
  (payload: FetchWordSynonymsRequestPayload): AppThunk<void> =>
  async (dispatch, getState) => {
    const state = getState();

    const {
      id: tabId,
      embeddingModelId,
      mapperId,
      wordAttributes,
      dimensions,
      manualDimensions,
      generateTextConfig
    } = getTesterActiveTab(state);

    const audienceModelId = getAudienceModelFromConfig(state.audiences, generateTextConfig);

    const word = getCurrentTabTextWord({
      state: state.tester,
      textId: payload.textId,
      wordId: payload.wordId
    })?.word;

    if (audienceModelId && word) {
      dispatch(fetchWordSynonyms.request(payload));

      try {
        const { goalConfig, apiDimensions, mappers } = getScoreTextConfig({
          mapperId,
          wordAttributes,
          dimensions,
          manualDimensions
        });

        const outputType = 'synonyms';
        const nTimes = getGetNTimesByOutputType(state)(outputType);

        const { outputs } = await generateTexts(
          { audienceModelId, outputType, nTimes },
          { keywords: word }
        );

        const scoreWordsResponse = await WordEmbeddingAPI.scoreWords({
          embeddingModelId: embeddingModelId,
          dimensions: apiDimensions,
          mappers,
          goalConfig: {
            ...goalConfig,
            seed_score: 0,
            normalization: 'none'
          },
          seedWords: textsFromGenerateTextOutputEntries(outputs),
          whitelistWords: textsFromGenerateTextOutputEntries(outputs),
          limit: outputs.length
        });

        const tokenData = scoreWordsResponse.status
          ? rateSynonyms({ scoredWords: scoreWordsResponse.data.scored_words })
          : getDefaultSynonymsRating({
              words: textsFromGenerateTextOutputEntries(outputs)
            });

        dispatch(
          fetchWordSynonyms.success({
            tabId,
            synonyms: textsFromGenerateTextOutputEntries(outputs),
            tokenData,
            ...payload
          })
        );
      } catch (e) {
        dispatch(fetchWordSynonyms.failure({ tabId, ...payload }));
      }
    }

    return Promise.resolve();
  };

const rateSynonyms = ({ scoredWords }: { scoredWords: ScoredWord[] }) => {
  return scoredWords.reduce<Record<string, TokenData[]>>((dict, word) => {
    dict[word.word] = createGoalScoreEntry(word.goal_score);

    return dict;
  }, {});
};

const getDefaultSynonymsRating = ({ words }: { words: string[] }) => {
  return words.reduce<Record<string, TokenData[]>>((dict, word) => {
    dict[word] = createGoalScoreEntry(0);

    return dict;
  }, {});
};

const createGoalScoreEntry = (value: number) => [{ id: 'goal_score', label: null, value }];
