import HelpIcon from '@mui/icons-material/Help';
import { Link, Tooltip } from '@mui/material';
import { useModal } from 'components/modals';
import { StyledTooltipLink } from 'features/aiWriter/AiWriterProjectOverview/chatForm/CreateChatForm';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

const GenerateTextExplanation = () => {
  const tr = useTr();

  const { showModal } = useModal();

  function handleClick() {
    showModal('YOUTUBE_VIDEO', {
      size: 1100,
      videoId: tr('generate_text.brief.explanation.video_id')
    });
  }

  return (
    <Tooltip
      title={
        <FormattedMessage
          id="generate_text.brief.explanation"
          values={{
            br: <br />,
            helpcenter: (text: string) => (
              <StyledTooltipLink
                href={tr('generate_text.brief.explanation.helpcenter_link')}
                rel="noreferrer"
                target="_blank"
              >
                {text}
              </StyledTooltipLink>
            ),
            video: (text: string) => <VideoLink onClick={handleClick}>{text}</VideoLink>
          }}
        />
      }
    >
      <InfoIcon fontSize="small" />
    </Tooltip>
  );
};

const InfoIcon = styled(HelpIcon)`
  && {
    color: ${({ theme }) => theme.colors.blackMediumEmphasis};
  }
`;

const VideoLink = styled(Link)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primaryColorLight};
  &:hover {
    color: ${({ theme }) => theme.colors.primaryColorLight};
  }
`;

export default GenerateTextExplanation;
