import { KeyboardArrowLeftRounded, KeyboardArrowRightRounded } from '@mui/icons-material';
import { ReactElement } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { GAEvents } from 'services/tracking/GAEvents';
import styled from 'styled-components';

type Props = {
  children: ReactElement[] | undefined;
};

export const CarouselComponent = ({ children }: Props) => (
  <Carousel
    showThumbs={false}
    showIndicators={false}
    useKeyboardArrows={true}
    showStatus={false}
    renderArrowPrev={(clickHandler, hasPrev) =>
      hasPrev && (
        <StyledLeftArrow
          onClick={() => {
            GAEvents.onboardingSelectionSlidePrevious();
            clickHandler();
          }}
        />
      )
    }
    renderArrowNext={(clickHandler, hasNext) =>
      hasNext && (
        <StyledRightArrow
          onClick={() => {
            GAEvents.onboardingSelectionSlideNext();
            clickHandler();
          }}
        />
      )
    }
  >
    {children}
  </Carousel>
);

const StyledLeftArrow = styled(KeyboardArrowLeftRounded)`
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 1;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 20px;
`;

const StyledRightArrow = styled(KeyboardArrowRightRounded)`
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 1;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 20px;
`;
