import { IconButton, Tooltip } from '@mui/material';
import { PulseDot } from 'components/PulseDot';
import React from 'react';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled from 'styled-components';

type Props = {
  id?: string;
  gtmId?: string;
  icon: string;
  selectedIcon: string;
  isSelected: boolean;
  tooltip?: string;
  isDisabled?: boolean;
  isNewFeature?: boolean;
  style?: React.CSSProperties;

  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const SelectableIconButton = ({
  id,
  gtmId,
  icon,
  selectedIcon,
  isSelected,
  tooltip,
  isDisabled = false,
  isNewFeature = false,
  style,
  onClick
}: Props) => {
  const content = (
    <IconButton
      {...withGtmInteraction(gtmId)}
      id={id} //Deprecated way of gtm tag usage #tech-debt: https://app.clickup.com/t/862je7864
      onClick={onClick}
      disabled={isDisabled}
      style={style}
    >
      {isNewFeature && (
        <PulseDotContainer>
          <PulseDot />
        </PulseDotContainer>
      )}
      {isSelected ? <SelectedImg src={selectedIcon} alt="icon" /> : <img src={icon} alt="icon" />}
    </IconButton>
  );

  return tooltip && !isDisabled ? (
    <Tooltip title={tooltip} placement="left" arrow>
      {content}
    </Tooltip>
  ) : (
    content
  );
};

const PulseDotContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 5%;

  transform: translate(0, -50%);
`;

const SelectedImg = styled.img`
  & > svg {
    fill: ${({ theme }) => theme.colors.commonBrandColorsBrandGreen};
    color: ${({ theme }) => theme.colors.commonBrandColorsBrandGreen};
  }
`;

export default SelectableIconButton;
