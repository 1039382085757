import Toast from 'components/toasts/Toast';
import { updateTermSuggestions } from 'features/aiWriter/store/actions/seoAnalysis/actions';
import { UpdateTermSuggestionsThunkPayload } from 'features/aiWriter/store/actions/seoAnalysis/types';
import { SeoTermSuggestions, TermSuggestion } from 'features/aiWriter/store/types';
import { updateSeoLimitations } from 'features/pricing/store/actions/limitations/actions';
import SeoAnalysisAPI from 'services/api/seoAnalysis';
import { invalidateSeoAnalysisUsageQueries } from 'services/api/seoAnalysis/invalidateSeoAnalysisUsageQueries';
import { handleApiCommonErrors } from 'services/api/utils/handleApiCommonErrors';
import { invalidateCustomerAllLimitationsQueries } from 'services/backofficeIntegration/http/endpoints/customer/httpGetAllLimitations';
import { AppThunk } from 'store/store';
import { getCurrentTab } from 'store/utils/tabUtils';

export const updateTermSuggestionsThunk =
  ({
    wordsAmount,
    keyword,
    searchEngine,
    text,
    countWordOccurrence
  }: UpdateTermSuggestionsThunkPayload): AppThunk<void> =>
  async (dispatch, getState) => {
    const state = getState();

    const tab = getCurrentTab(state.aiWriter);

    if (!tab || !tab.seoAnalysis) {
      return;
    }

    try {
      const response = await SeoAnalysisAPI.termSuggestions({
        searchengine: searchEngine,
        keyword,
        text,
        ai_writer_id: 'ai_writer_id'
      });

      invalidateSeoAnalysisUsageQueries();
      invalidateCustomerAllLimitationsQueries();

      if (response.status) {
        const mkTermSuggestions = (words: string[]): TermSuggestion[] =>
          words.map(word => {
            return {
              word,
              occurrence: countWordOccurrence(word)
            };
          });

        const { termsuggestions } = response.data;

        const termSuggestions: SeoTermSuggestions = {
          less: mkTermSuggestions(termsuggestions.less),
          more: mkTermSuggestions(termsuggestions.more),
          ok: mkTermSuggestions(termsuggestions.ok)
        };

        dispatch(updateTermSuggestions({ termSuggestions, wordsAmount }));
        dispatch(updateSeoLimitations(response.data.limitations));
      } else {
        const errorId = handleApiCommonErrors(response.data.message);

        Toast.backendError(errorId);
      }
    } catch (e) {
      Toast.apiError();
    }
  };
