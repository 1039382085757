import { useMutation } from '@tanstack/react-query';
import { getPreferredAudience } from 'features/aiWriter/utils/getPreferredAudience';
import { getUserAudiences } from 'features/audiences/store/selectors';
import { setWordsLimitReached } from 'features/customer/store/actions';
import { useCustomerPreferences } from 'features/customerPreferences/useCustomerPreferences';
import { generateTexts } from 'features/textGenerator/actions/generateTexts';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { assertNonNullable } from 'utils/typescript/nonNullable';

type Props = {
  initialPrompt: string;
  prompt: string;
  outputType: string;
  locale?: {
    language: string;
    country: string;
  };
  setPrompt: (prompt: string) => void;
  callback?: () => void;
};

export function usePromptOptimizerControlled(props: Props) {
  const [initialPrompt, setInitialPrompt] = useState(props.initialPrompt);
  const [isUndoVisible, setIsUndoVisible] = useState(false);

  const dispatch = useAppDispatch();
  const audiences = useAppSelector(getUserAudiences);
  const { data: customerPreferences } = useCustomerPreferences();

  const language = props.locale?.language ?? customerPreferences?.preferredLanguage ?? 'en';
  const country = props.locale?.country ?? customerPreferences?.preferredLanguageCountry ?? 'us';
  const audienceId = getPreferredAudience({ audiences, locale: { language, country } })?.model_id;

  assertNonNullable(audienceId, 'Audience not found');

  const optimizePromptMutation = useMutation({
    mutationFn: () =>
      generateTexts(
        {
          audienceModelId: audienceId,
          nTimes: 1,
          nTextItems: 1,
          outputType: props.outputType
        },
        { text: props.prompt },
        {
          onCustomError: [
            'ERROR_ALL_WORDS_USED',
            () => {
              dispatch(setWordsLimitReached(true));
            }
          ]
        }
      ),
    onSuccess: data => {
      props.setPrompt(data.outputs[0].text);
      setIsUndoVisible(true);

      if (props.callback) {
        props.callback();
      }
    }
  });

  const undoPromptOptimization = () => {
    props.setPrompt(initialPrompt);
    setInitialPrompt('');
    setIsUndoVisible(false);
  };

  return {
    initialPrompt,
    optimizePromptMutation,
    isUndoVisible,
    setInitialPrompt,
    undoPromptOptimization,
    setIsUndoVisible
  };
}
