import { useMutation } from '@tanstack/react-query';
import { showNotSufficientPermissionsError } from 'components/profile/components/pricing-plan-card/showNotSufficientPermissionsError';
import Toast from 'components/toasts/Toast';
import { isSubscriptionInGrace } from 'features/pricing/utils/isSubscriptionInGrace';
import { createStripeCheckoutUrlFromResponse } from 'features/stripe-portal/createStripeCheckoutUrlFromResponse';
import CustomerAPI from 'services/api/customer';
import { CustomerActiveSubscriptionState } from 'services/api/customer/types';
import { PricingContextParams } from 'services/api/pricing';
import { handleApiCommonErrors } from 'services/api/utils/handleApiCommonErrors';
import getFirstPromoterReferralId from 'utils/getFirstPromoterReferralId';
import redirectToStripe from 'utils/redirectToStripe';

export function usePlanConfirmMutation() {
  return useMutation({
    mutationFn: async (props: {
      pricingId: number;
      isActivePlan: boolean;
      subscriptionState: CustomerActiveSubscriptionState;
      context?: PricingContextParams['context'];
    }) => {
      const { pricingId, isActivePlan, subscriptionState, context } = props;
      
      // #tech-debt https://app.clickup.com/t/862jtwd0g
      const response = await CustomerAPI.createBillingPortalUrl({
        pricingId,
        referralId: getFirstPromoterReferralId(),
        context
      });

      if (!response.status) {
        const errorId = handleApiCommonErrors(response.data.message);

        if (response?.message === 'ERROR_FORBIDDEN') {
          showNotSufficientPermissionsError();
          return;
        }

        Toast.backendError(errorId);
        return;
      }

      const redirectUrl = createStripeCheckoutUrlFromResponse(
        response.data,
        isActivePlan,
        isSubscriptionInGrace(subscriptionState)
      );

      redirectToStripe(redirectUrl);
    }
  });
}
