import { ApiErrors } from '../types';
import mkApiErrorMessage from '../utils/mkApiErrorMessage';
import mkHandleApiErrorMessage from '../utils/mkHandleApiErrorMessage';
import { CustomerApiEndpoint, LoginErrorCode } from './types';

const mkCustomerApiErrors = (endpoint: CustomerApiEndpoint, errorCode: LoginErrorCode) =>
  mkApiErrorMessage('customer', endpoint, errorCode);

const loginErrors: ApiErrors<LoginErrorCode> = {
  ERROR_WRONG_EMAIL: mkCustomerApiErrors('logIn', 'ERROR_WRONG_EMAIL'),
  ERROR_UNAUTHORIZED: mkCustomerApiErrors('logIn', 'ERROR_UNAUTHORIZED'),
  ERROR_CUSTOMER_IS_NOT_ACTIVE: mkCustomerApiErrors('logIn', 'ERROR_CUSTOMER_IS_NOT_ACTIVE'),
  ERROR_CUSTOMER_NOT_VERIFIED: mkCustomerApiErrors('logIn', 'ERROR_CUSTOMER_NOT_VERIFIED'),
  ERROR_CUSTOMER_DISABLED: mkCustomerApiErrors('logIn', 'ERROR_CUSTOMER_DISABLED'),
  ERROR_CUSTOMER_ALREADY_LOGGED_IN: mkCustomerApiErrors('logIn', 'ERROR_CUSTOMER_ALREADY_LOGGED_IN'),
  ERROR_WRONG_SESSION: mkCustomerApiErrors('logIn', 'ERROR_WRONG_SESSION'),
  ERROR_AUTH: mkCustomerApiErrors('logIn', 'ERROR_AUTH'),
};

export const handleLoginErrors = mkHandleApiErrorMessage(loginErrors);
