/* eslint-disable react/prop-types */
import { scaleLinear } from 'd3-scale';
import { select as d3select } from 'd3-selection';
import { zoom as d3zoom, zoomIdentity } from 'd3-zoom';
import { each, get, orderBy, range, sortBy } from 'lodash';
import { readableColor } from 'polished';
import { Component } from 'react';
import { connect } from 'react-redux';
import { ReactComponent as Logo } from '../../../assets/neuroflashLogo.svg';
import { defaultGraphFont } from '../../../styles/styleUtils';
import withTr from '../../../utils/hocs/withTr';
import { graphColorScale, palettes } from '../../../utils/palettes';
import { noop } from '../../../utils/utils';
import { formatWordClassName } from '../../../utils/wordUtils';
import {
  getActiveTab,
  getExplorerPageLayout,
  getGoalConfigStatus,
  getGraphColoringLabel,
  getSelectedWords
} from '../store/selectors';
import { getInterconnectedness, setColorPalette } from '../store/slice';
import GoalLegend from './GoalLegend';
import GoalStatus from './GoalStatus';
import Gradient from './Gradient';
import MainLegend from './MainLegend';
import './style.scss';
import TriangleSwitch from './TriangleSwitch';

const mapStateToProps = state => ({
  verticalLayout: getExplorerPageLayout(state),
  data: getSelectedWords(state),
  activeTab: getActiveTab(state),
  goalConfig: getGoalConfigStatus(state),
  graphColoringLabel: getGraphColoringLabel(state)
});

const mapDispatchToProps = {
  getI16sData: getInterconnectedness.request,
  setColorPalette
};

class BubbleChart extends Component {
  config = {
    baseBubbleSize: 40,
    initialDistanceBetweenBubbles: 5,
    bubbleRadiusStep: 0.2,
    minInnerRadius: 150,
    startingPoint: 0.5 * Math.PI,
    defaultShadowMargin: 5,
    emptyGroupShadowMargin: 25,
    labelOverlapErrorMargin: 8
  };

  state = {
    width: 1200,
    height: 1060,
    xTrans: 0,
    yTrans: 0
  };

  canvasWidth = 1200;

  canvasHeight = 1060;

  startingX = this.canvasWidth / 2;

  startingY = this.canvasHeight / 2;

  svgDefs = (
    <>
      <filter id="drop-shadow" height="140%">
        <feGaussianBlur in="SourceAlpha" stdDeviation="15" result="blur" />
        <feOffset in="blur" dx="0" dy="0" result="offsetBlur" />
        <feFlood floodColor="#aaa" result="offsetColor" />
        <feComposite in="offsetColor" in2="offsetBlur" operator="in" result="offsetBlur" />
        <feMerge>
          <feMergeNode in="offsetBlur" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
      <marker
        id="arrow"
        viewBox="0 -5 10 10"
        refX="6"
        refY="0"
        markerWidth="4"
        markerHeight="4"
        fill="#425369"
        orient="auto"
      >
        <path d="M0,-5L10,0L0,5" className="arrowHead" />
      </marker>
      <marker
        id="arrow-start"
        viewBox="0 -5 10 10"
        refX="6"
        refY="0"
        fill="#425369"
        markerWidth="4"
        markerHeight="4"
        orient="auto-start-reverse"
      >
        <path d="M0,-5L10,0L0,5" className="arrowHead" />
      </marker>
    </>
  );

  componentDidMount() {
    this.drawChart();
  }

  componentDidUpdate(prevProps) {
    // eslint-disable-next-line react/prop-types
    if (this.props.activeTab !== prevProps.activeTab) {
      this.container.remove();
      this.drawChart();
    }
    if (this.props.activeTab.graphI16sEnabled !== prevProps.activeTab.graphI16sEnabled) {
      const isEnabled = this.props.activeTab.graphI16sEnabled;
      if (!isEnabled) {
        this.networksGroup.html(null);
      } else {
        this.props.getI16sData();
      }
    }
  }

  getViewBox = () => {
    const { xTrans, yTrans, width, height } = this.state;
    return `${xTrans} ${yTrans} ${width} ${height}`;
  };

  scaleLegendElements = scaleLinear([0, 40], [1, 1.4]).clamp(true);

  render() {
    const { xTrans, yTrans, width } = this.state;
    const { data, verticalLayout, goalConfig, activeTab, tr, graphColoringLabel } = this.props;
    const { graphColoring, palette } = activeTab;
    const coloringLabel = graphColoringLabel.needsTranslation
      ? tr(graphColoringLabel.label)
      : graphColoringLabel.label;

    this.legendScale = this.scaleLegendElements(data.length || 40);

    return (
      <svg
        ref={svgElement => {
          this.svgElement = svgElement;
        }}
        className={verticalLayout ? 'bubble-chart__svg--vertical' : 'bubble-chart__svg'}
        id="bubble-graph"
        preserveAspectRatio="xMidYMid"
        viewBox={this.getViewBox()}
      >
        <defs className="bubble-chart__defs">
          {this.svgDefs}
          <Gradient palette={palette} />
        </defs>
        <g opacity="0" transform="scale(0)">
          <MainLegend
            graphColoring={graphColoring}
            label={coloringLabel}
            scale={this.legendScale}
          />
          <GoalStatus goalConfig={goalConfig} scale={this.legendScale} />
          <GoalLegend scale={this.legendScale} />
          <TriangleSwitch transform="translate(5, 122)" scale={this.legendScale} direction="left" />
          <TriangleSwitch
            transform="translate(107, 122)"
            scale={this.legendScale}
            direction="right"
          />
          <Logo
            id="nf-logo"
            className="export-only"
            width={234}
            height={54}
            x={width + xTrans - 234}
            y={yTrans}
          />
        </g>
      </svg>
    );
  }

  drawChart() {
    this.prepareData();
    const hasData = this.props.data.length;

    this.prepareGraphSelectors();
    if (hasData) {
      this.drawGroupLayouts();
      this.drawSearchTerms();
      range(1, 5).forEach(group => this.drawSingleGroup(group));
    } else {
      this.drawNoDataInfo();
    }
    this.setViewBox(() => {
      if (hasData) {
        this.drawLogo();
        this.drawLegend();
        this.checkIntersections(this.config.labelOverlapErrorMargin);
      }
    });
  }

  drawNoDataInfo() {
    this.graph
      .append('text')
      .attr('class', 'bubble-chart__search-terms-legend')
      .attr('x', this.startingX)
      .attr('y', this.startingY)
      .attr('font-family', defaultGraphFont)
      .attr('text-anchor', 'middle')
      .style('font-size', '50px')
      .append('tspan')
      .text(this.props.tr('exploring.bubble_chart.no_words'));
  }

  getCircleRadius = (modelRankQuartile, step, baseBubbleSize) =>
    (1 - (modelRankQuartile - 1) * step) * baseBubbleSize;

  prepareData() {
    const { palette, lookupTable } = this.props.activeTab;
    const { data } = this.props;
    const { bubbleRadiusStep, baseBubbleSize } = this.config;

    this.scale = graphColorScale(palette);
    this.lookupData = lookupTable;

    const splitData = sortBy(data, ['model_rank']).reduce(
      (splitData, item) => {
        const index = item.goal_score_quartile !== -1 ? item.goal_score_quartile : 4;
        splitData[`${index}`].push({
          ...item,
          radius: this.getCircleRadius(item.model_rank_quartile, bubbleRadiusStep, baseBubbleSize)
        });
        return splitData;
      },
      { 1: [], 2: [], 3: [], 4: [] }
    );

    Object.keys(splitData).forEach(rank => {
      splitData[rank] = orderBy(
        splitData[rank],
        ['model_rank_quartile', 'valence'],
        ['asc', 'desc']
      );
    });

    this.data = splitData;
  }

  prepareGraphSelectors() {
    const { graphZoomEnabled } = this.props.activeTab;
    this.container = d3select(this.svgElement)
      .append('g')
      .call(
        graphZoomEnabled
          ? d3zoom()
              .scaleExtent([0.5, 4])
              .on('zoom', () => this.graph.attr('transform', zoomIdentity.toString()))
          : () => noop
      );

    if (graphZoomEnabled) {
      this.container
        .append('rect')
        .attr('class', 'bubble-chart__zoom-handler')
        .attr('fill', 'none')
        .attr('pointer-events', 'all')
        .attr('width', this.state.width)
        .attr('height', this.state.height);
    }

    this.graph = this.container.append('g').attr('class', 'bubble-graph__content');

    this.groupsGroup = this.graph.append('g').attr('class', 'bubble-chart__groups-group');
    this.networksGroup = this.graph.append('g').attr('class', 'bubble-chart__networks-group');
    this.bubblesGroup = this.graph.append('g').attr('class', 'bubble-chart__bubbles-group');
    this.labelsGroup = this.graph.append('g').attr('class', 'bubble-chart__labels-group');
  }

  setPalette = direction => {
    const { palette } = this.props.activeTab;

    const paletteNames = Object.keys(palettes);
    const currentIndex = paletteNames.indexOf(palette);
    const nextPalette =
      currentIndex + 1 < paletteNames.length ? paletteNames[currentIndex + 1] : paletteNames[0];
    const prevPalette =
      currentIndex - 1 < 0 ? paletteNames[paletteNames.length - 1] : paletteNames[currentIndex - 1];

    this.props.setColorPalette(direction === 'right' ? nextPalette : prevPalette);
  };

  drawLegend() {
    const scale = this.legendScale;
    const { xTrans, yTrans, height, width } = this.state;
    const { height: mainLegHeight } = document.querySelector('#graph-legend').getBBox();
    const { height: goalStatHeight } = document.querySelector('#goal-status').getBBox();

    const margin = 12.5 * scale;

    this.graph
      .append('use')
      .attr('xlink:href', '#graph-legend')
      .attr('x', xTrans)
      .attr('y', height + yTrans - mainLegHeight * scale - margin * 2.2);

    this.graph
      .append('use')
      .attr('xlink:href', '#palette-switch-left')
      .attr('x', xTrans)
      .attr('y', height + yTrans - mainLegHeight * scale - margin * 2.2)
      .on('click', () => this.setPalette('left'));

    this.graph
      .append('use')
      .attr('xlink:href', '#palette-switch-right')
      .attr('x', xTrans)
      .attr('y', height + yTrans - mainLegHeight * scale - margin * 2.2)
      .on('click', () => this.setPalette('right'));

    this.graph
      .append('use')
      .attr('xlink:href', '#goal-status')
      .attr('x', width + xTrans - margin * 1.5)
      .attr('y', height + yTrans - goalStatHeight * scale - margin * 1.79);

    this.graph
      .append('use')
      .attr('xlink:href', '#goal-legend')
      .attr('x', width + xTrans - margin * 1.5)
      .attr('y', height + yTrans - goalStatHeight * scale - 2.75 * margin);
  }

  drawLogo() {
    this.graph.append('use').attr('xlink:href', '#nf-logo');
  }

  drawSearchTerms() {
    const getYTranslation = (fSize, wordsCount) => -((fSize * wordsCount) / 2);
    const pyramidSort = arr => {
      const newArray = [];
      arr.sort((a, b) => a.length - b.length);
      newArray.push(arr.pop());

      while (arr.length) {
        newArray[arr.length % 2 === 0 ? 'push' : 'unshift'](arr.pop());
      }

      return newArray;
    };

    let words = pyramidSort(this.props.activeTab.name.split(', '));
    if (words.length > 4) {
      words = words.slice(0, 3);
      words.push('...');
    }

    const indexes = Array.from(Array(words.length).keys());
    const fontSize = 40;

    const drawnSearchTerms = this.graph
      .append('text')
      .attr('class', 'bubble-chart__search-terms-legend')
      .attr('x', this.startingX)
      .attr('y', this.startingY + 20)
      .attr('font-family', defaultGraphFont)
      .attr('transform', `translate(0, ${getYTranslation(fontSize, words.length)})`)
      .attr('text-anchor', 'middle')
      .style('font-size', `${fontSize}px`)
      .selectAll('tspan')
      .data(indexes)
      .enter()
      .append('tspan')
      .text(i => words[i])
      .attr('x', i => (i === 0 ? null : this.startingX))
      .attr('dx', i => (i === 0 ? 0 : null))
      .attr('dy', i => (i === 0 ? null : fontSize));

    const drawingField = this.groupRs[1] * 2 - this.getLargestBubbleRadiusFromGroup(1) * 4 - 10;
    const bbox = document.querySelector('.bubble-chart__search-terms-legend').getBBox();
    let newFontSize = fontSize;

    if (bbox.width > drawingField) {
      newFontSize = fontSize * (drawingField / bbox.width);
    }

    if (bbox.height > drawingField) {
      const lineSpace = 0.35; // experimentally set value
      const heightDivider = words.length + lineSpace;
      const heightRegulatedFontSize = drawingField / heightDivider;
      newFontSize = Math.min(heightRegulatedFontSize, newFontSize);
    }

    if (newFontSize !== fontSize) {
      drawnSearchTerms.attr('dy', i => (i === 0 ? null : newFontSize));
      d3select('.bubble-chart__search-terms-legend')
        .attr('transform', `translate(0, ${getYTranslation(newFontSize, words.length)})`)
        .style('font-size', `${newFontSize}px`);
    }
  }

  drawGroupLayouts() {
    this.drawingCircleRs = {};
    this.groupRs = {};

    range(1, 5).forEach(groupId => {
      this.drawingCircleRs[groupId] = this.calculateDrawingCircleRadius(
        groupId,
        this.config.initialDistanceBetweenBubbles,
        this.config.minInnerRadius,
        this.config.defaultShadowMargin,
        this.config.emptyGroupShadowMargin
      );

      if (groupId < 4) {
        this.groupRs[groupId] = this.calculateGroupRadius(groupId);
      }
    });

    this.groupsGroup
      .selectAll('circle.bubble-chart__group')
      .data([3, 2, 1])
      .enter()
      .append('circle')
      .attr('fill', (d, i) => ['#f0f0f0', '#e0e0e0', '#d0d0d0'][i])
      .attr('r', d => this.groupRs[d])
      .attr('cx', this.startingX)
      .attr('cy', this.startingY)
      .style('filter', 'url(#drop-shadow)');
  }

  drawSingleGroup(group) {
    const angles = [];
    const groupR = this.drawingCircleRs[group];
    const bubblesCount = this.data[group].length;
    const sumOfRadiuses = this.getSumOfRadii(group);
    const distance = (this.drawingCircleRs[group] * 2 * Math.PI - sumOfRadiuses) / bubblesCount;

    const getX = (d, i) =>
      this.startingX + groupR * Math.cos(angles[i] - this.config.startingPoint);
    const getYForBubble = (d, i) =>
      this.startingY + groupR * Math.sin(angles[i] - this.config.startingPoint);
    const getYForLabel = (d, i) => getYForBubble(d, i) + 5;

    let nextAngle = 0;

    range(0, bubblesCount).forEach(i => {
      angles.push(nextAngle);

      const currentR = this.data[group][i].radius;
      const nextR = this.data[group][i !== bubblesCount - 1 ? i + 1 : i].radius;

      nextAngle += (currentR + nextR + distance) / groupR;
    });

    this.drawBubbleGroup(this.data[group], group, getX, getYForBubble);
    this.drawLabelsGroup(this.data[group], group, getX, getYForLabel);
  }

  checkIntersections(errorMargin) {
    const rectsOverlap = (a, b, margin) => ({
      doesOverlap: !(
        b.left > a.right - margin ||
        b.right < a.left + margin ||
        b.top > a.bottom - margin ||
        b.bottom < a.top + margin
      ),
      bottom: -b.top + a.bottom,
      top: b.bottom - a.top
    });

    each(
      document.querySelectorAll('.bubble-chart__info:not(.bubble-chart__info--toggleable)'),
      textElement => {
        const currentBBox = textElement.getBoundingClientRect();
        each(
          document.querySelectorAll('text:not(.bubble-chart__info--toggleable)'),
          otherTextElement => {
            const currentWord = textElement.getAttribute('data-word');
            const otherWord = otherTextElement.getAttribute('data-word');
            let elementToModify = textElement;

            if (currentWord && otherWord) {
              elementToModify =
                this.lookupData[currentWord].goal_score > this.lookupData[otherWord].goal_score
                  ? otherTextElement
                  : textElement;
            }

            if (
              currentWord !== otherWord &&
              !otherTextElement.classList.contains('bubble-chart__info--toggleable') &&
              !textElement.classList.contains('bubble-chart__info--toggleable')
            ) {
              const otherBBox = otherTextElement.getBoundingClientRect();
              const { doesOverlap } = rectsOverlap(currentBBox, otherBBox, errorMargin);
              if (doesOverlap) {
                return elementToModify.classList.add('bubble-chart__info--toggleable');
              }
            }

            return elementToModify.classList.add('bubble-chart__info--visible');
          }
        );
      }
    );

    each(document.querySelectorAll('.bubble-chart__info--toggleable'), hiddenLabel => {
      let currentBBox = hiddenLabel.getBoundingClientRect();
      const visibleLabels = document.querySelectorAll(
        '.bubble-chart__info:not(.bubble-chart__info--toggleable)'
      );

      each(visibleLabels, visibleLabel => {
        const labelBBox = visibleLabel.getBoundingClientRect();
        const { doesOverlap, top, bottom } = rectsOverlap(currentBBox, labelBBox, errorMargin);

        if (doesOverlap) {
          const translateY = bottom < top ? -(bottom - errorMargin / 2) : top - errorMargin / 2;
          hiddenLabel.setAttribute('transform', `translate(0, ${translateY})`);
          currentBBox = hiddenLabel.getBoundingClientRect();
        }
      });

      hiddenLabel.classList.remove('bubble-chart__info--toggleable');
      hiddenLabel.classList.add('bubble-chart__info--visible');
    });
  }

  setViewBox(callback) {
    const yMargin = 50;
    const xMargin = 100;
    const marginBottom = 30;
    const bbox = this.graph.node().getBBox();
    bbox.height += yMargin * 2 + marginBottom;
    bbox.y -= yMargin;
    bbox.width += xMargin * 2;
    bbox.x -= xMargin;
    this.setState(
      {
        width: bbox.width,
        height: bbox.height,
        xTrans: bbox.x,
        yTrans: bbox.y
      },
      callback
    );
  }

  /* helpers */
  getSumOfRadii(group, distance = 0) {
    return this.data[group].reduce((sum, bubble) => {
      sum += 2 * bubble.radius + distance;
      return sum;
    }, 0);
  }

  getLargestBubbleRadiusFromGroup(group) {
    return get(this.data, [group, 0, 'radius'], 0);
  }

  calculateDrawingCircleRadius(
    group,
    initialDistance,
    minInnerRadius,
    defShadowMargin,
    emptyGroupShadowMargin
  ) {
    const calculatedR = this.getSumOfRadii(group, initialDistance) / (2 * Math.PI);
    let minimumR = minInnerRadius;

    if (group > 1) {
      const maxGroupR = this.getLargestBubbleRadiusFromGroup(group);
      const shadowMargin = maxGroupR ? defShadowMargin : emptyGroupShadowMargin;
      minimumR = this.groupRs[group - 1] + 1.25 * maxGroupR + shadowMargin;
    }

    return Math.max(calculatedR, minimumR);
  }

  calculateGroupRadius(group) {
    if (group === 1) {
      return this.drawingCircleRs[group] + 1.25 * this.getLargestBubbleRadiusFromGroup(group);
    }
    // ensures that bubbles are centered in outer circles
    return 2 * this.drawingCircleRs[group] - this.groupRs[group - 1];
  }

  jumpToTableRow = ({ word }) => {
    if (!this.props.activeTab.graphI16sEnabled) {
      const rowElement = document.querySelector(`.words-table-row-${formatWordClassName(word)}`);

      if (rowElement) {
        rowElement.scrollIntoView({ behavior: 'smooth' });
        rowElement.classList.add('block-highlight');
        setTimeout(() => rowElement.classList.remove('block-highlight'), 3000);
      }
    }
  };

  drawNetwork = (sourceWord, sourceX, sourceY) => {
    const networkData = Array.from(document.querySelectorAll('.bubble-chart__bubble-circle'))
      .map(el => ({
        x: el.getAttribute('cx'),
        y: el.getAttribute('cy'),
        word: el.getAttribute('data-word'),
        score: get(this.props.activeTab.i16s, [el.getAttribute('data-word'), sourceWord], 0)
      }))
      .filter(line => line.score > 0.2 && line.word !== sourceWord);

    const linesGroup = this.graph.selectAll(`g.network-lines-${sourceWord}`);
    if (linesGroup.size()) {
      linesGroup.remove();
    } else {
      this.networksGroup
        .append('g')
        .attr('class', `network-lines-${sourceWord}`)
        .selectAll(`g.network-lines-${sourceWord}`)
        .data(networkData)
        .enter()
        .append('line')
        .attr('x1', sourceX)
        .attr('y1', sourceY)
        .attr('x2', d => d.x)
        .attr('y2', d => d.y)
        .attr('y2', d => d.y)
        .attr('stroke-width', d => d.score * 10)
        .attr('stroke', '#2A6095')
        .attr('opacity', d => d.score);
    }
  };

  bubbleOnClickAction = (wordData, x, y) => {
    if (!this.props.activeTab.graphI16sEnabled) {
      this.jumpToTableRow(wordData);
    } else {
      this.drawNetwork(wordData.word, x, y);
    }
  };

  drawBubbleGroup(data, group, x, y) {
    this.bubblesGroup
      .selectAll(`circle.bubble-chart__bubble-circle-${group}`)
      .data(data)
      .enter()
      .append('circle')
      .attr('class', 'bubble-chart__bubble-circle')
      .attr('stroke', '#999')
      .attr('cx', x)
      .attr('cy', y)
      .attr('data-word', d => d.word)
      .attr('r', d => d.radius)
      .attr('fill', d => this.scale(get(d, this.props.activeTab.graphColoring)))
      .on('click', (wordData, i) =>
        this.bubbleOnClickAction(wordData, x(wordData, i), y(wordData, i))
      );
  }

  drawLabelsGroup(data, group, x, y) {
    this.labelsGroup
      .selectAll(`circle.bubble-chart__bubble-text-${group}`)
      .data(data)
      .enter()
      .append('text')
      .text(d =>
        this.props.activeTab.graphTranslationEnabled ? d.translation_en || d.word : d.word
      )
      .attr('class', 'bubble-chart__info')
      .attr('cursor', 'default')
      .attr('fill', d => readableColor(this.scale(get(d, this.props.activeTab.graphColoring))))
      .attr('font-size', '18px')
      .attr('font-family', defaultGraphFont)
      .attr('text-anchor', 'middle')
      .attr('data-word', d => d.word)
      .attr('x', x)
      .attr('y', y)
      .on('click', (wordData, i) =>
        this.bubbleOnClickAction(wordData, x(wordData, i), y(wordData, i))
      );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTr(BubbleChart));
