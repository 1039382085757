import { AxiosRequestConfig } from 'axios';
import { CustomerUpdateRequest } from 'features/customer/store/types';
import AxiosClient from 'services/api';
import { ApiExtendedResponse } from 'services/api/types';
import { keysToSnake } from 'utils/utils';

import {
  ApplyCouponCodeRequest,
  ApplyCouponCodeResponse,
  ApplyPromoCodeResponse,
  AuthRequest,
  AuthWithSessionRequest,
  CreateAccountRequest,
  CreateAccountResponse,
  CreateBillingPortalRequest,
  CreateBillingPortalResponse,
  CreateBillingPortalResponseForBillingPortal,
  CreateCheckoutAccountResponse,
  CreateReferralLinkResponse,
  CustomerData,
  GetChurnStatisticResponse,
  GetCustomerSubscriptionResponse,
  GetGroupResponse,
  GetHasChurnDiscountResponse,
  GetWordsCurrentUsageResponse,
  GetWordsUsageHistoryResponse,
  LoginReponse,
  RecaptchaAction,
  RecaptchaResponse,
  UnsubscribeResponse
} from './types';

const CustomerAPI = {
  logIn: ({ email, password }: AuthRequest) =>
    AxiosClient.post<unknown, LoginReponse>('/customer/auth', {
      email: email.trim(),
      password: password.trim()
    }),
  logInWithSession: ({ session_id }: AuthWithSessionRequest) =>
    AxiosClient.post<unknown, LoginReponse>('/customer/checkout-auth', {
      session_id: session_id.trim()
    }),
  logOut: () => AxiosClient.post('/customer/logout'),

  get: () => AxiosClient.get<unknown, ApiExtendedResponse<CustomerData>>('/customer'),
  delete: () => AxiosClient.delete('/customer'),
  update: ({ payload, config }: { payload: CustomerUpdateRequest; config?: AxiosRequestConfig }) =>
    AxiosClient.put('/customer', keysToSnake(payload), config),
  getStats: () =>
    AxiosClient.get<unknown, ApiExtendedResponse<Record<string, unknown>>>('/customer/statistic'),
  getGroup: () => AxiosClient.get<unknown, GetGroupResponse>('/group'),
  createCheckout: (payload: CreateAccountRequest) =>
    AxiosClient.post<
      unknown,
      ApiExtendedResponse<CreateAccountResponse | CreateCheckoutAccountResponse>
    >('/customer/checkout', keysToSnake(payload)),
  recoverPassword: (email: string) =>
    AxiosClient.post<unknown, ApiExtendedResponse<unknown>>('/customer/reset-password', { email }),
  changePassword: (password: string, token: string) =>
    AxiosClient.post<unknown, ApiExtendedResponse<unknown>>('/customer/change-password', {
      password,
      token
    }),
  validateEmail: async (email: string) => {
    const response = await AxiosClient.post<
      unknown,
      ApiExtendedResponse<never[] | { message: { email: string[] } }>
    >('/customer/email-available', { email });
    return response.status ? '' : 'validation.email.currently_used';
  },
  verifyEmail: (email: string) =>
    AxiosClient.get<unknown, ApiExtendedResponse<unknown>>('/customer/verify', {
      params: { email }
    }),
  updatePaymentMethod: (payload: { paymentMethod: string }) =>
    AxiosClient.put<unknown, ApiExtendedResponse<unknown>>(
      '/customer/payment',
      keysToSnake(payload)
    ),
  unsubscribe: () =>
    AxiosClient.post<unknown, UnsubscribeResponse>('/customer/pricing-unsubscribe'),
  recaptcha: (payload: { token: string; expectedAction: RecaptchaAction; customerId: number }) =>
    AxiosClient.post<unknown, ApiExtendedResponse<RecaptchaResponse>>(
      '/customer/recaptcha',
      keysToSnake(payload)
    ),
  /**
   * DEPRECATED!
   * #tech-debt https://app.clickup.com/t/862jtwd0g
   */
  createBillingPortalUrl: (payload?: CreateBillingPortalRequest) =>
    AxiosClient.post<
      CreateBillingPortalResponse,
      ApiExtendedResponse<CreateBillingPortalResponseForBillingPortal>
    >('/customer/billing-portal', keysToSnake(payload ?? {})),
  applyCoupon: (payload: ApplyCouponCodeRequest) =>
    AxiosClient.post<ApplyCouponCodeResponse, ApiExtendedResponse<ApplyCouponCodeResponse>>(
      '/customer/add-coupon',
      keysToSnake(payload)
    ),
  subscription: () =>
    AxiosClient.get<unknown, GetCustomerSubscriptionResponse>('/customer/subscription'),
  hasChurnDiscount: () =>
    AxiosClient.get<unknown, GetHasChurnDiscountResponse>('/customer/has-churn-discount'),
  applyChurnDiscount: () => AxiosClient.post<unknown, unknown>('/customer/apply-churn-discount'),
  getChurnStatistic: () =>
    AxiosClient.get<unknown, GetChurnStatisticResponse>('/customer/churn-statistic'),
  inviteFriendByEmail: (email: string) =>
    AxiosClient.post<unknown, ApiExtendedResponse<unknown>>('/customer/invite', {
      email
    }),
  getWordsCurrentUsage: () =>
    AxiosClient.get<unknown, GetWordsCurrentUsageResponse>('/customer/words/current-usage'),
  getWordsUsageHistory: () =>
    AxiosClient.get<unknown, GetWordsUsageHistoryResponse>('/customer/words/usage-history'),
  createReferralLink: () =>
    AxiosClient.post<unknown, CreateReferralLinkResponse>('/customer/referral-link'),
  applyPromoCode: (payload: { promo_code: string }) =>
    AxiosClient.post<unknown, ApplyPromoCodeResponse>('/customer/apply-promo-code', payload)
};

export default CustomerAPI;
