import { ELEMENT_CODE_BLOCK, ELEMENT_CODE_LINE, TTableRowElement } from '@udecode/plate';
import { ELEMENT_BLOCKQUOTE } from '@udecode/plate-block-quote';
import { ELEMENT_DEFAULT, isText, TDescendant, TElement, TText } from '@udecode/plate-common';
import {
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  ELEMENT_H5,
  ELEMENT_H6
} from '@udecode/plate-heading';
import { ELEMENT_HR } from '@udecode/plate-horizontal-rule';
import { ELEMENT_LINK } from '@udecode/plate-link';
import {
  ELEMENT_LI,
  ELEMENT_LIC,
  ELEMENT_OL,
  ELEMENT_TODO_LI,
  ELEMENT_UL
} from '@udecode/plate-list';
import { ELEMENT_IMAGE } from '@udecode/plate-media';
import {
  BorderStyle,
  ELEMENT_TABLE,
  ELEMENT_TD,
  ELEMENT_TH,
  ELEMENT_TR,
  TTableCellElement,
  TTableElement
} from '@udecode/plate-table';
import { UnknownObject } from '@udecode/utils';
import { AiWriterTab } from 'features/aiWriter/store/types';
import { unnamed } from 'features/aiWriter/utils/unnamed';

const blockQuoteStyle = `
  font-style: italic;
  color: #6e7687;
  padding-left: 2rem;
  border-left: 2px solid rgba(0, 40, 100, 0.12);
`;

const horizontalLineStyles = `
  margin-top: 2rem; 
  margin-bottom: 2rem;
`;

export const getDocumentAsHtml = ({ text, name }: AiWriterTab): string => {
  const bodyContent = convertNodesToHtml(text);
  const documentTitle = name || unnamed;

  return `
    <!DOCTYPE html>
    <head>
      <title>${documentTitle} - Neuroflash Export</title>
    </head>
    <body>
      ${bodyContent}
    </body>
    </html>
  `;
};

type ConvertOptions = {
  includeStyle?: boolean;
};

export function convertNodesToHtml(text: TElement[], { includeStyle = true }: ConvertOptions = {}) {
  return text
    .map(node => convertElementToHtml(node, includeStyle))
    .filter(content => !!content)
    .join('');
}

function renderChildren(node: TElement, includeStyle: boolean) {
  return node.children.map(child => convertElementToHtml(child, includeStyle)).join('');
}

function convertElementToHtml(node: TDescendant, includeStyle: boolean): string | undefined {
  if (isText(node)) {
    return convertTextNodeToHtml(node, includeStyle);
  }

  switch (node.type) {
    case ELEMENT_H1:
      return renderStyledTag(node, 'h1', includeStyle);
    case ELEMENT_H2:
      return renderStyledTag(node, 'h2', includeStyle);
    case ELEMENT_H3:
      return renderStyledTag(node, 'h3', includeStyle);
    case ELEMENT_H4:
      return renderStyledTag(node, 'h4', includeStyle);
    case ELEMENT_H5:
      return renderStyledTag(node, 'h5', includeStyle);
    case ELEMENT_H6:
      return renderStyledTag(node, 'h6', includeStyle);
    case ELEMENT_DEFAULT:
    case 'paragraph':
      if ('listStyleType' in node && typeof node.listStyleType === 'string') {
        return renderList(node, includeStyle);
      }

      return renderStyledTag(node, 'p', includeStyle);
    case ELEMENT_IMAGE:
      return renderImageElement(node);
    case ELEMENT_UL:
      return `<ul>${renderChildren(node, includeStyle)}</ul>`;
    case ELEMENT_OL:
      return `<ol>${renderChildren(node, includeStyle)}</ol>`;
    case ELEMENT_LI:
      return renderStyledTag(node, 'li', includeStyle);
    case ELEMENT_LIC:
      return renderChildren(node, includeStyle);
    case ELEMENT_BLOCKQUOTE:
    case 'block-quote':
      return `<blockquote style="${includeStyle ? blockQuoteStyle : ''}">${renderChildren(
        node,
        includeStyle
      )}</blockquote>`;
    case ELEMENT_HR:
      return `<hr style="${includeStyle ? horizontalLineStyles : ''}" />`;
    case ELEMENT_LINK:
      return `<a href="${node.url}">${renderChildren(node, includeStyle)}</a>`;
    case ELEMENT_CODE_BLOCK:
      return `<pre><code>${renderChildren(node, includeStyle)}</code></pre>`;
    case ELEMENT_CODE_LINE:
      return renderChildren(node, includeStyle);
    case ELEMENT_TODO_LI:
      return `<label><input type="checkbox" ${
        node.checked ? 'checked="checked"' : ''
      } /> ${renderChildren(node, includeStyle)}</label><br />`;
    case ELEMENT_TABLE:
      return renderTable(node, includeStyle);
  }

  return undefined;
}

function renderStyledTag(node: TElement, tag: string, includeStyle: boolean) {
  return `<${tag} style="${getElementStyles(node, includeStyle)}">${renderChildren(
    node,
    includeStyle
  )}</${tag}>`;
}

function renderImageElement(node: TElement) {
  // Optional caption like "caption\nsecondline"
  const renderedCaption =
    Array.isArray(node.caption) && node.caption.length > 0
      ? `<figcaption>${node.caption[0].text.replace(/\n/g, '<br />')}</figcaption>`
      : '';

  return `
    <figure style="text-align: center">
      <img src="${node.url}" width="${node.width ?? 600}" />
      ${renderedCaption}
    </figure>
  `;
}

function renderList(node: TElement, includeStyle: boolean) {
  const tagName = node.listStyleType === 'disc' ? 'ul' : 'ol';
  const listStyle = `padding: 0 0 0 20px; margin: 0; ${getElementStyles(node, includeStyle, {
    textStyle: false
  })}`;

  return `
    <${tagName} start="${node.listStart ?? 1}" style="${listStyle}">
      <li style="padding: 0; margin: 0;">${node.children
        .map(child => convertElementToHtml(child, includeStyle))
        .join('')}</li>
    </${tagName}>
  `;
}

const isTableElement = (node: TElement): node is TTableElement => node.type === ELEMENT_TABLE;

function renderTable(node: TElement, includeStyle: boolean) {
  if (!isTableElement(node)) {
    return '';
  }

  const renderTableChildren = (node: TElement, minHeight?: number): string => {
    if (node.type === ELEMENT_TR) {
      return `<tr>
        ${node.children
          .map(child => renderTableChildren(child as TTableCellElement, node.size as number))
          .join('')}
      </tr>`;
    }

    const borderConfig = (node.borders as TTableCellElement['borders']) ?? {
      top: { size: 1 },
      bottom: { size: 1 },
      left: { size: 1 },
      right: { size: 1 }
    };
    const crateBorderStyle = (borderDirection: string, style?: BorderStyle) =>
      `border-${borderDirection}: ${style?.size ?? 1}px ${style?.style ?? 'solid'} ${
        style?.color ?? '#dfe2e5'
      }`;

    const borderStyle = [
      crateBorderStyle('top', borderConfig.top),
      crateBorderStyle('bottom', borderConfig.bottom),
      crateBorderStyle('left', borderConfig.left),
      crateBorderStyle('right', borderConfig.right)
    ].join(';');

    if (node.type === ELEMENT_TH) {
      return `<th ${
        includeStyle
          ? `style="text-transform: uppercase; text-align: left; background: ${
              node.background ?? 'unset'
            }; ${borderStyle}"`
          : ''
      }>
        <div ${
          includeStyle
            ? `style="padding: 0.5rem; min-height: ${minHeight ? `${minHeight}px` : 'auto'};"`
            : ''
        }>${node.children
        .map(child => renderChildren(child as TElement, includeStyle))
        .join('')}</div>
      </th>`;
    }

    if (node.type === ELEMENT_TD) {
      return `<td ${
        includeStyle ? `style="background: ${node.background ?? 'unset'}; ${borderStyle}"` : ''
      }>
        <div ${
          includeStyle
            ? `style="padding: 0.5rem; text-align: left; min-height: ${
                minHeight ? `${minHeight}px` : 'auto'
              };"`
            : ''
        }>${node.children
        .map(child => renderChildren(child as TElement, includeStyle))
        .join('')}</div>
      </td>`;
    }

    return '';
  };

  // Taken from plugin source
  // @see https://github.com/udecode/plate/blob/2f52b9e786fc2f495430beda9cd0b4d123c079ef/packages/table/src/createTablePlugin.ts#L38
  const columnMinWidth = 48;
  const collSizes: Array<number | string> = node.colSizes
    ? [...node.colSizes]
    : Array.from({ length: 2 }).map(() => columnMinWidth);

  // Taken from plugin source
  // @see https://github.com/udecode/plate/blob/2f52b9e786fc2f495430beda9cd0b4d123c079ef/packages/table/src/components/TableElement/useTableElement.ts#L51
  if (!collSizes.includes(0)) {
    collSizes.push('100%');
  }

  return `
    <table ${
      includeStyle
        ? `style="${[
            // Mimic editor width
            'width: 600px',
            // Required to shrink the table according to colgroup widths
            'table-layout: fixed',
            // Avoid default table border
            'border-spacing: 0',
            // Avoid overlapping double border between cells
            'border-collapse: collapse',
            `margin-left: ${node.marginLeft ?? 0}px`
          ].join(';')};"`
        : ''
    }>
      <colgroup>
        ${collSizes
          ?.map(
            size =>
              `<col style="${[
                `min-width: ${columnMinWidth}px`,
                `width: ${typeof size === 'number' ? `${size}px` : size}`
              ].join(';')};" />`
          )
          .join('')}
      </colgroup>
      <tbody>
        ${node.children.map(child => renderTableChildren(child as TTableRowElement)).join('')}
      </tbody>
    </table> 
  `;
}

function convertTextNodeToHtml(node: TText, includeStyle: boolean): string {
  let renderedNodeText = node.text;

  if (node.bold) {
    renderedNodeText = `<strong>${renderedNodeText}</strong>`;
  }

  if (node.underline) {
    renderedNodeText = `<u>${renderedNodeText}</u>`;
  }

  if (node.italic) {
    renderedNodeText = `<i>${renderedNodeText}</i>`;
  }

  if (node.code) {
    renderedNodeText = `<code>${renderedNodeText}</code>`;
  }

  return `<span style="${getElementStyles(node, includeStyle)}">${renderedNodeText}</span>`;
}

function getElementStyles(
  node: UnknownObject,
  includeStyle = true,
  options = { textStyle: true }
): string {
  if (!includeStyle) {
    return '';
  }

  const properties = options.textStyle
    ? ['white-space: pre-wrap', 'overflow-wrap: break-word']
    : [];

  if (node.code) {
    properties.push(
      'font-family: monospace',
      'background: rgb(0 0 0 / 6%)',
      'border: 1px solid rgba(0, 0, 0, 0.05)',
      'padding-inline: 3px',
      'border-radius: 4px'
    );
  }

  if (node.highlight) {
    properties.push('background-color: yellow');
  }

  if (node.color) {
    properties.push(`color: ${node.color}`);
  }

  if (node.backgroundColor) {
    properties.push(`background-color: ${node.backgroundColor}`);
  }

  if (node.align) {
    properties.push(`text-align: ${node.align}`);
  }

  if (node.indent && typeof node.indent === 'number' && node.indent > 1) {
    properties.push(`padding-left: ${node.indent * 20}px`);
  }

  if (node.lineHeight) {
    properties.push(`line-height: ${node.lineHeight}`);
  }

  return properties.join(';');
}
