import { LoadingButton } from '@mui/lab';
import FlexContainer from 'components/FlexContainer';
import EmailField from 'components/forms/customFields/EmailField';
import PasswordField from 'components/forms/customFields/PasswordField';
import { login } from 'features/customer/store/actions';
import { Form, Formik } from 'formik';
import React, { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { getValidationForField } from 'utils/getValidationForField';
import useTr from 'utils/hooks/useTr';
import { object, string } from 'yup';

const emailField = 'email';
const passwordField = 'password';

export type LoginFormValues = {
  [emailField]: string;
  [passwordField]: string;
};

const initialValues: LoginFormValues = {
  [emailField]: '',
  [passwordField]: ''
};

export const LoginForm = ({ error }: { error?: React.ReactNode }) => {
  const dispatch = useAppDispatch();
  const tr = useTr();

  const isLoading = useAppSelector(state => state.customer.isLoading);

  const onSubmit = useCallback(
    (values: LoginFormValues) => {
      dispatch(login.request(values));
    },
    [dispatch]
  );

  const validationSchema = useMemo(
    () =>
      object().shape({
        [emailField]: string()
          .trim()
          .email()
          .required(tr(getValidationForField('email'))),
        [passwordField]: string().required(tr(getValidationForField('password')))
      }),
    [tr]
  );

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {() => (
        <Form>
          <Content gap="small" alignItems="center">
            <EmailField name={emailField} />
            <PasswordField name={passwordField} />

            {error}

            <StyledLoadingButton
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              loading={isLoading}
            >
              <FormattedMessage id="common.login" />
            </StyledLoadingButton>
          </Content>
        </Form>
      )}
    </Formik>
  );
};

const Content = styled(FlexContainer)``;

const StyledLoadingButton = styled(LoadingButton)`
  margin-top: 0.5rem;
`;
