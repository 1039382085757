import {
  changeGenerateTextConfig,
  setInspirationsCurrentStep
} from 'features/aiWriter/store/actions/config/actions';
import {
  getCurrentModelLanguageAndCountry,
  getGenerateTextConfig
} from 'features/aiWriter/store/selectors';
import { AiWriterSidebarStep } from 'features/aiWriter/store/types';
import { getGetPromptConfigByLanguageCountryAndOutputType } from 'features/textGenerator/store/selectors';
import { getFieldPrefillValue } from 'features/textGenerator/utils/getFieldPrefillValue';
import { getNTextItems } from 'features/textGenerator/utils/getNTextItems';
import { AppThunk } from 'store/store';

export const changeOutputTypeThunk =
  (outputType: string): AppThunk<void> =>
  async (dispatch, getState) => {
    /**
     * #tech-debt https://app.clickup.com/t/862hy4n1w
     * When output type changes we need to override
     * config with prefilled values. We have this logic
     * in multiple places. I don't like it but I don't know how to
     * make it better without a complete rewrite.
     */
    const state = getState();
    const { currentModelLanguage, currentModelCountry } = getCurrentModelLanguageAndCountry(state);

    const prompt = getGetPromptConfigByLanguageCountryAndOutputType(state)(
      currentModelLanguage,
      currentModelCountry,
      outputType
    );

    const config = getGenerateTextConfig(state);
    config.outputType = outputType;
    config.nTextItems = getNTextItems(prompt);

    const text = getFieldPrefillValue(prompt, 'text');
    if (text) {
      config.text = text;
    }
    const keywords = getFieldPrefillValue(prompt, 'keywords');
    if (keywords) {
      config.keywords = keywords;
    }
    const keywords2 = getFieldPrefillValue(prompt, 'keywords2');
    if (keywords2) {
      config.keywords2 = keywords2;
    }

    dispatch(changeGenerateTextConfig(config));
    dispatch(
      setInspirationsCurrentStep({ step: AiWriterSidebarStep.aiWriter, subStep: 'editBrief' })
    );
  };
