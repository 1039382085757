import { Button, TextField } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import Toast from 'components/toasts/Toast';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { useField } from 'formik';
import { useState } from 'react';
import styled from 'styled-components';

export type AvatarInputProps = {
  name: string;
};

const maxFileSizeInMB = 7;

export function AvatarInput(props: AvatarInputProps) {
  const [, , helpers] = useField(props.name);

  const [fileName, setFileName] = useState<string>('');

  const handleAvatarUpload = ({ target: { files } }: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = files?.[0];

    if (!uploadedFile) return;

    if (uploadedFile.size > maxFileSizeInMB * 1024 * 1024) {
      Toast.error('profile.upload_image.size_error', { fileSizeLimitInMB: maxFileSizeInMB });
      return;
    }

    if (uploadedFile) {
      const reader = new FileReader();
      reader.readAsDataURL(uploadedFile);
      reader.onload = () => {
        setFileName(uploadedFile.name);
        helpers.setValue(reader.result);
      };
    }
  };

  return (
    <FlexContainer direction="row" gap="two">
      <FileIndicator disabled={true} value={fileName} fullWidth />
      <Button role={undefined} component="label" size="large" variant="outlined" tabIndex={-1}>
        <FormattedMessage id="common.browse" />
        <VisuallyHiddenInput
          type="file"
          accept="image/png, image/jpeg, image/png, image/jpg"
          onChange={handleAvatarUpload}
        />
      </Button>
    </FlexContainer>
  );
}

const FileIndicator = styled(TextField)`
  & .Mui-disabled {
    color: ${({ theme }) => theme.colors.textPrimary};
  }
`;

const VisuallyHiddenInput = styled.input`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;
