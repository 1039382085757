import { useQuery } from '@tanstack/react-query';
import Toast from 'components/toasts/Toast';
import { makeCurrentUserQueryKeyPrefix } from 'features/currentUser/currentUserQueries';
import AiTesterAPI from 'services/api/aiTester';
import { ApiAiTesterProject } from 'services/api/aiTester/types';
import { handleApiCommonErrors } from 'services/api/utils/handleApiCommonErrors';
import { useAppDispatch } from 'store/hooks';

import { updateProjects } from '../store/actions/project/actions';
import { testerProjectFromApi } from '../store/utils/mappers/testerProjectMappers';

async function getProjects() {
  const projectResponse: ApiAiTesterProject[][] = [];
  let stopped = false;

  const fetchProjects = async (props: { offset: number; limit: number }) =>
    AiTesterAPI.get({ ...props });

  let offset = 0;
  const limit = 100;

  while (!stopped) {
    const response = await fetchProjects({ offset, limit });

    if (response.status) {
      projectResponse.push(response.data);
      offset += limit;
      if (response.total <= offset) stopped = true;
    } else {
      const errorId = handleApiCommonErrors(response.data.message);

      Toast.backendError(errorId);

      stopped = true;
    }
  }

  return projectResponse.flat(1);
}

export default function useFetchTesterProjects() {
  const dispatch = useAppDispatch();

  const { isLoading } = useQuery(
    [...makeCurrentUserQueryKeyPrefix(), 'projects', 'ai-tester'],
    () => getProjects(),
    {
      onSuccess: projects => {
        dispatch(updateProjects(projects.map(testerProjectFromApi)));
      },
      onError: () => Toast.apiError()
    }
  );

  return { isLoading };
}
