import { useMutation } from '@tanstack/react-query';
import Toast from 'components/toasts/Toast';
import { getCurrentLanguage } from 'features/language/store/selectors';
import { getErrorCodeFromAxiosError } from 'features/teamInvitation/getErrorCodeFromAxiosError';
import { handleApiCommonErrors } from 'services/api/utils/handleApiCommonErrors';
import {
  httpTeamInvitationAccept,
  InvitationAcceptanceParams
} from 'services/backofficeIntegration/http/endpoints/teamMembers/httpTeamInvitationAccept';
import { useAppSelector } from 'store/hooks';
import getTimezone from 'utils/getTimezone';

export const useTeamInvitationAccept = (invitationToken: string) => {
  const locale = useAppSelector(getCurrentLanguage);
  const timezone = getTimezone();

  return useMutation({
    mutationFn: () => {
      const params: InvitationAcceptanceParams = {
        token: invitationToken,
        timezone,
        locale
      };

      return httpTeamInvitationAccept.callEndpoint(params);
    },
    onError: err => {
      const errorCode = getErrorCodeFromAxiosError(err);
      if (errorCode) {
        Toast.backendError(handleApiCommonErrors(errorCode));
      } else {
        Toast.apiError();
      }
    }
  });
};
