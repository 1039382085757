import {
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import { ColoredTypography } from 'components/ColoredTypography';
import FlexContainer from 'components/FlexContainer';
import dayjs from 'dayjs';
import { CustomerRole } from 'features/customer/store/types';
import { IntlMessageKeys } from 'features/i18n/IntlMessageKeys';
import { useGetCurrentTeamUsage } from 'features/profilePage/wordsUsage/useGetCurrentTeamUsage';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { TeamCurrentUsageDto } from 'services/backofficeIntegration/http/endpoints/teamMembers/httpGetCurrentTeamUsage';
import styled, { css } from 'styled-components';

export function UsagePerUserSection() {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const offset = limit * page;

  const paginatedQueryParams = {
    offset,
    limit
  };

  const teamCurrentUsage = useGetCurrentTeamUsage(paginatedQueryParams);

  function handleChangePage(_event: unknown | null, page: number) {
    setPage(page);
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Root>
      <Typography variant="h6">
        <FormattedMessage id="profile_page.usage_card.team_usage_section.heading" />
      </Typography>

      {selectContent()}
    </Root>
  );

  function selectContent() {
    if (teamCurrentUsage.isSuccess && teamCurrentUsage.data.status) {
      // eslint-disable-next-line no-constant-condition
      // if (true) {
      const { total } = teamCurrentUsage.data;

      return (
        <Paper variant="outlined">
          <TeamUsageTable usage={teamCurrentUsage.data.data.usage} />
          <TablePagination
            count={total ?? 0}
            page={page}
            rowsPerPage={limit}
            onPageChange={handleChangePage}
            rowsPerPageOptions={[5, 10, 25]}
            onRowsPerPageChange={handleChangeRowsPerPage}
            component="div"
          />
        </Paper>
      );
    }

    const beError = teamCurrentUsage.isSuccess && !teamCurrentUsage.data.status;
    if (teamCurrentUsage.isError || beError) {
      return (
        <CenteredItemsBox>
          <FormattedMessage id="profile_page.usage_card.current_usage_section.error" />
          <Button onClick={() => teamCurrentUsage.refetch()}>
            <FormattedMessage id="common.refresh" />
          </Button>
        </CenteredItemsBox>
      );
    }

    return (
      <CenteredItemsBox>
        <CircularProgress />
      </CenteredItemsBox>
    );
  }
}

const CenteredItemsBox = styled.div`
  display: grid;
  place-items: center;
`;

function TeamUsageTable(props: { usage: TeamCurrentUsageDto[] }) {
  const { usage } = props;

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <HeadCell textId="profile_page.usage_card.team_usage_section.table_header.user" />
            <HeadCell textId="profile_page.usage_card.team_usage_section.table_header.word_count" />
            <HeadCell textId="profile_page.usage_card.team_usage_section.table_header.files" />
            <HeadCell textId="profile_page.usage_card.team_usage_section.table_header.active" />
          </TableRow>
        </TableHead>
        <TableBody>
          {usage.map(memberUsage => (
            <MemberRow key={memberUsage.email} memberUsage={memberUsage} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  function HeadCell(props: { textId: IntlMessageKeys }) {
    const { textId } = props;

    return (
      <TableCell>
        <Typography variant="subtitle1" fontWeight="bold">
          <FormattedMessage id={textId} />
        </Typography>
      </TableCell>
    );
  }
}

function MemberRow(props: { memberUsage: TeamCurrentUsageDto }) {
  const { memberUsage } = props;
  return (
    <TableRow>
      <UserTableCell
        email={memberUsage.email}
        name={memberUsage.name}
        profilePictureSvg={memberUsage.image_url}
        teamRole={memberUsage.role}
      />
      <TableCell>{memberUsage.words_count}</TableCell>
      <TableCell>{memberUsage.files_count}</TableCell>
      <TableCell>{dayjs(memberUsage.last_active).format('DD.MM.YYYY')}</TableCell>
    </TableRow>
  );
}

function UserTableCell(props: {
  name: string;
  email: string;
  profilePictureSvg: string;
  teamRole: CustomerRole;
}) {
  const { name, email, profilePictureSvg, teamRole } = props;

  return (
    <TableCell>
      <FlexContainer direction="row" gap="small" alignItems="center">
        <Avatar src={profilePictureSvg} />
        <FlexContainer>
          <FlexContainer direction="row" gap="small" alignItems="center">
            <ColoredTypography variant="body1" fontWeight="500">
              {name}
            </ColoredTypography>
            {renderSpecialRoleBadge()}
          </FlexContainer>
          <ColoredTypography variant="body2">{email}</ColoredTypography>
        </FlexContainer>
      </FlexContainer>
    </TableCell>
  );

  function renderSpecialRoleBadge() {
    if (teamRole === CustomerRole.OWNER || teamRole === CustomerRole.ADMIN) {
      return (
        <SpecialRoleBadge $role={teamRole}>
          <ColoredTypography color="white" fontSize="0.6rem">
            <FormattedMessage id={`customer.role.${teamRole.toLowerCase()}`} />
          </ColoredTypography>
        </SpecialRoleBadge>
      );
    }
  }
}

const Root = styled(FlexContainer).attrs({ gap: 'medium' })``;

const Avatar = styled.img`
  width: 35px;
  height: 35px;

  border-radius: 50%;
`;

const SpecialRoleBadge = styled.div<{ $role: CustomerRole }>(props => {
  const baseStyles = css`
    display: flex;
    align-items: center;
    justify-content: center;

    padding: ${({ theme }) => `0 ${theme.spacings.small}`};

    height: 1rem;

    font-size: 0.3rem;

    border-radius: ${({ theme }) => theme.borderRadius.small};
  `;
  const ownerStyles = css`
    background-color: ${({ theme }) => theme.colors.primary};
  `;
  const adminStyles = css`
    background-color: ${({ theme }) => theme.colors.black};
  `;

  if (props.$role === CustomerRole.OWNER) {
    return css`
      ${baseStyles}
      ${ownerStyles}
    `;
  } else {
    return css`
      ${baseStyles}
      ${adminStyles}
    `;
  }
});
