import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import RefreshIcon from '@mui/icons-material/Refresh';
import WarningRounded from '@mui/icons-material/WarningRounded';
import { useFlashScoreSidebarState } from 'features/aiWriter/AiWriterSidebar/steps/flashScore/useFlashScoreSidebarState';
import styled from 'styled-components';

export const FlashScoreFetchingIcon = () => {
  const autoFetchState = useFlashScoreSidebarState(state => state.autoFetchState);

  if (autoFetchState === 'disabled') {
    return <OrangeWarningIcon width={24} />;
  }

  if (autoFetchState === 'fetching') {
    return <SpinningRefreshIcon width={24} />;
  }

  return <GreenCheckIcon width={24} />;
};

const GreenCheckIcon = styled(CheckCircleRoundedIcon)`
  fill: ${({ theme }) => theme.colors.success};
  color: ${({ theme }) => theme.colors.white};
`;

const OrangeWarningIcon = styled(WarningRounded)`
  fill: ${({ theme }) => theme.colors.warning};
  color: ${({ theme }) => theme.colors.white};
`;

const SpinningRefreshIcon = styled(RefreshIcon)`
  animation: spin 1s linear infinite;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
