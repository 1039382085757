import { LabelRounded } from '@mui/icons-material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Checkbox,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import CountryFlag from 'components/base/CountryFlag';
import FlexContainer from 'components/FlexContainer';
import dayjs from 'dayjs';
import { getProjectName } from 'features/aiWriter/AiWriterProjectOverview/getProjectName';
import {
  ProjectId,
  SortConfiguration
} from 'features/aiWriter/AiWriterProjectOverview/NewProjectsSection';
import { ProjectsMenu } from 'features/aiWriter/AiWriterProjectOverview/ProjectsMenu';
import { useRenameProjectModal } from 'features/aiWriter/AiWriterProjectOverview/RenameProjectModal';
import {
  AiWriterProjectGroupOfTags,
  AiWriterProjectTag
} from 'features/aiWriter/AiWriterProjectOverview/tags/AiWriterProjectTag';
import { AiWriterProjectTagsDropdownMenu } from 'features/aiWriter/AiWriterProjectOverview/tags/AiWriterProjectTagsDropdownMenu';
import { useCopyDocument } from 'features/aiWriter/AiWriterProjectOverview/useCopyDocument';
import { useDeleteAiWriterProjects } from 'features/aiWriter/AiWriterProjectOverview/useDeleteAiWriterProjects';
import { loadTabThunk } from 'features/aiWriter/store/actions/tabs/thunks/loadTabThunk';
import { AiWriterProject, AiWriterProjectWithShortText } from 'features/aiWriter/store/types';
import { getGetEmbeddingModelById } from 'features/embeddingModels/store/selectors';
import { isEmpty } from 'lodash';
import React, { ChangeEvent, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { invalidateProjectsQuery } from 'services/backofficeIntegration/http/endpoints/aiWriter/httpGetProjects';
import { httpUpdateProject } from 'services/backofficeIntegration/http/endpoints/aiWriter/httpUpdateProject';
import { GAEvents } from 'services/tracking/GAEvents';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import styled from 'styled-components';

import { useGetProjectById } from '../useGetProjectById';

export function ProjectsTable(props: {
  projects: AiWriterProjectWithShortText[];
  sorting: SortConfiguration;
  selectedProjectIds: ProjectId[];
  setSelectedProjectIds: (selected: ProjectId[]) => void;
  onSortChange: (sorting: SortConfiguration) => void;
}) {
  const { projects, sorting, selectedProjectIds, onSortChange, setSelectedProjectIds } = props;

  const customerId = useAppSelector(state => state.customer.id);

  const allVisibleProjectIds = useMemo(
    () => projects.filter(project => project.authorId === customerId).map(p => p.id),
    [customerId, projects]
  );

  const handleSelection = (id: string) => {
    if (selectedProjectIds.includes(id)) {
      setSelectedProjectIds(selectedProjectIds.filter(s => s !== id));
    } else {
      setSelectedProjectIds([...selectedProjectIds, id]);
    }
  };

  const onSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      // create a Set to remove duplicates
      const projectIdsSet = new Set([...selectedProjectIds, ...allVisibleProjectIds]);
      setSelectedProjectIds(Array.from(projectIdsSet));
      return;
    }

    // unselect from selectedProjectIds all projects that are in projects
    const newSelected = selectedProjectIds.filter(id => !allVisibleProjectIds.includes(id));
    setSelectedProjectIds(newSelected);
  };

  const hasSelectedAllRows = useMemo(
    () => allVisibleProjectIds.every(projectId => selectedProjectIds.includes(projectId)),
    [allVisibleProjectIds, selectedProjectIds]
  );

  const getToggledDirection = () => (sorting.direction === 'asc' ? 'desc' : 'asc');

  const isFilteredByMember = projects.every(project => project.authorId !== customerId);

  return (
    <Root>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              {!isFilteredByMember && (
                <Checkbox checked={hasSelectedAllRows} onChange={onSelectAllClick} />
              )}
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={sorting.column === 'name'}
                direction={sorting.direction}
                onClick={() =>
                  onSortChange({
                    column: 'name',
                    direction: getToggledDirection()
                  })
                }
              >
                <FormattedMessage id="aiWriter.project_overview.projects_table.column.name" />
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <FormattedMessage id="common.tags" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="common.created_by" />
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={sorting.column === 'updated_at'}
                direction={sorting.direction}
                onClick={() =>
                  onSortChange({
                    column: 'updated_at',
                    direction: getToggledDirection()
                  })
                }
              >
                <FormattedMessage id="aiWriter.project_overview.projects_table.column.last_modified" />
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={sorting.column === 'locale'}
                onClick={() =>
                  onSortChange({
                    column: 'locale',
                    direction: undefined
                  })
                }
              >
                <FormattedMessage id="aiWriter.project_overview.projects_table.column.language" />
              </TableSortLabel>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {projects.map(project => {
            return (
              <ProjectRow
                key={project.id}
                project={project}
                isSelected={selectedProjectIds.includes(project.id)}
                handleSelection={handleSelection}
              />
            );
          })}
        </TableBody>
      </Table>
    </Root>
  );
}

function ProjectRow(props: {
  project: AiWriterProjectWithShortText;
  isSelected: boolean;
  handleSelection: (id: string) => void;
}) {
  const { id, name, short_text, updatedAt, embeddingModelId, authorName, authorId, tags } =
    props.project;
  const { isSelected, handleSelection } = props;

  const [isClickPrevented, setIsClickPrevented] = useState(false);
  const tagsAnchorEl = useRef<null | HTMLElement>(null);
  const [isTagsMenuOpen, setIsTagsMenuOpen] = useState(false);
  const fullProjectRef = useRef<AiWriterProject>();
  const [projectsAnchorEl, setProjectsAnchorEl] = useState<null | HTMLElement>(null);
  const isProjectsMenuOpen = Boolean(projectsAnchorEl);

  const dispatch = useAppDispatch();
  const customerId = useAppSelector(state => state.customer.id);

  const { mutate: deleteProject, isLoading: isDeleteLoading } = useDeleteAiWriterProjects();

  const { mutate: updateProject, isLoading: isUpdateLoading } = useMutation({
    mutationFn: (payload: { id: string; project: AiWriterProject }) =>
      httpUpdateProject.callEndpoint(payload.id, payload.project),
    onSuccess: () => invalidateProjectsQuery()
  });
  const { isLoading: isFetchingLoading, fetchProject } = useGetProjectById();

  const fetchFullProjectData = async () => {
    if (fullProjectRef.current) {
      return fullProjectRef.current;
    }

    return fetchProject(
      { id },
      {
        onSuccess: data => {
          fullProjectRef.current = data;
        }
      }
    );
  };

  const handleClickOption = (event: React.MouseEvent<HTMLButtonElement>) => {
    setProjectsAnchorEl(event.currentTarget);
  };

  const handleCloseOption = () => {
    setProjectsAnchorEl(null);
  };

  const showRenameProjectModal = useRenameProjectModal();

  const { copyDocument } = useCopyDocument();

  const isLoading = isDeleteLoading || isUpdateLoading || isFetchingLoading;

  const isProjectAuthor = authorId === customerId;

  const handleRowClick = () => {
    if (isClickPrevented) {
      return;
    }

    if (!isProjectsMenuOpen && !isLoading) {
      fetchProject(
        { id },
        {
          onSuccess: data => {
            dispatch(loadTabThunk(data));
          }
        }
      );
    }
  };

  const handleCheckboxClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    handleSelection(id);
  };

  const handleRenameClick = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.stopPropagation();
    showRenameProjectModal({
      oldName: name,
      onUpdate: async (name: string) => {
        const project = await fetchFullProjectData();

        updateProject({ id, project: { ...project, name } });
      }
    });
  };

  const handleCopyDocument = async (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.stopPropagation();
    const project = await fetchFullProjectData();

    copyDocument(project);
  };

  const handleTagClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setIsTagsMenuOpen(true);
    setIsClickPrevented(true);
    GAEvents.tagClickedInProjectOverview();
  };

  const handleCloseTagMenu = () => {
    setIsTagsMenuOpen(false);
    setIsClickPrevented(false);
    handleCloseOption();
  };

  // show only two tags per doc and the rest in a tooltip in a GroupOfTags component
  const visibleTagsList = tags?.slice(0, 2);

  return (
    <ClickableRow hover onClick={handleRowClick} selected={isSelected}>
      <TableCell sx={{ width: '1rem' }} padding='checkbox'>
        {isProjectAuthor && <Checkbox onClick={handleCheckboxClick} checked={isSelected} />}
      </TableCell>
      <NameTableCell>
        <FlexContainer direction="row" gap="medium" alignItems="center">
          <EllipsisTitle variant="body2">{getProjectName(name, short_text ?? '')}</EllipsisTitle>
        </FlexContainer>
      </NameTableCell>
      <TableCell ref={tagsAnchorEl}>
        {tags && !isEmpty(tags) && visibleTagsList ? (
          <FlexContainer direction="row" gap="xsmall">
            {visibleTagsList.map(tag => (
              <AiWriterProjectTag key={tag.id} tag={tag} onClick={handleTagClick} />
            ))}
            {tags.length > 2 && (
              <AiWriterProjectGroupOfTags
                numberOfTags={tags.length - 2}
                tagsList={tags.slice(2)}
                onClick={handleTagClick}
              />
            )}
          </FlexContainer>
        ) : (
          isProjectAuthor && (
            <IconButton onClick={handleTagClick}>
              <LabelRounded />
            </IconButton>
          )
        )}
      </TableCell>
      <TableCell>{authorName}</TableCell>
      <TableCell>{dayjs(updatedAt).format('DD.MM.YYYY')}</TableCell>
      <TableCell>
        <FlagIcon embeddingModelId={embeddingModelId} />
      </TableCell>
      <TableCell>
        {isLoading ? (
          <CircularProgress size={20} />
        ) : (
          <IconButton
            size="small"
            onClick={e => {
              e.stopPropagation();
              handleClickOption(e);
            }}
          >
            <MoreVertIcon />
          </IconButton>
        )}
      </TableCell>
      <ProjectsMenu
        project={props.project}
        anchorEl={projectsAnchorEl}
        isOptionsOpen={isProjectsMenuOpen}
        handleCloseOption={handleCloseOption}
        renameClick={handleRenameClick}
        copyDocument={handleCopyDocument}
        deleteProject={deleteProject}
        tags={tags}
      />
      <AiWriterProjectTagsDropdownMenu
        anchorEl={tagsAnchorEl.current}
        isOpen={isTagsMenuOpen}
        handleCloseMenu={handleCloseTagMenu}
        tags={tags}
        project={props.project}
      />
    </ClickableRow>
  );
}

export function FlagIcon(props: { embeddingModelId: string }) {
  const { embeddingModelId } = props;
  const modelLang = useAppSelector(getGetEmbeddingModelById)(embeddingModelId);

  return <StyledFlag language={modelLang?.language} country={modelLang?.country} />;
}

const Root = styled(TableContainer)`
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  border-color: ${({ theme }) => theme.colors.blackPressedOverlay};
`;

const ClickableRow = styled(TableRow)`
  cursor: pointer;
`;

const StyledFlag = styled(CountryFlag)`
  width: 1.5rem;
  height: 1.125rem;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  margin-right: ${({ theme }) => theme.spacings.xsmall};
`;

const NameTableCell = styled(TableCell)`
  width: 12rem;
`;

const EllipsisTitle = styled(Typography)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 12rem;
`;
