/* eslint-disable @typescript-eslint/no-explicit-any */
import { ThunkAction } from '@reduxjs/toolkit';
import { camelCase, isPlainObject, noop as lodashNoop, snakeCase } from 'lodash';

export const createLookupTable = (data: any[], idField = 'word'): Record<string, number> =>
  data.reduce((lookupTable, item, index) => {
    lookupTable[item[idField]] = index;
    return lookupTable;
  }, {});

export function arrayToDictionary<T>(data: T[], field: keyof T): Record<string, T> {
  return data.reduce<Record<string, T>>((acc, curr) => {
    acc[`${curr[field]}`] = curr;
    return acc;
  }, {});
}

export function removeDuplicates<T>(array: T[]) {
  return Array.from(new Set(array));
}

export function identity<T>(i: T): T {
  return i;
}

export const noop = lodashNoop;

type ThunkPromise = ThunkAction<Promise<any>, any, any, any>;
type ActionPayload<A> = A extends (payload: infer P, thunk: { thunk: true }) => void ? P : never;

// Quite a bit hacky here.. but this allows to pass all sort of functions in but
// types the return to be a thunk with a promise, so it's awaitable
export function promisifySagaThunk<A extends (payload: any, thunk: { thunk: true }) => unknown>(
  func: A,
  payload: ActionPayload<A>
): ThunkPromise {
  return func(payload, { thunk: true }) as ThunkPromise;
}

export function intersectAndJoin<
  T1 extends object,
  T2 extends { [key in keyof (T1 | T2)]: any },
  K extends keyof T1 & keyof T2
>(a: T1[], b: T2[], identifier: K): Array<T1 & T2> {
  return a.reduce<Array<T1 & T2>>(
    (result, aItem) =>
      result.concat(
        b
          .filter(bItem => bItem[identifier] === aItem[identifier])
          .map(bItem => ({ ...bItem, ...aItem }))
      ),
    []
  );
}

export function debouncePromise<T extends (...args: any[]) => any>(
  f: T,
  interval: number
): (...args: Parameters<T>) => Promise<ReturnType<T>> {
  let timer: number;

  return (...args) => {
    clearTimeout(timer);
    return new Promise(resolve => {
      timer = window.setTimeout(() => resolve(f(...args)), interval);
    });
  };
}

export function delayExecution(milliSeconds: number): Promise<void> {
  return new Promise(resolve => window.setTimeout(resolve, milliSeconds));
}

function changeObjectKeysCasing(object: any, convertFn: (key: string) => string): any {
  if (isPlainObject(object)) {
    return Object.keys(object).reduce<any>((newObject, key) => {
      newObject[convertFn(key)] = changeObjectKeysCasing(object[key], convertFn);
      return newObject;
    }, {});
  } else if (Array.isArray(object)) {
    return object.map(item => changeObjectKeysCasing(item, convertFn));
  }

  return object;
}

export function keysToCamel(object: object): object {
  return changeObjectKeysCasing(object, camelCase);
}

export function keysToSnake(object: object): object {
  return changeObjectKeysCasing(object, snakeCase);
}

export const pageToOffset = (page: number, pageSize = 20) => ({
  offset: (page - 1) * pageSize,
  limit: page * pageSize
});
