import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';

import GraphExportDropdown from '../../components/GraphExportDropdown';
import useTr from '../../utils/hooks/useTr';
import LayoutSwitchButton from './LayoutSwitchButton';
import { getActiveTab, getExportNameSelector, getTabHasData } from './store/selectors';
import { ConceptFlashTab } from './store/types';

const exportToSheet = (data: ConceptFlashTab, exportAll: boolean, filename: string) => {
  import('../../utils/excelExport').then(({ exportConceptTableToFile }) => {
    exportConceptTableToFile(data, exportAll, filename);
  });
};

const Container = styled.div`
  display: flex;
  position: absolute;
  right: 1.5rem;
  justify-content: flex-end;
  z-index: 2;
  max-height: 34px;
  align-items: center;

  @media (min-width: 768px) and (max-width: 1280px) {
    position: static;
    margin-bottom: 0.25rem;
  }
`;

const TabActions = () => {
  const tr = useTr();
  const activeTab = useAppSelector(getActiveTab);
  const hasData = useAppSelector(getTabHasData);
  const exportName = useAppSelector(getExportNameSelector)(tr);

  return (
    <Container>
      {hasData && (
        <GraphExportDropdown
          filename={exportName}
          svgElement="#bubble-graph"
          additionalItems={[
            {
              isDivider: true
            },
            {
              value: tr('words_table.export_to_excel'),
              onClick: () => exportToSheet(activeTab, false, exportName)
            },
            {
              value: tr('words_table.export_to_excel_all'),
              onClick: () => exportToSheet(activeTab, true, exportName)
            }
          ]}
        />
      )}
      <LayoutSwitchButton />
    </Container>
  );
};

export default TabActions;
