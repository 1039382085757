import { Check, Remove } from '@mui/icons-material';
import { TableCell, TableContainer, Typography } from '@mui/material';
import { BrandVoiceTable } from 'components/profile/components/feature-comparison/BrandVoiceTable';
import { CollaborationTable } from 'components/profile/components/feature-comparison/CollaborationTable';
import { ContentGenerationCapabilitiesTable } from 'components/profile/components/feature-comparison/ContentGenerationCapabilitiesTable';
import { IntegrationsTable } from 'components/profile/components/feature-comparison/IntegrationsTable';
import { NeuroflashSuiteTable } from 'components/profile/components/feature-comparison/NeuroflashSuiteTable';
import { PricingPlanTable } from 'components/profile/components/feature-comparison/PricingPlanTable';
import { SupportTable } from 'components/profile/components/feature-comparison/SupportTable';
import { UsageCreditsTable } from 'components/profile/components/feature-comparison/UsageCreditsTable';
import { FormattedMessage } from 'react-intl';
import gtmIds from 'services/tracking/GTMIds';
import styled from 'styled-components';

export const FeatureComparisonSection = () => {
  return (
    <Root id={gtmIds.modals.featuresComparisonTable}>
      <PricingPlanTable />
      <Typography variant="h6">
        <FormattedMessage id="features_comparison.usage_credits.label" />
      </Typography>
      <UsageCreditsTable />
      <Typography variant="h6">
        <FormattedMessage id="features_comparison.neuroflash_suite.label" />
      </Typography>
      <NeuroflashSuiteTable />
      <Typography variant="h6">
        <FormattedMessage id="features_comparison.content_generation_capabilities.label" />
      </Typography>
      <ContentGenerationCapabilitiesTable />
      <Typography variant="h6">
        <FormattedMessage id="features_comparison.brand_voice.label" />
      </Typography>
      <BrandVoiceTable />
      <Typography variant="h6">
        <FormattedMessage id="features_comparison.collaboration.label" />
      </Typography>
      <CollaborationTable />
      <Typography variant="h6">
        <FormattedMessage id="features_comparison.integrations.label" />
      </Typography>
      <IntegrationsTable />
      <Typography variant="h6">
        <FormattedMessage id="features_comparison.support.label" />
      </Typography>
      <SupportTable />
    </Root>
  );
};

export const displayFeaturePresence = (feature: boolean) => {
  return feature ? <CheckMark color="success" /> : <Dash />;
};

const CheckMark = styled(Check)`
  color: ${({ theme }) => theme.colors.secondaryColorMain};
`;

const Dash = styled(Remove)`
  color: ${({ theme }) => theme.colors.blackDisabled};
`;

export const Root = styled.div`
  margin-top: 1rem;
`;

export const StyledTable = styled(TableContainer)`
  margin-bottom: 1rem;
  margin-top: 0.25rem;
`;

export const StyledCell = styled(TableCell)`
  && {
    border: 1px solid ${({ theme }) => theme.colors.commonBlackSelected};
  }
  text-align: center;
  width: 20%;

  ${({ theme }) => ({
    ...theme.typography.body1
  })}
`;

export const FirstCell = styled(StyledCell)`
  width: 40%;
  text-align: left;
`;

export const UnderlinedText = styled.span`
  text-decoration: underline;
  text-underline-position: under;
`;

export const BorderedCell = styled(StyledCell)`
  border: ${({ theme }) => `1px solid ${theme.colors.blackHoverOverlay}`};
`;
