import { rgba } from 'polished';
import { borderRadiusPalette } from 'styles/new-theme/borderRadiusPalette';
import { colorPalette } from 'styles/new-theme/colorPalette';
import { spacingPalette } from 'styles/new-theme/spacingPalette';
import { transitionPalette } from 'styles/new-theme/transitionPalette';
import { typographyPalette } from 'styles/new-theme/typographyPalette';

export type NewThemeConfig = typeof newThemeConfig;
export type NewColorConfig = typeof newThemeConfig.colors;

const fontFamily =
  '"Metropolis", "Red Hat Display", "Roboto", "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif';

export const newThemeConfig = {
  fontFamily,

  colors: {
    palette: colorPalette,

    primary__50: colorPalette.blue__50,
    primary__100: colorPalette.blue__100,
    primary__200: colorPalette.blue__200,
    primary__300: colorPalette.blue__300,
    primary__400: colorPalette.blue__400,
    primary__500main: colorPalette.blue__500main,
    primary__600: colorPalette.blue__600,
    primary__700: colorPalette.blue__700,
    primary__800: colorPalette.blue__800,
    primary__900: colorPalette.blue__900,

    secondary__50: colorPalette.green__50,
    secondary__100: colorPalette.green__100,
    secondary__200: colorPalette.green__200,
    secondary__300: colorPalette.green__300,
    secondary__400: colorPalette.green__400,
    secondary__500main: colorPalette.green__500main,
    secondary__600: colorPalette.green__600,
    secondary__700: colorPalette.green__700,
    secondary__800: colorPalette.green__800,
    secondary__900: colorPalette.green__900,

    primaryColorContrast: colorPalette.white,
    primaryColorDark: colorPalette.blue__700,
    primaryColorFocus: rgba(colorPalette.blue__500main, 0.12),
    primaryColorFocusvisible: rgba(colorPalette.blue__500main, 0.3),
    primaryColorHover: rgba(colorPalette.blue__500main, 0.08),
    primaryColorLight: colorPalette.blue__300,
    primaryColorMain: colorPalette.blue__500main,
    primaryColorOutlinedborder: rgba(colorPalette.blue__500main, 0.5),
    primaryColorSelected: rgba(colorPalette.blue__500main, 0.16),

    secondaryColorContrast: colorPalette.white,
    secondaryColorDark: colorPalette.green__700,
    secondaryColorFocus: rgba(colorPalette.green__500main, 0.2),
    secondaryColorFocusvisible: rgba(colorPalette.green__500main, 0.3),
    secondaryColorHover: rgba(colorPalette.green__500main, 0.08),
    secondaryColorLight: colorPalette.green__300,
    secondaryColorMain: colorPalette.green__500main,
    secondaryColorOutlinedborder: rgba(colorPalette.green__500main, 0.5),
    secondaryColorSelected: rgba(colorPalette.green__500main, 0.16),

    nativeScrollbarBg: colorPalette.dark__200,

    accentOrangeContrast: colorPalette.white,
    accentOrangeDark: colorPalette.orange__700,
    accentOrangeFocus: rgba(colorPalette.orange__500main, 0.2),
    accentOrangeFocusvisible: rgba(colorPalette.orange__500main, 0.3),
    accentOrangeHover: rgba(colorPalette.orange__500main, 0.08),
    accentOrangeLight: colorPalette.orange__200,
    accentOrangeMain: colorPalette.orange__500main,
    accentOrangeOutlinedborder: rgba(colorPalette.orange__500main, 0.5),
    accentOrangeSelected: rgba(colorPalette.orange__500main, 0.16),

    accentRedContrast: colorPalette.white,
    accentRedDark: colorPalette.red__800,
    accentRedFocus: rgba(colorPalette.red__500main, 0.2),
    accentRedFocusvisible: rgba(colorPalette.red__500main, 0.3),
    accentRedHover: rgba(colorPalette.red__500main, 0.08),
    accentRedLight: colorPalette.red__300,
    accentRedMain: colorPalette.red__500main,
    accentRedOutlinedborder: rgba(colorPalette.red__500main, 0.5),
    accentRedSelected: rgba(colorPalette.red__500main, 0.16),

    accentRoseContrast: colorPalette.white,
    accentRoseDark: colorPalette.pinkRose__700,
    accentRoseFocus: rgba(colorPalette.pinkRose__500main, 0.2),
    accentRoseFocusvisible: rgba(colorPalette.pinkRose__500main, 0.3),
    accentRoseHover: rgba(colorPalette.pinkRose__500main, 0.2),
    accentRoseLight: colorPalette.pinkRose__200,
    accentRoseMain: colorPalette.pinkRose__500main,
    accentRoseOutlinedborder: rgba(colorPalette.pinkRose__500main, 0.5),
    accentRoseSelected: rgba(colorPalette.pinkRose__500main, 0.16),

    accentYellowContrast: colorPalette.white,
    accentYellowDark: colorPalette.yellow__800,
    accentYellowFocus: rgba(colorPalette.yellow__500main, 0.2),
    accentYellowFocusvisible: rgba(colorPalette.yellow__500main, 0.3),
    accentYellowHover: rgba(colorPalette.yellow__500main, 0.08),
    accentYellowLight: colorPalette.yellow__200,
    accentYellowMain: colorPalette.yellow__500main,
    accentYellowOutlinedborder: rgba(colorPalette.yellow__500main, 0.5),
    accentYellowSelected: rgba(colorPalette.yellow__500main, 0.16),

    actionActive: rgba(colorPalette.dark__900main, 0.7),
    actionAvailable: rgba(colorPalette.dark__900main, 0.5),
    actionDisabled: rgba(colorPalette.dark__900main, 0.38),
    actionDisabledbackground: rgba(colorPalette.dark__900main, 0.12),
    actionFocus: rgba(colorPalette.dark__900main, 0.12),
    actionHover: rgba(colorPalette.dark__900main, 0.04),
    actionSelected: rgba(colorPalette.dark__900main, 0.08),

    backgroundDefault: colorPalette.white,
    backgroundPaperElevation0: colorPalette.white,
    backgroundPaperElevation1: colorPalette.white,
    backgroundPaperElevation10: colorPalette.white,
    backgroundPaperElevation11: colorPalette.white,
    backgroundPaperElevation12: colorPalette.white,
    backgroundPaperElevation13: colorPalette.white,
    backgroundPaperElevation14: colorPalette.white,
    backgroundPaperElevation15: colorPalette.white,
    backgroundPaperElevation16: colorPalette.white,
    backgroundPaperElevation17: colorPalette.white,
    backgroundPaperElevation18: colorPalette.white,
    backgroundPaperElevation19: colorPalette.white,
    backgroundPaperElevation2: colorPalette.white,
    backgroundPaperElevation20: colorPalette.white,
    backgroundPaperElevation21: colorPalette.white,
    backgroundPaperElevation22: colorPalette.white,
    backgroundPaperElevation23: colorPalette.white,
    backgroundPaperElevation24: colorPalette.white,
    backgroundPaperElevation3: colorPalette.white,
    backgroundPaperElevation4: colorPalette.white,
    backgroundPaperElevation5: colorPalette.white,
    backgroundPaperElevation6: colorPalette.white,
    backgroundPaperElevation7: colorPalette.white,
    backgroundPaperElevation8: colorPalette.white,
    backgroundPaperElevation9: colorPalette.white,

    commonBlackFocus: rgba(colorPalette.dark__900main, 0.12),
    commonBlackFocusvisible: rgba(colorPalette.dark__900main, 0.3),
    commonBlackHover: rgba(colorPalette.dark__900main, 0.04),
    commonBlackMain: colorPalette.dark__900main,
    commonBlackOutlinedborder: rgba(colorPalette.dark__900main, 0.5),
    commonBlackSelected: rgba(colorPalette.dark__900main, 0.08),

    commonBrandColorsBrandBeige: colorPalette.beige__100main,
    commonBrandColorsBrandBlue: colorPalette.blue__500main,
    commonBrandColorsBrandDark: colorPalette.dark__900main,
    commonBrandColorsBrandGreen: colorPalette.green__500main,
    commonBrandColorsBrandOrange: colorPalette.orange__500main,
    commonBrandColorsBrandRed: colorPalette.red__500main,
    commonBrandColorsBrandRose: colorPalette.pinkRose__500main,

    commonWhiteFocus: rgba(colorPalette.white, 0.12),
    commonWhiteFocusvisible: rgba(colorPalette.white, 0.3),
    commonWhiteHover: rgba(colorPalette.white, 0.04),
    commonWhiteMain: colorPalette.white,
    commonWhiteOutlinedborder: rgba(colorPalette.white, 0.5),
    commonWhiteSelected: rgba(colorPalette.white, 0.08),

    componentsAlertErrorBackground: colorPalette.red__100,
    componentsAlertErrorColor: colorPalette.red__900,
    componentsAlertInfoBackground: colorPalette.pinkRose__400,
    componentsAlertInfoColor: colorPalette.pinkRose__900,
    componentsAlertSuccessBackground: colorPalette.green__50,
    componentsAlertSuccessColor: colorPalette.green__700,
    componentsAlertWarningBackground: colorPalette.yellow__300,
    componentsAlertWarningColor: colorPalette.orange__900,

    componentsAppbarDefaultfill: colorPalette.dark__500,
    componentsAvatarFill: colorPalette.orange__500main,
    componentsBackdropFill: colorPalette.dark__600,
    componentsBreadcrumbsCollapsefill: colorPalette.dark__200,

    componentsChipDefaultclosefill: colorPalette.dark__600,
    componentsChipDefaultenabledborder: colorPalette.beige__200,
    componentsChipDefaultfocusfill: colorPalette.beige__300,
    componentsChipDefaulthoverfill: colorPalette.beige__100main,

    componentsIconActive: '#626262',
    componentsIconInactive: '#a19d9d',

    componentsInputfieldFilledEnabledfill: rgba(colorPalette.beige__100main, 0.3),
    componentsInputfieldFilledHoverfill: rgba(colorPalette.dark__900main, 0.09),
    componentsInputfieldOutlinedEnabledborder: rgba(colorPalette.dark__900main, 0.23),
    componentsInputfieldOutlinedHoverborder: colorPalette.black,
    componentsInputfieldStandardEnabledborder: colorPalette.dark__900main,
    componentsInputfieldStandardHoverborder: colorPalette.black,

    componentsRatingActivefill: colorPalette.orange__300,
    componentsRatingEnabledborder: rgba(colorPalette.dark__900main, 0.23),
    componentsSnackbarFill: colorPalette.pinkRose__500main,
    componentsStepperConnector: colorPalette.dark__500,
    componentsSwitchKnobfillenabled: colorPalette.dark__600,
    componentsSwitchKnowfilldisabled: colorPalette.dark__500,
    componentsSwitchSlidefill: colorPalette.black,
    componentsTooltipFill: colorPalette.dark__600,

    contrast: colorPalette.black,
    dark: colorPalette.orange__900,
    divider: rgba(colorPalette.dark__900main, 0.12),
    elevationOutlined: colorPalette.dark__500,

    errorContrast: colorPalette.white,
    errorDark: colorPalette.red__900,
    errorFocusvisible: rgba('#d32f2f', 0.3),
    errorHover: rgba('#d32f2f', 0.04),
    errorLight: colorPalette.red__400,
    errorMain: colorPalette.red__700,
    errorOutlinedborder: rgba('#d32f2f', 0.5),
    errorSelected: rgba('#d32f2f', 0.08),

    focus: '#fc7924',
    focusvisible: '#fc7924',
    hover: '#fc7924',

    infoContrast: colorPalette.white,
    infoDark: colorPalette.pinkRose__900,
    infoFocusvisible: rgba(colorPalette.pinkRose__400, 0.3),
    infoHover: rgba(colorPalette.pinkRose__400, 0.08),
    infoLight: colorPalette.pinkRose__400,
    infoMain: colorPalette.pinkRose__500main,
    infoOutlinedborder: rgba(colorPalette.pinkRose__400, 0.5),
    infoSelected: rgba(colorPalette.pinkRose__400, 0.16),

    light: colorPalette.orange__100,
    main: colorPalette.orange__500main,

    outlinedborder: '#fc7924',

    selected: '#fc7924',

    successContrast: colorPalette.white,
    successDark: colorPalette.green__900,
    successFocusvisible: rgba('#58a6ea', 0.3),
    successHover: rgba('#58a6ea', 0.04),
    successLight: colorPalette.green__50,
    successMain: colorPalette.green__400,
    successOutlinedborder: rgba('#58a6ea', 0.5),
    successSelected: rgba('#58a6ea', 0.08),

    textDisabled: colorPalette.dark__400,
    textFocus: colorPalette.dark__400,
    textFocusvisible: colorPalette.dark__500,
    textHover: rgba(colorPalette.dark__900main, 0.04),
    textPrimary: colorPalette.dark__900main,
    textSecondary: colorPalette.dark__700,
    textSelected: rgba(colorPalette.dark__900main, 0.08),

    warningContrast: colorPalette.white,
    warningDark: colorPalette.orange__900,
    warningFocusvisible: rgba(colorPalette.orange__500main, 0.3),
    warningHover: rgba(colorPalette.orange__500main, 0.04),
    warningLight: colorPalette.yellow__200,
    warningMain: colorPalette.orange__400,
    warningOutlinedborder: rgba(colorPalette.orange__500main, 0.5),
    warningSelected: rgba(colorPalette.orange__500main, 0.08)
  },

  typography: {
    ...typographyPalette
  },
  /**
   * @deprecated Please us the typography property to have access to more than just font-size
   */
  fontSizes: {
    h1: typographyPalette.h1.fontSize,
    h2: typographyPalette.h2.fontSize,
    h3: typographyPalette.h3.fontSize,
    h4: typographyPalette.h4.fontSize,
    h5: typographyPalette.h5.fontSize,
    h6: typographyPalette.h6.fontSize,
    body1: typographyPalette.body1.fontSize,
    body1Bold: typographyPalette.body1Bold.fontSize,
    body2: typographyPalette.body2.fontSize,
    subtitle1: typographyPalette.subtitle1.fontSize,
    subtitle2: typographyPalette.subtitle2.fontSize,
    overline: typographyPalette.overline.fontSize,
    caption: typographyPalette.caption.fontSize
  },
  spacings: {
    ...spacingPalette,

    /**
     * @deprecated Use levels (like 1, 2, ... 12) from `spacing` instead
     */
    small: spacingPalette.one,
    /**
     * @deprecated Use levels (like 1, 2, ... 12) from `spacing` instead
     */
    medium: spacingPalette.two,
    /**
     * @deprecated Use levels (like 1, 2, ... 12) from `spacing` instead
     */
    large: spacingPalette.three,
    /**
     * @deprecated Use levels (like 1, 2, ... 12) from `spacing` instead
     */
    extraLarge: spacingPalette.four
  },
  borderRadius: {
    ...borderRadiusPalette,

    /**
     * @deprecated Use levels (like 1, 2, ... 12) from `borderRadius` instead
     */
    none: borderRadiusPalette.five,
    /**
     * @deprecated Use levels (like 1, 2, ... 12) from `borderRadius` instead
     */
    small: borderRadiusPalette.four,
    /**
     * @deprecated Use levels (like 1, 2, ... 12) from `borderRadius` instead
     */
    medium: borderRadiusPalette.three,
    /**
     * @deprecated Use levels (like 1, 2, ... 12) from `borderRadius` instead
     */
    large: borderRadiusPalette.two,
    /**
     * @deprecated Use levels (like 1, 2, ... 12) from `borderRadius` instead
     */
    extraLarge: borderRadiusPalette.one,

    componentButton: borderRadiusPalette.one,
    componentMenu: borderRadiusPalette.three,
    componentCard: borderRadiusPalette.one,
    componentChip: borderRadiusPalette.one,
    componentContainer: borderRadiusPalette.one,
    componentTextField: borderRadiusPalette.two,
    componentSelectField: borderRadiusPalette.one,
    componentAlert: borderRadiusPalette.one,
    componentTab: borderRadiusPalette.four,
    componentTooltip: borderRadiusPalette.three
  },
  container: {
    sm: '540px',
    md: '720px',
    lg: '960px',
    xl: '1200px'
  },
  shadow: {
    card: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    popOver:
      '0 2px 4px rgba(0, 0, 0, 0.14), 0 4px 5px rgba(0, 0, 0, 0.12), 0 1px 10px rgba(0, 0, 0, 0.2)',
    textSuggestionHover:
      '0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)',
    elevation2:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)',
    elevation6:
      '0px 1px 18px 0px rgba(0, 0, 0, 0.12), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 3px 5px -1px rgba(0, 0, 0, 0.20)',
    inviteFriendCard: `0px 2px 4px 0px ${rgba(
      colorPalette.blue__500main,
      0.3
    )}, 0px 1px 20px 0px ${rgba(colorPalette.blue__500main, 0.3)}`
  },
  transition: {
    ...transitionPalette,
    create: (duration: string, ...properties: string[]) =>
      properties.map(property => `${property} ${duration}`).join(', ')
  },
  sizes: {
    sideBar: '64px',
    authForm: '496px',
    topToolbarHeight: '56px'
  }
};
