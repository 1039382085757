import {
  BriefConfigValues,
  keywords2Field,
  keywordsField,
  textField
} from 'features/aiTester/modals/briefConfig/BriefConfigModal';
import { getCurrentModelLanguageAndCountry } from 'features/aiTester/store/selectors';
import { useGetPrefillValueByLanguageCountryAndOutputType } from 'features/textGenerator/utils/useGetPromptsOthers';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';

export default function useHandleOutputTypeChange() {
  const { setFieldValue } = useFormikContext<BriefConfigValues>();

  const { currentModelLanguage, currentModelCountry } = useSelector(
    getCurrentModelLanguageAndCountry
  );
  const getPrefillValue = useGetPrefillValueByLanguageCountryAndOutputType();

  return (outputType: string) => {
    setFieldValue(
      textField,
      getPrefillValue(currentModelLanguage, currentModelCountry, outputType, 'text')
    );
    setFieldValue(
      keywordsField,
      getPrefillValue(currentModelLanguage, currentModelCountry, outputType, 'keywords')
    );
    setFieldValue(
      keywords2Field,
      getPrefillValue(currentModelLanguage, currentModelCountry, outputType, 'keywords2')
    );
  };
}
