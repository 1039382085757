import { Table, TableBody, TableRow, Tooltip } from '@mui/material';
import {
  displayFeaturePresence,
  FirstCell,
  StyledCell,
  StyledTable,
  UnderlinedText
} from 'components/profile/components/pricing-plan-card/FeatureComparisonSection';
import { FormattedMessage } from 'react-intl';

const createIntegrationsData = (
  id: string | null,
  name: string,
  standard: boolean,
  pro: boolean,
  enterprise: boolean
) => {
  return { id, name, standard, pro, enterprise };
};

const integrationsData = [
  createIntegrationsData(null, 'seobility', false, true, true),
  createIntegrationsData(null, 'unsplash', true, true, true),
  createIntegrationsData(null, 'plagiarism_check', false, true, true),
  createIntegrationsData(null, 'browser_extension', true, true, true),
  createIntegrationsData('language_tool', 'language_tool', false, true, true),
  createIntegrationsData(null, 'api_access', false, false, false)
];

export const IntegrationsTable = () => {
  return (
    <StyledTable>
      <Table>
        <TableBody>
          {integrationsData.map(row => (
            <TableRow key={row.name}>
              {row.id ? (
                <FirstCell component="th" scope="row">
                  <Tooltip
                    title={
                      <FormattedMessage id={`features_comparison.integrations.${row.id}.tooltip`} />
                    }
                    placement="top"
                  >
                    <UnderlinedText>
                      <FormattedMessage id={`features_comparison.integrations.${row.name}`} />
                    </UnderlinedText>
                  </Tooltip>
                </FirstCell>
              ) : (
                <FirstCell component="th" scope="row">
                  <FormattedMessage id={`features_comparison.integrations.${row.name}`} />
                </FirstCell>
              )}
              <StyledCell>{displayFeaturePresence(row.standard)}</StyledCell>
              <StyledCell>{displayFeaturePresence(row.pro)}</StyledCell>
              <StyledCell>{displayFeaturePresence(row.enterprise)}</StyledCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </StyledTable>
  );
};
