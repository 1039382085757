import { Avatar, Typography } from '@mui/material';
import { ReactElement } from 'react';
import styled from 'styled-components';

type Props = {
  name?: string;
  image?: string;
  size?: string;
  className?: string;
  backgroundColor?: string;
  email?: string;
};

export const CustomerAvatar = ({
  name,
  image,
  size,
  backgroundColor,
  email
}: Props): ReactElement => {
  return (
    <StyledAvatar alt={name ?? 'User'} src={image} $side={size} $bgColor={backgroundColor}>
      {!image && name && (
        <Initial variant="body1">
          {getFirstLetter(getNamePart(name, 'firstname'))}
          {getFirstLetter(getNamePart(name, 'lastname'))}
        </Initial>
      )}
      {!image && !name && email && <Initial variant="body1">{getFirstLetter(email)}</Initial>}
    </StyledAvatar>
  );
};

const StyledAvatar = styled(Avatar)<{ $side?: string; $bgColor?: string }>`
  && {
    background-color: ${({ theme, $bgColor }) => $bgColor ?? theme.colors.accentOrangeMain};
    width: ${({ $side }) => $side ?? $side};
    height: ${({ $side }) => $side ?? $side};
  }
`;

const getFirstLetter = (name: string) => {
  if (name) {
    return name[0];
  }

  return '';
};

const getNamePart = (name: string, part: 'firstname' | 'lastname') => {
  const nameArr = name.split(' ');

  switch (part) {
    case 'firstname':
      return nameArr[0];
    case 'lastname': {
      return nameArr[1];
    }
    default:
      return '';
  }
};

const Initial = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.white};
    font-weight: 500;
  }
`;
