import SVGText from 'components/ChartElements/SVGText';
import ColorPickerPopup from 'components/ColorPicker/ColorPickerPopup';
import useGetGraphLabel from 'features/aiTester/hooks/getGraphLabel';
import { getTesterActiveTab } from 'features/aiTester/store/selectors';
import { FieldType, LegendTestingOrder, TestingLegend } from 'features/aiTester/store/types';
import { storeColor } from 'features/aiTester/utils/graphData';
import React, { useEffect } from 'react';
import { useAppSelector } from 'store/hooks';
import useTr from 'utils/hooks/useTr';

function wrap(elem: SVGTextContentElement) {
  const width = 125;
  if (elem) {
    let textLength = elem.getComputedTextLength();
    let text = elem.textContent;
    while (textLength > width && text && text.length > 0) {
      text = text.slice(0, -1);
      elem.textContent = `${text}...`;
      textLength = elem.getComputedTextLength();
    }
  }
}

type BarWidthLegendProps = {
  baseY: number;
  baseX?: number;
  distanceX?: number;
  distanceY?: number;
  stroke?: string;
  strokeWidth?: number;
  active?: boolean;
  onClick?: () => void;
  children: React.ReactNode;
};

const pointerStyle = { cursor: 'pointer' };

const BarWidthLegend = ({
  baseY,
  baseX = 9,
  distanceX = 29,
  distanceY = 12,
  active,
  stroke = active ? '#425369' : '#bbb',
  strokeWidth = 2,
  onClick,
  children
}: BarWidthLegendProps) => (
  <g style={pointerStyle}>
    <line x1={baseX} y1={baseY} x2={baseX} y2={baseY + distanceY} style={{ stroke, strokeWidth }} />
    <line
      x1={baseX + distanceX}
      y1={baseY}
      x2={baseX + distanceX}
      y2={baseY + distanceY}
      style={{ stroke, strokeWidth }}
    />
    <line
      x1={baseX}
      y1={baseY + distanceY / 2}
      x2={baseX + distanceX}
      y2={baseY + distanceY / 2}
      style={{ stroke, strokeWidth }}
    />
    <SVGText
      className="legend-label"
      x={50}
      y={baseY + 9}
      fill={active ? '#000' : '#bbb'}
      fontSize={14}
      onClick={onClick}
    >
      {children}
    </SVGText>
  </g>
);

type Props = {
  legendOrder: LegendTestingOrder;
  legendData: TestingLegend;
  transform: string;
  prevalenceAsWidth: boolean;
  onColorChange: (field: FieldType, color: string) => void;
  onActiveChange: (field: FieldType, active: boolean) => void;
};

const GraphLegend = ({
  legendData = {},
  legendOrder,
  transform,
  onColorChange,
  onActiveChange,
  prevalenceAsWidth
}: Props) => {
  const tr = useTr();
  const data = legendOrder.map(id => legendData[id]);
  const hasPrevalence = typeof legendData.model_rank_score !== 'undefined';
  const hasOnlyPrevalence = hasPrevalence && data.length === 1;

  const { wordAttributes } = useAppSelector(getTesterActiveTab);

  const getGraphLabel = useGetGraphLabel();

  useEffect(() => {
    document.querySelectorAll('.legend-label').forEach(element => {
      setTimeout(() => wrap(element as SVGTextContentElement));
    });
  });

  return (
    <g transform={transform}>
      <g>
        {data
          .filter(entry => !!entry)
          .filter(({ field }) => {
            if (prevalenceAsWidth && !hasOnlyPrevalence) {
              return field !== 'model_rank_score';
            }
            return true;
          })
          .map(({ field, label, color, active }, idx) => (
            <g key={field} style={pointerStyle}>
              <ColorPickerPopup
                offset={10}
                color={color}
                onConfirm={hex => {
                  onColorChange(field, hex);
                  storeColor(field, hex);
                }}
                placement="left-start"
              >
                {({ ref, setOpen }) => (
                  <rect
                    onClick={active ? setOpen : undefined}
                    ref={ref}
                    width={25}
                    height={25}
                    x={10}
                    y={idx * 35}
                    fill={active ? color : '#bbb'}
                    rx={5}
                    ry={5}
                  />
                )}
              </ColorPickerPopup>
              <SVGText
                className="legend-label"
                x={50}
                y={16 + idx * 35}
                fill={active ? '#000' : '#bbb'}
                fontSize={14}
                onClick={() => {
                  onActiveChange(field, !active);
                }}
              >
                {getGraphLabel(field, label)}
              </SVGText>
            </g>
          ))}
      </g>
      {hasPrevalence && !hasOnlyPrevalence && prevalenceAsWidth && (
        <BarWidthLegend
          baseY={(data.length - 1) * 35 + 6}
          active={legendData.model_rank_score.active}
          onClick={() => {
            const { active } = legendData.model_rank_score;
            onActiveChange('model_rank_score', !active);
          }}
        >
          {tr(
            `${wordAttributes.find(option => option.value === 'model_rank_score')?.option?.label}`
          )}
        </BarWidthLegend>
      )}
    </g>
  );
};

export default GraphLegend;
