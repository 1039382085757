import { Button } from '@mui/material';
import backgroundDoodleOrnament from 'assets/background-doodle.png';
import { AuthSummary } from 'components/auth/AuthSummary';
import { AuthSummarySection } from 'components/auth/AuthSummarySection';
import { Ornament } from 'components/Ornament';
import {
  RegistrationFormContent,
  RegistrationFormContentData
} from 'components/register/RegistrationFormContent/RegistrationFormContent';
import { PricingSelect } from 'features/pricing/PricingSelect';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

type Props = RegistrationFormContentData & {
  goToPrevStep: () => void;
};

export const PricingPlanStep = ({
  status,
  isValid,
  isSubmitting,

  goToPrevStep
}: Props) => {
  const summary = useMemo(
    () => (
      <AuthSummary slogan="register.slogan">
        <AuthSummarySection
          title="register.pricing_plan_step.auth_summary.basic.title"
          items={[
            'register.pricing_plan_step.auth_summary.basic.item_1',
            'register.pricing_plan_step.auth_summary.basic.item_2',
            'register.pricing_plan_step.auth_summary.basic.item_3'
          ]}
        />
        <AuthSummarySection
          title="register.pricing_plan_step.auth_summary.premium.title"
          items={[
            'register.pricing_plan_step.auth_summary.premium.item_1',
            'register.pricing_plan_step.auth_summary.premium.item_2',
            'register.pricing_plan_step.auth_summary.premium.item_3'
          ]}
        />
        <AuthSummarySection
          title="register.pricing_plan_step.auth_summary.business.title"
          items={[
            'register.pricing_plan_step.auth_summary.business.item_1',
            'register.pricing_plan_step.auth_summary.business.item_2',
            'register.pricing_plan_step.auth_summary.business.item_3'
          ]}
        />
      </AuthSummary>
    ),
    []
  );

  const actions = useMemo(
    () => (
      <>
        <Button color="primary" size="large" onClick={goToPrevStep}>
          <FormattedMessage id="common.back" />
        </Button>
        <Button variant="contained" size="large" color="primary" type="submit" disabled={!isValid}>
          <FormattedMessage id="register.pricing_plan_step.start_trial.button" />
        </Button>
      </>
    ),
    [isValid, goToPrevStep]
  );

  return (
    <RegistrationFormContent
      header="register.pricing_plan_step.header"
      isLoading={isSubmitting}
      status={status}
      actions={actions}
      summary={summary}
      ornaments={<Ornament src={backgroundDoodleOrnament} bottom="0%" right="0%" />}
    >
      <PricingSelect />
    </RegistrationFormContent>
  );
};
