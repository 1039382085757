import {
  Avatar,
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { ColoredTypography } from 'components/ColoredTypography';
import FlexContainer from 'components/FlexContainer';
import { useModal } from 'components/modals';
import {
  Card,
  Header,
  LeftSideContainer,
  StyledCardContent
} from 'components/profile/components/common';
import Toast from 'components/toasts/Toast';
import LocalStorageKey from 'config/localStorageKey';
import { getCurrentModelLanguageAndCountry } from 'features/aiWriter/store/selectors';
import { Loader } from 'features/churnModal/loader';
import { getIsApiCustomer } from 'features/customer/store/selectors';
import { useCustomerPreferences } from 'features/customerPreferences/useCustomerPreferences';
import { useUpdateCustomerPreferences } from 'features/customerPreferences/useUpdateCustomerPreferences';
import { getEmbeddingModelsByLanguageAndAudience } from 'features/embeddingModels/store/selectors';
import { useEnableSidebarOnMount } from 'features/pageSidebar/useSidebarStore';
import {
  Language,
  ProfileInformationLanguageField
} from 'features/profilePage/ProfileInformationLanguageField';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { assertNonNullable } from 'utils/typescript/nonNullable';

type Props = {
  username: string;
  email: string;
  timezone: string;
  groupName: string;
  avatarImage?: string;
};

export const ProfileInformationCard = ({
  username,
  email,
  timezone,
  groupName,
  avatarImage
}: Props) => {
  const { showModal } = useModal();
  const { data: preferences, isLoading: isLoadingCustomerPreferences } = useCustomerPreferences();
  const { mutateAsync: updatePreferences } = useUpdateCustomerPreferences();

  const models = useAppSelector(getEmbeddingModelsByLanguageAndAudience);
  const { currentModelCountry, currentModelLanguage } = useAppSelector(
    getCurrentModelLanguageAndCountry
  );

  const isApiCustomer = useAppSelector(getIsApiCustomer);
  const languageToolEnabled = preferences?.languageToolEnabled ?? false;

  const [checkboxChecked, setCheckboxChecked] = useState(languageToolEnabled);

  useEffect(() => {
    if (preferences && !isLoadingCustomerPreferences) {
      setCheckboxChecked(preferences.languageToolEnabled);
    }
  }, [preferences, isLoadingCustomerPreferences]);

  const selectedLanguageModelId = models.find(
    model =>
      model.language === preferences?.preferredLanguage &&
      model.country === preferences.preferredLanguageCountry
  )?.id;
  const defaultLanguageModelId = models.find(
    model => model.language === currentModelLanguage && model.country === currentModelCountry
  )?.id;

  const initialValues = {
    language: selectedLanguageModelId || defaultLanguageModelId,
    country: currentModelCountry
  };

  useEnableSidebarOnMount();

  const handleCheckbox = () => {
    setCheckboxChecked(!checkboxChecked);
    updatePreferences({ languageToolEnabled: !checkboxChecked }).then(() => {
      Toast.success('profile.success');
    });
  };

  const handleSubmit = (values: Language) => {
    const embeddingModel = models.find(model => model.id === values.language);
    const updatedValues = {
      language: embeddingModel?.language,
      country: embeddingModel?.country
    };
    const embeddingModelId = embeddingModel?.id;
    assertNonNullable(embeddingModelId, 'Missing model!');

    updatePreferences({
      preferredLanguage: updatedValues.language,
      preferredLanguageCountry: updatedValues.country
    }).then(() => {
      Toast.success('profile.success');
    });
    localStorage.setItem(LocalStorageKey.AiWriterModel, String(embeddingModelId));
  };

  return (
    <Card variant="outlined">
      <Header>
        <LeftSideContainer>
          <Typography variant="h5">
            <FormattedMessage id="profile.header" />
          </Typography>
          <ColoredTypography variant="body1" color="textSecondary">
            <FormattedMessage id="profile.subheader" />
          </ColoredTypography>
        </LeftSideContainer>

        <StyledAvatar src={avatarImage ?? undefined}>{username[0]}</StyledAvatar>
      </Header>
      <StyledCardContent>
        <Table>
          <TableHead>
            <TableRow>
              <StyledHeaderTableCell>
                <ColoredTypography variant="subtitle2" color="textSecondary">
                  <FormattedMessage id="profile.column.name" />
                </ColoredTypography>
              </StyledHeaderTableCell>
              <StyledHeaderTableCell>
                <ColoredTypography variant="subtitle2" color="textSecondary">
                  <FormattedMessage id="profile.column.email" />
                </ColoredTypography>
              </StyledHeaderTableCell>
              <StyledHeaderTableCell>
                <ColoredTypography variant="subtitle2" color="textSecondary">
                  <FormattedMessage id="profile.column.team" />
                </ColoredTypography>
              </StyledHeaderTableCell>
              <StyledHeaderTableCell>
                <ColoredTypography variant="subtitle2" color="textSecondary">
                  <FormattedMessage id="profile.column.time_zone" />
                </ColoredTypography>
              </StyledHeaderTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <StyledTableCell>
                <Typography variant="body1">{username}</Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography variant="body1">{email}</Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography variant="body1">{groupName}</Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography variant="body1">{timezone}</Typography>
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
        {!isApiCustomer && (
          <FlexContainer gap="four">
            <FooterContainer>
              <Button color="primary" variant="outlined" onClick={() => showModal('EDIT_PROFILE')}>
                <FormattedMessage id="profile.edit_profile" />
              </Button>
            </FooterContainer>
            {isLoadingCustomerPreferences ? (
              <Loader />
            ) : (
              <>
                <LanguageToolContainer>
                  <Typography variant="subtitle2">
                    <FormattedMessage id="profile.settings.language_tool.headline" />
                  </Typography>
                  <CheckboxContainer>
                    <StyledCheckbox onChange={handleCheckbox} checked={checkboxChecked} />
                    <Typography variant="body1">
                      <FormattedMessage id="profile.settings.language_tool.checkbox_label" />
                    </Typography>
                  </CheckboxContainer>
                </LanguageToolContainer>
                <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                  <Form>
                    <ProfileInformationLanguageField
                      name="language"
                      models={models}
                      onSubmit={handleSubmit}
                    />
                  </Form>
                </Formik>
              </>
            )}
          </FlexContainer>
        )}
      </StyledCardContent>
    </Card>
  );
};

const StyledAvatar = styled(Avatar)`
  && {
    width: 85px;
    height: 85px;
  }
`;

const FooterContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacings.four};
`;

const StyledCheckbox = styled(Checkbox)`
  // The checkbox is not aligned with the text without this
  margin-top: -9px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacings.two};
`;

const LanguageToolContainer = styled(FlexContainer)`
  gap: ${({ theme }) => theme.spacings.two};
`;

const StyledHeaderTableCell = styled(TableCell)`
  padding: 0;
  padding-bottom: ${({ theme }) => theme.spacings.two};
`;

const StyledTableCell = styled(TableCell)`
  padding: 0;
  padding-top: ${({ theme }) => theme.spacings.two};
`;
