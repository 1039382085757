import { useQuery } from '@tanstack/react-query';
import Toast from 'components/toasts/Toast';
import { getIsAuthenticated } from 'features/customer/store/selectors';
import { setOnboardingChecklistData } from 'features/onboardingJourney/checklist/useOnboardingChecklist';
import { handleApiCommonErrors } from 'services/api/utils/handleApiCommonErrors';
import { httpGetChecklist } from 'services/backofficeIntegration/http/endpoints/onboardingChecklist/httpGetChecklist';
import { useAppSelector } from 'store/hooks';

const checklistKey = 'onboarding';

export function useFetchOnboardingChecklist() {
  const isAuthenticated = useAppSelector(getIsAuthenticated);

  return useQuery({
    enabled: isAuthenticated,
    queryKey: httpGetChecklist.makeQueryKey(checklistKey),
    queryFn: () => httpGetChecklist.callEndpoint(checklistKey),
    onSuccess: data => {
      if (data && data.status) {
        setOnboardingChecklistData(data.data);
        return;
      }

      const errorId = handleApiCommonErrors(data.message);
      Toast.backendError(errorId);
    },
    onError() {
      Toast.apiError();
    }
  });
}
