import { ApiModularWorkflowStep } from 'features/modular-workflow/builder/types-api';
import { BuilderModularWorkflow } from 'features/modular-workflow/builder/types-builder';
import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { mutationEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

export type ResponseDto = BaseSuccessDto<ApiModularWorkflowStep[]>;

export const httpUpdateModularWorkflow = mutationEndpoint({
  callEndpoint: (workflow: BuilderModularWorkflow) => {
    return backofficeClient
      .patch<ResponseDto>(`/workflows/${workflow.id}`, workflow)
      .then(response => response.data.data);
  }
});
