import { Typography } from '@mui/material';
import { InputRendererContext } from 'features/modular-workflow/runner/settings/InputRenderer';
import { ReactElement, ReactNode, useContext, useId } from 'react';
import styled from 'styled-components';

type Props = {
  children: (elementId: string) => ReactElement;
  endAdornment?: ReactNode;
};

export const BaseSetting = ({ children, endAdornment }: Props) => {
  const elementId = useId();

  const { inputSettings: settings, inputErrorList } = useContext(InputRendererContext);

  return (
    <Root>
      <InputHeader>
        <Typography variant="subtitle2" color={inputErrorList.length > 0 ? 'red' : undefined}>
          <label className="label" htmlFor={elementId}>
            {settings.name}
            {settings.is_required ? '*' : ''}
          </label>
        </Typography>

        {endAdornment}
      </InputHeader>

      {children(elementId)}
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
`;

const InputHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacings.medium};
  margin-bottom: ${({ theme }) => theme.spacings.small};
`;
