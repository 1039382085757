import LocalStorageKey from 'config/localStorageKey';
import getMapperInitValue from 'features/aiTester/modals/setup/getMapperInitValue';
import {
  audienceField,
  keywords2Field,
  keywordsField,
  mapperField,
  modelField,
  outputTypeField,
  TesterSetupValues,
  textBodyField
} from 'features/aiTester/modals/setup/SetupModal';
import useGetModelAudiences from 'features/audiences/hooks/useGetModelAudiences';
import getAudienceInitValue from 'features/audiences/utils/getAudienceInitValue';
import { ModelAutocomplete } from 'features/embeddingModels/ModelAutocomplete';
import {
  getEmbeddingModelsByLanguage,
  getGetEmbeddingModelLanguageAndCountryById,
  getGetEmbeddingModelMappersWithFallbackById
} from 'features/embeddingModels/store/selectors';
import {
  useGetDefaultOutputTypeByLanguageCountry,
  useGetPrefillValueByLanguageAndOutputType
} from 'features/textGenerator/utils/useGetPromptsOthers';
import { useFormikContext } from 'formik';
import React from 'react';
import { useSelector } from 'react-redux';
import { useAppSelector } from 'store/hooks';

const SetupModelSelect = (): React.ReactElement => {
  const { setFieldValue } = useFormikContext<TesterSetupValues>();

  const languageModels = useAppSelector(getEmbeddingModelsByLanguage);

  const getModelMappersWithFallbackById = useAppSelector(
    getGetEmbeddingModelMappersWithFallbackById
  );
  const getCountryProjects = useGetModelAudiences();

  const getModelLanguageCountryById = useSelector(getGetEmbeddingModelLanguageAndCountryById);

  const getDefaultOutputTypeByLanguageCountry = useGetDefaultOutputTypeByLanguageCountry();

  const getPrefillValue = useGetPrefillValueByLanguageAndOutputType();

  const onChange = (embeddingModelId: string) => {
    const { language: modelLanguage, country: modelCountry } =
      getModelLanguageCountryById(embeddingModelId);
    const countryMappers = getModelMappersWithFallbackById(embeddingModelId);
    const countryProjects = getCountryProjects(embeddingModelId);

    const defaultOutputType = getDefaultOutputTypeByLanguageCountry(modelLanguage, modelCountry);

    setFieldValue(
      audienceField,
      getAudienceInitValue(countryProjects, LocalStorageKey.AiWriterProject)
    );
    setFieldValue(mapperField, getMapperInitValue(countryMappers));
    setFieldValue(outputTypeField, defaultOutputType);

    setFieldValue(textBodyField, getPrefillValue(modelLanguage, defaultOutputType, 'text'));
    setFieldValue(keywordsField, getPrefillValue(modelLanguage, defaultOutputType, 'keywords'));
    setFieldValue(keywords2Field, getPrefillValue(modelLanguage, defaultOutputType, 'keywords2'));

    localStorage.setItem(LocalStorageKey.TesterModel, String(embeddingModelId));
  };

  return <ModelAutocomplete name={modelField} models={languageModels} onSelect={onChange} />;
};

export default SetupModelSelect;
