import { Button, Typography } from '@mui/material';
import welcomeOnboardingImage from 'assets/onboarding/welcome.svg';
import FlexContainer from 'components/FlexContainer';
import { getRoutePath } from 'config/routes';
import { useDisableSidebarOnMount } from 'features/pageSidebar/useSidebarStore';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled from 'styled-components';

export function OnboardingWelcome() {
  const navigate = useNavigate();

  useDisableSidebarOnMount();

  const handleClick = () => {
    navigate(getRoutePath('onboardingSurvey'));
  };

  return (
    <Root>
      <img src={welcomeOnboardingImage} alt="" />

      <FlexContainer gap="small" justifyContent="center" alignItems="center">
        <Typography variant="h4" fontWeight="bold">
          <FormattedMessage id="aiWriter.onboarding_journey.welcome_view.header" />
        </Typography>
      </FlexContainer>
      <Typography variant="body1" fontWeight="bold">
        <FormattedMessage id="aiWriter.onboarding_journey.welcome_view.body_1" />
      </Typography>
      <Typography variant="body1" fontWeight="bold">
        <FlexContainer alignItems="center">
          <FormattedMessage
            id="aiWriter.onboarding_journey.welcome_view.body_2"
            values={{ span: (chunks: string) => <span>{chunks}</span> }}
          />
        </FlexContainer>
      </Typography>
      <Button
        {...withGtmInteraction(gtmIds.onboardingJourney.openView.choice)}
        variant="contained"
        onClick={handleClick}
      >
        <FormattedMessage id="aiWriter.onboarding_journey.welcome_view.button" />
      </Button>
    </Root>
  );
}

const Root = styled(FlexContainer).attrs({
  gap: 'large',
  alignItems: 'center',
  justifyContent: 'center'
})`
  margin-top: 3rem;
`;
