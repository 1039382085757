import dimensionsIcon from 'assets/icon-dimensions.svg';
import { MenuDropdownItem } from 'components/MenuDropdown/MenuDropdownItem';
import { getRoutePath } from 'config/routes';
import { NavLink } from 'features/pageSidebar/sidebarItems/actions/account/UnstyledLink';
import { getPricingPlan } from 'features/pricing/store/selectors';
import { FormattedMessage } from 'react-intl';
import gtmIds from 'services/tracking/GTMIds';
import { useAppSelector } from 'store/hooks';

type Props = {
  onClose: () => void;
};

const DimensionsItem = ({ onClose }: Props) => {
  const isNotEnterprisePricing = useAppSelector(getPricingPlan) !== 'enterprise';

  if (isNotEnterprisePricing) {
    return null;
  }

  return (
    <NavLink to={getRoutePath('dimensionsManagement')}>
      <MenuDropdownItem
        gtmId={gtmIds.sidebar.accountMenuItem}
        icon={<img src={dimensionsIcon} alt="profile" />}
        disabled={isNotEnterprisePricing}
        onClose={onClose}
      >
        <FormattedMessage id="layout.sidebar.actions.profile.dimensions" />
      </MenuDropdownItem>
    </NavLink>
  );
};

export default DimensionsItem;
