import {
  CampaignRounded,
  EmojiObjectsRounded,
  Flag,
  HelpRounded,
  OndemandVideoRounded,
  SchoolRounded,
  SmartDisplayRounded,
  SupportAgentRounded
} from '@mui/icons-material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { GettingStarted } from 'components/layout/toolbar/modals/HelpAndInfo/GettingStartedComponent';
import { Section } from 'components/layout/toolbar/modals/HelpAndInfo/styles';
import { VideoTutorials } from 'components/layout/toolbar/modals/HelpAndInfo/VideoComponent';
import { ReactNode, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import gtmIds from 'services/tracking/GTMIds';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

const TABS = {
  gettingStarted: 'gettingStarted',
  videoTutorials: 'videoTutorials'
} as const;

type Tabs = typeof TABS[keyof typeof TABS];

type Props = {
  defaultTab?: Tabs;
  onClose?: () => void;
};

export function LearningCenter({ defaultTab = 'gettingStarted', onClose }: Props) {
  const translate = useTr();

  const [tabOpen, setTabOpen] = useState<Tabs>(defaultTab);

  const handleTabClick = (tab: Tabs) => {
    setTabOpen(tab);
  };

  return (
    <Root>
      <LeftRow>
        <Section>
          <ContentBlock
            title="layout.sidebar.actions.help.getting_started.title"
            icon={<Flag color="disabled" />}
            onClick={() => handleTabClick(TABS.gettingStarted)}
            gtmId={gtmIds.sidebar.learningCenter.gettingStarted}
            isSelected={tabOpen === TABS.gettingStarted}
          />
        </Section>
        <Section>
          <ContentBlock
            title="layout.sidebar.actions.help.video_tutorials.title"
            icon={<SmartDisplayRounded />}
            onClick={() => handleTabClick(TABS.videoTutorials)}
            gtmId={gtmIds.sidebar.learningCenter.videoTutorials}
            isSelected={tabOpen === TABS.videoTutorials}
          />
          <LinkBlock
            title="layout.sidebar.actions.help.help_center.title"
            icon={<HelpRounded />}
            link={translate('external_links.app_helpscout')}
            gtmId={gtmIds.sidebar.learningCenter.helpCenter}
          />
          <LinkBlock
            title="layout.sidebar.actions.help.live_webinars.title"
            icon={<OndemandVideoRounded />}
            link={translate('external_links.live_webinars')}
            gtmId={gtmIds.sidebar.learningCenter.liveWebinars}
          />
          <LinkBlock
            title="layout.sidebar.actions.help.neuroflash_academy.title"
            icon={<SchoolRounded />}
            link={translate('external_links.neuroflash_academy')}
            gtmId={gtmIds.sidebar.learningCenter.neuroflashAcademy}
          />
        </Section>
        <Section>
          <LinkBlock
            title="layout.sidebar.actions.help.product_updates.title"
            icon={<CampaignRounded />}
            link={translate('external_links.product_updates')}
            gtmId={gtmIds.sidebar.learningCenter.productUpdates}
          />
          <LinkBlock
            title="layout.sidebar.actions.help.request_feature.title"
            icon={<EmojiObjectsRounded />}
            link={translate('external_links.request_feature')}
            gtmId={gtmIds.sidebar.learningCenter.requestFeature}
          />
        </Section>
        <ContentBlock
          title="layout.sidebar.actions.help.contact_support.title"
          icon={<SupportAgentRounded />}
          onClick={() => {
            onClose?.();
            window.Beacon?.('open');
          }}
          gtmId={gtmIds.sidebar.learningCenter.contactSupport}
        />
      </LeftRow>
      <RightRow>
        {tabOpen === TABS.gettingStarted ? (
          <GettingStarted onClose={onClose} />
        ) : (
          <VideoTutorials />
        )}
      </RightRow>
    </Root>
  );
}

const Root = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // this is needed to make the left column background color extend to the bottom of the modal
  height: calc(100% - 80px);

  padding: ${({ theme }) => `${theme.spacings.medium} ${theme.spacings.xmedium}`};
`;

const LeftRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  padding: ${({ theme }) => theme.spacings.medium};
  align-items: flex-start;
  background-color: ${({ theme }) => theme.colors.successHover};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
`;

const RightRow = styled.div`
  display: flex;
  flex-direction: column;

  max-width: 800px;
`;

type BlockProps = {
  title: string;
  icon: ReactNode;
  onClick?: () => void;
  gtmId: string;
  isSelected?: boolean;
};

type LinkProps = {
  title: string;
  icon: ReactNode;
  link: string;
  gtmId: string;
};

const ContentBlock = ({ title, icon, onClick, gtmId, isSelected }: BlockProps) => (
  <Block id={gtmId} onClick={onClick} $isSelected={isSelected}>
    <FlexContainer direction="row" justifyContent="flex-start" gap="small" alignItems="center">
      <StyledListItemIcon>{icon}</StyledListItemIcon>
      <Typography variant="body2">
        <FormattedMessage id={title} />
      </Typography>
    </FlexContainer>
  </Block>
);

const LinkBlock = ({ title, icon, link, gtmId }: LinkProps) => (
  <Block id={gtmId} href={link} target="_blank">
    <FlexContainer direction="row" justifyContent="flex-start" gap="small" alignItems="center">
      <StyledListItemIcon>{icon}</StyledListItemIcon>
      <Typography variant="body2">
        <FormattedMessage id={title} />
      </Typography>
    </FlexContainer>

    <StyledListItemIcon>
      <OpenInNewIcon fontSize="small" className="openInNew" />
    </StyledListItemIcon>
  </Block>
);

const Block = styled.a<{ $isSelected?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  color: inherit;
  outline: 0;
  width: 100%;
  padding: ${({ theme }) => `${theme.spacings.one} ${theme.spacings.two}`};
  margin-bottom: ${({ theme }) => theme.spacings.one};
  background-color: ${({ theme, $isSelected }) =>
    $isSelected ? theme.colors.primaryColorSelected : 'initial'};

  border-radius: ${({ theme }) => theme.borderRadius.one};
  transition: background-color 0.1s linear;
  cursor: pointer;

  .openInNew {
    visibility: hidden;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryColorHover};
    text-decoration: none;
    color: inherit;
    outline: 0;
    cursor: pointer;

    .openInNew {
      visibility: visible;
    }
  }
`;

const StyledListItemIcon = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;

  &,
  > svg {
    color: ${({ theme }) => theme.colors.componentsIconActive};
  }
`;
