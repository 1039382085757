import { useState } from 'react';
import useWindowSize from 'utils/hooks/useWindowSize';

export const mobileWindowWidth = 922;
export const mobileWindowWidthCSS = `${mobileWindowWidth}px`;

export function useMobileVersion() {
  const { width } = useWindowSize();
  const [continueMobile, setContinueMobile] = useState(false);

  const isMobileWidth = width === undefined ? false : width <= mobileWindowWidth;

  return [isMobileWidth && !continueMobile, setContinueMobile] as const;
}
