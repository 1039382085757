import { ModalCommonProps } from 'components/modals/RootModal';
import { ChangePricingPlanModal } from 'features/pricing/modals/ChangePricingPlanModal';
import { usePostHog } from 'posthog-js/react';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { GAEvents } from 'services/tracking/GAEvents';

type Props = ModalCommonProps;

export const UpdateSubscriptionModal = ({ closeModal }: Props) => {
  const postHog = usePostHog();

  useEffect(() => {
    GAEvents.modalSeen({
      modal: 'Generic upgrade modal'
    });

    postHog?.capture('Upgrade modal seen', {
      modal: 'Generic upgrade modal'
    });
  }, [postHog]);

  return (
    <ChangePricingPlanModal
      onClose={closeModal}
      headline={<FormattedMessage id="update_subscription.headline" values={{ br: <br /> }} />}
      subHeadline={
        <FormattedMessage id="update_subscription.sub_headline" values={{ br: <br /> }} />
      }
    />
  );
};
