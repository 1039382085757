import { Add, ArrowDropDown, Close } from '@mui/icons-material';
import { Tooltip, Typography } from '@mui/material';
import tabFolderIcon from 'assets/icon-tab-folder.svg';
import EditableContent from 'components/EditableContent';
import FlexContainer from 'components/FlexContainer';
import { IconButton } from 'components/iconButtons/IconButton';
import { MenuDropdown } from 'components/MenuDropdown/MenuDropdown';
import { MenuDropdownItem } from 'components/MenuDropdown/MenuDropdownItem';
import NoTabsPlaceholder from 'components/TabbedCard/NoTabsPlaceholder';
import { ErrorBoundary } from 'features/react-error-boundary/ErrorBoundary';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { maxProjectNameLength } from 'store/utils/tabUtils';
import styled from 'styled-components';
import { resetButton } from 'styles/styledComponents/resets';
import { TabHeader } from 'types/ReduxStateTypes';
import useDropdown from 'utils/hooks/useDropdown';

type Props = {
  title?: string;
  activeTab: string | null;
  className?: string;
  tabsHeaders: TabHeader[];
  noTabsMessage: string;
  customContentContainer?: boolean;
  extra?: React.ReactNode;
  createButtonId?: string;
  children: React.ReactNode;

  onClose: (id: string) => void;
  onCreate?: () => void;
  onSelect: (id: string) => void;
  onRename?: (name: string) => void;
  onBack?: () => void;
  onLastClose?: () => void;
};

const TabbedCard = ({
  activeTab,
  className,
  title,
  tabsHeaders,
  noTabsMessage,
  customContentContainer,
  extra,
  createButtonId,
  children,

  onClose,
  onCreate,
  onSelect,
  onRename,
  onBack,
  onLastClose
}: Props) => {
  const { anchorElement, isOpen, close, open } = useDropdown();

  const header = useMemo(() => {
    const currentTab = tabsHeaders.find(t => t.id === activeTab);

    if (currentTab) {
      const name = (
        <Tooltip title={<FormattedMessage id="common.project_breadcrumbs.edit" />}>
          <Name variant="subtitle1">{currentTab.name}</Name>
        </Tooltip>
      );

      const extra = currentTab.modelData && (
        <Name variant="overline">{`(${currentTab.modelData.language}-${currentTab.modelData.country})`}</Name>
      );

      if (onRename) {
        return (
          <>
            <EditableContent
              value={currentTab.name}
              onSave={onRename}
              maxLength={maxProjectNameLength}
            >
              {name}
            </EditableContent>
            {extra}
          </>
        );
      }

      return (
        <>
          {name}
          {extra}
        </>
      );
    }

    return null;
  }, [activeTab, tabsHeaders, onRename]);

  const content = useMemo(() => {
    const body = (
      <ErrorBoundary>
        {activeTab ? children : <NoTabsPlaceholder message={noTabsMessage} />}
      </ErrorBoundary>
    );

    return customContentContainer ? body : <Content>{body}</Content>;
  }, [customContentContainer, activeTab, noTabsMessage, children]);

  const closeTab = (id: string) => {
    if (tabsHeaders.length === 1) {
      onLastClose?.();
    }
    onClose?.(id);
  };

  return (
    <Root className={className}>
      <Header>
        <HeaderContent gap="xmedium" direction="row">
          <Tooltip title={<FormattedMessage id="common.project_breadcrumbs.see_all_dok" />}>
            <OverviewButton as={onBack ? 'button' : 'div'} onClick={onBack}>
              <Feature gap="small" direction="row">
                <FeatureIcon src={tabFolderIcon} alt="tab-folder" />
                <Name variant="subtitle1">
                  <FormattedMessage id={title ?? 'common.all_projects'} />
                </Name>
              </Feature>
            </OverviewButton>
          </Tooltip>

          <Divider>/</Divider>

          <Project gap="small" direction="row">
            {header}

            <MenuDropdown
              trigger={
                <Action onClick={open}>
                  <Tooltip title={<FormattedMessage id="common.project_breadcrumbs.dropdown" />}>
                    <ArrowDropDownIcon />
                  </Tooltip>
                </Action>
              }
              isOpen={isOpen}
              anchorElement={anchorElement}
              close={close}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
              {tabsHeaders.map(t => (
                <MenuDropdownItem
                  key={t.id}
                  onClick={() => onSelect(t.id)}
                  onClose={close}
                  icon={
                    <IconButton onClick={() => closeTab(t.id)} icon={<CloseIcon />} size="large" />
                  }
                >
                  {t.name}
                </MenuDropdownItem>
              ))}
            </MenuDropdown>
          </Project>

          {onCreate && (
            <IconButton
              id={createButtonId}
              onClick={() => onCreate?.()}
              icon={<CreateIcon />}
              tooltip={<FormattedMessage id="common.new_document" />}
              size="large"
            />
          )}
        </HeaderContent>

        {extra}
      </Header>

      {content}
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  padding-right: ${({ theme }) => theme.spacings.medium};
`;

const HeaderContent = styled(FlexContainer)`
  align-items: center;
  height: 30px;
`;

const OverviewButton = styled.button`
  ${resetButton}
`;

const Feature = styled(FlexContainer)``;

const FeatureIcon = styled.img``;

const Divider = styled.div``;

const Project = styled(FlexContainer)`
  align-items: center;
`;

const Action = styled.button`
  ${resetButton}
`;

const Name = styled(Typography)`
  cursor: pointer;

  && {
    color: ${({ theme }) => theme.colors.blackInactive};
  }
`;

const CreateIcon = styled(Add)`
  color: ${({ theme }) => theme.colors.blackInactive};
`;

const CloseIcon = styled(Close)`
  color: ${({ theme }) => theme.colors.blackInactive};
`;

const ArrowDropDownIcon = styled(ArrowDropDown)`
  color: ${({ theme }) => theme.colors.blackInactive};
`;

const Content = styled.div`
  background: #fff;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  margin-bottom: 1.5rem;
  flex: 1 1 auto;

  @media (min-width: 576px) {
    padding: 1rem;
  }

  @media (min-width: 992px) {
    padding: 1.5rem;
  }
`;

export default TabbedCard;
