import { ColoredTypography } from 'components/ColoredTypography';
import FlexContainer from 'components/FlexContainer';
import TextInputField from 'components/forms/TextInputField';
import { LoadingButton } from 'components/LoadingButton';
import { useModal } from 'components/modals';
import { getGetIsFeatureAvailable } from 'features/features/store/selectors';
import { getIsSeoLimitReached } from 'features/pricing/store/selectors';
import { Form, useFormikContext } from 'formik';
import { FormattedMessage } from 'react-intl';
import gtmIds from 'services/tracking/GTMIds';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

import SearchEngineAutocomplete from './SearchEngineAutocomplete';

export const searchEngineField = 'searchEngine';
export const keywordField = 'keyword';

export type SeoAnalysisFormValues = {
  [searchEngineField]: string;
  [keywordField]: string;
};

type Props = {
  isLoading?: boolean;
};

const MainSubStepFormSection = ({ isLoading }: Props) => {
  const tr = useTr();

  const isSeoAnalysisAvailable = useAppSelector(getGetIsFeatureAvailable)('seoAnalysis');
  const isLimitReached = useAppSelector(getIsSeoLimitReached);

  const { isValid } = useFormikContext<SeoAnalysisFormValues>();

  const isInputDisabled = !isSeoAnalysisAvailable || isLimitReached;
  const isButtonDisabled = !isSeoAnalysisAvailable || !isValid || isLimitReached;

  return (
    <Form>
      <Box gap="medium">
        <SearchEngineAutocomplete name={searchEngineField} disabled={isInputDisabled} />
        <TextInputField
          name={keywordField}
          disabled={isInputDisabled}
          variant="outlined"
          label={tr('aiWriter.inspirations.seoAnalysis.keywords_field.placeholder')}
          placeholder={tr('aiWriter.inspirations.seoAnalysis.keywords_field.placeholder')}
        />
        <PoweredBy />
        <LoadingButton
          id={gtmIds.aiWriter.seo.analyse}
          loading={isLoading}
          variant="contained"
          color="primary"
          type="submit"
          disabled={isButtonDisabled}
          fullWidth={true}
        >
          <FormattedMessage id="common.analyse" />
        </LoadingButton>
      </Box>
    </Form>
  );
};

const Box = styled(FlexContainer).attrs({ gap: 'medium' })`
  padding-top: 5px;
`;
const PoweredBy = () => {
  const { showModal } = useModal();

  return (
    <ColoredTypography variant="body1" color="blackMediumEmphasis" textAlign="right">
      <FormattedMessage
        id="aiWriter.inspirations.seoAnalysis.powered_by_seo"
        values={{
          button: (text: string) => (
            <SeoButton type="button" onClick={() => showModal('SEO', { size: 480 })}>
              {text}
            </SeoButton>
          )
        }}
      />
    </ColoredTypography>
  );
};

const SeoButton = styled.button`
  border: 0;
  background: none;
  cursor: pointer;
  font: inherit;
  font-weight: bold;
`;

export default MainSubStepFormSection;
