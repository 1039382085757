import { Button, Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { useActionImageSectionTile } from 'features/onboardingJourney/useActionImageSectionTile';
import styled from 'styled-components';

export const ActionImageSectionTile = ({ selectedTile }: { selectedTile: string }) => {
  const tileList = useActionImageSectionTile();
  const tileData = tileList.find(({ type }) => type === selectedTile);
  if (!tileData) {
    return null;
  }

  const { title, subtitle, images, imageAlt, actions } = tileData;
  const firstImage = images[0];

  return (
    <StepTile>
      <FlexContainer>
        <Typography variant="body1">{title}</Typography>
        {subtitle && <Typography variant="caption">{subtitle}</Typography>}
      </FlexContainer>

      <CenterAlignedItems>
        {actions.map(action =>
          action.onClick ? (
            <ActionButton key={action.title} variant="contained" onClick={action.onClick}>
              {action.title}
            </ActionButton>
          ) : (
            <Typography variant="caption">{action.title}</Typography>
          )
        )}
      </CenterAlignedItems>

      <Image src={firstImage} alt={imageAlt} />
    </StepTile>
  );
};

const StepTile = styled(FlexContainer)`
  padding: ${({ theme }) => theme.spacings.three};
  border-radius: ${({ theme }) => theme.borderRadius.one};
  background-color: ${({ theme }) => theme.colors.secondaryColorMain};
  margin: ${({ theme }) => `0 ${theme.spacings.one}`};

  cursor: pointer;

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.three};
`;

const ActionButton = styled(Button)`
  width: 100%;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
`;

const CenterAlignedItems = styled(FlexContainer)`
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacings.three};
`;
