import { CircularProgress, Paper, TablePagination, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ReactComponent as TesterIcon } from 'assets/home/projects-icons/tester-home-projects.svg';
import { ReactComponent as WriterIcon } from 'assets/home/projects-icons/writer-home-projects.svg';
import { SearchField } from 'components/SearchField';
import { getRoutePath } from 'config/routes';
import dayjs from 'dayjs';
import { loadTabThunk as loadTesterTabThunk } from 'features/aiTester/store/actions/tabs/thunks/loadTabThunk';
import { testerProjectFromApi } from 'features/aiTester/store/utils/mappers/testerProjectMappers';
import { FlagIcon } from 'features/aiWriter/AiWriterProjectOverview/ProjectsTable';
import { useGetAndLoadProjectById } from 'features/aiWriter/useGetAndLoadProjectById';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { ChangeEvent, useState } from 'react';
import { useNavigate } from 'react-router';
import AiTesterAPI from 'services/api/aiTester';
import {
  GeneratedFiles,
  GeneratedFilesDto,
  httpGetGeneratedFiles,
  PaginatedGeneratedFilesParams
} from 'services/backofficeIntegration/http/endpoints/customer/httpGetGeneratedFiles';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { useDebounce } from 'use-debounce/lib';

export function HomeRecentFiles() {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const offset = limit * page;

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [debouncedSearchQuery] = useDebounce(searchQuery, 300);

  const queryParams: PaginatedGeneratedFilesParams = {
    offset,
    limit,
    sort: 'updated_at',
    name: debouncedSearchQuery
  };

  const { data, isLoading, isError } = useQuery({
    queryKey: httpGetGeneratedFiles.makeQueryKey(queryParams),
    queryFn: () => httpGetGeneratedFiles.callEndpoint(queryParams),
    keepPreviousData: true
  });

  const { fetchProject: fetchWriterProject } = useGetAndLoadProjectById();

  const { mutate: fetchTesterProject } = useMutation({
    mutationFn: (id: string) => AiTesterAPI.getById(id),
    onSuccess: data => {
      if (data.status) {
        dispatch(loadTesterTabThunk(testerProjectFromApi(data.data)));
      }
    }
  });

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  function handleChangePage(_event: unknown | null, page: number) {
    setPage(page);
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  function handleSearchChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setSearchQuery(e.target.value);
    setPage(0);
  }

  const handleProjectClick = async (project: GeneratedFilesDto) => {
    if (project.file_type === 'ContentFlash') {
      await navigate(getRoutePath('aiWriter'));
      fetchWriterProject({ id: project.id.toString() });
      return;
    }

    if (project.file_type === 'PerformanceFlash') {
      await navigate(getRoutePath('aiTester'));
      fetchTesterProject(project.id.toString());

      return;
    }
  };

  if (isError) {
    return null;
  }

  if (isLoading) {
    return (
      <CenteredBox>
        <CircularProgress />
      </CenteredBox>
    );
  }

  const projects = data.data;
  const total = data.total;

  return (
    <>
      <ActionBox>
        <SearchField value={searchQuery} onChange={handleSearchChange} />
      </ActionBox>

      <Paper variant="outlined">
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>
                  <FormattedMessage id="home.sections.recentFiles.table.name" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="home.sections.recentFiles.table.author" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="home.sections.recentFiles.table.language" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="home.sections.recentFiles.table.date" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {projects.map(project => (
                <ClickableRow key={project.id} hover onClick={() => handleProjectClick(project)}>
                  <TableCell>{renderProjectIcon(project.file_type)}</TableCell>
                  <NameTableCell>
                    <EllipsisTitle variant="subtitle2">{project.name} </EllipsisTitle>
                  </NameTableCell>
                  <TableCell>{project.author_name}</TableCell>
                  <TableCell>
                    <FlagIcon embeddingModelId={project.embedding_model_id} />
                  </TableCell>
                  <TableCell>{dayjs(project.updated_at).format('DD.MM.YYYY')}</TableCell>
                </ClickableRow>
              ))}
            </TableBody>
          </Table>

          <TablePagination
            count={total ?? 0}
            page={page}
            rowsPerPage={limit}
            onPageChange={handleChangePage}
            rowsPerPageOptions={[5, 10, 25]}
            onRowsPerPageChange={handleChangeRowsPerPage}
            component="div"
          />
        </TableContainer>
      </Paper>
    </>
  );
}

const renderProjectIcon = (projectType: GeneratedFiles) => {
  switch (projectType) {
    case 'PerformanceFlash':
      return <TesterIcon />;
    case 'ContentFlash':
      return <WriterIcon />;
  }
};

const NameTableCell = styled(TableCell)`
  width: 19rem;
`;

const EllipsisTitle = styled(Typography)`
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 20rem;
`;

const ClickableRow = styled(TableRow)`
  cursor: pointer;
`;

const CenteredBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ActionBox = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;
