import { ApiErrors } from '../types';
import mkApiErrorMessage from '../utils/mkApiErrorMessage';
import mkHandleApiErrorMessage from '../utils/mkHandleApiErrorMessage';
import { CampaignApiEndpoint, DeleteCampaignError, UpdateCampaignError } from './types';

const mkCampaignApiErrors = (
  endpoint: CampaignApiEndpoint,
  errorCode: UpdateCampaignError | DeleteCampaignError
) => mkApiErrorMessage('campaign', endpoint, errorCode);

const updateCampaignErrors: ApiErrors<UpdateCampaignError> = {
  ERROR_CANT_EDIT_CAMPAIGN: mkCampaignApiErrors('update', 'ERROR_CANT_EDIT_CAMPAIGN')
};

const deleteCampaignErrors: ApiErrors<DeleteCampaignError> = {
  ERROR_CANT_EDIT_CAMPAIGN: mkCampaignApiErrors('delete', 'ERROR_CANT_EDIT_CAMPAIGN')
};

export const handleUpdateCampaingErrors = mkHandleApiErrorMessage(updateCampaignErrors);
export const handleDeleteCampaingErrors = mkHandleApiErrorMessage(deleteCampaignErrors);
