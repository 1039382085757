import './index.scss';

import SectionWithActions from 'components/base/SectionWithActions';
import { useModal } from 'components/modals';
import TabbedCard from 'components/TabbedCard/TabbedCard';
import LocalStorageKey from 'config/localStorageKey';
import BubbleChart from 'features/explorer/BubbleChart';
import GraphSettingsDropdown from 'features/explorer/BubbleChart/GraphSettingsDropdown';
import ConfigSection from 'features/explorer/ConfigSection';
import EmptyStatePlaceholder from 'features/explorer/EmptyStatePlaceholder';
import {
  getCurrentTabId,
  getExplorerIsLoading,
  getExplorerPageLayout,
  getIsTabInitialized,
  getTabHasData,
  getTabsHeaders
} from 'features/explorer/store/selectors';
import { closeTab, setCurrentTab } from 'features/explorer/store/slice';
import { createTabThunk } from 'features/explorer/store/thunks';
import TabActions from 'features/explorer/TabActions';
import useCXQuery from 'features/explorer/useCXQuery';
import WordsTable from 'features/explorer/WordsTable';
import { useEnableSidebarOnMount } from 'features/pageSidebar/useSidebarStore';
import React, { useEffect } from 'react';
import { shallowEqual } from 'react-redux';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { useLocalStorage } from 'utils/hooks/useLocalStorage';

const VerticalTabContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const HorizontalTabContent = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: fit-content(30%) auto;

  @media (max-width: 1280px) {
    display: flex;
    flex-direction: column;
  }
`;

type TabContentProps = React.ComponentPropsWithoutRef<'div'> & { isVertical?: boolean };
const TabContent = ({ isVertical, ...props }: TabContentProps) =>
  isVertical ? <VerticalTabContent {...props} /> : <HorizontalTabContent {...props} />;

const Exploring = () => {
  const { showModal } = useModal();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(getExplorerIsLoading);
  const isVerticalLayout = useAppSelector(getExplorerPageLayout);
  const tabsHeaders = useAppSelector(getTabsHeaders, shallowEqual);
  const currentTabId = useAppSelector(getCurrentTabId);
  const isInitialized = useAppSelector(getIsTabInitialized);
  const hasData = useAppSelector(getTabHasData);

  useEnableSidebarOnMount();

  const [guideShown, setGuideShown] = useLocalStorage(LocalStorageKey.ExplorerGuideShown, false);
  useEffect(() => {
    if (!guideShown) {
      showModal('VIDEO_GUIDE');
      setGuideShown(true);
    }
  }, [showModal, guideShown, setGuideShown]);

  useCXQuery();

  return (
    <div className="h-100">
      <TabbedCard
        className="h-100"
        activeTab={currentTabId}
        tabsHeaders={tabsHeaders}
        onSelect={tabId => dispatch(setCurrentTab(tabId))}
        onClose={tabId => dispatch(closeTab(tabId))}
        onCreate={() => dispatch(createTabThunk())}
        noTabsMessage="exploring.no_tabs"
      >
        <TabActions />
        <TabContent key={currentTabId} isVertical={isVerticalLayout}>
          <ConfigSection />
          {isInitialized ? (
            hasData ? (
              <>
                <SectionWithActions
                  isLoading={isLoading}
                  withLoader
                  title="exploring.results"
                  helpTooltip="exploring.results.tooltip"
                >
                  <WordsTable />
                </SectionWithActions>
                <SectionWithActions
                  isLoading={isLoading}
                  title="exploring.graph"
                  helpTooltip="exploring.graph.tooltip"
                  actions={<GraphSettingsDropdown />}
                >
                  <BubbleChart />
                </SectionWithActions>
              </>
            ) : (
              <EmptyStatePlaceholder animationOrder={3} isLoading={isLoading} />
            )
          ) : null}
        </TabContent>
      </TabbedCard>
    </div>
  );
};

export default Exploring;
