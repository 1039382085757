import { Container } from '@mui/material';
import { AiImagesGeneratorContextProvider } from 'features/aiImages/AiImagesPage/AiImagesGeneratorContext';
import { GeneratorForm } from 'features/aiImages/AiImagesPage/GeneratorForm';
import { Heading } from 'features/aiImages/AiImagesPage/Heading';
import { ImagesBox } from 'features/aiImages/AiImagesPage/ImagesBox';
import { TermsOfUse } from 'features/aiImages/AiImagesPage/TermsOfUse';
import { useNavigateToOnboarding } from 'features/onboardingJourney/useNavigateToOnboarding';
import { useEnableSidebarOnMount } from 'features/pageSidebar/useSidebarStore';
import { ReactElement } from 'react';

export const AiImagesPage = (): ReactElement => {
  useEnableSidebarOnMount();

  useNavigateToOnboarding();

  return (
    <AiImagesGeneratorContextProvider>
      <article>
        <Container
          maxWidth="md"
          sx={theme => ({
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(2),
            marginTop: '2.5rem'
          })}
        >
          <Heading />
          <GeneratorForm />
          <ImagesBox />
          <TermsOfUse />
        </Container>
      </article>
    </AiImagesGeneratorContextProvider>
  );
};
