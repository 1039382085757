import { apiCommonErrorsFallback } from './handleApiCommonErrors';

export const defaultError = 'app.api_error';

function mkHandleApiErrorMessage<T extends string>(errorCodes: Record<T, string | undefined>) {
  return (errorCode: T | undefined) => {
    if (errorCode) {
      return errorCodes[errorCode] ?? apiCommonErrorsFallback[errorCode] ?? defaultError;
    }
    return defaultError;
  };
}

export default mkHandleApiErrorMessage;
