import { motion } from 'framer-motion';
import styled from 'styled-components';

const FullMask = styled(motion.div)<{ $backgroundColor?: string; $zIndex?: number }>`
  position: fixed;
  top: 0;
  left: 0;
  background: ${({ $backgroundColor }) => $backgroundColor || 'rgba(0, 0, 0, 0.5)'};
  z-index: ${({ $zIndex }) => $zIndex || 75};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default FullMask;
