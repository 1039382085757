import { Button } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { CarouselComponent } from 'features/onboardingJourney/desktop/CarouselComponent';
import { onboardingContentWidth } from 'features/onboardingJourney/OnboardingRoot';
import {
  ActionImageSection,
  useActionImageSectionTile
} from 'features/onboardingJourney/useActionImageSectionTile';
import styled from 'styled-components';

export const ActionButtons = ({ actions }: { actions: ActionImageSection['actions'] }) => {
  if (actions.length === 0) {
    return null;
  }

  // Multiple action buttons are rendered in smaller buttons
  if (actions.length > 1) {
    return (
      <CenterAlignedItems>
        {actions.map(action => (
          <SmallerActionButton key={action.title} variant="contained" onClick={action.onClick}>
            {action.title}
          </SmallerActionButton>
        ))}
      </CenterAlignedItems>
    );
  }

  const firstAction = actions[0];
  if (firstAction.onClick) {
    return (
      <ActionButton variant="contained" onClick={firstAction.onClick}>
        {firstAction.title}
      </ActionButton>
    );
  }

  return <>{firstAction.title}</>;
};

export const DesktopActionImageSection = ({ selectedTile }: { selectedTile: string }) => {
  const tileList = useActionImageSectionTile();
  const tile = tileList.find(({ type }) => type === selectedTile);

  if (!tile) {
    return null;
  }

  const { images, imageAlt, actions } = tile;

  return (
    <>
      {images.length > 1 && (
        <CarouselComponent>
          {images.map(image => (
            <img key={image} src={image} alt={imageAlt} />
          ))}
        </CarouselComponent>
      )}
      {images.length === 1 && <img src={images[0]} alt={imageAlt} />}

      <ActionButtons actions={actions} />
    </>
  );
};

const ActionButton = styled(Button)`
  width: ${onboardingContentWidth};
`;

const SmallerActionButton = styled(Button)`
  width: 240px;
`;

const CenterAlignedItems = styled(FlexContainer)`
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacings.medium};
  flex-direction: row;
`;
