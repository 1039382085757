import { useEffect } from 'react';
import { create } from 'zustand';

type SidebarState = {
  isEnabled: boolean;
  isExpanded: boolean;

  enableSidebar: () => void;
  disableSidebar: () => void;
  toggleSidebar: () => void;
  expandSidebar: () => void;
  collapseSidebar: () => void;
};

export const useSidebarStore = create<SidebarState>()(set => ({
  isEnabled: false,
  isExpanded: false,

  enableSidebar: () => set({ isEnabled: true }),
  disableSidebar: () => set({ isEnabled: false }),
  toggleSidebar: () => set(state => ({ isExpanded: !state.isExpanded })),
  collapseSidebar: () => set({ isExpanded: false }),
  expandSidebar: () => set({ isExpanded: true })
}));

export const useEnableSidebarOnMount = () => {
  const enableSidebar = useSidebarStore(state => state.enableSidebar);

  useEffect(() => {
    enableSidebar();
  }, [enableSidebar]);
};

export const useDisableSidebarOnMount = () => {
  const disableSidebar = useSidebarStore(state => state.disableSidebar);

  useEffect(() => {
    disableSidebar();
  }, [disableSidebar]);
};

export const collapseSidebar = () => useSidebarStore.getState().collapseSidebar();
