import { Button, TextField, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import FlexContainer from 'components/FlexContainer';
import { personalityTips, TipLoader } from 'components/TipLoader';
import Toast from 'components/toasts/Toast';
import { getRoutePath } from 'config/routes';
import {
  INPUT_OUTPUT_HEIGHT,
  MIN_WORDS_LIMIT,
  PERSONALITY_DEFINITION_MAX_WORDS_LIMIT
} from 'features/aiWriter/AiWriterSidebar/steps/chat/PersonalityAutomaticCreationView';
import { fallbackModelCountry } from 'features/aiWriter/store/utils/defaults/fallbackModelCountry';
import { fallbackModelLanguage } from 'features/aiWriter/store/utils/defaults/fallbackModelLanguage';
import { getPreferredAudience } from 'features/aiWriter/utils/getPreferredAudience';
import { getUserAudiences } from 'features/audiences/store/selectors';
import { useCustomerPreferences } from 'features/customerPreferences/useCustomerPreferences';
import { MobileOnboardingFooter } from 'features/onboardingJourney/MobileFooter';
import { Footer, NextButton, OnboardingRoot } from 'features/onboardingJourney/OnboardingRoot';
import { OnboardingStepper } from 'features/onboardingJourney/OnboardingStepper';
import { useIsDesktop } from 'features/onboardingJourney/useIsDesktop';
import { useDisableSidebarOnMount } from 'features/pageSidebar/useSidebarStore';
import { ChangeEvent, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';
import { handleGenerateTextErrorsWithHyperlink } from 'services/api/wordEmbedding/errors';
import {
  GeneratePersonalityProps,
  httpGeneratePersonality
} from 'services/backofficeIntegration/http/endpoints/textGeneration/httpGeneratePersonality';
import { GAEvents } from 'services/tracking/GAEvents';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { getWordCount } from 'utils/getWordCount';
import useTr from 'utils/hooks/useTr';

export type OnboardingPersonalityCreationLocationState = {
  personalityText: string;
  personalityLanguage: string;
  personalityCountry: string;
};

export const OnboardingPersonalityCreation = () => {
  const tr = useTr();
  const navigate = useNavigate();
  const { data: customerPreferences } = useCustomerPreferences();

  useDisableSidebarOnMount();

  const [text, setText] = useState('');
  const [personality, setPersonality] = useState('...');
  const isDesktop = useIsDesktop();

  const {
    mutate: generatePersonality,
    isLoading,
    isSuccess
  } = useMutation({
    mutationFn: (params: GeneratePersonalityProps) => httpGeneratePersonality.callEndpoint(params),
    onSuccess: data => {
      if (data.data.status) {
        setPersonality(data.data.data.outputs[0].text);
      } else {
        const code = data.data.message;
        Toast.backendError(...handleGenerateTextErrorsWithHyperlink(code));
      }
    }
  });

  const textWordCount = getWordCount(text);

  const isInputError = textWordCount > PERSONALITY_DEFINITION_MAX_WORDS_LIMIT;
  const isInputValid = isLoading || isInputError || textWordCount < MIN_WORDS_LIMIT;

  const language = customerPreferences?.preferredLanguage ?? fallbackModelLanguage;
  const country = customerPreferences?.preferredLanguageCountry ?? fallbackModelCountry;
  const audiences = useAppSelector(getUserAudiences);
  const audience = getPreferredAudience({ audiences, locale: { language, country } });

  const handleSkipClick = () => {
    GAEvents.onboardingPersonalitySkipClick();
    navigate(getRoutePath('onboardingSelection'), { state: { skippedPersonalityCreation: true } });
  };

  const handleChangeText = (e: ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);
  };

  const handleAnalysisClick = async () => {
    if (!audience || !audience.model_id) {
      throw new Error('Audience not found');
    }

    GAEvents.onboardingPersonalityAnalysisClick();
    await generatePersonality({
      audience_model_id: audience.model_id,
      text: text,
      n_times: 1,
      n_text_items: 1
    });
  };

  useEffect(() => {
    if (
      !audience ||
      !audience.language ||
      !audience.country ||
      personality === '...' ||
      isLoading ||
      !isSuccess
    ) {
      return;
    }

    const state: OnboardingPersonalityCreationLocationState = {
      personalityText: personality,
      personalityLanguage: audience.language,
      personalityCountry: audience.country
    };

    navigate(getRoutePath('onboardingPersonalityFinished'), { state });
  }, [personality, isLoading, isSuccess, navigate, audience]);

  return (
    <OnboardingRoot
      title={tr('onboarding.personality_creation.title')}
      subtitle={tr('onboarding.personality_creation.subtitle')}
      stepper={<OnboardingStepper currentStep={2} />}
    >
      {isLoading ? (
        <PersonalityInput>
          <TipLoader tips={personalityTips} />
        </PersonalityInput>
      ) : (
        <PersonalityInput>
          <TextField
            value={text}
            onChange={handleChangeText}
            multiline
            fullWidth
            error={isInputError}
            placeholder={tr('personality_creation.input_placeholder')}
            inputProps={{
              style: {
                height: INPUT_OUTPUT_HEIGHT,
                overflow: 'auto'
              }
            }}
          />

          <CounterBox $isError={isInputError}>
            <Typography variant="caption">
              {textWordCount}/{PERSONALITY_DEFINITION_MAX_WORDS_LIMIT}
            </Typography>
            {isInputError && (
              <Typography variant="caption">
                <FormattedMessage id="aiWriter.inspirations.chat.personalities.library_modal.automatic_creation.input_column.error" />
              </Typography>
            )}
          </CounterBox>
        </PersonalityInput>
      )}

      {isDesktop && (
        <Footer direction="column">
          <NextButton
            {...withGtmInteraction(gtmIds.personalitiesLibrary.personalityGenerated)}
            variant="contained"
            color="primary"
            onClick={handleAnalysisClick}
            disabled={isInputValid}
            fullWidth={true}
          >
            <FormattedMessage id="onboarding.personality_creation.next.button" />
          </NextButton>
          <Button onClick={handleSkipClick} disabled={isLoading} fullWidth={true}>
            <FormattedMessage id="aiWriter.onboarding.skip_button.label" />
          </Button>
        </Footer>
      )}
      {!isDesktop && (
        <MobileOnboardingFooter
          buttons={[
            <NextButton
              {...withGtmInteraction(gtmIds.onboardingJourney.survey.next)}
              color="primary"
              variant="contained"
              onClick={handleAnalysisClick}
              disabled={isInputValid}
              fullWidth={true}
              key="nextButton"
            >
              <FormattedMessage id="onboarding.personality_creation.next.button" />
            </NextButton>,
            <Button
              onClick={handleSkipClick}
              disabled={isLoading}
              fullWidth={true}
              key="skipButton"
            >
              <FormattedMessage id="aiWriter.onboarding.skip_button.label" />
            </Button>
          ]}
          step={2}
        />
      )}
    </OnboardingRoot>
  );
};

const PersonalityInput = styled(FlexContainer)`
  max-width: 400px;
  width: 100%;
`;

const CounterBox = styled.div<{ $isError: boolean }>`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;

  color: ${({ theme, $isError }) => $isError && theme.colors.danger};
`;
