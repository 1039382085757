import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import FlexContainer from 'components/FlexContainer';
import { getRoutePath } from 'config/routes';
import { CreateWorkflowButton } from 'features/aiWriter/AiWriterProjectOverview/CreateWorkflowButton';
import { setInspirationsCurrentStep } from 'features/aiWriter/store/actions/config/actions';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { ApiModularWorkflowSharingPermissions } from 'features/modular-workflow/builder/types-api';
import { useSidebarStore } from 'features/pageSidebar/useSidebarStore';
import CreateProjectButton from 'features/projects/CreateProjectButton';
import { trackingWrapper } from 'features/tracking/wrapper/TrackingWrapper';
import { useNavigate } from 'react-router';
import {
  httpGetModularWorkflows,
  PaginatedListParams
} from 'services/backofficeIntegration/http/endpoints/modularWorkflow/httpGetModularWorkflows';
import gtmIds from 'services/tracking/GTMIds';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import useDropdown from 'utils/hooks/useDropdown';

export const WorkflowButtons = () => {
  const dispatch = useAppDispatch();
  const collapseSidebar = useSidebarStore(state => state.collapseSidebar);
  const navigate = useNavigate();
  const { anchorElement, isOpen, open, close } = useDropdown();

  const queryParams: PaginatedListParams = {
    view_mode: 'run',
    sharing_permissions: [
      ApiModularWorkflowSharingPermissions.public,
      ApiModularWorkflowSharingPermissions.workspace,
      ApiModularWorkflowSharingPermissions.other_workspaces
    ],
    sort: 'newest'
  };

  const { data: modularWorkflowsData } = useQuery({
    queryKey: httpGetModularWorkflows.makeQueryKey(queryParams),
    queryFn: () => httpGetModularWorkflows.callEndpoint(queryParams),
    keepPreviousData: true
  });

  const modularWorkflows = modularWorkflowsData?.workflows ?? [];

  const handleBlogPostBuilderClick = () => {
    trackingWrapper.track('aiWriterOverviewBlogPostBuilderStarted');

    collapseSidebar();

    navigate(getRoutePath('blogPostWorkflow'));
  };

  const handleSocialMediaBuilderClick = () => {
    trackingWrapper.track('aiWriterOverviewSocialMediaBuilderStarted');

    collapseSidebar();

    navigate(getRoutePath('socialPostWorkflow'));
    dispatch(setInspirationsCurrentStep(undefined));
  };

  const handleNewDocumentClick = () => {
    trackingWrapper.track('aiWriterOverviewNewDocumentStarted');

    navigate(getRoutePath('newDocument'));
  };

  const handleModularWorkflowClick = (workflowId: number) => {
    trackingWrapper.track('aiWriterOverviewModularWorkflowStarted', {
      workflowId
    });

    navigate(getRoutePath('modularWorkflowRun', { workflowId }));
  };

  return (
    <ButtonsGroup>
      <CreateProjectButton id={gtmIds.aiWriter.createNewProject} onClick={handleNewDocumentClick} />
      <CreateWorkflowButton
        title={<FormattedMessage id="aiWriter.project_overview.blog_post_builder" />}
        subtitle={<FormattedMessage id="aiWriter.project_overview.blog_post_builder_description" />}
        gtmId={gtmIds.aiWriter.newBlogPostBuilder}
        onClick={handleBlogPostBuilderClick}
      />
      <CreateWorkflowButton
        title={<FormattedMessage id="aiWriter.project_overview.social_post_builder.title" />}
        subtitle={<FormattedMessage id="aiWriter.project_overview.social_post_builder.subtitle" />}
        gtmId={gtmIds.aiWriter.newSocialPostBuilder}
        onClick={handleSocialMediaBuilderClick}
      />

      {modularWorkflows.length > 0 && (
        <CreateWorkflowButton
          title={modularWorkflows[0].name}
          subtitle={modularWorkflows[0].description || undefined}
          onClick={() => handleModularWorkflowClick(modularWorkflows[0].id)}
        />
      )}

      {modularWorkflows.length > 1 && (
        <StyledIconButton onClick={open}>
          <MoreVertIcon />
        </StyledIconButton>
      )}

      <Menu anchorEl={anchorElement} open={isOpen} onClose={close}>
        {modularWorkflows.slice(1).map(workflow => (
          <MenuItem key={workflow.id} onClick={() => handleModularWorkflowClick(workflow.id)}>
            {workflow.name}
          </MenuItem>
        ))}
      </Menu>
    </ButtonsGroup>
  );
};

const ButtonsGroup = styled(FlexContainer).attrs({
  direction: 'row',
  gap: 'three',
  justifyContent: 'flex-start'
})`
  height: 100px;
`;

const StyledIconButton = styled(IconButton)`
  min-width: 50px;
  height: 50px;
  align-self: center;
`;
