import Toast from 'components/toasts/Toast';
import { hasForbiddenDocumentLanguage } from 'features/aiWriter/AiWriterSidebar/steps/flashScore/useHasForbiddenDocumentLanguage';
import { isAiWriterSupportedLanguageAndCountryCode } from 'features/aiWriter/aiWriterSupportedLanguageAndCountryCodes';
import { generateTextInspirations } from 'features/aiWriter/store/actions/inspirations/actions';
import { scoreTextInspirations } from 'features/aiWriter/store/actions/inspirations/thunks/scoreTextInspirations';
import { updateCurrentProjectInBackgroundThunk } from 'features/aiWriter/store/actions/project/thunks/updateCurrentProjectInBackground';
import { verifySystemTonalityValues } from 'features/aiWriter/tonality/convertSystemTonalityValue';
import getAudienceModelById from 'features/audiences/utils/getAudienceModelById';
import { setWordsLimitReached } from 'features/customer/store/actions';
import {
  getGetEmbeddingModelLanguageAndCountryById,
  getGetFirstGenericEmbeddingModelMapperById
} from 'features/embeddingModels/store/selectors';
import { generateTexts } from 'features/textGenerator/actions/generateTexts';
import { getGetPromptConfigByLanguageAndOutputType } from 'features/textGenerator/store/selectors';
import isValidGenerateTextConfig from 'features/textGenerator/store/utils/isValidGenerateTextConfig';
import { generatedTextsFromGenerateTextOutputEntries } from 'features/textGenerator/utils/generateTextEntryMapper';
import { AppThunk } from 'store/store';
import { getCurrentTab } from 'store/utils/tabUtils';
import { assertNonNullable } from 'utils/typescript/nonNullable';

export const generateTextInspirationsThunk = (): AppThunk<void> => async (dispatch, getState) => {
  const state = getState();
  const { aiWriter, audiences: audiencesState } = state;
  const currentTab = getCurrentTab(aiWriter);
  if (!currentTab) {
    return;
  }

  const { embeddingModelId, generateTextConfig, id: tabId } = currentTab;

  if (!isValidGenerateTextConfig(generateTextConfig)) {
    return;
  }

  const {
    audienceId,
    outputType,
    brand,
    keywords,
    text,
    systemTonality: unsafeSystemTonality = [],
    userTonality = [],
    nTextItems,
    length,
    personalityId,
    informationList
  } = generateTextConfig;

  const audienceModelId = getAudienceModelById(audiencesState, audienceId);
  const { language: modelLanguage, country: modelCountry } =
    getGetEmbeddingModelLanguageAndCountryById(state)(embeddingModelId);
  const mapper = getGetFirstGenericEmbeddingModelMapperById(state)(embeddingModelId);

  const emotionalityMapperName = mapper?.name ?? 'de_emotions_standard';

  const languageAndCountryCode = `${modelLanguage}_${modelCountry}`;

  if (!audienceModelId || !isAiWriterSupportedLanguageAndCountryCode(languageAndCountryCode)) {
    return;
  }

  /**
   * Need this for backward compatibility. A document can have tonalities stored
   * in a different language than current document language.
   */
  const { safeValues: safeSystemTonality } = verifySystemTonalityValues({
    unsafeValues: unsafeSystemTonality ?? [],
    targetLanguageAndCountry: languageAndCountryCode
  });

  dispatch(generateTextInspirations.request());

  try {
    const promptConfig = getGetPromptConfigByLanguageAndOutputType(state)(
      modelLanguage,
      outputType
    );

    if (!promptConfig) {
      dispatch(generateTextInspirations.failure({ tabId }));
      Toast.commonError();
      return;
    }

    const { outputs } = await generateTexts(
      {
        projectId: tabId,
        audienceModelId: audienceModelId,
        outputType,
        nTimes: promptConfig.nTimes,
        nTextItems: nTextItems ?? promptConfig.nTextItems
      },
      {
        keywords,
        keywords2: brand, // #tech-debt https://app.clickup.com/t/37t6awm
        text,
        systemTonality: safeSystemTonality,
        userTonality,
        personalityId,
        informationList,
        length
      },
      {
        onCustomError: [
          'ERROR_ALL_WORDS_USED',
          () => {
            dispatch(setWordsLimitReached(true));
          }
        ]
      }
    );

    const inspirations = generatedTextsFromGenerateTextOutputEntries(outputs);

    // Score all inspirations
    const isFlashScoreSupportedForDocumentLanguage = !hasForbiddenDocumentLanguage(modelLanguage);
    if (isFlashScoreSupportedForDocumentLanguage) {
      const outputScores = await scoreTextInspirations(
        embeddingModelId,
        emotionalityMapperName,
        inspirations
      );
      if (outputScores && outputScores.length > 0) {
        for (let index = 0; index < inspirations.length; index++) {
          inspirations[index].flashScore = outputScores[index];
        }

        // Sort by readability by default
        inspirations.sort((a, b) => {
          assertNonNullable(a.flashScore);
          assertNonNullable(b.flashScore);

          return b.flashScore.readability.score - a.flashScore.readability.score;
        });
      }
    }

    dispatch(generateTextInspirations.success({ inspirations, tabId }));
    dispatch(updateCurrentProjectInBackgroundThunk());
  } catch (e) {
    dispatch(generateTextInspirations.failure({ tabId }));
  }
};
