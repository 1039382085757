import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Checkbox,
  Chip,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { uuid4 } from '@sentry/utils';
import { useMutation } from '@tanstack/react-query';
import { ColoredTypography } from 'components/ColoredTypography';
import FlexContainer from 'components/FlexContainer';
import dayjs from 'dayjs';
import { useRenameProjectModal } from 'features/aiWriter/AiWriterProjectOverview/RenameProjectModal';
import { TagChipColors } from 'features/aiWriter/AiWriterProjectOverview/tags/AiWriterProjectTag';
import { TagRowMenu } from 'features/aiWriter/AiWriterProjectOverview/tags/TagRowMenu';
import { truncateText } from 'features/aiWriter/AiWriterProjectOverview/TeamMemberFilter';
import { isEmpty } from 'lodash';
import React, { ChangeEvent, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { AiWriterProjectTagType } from 'services/api/aiWriter/types';
import { httpDeleteTag } from 'services/backofficeIntegration/http/endpoints/aiWriter/httpDeleteTag';
import { invalidateGetAllTagsQuery } from 'services/backofficeIntegration/http/endpoints/aiWriter/httpGetAllTags';
import { invalidateProjectsQuery } from 'services/backofficeIntegration/http/endpoints/aiWriter/httpGetProjects';
import { httpUpdateTag } from 'services/backofficeIntegration/http/endpoints/aiWriter/httpUpdateTag';
import { GAEvents } from 'services/tracking/GAEvents';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';

export const TagsTable = (props: {
  tags: AiWriterProjectTagType[];
  selectedProjectIds: number[];
  setSelectedProjectIds: (selected: number[]) => void;
}) => {
  const { tags, selectedProjectIds, setSelectedProjectIds } = props;

  const customerId = useAppSelector(state => state.customer.id);

  const allVisibleTagIds = useMemo(
    () => tags.filter(tag => tag.customer_id === customerId).map(p => p.id),
    [customerId, tags]
  );

  const handleSelection = (id: number) => {
    if (selectedProjectIds.includes(id)) {
      setSelectedProjectIds(selectedProjectIds.filter(s => s !== id));
    } else {
      setSelectedProjectIds([...selectedProjectIds, id]);
    }
  };

  const onSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const projectIdsSet = new Set([...selectedProjectIds, ...allVisibleTagIds]);
      setSelectedProjectIds(Array.from(projectIdsSet));
      return;
    }

    const newSelected = selectedProjectIds.filter(id => !allVisibleTagIds.includes(id));
    setSelectedProjectIds(newSelected);
  };

  const hasSelectedAllRows = useMemo(
    () =>
      !isEmpty(allVisibleTagIds) &&
      allVisibleTagIds.every(projectId => selectedProjectIds.includes(projectId)),
    [allVisibleTagIds, selectedProjectIds]
  );

  return (
    <Root>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              {!isEmpty(allVisibleTagIds) && (
                <Checkbox checked={hasSelectedAllRows} onChange={onSelectAllClick} />
              )}
            </TableCell>
            <TableCell>
              <ColoredTypography variant="subtitle1">
                <FormattedMessage id="tags.modal.tags_table.column.name" />
              </ColoredTypography>
            </TableCell>
            <TableCell>
              <ColoredTypography variant="subtitle1">
                <FormattedMessage id="tags.modal.tags_table.column.files" />
              </ColoredTypography>
            </TableCell>
            <TableCell>
              <ColoredTypography variant="subtitle1">
                <FormattedMessage id="tags.modal.tags_table.column.created_by" />
              </ColoredTypography>
            </TableCell>
            <TableCell>
              <ColoredTypography variant="subtitle1">
                <FormattedMessage id="tags.modal.tags_table.column.created_at" />
              </ColoredTypography>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {tags.map(tag => {
            return (
              <TagRow
                key={uuid4()}
                tag={tag}
                isSelected={selectedProjectIds.includes(tag.id)}
                handleSelection={handleSelection}
              />
            );
          })}
        </TableBody>
      </Table>
    </Root>
  );
};

const TagRow = (props: {
  tag: AiWriterProjectTagType;
  isSelected: boolean;
  handleSelection: (id: number) => void;
}) => {
  const { id, name, ai_writer_project_count, color, created_at, customer_id, customer } = props.tag;
  const { isSelected, handleSelection, tag } = props;
  const customerId = useAppSelector(state => state.customer.id);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOptionsOpen = Boolean(anchorEl);

  const handleClickOption = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseOption = () => {
    setAnchorEl(null);
  };

  const showRenameProjectModal = useRenameProjectModal();

  const { mutate: deleteTag, isLoading: isDeleteLoading } = useMutation({
    mutationFn: httpDeleteTag.callEndpoint,
    onSuccess: async () => {
      invalidateGetAllTagsQuery();
      invalidateProjectsQuery();
      GAEvents.tagDeletedInTagsLibrary();
    }
  });

  const { mutate: updateTag, isLoading: isUpdateLoading } = useMutation({
    mutationFn: httpUpdateTag.callEndpoint,
    onSuccess: async () => {
      invalidateGetAllTagsQuery();
      invalidateProjectsQuery();
    }
  });

  const isLoading = isDeleteLoading || isUpdateLoading;

  const isTagAuthor = customer_id === customerId;

  const handleCheckboxClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    handleSelection(id);
  };

  const handleRenameClick = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.stopPropagation();
    showRenameProjectModal({
      oldName: name,
      onUpdate: (name: string) => {
        updateTag({ id: id, name, color: TagChipColors[color] });
      }
    });
  };

  const updateTagColor = async (c: string) => {
    await updateTag({ id: tag.id, name: tag.name, color: TagChipColors[c] });
    handleCloseOption();
  };

  return (
    <ClickableRow hover>
      <TableCell sx={{ width: '1rem' }}>
        {isTagAuthor && <Checkbox onClick={handleCheckboxClick} checked={isSelected} />}
      </TableCell>
      <TableCell>
        <FlexContainer direction="row" gap="medium" alignItems="center">
          <ColoredChip label={truncateText(name, 30)} $color={color} size="small" />
        </FlexContainer>
      </TableCell>
      <TableCell>{ai_writer_project_count === null ? 0 : ai_writer_project_count}</TableCell>
      <CustomerCell>
        {customer.image_url && <Avatar src={customer.image_url} />}
        <ColoredTypography variant="body2">{customer.name}</ColoredTypography>
      </CustomerCell>
      <TableCell>{dayjs(created_at).format('DD.MM.YYYY')}</TableCell>
      <TableCell>
        {isLoading ? (
          <CircularProgress size={20} />
        ) : (
          isTagAuthor && (
            <IconButton
              size="small"
              onClick={e => {
                e.stopPropagation();
                handleClickOption(e);
              }}
            >
              <MoreVertIcon />
            </IconButton>
          )
        )}
      </TableCell>
      <TagRowMenu
        tag={tag}
        isOptionsOpen={isOptionsOpen}
        anchorEl={anchorEl}
        handleCloseOption={handleCloseOption}
        renameClick={handleRenameClick}
        deleteTag={deleteTag}
        onColorSelection={updateTagColor}
      />
    </ClickableRow>
  );
};

const Root = styled(TableContainer)`
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  border-color: ${({ theme }) => theme.colors.blackPressedOverlay};
`;

const ClickableRow = styled(TableRow)`
  cursor: pointer;
`;

const ColoredChip = styled(Chip)<{ $color: string }>`
  background-color: ${({ $color }) => $color};
  color: ${({ theme }) => theme.colors.white};
`;

const Avatar = styled.img`
  width: 20px;
  height: 20px;
  margin-right: ${({ theme }) => theme.spacings.small};

  border-radius: 50%;
`;

const CustomerCell = styled(TableCell)`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 55px;
`;
