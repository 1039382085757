import { useQuery } from '@tanstack/react-query';
import { getActiveTab } from 'features/aiWriter/store/selectors';
import getAudienceModelFromConfig from 'features/audiences/utils/getAudienceModelFromConfig';
import { makeCurrentUserQueryKeyPrefix } from 'features/currentUser/currentUserQueries';
import {
  createEmptyScoreTextsResult,
  createScoreTextsResult
} from 'features/flashScore/createScoreTextsResult';
import { useGetEmotionalityMapperName } from 'features/flashScore/useGetEmotionalityMapperName';
import { generateTexts } from 'features/textGenerator/actions/generateTexts';
import { useGetNTimesByOutputTypeCached } from 'features/textGenerator/utils/useGetPromptsOthers';
import { httpFlashScoreGeneratedTexts } from 'services/backofficeIntegration/http/endpoints/flashScore/httpFlashScoreGeneratedTexts';
import { useAppSelector } from 'store/hooks';
import { getWordCount } from 'utils/getWordCount';
import { assertNonNullable } from 'utils/typescript/nonNullable';

export const useGetScoredSynonyms = (nodeText: string) => {
  const { generateTextConfig, embeddingModelId } = useAppSelector(getActiveTab);

  const audienceModelId = useAppSelector(state =>
    getAudienceModelFromConfig(state.audiences, generateTextConfig)
  );

  const mapperName = useGetEmotionalityMapperName(embeddingModelId);

  const outputType = 'synonyms';
  const nTimes = useGetNTimesByOutputTypeCached(outputType);

  return useQuery({
    enabled: nodeText.length > 0,
    // Never fetch the suggestion again
    staleTime: Infinity,
    queryKey: [
      ...makeCurrentUserQueryKeyPrefix(),
      'flash-score-synonyms',
      audienceModelId,
      nodeText
    ],
    queryFn: async () => {
      assertNonNullable(audienceModelId, 'audienceModelId');

      return generateTexts(
        {
          outputType: outputType,
          audienceModelId,
          nTimes
        },
        {
          keywords: nodeText
        }
      )
        .then(result => result.outputs.map(result => result.text))
        .then(async words => {
          const defaultEmptyResult = words.map(word => createEmptyScoreTextsResult(word));

          return httpFlashScoreGeneratedTexts
            .callEndpoint({
              embedding_model_id: embeddingModelId,
              mappers: [{ id: 'emotionality', name: mapperName, variable: 'emotionality' }],
              texts: words
            })
            .then(response => {
              if (!response || !response.status) {
                return defaultEmptyResult;
              }

              return (
                response.data
                  .map((result, index) => createScoreTextsResult(words[index], result))
                  // Order by emotionality score
                  .sort((a, b) => b.emotionality.score - a.emotionality.score)
                  // Filter out synonyms with more than 3 words (which could be an AI generated sentence)
                  .filter(word => getWordCount(word.text) <= 3)
              );
            })
            .catch(() => defaultEmptyResult);
        });
    }
  });
};
