import { init, reactRouterV6Instrumentation } from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom';

export const initializeSentry = () => {
  if (!process.env.REACT_APP_SENTRY_DSN) {
    return;
  }

  init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        )
      })
    ],
    tracesSampleRate: parseFloat(process.env.REACT_APP_SENTRY_TRACING_SAMPLE_RATE),
    environment: process.env.REACT_APP_ENV,
    normalizeDepth: 5
  });
};
