import { TranslationFunction } from 'utils/hooks/useTr';
import { number, object, string } from 'yup';

export const mkBriefFormValidationSchema = ({
  textField,
  keywordsField,
  keywords2Field,
  numberOfSuggestionsField,
  tr
}: {
  textField: string;
  keywordsField: string;
  keywords2Field: string;
  numberOfSuggestionsField?: string;
  tr: TranslationFunction;
}) => {
  let schema = object().shape(
    {
      [textField]: string().when([keywords2Field, keywordsField], {
        is: (keywords2: string, keywords: string) => !keywords2 && !keywords,
        then: string().required(tr('validation.min_one_is_required'))
      }),
      [keywords2Field]: string().when([textField, keywordsField], {
        is: (text: string, keywords: string) => !text && !keywords,
        then: string().required(tr('validation.min_one_is_required'))
      }),
      [keywordsField]: string().when([textField, keywords2Field], {
        is: (text: string, keywords2: string) => !text && !keywords2,
        then: string().required(tr('validation.min_one_is_required'))
      })
    },
    [
      [keywords2Field, keywordsField],
      [textField, keywordsField],
      [textField, keywords2Field]
    ]
  );

  if (numberOfSuggestionsField) {
    schema = schema.concat(
      object().shape({
        [numberOfSuggestionsField]: number()
          .required(tr('validation.min_one_is_required'))
          .min(1)
          .max(10)
      })
    );
  }

  return schema;
};
