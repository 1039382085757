import { Paper } from '@mui/material';
import { getCurrentLanguage } from 'features/language/store/selectors';
import { AppLanguage } from 'features/language/store/types';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis
} from 'recharts';
import { WordsUsageHistory } from 'services/api/customer/types';
import { useAppSelector } from 'store/hooks';
import styled, { useTheme } from 'styled-components';

const categoryKey = 'day';
const valueKey = 'wordsUsed';

type DatumShape = {
  [categoryKey]: string;
  [valueKey]: number;
  date: Date;
};

export function DailyUsageChart(props: { history: WordsUsageHistory }) {
  const theme = useTheme();
  const language = useAppSelector(getCurrentLanguage);

  const xAxisTickFormatter = makeXAxisTickFormatter(language);
  const yAxisTickFormatter = makeYAxisTickFormatter(language);
  const tooltipFormatter = makeTooltipFormatter(language);
  const data = makeData(props.history, xAxisTickFormatter);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart data={data}>
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <XAxis dataKey={categoryKey} />
        <YAxis tickFormatter={props => yAxisTickFormatter.format(props)} width={80} />
        <Tooltip<number, string> content={renderTooltip} />
        <Bar
          dataKey={valueKey}
          fill={theme.colors.primary200}
          shape={<Rectangle radius={[8, 8, 0, 0]} />}
        />
      </BarChart>
    </ResponsiveContainer>
  );

  function renderTooltip(props: TooltipProps<number, string>) {
    const { payload, active } = props;
    const [first = {}] = payload ?? [];
    const { value } = first;

    if (!active || value === undefined) return null;

    return <TooltipSurface>{tooltipFormatter.format(value)}</TooltipSurface>;
  }
}

function makeXAxisTickFormatter(language: AppLanguage) {
  return new Intl.DateTimeFormat(language, {
    month: 'short',
    day: '2-digit',
    /**
     * We are getting dates from BE in format `YYYY-MM-DD`. When such string
     * is parsed with `new Date()`, UTC time zone is used. See example here:
     * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/parse#using_date.parse
     * We need to enforce timezone here, otherwise timezone would be selected
     * based on browsers setting. This could lead to dates being shifted in some edge cases.
     */
    timeZone: 'UTC'
  });
}

function makeYAxisTickFormatter(language: AppLanguage) {
  return new Intl.NumberFormat(language, {
    notation: 'compact'
  });
}

function makeTooltipFormatter(language: AppLanguage) {
  return new Intl.NumberFormat(language, {});
}

function makeData(history: WordsUsageHistory, dateFormatter: Intl.DateTimeFormat): DatumShape[] {
  return Object.entries(history)
    .map(([dateString, value]) => {
      const date = new Date(dateString);
      const category = dateFormatter.format(date);
      return {
        date,
        [categoryKey]: category,
        [valueKey]: value
      };
    })
    .sort((x, y) => x.date.getTime() - y.date.getTime());
}

const TooltipSurface = styled(Paper).attrs({ elevation: 3 })`
  padding: ${({ theme }) => theme.spacings.small} ${({ theme }) => theme.spacings.medium};
`;
