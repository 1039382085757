import { Add, ArrowDropDown, Check, Close, LabelRounded } from '@mui/icons-material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  PopoverProps,
  Tooltip,
  Typography
} from '@mui/material';
import CountryFlag from 'components/base/CountryFlag';
import EditableContent from 'components/EditableContent';
import FlexContainer from 'components/FlexContainer';
import { MenuDropdown } from 'components/MenuDropdown/MenuDropdown';
import { PersonalityIcon } from 'components/personality/PersonalityIcon';
import { ProjectSaver } from 'components/ProjectSaver';
import { differenceInSeconds, formatDistanceToNow, parseISO } from 'date-fns';
import { AiWriterProjectTagsDropdownMenu } from 'features/aiWriter/AiWriterProjectOverview/tags/AiWriterProjectTagsDropdownMenu';
import { topBarDropdownAlignmentConfig } from 'features/aiWriter/AiWriterTextEditor/utils/topBarDropdownAlignmentConfig';
import { LanguageMenu } from 'features/aiWriter/AiWriterWorkspace/header/LanguageMenu';
import { UsageIndicatorWithPopover } from 'features/aiWriter/AiWriterWorkspace/header/UsageIndicatorWithPopover';
import { loadTabThunk } from 'features/aiWriter/store/actions/tabs/thunks/loadTabThunk';
import { useGetProjectById } from 'features/aiWriter/useGetProjectById';
import { DocumentPageTopBarMoreButton } from 'features/document-top-bar/DocumentPageTopBarMoreButton';
import { PersonalityButton } from 'features/document-top-bar/PersonalityButton';
import { languageToDateFnsLocaleMapper } from 'features/language/languageToDateFnsLocaleMapper';
import { getCurrentLanguage } from 'features/language/store/selectors';
import { MouseEvent, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { useResizeDetector } from 'react-resize-detector';
import { APIModel } from 'services/api/embeddingModels/types';
import { GAEvents } from 'services/tracking/GAEvents';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { maxProjectNameLength } from 'store/utils/tabUtils';
import styled from 'styled-components';
import { resetButton } from 'styles/styledComponents/resets';
import useDropdown from 'utils/hooks/useDropdown';
import { useMenu } from 'utils/hooks/useMenu';
import useTr from 'utils/hooks/useTr';

export const DOCUMENT_TOP_BAR_SCREEN_WIDTH_THRESHOLD = 600;
const DOCUMENT_NAME_TRUNCATION_THRESHOLD_WIDTH = 650;

export type DocumentPageTopBarDocument = {
  id: string | null;
  name: string;
  modelData?: APIModel;
};

export const popoverProps: Partial<PopoverProps> = {
  transformOrigin: {
    vertical: -10,
    horizontal: 'right'
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right'
  }
};

type BaseProps = {
  activeDocumentId: string | null;
  documents: DocumentPageTopBarDocument[];
  isSaving: boolean;
  placeholderName?: string;
  withSaveIndicator?: boolean;
  withNameChange?: boolean;
  withLanguageChange?: boolean;
  withUsageIndicator?: boolean;
  onRename: (name: string) => void;
  onBack: () => void;
  onCreate?: () => void;
  children?: ReactNode;
  renderName?: (name: string) => string;
  enablePersonalityButton?: boolean;
  enableTagsButton?: boolean;
};

type PropsWithMultipleTabs = BaseProps & {
  onClose: (id: string) => void;
  onSelect: (id: string) => void;
  onLastClose: () => void;
};

type PropsWithSingleTab = BaseProps & {
  onClose?: never;
  onSelect?: never;
  onLastClose?: never;
};

type Props = PropsWithMultipleTabs | PropsWithSingleTab;

export const DocumentPageTopBar = ({
  activeDocumentId,
  documents,
  isSaving,
  placeholderName,
  withSaveIndicator = true,
  withNameChange = true,
  withLanguageChange = true,
  withUsageIndicator = true,
  onClose,
  onCreate,
  onSelect,
  onRename,
  onBack,
  onLastClose,
  children,
  renderName,
  enablePersonalityButton = false,
  enableTagsButton = false
}: Props) => {
  const dispatch = useAppDispatch();
  const translate = useTr();
  const { anchorElement, isOpen, close, open } = useDropdown();

  const {
    anchorEl: languageAnchorEl,
    isOpen: isLanguageMenuOpen,
    onTriggerClick: onLanguageTriggerClick,
    onMenuClose: onLanguageMenuClose
  } = useMenu();

  const { fetchProject } = useGetProjectById();

  const appLanguage = useAppSelector(getCurrentLanguage);
  const aiWriterProjects = useAppSelector(state => state.aiWriter.projects);
  const currentProject = aiWriterProjects.find(project => project.id === activeDocumentId);
  const aiWriterProjectTags = useAppSelector(state =>
    activeDocumentId ? state.aiWriter.tabs[activeDocumentId]?.tags : []
  );
  const userId = useAppSelector(state => state.customer.id);
  const isDocumentAuthor = currentProject?.authorId === userId;

  const { width, ref } = useResizeDetector();
  const isSqueezed = width && width < DOCUMENT_TOP_BAR_SCREEN_WIDTH_THRESHOLD;
  // 650px is experimentally determined value at which the title should be truncated
  // not to cut-off the "Upgrade" button in the right-hand side of the top bar
  const shouldTruncateTitle = width && width < DOCUMENT_NAME_TRUNCATION_THRESHOLD_WIDTH;

  const activeDocument = documents.find(t => t.id === activeDocumentId);

  const documentName = activeDocument?.name || placeholderName || '';
  const truncatedName = documentName.length > 10 ? `${documentName.slice(0, 10)}...` : documentName;
  const displayName = shouldTruncateTitle ? truncatedName : documentName;
  const renderedName = renderName ? renderName(displayName) : displayName;

  const {
    anchorEl: tagsAnchorEl,
    isOpen: isTagsMenuOpen,
    onTriggerClick: onTagsTriggerClick,
    onMenuClose: onTagsMenuClose
  } = useMenu();

  const handleDocumentClose = (event: MouseEvent<HTMLButtonElement>, id: string | null) => {
    event.stopPropagation();

    if (!id) {
      return;
    }

    if (documents.length === 1) {
      onLastClose?.();
    }

    onClose?.(id);
  };

  const handleOpenTagsDropdown = (event: MouseEvent<HTMLDivElement>) => {
    onTagsTriggerClick(event);
    GAEvents.tagsIconClickedInDocumentEditor();
  };

  const sortedDocuments = documents
    .filter(hasUpdatedAt)
    .sort((a, b) => differenceInSeconds(parseISO(b.updatedAt), parseISO(a.updatedAt)));

  const onUpdateDocumentTags = () => {
    if (!activeDocumentId) {
      return;
    }

    fetchProject(
      { id: activeDocumentId },
      {
        onSuccess: project => {
          dispatch(loadTabThunk(project));
        }
      }
    );
  };

  if (!activeDocument) {
    return null;
  }

  return (
    <Root ref={ref}>
      <HeaderContent gap="one" direction="row">
        {isSqueezed ? (
          <DocumentPageTopBarMoreButton
            onBack={onBack}
            onCreate={onCreate}
            activeDocument={activeDocument}
            withLanguageChange={withLanguageChange}
            enablePersonalityButton={enablePersonalityButton}
            enableTagsButton={enableTagsButton}
          />
        ) : (
          <>
            <Tooltip title={translate('aiWriter.topbar.tooltip.back_to_overview')}>
              <StyledIconButton
                {...withGtmInteraction(gtmIds.aiWriter.backToOverview)}
                onClick={onBack}
              >
                <ChevronLeftIcon />
              </StyledIconButton>
            </Tooltip>
            {onCreate && (
              <Tooltip title={translate('aiWriter.topbar.tooltip.new_document')}>
                <StyledIconButton id={gtmIds.aiWriter.editorCreateNewProject} onClick={onCreate}>
                  <CreateIcon />
                </StyledIconButton>
              </Tooltip>
            )}

            {activeDocument.modelData && (
              <Tooltip title={translate('aiWriter.topbar.tooltip.change_language')}>
                <StyledIconButton
                  {...withGtmInteraction(gtmIds.aiWriter.languageSelector)}
                  onClick={withLanguageChange ? onLanguageTriggerClick : undefined}
                >
                  <SmallerCountryFlag
                    country={activeDocument.modelData.country}
                    language={activeDocument.modelData.language}
                  />
                </StyledIconButton>
              </Tooltip>
            )}
            {enableTagsButton && (
              <Tooltip title={translate('common.tags')}>
                <StyledIconButton onClick={handleOpenTagsDropdown}>
                  <TagsIcon fontSize="small" />
                </StyledIconButton>
              </Tooltip>
            )}
            {enablePersonalityButton && (
              <PersonalityButton>
                {onPersonalityIconClick => (
                  <Tooltip title={translate('aiWriter.topbar.personality_button.tooltip')}>
                    <StyledIconButton onClick={onPersonalityIconClick}>
                      <PersonalityIcon />
                    </StyledIconButton>
                  </Tooltip>
                )}
              </PersonalityButton>
            )}
          </>
        )}

        <NameContainer gap="small" direction="row">
          {withSaveIndicator && (
            <Tooltip
              title={translate(
                isSaving ? 'aiWriter.topbar.tooltip.is_saving' : 'aiWriter.topbar.tooltip.is_saved'
              )}
            >
              <ProjectSaver isSaving={isSaving} />
            </Tooltip>
          )}

          {withNameChange && isDocumentAuthor ? (
            <EditableContent
              value={activeDocument.name}
              onSave={onRename}
              maxLength={maxProjectNameLength}
            >
              <Tooltip title={<FormattedMessage id="common.project_breadcrumbs.edit" />}>
                <Name variant="body2">{renderedName}</Name>
              </Tooltip>
            </EditableContent>
          ) : (
            <Tooltip title={renderedName}>
              <Name variant="body2">{renderedName}</Name>
            </Tooltip>
          )}

          {sortedDocuments.length > 1 && (
            <MenuDropdown
              trigger={
                <Action {...withGtmInteraction(gtmIds.aiWriter.activeTabDropdown)} onClick={open}>
                  <Tooltip title={<FormattedMessage id="common.project_breadcrumbs.dropdown" />}>
                    <ArrowDropDownIcon />
                  </Tooltip>
                </Action>
              }
              isOpen={isOpen}
              anchorElement={anchorElement}
              close={close}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
              width="300px"
            >
              <MenuList>
                <RecentDocuments>
                  <RecentDocumentsHeader variant="overline">
                    <FormattedMessage id="common.project_breadcrumbs.recent_documents" />
                  </RecentDocumentsHeader>
                  {sortedDocuments.map(document => (
                    <MenuItem
                      key={document.id}
                      onClick={() => {
                        if (!document.id) {
                          return;
                        }

                        onSelect?.(document.id);
                        close();
                      }}
                    >
                      <ListItemIcon>{document.id === activeDocumentId && <Check />}</ListItemIcon>

                      <StyledListItemText>
                        <FlexContainer>
                          <TruncatedTypography>{document.name}</TruncatedTypography>
                          <TruncatedTypography variant="caption" color="textSecondary">
                            {document?.updatedAt && (
                              <FormattedMessage
                                id="common.project_breadcrumbs.edited"
                                values={{
                                  date: formatDistanceToNow(new Date(document.updatedAt), {
                                    addSuffix: true,
                                    locale: languageToDateFnsLocaleMapper(appLanguage)
                                  })
                                }}
                              />
                            )}
                          </TruncatedTypography>
                        </FlexContainer>
                      </StyledListItemText>
                      <IconButton onClick={e => handleDocumentClose(e, document.id)} size="large">
                        <CloseIcon />
                      </IconButton>
                    </MenuItem>
                  ))}
                </RecentDocuments>
              </MenuList>
            </MenuDropdown>
          )}
        </NameContainer>
      </HeaderContent>

      <FlexContainer gap="medium" direction="row">
        {withUsageIndicator && <UsageIndicatorWithPopover {...popoverProps} />}

        <FlexContainer gap="xsmall" direction="row">
          {children}
        </FlexContainer>
      </FlexContainer>

      <LanguageMenu
        anchorEl={languageAnchorEl}
        open={isLanguageMenuOpen}
        onClose={onLanguageMenuClose}
        {...popoverProps}
        isSaveAsDefaultShown={true}
        anchorOrigin={topBarDropdownAlignmentConfig.leftAlignment.anchorOrigin}
        transformOrigin={topBarDropdownAlignmentConfig.leftAlignment.transformOrigin}
      />
      <AiWriterProjectTagsDropdownMenu
        anchorEl={tagsAnchorEl}
        isOpen={isTagsMenuOpen}
        handleCloseMenu={onTagsMenuClose}
        tags={aiWriterProjectTags}
        project={currentProject}
        onCreateTag={onUpdateDocumentTags}
      />
    </Root>
  );
};

function hasUpdatedAt(
  doc: DocumentPageTopBarDocument
): doc is DocumentPageTopBarDocument & { updatedAt: string } {
  return 'updatedAt' in doc;
}

const Root = styled.div`
  flex: 0;
  // Required to align it with sidebar icon top offset
  margin-top: 2px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const HeaderContent = styled(FlexContainer)<{ $isSqueezed?: boolean }>`
  align-items: center;
  height: 30px;
`;

const NameContainer = styled(FlexContainer)`
  align-items: center;
  margin-left: ${({ theme }) => theme.spacings.two};

  min-width: 0;
`;

const Action = styled.button`
  ${resetButton};
  padding: 0;
`;

const Name = styled(Typography)`
  cursor: pointer;

  && {
    color: ${({ theme }) => theme.colors.textSecondary};
  }

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 140px;
`;

export const StyledIconButton = styled.div`
  position: relative;

  flex-shrink: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  // Should feel similar to AI writer top toolbar
  width: 30px;
  height: 30px;

  border: 0.5px solid ${({ theme }) => theme.colors.divider};
  border-radius: ${({ theme }) => theme.borderRadius.small};

  cursor: pointer;
`;

export const SmallerCountryFlag = styled(CountryFlag)`
  width: 20px;
  height: 15px;
  border-radius: ${({ theme }) => theme.borderRadius.small};
`;

export const CreateIcon = styled(Add)`
  color: ${({ theme }) => theme.colors.componentsIconActive};
`;

const CloseIcon = styled(Close)`
  color: ${({ theme }) => theme.colors.componentsIconActive};
`;

const ArrowDropDownIcon = styled(ArrowDropDown)`
  color: ${({ theme }) => theme.colors.componentsIconActive};
`;

const RecentDocuments = styled.div`
  padding: ${({ theme }) => `${theme.spacings.small} 0`};
`;

const RecentDocumentsHeader = styled(Typography)`
  margin-left: ${({ theme }) => theme.spacings.medium};

  font-size: ${({ theme }) => theme.fontSizes.caption};
`;

const StyledListItemText = styled(ListItemText)`
  margin-left: ${({ theme }) => theme.spacings.xsmall};
`;

const TruncatedTypography = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const TagsIcon = styled(LabelRounded)`
  color: ${({ theme }) => theme.colors.componentsIconActive};
`;
