import { WorkspacesRounded } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import { Button, Popover, Tooltip, Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { IntlMessageKeys } from 'features/i18n/IntlMessageKeys';
import useSubscriptionState from 'features/pricing/hook/useSubscriptionState';
import { PricingPlan } from 'features/pricing/types';
import { useInviteUserModal } from 'features/profilePage/useInviteUserModal';
import { useIsTeamOwnerOrAdmin } from 'features/team/useIsTeamOwnerOrAdmin';
import useUpdateSubscriptionModal from 'features/updateSubscriptionModal/hook/useUpdateSubscriptionModal';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled from 'styled-components';
import useDropdown from 'utils/hooks/useDropdown';

type PricingPlanTranslationKeys = {
  title: IntlMessageKeys;
  body: IntlMessageKeys;
  perks: IntlMessageKeys[];
  button: IntlMessageKeys;
};

type TextVariants = 'free_standard' | 'pro_enterprise';

const PRICING_PLAN_TEXTS: Record<TextVariants, PricingPlanTranslationKeys> = {
  free_standard: {
    title: 'home.header.team.invite_teammates.free_standard.title',
    body: 'home.header.team.invite_teammates.free_standard.body',
    perks: [
      'home.header.team.invite_teammates.free_standard.perk_1',
      'home.header.team.invite_teammates.free_standard.perk_2',
      'home.header.team.invite_teammates.free_standard.perk_3'
    ],
    button: 'home.header.team.invite_teammates.free_standard.button'
  },
  pro_enterprise: {
    title: 'home.header.team.invite_teammates.pro_enterprise.title',
    body: 'home.header.team.invite_teammates.pro_enterprise.body',
    perks: [
      'home.header.team.invite_teammates.pro_enterprise.perk_1',
      'home.header.team.invite_teammates.pro_enterprise.perk_2',
      'home.header.team.invite_teammates.pro_enterprise.perk_3'
    ],
    button: 'home.header.team.invite_teammates.pro_enterprise.button'
  }
};

export function InviteTeammatesButton({ onClick }: { onClick?: () => void }) {
  const { anchorElement, close, isOpen, open } = useDropdown();

  const isTeamOwnerOrAdmin = useIsTeamOwnerOrAdmin();

  const { currentPricingPlan } = useSubscriptionState();
  const isFreeOrStandardPlan =
    currentPricingPlan.type === PricingPlan.free ||
    currentPricingPlan.type === PricingPlan.standard;

  const planGroup: TextVariants = isFreeOrStandardPlan ? 'free_standard' : 'pro_enterprise';

  const showUpgradeSubscriptionModal = useUpdateSubscriptionModal();
  const showInviteUserModal = useInviteUserModal();

  const handleUpgradeClick = () => {
    showUpgradeSubscriptionModal();
    close();
  };

  const handleInviteClick = () => {
    showInviteUserModal();
    close();
  };

  const handleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    onClick?.();
    open(e);
  };

  return (
    <>
      <Button
        {...withGtmInteraction(gtmIds.home.inviteTeammates)}
        variant="outlined"
        startIcon={<WorkspacesRounded />}
        onClick={handleClick}
      >
        <FormattedMessage id="home.header.team.invite_teammates.button" />
      </Button>

      <StyledPopover
        open={isOpen}
        anchorEl={anchorElement}
        onClose={close}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Body>
          <Typography variant="h5">
            <FormattedMessage id={PRICING_PLAN_TEXTS[planGroup].title} />
          </Typography>
          <Typography variant="body2">
            <FormattedMessage id={PRICING_PLAN_TEXTS[planGroup].body} />
          </Typography>

          <FlexContainer gap="xsmall">
            {PRICING_PLAN_TEXTS[planGroup].perks.map(perk => (
              <FlexContainer key={perk} gap="small" direction="row">
                <StyledCheckIcon fontSize="small" />
                <Typography variant="body2">
                  <FormattedMessage id={perk} />
                </Typography>
              </FlexContainer>
            ))}
          </FlexContainer>

          {isFreeOrStandardPlan ? (
            <Button
              {...withGtmInteraction(gtmIds.home.inviteTeammatesPopup.upgrade)}
              variant="contained"
              onClick={handleUpgradeClick}
            >
              <FormattedMessage id={PRICING_PLAN_TEXTS[planGroup].button} />
            </Button>
          ) : (
            <Tooltip
              title={<FormattedMessage id="common.teams_invite_disabled" />}
              disableHoverListener={isTeamOwnerOrAdmin}
            >
              <span>
                <Button
                  variant="contained"
                  onClick={handleInviteClick}
                  disabled={!isTeamOwnerOrAdmin}
                  fullWidth
                >
                  <FormattedMessage id={PRICING_PLAN_TEXTS[planGroup].button} />
                </Button>
              </span>
            </Tooltip>
          )}
        </Body>
      </StyledPopover>
    </>
  );
}

const StyledPopover = styled(Popover)`
  margin-top: ${({ theme }) => theme.spacings.medium};
`;

const Body = styled.div`
  padding: ${({ theme }) => theme.spacings.medium};

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.medium};

  width: 27.5rem;
`;

const StyledCheckIcon = styled(CheckIcon)`
  color: rgba(76, 175, 80, 1);
`;
