// must be imported BEFORE App - WebStorm import sorter will try to fix it but it has to stay here
import '@formatjs/intl-relativetimeformat/polyfill';

import { initializeSentry } from 'config/initializeSentry';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import { createRoot } from 'react-dom/client';
import { Root } from 'Root';
// Monkey-patching library to fix a bug in Slate.js
import shimmer from 'shimmer';
import type { Node as NodeType } from 'slate';
import { Node, Text } from 'slate';

// Fix for https://app.clickup.com/t/86bwaavnk
// eslint-disable-next-line @typescript-eslint/no-unused-vars
shimmer.wrap(Node, 'string', _original => {
  return (node: NodeType) => {
    if (Text.isText(node)) {
      return node.text;
    }

    // Additional check to avoid errors
    if (!node.children) {
      return '';
    }

    return node.children.map(Node.string).join('');
  };
});

initializeSentry();

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(document.getElementById('root')!);
root.render(<Root />);
