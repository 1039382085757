import { Button, Typography } from '@mui/material';
import { CodeResponse, useGoogleLogin } from '@react-oauth/google';
import { ReactComponent as GoogleIcon } from 'assets/icons/icon-google.svg';
import LocalStorageKey from 'config/localStorageKey';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { mkEncodedSocialLoginState } from 'features/social/mkSocialLoginState';
import { socialButtonStyles } from 'features/social/SocialButtonStyles';
import { useCallback, useRef, useState } from 'react';
import { getSocialProviderRedirectUri } from 'services/api/social';
import { createUuidV4 } from 'utils/createUuidV4';
import useTr from 'utils/hooks/useTr';

type ErrorResponse = Pick<CodeResponse, 'error' | 'error_description' | 'error_uri'>;
type Props = {
  formName: 'login' | 'signup';
  newsletterSubscribed?: boolean;
  isPaid?: boolean;
  campaignUrl?: string;
};

export const GoogleLoginButton = ({
  formName,
  newsletterSubscribed,
  isPaid,
  campaignUrl
}: Props) => {
  const [authError, setAuthError] = useState<string | null>(null);
  const translate = useTr();

  const stateRef = useRef(createUuidV4());

  const state = mkEncodedSocialLoginState({
    uuid: stateRef.current,
    newsletterSubscribed: newsletterSubscribed,
    isPaid: isPaid,
    campaignUrl
  });

  const handleSuccess = useCallback(() => {
    // eslint-disable-next-line no-console
    console.error('onSuccess trigger, this should never happen');
  }, []);

  const handleError = useCallback(
    (response: ErrorResponse) => {
      setAuthError(
        response.error_description ?? response.error ?? translate('social.login.unknown_error')
      );
    },
    [translate]
  );

  const initLogin = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: handleSuccess,
    onError: handleError,
    // This redirects to /social/{provider}/callback, onSuccess won't be triggered
    redirect_uri: getSocialProviderRedirectUri('google'),
    // Don't change it to popup, because it creates a different "code" response
    // which is incompatible with the API implementation
    ux_mode: 'redirect',
    state
  });

  const messageId = formName === 'login' ? 'social.login.google' : 'social.signup.google';

  return (
    <>
      <Button
        css={socialButtonStyles}
        onClick={() => {
          initLogin();
          localStorage.setItem(LocalStorageKey.OauthCsrfState, state);
        }}
        startIcon={<GoogleIcon />}
      >
        <Typography variant="subtitle1">
          <FormattedMessage id={messageId} />
        </Typography>
      </Button>

      {authError && <p>{authError}</p>}
    </>
  );
};
