import LocalStorageKey from 'config/localStorageKey';
import { RestrictedRoute } from 'config/routes';
import cuid from 'cuid';
import { CustomerDataPayload } from 'features/customer/store/types';
import { PricingPlan } from 'features/pricing/types';
import { ACLPricing, CustomerData, Product, SubscriptionPeriod } from 'services/api/customer/types';
import { ApiPricingType, BOACLPricing } from 'services/api/pricing/types';
import { Message, MessageType } from 'types/Message';
import { isDevEnv } from 'utils/isDevEnv';
import { forceNever } from 'utils/typescript/never';

type ProductRestrictions = CustomerDataPayload['restrictions'];

// https://backofficeapi.neuro-flash.com:5011/product - IDs
const idToProduct: Partial<Record<number, RestrictedRoute>> = {
  [isDevEnv() ? 12 : 7]: 'exploring',
  [isDevEnv() ? 18 : 8]: 'aiTester',
  [isDevEnv() ? 19 : 17]: 'aiImages',
  [isDevEnv() ? 22 : 14]: 'aiWriter',
  [isDevEnv() ? 24 : 16]: 'subjectLines'
};

export const getProductRestrictions = (options: {
  availableProducts: Product[];
}): ProductRestrictions => {
  const { availableProducts } = options;

  const restrictions: ProductRestrictions = {
    exploring: true,
    aiTester: true,
    aiWriter: true,
    subjectLines: true,
    aiImages: true
  };

  availableProducts.forEach(product => {
    const productName = idToProduct[product.id];
    if (productName) {
      restrictions[productName] = false;
    }
  });
  return restrictions;
};

// All plans below that number are treated as monthly and all above are treated as annually
const yearlyPeriodDuration = 365;

const getPeriodName = (period: number): SubscriptionPeriod =>
  period >= yearlyPeriodDuration ? 'year' : 'month';

export const mapBackofficePricingPlan = ({
  id,
  cost,
  currency,
  type,
  name,
  period,
  description,
  is_trial
}: BOACLPricing): ACLPricing => ({
  id,
  cost,
  currency,
  name,
  type: mapToPricingPlan(type),
  period,
  periodName: getPeriodName(period),
  description: description ?? undefined,
  isFree: cost === '0.00' || cost === '0',
  isTrial: is_trial === 1 ? true : false
});

function mapToPricingPlan(typeFromBe: BOACLPricing['type']): PricingPlan {
  // noinspection JSUnreachableSwitchBranches
  switch (typeFromBe) {
    case 'Basic':
    case 'Basic S':
      return PricingPlan.basic;
    case 'Enterprise':
      return PricingPlan.enterprise;
    case 'Free':
      return PricingPlan.free;
    case 'Power':
      return PricingPlan.power;
    case 'Power (VIP)':
      return PricingPlan.powerVip;
    case 'Power (lower)':
      return PricingPlan.powerLower;
    case 'Power (higher)':
      return PricingPlan.powerHigher;
    case 'Premium':
      return PricingPlan.premium;
    case 'Standard':
      return PricingPlan.standard;
    case 'Pro':
      return PricingPlan.pro;
    case 'Business':
      return PricingPlan.business;
    case 'no limitations':
      return PricingPlan.enterprise;
    case ApiPricingType.Flex:
    case ApiPricingType.Light:
    case ApiPricingType.Medium:
    case ApiPricingType.Advanced:
      return PricingPlan.ignore;
    default:
      return forceNever(typeFromBe, 'Unexpected pricing type from BE');
  }
}

export const getUserData = (data: CustomerData): CustomerDataPayload => ({
  id: data.id,
  ...(data.api_token ? { apiToken: data.api_token } : {}),
  apiCustomerToken: data.api_customer_token,
  username: data.name,
  image: data.image_url,
  email: data.email,
  timezone: data.timezone,
  groupId: data.group_id,
  cardBrand: data.pm_type,
  isVerified: data.verified === 1,
  cardLastFour: data.pm_last_four,
  billingInfo: {
    fullName: data.name || '',
    address: data.address || '',
    city: data.city || '',
    postalCode: data.postal_code || '',
    country: data.country || '',
    phone: data.phone || '',
    companyName: data.company_name || '',
    useCompanyAddress: !!data.use_company_name || false
  },

  pricing: mapBackofficePricingPlan(data.pricing),
  pricingExpiryDate: data.expiry_date,
  // TODO: Fix me with new token based logic
  pricingExpired: false,
  // TODO refactor backend and move to the features store
  restrictions: getProductRestrictions({
    availableProducts: data.pricing.products ?? []
  }),
  teamRole: data.team_role,
  teams: data.teams,
  stripeId: data.stripe_id,
  registrationDate: data.created_at,
  sepaPaymentFailed: data.sepa_payment_failed === 1,
  referralLink: data.first_promoter_referral_link,
  wordsUsedMonthly: data.words_used_monthly,
  isApiCustomer: data.is_api_customer,
  wordsShownMonthly: data.words_shown_monthly,
  currency: data.currency
});

const getIsFirstLoginFromApiOrLocal = (
  localIsFirstLogin: null | string,
  apiFirstLoginState?: number
): boolean => {
  // Prefer API flag over local storage
  if (apiFirstLoginState === 1) {
    return true;
  }

  // Never set in local state = true
  if (localIsFirstLogin === null) {
    return true;
  }

  return localIsFirstLogin === '1';
};

export const setIsFirstLoginInLocalStorage = (isFirstLogin: boolean): void => {
  localStorage.setItem(LocalStorageKey.CustomerIsFirstLogin, isFirstLogin ? '1' : '0');
};

export const saveUserDataToStorage = (
  { apiToken, username, timezone, image, isApiCustomer }: CustomerDataPayload,
  apiFirstLoginState?: number
) => {
  const isFirstLogin = getIsFirstLoginFromApiOrLocal(
    localStorage.getItem(LocalStorageKey.CustomerIsFirstLogin),
    apiFirstLoginState
  );

  if (apiToken) {
    localStorage.setItem(LocalStorageKey.APIToken, apiToken);
  }
  localStorage.setItem(LocalStorageKey.IsApiCustomer, isApiCustomer ? '1' : '0');
  localStorage.setItem(LocalStorageKey.CustomerUsername, username);
  localStorage.setItem(LocalStorageKey.CustomerTimezone, timezone);
  localStorage.setItem(LocalStorageKey.CustomerPicture, image ?? '');
  setIsFirstLoginInLocalStorage(isFirstLogin);
};

export const removeLocalUserData = () => {
  localStorage.removeItem(LocalStorageKey.APIToken);
  localStorage.removeItem(LocalStorageKey.IsApiCustomer);
  localStorage.removeItem(LocalStorageKey.CustomerUsername);
  localStorage.removeItem(LocalStorageKey.CustomerTimezone);
  localStorage.removeItem(LocalStorageKey.CustomerPicture);
  // Note: Don't remove the first login flag, it's used to determine if the user has logged in before
  setIsFirstLoginInLocalStorage(false);
};

export const createMessage = (type: MessageType, message?: string): Message => ({
  id: cuid(),
  type,
  message
});
