import { Form, Formik } from 'formik';
import { motion } from 'framer-motion';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { validateGoalConfig } from 'utils/tabUtils';

import useConstant from '../../utils/hooks/useConstant';
import useTr from '../../utils/hooks/useTr';
import ConfigForm, { ConfigFormValues } from './ConfigForm';
import { getActiveTab } from './store/selectors';
import { rateWordsThunk } from './store/thunks';

const FormContainer = styled(motion.div)`
  grid-column: 1 / span 2;
  grid-row: 1;
  width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  padding-bottom: 1rem;
`;

const ConfigSection = () => {
  const tr = useTr();
  const activeTab = useAppSelector(getActiveTab);
  const dispatch = useAppDispatch();

  const initialValues = useConstant(() => {
    const {
      manualDimensions,
      dimensions,
      seoParams,
      seedWords,
      filters,
      goalConfig,
      wordAttributes,
      listId
    } = activeTab;
    return {
      manualDimensions,
      dimensions,
      seoParams,
      seedWords,
      filters,
      goalConfig,
      wordAttributes,
      listId
    };
  });

  return (
    <FormContainer>
      <Formik
        onSubmit={(values: ConfigFormValues) => {
          const { dimensions, manualDimensions, wordAttributes } = values;
          const wordAttributesModelValues = wordAttributes.map(attr => attr.value);
          const valuesWithGoalConfig = {
            ...values,
            goalConfig: {
              model_rank_score: wordAttributesModelValues.includes('model_rank_score') ? 1 : 0,
              seed_score: wordAttributesModelValues.includes('seed_score') ? 1 : 0,
              mappers: wordAttributes.map(({ value, option }) => {
                return { id: value, weight: option?.oppositeValue ? -1 : 1 };
              }),
              dimensions: dimensions
                .map(({ id }) => ({ id, weight: 1 }))
                .concat(manualDimensions.map(({ id }) => ({ id, weight: 1 })))
            }
          };
          dispatch(rateWordsThunk(valuesWithGoalConfig));
        }}
        validate={values => {
          const errors: Partial<Record<keyof typeof values, string>> = {};
          const {
            listId,
            seedWords,
            goalConfig,
            wordAttributes,
            seoParams,
            manualDimensions,
            dimensions
          } = values;

          if (!listId && seedWords.length === 0) {
            errors.seedWords = tr('exploring.form.error.seed_words');
          }

          if (seedWords.some(tag => !tag.isAvailable || !tag.checked)) {
            errors.seedWords = tr('exploring.form.error.seed_words_invalid');
          }

          if (!validateGoalConfig(goalConfig)) {
            errors.goalConfig = tr('exploring.form.error.goal_score');
          }

          if (
            wordAttributes.length +
              seoParams.length +
              manualDimensions.length +
              dimensions.length ===
            0
          ) {
            errors.wordAttributes = tr('exploring.form.error.parameters');
          }

          return errors;
        }}
        initialValues={initialValues}
      >
        {props => (
          <Form>
            <ConfigForm {...props} />
          </Form>
        )}
      </Formik>
    </FormContainer>
  );
};

export default ConfigSection;
