import 'react-multi-carousel/lib/styles.css';

import { Button, Typography } from '@mui/material';
import { ReactComponent as IconNeuroflashLogoSVG } from 'assets/neuroflashLogo.svg';
import FlexContainer from 'components/FlexContainer';
import { ActionImageSectionTile } from 'features/onboardingJourney/mobile/ActionImageSectionTile';
import {
  Steps,
  useStartFromScratchStep
} from 'features/onboardingJourney/useActionImageSectionTile';
import { useDisableSidebarOnMount } from 'features/pageSidebar/useSidebarStore';
import { useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import Carousel, { CarouselProps } from 'react-multi-carousel';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import { mobileWindowWidthCSS } from 'utils/hooks/useMobileVersion';

const responsive: CarouselProps['responsive'] = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    partialVisibilityGutter: 40 // this is needed to tell the amount of px that should be visible.
  },
  mobile: {
    breakpoint: { max: 922, min: 0 },
    items: 1,
    partialVisibilityGutter: 30 // this is needed to tell the amount of px that should be visible.
  }
};

type PreviousLocationStateType = Location & {
  state?: {
    skippedPersonalityCreation?: boolean;
  };
};

export const MobileOnboardingSelection = () => {
  const { state: locationState } = useLocation() as unknown as PreviousLocationStateType;
  const carouselRef = useRef<Carousel>(null);
  const startFromScratchStep = useStartFromScratchStep();

  useDisableSidebarOnMount();

  // this can be set in onboarding personality creation page
  const shouldSkipPersonality = locationState?.skippedPersonalityCreation;
  useEffect(() => {
    if (shouldSkipPersonality) {
      carouselRef.current?.goToSlide(1);
    }
  }, [shouldSkipPersonality]);

  return (
    <Root>
      <NeuroflashLogo />
      <Heading>
        <Title>
          <FormattedMessage id="onboarding.selection.title" />
        </Title>
        <Subtitle variant="body1" color="textSecondary">
          <FormattedMessage id="onboarding.selection.subtitle" />
        </Subtitle>
      </Heading>

      <CarouselContainer>
        <Carousel ref={carouselRef} partialVisbile={true} responsive={responsive}>
          {Object.values(Steps).map(step => (
            <ActionImageSectionTile key={step} selectedTile={step} />
          ))}
        </Carousel>
      </CarouselContainer>

      <Button variant="text" size="large" fullWidth={true} onClick={startFromScratchStep.action}>
        {startFromScratchStep.title}
      </Button>
    </Root>
  );
};

const Root = styled(FlexContainer).attrs({
  alignItems: 'center',
  gap: 'xmedium'
})`
  margin: ${({ theme }) => `${theme.spacings.small} 0 ${theme.spacings.medium} 0`};
  gap: ${({ theme }) => theme.spacings.medium};
  height: 100%;

  @media (min-width: calc(${mobileWindowWidthCSS} + 1px)) {
    display: none;
    visibility: hidden;
  }
`;

const Heading = styled(FlexContainer)`
  width: 100%;
  gap: ${({ theme }) => theme.spacings.small};
`;

const NeuroflashLogo = styled(IconNeuroflashLogoSVG)`
  margin-top: 0;
  margin-bottom: 0;
  position: static;
`;

const Title = styled(Typography).attrs({ variant: 'h6' })`
  text-align: center;
`;

const Subtitle = styled(Typography)`
  text-align: center;
`;

const CarouselContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
