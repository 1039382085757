import { Close, RemoveCircleOutline } from '@mui/icons-material';
import React from 'react';
import styled from 'styled-components';

import { IconButton, IconButtonSize } from '../IconButton';

type Props = {
  id?: string;
  className?: string;
  circle?: boolean;
  tooltip?: React.ReactNode;
  size?: IconButtonSize;

  onClick: () => void;
};

const DeleteIconButton = ({ id, className, circle, tooltip, size = 'small', onClick }: Props) => (
  <StyledIconButton
    id={id}
    className={className}
    size={size}
    icon={
      circle ? (
        <RemoveCircleOutline fontSize={size} />
      ) : (
         <Close fontSize={size} />
      )
    }
    tooltip={tooltip}
    onClick={onClick}
  />
);

const StyledIconButton = styled(IconButton)`
  color: ${({ theme }) => theme.colors.componentsIconActive};
`;

export default DeleteIconButton;