import {
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_IMAGE,
  ELEMENT_LI,
  ELEMENT_LIC,
  ELEMENT_LINK,
  ELEMENT_PARAGRAPH,
  ELEMENT_UL,
  TElement
} from '@udecode/plate';
import Toast from 'components/toasts/Toast';
import { getCurrentModelLanguageAndCountry } from 'features/aiWriter/store/selectors';
import { getPreferredAudience } from 'features/aiWriter/utils/getPreferredAudience';
import { getUserAudiences } from 'features/audiences/store/selectors';
import { getEmbeddingModelDataSelector } from 'features/embeddingModels/store/selectors';
import { invalidateWordsUsageQueries } from 'features/wordsUsage/invalidateWordsUsageQueries';
import UnsplashAPI from 'services/api/unsplash';
import { invalidateUnsplashImagesUsageQueries } from 'services/api/unsplash/invalidateUnsplashImagesUsageQueries';
import { handleApiCommonErrors } from 'services/api/utils/handleApiCommonErrors';
import {
  BlogPostDto,
  GenerateBlogPostParams,
  httpGenerateBlogPost,
  OutlineItem,
  QuestionItem
} from 'services/backofficeIntegration/http/endpoints/aiWriter/httpGenerateBlogPost';
import { invalidateCustomerAllLimitationsQueries } from 'services/backofficeIntegration/http/endpoints/customer/httpGetAllLimitations';
import { useAppSelector } from 'store/hooks';
import useTr from 'utils/hooks/useTr';

type ImageConfig = {
  query: string;
};

type BlogConfig = Omit<GenerateBlogPostParams, 'country' | 'audience_model_id'> & {
  language: string;
};

type Params = {
  blogConfig: BlogConfig;
  imageConfig: ImageConfig | null;
};

export const useGenerateFullBlogPost = () => {
  const getModelById = useAppSelector(getEmbeddingModelDataSelector);
  const { currentModelCountry } = useAppSelector(getCurrentModelLanguageAndCountry);
  const audiences = useAppSelector(getUserAudiences);

  const tr = useTr();

  return async (params: Params): Promise<TElement[]> => {
    const { blogConfig, imageConfig } = params;

    const model = getModelById(blogConfig.language);
    const language = model.language;

    const audience = getPreferredAudience({
      audiences,
      locale: { language, country: currentModelCountry }
    });

    const [{ external_links, intro, outline, questions, youtube_video }, image] = await Promise.all(
      [
        httpGenerateBlogPost
          .callEndpoint({
            ...blogConfig,
            language,
            country: currentModelCountry,
            audience_model_id: audience?.model_id ?? ''
          })
          .catch(
            (): BlogPostDto => ({
              intro: '',
              youtube_video: [],
              external_links: [],
              outline: blogConfig.outline.map(headline => ({ headline, paragraph: '' })),
              questions: []
            })
          ),
        imageConfig
          ? UnsplashAPI.get({ ...imageConfig, lang: language, per_page: 1 }).then(response => {
              if (response.status) {
                return response.data.results[0]?.urls.full ?? null;
              } else {
                Toast.backendError(handleApiCommonErrors(response.message));
                return null;
              }
            })
          : Promise.resolve(null)
      ]
    );

    invalidateWordsUsageQueries();
    invalidateUnsplashImagesUsageQueries();
    invalidateCustomerAllLimitationsQueries();

    return [
      ...makeMainContentNodes(image, intro, outline),
      ...makeQuestionsNodes(
        questions,
        tr('blog_post_builder.generated.questions.heading'),
        tr('blog_post_builder.generated.questions.paragraph')
      ),
      ...makeExternalLinksNodes(
        external_links,
        tr('blog_post_builder.generated.references.heading'),
        tr('blog_post_builder.generated.references.paragraph')
      ),
      ...makeYoutubeVideoNodes(
        youtube_video,
        tr('blog_post_builder.generated.youtube.heading'),
        tr('blog_post_builder.generated.youtube.paragraph')
      )
    ];
  };
};

function makeMainContentNodes(
  imageUrl: string | null,
  intro: string,
  outline: OutlineItem[]
): TElement[] {
  return [
    ...(imageUrl
      ? [
          {
            type: ELEMENT_IMAGE,
            url: imageUrl ?? '',
            children: [
              {
                text: ''
              }
            ]
          }
        ]
      : []),
    {
      type: ELEMENT_PARAGRAPH,
      children: [
        {
          text: intro
        }
      ]
    },
    ...outline.flatMap(({ headline, paragraph }) => [
      {
        type: ELEMENT_H3,
        children: [
          {
            text: headline
          }
        ]
      },
      {
        type: ELEMENT_PARAGRAPH,
        children: [
          {
            text: paragraph
          }
        ]
      }
    ])
  ];
}

function makeQuestionsNodes(
  questions: QuestionItem[],
  heading: string,
  paragraph: string
): TElement[] {
  return questions.length < 1
    ? []
    : [
        {
          type: ELEMENT_H2,
          children: [
            {
              text: heading
            }
          ]
        },
        {
          type: ELEMENT_PARAGRAPH,
          children: [
            {
              text: paragraph
            }
          ]
        },
        ...questions.flatMap(({ questions, answer }) => [
          {
            type: ELEMENT_H3,
            children: [
              {
                text: questions
              }
            ]
          },
          {
            type: ELEMENT_PARAGRAPH,
            children: [
              {
                text: answer
              }
            ]
          }
        ])
      ];
}

function makeExternalLinksNodes(
  external_links: string[],
  heading: string,
  paragraph: string
): TElement[] {
  // Limit to max 3 links
  const limitedLinks = external_links.slice(0, 3);

  return external_links.length < 1
    ? []
    : [
        {
          type: ELEMENT_H2,
          children: [
            {
              text: heading
            }
          ]
        },
        {
          type: ELEMENT_PARAGRAPH,
          children: [
            {
              text: paragraph
            }
          ]
        },
        {
          type: ELEMENT_UL,
          children: limitedLinks.map(link => ({
            type: ELEMENT_LI,
            children: [
              {
                type: ELEMENT_LIC,
                children: [
                  {
                    type: ELEMENT_LINK,
                    url: link,
                    children: [
                      {
                        text: link
                      }
                    ]
                  }
                ]
              }
            ]
          }))
        }
      ];
}

function makeYoutubeVideoNodes(
  youtube_video: string[],
  heading: string,
  paragraph: string
): TElement[] {
  return youtube_video.length < 1
    ? []
    : [
        {
          type: ELEMENT_H2,
          children: [
            {
              text: heading
            }
          ]
        },
        {
          type: ELEMENT_PARAGRAPH,
          children: [
            {
              text: paragraph
            }
          ]
        },
        {
          type: ELEMENT_PARAGRAPH,
          children: [
            {
              type: ELEMENT_LINK,
              url: youtube_video[0], // requirement to display only one video
              children: [
                {
                  text: youtube_video[0]
                }
              ]
            }
          ]
        }
      ];
}
