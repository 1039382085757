import useRedirectWhenAuthenticated from 'components/auth/hooks/useRedirectWhenAuthenticated';
import { RecaptchaProvider } from 'components/RecaptchaProvider';
import { CreateAccountStep } from 'components/register/CreateAccountStep';
import { StripeProvider } from 'features/pricing/StripeProvider';
import { ReactElement } from 'react';

export const RegisterPage = (): ReactElement => {
  useRedirectWhenAuthenticated();

  return (
    <RecaptchaProvider>
      <StripeProvider>
        <CreateAccountStep />
      </StripeProvider>
    </RecaptchaProvider>
  );
};
