import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { insertNodes } from '@udecode/plate';
import { ReactComponent as RewriteIcon } from 'assets/icons/icon-rewrite-primary.svg';
import { ColoredTypography } from 'components/ColoredTypography';
import { ExternalLink } from 'components/ExternalLink';
import FlexContainer from 'components/FlexContainer';
import { LoadingButton } from 'components/LoadingButton';
import useEditor from 'features/aiWriter/AiWriterTextEditor/hooks/useEditor';
import { updateCurrentProjectInBackgroundThunk } from 'features/aiWriter/store/actions/project/thunks/updateCurrentProjectInBackground';
import { rewriteSeoSearchResultThunk } from 'features/aiWriter/store/actions/seoAnalysis/thunks/rewriteSeoSearchResultThunk';
import { isSeoRewriteSearchSupported } from 'features/aiWriter/store/selectors';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import gtmIds from 'services/tracking/GTMIds';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import useRunInTask from 'utils/hooks/useRunInTask';

import { SeoSearchResultRewrittenBadge } from './SeoSearchResultRewrittenBadge';

type SeoSearchResultOriginalTileProps = {
  title: string;
  description: string;
  link: string;
  rank: number;
  domain: string;
  wordCount: number;
};

export function SeoSearchResultOriginalTile({
  title,
  description,
  domain,
  link,
  rank,
  wordCount
}: SeoSearchResultOriginalTileProps) {
  const dispatch = useAppDispatch();
  const editor = useEditor();

  const isRewriteSupported = useAppSelector(isSeoRewriteSearchSupported);

  const [isRewriteLoading, runInRewriteTask] = useRunInTask();

  const moveResultToEditor = useCallback(() => {
    if (!editor) {
      return;
    }

    insertNodes(editor, [
      { type: 'h3', children: [{ text: title }] },
      { type: 'paragraph', children: [{ text: description }] }
    ]);

    dispatch(updateCurrentProjectInBackgroundThunk());
  }, [title, description, dispatch, editor]);

  const rewriteResult = useCallback(
    (searchResultId: number) => {
      runInRewriteTask(async () => {
        await dispatch(rewriteSeoSearchResultThunk({ searchResultId }));
      });
    },
    [runInRewriteTask, dispatch]
  );

  return (
    <Root>
      <FlexContainer gap="medium">
        <FlexContainer gap="small">
          <ExternalLink href={link}>{domain}</ExternalLink>
          <ColoredTypography variant="subtitle1" color="blackHighEmphasis">
            {title}
          </ColoredTypography>
          <FlexContainer direction="row" gap="medium">
            <ColoredTypography variant="caption" color="blackDisabled">
              <FormattedMessage id="aiWriter.inspirations.seoAnalysis.search_results.ranking" />#
              {rank}
            </ColoredTypography>
            <ColoredTypography variant="caption" color="blackDisabled">
              <FormattedMessage id="aiWriter.inspirations.seoAnalysis.search_results.word_count" />
              {wordCount}
            </ColoredTypography>
          </FlexContainer>
        </FlexContainer>
        <ColoredTypography variant="body2" color="blackMediumEmphasis">
          {description}
        </ColoredTypography>
        <FlexContainer gap="large" direction="row">
          <Button
            id={gtmIds.aiWriter.seo.addSERP}
            startIcon={<AddIcon />}
            onClick={moveResultToEditor}
          >
            <FormattedMessage id="aiWriter.inspirations.seoAnalysis.search_results.add_to_editor" />
          </Button>
          {isRewriteSupported && (
            <LoadingButton
              loading={isRewriteLoading}
              startIcon={<RewriteIcon />}
              onClick={() => rewriteResult(rank)}
            >
              <FormattedMessage id="common.rewrite" />
            </LoadingButton>
          )}
        </FlexContainer>
      </FlexContainer>
    </Root>
  );
}

type SeoSearchResultRewrittenTileProps = {
  title: string;
  description: string;
};

export const SeoSearchResultRewrittenTile = ({
  title,
  description
}: SeoSearchResultRewrittenTileProps) => {
  const dispatch = useAppDispatch();
  const editor = useEditor();

  const moveResultToEditor = useCallback(() => {
    if (!editor) {
      return;
    }

    insertNodes(editor, [
      { type: 'h3', children: [{ text: title }] },
      { type: 'paragraph', children: [{ text: description }] }
    ]);

    dispatch(updateCurrentProjectInBackgroundThunk());
  }, [title, description, dispatch, editor]);

  return (
    <Root>
      <FlexContainer gap="medium">
        <FlexContainer gap="small">
          <SeoSearchResultRewrittenBadgeContainer>
            <SeoSearchResultRewrittenBadge />
          </SeoSearchResultRewrittenBadgeContainer>
        </FlexContainer>
        <ColoredTypography variant="subtitle1" color="blackHighEmphasis">
          {title}
        </ColoredTypography>
        <ColoredTypography variant="body2" color="blackMediumEmphasis">
          {description}
        </ColoredTypography>
        <FlexContainer gap="large" direction="row">
          <Button
            id={gtmIds.aiWriter.seo.addSERP}
            startIcon={<AddIcon />}
            onClick={moveResultToEditor}
          >
            <FormattedMessage id="aiWriter.inspirations.seoAnalysis.search_results.add_to_editor" />
          </Button>
        </FlexContainer>
      </FlexContainer>
    </Root>
  );
};

const Root = styled.div`
  padding: ${({ theme }) => `${theme.spacings.small} ${theme.spacings.medium}`};

  background-color: ${({ theme }) => theme.colors.surfaceCard};
`;

const SeoSearchResultRewrittenBadgeContainer = styled.div`
  display: flex;
  align-self: flex-end;
`;
