import { FavoriteRounded, Help, HistoryRounded } from '@mui/icons-material';
import styled from 'styled-components';

export const ColoredHelpIcon = styled(Help).attrs({ fontSize: 'small' })`
  color: ${({ theme }) => theme.colors.componentsIconActive};
`;

export const ColoredFavoriteRoundedIcon = styled(FavoriteRounded).attrs({ fontSize: 'small' })`
  color: ${({ theme }) => theme.colors.componentsIconActive};
`;

export const ColoredHistoryRoundedIcon = styled(HistoryRounded).attrs({ fontSize: 'small' })`
  color: ${({ theme }) => theme.colors.componentsIconActive};
`;
