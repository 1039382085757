import { Paper, Typography } from '@mui/material';
import { Container } from 'components/Container';
import FlexContainer from 'components/FlexContainer';
import { getIsApiCustomer } from 'features/customer/store/selectors';
import { ApiDashboard } from 'features/homePage/ApiDashboard';
import {
  HomeBrowserExtensionBanner,
  useShouldShowBrowserExtensionHint
} from 'features/homePage/HomeBrowserExtensionBanner';
import { HomeDeactivatedAccountBanner } from 'features/homePage/HomeDeactivatedAccountBanner';
import { HomeFeatureCards } from 'features/homePage/HomeFeatureCards';
import { HomeHeader } from 'features/homePage/HomeHeader';
import { HomeOnboardingFeatureCard } from 'features/homePage/HomeOnboardingFeatureCard';
import { HomeRecentFiles } from 'features/homePage/HomeRecentFiles';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { LearningCenter } from 'features/learningCenter/LearningCenter';
import { useShouldShowOnboardingChecklist } from 'features/onboardingJourney/checklist/ChecklistButton';
import { useNavigateToOnboarding } from 'features/onboardingJourney/useNavigateToOnboarding';
import { useEnableSidebarOnMount } from 'features/pageSidebar/useSidebarStore';
import { TrialCanceledBanner } from 'features/pricing/TrialCanceledBanner';
import { TrialExpiryBanner } from 'features/pricing/TrialExpiryBanner';
import { ReactElement } from 'react';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { mobileWindowWidthCSS } from 'utils/hooks/useMobileVersion';

const HomePage = (): ReactElement => {
  const isApiCustomer = useAppSelector(getIsApiCustomer);

  const shouldShowOnboardingChecklist = useShouldShowOnboardingChecklist();
  const shouldShowBrowserExtensionHint =
    useShouldShowBrowserExtensionHint() && !shouldShowOnboardingChecklist;

  useEnableSidebarOnMount();

  useNavigateToOnboarding();

  if (isApiCustomer) {
    return <ApiDashboard />;
  }

  return (
    <Container size="xl">
      <HomeHeader />

      <ContentContainer gap="xmedium">
        <HomeDeactivatedAccountBanner />
        {shouldShowBrowserExtensionHint && <HomeBrowserExtensionBanner />}
        <TrialExpiryBanner />
        <TrialCanceledBanner />
        <TopContentContainer $twoColumns={shouldShowOnboardingChecklist}>
          <FlexContainer gap="small" direction="column">
            <SectionHeadline>
              <FormattedMessage id="home.sections.tools.title" />
            </SectionHeadline>
            <FeatureCardContainer
              // Only if the onboarding card is NOT shown, we have enough space for 4 columns
              $fourColumns={!shouldShowOnboardingChecklist}
            >
              <HomeFeatureCards />
            </FeatureCardContainer>
          </FlexContainer>

          {shouldShowOnboardingChecklist && (
            <FlexContainer gap="small" direction="column">
              <SectionHeadline>
                <FormattedMessage id="home.sections.onbording.title" />
              </SectionHeadline>

              <HomeOnboardingFeatureCard />
            </FlexContainer>
          )}
        </TopContentContainer>
        <FlexContainer gap="small" direction="column">
          <SectionHeadline>
            <FormattedMessage id="home.sections.recentFiles.title" />
          </SectionHeadline>

          <HomeRecentFiles />
        </FlexContainer>
        <Paper variant="outlined">
          <LearningCenter defaultTab="videoTutorials" />
        </Paper>
      </ContentContainer>
    </Container>
  );
};

function SectionHeadline({ children }: { children: ReactElement }) {
  return <Typography variant="subtitle2">{children}</Typography>;
}

const ContentContainer = styled(FlexContainer)`
  margin-top: ${({ theme }) => theme.spacings.xlarge};
  margin-bottom: ${({ theme }) => theme.spacings.xlarge};
`;

const TopContentContainer = styled.div<{ $twoColumns: boolean }>`
  display: grid;
  grid-template-columns: ${({ $twoColumns }) => `repeat(${$twoColumns ? 2 : 1}, 1fr)`};
  gap: ${({ theme }) => theme.spacings.medium};

  @media (max-width: 1280px) {
    grid-template-columns: repeat(1, 1fr);

    > :first-child {
      order: 2;
    }
  }
`;

const FeatureCardContainer = styled.div<{ $fourColumns: boolean }>`
  flex: 1;
  display: grid;
  grid-template-columns: ${({ $fourColumns }) => `repeat(${$fourColumns ? 4 : 2}, 1fr)`};
  gap: ${({ theme }) => theme.spacings.three};

  @media (max-width: ${mobileWindowWidthCSS}) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export default HomePage;
