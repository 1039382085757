import './RootModal.scss';

import cx from 'classnames';
import { modalComponents } from 'components/modals/modalComponents';
import { ModalContext } from 'components/modals/ModalProvider';
import { ErrorBoundary } from 'features/react-error-boundary/ErrorBoundary';
import React, { useContext } from 'react';
import ReactModal from 'react-modal';

ReactModal.setAppElement('#root');

export type ModalCommonProps = {
  closeModal: () => void;
};

const RootModal = () => {
  const { modalProps, modalType, isOpen, hideModal, cleanUpModal } = useContext(ModalContext);
  const { size, onClose, afterClose, uncloseable, ...specificModalProps } = modalProps;

  const closeModal = () => {
    hideModal();
    onClose?.();
  };

  const handleAfterClose = () => {
    cleanUpModal();
    afterClose?.();
  };

  if (!modalType || !modalComponents[modalType]) {
    return null;
  }
  const SpecificModal: React.ElementType = modalComponents[modalType];

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={closeModal}
      onAfterClose={handleAfterClose}
      closeTimeoutMS={200}
      bodyOpenClassName="modal-open"
      overlayClassName={cx('modal fade', isOpen && 'show')}
      className={cx('modal-dialog', size && `modal-${size}`)}
      shouldCloseOnOverlayClick={!uncloseable}
      shouldCloseOnEsc={!uncloseable}
      shouldFocusAfterRender={true}
      shouldReturnFocusAfterClose={true}
    >
      <ErrorBoundary>
        <div className="modal-content">
          <SpecificModal closeModal={closeModal} {...specificModalProps} />
        </div>
      </ErrorBoundary>
    </ReactModal>
  );
};

export default RootModal;
