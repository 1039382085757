import { useMutation } from '@tanstack/react-query';
import LocalStorageKey from 'config/localStorageKey';
import { configureChatTabThunk } from 'features/aiWriter/store/actions/tabs/thunks/configureChatTabThunk';
import { initializeTabThunk } from 'features/aiWriter/store/actions/tabs/thunks/initializeTabThunk';
import { unnamed } from 'features/aiWriter/utils/unnamed';
import useGetModelAudiences from 'features/audiences/hooks/useGetModelAudiences';
import { getEmbeddingModelsByLanguageAndAudience } from 'features/embeddingModels/store/selectors';
import getModelInitValue from 'features/embeddingModels/store/utils/getModelInitValue';
import { useGetDefaultOutputTypeByLanguageCached } from 'features/textGenerator/utils/useGetPromptsOthers';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { assertNonNullable } from 'utils/typescript/nonNullable';

export function useCreateNewChatProject() {
  const dispatch = useAppDispatch();

  const models = useAppSelector(getEmbeddingModelsByLanguageAndAudience);
  const defaultModel = getModelInitValue(models, LocalStorageKey.AiWriterModel) ?? models[0];

  const getModelAudiences = useGetModelAudiences();
  const audience = getModelAudiences(defaultModel.id)[0];

  const defaultOutputType = useGetDefaultOutputTypeByLanguageCached(audience?.language || '');

  const { mutate: initializeTab } = useMutation({
    mutationFn: async () => {
      assertNonNullable(audience.language, 'Audience language not found');
      await dispatch(
        initializeTabThunk(
          {
            embeddingModelId: defaultModel.id,
            audienceId: audience.id,
            name: unnamed,
            isNewDocument: true,
            outputType: defaultOutputType,
            brief: '',
            keywords: '',
            keywords2: '',
            tonality: []
          },
          configureChatTabThunk
        )
      );
    }
  });

  return initializeTab;
}
