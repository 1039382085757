import Toast from 'components/toasts/Toast';
import { aiWriterImagesFromApi } from 'features/aiWriter/store/utils/mappers/aiWriterImagesMappers';
import { getEmbeddingModelDataSelector } from 'features/embeddingModels/store/selectors';
import { updateUnsplashImagesLimitations } from 'features/pricing/store/actions/limitations/actions';
import { getIsUnsplashImagesLimitReached } from 'features/pricing/store/selectors';
import UnsplashAPI from 'services/api/unsplash';
import { invalidateUnsplashImagesUsageQueries } from 'services/api/unsplash/invalidateUnsplashImagesUsageQueries';
import { handleApiCommonErrors } from 'services/api/utils/handleApiCommonErrors';
import { invalidateCustomerAllLimitationsQueries } from 'services/backofficeIntegration/http/endpoints/customer/httpGetAllLimitations';
import { AppThunk } from 'store/store';
import { getCurrentTab } from 'store/utils/tabUtils';

import { clearImagesInspirations, fetchImageInspirations } from '../actions';

type action = 'extend' | 'replace';

export const fetchImageInspirationsThunk =
  (keywords: string, action?: action, offset?: number): AppThunk<void> =>
  async (dispatch, getState) => {
    const state = getState();
    const currentTab = getCurrentTab(state.aiWriter);
    const isLimitReached = getIsUnsplashImagesLimitReached(state);
    const getModelById = getEmbeddingModelDataSelector(state);

    if (!currentTab) {
      return;
    }

    if (keywords === '') {
      return;
    }

    if (isLimitReached) {
      return;
    }

    const { id: tabId, images, embeddingModelId } = currentTab;

    if (action === 'replace') {
      // needed to force the loader to be shown
      dispatch(clearImagesInspirations());
    }

    dispatch(fetchImageInspirations.request());

    const model = getModelById(embeddingModelId);

    try {
      const response = await UnsplashAPI.get({
        query: keywords,
        lang: model.language,
        page: offset
      });

      invalidateUnsplashImagesUsageQueries();
      invalidateCustomerAllLimitationsQueries();

      if (response.status) {
        switch (action) {
          case 'extend':
            dispatch(
              fetchImageInspirations.success({
                images: [...images, ...aiWriterImagesFromApi(response.data)],
                tabId,
                imagesNotFound: false
              })
            );
            break;
          case 'replace':
            dispatch(
              fetchImageInspirations.success({
                images: aiWriterImagesFromApi(response.data),
                tabId,
                imagesNotFound: response.data.total === 0
              })
            );
            break;
        }

        dispatch(updateUnsplashImagesLimitations(response.data.limitations));
      } else {
        const errorId = handleApiCommonErrors(response.data.message);

        Toast.backendError(errorId);
      }
    } catch (e) {
      Toast.commonError();
      dispatch(fetchImageInspirations.failure({ tabId }));
    }
  };
