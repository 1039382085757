function sanitizeBaseUrl(urlString: string) {
  const url = new URL(urlString);
  const path = url.pathname;
  if (path.length > 0 && !path.endsWith('/')) {
    url.pathname += '/';
  }
  return url;
}

const leadingSlashRE = /^\//;

function sanitizeRelativePath(path: string) {
  return path.replace(leadingSlashRE, '');
}

/**
 * Any combination of leading/trailing slash should give the same result
 * https://fake.com/a/b/ + /c = https://fake.com/a/b/c
 * https://fake.com/a/b/ + c = https://fake.com/a/b/c
 * https://fake.com/a/b/ + /c = https://fake.com/a/b/c
 * https://fake.com/a/b/ + c = https://fake.com/a/b/c
 */
function combineUrl(baseUrl: string, relativePath: string) {
  const base = sanitizeBaseUrl(baseUrl);
  const fullPath = base.pathname + sanitizeRelativePath(relativePath);
  /**
   * URL behavior can be a bit unintuitive to ppl who are
   * used to tools like axios but it is consistent with
   * paths resolution described here
   * https://en.wikipedia.org/wiki/Uniform_Resource_Identifier#Resolution
   *
   * Some example that may be surprising
   * 1) leading / means ignore the path from base url
   * new URL("/c", "https://fake.com/a/b/") -> https://fake.com/c
   * 2) last segment of the path is substituted
   * new URL("c", "https://fake.com/a/b") -> https://fake.com/a/c
   */
  return new URL(fullPath, base).toString();
}

export function makeBackofficeUrl(relativePath: string) {
  return combineUrl(getBackofficeBaseUrl(), relativePath);
}

export function getBackofficeBaseUrl() {
  return process.env.REACT_APP_API;
}
