import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import { useModal } from 'components/modals';
import { SidebarItem } from 'features/pageSidebar/sidebarItems/SidebarItem';
import React from 'react';
import gtmIds from 'services/tracking/GTMIds';

export const SidebarItemHelpCenter = (): React.ReactElement => {
  const { showModal } = useModal();

  return (
    <SidebarItem
      gtmId={gtmIds.sidebar.help}
      onClick={() => showModal('HELP_AND_INFO', { size: 1100 })}
      icon={<HelpRoundedIcon />}
      name="layout.sidebar.actions.help"
      tooltip="layout.sidebar.actions.help"
    />
  );
};
