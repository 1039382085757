import { AxiosInstance } from 'axios';
import LocalStorageKey from 'config/localStorageKey';
import { apiTokenHeader } from 'services/backofficeIntegration/http/backofficeHeaders';

export function withApiToken(client: AxiosInstance) {
  client.interceptors.request.use(request => {
    const apiToken = localStorage.getItem(LocalStorageKey.APIToken);

    if (apiToken) {
      Object.assign(request.headers, apiTokenHeader(apiToken));
    }

    return request;
  });
}
