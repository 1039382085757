import { Typography } from '@mui/material';
import Toast from 'components/toasts/Toast';
import { getRoutePath } from 'config/routes';
import { getUserName } from 'features/customer/store/selectors';
import { useCustomerPreferences } from 'features/customerPreferences/useCustomerPreferences';
import { clearPreferredLocaleQuery } from 'features/customerPreferences/usePreferredLocaleQuery';
import { useUpdateCustomerPreferences } from 'features/customerPreferences/useUpdateCustomerPreferences';
import { getEmbeddingModelsByLanguageAndAudience } from 'features/embeddingModels/store/selectors';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { MobileOnboardingFooter } from 'features/onboardingJourney/MobileFooter';
import { NewUserSurveyQuestion } from 'features/onboardingJourney/newUserSurvey/NewUserSurveyQuestion';
import { useOnboardingContext } from 'features/onboardingJourney/newUserSurvey/OnboardingContextProvider';
import { useUpdateSurveyAnswers } from 'features/onboardingJourney/newUserSurvey/useUpdateSurveyAnswers';
import {
  Footer,
  NextButton,
  onboardingContentWidth,
  onboardingMobileThreshold,
  OnboardingRoot
} from 'features/onboardingJourney/OnboardingRoot';
import { OnboardingStepper } from 'features/onboardingJourney/OnboardingStepper';
import { OnboardingSurveyLanguageField } from 'features/onboardingJourney/OnboardingSurveyLanguageField';
import { useIsDesktop } from 'features/onboardingJourney/useIsDesktop';
import { useDisableSidebarOnMount } from 'features/pageSidebar/useSidebarStore';
import { Form, Formik } from 'formik';
import { useEffect } from 'react';
import Confetti from 'react-confetti';
import { useNavigate } from 'react-router';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';
import useWindowSize from 'utils/hooks/useWindowSize';
import { getBrowserLangAndCountry } from 'utils/langUtils';

type Language = {
  language?: string;
  country?: string;
};

export function OnboardingSurvey() {
  const navigate = useNavigate();
  const tr = useTr();
  const username = useAppSelector(getUserName);
  const { width, height } = useWindowSize();
  const models = useAppSelector(getEmbeddingModelsByLanguageAndAudience);

  useDisableSidebarOnMount();

  const { questions, newAnswers, existingAnswers, setNewAnswers, isLoading, refetchAnswers } =
    useOnboardingContext();

  const { mutateAsync: updateAnswers, isLoading: isUpdateAnswersLoading } =
    useUpdateSurveyAnswers(newAnswers);
  const { mutateAsync: updateCustomerPreferences } = useUpdateCustomerPreferences();
  const { data: customerPreferences } = useCustomerPreferences();

  const [browserLanguage, browserCountry] = getBrowserLangAndCountry();

  const browserLanguageModelId = models.find(
    model => model.language === browserLanguage && model.country === browserCountry
  )?.id;
  const defaultLanguageModelId = models.find(
    model => model.language === 'en' && model.country === 'us'
  )?.id;
  const initialLanguageModelId = browserLanguageModelId ?? defaultLanguageModelId;
  const initialLanguage = models.find(model => model.id === initialLanguageModelId)?.language;
  const initialCountry = models.find(model => model.id === initialLanguageModelId)?.country;

  const initialValues = {
    language: initialLanguageModelId,
    country: browserCountry
  };

  const hasFinishedLoading =
    !isLoading && !isUpdateAnswersLoading && Array.isArray(questions) && questions.length > 0;

  const isDesktop = useIsDesktop();

  // Show a welcome toast only on desktop
  useEffect(() => {
    if (isLoading || !isDesktop) {
      return;
    }

    Toast.success('aiWriter.onboarding_journey.survey_view.welcome_toast', {
      name: username
    });
  }, [username, isLoading, isDesktop]);

  const selectableQuestions = questions.filter(question => question.slug !== 'content_type');

  const hasAnsweredCurrentQuestions = !selectableQuestions.some(
    question => !newAnswers[question.id] && !existingAnswers[question.slug]
  );

  const handleNextClick = async () => {
    // if a user has not set preferred language himself we need to set it to the default
    if (!customerPreferences?.preferredLanguage || !customerPreferences?.preferredLanguageCountry) {
      await updateCustomerPreferences({
        preferredLanguage: initialLanguage,
        preferredLanguageCountry: initialCountry
      });
    }

    // Store answers, if any given
    if (Object.entries(newAnswers).length > 0) {
      await updateAnswers();
      // User survey may affect preferred locale so we have to clear the
      // query cache to enforce fresh data. We could probably optimize and
      // check if language was actually changed, but not sure if this is worth it.
      clearPreferredLocaleQuery();

      refetchAnswers();
    }

    navigate(getRoutePath('onboardingUsecase'));
  };

  const handleSubmit = (values: Language) => {
    const embeddingModel = models.find(model => model.id === values.language);
    const updatedValues = {
      language: embeddingModel?.language,
      country: embeddingModel?.country
    };
    updateCustomerPreferences({
      preferredLanguage: updatedValues.language,
      preferredLanguageCountry: updatedValues.country
    });
  };

  return (
    <OnboardingRoot
      title={tr('aiWriter.onboarding_journey.survey_view.header')}
      subtitle={tr('aiWriter.onboarding_journey.survey_view.subtitle')}
      stepper={<OnboardingStepper currentStep={0} />}
    >
      <QnAContainer>
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          <Form>
            <OnboardingSurveyLanguageField
              name="language"
              models={models}
              onSubmit={handleSubmit}
            />
          </Form>
        </Formik>
        {selectableQuestions.map(currentQuestion => {
          return (
            <QnAPair key={currentQuestion.id}>
              <Question variant="subtitle1">{currentQuestion.question_text}</Question>
              <Answer key={Math.random()}>
                <NewUserSurveyQuestion
                  question={currentQuestion}
                  onChange={option =>
                    setNewAnswers(prevState => ({
                      ...prevState,
                      [currentQuestion.id]: option.id
                    }))
                  }
                />
              </Answer>
            </QnAPair>
          );
        })}
      </QnAContainer>

      {isDesktop && (
        <Footer direction="row">
          <NextButton
            {...withGtmInteraction(gtmIds.onboardingJourney.survey.next)}
            color="primary"
            variant="contained"
            onClick={handleNextClick}
            disabled={!hasAnsweredCurrentQuestions}
            fullWidth={true}
          >
            <FormattedMessage id="survey.new_user.next" />
          </NextButton>
        </Footer>
      )}

      {hasFinishedLoading && (
        <Confetti
          width={width}
          height={height}
          numberOfPieces={1000}
          gravity={0.1}
          initialVelocityX={4}
          initialVelocityY={10}
          wind={0}
          recycle={false}
        />
      )}
      {!isDesktop && (
        <MobileOnboardingFooter
          buttons={[
            <NextButton
              {...withGtmInteraction(gtmIds.onboardingJourney.survey.next)}
              color="primary"
              variant="contained"
              onClick={handleNextClick}
              disabled={!hasAnsweredCurrentQuestions}
              fullWidth={true}
              key="nextButton"
            >
              <FormattedMessage id="survey.new_user.next" />
            </NextButton>
          ]}
          step={0}
        />
      )}
    </OnboardingRoot>
  );
}

const QnAContainer = styled.div`
  width: ${onboardingContentWidth};

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.four};

  @media (max-width: ${onboardingMobileThreshold}) {
    max-width: 100%;
    gap: ${({ theme }) => theme.spacings.three};
  }
`;

export const QnAPair = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.two};
`;

export const Question = styled(Typography)`
  justify-self: start;
`;

export const Answer = styled.div`
  justify-self: end;
`;
