import axios, { AxiosInstance } from 'axios';
import { forcedLogout } from 'features/customer/store/actions';
import store from 'store/store';

export function withAuthenticationRedirect(client: AxiosInstance) {
  client.interceptors.response.use(undefined, (error: unknown) => {
    if (axios.isAxiosError(error) && error.response?.status === 401) {
      store.dispatch(forcedLogout());
    }

    throw error;
  });
}
