import { ModularWorkflowCreationPageTopBar } from 'features/modular-workflow/builder/ModularWorkflowCreationPageTopBar';
import { ModularWorkflowCreationViewEdit } from 'features/modular-workflow/builder/ModularWorkflowCreationViewEdit';
import { useModulareWorkflowCreationStore } from 'features/modular-workflow/builder/useModulareWorkflowCreationStore';
import { useWorkflowBuilderData } from 'features/modular-workflow/builder/useWorkflowBuilderData';
import { ModularWorkflowStructureProvider } from 'features/modular-workflow/ModularWorkflowStructureProvider';
import { useEnableSidebarOnMount } from 'features/pageSidebar/useSidebarStore';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';

const ModulareWorkflowEditPage = () => {
  const { workflowId } = useParams<{ workflowId: string }>();
  const workflow = useModulareWorkflowCreationStore(state => state.workflow);
  const updateWorkflow = useModulareWorkflowCreationStore(state => state.updateWorkflow);
  const setCurrentView = useModulareWorkflowCreationStore(state => state.setCurrentView);

  const [showLoader, setShowLoader] = useState<boolean>(true);

  const workflowIdAsNumber = Number(workflowId);
  const { data: fetchedWorkflow } = useWorkflowBuilderData(workflowIdAsNumber);

  useEnableSidebarOnMount();

  // Request data for the editing workflow once on mount
  useEffect(() => {
    if (!fetchedWorkflow) {
      return;
    }

    updateWorkflow(fetchedWorkflow);
    setCurrentView('edit');

    setShowLoader(false);
  }, [fetchedWorkflow, setCurrentView, updateWorkflow, workflow.id]);

  return (
    <Root>
      <ModularWorkflowCreationPageTopBar />

      <ModularWorkflowStructureProvider forceShowLoader={showLoader}>
        <ModularWorkflowCreationViewEdit />
      </ModularWorkflowStructureProvider>
    </Root>
  );
};

export default ModulareWorkflowEditPage;

const Root = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xmedium};
`;
