import { getRouteUrl, RestrictedRoute } from 'config/routes';
import { getRestrictions } from 'features/customer/store/selectors';
import FeatureRestriction from 'features/features/FeatureRestriction';
import { ClickHandlerType, FeatureCard } from 'features/homePage/FeatureCard';
import { ReactElement } from 'react';
import gtmIds from 'services/tracking/GTMIds';
import { useAppSelector } from 'store/hooks';

const useIsRestricted = (slug: RestrictedRoute) => {
  const restrictions = useAppSelector(getRestrictions);

  return restrictions[slug] ?? false;
};

export const HomeFeatureCards = (): ReactElement => {
  return (
    <>
      <AiWriterCard />
      <AiImagesCard />
      <TestingCard />
      <ExploringCard />
    </>
  );
};

function AiWriterCard() {
  return (
    <FeatureCard
      title="product.aiWriter"
      description="product_desc.aiWriter"
      linkId={gtmIds.home.toolCard.aiWriter}
      clickHandler={{
        type: ClickHandlerType.InternalLink,
        url: getRouteUrl('aiWriter')
      }}
    />
  );
}

function AiImagesCard() {
  return (
    <FeatureCard
      title="product.aiImages"
      description="product_desc.aiImages"
      linkId={gtmIds.home.toolCard.aiImages}
      clickHandler={{
        type: ClickHandlerType.InternalLink,
        url: getRouteUrl('aiImages')
      }}
    />
  );
}

function TestingCard() {
  const restricted = useIsRestricted('aiTester');

  return (
    <FeatureCard
      title="product.create_and_compare"
      description="product_desc.create_and_compare"
      linkId={gtmIds.home.toolCard.testing}
      badge={restricted ? <FeatureRestriction restriction="pro" shortcut={true} /> : undefined}
      clickHandler={{
        type: ClickHandlerType.InternalLink,
        url: getRouteUrl(restricted ? 'productInfoTesting' : 'aiTester')
      }}
    />
  );
}

function ExploringCard() {
  const restricted = useIsRestricted('exploring');

  return (
    <FeatureCard
      title="product.explorer"
      description="product_desc.explorer"
      linkId={gtmIds.home.toolCard.exploring}
      badge={restricted ? <FeatureRestriction restriction="pro" shortcut={true} /> : undefined}
      clickHandler={{
        type: ClickHandlerType.InternalLink,
        url: getRouteUrl(restricted ? 'productInfoExploring' : 'exploring')
      }}
    />
  );
}
