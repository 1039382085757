import { Typography } from '@mui/material';
import MobileStepper from '@mui/material/MobileStepper';
import FlexContainer from 'components/FlexContainer';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { onboardingContentWidth } from 'features/onboardingJourney/OnboardingRoot';
import styled from 'styled-components';
import { mobileWindowWidth } from 'utils/hooks/useMobileVersion';
import useWindowSize from 'utils/hooks/useWindowSize';

const stepCount = 3;

type Props = {
  currentStep: number;
};

const MobileStepperComponent = ({ currentStep }: Props) => {
  return (
    <MobileStepperContainer>
      <MobileStepper
        steps={stepCount}
        activeStep={currentStep}
        position="static"
        backButton={null}
        nextButton={null}
      />
    </MobileStepperContainer>
  );
};

const DesktopStepperComponent = ({ currentStep }: Props) => {
  return (
    <DesktopStepperContainer>
      <Typography variant="body1" color="textSecondary">
        <FormattedMessage
          id="onboarding.desktop_stepper.label"
          values={{ stepCount, currentStep: currentStep + 1 }}
        />
      </Typography>
      <MobileStepper
        steps={stepCount}
        variant="progress"
        activeStep={currentStep}
        position="static"
        backButton={null}
        nextButton={null}
      />
    </DesktopStepperContainer>
  );
};

export const OnboardingStepper = ({ currentStep }: Props) => {
  const { width } = useWindowSize();

  const component =
    width && width < mobileWindowWidth ? (
      <MobileStepperComponent currentStep={currentStep} />
    ) : (
      <DesktopStepperComponent currentStep={currentStep} />
    );
  return component;
};

const DesktopStepperContainer = styled(FlexContainer)`
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacings.medium};
  width: ${onboardingContentWidth};

  .MuiMobileStepper-root {
    padding: ${({ theme }) =>
      `${theme.spacings.small} ${theme.spacings.small} ${theme.spacings.small} 0`};
  }

  .MuiMobileStepper-progress {
    width: 100%;
  }
`;

const MobileStepperContainer = styled(FlexContainer)`
  display: flex;
  justify-content: center;
`;
