import { Button, Typography } from '@mui/material';
import IconNeuroflashLogo from 'assets/neuroflashLogo.svg';
import { useModal } from 'components/modals';
import { CloseModal } from 'components/modals/types';
import LocalStorageKey from 'config/localStorageKey';
import useUpdateSubscriptionModal from 'features/updateSubscriptionModal/hook/useUpdateSubscriptionModal';
import { FormattedMessage } from 'react-intl';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled, { css } from 'styled-components';
import { useLocalStorage } from 'utils/hooks/useLocalStorage';

export const UpgradeImagesModal = (props: { imageURL: string } & CloseModal) => {
  const { imageURL, closeModal } = props;

  const [, setIsSkipped] = useLocalStorage(LocalStorageKey.ImagesUpgradeModalSeen, false);

  const showUpgradeSubscriptionModal = useUpdateSubscriptionModal();

  const handleSkipButton = () => {
    setIsSkipped(true);
    closeModal();
  };

  const handleConfirmButton = () => {
    closeModal();
    showUpgradeSubscriptionModal();
  };

  return (
    <Root>
      <ImagesContainer>
        <GeneratedImage src={imageURL} />
        <OverladedImg src={IconNeuroflashLogo} />
      </ImagesContainer>

      <Container>
        <Typography variant="h6" fontWeight="bold">
          <FormattedMessage
            id="ai_images.generator_page.upgrade_modal.header"
            values={{ br: <br /> }}
          />
        </Typography>

        <Typography variant="body1">
          <FormattedMessage id="ai_images.generator_page.upgrade_modal.body" />
        </Typography>

        <ButtonsContainer>
          <Button
            {...withGtmInteraction(gtmIds.aiImages.generatorPage.upgradePopup.upgradeButton)}
            variant="contained"
            onClick={handleConfirmButton}
          >
            <FormattedMessage id="ai_images.generator_page.upgrade_modal.confirm" />
          </Button>
          <Button
            {...withGtmInteraction(gtmIds.aiImages.generatorPage.upgradePopup.maybeLaterButton)}
            onClick={handleSkipButton}
          >
            <FormattedMessage id="ai_images.generator_page.upgrade_modal.skip" />
          </Button>
        </ButtonsContainer>
      </Container>
    </Root>
  );
};

export const useShowUpgradeImagesModal = () => {
  const { showModal } = useModal();

  return (imageURL: string) => showModal('AI_IMAGES_UPGRADE', { size: 600, imageURL });
};

const Root = styled.div`
  padding: ${({ theme }) => theme.spacings.four};

  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: ${({ theme }) => theme.spacings.three};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.three};

  text-align: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.one};

  width: 100%;
`;

const ImagesContainer = styled.div`
  position: relative;
`;

const OverlayStyle = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
`;

const GeneratedImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: ${({ theme }) => theme.borderRadius.one};
`;

const OverladedImg = styled.img`
  ${OverlayStyle};

  width: 100%;
  height: 100%;
`;
