import StarIcon from '@mui/icons-material/Star';
import { Chip, Typography } from '@mui/material';
import { ReactComponent as IconCheckCircle } from 'assets/icons/icon-check-circle.svg';
import FlexContainer from 'components/FlexContainer';
import { PricingPlan } from 'features/pricing/types';
import { range, uniqueId } from 'lodash';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

const permittedPricingTypes: Array<PricingPlan> = [
  PricingPlan.pro,
  PricingPlan.business,
  PricingPlan.enterprise
];

type Props = {
  planType: PricingPlan;
  header?: string;
  listType: 'feature' | 'request' | 'analysis';
  perksAmount: number;
};

type IconProps = {
  index: number;
  planType: PricingPlan;
};

const PerkSublistIcon = ({ index, planType }: IconProps) => {
  const icon =
    permittedPricingTypes.includes(planType) && index === 0 ? (
      <YellowStarIcon />
    ) : (
      <StyledIconCheckCircle width={24} height={24} />
    );
  return icon;
};

const PlanPerkSublist = ({ planType, header, listType, perksAmount }: Props) => {
  const tr = useTr();

  const getLinkForMessageByPerkIndex = (index: number) =>
    tr(`profile.plan.${planType}_${listType}_perk_${index}_link`);

  return (
    <div>
      {perksAmount > 0 && (
        <FlexContainer
          direction="col"
          justifyContent="space-between"
          alignItems="flex-start"
          gap="three"
        >
          {header && (
            <FormattedMessage
              id={header}
              values={{
                b: (text: string) => <strong>{text}</strong>
              }}
            />
          )}

          {range(perksAmount).map((_, index) => {
            return (
              <FlexContainer key={uniqueId()} gap="small" direction="row" alignItems="flex-start">
                <PerkSublistIcon index={index} planType={planType} />
                <FeatureText variant="body1">
                  <FormattedMessage
                    id={`profile.plan.${planType}_${listType}_perk_${index}`}
                    values={{
                      br: <br />,
                      b: (text: string) => <strong>{text}</strong>,
                      u: (text: string) => <u>{text}</u>,
                      i: (text: string) => <i>{text}</i>,
                      small: (text: string) => <Typography variant="caption">{text}</Typography>,
                      a: (text: string) => (
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={getLinkForMessageByPerkIndex(index)}
                        >
                          {text}
                        </a>
                      ),
                      badge: (text: string) => (
                        <StyledBadge size="small" color="secondary" label={text} />
                      )
                    }}
                  />
                </FeatureText>
              </FlexContainer>
            );
          })}
        </FlexContainer>
      )}
    </div>
  );
};

const StyledIconCheckCircle = styled(IconCheckCircle)`
  flex: 0 0 20px;
`;

const StyledBadge = styled(Chip)`
  background-color: #ba87ff; /* #color-tech-debt */
  color: ${({ theme }) => theme.colors.palette.white};
  border-radius: ${({ theme }) => theme.borderRadius.four};
  margin-left: ${({ theme }) => theme.spacings.one};
`;

const FeatureText = styled(Typography)`
  text-align: left;
`;

const YellowStarIcon = styled(StarIcon)`
  color: ${({ theme }) => theme.colors.accentYellowMain};
`;

export default PlanPerkSublist;
