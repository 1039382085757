import { useShowApplyInformationModal } from 'features/information/apply-information/ApplyInformationModal';
import { InformationField } from 'features/information/apply-information/InformationField';
import { BaseSetting } from 'features/modular-workflow/runner/settings/BaseSetting';
import { InputRendererContext } from 'features/modular-workflow/runner/settings/InputRenderer';
import { useContext, useState } from 'react';
import { InformationDto } from 'services/backofficeIntegration/http/endpoints/infomration/httpGetInformationList';

export const DataTypeInformationCollection = () => {
  const { input, onChange, inputErrorList } = useContext(InputRendererContext);

  const showApplyInformationModal = useShowApplyInformationModal();

  const [localSelectedValue, setLocalSelectedValue] = useState<InformationDto[]>([]);

  const hasError = inputErrorList.length > 0;

  const handleInformationApply = () => {
    showApplyInformationModal({
      preselectedInformation: localSelectedValue ?? [],
      onApply: informationList => {
        setLocalSelectedValue(informationList);
        onChange(
          input,
          informationList.map(information => information.id)
        );
      }
    });
  };

  const handleInformationRemove = (id: number) => {
    const newInformationList = localSelectedValue?.filter(information => information.id !== id);

    setLocalSelectedValue(newInformationList);
    onChange(
      input,
      newInformationList.map(information => information.id)
    );
  };

  return (
    <BaseSetting>
      {() => (
        <InformationField
          informationList={localSelectedValue ?? []}
          fontVariant="subtitle1"
          hasError={hasError}
          onApply={handleInformationApply}
          onRemove={handleInformationRemove}
        />
      )}
    </BaseSetting>
  );
};
