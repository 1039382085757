import { useMutation } from '@tanstack/react-query';
import { TElement } from '@udecode/plate-common';
import { ELEMENT_H2 } from '@udecode/plate-heading';
import { ELEMENT_PARAGRAPH } from '@udecode/plate-paragraph';
import { getRoutePath } from 'config/routes';
import {
  FormValues,
  SocialPlatform
} from 'features/aiWriter/socialPostBuilder/SocialPostBuilderWrapper';
import { changeGenerateTextConfig } from 'features/aiWriter/store/actions/config/actions';
import { updateText } from 'features/aiWriter/store/actions/editor/actions';
import { updateCurrentProjectInBackgroundThunk } from 'features/aiWriter/store/actions/project/thunks/updateCurrentProjectInBackground';
import { setIsUserOnboarded } from 'features/aiWriter/store/actions/tour/actions';
import { getActiveTab } from 'features/aiWriter/store/selectors';
import useGetModelAudiences from 'features/audiences/hooks/useGetModelAudiences';
import { setCurrentTab } from 'features/explorer/store/slice';
import { useCompleteTask } from 'features/onboardingJourney/checklist/useOnboardingChecklist';
import { generateTextSafely } from 'features/textGenerator/actions/generateTexts';
import { useFormikContext } from 'formik';
import { useNavigate } from 'react-router';
import { InformationDto } from 'services/backofficeIntegration/http/endpoints/infomration/httpGetInformationList';
import { TASK_TYPE } from 'services/backofficeIntegration/http/endpoints/onboardingChecklist/httpGetChecklist';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import useTr, { TranslationFunction } from 'utils/hooks/useTr';

export function useCreateDocumentStructure() {
  const { values } = useFormikContext<FormValues>();
  const dispatch = useAppDispatch();
  const translate = useTr();
  const { id: tabId } = useAppSelector(getActiveTab);
  const getModelAudiences = useGetModelAudiences();
  const navigate = useNavigate();

  const completeSocialWorkflowTask = useCompleteTask(TASK_TYPE.COMPLETE_SOCIAL_MEDIA_WORKFLOW);

  return useMutation({
    mutationFn: async () => {
      const embeddingModelId = values.language;

      const audiences = getModelAudiences(embeddingModelId);
      const audienceModelId = audiences[0]?.model_id ?? '';

      // Generate document structure
      const editorNodes = await generateDocumentNodes({
        ...values,
        systemTonality: values.systemTonality,
        userTonality: values.userTonality,
        translate,
        audienceModelId,
        personalityId: values.personalityId?.id,
        informationList: values.informationList
      });

      dispatch(
        updateText({
          tabId,
          value: [
            {
              type: ELEMENT_PARAGRAPH,
              children: [{ text: '' }]
            },
            ...editorNodes
          ]
        })
      );

      // TODO after generation timeout in separate fun
      dispatch(setIsUserOnboarded(true));
      await navigate(getRoutePath('aiWriter'));
      dispatch(setCurrentTab(tabId));
      dispatch(updateCurrentProjectInBackgroundThunk());
      // since currently information is not saved on doc lvl we need to manually attach it to the project
      dispatch(
        changeGenerateTextConfig({
          informationList: values.informationList
        })
      );
      completeSocialWorkflowTask();
    }
  });
}

type Params = {
  language: string;
  audienceModelId: string;
  socialPlatforms: SocialPlatform[];
  systemTonality: string[];
  userTonality: string[];
  outline: string;
  translate: TranslationFunction;
  personalityId?: number;
  informationList?: InformationDto[];
};

type GeneratorResult = {
  platform: SocialPlatform;
  text: string;
  translationToken: string;
};

const outputMap: Record<SocialPlatform, { prompt: string; translationToken: string }[]> = {
  twitter: [{ prompt: '3_workflow_social_post_twitter', translationToken: 'tweet' }],
  youtube: [{ prompt: '3_workflow_social_post_youtube', translationToken: 'headline' }],
  linkedin: [{ prompt: '3_workflow_social_post_linkedin', translationToken: 'post' }],
  facebook: [
    { prompt: '3_workflow_social_post_facebook_1', translationToken: 'private' },
    { prompt: '3_workflow_social_post_facebook_2', translationToken: 'business' }
  ],
  instagram: [{ prompt: '3_workflow_social_post_instagram', translationToken: 'caption' }]
};

const generateDocumentNodes = async ({
  outline,
  socialPlatforms,
  systemTonality,
  userTonality,
  translate,
  audienceModelId,
  personalityId,
  informationList
}: Params) => {
  // Creates based on form values all required promises for the selected social platforms
  // Fire & await for all promises
  // Build a Plate.js node structure with the promise results
  const promises = [];

  for (const platform of socialPlatforms) {
    // Get the output types for this platform from the outputMap
    const outputTypes = outputMap[platform];

    // Collect all promises in an array
    promises.push(
      ...outputTypes.map(({ prompt, translationToken }) => {
        return new Promise<GeneratorResult>(resolve => {
          generateTextSafely({
            outputType: prompt,
            text: outline,
            audienceModelId,
            systemTonality,
            userTonality,
            personalityId,
            informationList
          }).then(generatedText => {
            resolve({
              platform,
              text: generatedText,
              translationToken
            });
          });
        });
      })
    );
  }

  // Execute Promise.all on the promises array
  const results = await Promise.all(promises);

  // Group results based on social platform
  const resultsByPlatform: Record<SocialPlatform, GeneratorResult[]> = {
    linkedin: [],
    instagram: [],
    twitter: [],
    facebook: [],
    youtube: []
  };

  for (const result of results) {
    resultsByPlatform[result.platform].push(result);
  }

  const editorNodes = new Array<TElement>();

  Object.keys(resultsByPlatform).forEach(platform => {
    const platformResults = resultsByPlatform[platform as SocialPlatform];
    if (platformResults.length === 0) {
      return;
    }

    // Create headline for platform
    editorNodes.push({
      type: ELEMENT_H2,
      children: [{ text: translate(`social_post_builder.${platform}.headline`) }]
    });

    for (const result of platformResults) {
      // Create translation for the bold text prefix
      const captionText = translate(
        `social_post_builder.${platform}.prefix.${result.translationToken}`
      );

      editorNodes.push({
        type: ELEMENT_PARAGRAPH,
        children: [{ text: captionText, bold: true }, { text: result.text }]
      });
    }
  });

  return editorNodes;
};
