import { Paper, Typography } from '@mui/material';
import styled from 'styled-components';

type Props = {
  title: string;
  subtitle?: string;
  isSelected: boolean;
  onClick: () => void;
};

export const StepButton = ({ title, subtitle, isSelected, onClick }: Props) => (
  <StepTile $isSelected={isSelected} onClick={onClick} elevation={isSelected ? 4 : 0}>
    <StepText variant="body1" $isSelected={isSelected}>
      {title}
    </StepText>
    {isSelected && (
      <StepText variant="caption" $isSelected={isSelected}>
        {subtitle}
      </StepText>
    )}
  </StepTile>
);

const StepTile = styled(Paper)<{ $isSelected: boolean }>`
  padding: ${({ theme }) => theme.spacings.three};
  border: ${({ $isSelected, theme }) =>
    $isSelected ? 'none' : `1px solid ${theme.colors.divider}`};
  display: flex;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.borderRadius.one};
  cursor: pointer;
  background-color: ${({ $isSelected, theme }) =>
    $isSelected ? theme.colors.secondary : 'transparent'};
`;

const StepText = styled(Typography)<{ $isSelected: boolean }>`
  color: ${({ $isSelected, theme }) =>
    $isSelected ? theme.colors.white : theme.colors.blackHighEmphasis};
`;
