import { Typography } from '@mui/material';
import { ModelAutocomplete } from 'features/embeddingModels/ModelAutocomplete';
import { EmbeddingModel } from 'features/embeddingModels/store/types';
import { useField, useFormikContext } from 'formik';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

export type Language = {
  language?: string;
  country?: string;
};

export const ProfileInformationLanguageField = (props: {
  name: string;
  models: EmbeddingModel[];
  onSubmit: (values: Language) => void;
}) => {
  const { name, models, onSubmit } = props;
  const [field] = useField(name);
  const { setFieldValue } = useFormikContext<Language>();
  const handleLanguage = (model: string) => {
    setFieldValue('language', model);
    onSubmit({ language: model });
  };

  return (
    <Root>
      <Typography variant="subtitle2">
        <FormattedMessage id="profile.settings.language_selection.headline" />
      </Typography>
      <ModelBox>
        <ModelAutocomplete
          models={models}
          {...field}
          onSelect={handleLanguage}
          size="small"
          isSaveAsDefaultShown={false}
        />
      </ModelBox>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.one};
`;

const ModelBox = styled.div`
  width: 30%;
`;
