import { AiWriterProjectTagsDropdownMenu } from 'features/aiWriter/AiWriterProjectOverview/tags/AiWriterProjectTagsDropdownMenu';
import { loadTabThunk } from 'features/aiWriter/store/actions/tabs/thunks/loadTabThunk';
import { getActiveTab } from 'features/aiWriter/store/selectors';
import { useGetProjectById } from 'features/aiWriter/useGetProjectById';
import { ReactElement } from 'react';
import { GAEvents } from 'services/tracking/GAEvents';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useMenu } from 'utils/hooks/useMenu';

type EventHandler = (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;

type Props = {
  onClose?: () => void;
  children: (onOpenTagsDropdown: EventHandler) => ReactElement;
};

export function TagsButton(props: Props) {
  const {
    anchorEl: tagsAnchorEl,
    isOpen: isTagsMenuOpen,
    onTriggerClick: onTagsTriggerClick,
    onMenuClose: onTagsMenuClose
  } = useMenu();

  const tab = useAppSelector(getActiveTab);
  const aiWriterProjectTags = useAppSelector(state =>
    tab.id ? state.aiWriter.tabs[tab.id]?.tags : []
  );
  const aiWriterProjects = useAppSelector(state => state.aiWriter.projects);
  const currentProject = aiWriterProjects.find(project => project.id === tab.id);

  const { fetchProject } = useGetProjectById();
  const dispatch = useAppDispatch();
  const onUpdateDocumentTags = () => {
    if (!tab) {
      return;
    }

    fetchProject(
      { id: tab.id },
      {
        onSuccess: project => {
          dispatch(loadTabThunk(project));
        }
      }
    );
  };

  const handleOpenTagsDropdown = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    onTagsTriggerClick(event);
    GAEvents.tagsIconClickedInDocumentEditor();
  };

  const handleCloseMenu = () => {
    onTagsMenuClose();
    props.onClose?.();
  };

  return (
    <>
      {props.children(handleOpenTagsDropdown)}

      <AiWriterProjectTagsDropdownMenu
        anchorEl={tagsAnchorEl}
        isOpen={isTagsMenuOpen}
        handleCloseMenu={handleCloseMenu}
        tags={aiWriterProjectTags}
        project={currentProject}
        onCreateTag={onUpdateDocumentTags}
      />
    </>
  );
}
