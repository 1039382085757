import { ChatStep } from 'features/aiWriter/AiWriterSidebar/steps/chat/ChatStep';
import { FlashScoreStep } from 'features/aiWriter/AiWriterSidebar/steps/FlashScoreStep';
import { ImagesStep } from 'features/aiWriter/AiWriterSidebar/steps/ImagesStep';
import { PlagiarismCheckStep } from 'features/aiWriter/AiWriterSidebar/steps/PlagiarismCheckStep';
import { SeoAnalysisStep } from 'features/aiWriter/AiWriterSidebar/steps/SeoAnalysisStep';
import { TemplatesStep } from 'features/aiWriter/AiWriterSidebar/steps/TemplatesStep';
import { TextInspirationsStep } from 'features/aiWriter/AiWriterSidebar/steps/TextInspirationsStep';
import { getInspirationsCurrentStep } from 'features/aiWriter/store/selectors';
import { AiWriterSidebarStep } from 'features/aiWriter/store/types';
import { useIsFlashScoreEnabled } from 'features/flashScore/useIsFlashScoreEnabled';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';

export const AiWriterSidebarViews = () => {
  const inspirationsCurrentStep = useAppSelector(getInspirationsCurrentStep);
  const isFlashScoreEnabled = useIsFlashScoreEnabled();

  return (
    <Root>
      {(() => {
        switch (inspirationsCurrentStep) {
          case AiWriterSidebarStep.aiWriter:
            return <TextInspirationsStep />;
          case AiWriterSidebarStep.images:
            return <ImagesStep />;
          case AiWriterSidebarStep.seoAnalysis:
            return <SeoAnalysisStep />;
          case AiWriterSidebarStep.templates:
            return <TemplatesStep />;
          case AiWriterSidebarStep.plagiarism:
            return <PlagiarismCheckStep />;
          case AiWriterSidebarStep.chat:
            return <ChatStep />;
          case AiWriterSidebarStep.flashScore:
            if (!isFlashScoreEnabled) {
              return null;
            }

            return <FlashScoreStep />;
        }
      })()}
    </Root>
  );
};

const Root = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
`;
