import { useMutation } from '@tanstack/react-query';
import Toast from 'components/toasts/Toast';
import { invalidateGetTeamMembersQuery } from 'features/profilePage/useGetTeamMembers';
import { getErrorCodeFromAxiosError } from 'features/teamInvitation/getErrorCodeFromAxiosError';
import { handleApiCommonErrors } from 'services/api/utils/handleApiCommonErrors';
import {
  httpRemoveTeamMember,
  RemoveUserParams
} from 'services/backofficeIntegration/http/endpoints/teamMembers/httpRemoveTeamMember';

export const useRemoveUser = () =>
  useMutation({
    mutationFn: (params: RemoveUserParams) => httpRemoveTeamMember.callEndpoint(params),
    onSuccess: () => {
      invalidateGetTeamMembersQuery();
      Toast.success('profile.users.users_list.remove_user.success');
    },
    onError: err => {
      const errorCode = getErrorCodeFromAxiosError(err);
      if (errorCode) {
        Toast.backendError(handleApiCommonErrors(errorCode));
      } else {
        Toast.apiError();
      }
    }
  });
