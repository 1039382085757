import { Table, TableHead, TableRow, Typography } from '@mui/material';
import {
  BorderedCell,
  FirstCell,
  StyledCell,
  StyledTable
} from 'components/profile/components/pricing-plan-card/FeatureComparisonSection';
import { PricingPlan } from 'features/pricing/types';
import { capitalize } from 'lodash';

export const PricingPlanTable = () => {
  return (
    <StyledTable>
      <Table>
        <TableHead>
          <TableRow>
            <FirstCell></FirstCell>
            <BorderedCell>
              <Typography variant="h6">{capitalize(PricingPlan.standard)}</Typography>
            </BorderedCell>
            <StyledCell>
              <Typography variant="h6">{capitalize(PricingPlan.pro)}</Typography>
            </StyledCell>
            <StyledCell>
              <Typography variant="h6">{capitalize(PricingPlan.business)}</Typography>
            </StyledCell>
          </TableRow>
        </TableHead>
      </Table>
    </StyledTable>
  );
};
