import { AiWriterProjectOverview } from 'features/aiWriter/AiWriterProjectOverview/AiWriterProjectOverview';
import { useFlashScoreSidebarState } from 'features/aiWriter/AiWriterSidebar/steps/flashScore/useFlashScoreSidebarState';
import { EditorProvider } from 'features/aiWriter/AiWriterTextEditor/EditorProvider/EditorProvider';
import { AiWriterWorkspace } from 'features/aiWriter/AiWriterWorkspace/AiWriterWorkspace';
import {
  getActiveTab,
  getIsTabSelected,
  getTabSharePermission
} from 'features/aiWriter/store/selectors';
import { useNavigateToOnboarding } from 'features/onboardingJourney/useNavigateToOnboarding';
import { useEnableSidebarOnMount } from 'features/pageSidebar/useSidebarStore';
import { useEffect } from 'react';
import { useAppSelector } from 'store/hooks';

export const aiWriterPlateId = 'aiWriter';

export const AiWriter = () => {
  const isTabSelected = useAppSelector(getIsTabSelected);
  const tab = useAppSelector(getActiveTab);
  const tabSharePermission = useAppSelector(getTabSharePermission);
  const userId = useAppSelector(state => state.customer.id);
  const resetFlashScoreSidebarState = useFlashScoreSidebarState(state => state.reset);

  const isTeamShared = tabSharePermission === 'team';
  const isUserOwner = tab?.authorId === userId;
  const isDocumentReadonly = isTeamShared && !isUserOwner;

  useEnableSidebarOnMount();
  // TODO: look into this once again
  // useClearCurrentTabOnPageLeave();

  useNavigateToOnboarding();

  // Reset FlashScore on mount if no tab is selected
  useEffect(() => {
    if (!isTabSelected) {
      resetFlashScoreSidebarState();
    }
  }, [isTabSelected, resetFlashScoreSidebarState]);

  if (isTabSelected) {
    return (
      <EditorProvider readonly={isDocumentReadonly}>
        <AiWriterWorkspace readonly={isDocumentReadonly} />
      </EditorProvider>
    );
  }

  return <AiWriterProjectOverview />;
};
