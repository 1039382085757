import { Button, TextField, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { CloseModal } from 'components/modals/types';
import { personalityTips, TipLoader } from 'components/TipLoader';
import Toast from 'components/toasts/Toast';
import { getPreferredAudience } from 'features/aiWriter/utils/getPreferredAudience';
import { getUserAudiences } from 'features/audiences/store/selectors';
import { EmbeddingModel } from 'features/embeddingModels/store/types';
import FormattedMessage from 'features/i18n/FormattedMessage';
import {
  ContentToAnalyze,
  CREATION_METHODS,
  CreationMethod,
  personalityCreationModalHeight,
  ViewMode
} from 'features/personality/creation/PersonalityCreationModal';
import {
  ModalBodyWithColumnDirectionAndSpaceBetween,
  ModalWithDividedHeaderLayout
} from 'features/theme-2024/ModalWithDividedHeaderLayout';
import { isNumber } from 'lodash';
import { ChangeEvent, useEffect, useState } from 'react';
import { handleGenerateTextErrorsWithHyperlink } from 'services/api/wordEmbedding/errors';
import { httpCreatePersonalityFromInformation } from 'services/backofficeIntegration/http/endpoints/personalities/httpCreatePersonalityFromInformation';
import {
  CreatePersonalityFromUrlProps,
  httpCreatePersonalityFromUrl
} from 'services/backofficeIntegration/http/endpoints/personalities/httpCreatePersonalityFromUrl';
import {
  GeneratePersonalityProps,
  httpGeneratePersonality
} from 'services/backofficeIntegration/http/endpoints/textGeneration/httpGeneratePersonality';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { getWordCount } from 'utils/getWordCount';
import useTr from 'utils/hooks/useTr';

const PERSONALITY_DEFINITION_MAX_WORDS_LIMIT = 600;

type Props = {
  contentToAnalyze: ContentToAnalyze;
  shouldAnalyze: boolean;
  analyzedPersonality: string | undefined;
  languageModel: EmbeddingModel;
  setShouldAnalyze: (value: boolean) => void;
  setAnalyzedPersonality: (personality: string) => void;
  setView: (view: ViewMode) => void;
  creationMethod: CreationMethod | undefined;
} & CloseModal;

export function AnalysisView(props: Props) {
  const { shouldAnalyze, contentToAnalyze, creationMethod, languageModel, setShouldAnalyze } =
    props;
  const [text, setText] = useState(props.analyzedPersonality ?? '');

  const tr = useTr();

  const { language, country } = languageModel;
  const audiences = useAppSelector(getUserAudiences);
  const audience = getPreferredAudience({ audiences, locale: { language, country } });

  const {
    mutate: createPersonalityFromInformation,
    isLoading: isPersonalityFromInformationCreationLoading
  } = useMutation({
    mutationFn: (informationId: number) =>
      httpCreatePersonalityFromInformation.callEndpoint({ id: informationId }),
    onSuccess: data => {
      if (data.is_successful) {
        setText(data.personality_text);
      } else {
        const code = data.error_message;
        code
          ? Toast.backendError(...handleGenerateTextErrorsWithHyperlink(code))
          : Toast.apiError();
      }
    }
  });

  const { mutate: generateCopyPastePersonality, isLoading: isCopyPasteCreationLoading } =
    useMutation({
      mutationFn: (params: GeneratePersonalityProps) =>
        httpGeneratePersonality.callEndpoint(params),
      onSuccess: data => {
        if (data.data.status) {
          setText(data.data.data.outputs[0].text);
        } else {
          const code = data.data.message;
          Toast.backendError(...handleGenerateTextErrorsWithHyperlink(code));
        }
      }
    });

  const { mutate: generateUrlPersonality, isLoading: isUrlCreationLoading } = useMutation({
    mutationFn: (params: CreatePersonalityFromUrlProps) =>
      httpCreatePersonalityFromUrl.callEndpoint(params),
    onSuccess: data => {
      if (data.status) {
        setText(data.data.personality_text);
        return;
      }

      if (data.message === 'ERROR_BRAND_VOICE_SCRAPE') {
        Toast.backendError(
          'personality.creation_modal.analysisView.share_url.ERROR_BRAND_VOICE_SCRAPE'
        );
        handleBackButtonClick();

        return;
      }

      Toast.apiError();
      handleBackButtonClick();
    }
  });

  useEffect(() => {
    if (!shouldAnalyze) {
      return;
    }

    if (creationMethod === CREATION_METHODS.FROM_INFORMATION && isNumber(contentToAnalyze)) {
      createPersonalityFromInformation(contentToAnalyze);
      setShouldAnalyze(false);
      return;
    }

    if (creationMethod === CREATION_METHODS.COPY_PASTE) {
      if (!audience || !audience.model_id) {
        throw new Error('Audience not found');
      }

      generateCopyPastePersonality({
        audience_model_id: audience.model_id,
        text: contentToAnalyze as string,
        n_times: 1,
        n_text_items: 1
      });
      setShouldAnalyze(false);

      return;
    }

    if (creationMethod === CREATION_METHODS.SHARE_URL) {
      generateUrlPersonality({ url: contentToAnalyze as string });
      setShouldAnalyze(false);
      return;
    }
  }, [
    createPersonalityFromInformation,
    contentToAnalyze,
    shouldAnalyze,
    creationMethod,
    setShouldAnalyze,
    generateCopyPastePersonality,
    text,
    audience,
    generateUrlPersonality
  ]);

  const handleChangeText = (e: ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);
  };

  const handleNextClick = () => {
    props.setAnalyzedPersonality(text);
    props.setView(ViewMode.create_or_edit);
  };

  const handleRedoClick = () => {
    setShouldAnalyze(true);
  };

  const handleBackButtonClick = () => {
    switch (props.creationMethod) {
      case CREATION_METHODS.COPY_PASTE:
        props.setView(ViewMode.create_automatic);
        break;
      case CREATION_METHODS.FROM_INFORMATION:
        props.setView(ViewMode.create_from_information);
        break;
      case CREATION_METHODS.SHARE_URL:
        props.setView(ViewMode.share_url);
        break;
      default:
        throw new Error('Invalid creation method');
    }
  };

  const textWordCount = getWordCount(text);
  const isInputError = textWordCount > PERSONALITY_DEFINITION_MAX_WORDS_LIMIT;

  const isLoading =
    isPersonalityFromInformationCreationLoading ||
    isCopyPasteCreationLoading ||
    isUrlCreationLoading;

  const areRightButtonsDisabled = isInputError || isLoading || !text;

  return (
    <ModalWithDividedHeaderLayout
      title={
        <FormattedMessage id="aiWriter.inspirations.chat.personalities.library_modal.create_personality" />
      }
      helpLink="aiWriter.inspirations.aiwriter.main.personality.help_link"
      closeModal={props.closeModal}
      height={personalityCreationModalHeight}
      slots={{
        bodyContainer: ModalBodyWithColumnDirectionAndSpaceBetween
      }}
      footer={
        <>
          <Button onClick={handleBackButtonClick} variant="text">
            <FormattedMessage id="common.back" />
          </Button>
          <RightActionsBox>
            <Button onClick={handleRedoClick} variant="outlined" disabled={areRightButtonsDisabled}>
              <FormattedMessage id="personality.creation_modal.analysisView.redo" />
            </Button>
            <Button
              {...withGtmInteraction(gtmIds.personalitiesLibrary.personalityCreated)}
              onClick={handleNextClick}
              variant="contained"
              disabled={areRightButtonsDisabled}
            >
              <FormattedMessage id="common.next" />
            </Button>
          </RightActionsBox>
        </>
      }
    >
      <Content>
        {isLoading ? (
          <LoadingBox>
            <TipLoader tips={personalityTips} />
          </LoadingBox>
        ) : (
          <>
            <TitleBox>
              <Typography variant="h6">
                <FormattedMessage id="personality.creation_modal.analysisView.title" />
              </Typography>
            </TitleBox>
            <TextField
              value={text}
              onChange={handleChangeText}
              multiline
              fullWidth
              placeholder={tr('personality_creation.input_placeholder')}
              inputProps={{
                style: {
                  height: '400px',
                  overflow: 'auto'
                }
              }}
            />
            <CounterBox $isError={isInputError}>
              <Typography variant="caption">
                {textWordCount}/{PERSONALITY_DEFINITION_MAX_WORDS_LIMIT}
              </Typography>
              {isInputError && (
                <Typography variant="caption">
                  <FormattedMessage id="aiWriter.inspirations.chat.personalities.library_modal.automatic_creation.input_column.error" />
                </Typography>
              )}
            </CounterBox>
          </>
        )}
      </Content>
    </ModalWithDividedHeaderLayout>
  );
}

const Content = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.medium};

  height: 100%;
`;

const TitleBox = styled.div`
  display: flex;
  justify-content: center;
`;

const RightActionsBox = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.small};
`;

const CounterBox = styled.div<{ $isError: boolean }>`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;

  color: ${({ theme, $isError }) => $isError && theme.colors.danger};
`;

const LoadingBox = styled.div`
  flex: 1;

  display: flex;
  justify-content: center;
  align-items: center;
`;
