import useRedirectWhenAuthenticated from 'components/auth/hooks/useRedirectWhenAuthenticated';
import { RecaptchaProvider } from 'components/RecaptchaProvider';
import { HandleSocialLogin } from 'features/social/HandleSocialLogin';
import { ReactElement } from 'react';

const SocialLoginCallbackPage = (): ReactElement => {
  useRedirectWhenAuthenticated();

  return (
    <RecaptchaProvider>
      <HandleSocialLogin />
    </RecaptchaProvider>
  );
};

export default SocialLoginCallbackPage;
