import { TElement } from '@udecode/plate';
import { SystemTonality } from 'features/aiWriter/tonality/SystemTonality';
import { PersonalityDto } from 'services/backofficeIntegration/http/dtos/PersonalityDto';
import { InformationDto } from 'services/backofficeIntegration/http/endpoints/infomration/httpGetInformationList';

export type FormValues = {
  language: string;
  title: string;
  goal: string;
  keywords: string[];
  systemTonality: SystemTonality[];
  userTonality: string[];
  personality: PersonalityDto | undefined | null;
  information: InformationDto[] | undefined;
  outline: string;
  generatedContent: TElement[];
  elements: Elements[];
  disableNextStep: boolean;
  /**
   * This is used to determine if the user started the blog builder from the onboarding
   */
  startedFromOnboarding: boolean;
};

export type Elements =
  | 'images'
  | 'youtube_video'
  | 'external_links'
  | 'what_people_asked'
  | 'seobility';

export enum BlogBuilderSteps {
  Language,
  Goal,
  Tonality,
  Elements,
  Title,
  Outline,
  GenerateText
}
