import { makeCurrentUserQueryKeyPrefix } from 'features/currentUser/currentUserQueries';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { queryEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';
import { PaginatedListSuccessDto } from 'services/backofficeIntegration/http/paginatedListEndpoint';
import { queryClient } from 'utils/ReactQueryClient';

type SuccessDto = PaginatedListSuccessDto<GeneratedFilesDto>;

export type GeneratedFilesDto = {
  id: number;
  author_id: number;
  author_name: string;
  name: string;
  embedding_model_id: string;
  updated_at: string;
  file_type: GeneratedFiles;
};

export const GeneratedFiles = {
  tester: 'PerformanceFlash',
  writer: 'ContentFlash'
} as const;

export type GeneratedFiles = typeof GeneratedFiles[keyof typeof GeneratedFiles];

export type PaginatedGeneratedFilesParams = {
  offset?: number;
  limit?: number;
  name?: string;
  sort?: 'updated_at';
};

export const httpGetGeneratedFiles = queryEndpoint({
  makeQueryKey: (params: PaginatedGeneratedFilesParams) => {
    return [
      ...makeCurrentUserQueryKeyPrefix(),
      'generated-files',
      ...Object.values(params)
    ] as const;
  },
  callEndpoint: (params: PaginatedGeneratedFilesParams) => {
    return backofficeClient
      .get<SuccessDto>('/customers/generated-files', { params })
      .then(response => response.data);
  }
});

export function invalidateProjectsQuery() {
  return queryClient.invalidateQueries({
    queryKey: [...makeCurrentUserQueryKeyPrefix(), 'projects', 'ai-writer']
  });
}
