import { createSelector } from '@reduxjs/toolkit';
import { getIsFirstLogin } from 'features/customer/store/selectors';
import { getEmbeddingModelsByLanguage } from 'features/embeddingModels/store/selectors';
import { isOutputTypeSupported } from 'features/textGenerator/store/selectors';
import { ApiTextAnalysisResult } from 'services/api/seoAnalysis/types';
import { RootState } from 'store/rootReducer';
import { getActiveTabFactory, getTabsHeadersFactory } from 'store/utils/selectorFactories';

import { getIsConfigReady } from '../../textGenerator/utils/getIsConfigReady';
import { AiWriterTab, AiWriterTabLoader } from './types';
import { defaultTab } from './utils/defaults/defaultTab';
import { fallbackModelCountry } from './utils/defaults/fallbackModelCountry';
import { fallbackModelLanguage } from './utils/defaults/fallbackModelLanguage';

export const getCurrentTabId = (state: RootState) => state.aiWriter.currentTab;
export const getAiWriterTabs = (state: RootState) => state.aiWriter.tabs;
export const getIsTabSelected = (state: RootState) => !!state.aiWriter.currentTab;
export const getInspirationsCurrentExtendedStep = (state: RootState) =>
  state.aiWriter.inspirationsCurrentStep;
export const getInspirationsCurrentStep = (state: RootState) =>
  state.aiWriter.inspirationsCurrentStep?.step;
export const getInspirationsCurrentSubStep = (state: RootState) =>
  state.aiWriter.inspirationsCurrentStep?.subStep;
export const getAreFavoritesLoading = (state: RootState) => state.aiWriter.areFavoritesLoading;
export const getFavorites = (state: RootState) => state.aiWriter.favorites;
export const getHistory = (state: RootState) => state.aiWriter.history;
export const getIsHistoryIsLoading = (state: RootState) => state.aiWriter.isHistoryLoading;
export const getIsAiWriterTourFinished = (state: RootState) =>
  state.aiWriter.isAiWriterTourFinished;
// #tech-debt https://app.clickup.com/t/862jjp0bb
export const getIsUserOnboarded = (state: RootState) => state.aiWriter.isUserOnboarded;
export const getAiWriterProjects = (state: RootState) => state.aiWriter.projects;
export const getAiWriterTabsHeaders = getTabsHeadersFactory(getAiWriterTabs);
export const getActiveTab = getActiveTabFactory<AiWriterTab>(
  getAiWriterTabs,
  getCurrentTabId,
  defaultTab
);
export const getIsNoOpenTabs = createSelector(getAiWriterTabsHeaders, tabs => !tabs.length);
export const getEditorValue = createSelector(getActiveTab, tab => tab?.text ?? []);

export const getIsNewDocument = createSelector(getActiveTab, tab => tab.isNewDocument);

export const getIsInspirationsPanelOpen = createSelector(
  getInspirationsCurrentStep,
  step => !!step
);

export const getTextInspirations = createSelector(getActiveTab, tab => tab?.textInspirations ?? []);

export const getIsGenerateConfigReady = createSelector(getActiveTab, tab => {
  if (!tab?.generateTextConfig) {
    return false;
  }
  return getIsConfigReady(tab.generateTextConfig);
});

export const getGenerateTextConfig = createSelector(getActiveTab, tab => {
  return tab?.generateTextConfig ?? {};
});

export const getTabSharePermission = createSelector(getActiveTab, tab => {
  return tab?.sharingPermission;
});

export const getGetIsAiWriterLoading = createSelector(
  getActiveTab,
  tab => (loader: AiWriterTabLoader) => tab?.isLoading[loader]
);

export const getCurrentModelLanguage = createSelector(
  getActiveTab,
  getEmbeddingModelsByLanguage,
  (tab, models) => {
    return models.find(m => m.id === tab.embeddingModelId)?.language ?? fallbackModelLanguage;
  }
);

export const getCurrentModelLanguageAndCountry = createSelector(
  getActiveTab,
  getEmbeddingModelsByLanguage,
  (tab, models) => {
    const model = models.find(m => m.id === tab.embeddingModelId);

    return {
      currentModelLanguage: model?.language ?? fallbackModelLanguage,
      currentModelCountry: model?.country ?? fallbackModelCountry
    };
  }
);

export const getIsAiWriterTourRunning = createSelector(
  getIsAiWriterTourFinished,
  getIsFirstLogin,
  (isTourFinished, isFirstLogin) => {
    return (isFirstLogin ?? false) && !isTourFinished;
  }
);

// #tech-debt https://app.clickup.com/t/862jjp0bb
export const getIsUserOnboardedOnFirstLogin = createSelector(
  getIsUserOnboarded,
  getIsFirstLogin,
  (isUserOnboarded, isFirstLogin) => {
    return !isFirstLogin || isUserOnboarded;
  }
);

export const getIsSaving = createSelector(getActiveTab, tab => tab.isSaving);

export const getSeoKeyword = createSelector(getActiveTab, tab => tab.seoConfig.keyword);

export const getIsBlogPostBuilderStarted = (state: RootState) =>
  state.aiWriter.isBlogPostBuilderStarted;

export const getImages = createSelector(getActiveTab, tab => tab?.images);
export const getIsImagesNotFound = createSelector(getActiveTab, tab => tab?.imagesNotFound);

export const getSeoAnalysis = createSelector(getActiveTab, tab => tab?.seoAnalysis);
export const getSeoConfig = createSelector(getActiveTab, tab => tab?.seoConfig);
export const getSeoHighlightedKeyword = createSelector(
  getActiveTab,
  tab => tab?.seoHighlightedKeyword
);

export const getSeoAnalysisResults = createSelector(getSeoAnalysis, seoAnalysis => {
  const results = seoAnalysis?.results;

  if (!results) {
    return undefined;
  }

  const resultsArr: ApiTextAnalysisResult[] = [];

  Object.values(results).forEach(value => resultsArr.push(value));

  return resultsArr;
});

export const getSeoAnalysisSuggestions = createSelector(
  getSeoAnalysis,
  seoAnalysis => seoAnalysis?.termSuggestions
);

export const getSeoAnalysisStatisticsSummary = createSelector(
  getSeoAnalysisSuggestions,
  seoAnalysisStatistics => ({
    less: seoAnalysisStatistics?.less.slice(0, 4) ?? [],
    more: seoAnalysisStatistics?.more.slice(0, 4) ?? [],
    perfect: seoAnalysisStatistics?.ok.slice(0, 2) ?? []
  })
);

export const getAverageWordCount = createSelector(getSeoAnalysisResults, searchResults => {
  if (!searchResults) {
    return 1;
  }

  const totalWordCount = searchResults?.reduce((prev, curr) => prev + curr.wordcount, 0);

  return Math.round(totalWordCount / searchResults.length);
});

export const getSeoWordsAmount = createSelector(
  getSeoAnalysis,
  seoAnalysis => seoAnalysis?.wordsAmount ?? 0
);

export const getGeneratingTextInEditor = createSelector(
  getActiveTab,
  tab => tab.generatingTextInEditor
);

export const getSeoSearchResultById = createSelector(
  getSeoAnalysis,
  seoAnalysis => (id: number) => {
    if (!seoAnalysis) {
      return undefined;
    }

    return seoAnalysis.results[id];
  }
);

export const isSeoRewriteSearchSupported = createSelector(
  isOutputTypeSupported,
  getCurrentModelLanguageAndCountry,
  (isSupported, { currentModelCountry, currentModelLanguage }) =>
    isSupported({
      outputType: 'rewrite_seo',
      country: currentModelCountry,
      language: currentModelLanguage
    })
);
