import { generateTexts } from 'features/textGenerator/actions/generateTexts';
import { generatedTextsFromGenerateTextOutputEntries } from 'features/textGenerator/utils/generateTextEntryMapper';
import { useGetNTimesByOutputTypeCached } from 'features/textGenerator/utils/useGetPromptsOthers';

import useAudienceConfig from '../../audiences/hooks/useAudienceConfig';
import { scoreSubjectLines } from '../utils/scoreSubjectLines';

export const useRewriteSubjectLine = ({ audienceId }: { audienceId: number }) => {
  const { model, audienceModelId } = useAudienceConfig({ audienceId });

  const outputType = 'variations';
  const nTimes = useGetNTimesByOutputTypeCached(outputType);

  return async ({ existingSubjectLine }: { existingSubjectLine: string }) => {
    const { outputs } = await generateTexts(
      { audienceModelId, outputType, nTimes },
      { text: existingSubjectLine }
    );

    const scoredSubjectLines = await scoreSubjectLines({
      inspirations: generatedTextsFromGenerateTextOutputEntries(outputs),
      embeddingModelId: model.id,
      audienceModelId
    });

    return scoredSubjectLines;
  };
};
