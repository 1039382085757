import { CircularProgress, InputAdornment, TextField } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { CollapsiblePanel } from 'components/CollapsiblePanel';
import FormattedMessage from 'features/i18n/FormattedMessage';
import React, { useCallback, useState } from 'react';
import CustomerAPI from 'services/api/customer';
import { handleApiCommonErrors } from 'services/api/utils/handleApiCommonErrors';
import { invalidateActiveCouponQuery } from 'services/backofficeIntegration/http/endpoints/customer/httpGetActiveCoupon';
import useTr from 'utils/hooks/useTr';

export const PricingCouponField = () => {
  const tr = useTr();
  const [couponCode, setCouponCode] = useState('');
  const [fieldError, setFieldError] = useState<string | undefined>(undefined);

  const applyPromoCodeMutation = useMutation({
    mutationFn: async (promoCode: string) => {
      const response = await CustomerAPI.applyPromoCode({ promo_code: promoCode });

      if (!response.status) {
        if (response.message) {
          setFieldError(handleApiCommonErrors(response.message));
          return;
        }
        setFieldError(tr('profile.subscription.subscription_coupon_api_error'));
        return;
      }

      invalidateActiveCouponQuery();
    },
    onError: () => setFieldError(tr('common.error'))
  });

  const onKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key !== 'Enter') {
        return;
      }
      e.preventDefault();

      applyPromoCodeMutation.mutate(couponCode);
    },
    [applyPromoCodeMutation, couponCode]
  );

  return (
    <CollapsiblePanel
      label={<FormattedMessage id="profile.subscription.subscription_coupon_apply" />}
    >
      <TextField
        disabled={applyPromoCodeMutation.isLoading}
        placeholder={tr('profile.subscription.subscription_coupon_field_placeholder')}
        fullWidth={false}
        variant="outlined"
        value={couponCode}
        onChange={e => setCouponCode(e.target.value)}
        error={applyPromoCodeMutation.isError}
        helperText={fieldError}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              {applyPromoCodeMutation.isLoading && <CircularProgress size={24} />}
            </InputAdornment>
          )
        }}
        onKeyPress={onKeyPress}
      />
    </CollapsiblePanel>
  );
};
