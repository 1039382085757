import { Button, LinearProgress, Typography } from '@mui/material';
import { useAiWriterExpandedSidebarStore } from 'features/aiWriter/AiWriterSidebar/sidebar/aiWriterExpandedSidebarStore';
import { AiWriterSidebarPanel } from 'features/aiWriter/AiWriterSidebar/sidebar/AiWriterSidebarPanel';
import { ExpandSidebarButton } from 'features/aiWriter/AiWriterSidebar/sidebar/ExpandSidebarButton';
import { ChatLayout } from 'features/aiWriter/AiWriterSidebar/steps/chat/ChatLayout';
import { ChatResetButton } from 'features/aiWriter/AiWriterSidebar/steps/chat/ChatResetButton';
import { MessageInput } from 'features/aiWriter/AiWriterSidebar/steps/chat/MessageInput';
import { MessagesArea } from 'features/aiWriter/AiWriterSidebar/steps/chat/MessagesArea';
import { useStoreSyncGuard } from 'features/aiWriter/AiWriterSidebar/steps/chat/useStoreSyncGuard';
import { setInspirationsCurrentStep } from 'features/aiWriter/store/actions/config/actions';
import { getActiveTab } from 'features/aiWriter/store/selectors';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import styled from 'styled-components';

const ErrorBox = styled.div`
  display: grid;
  min-height: 100%;
  place-content: center;
  place-items: center;
  gap: ${({ theme }) => theme.spacings.medium};
`;

export function ChatStep() {
  const dispatch = useAppDispatch();
  const { id: projectId } = useAppSelector(getActiveTab);
  const { isSyncing, isError, refetchFailed } = useStoreSyncGuard({ projectId });
  
  const isSidebarExpanded = useAiWriterExpandedSidebarStore((state) => state.isSidebarExpanded);
  const setIsSidebarExpanded = useAiWriterExpandedSidebarStore((state) => state.setIsSidebarExpanded);

  const handleClose = () => {
    dispatch(setInspirationsCurrentStep(undefined));
  };

  function renderRetry() {
    return (
      <ErrorBox>
        <Typography variant="body2" textAlign="center">
          <FormattedMessage id="aiWriter.inspirations.chat.error" />
        </Typography>
        <Button
          variant="contained"
          onClick={() => {
            refetchFailed();
          }}
        >
          <FormattedMessage id="common.refresh" />
        </Button>
      </ErrorBox>
    );
  }

  function selectContent() {
    if (isSyncing) {
      return <LinearProgress></LinearProgress>;
    }

    if (isError) {
      return renderRetry();
    }

    return (
      <ChatLayout
        messagesArea={<MessagesArea />}
        messageInput={<MessageInput />}
        resetButton={<ChatResetButton />}
      />
    );
  }

  return (
    <AiWriterSidebarPanel
      title="aiWriter.inspirations.chat.title"
      customButton={
        <ExpandSidebarButton isExpanded={isSidebarExpanded} onExpand={() => setIsSidebarExpanded(true)} onShrink={() => setIsSidebarExpanded(false)} />
      }
      onClose={handleClose}
    >
      {selectContent()}
    </AiWriterSidebarPanel>
  );
}
