import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

import { Button, Typography } from '@mui/material';
import { ReactComponent as IconNeuroflashLogoSVG } from 'assets/neuroflashLogo.svg';
import FlexContainer from 'components/FlexContainer';
import { DesktopActionImageSection } from 'features/onboardingJourney/desktop/DesktopActionImageSection';
import { StepButton } from 'features/onboardingJourney/desktop/StepButton';
import {
  Steps,
  useStartFromScratchStep
} from 'features/onboardingJourney/useActionImageSectionTile';
import { useDisableSidebarOnMount } from 'features/pageSidebar/useSidebarStore';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import { mobileWindowWidthCSS } from 'utils/hooks/useMobileVersion';
import useTr from 'utils/hooks/useTr';

type PreviousLocationStateType = Location & {
  state?: {
    skippedPersonalityCreation?: boolean;
  };
};

export const DesktopOnboardingSelection = () => {
  const { state: locationState } = useLocation() as unknown as PreviousLocationStateType;
  const initialStep = locationState?.skippedPersonalityCreation
    ? Steps.workflows
    : Steps.brandPersonality;
  const [selectedTile, setSelectedTile] = useState(initialStep);
  const tr = useTr();

  useDisableSidebarOnMount();

  const startFromScratchStep = useStartFromScratchStep();

  const handleTileClick = (tile: string) => {
    setSelectedTile(tile);
  };

  return (
    <Root>
      <NeuroflashLogo />
      <Heading>
        <Title>
          <FormattedMessage id="onboarding.selection.title" />
        </Title>
        <Subtitle>
          <FormattedMessage id="onboarding.selection.subtitle" />
        </Subtitle>
      </Heading>

      <TwoColumns>
        <LeftColumn>
          {Object.values(Steps).map(step => (
            <StepButton
              key={step}
              isSelected={selectedTile === step}
              onClick={() => handleTileClick(step)}
              title={tr(`onboarding.selection.${step}.title`)}
              subtitle={tr(`onboarding.selection.${step}.subtitle`)}
            />
          ))}

          <Button onClick={startFromScratchStep.action} size="large" variant="text">
            {startFromScratchStep.title}
          </Button>
        </LeftColumn>

        <RightColumn>
          <DesktopActionImageSection selectedTile={selectedTile} />
        </RightColumn>
      </TwoColumns>
    </Root>
  );
};

const Root = styled(FlexContainer).attrs({
  alignItems: 'center'
})`
  gap: ${({ theme }) => theme.spacings.four};
  margin: ${({ theme }) => theme.spacings.xmedium};
  padding-bottom: 40px;

  @media (max-width: ${mobileWindowWidthCSS}) {
    display: none;
    visibility: hidden;
  }
`;

const Heading = styled(FlexContainer)`
  width: 100%;
  gap: 0;
`;

const NeuroflashLogo = styled(IconNeuroflashLogoSVG)`
  position: absolute;
  top: ${({ theme }) => theme.spacings.medium};
  left: ${({ theme }) => theme.spacings.medium};
  margin-bottom: 5vh;
`;

const Title = styled(Typography).attrs({ variant: 'h4' })`
  text-align: center;
`;

const Subtitle = styled(Typography).attrs({ variant: 'subtitle1', color: 'textSecondary' })`
  text-align: center;
`;

const TwoColumns = styled(FlexContainer)`
  width: 100%;
  max-width: 1400px;
  max-height: 1000px;

  display: grid;
  grid-template-columns: 1fr 3fr;

  grid-gap: ${({ theme }) => theme.spacings.four};
`;

const LeftColumn = styled(FlexContainer)`
  gap: ${({ theme }) => theme.spacings.two};
`;

const RightColumn = styled(FlexContainer)`
  gap: ${({ theme }) => theme.spacings.three};
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadius.three};
  height: fit-content;
  overflow: hidden;
`;
