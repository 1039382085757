import { LoadingButton } from '@mui/lab';
import { Button, MenuItem, Select, TextField, Typography } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import FlexContainer from 'components/FlexContainer';
import { CloseModal } from 'components/modals/types';
import Toast from 'components/toasts/Toast';
import { getRoutePath } from 'config/routes';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { AppLanguage } from 'features/language/store/types';
import { ModularWorkflowCreationViewSetupTeamsSelect } from 'features/modular-workflow/builder/ModularWorkflowCreationViewSetupTeamsSelect';
import { ModularWorkflowTranslationSwitch } from 'features/modular-workflow/builder/ModularWorkflowTranslationSwitch';
import {
  ApiModularWorkflowSharingPermission,
  ApiModularWorkflowSharingPermissions
} from 'features/modular-workflow/builder/types-api';
import { useCreateWorkflowMutation } from 'features/modular-workflow/builder/useCreateWorkflowMutation';
import { useModulareWorkflowCreationStore } from 'features/modular-workflow/builder/useModulareWorkflowCreationStore';
import { useUpdateWorkflowMutation } from 'features/modular-workflow/builder/useUpdateWorkflowMutation';
import {
  ModalBodyWithColumnDirection,
  ModalWithDividedHeaderLayout
} from 'features/theme-2024/ModalWithDividedHeaderLayout';
import { ChangeEvent, SyntheticEvent, useMemo } from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';

type Props = CloseModal;

export const ModularWorkflowSettingsModal = ({ closeModal }: Props) => {
  const navigate = useNavigate();
  const workflow = useModulareWorkflowCreationStore(state => state.workflow);
  const updateWorkflow = useModulareWorkflowCreationStore(state => state.updateWorkflow);
  const updateWorkflowNameLocalized = useModulareWorkflowCreationStore(
    state => state.updateWorkflowNameLocalized
  );
  const updateWorkflowDescriptionLocalized = useModulareWorkflowCreationStore(
    state => state.updateWorkflowDescriptionLocalized
  );
  const currentLanguage = useModulareWorkflowCreationStore(state => state.currentLanguage);

  const { mutateAsync: updateWorkflowMutation, isLoading: isUpdatingWorkflow } =
    useUpdateWorkflowMutation();

  const { mutateAsync: createWorkflowMutation, isLoading: isCreatingWorkflow } =
    useCreateWorkflowMutation({
      onSuccess: data => {
        Toast.success('modular_workflow.builder.update_successful');

        if (data.id) {
          navigate(getRoutePath('modularWorkflowEdit', { workflowId: data.id }));
        }
      }
    });

  const canSwitchLanguage = useMemo(
    () =>
      workflow.name_localized[AppLanguage.English].length > 0 &&
      workflow.description_localized[AppLanguage.English].length > 0,
    [workflow.description_localized, workflow.name_localized]
  );

  const isUpdatingOrCreating = isUpdatingWorkflow || isCreatingWorkflow;

  const isSharingWithTeamsActive = useMemo(() => {
    return workflow.sharing_permission === ApiModularWorkflowSharingPermissions.other_workspaces;
  }, [workflow.sharing_permission]);

  const handleWorkflowNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    updateWorkflowNameLocalized(event.target.value);
  };

  const handleWorkflowDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    updateWorkflowDescriptionLocalized(event.target.value);
  };

  const handleWorkflowSharingChange = (event: SelectChangeEvent) => {
    const sharingValue = event.target.value as ApiModularWorkflowSharingPermission;
    const sharingTeamIds =
      sharingValue !== ApiModularWorkflowSharingPermissions.other_workspaces
        ? undefined
        : workflow.sharing_permission_team_ids;

    updateWorkflow({
      sharing_permission: sharingValue,
      sharing_permission_team_ids: sharingTeamIds
    });
  };

  const handleSaveClick = async () => {
    if (workflow.id) {
      await updateWorkflowMutation(workflow);
      closeModal();
      return;
    }

    // This redirects to edit page on success
    await createWorkflowMutation(workflow);
  };

  const handleSelectTeam = (event: SyntheticEvent, value: number) => {
    updateWorkflow({
      sharing_permission_team_ids: [value]
    });
  };

  return (
    <ModalWithDividedHeaderLayout
      title={<FormattedMessage id="modular_workflow.builder.settings_modal.title" />}
      closeModal={closeModal}
      slots={{
        bodyContainer: ModalBodyWithColumnDirection
      }}
    >
      <ModularWorkflowTranslationSwitch canSwitchLanguage={canSwitchLanguage} />

      <FieldContainer>
        <Typography variant="subtitle2">
          <FormattedMessage id="flashHub.overview.tab.workflows.create.name.label" />
        </Typography>

        <TextField
          id="name-input"
          placeholder="flashHub.overview.tab.workflows.create.name.placeholder"
          required={true}
          fullWidth={true}
          value={workflow.name_localized[currentLanguage]}
          onChange={handleWorkflowNameChange}
        />
      </FieldContainer>

      <FieldContainer>
        <Typography variant="subtitle2">
          <FormattedMessage id="flashHub.overview.tab.workflows.create.description.label" />
        </Typography>

        <TextField
          id="description-input"
          placeholder="flashHub.overview.tab.workflows.create.description.placeholder"
          multiline={true}
          minRows={2}
          required={true}
          fullWidth={true}
          value={workflow.description_localized[currentLanguage]}
          onChange={handleWorkflowDescriptionChange}
        />
      </FieldContainer>

      <FieldContainer>
        <Typography variant="subtitle2">
          <FormattedMessage id="flashHub.overview.tab.workflows.create.sharing.label" />
        </Typography>

        <Select
          value={workflow.sharing_permission}
          fullWidth={true}
          onChange={handleWorkflowSharingChange}
        >
          <MenuItem value={ApiModularWorkflowSharingPermissions.workspace}>
            <FormattedMessage id="flashHub.overview.tab.workflows.create.sharing.workspace" />
          </MenuItem>
          <MenuItem value={ApiModularWorkflowSharingPermissions.my}>
            <FormattedMessage id="flashHub.overview.tab.workflows.create.sharing.private" />
          </MenuItem>
          <MenuItem value={ApiModularWorkflowSharingPermissions.public}>
            <FormattedMessage id="flashHub.overview.tab.workflows.create.sharing.public" />
          </MenuItem>
          <MenuItem value={ApiModularWorkflowSharingPermissions.other_workspaces}>
            <FormattedMessage id="flashHub.overview.tab.workflows.create.sharing.other_workspaces" />
          </MenuItem>
        </Select>
      </FieldContainer>

      {isSharingWithTeamsActive && (
        <FieldContainer>
          <ModularWorkflowCreationViewSetupTeamsSelect
            enabled={isSharingWithTeamsActive}
            handleSelectTeam={handleSelectTeam}
          />
        </FieldContainer>
      )}

      <ModalFooter direction="row">
        <Button variant="text" onClick={closeModal} disabled={isUpdatingOrCreating}>
          <FormattedMessage id="common.back" />
        </Button>
        <LoadingButton
          variant="text"
          onClick={handleSaveClick}
          disabled={!canSwitchLanguage}
          loading={isUpdatingOrCreating}
        >
          <FormattedMessage id="common.save" />
        </LoadingButton>
      </ModalFooter>
    </ModalWithDividedHeaderLayout>
  );
};

const FieldContainer = styled(FlexContainer).attrs({ gap: 'small' })`
  width: 100%;
`;

const ModalFooter = styled(FlexContainer)`
  margin-left: auto;
`;
