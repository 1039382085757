import LocalStorageKey from 'config/localStorageKey';
import { ModelAutocomplete } from 'features/embeddingModels/ModelAutocomplete';
import { getEmbeddingModelsByLanguageAndAudience } from 'features/embeddingModels/store/selectors';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

import * as CommonStyled from './common.styles';

export const SelectLanguage = () => {
  const models = useAppSelector(getEmbeddingModelsByLanguageAndAudience);
  const tr = useTr();

  return (
    <>
      <CommonStyled.Title variant="subtitle1">
        {tr('blog_post_builder.steps.language.title')}
      </CommonStyled.Title>
      <LanguageSelector>
        <ModelAutocomplete
          name="language"
          models={models}
          onSelect={value => {
            localStorage.setItem(LocalStorageKey.AiWriterModel, value);
          }}
        />
      </LanguageSelector>
    </>
  );
};

const LanguageSelector = styled.div`
  max-width: 260px;
`;
