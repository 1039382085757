import { Button, Typography } from '@mui/material';
import AutocompleteTagsInput from 'components/AutocompleteInput/AutocompleteTagsInput';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import styled, { css } from 'styled-components';
import { WordTag } from 'types/WordTag';

import { useModal } from '../../components/modals';
import {
  getCurrentTabModelData,
  getIsTabInitialized,
  getSettingsModalShown
} from './store/selectors';
import { initializeTab, toggleTabProperty } from './store/slice';
import { ConceptFlashGoal, GoalValues, ModelGoalValues, ModelValues } from './store/types';

const InitialFormContainer = styled.div.attrs(() => ({ className: 'container' }))`
  position: absolute;
  left: 50%;
  top: 20%;
  transform: translate(-50%, -20%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const ActionsContainer = styled.div`
  width: 40rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

type Props = {
  seedWords: WordTag[];
  onChange: (newSeedWords: WordTag[]) => void;
  submitForm: () => void;
  onSetGoalAndModel: (values: ModelGoalValues) => void;
  onSetModel: (values: ModelValues) => void;
  onSetGoal: (values: GoalValues) => void;
};

const TabInitialization = ({
  seedWords,
  onChange,
  submitForm,
  onSetGoalAndModel,
  onSetModel,
  onSetGoal
}: Props) => {
  const dispatch = useAppDispatch();
  const { showModal } = useModal();
  const isInitialized = useAppSelector(getIsTabInitialized);
  const settingsModalShown = useAppSelector(getSettingsModalShown);
  const model = useAppSelector(getCurrentTabModelData);
  const {
    values: { wordAttributes, seoParams, manualDimensions, dimensions }
  } = useFormikContext<ConceptFlashGoal>();

  useEffect(() => {
    if (!settingsModalShown) {
      showModal({
        modalType: 'EXPLORING_MODEL_GOAL_MODAL',
        modalProps: {
          size: 900,
          onSubmit: onSetGoalAndModel,
          onHide: () => {
            dispatch(toggleTabProperty('settingsModalShown'));
          }
        }
      });
    }
  }, [showModal, settingsModalShown, onSetGoalAndModel, dispatch]);
  return (
    <InitialFormContainer>
      <div className="mb-4 text-center">
        {model ? (
          <Typography variant="h4">
            <FormattedMessage id="exploring.form.title" />
          </Typography>
        ) : (
          <FormattedMessage id="exploring.form.next_step" tagName="h3" />
        )}
      </div>
      {model && (
        <>
          <AutocompleteTagsInput
            inputProps={{ autoFocus: true }}
            css={css`
              max-width: 40rem;
            `}
            value={seedWords}
            onChange={newSeedWords => {
              if (!isInitialized) {
                dispatch(initializeTab(newSeedWords[0].word));
              }
              onChange(newSeedWords);
            }}
            eventHandler={submitForm}
            model={model?.id}
          />
          <ActionsContainer>
            <Button
              color="primary"
              onClick={() =>
                showModal({
                  modalType: 'EXPLORING_MODEL_MODAL',
                  modalProps: {
                    size: 900,
                    onSubmit: onSetModel
                  }
                })
              }
            >
              <FormattedMessage id="common.settings" />
            </Button>
            <Button
              color="primary"
              onClick={() =>
                showModal({
                  modalType: 'EXPLORING_GOAL_MODAL',
                  modalProps: {
                    size: 900,
                    onSubmit: onSetGoal,
                    initialValues: { wordAttributes, seoParams, manualDimensions, dimensions }
                  }
                })
              }
            >
              <FormattedMessage id="goal_score.goals" />
            </Button>
          </ActionsContainer>
        </>
      )}
    </InitialFormContainer>
  );
};

export default TabInitialization;
