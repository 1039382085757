import AddIcon from '@mui/icons-material/Add';
import { Button, CircularProgress, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import bannerImage from 'assets/brandHub/brand-voice-banner.png';
import { SearchField } from 'components/SearchField';
import { TeamMemberFilter } from 'features/aiWriter/AiWriterProjectOverview/TeamMemberFilter';
import { getCustomerRole } from 'features/customer/store/selectors';
import { CustomerRole } from 'features/customer/store/types';
import { EmbeddingModel } from 'features/embeddingModels/store/types';
import { useShowPersonalityCreationModal } from 'features/personality/creation/PersonalityCreationModal';
import { BannerLayout } from 'pages/brand-hub/BannerLayout';
import { BrandVoiceTable } from 'pages/brand-hub/brandVoiceTable';
import { FilterButton } from 'pages/brand-hub/FilterButton';
import { LanguageFilter } from 'pages/brand-hub/languageFilter';
import { BrandHubWarningBanner } from 'pages/brand-hub/warningBanner';
import { ChangeEvent, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { TeamMemberDto } from 'services/backofficeIntegration/http/dtos/TeamMemberDto';
import {
  GetPersonalitiesParams,
  httpGetPersonalities
} from 'services/backofficeIntegration/http/endpoints/personalities/httpGetPersonalities';
import { httpGetPersonalityLimitations } from 'services/backofficeIntegration/http/endpoints/personalities/httpGetPersonalityLimitations';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { useDebounce } from 'use-debounce/lib';

export function BrandHubBrandVoiceTab() {
  const [isFilteringActive, setIsFilteringActive] = useState(false);
  const [filteredLocale, setFilteredLocale] = useState<EmbeddingModel | undefined>();

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const offset = limit * page;

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [debouncedSearchQuery] = useDebounce(searchQuery, 300);

  const [filteredTeamMember, setFilteredTeamMember] = useState<TeamMemberDto | undefined>();

  const customerRole = useAppSelector(getCustomerRole);
  const isNotOwner = customerRole !== CustomerRole.OWNER;

  const customerHasTeam = useAppSelector(state => state.customer.hasTeam);
  const isTeamMember = customerHasTeam && isNotOwner;

  const queryParams: GetPersonalitiesParams = {
    offset,
    limit,
    search: debouncedSearchQuery || undefined,
    language: filteredLocale?.language,
    country: filteredLocale?.country,
    author_id: filteredTeamMember?.customer_id,
    sort: 'updated_at'
  };

  const { data, isLoading, isError } = useQuery({
    queryKey: httpGetPersonalities.makeQueryKey(queryParams),
    queryFn: () => httpGetPersonalities.callEndpoint(queryParams),
    keepPreviousData: true
  });

  const { data: limitationData, isSuccess: isLimitationQuerySuccess } = useQuery({
    queryKey: httpGetPersonalityLimitations.makeQueryKey(),
    queryFn: () => httpGetPersonalityLimitations.callEndpoint()
  });

  const showPersonalityCreationModal = useShowPersonalityCreationModal();

  function handleChangePage(_event: unknown | null, page: number) {
    setPage(page);
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  function handleSearchChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setSearchQuery(e.target.value);
    setPage(0);
  }

  const handleToggleFiltering = () => {
    setIsFilteringActive(!isFilteringActive);
  };

  const handleCreateBrandVoiceClick = () => {
    showPersonalityCreationModal({
      initialViewMode: 'choice',
      isLanguageEditable: true
    });
  };

  if (isError) {
    return null;
  }

  if (isLoading) {
    return (
      <CenteredBox>
        <CircularProgress />
      </CenteredBox>
    );
  }

  const brandVoices = data.data;
  const total = data.total;

  const hasOneAvailable = isLimitationQuerySuccess && limitationData.data.available === 1;
  const isOutOfCredits =
    isLimitationQuerySuccess &&
    !limitationData.data.isUnlimited &&
    limitationData.data.available === 0;

  return (
    <Root>
      <Banner />
      {(isOutOfCredits || hasOneAvailable) && (
        <WarningBox>
          {isOutOfCredits && (
            <BrandHubWarningBanner
              text={
                isTeamMember
                  ? 'flashHub.brandVoice.limitations.out_of_credits.no_owner_message'
                  : 'flashHub.brandVoice.limitations.out_of_credits'
              }
              variant="orange"
              gtmUpgradeId={gtmIds.brandHub.brandVoice.upgrade}
            />
          )}
          {hasOneAvailable && (
            <BrandHubWarningBanner
              text={
                isTeamMember
                  ? 'flashHub.brandVoice.limitations.one_left.no_owner_message'
                  : 'flashHub.brandVoice.limitations.one_left'
              }
              variant="yellow"
              gtmUpgradeId={gtmIds.brandHub.brandVoice.upgrade}
            />
          )}
        </WarningBox>
      )}
      <TableHeader>
        <Typography variant="subtitle1">
          <FormattedMessage id="flashHub.brandVoice.table_title" />
        </Typography>
        <ActionBox>
          <FilterButton
            isFilteringActive={isFilteringActive}
            onToggleFilteringClick={handleToggleFiltering}
          />
          <SearchField value={searchQuery} onChange={handleSearchChange} />
          <Button
            {...withGtmInteraction(gtmIds.brandHub.brandVoice.intentToCreateBrandVoice)}
            variant="contained"
            disabled={isOutOfCredits}
            startIcon={<AddIcon />}
            onClick={handleCreateBrandVoiceClick}
          >
            <FormattedMessage id="flashHub.brandVoice.create_button" />
          </Button>
        </ActionBox>
      </TableHeader>
      {isFilteringActive && (
        <FiltersBox>
          <LanguageFilter value={filteredLocale} setValue={setFilteredLocale} />
          <TeamMemberFilter value={filteredTeamMember} setValue={setFilteredTeamMember} />
        </FiltersBox>
      )}
      <BrandVoiceTable
        brandVoices={brandVoices}
        limit={limit}
        page={page}
        total={total}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Root>
  );
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.small};
`;

const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ActionBox = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.medium};
`;

const CenteredBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WarningBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.large};

  margin-bottom: ${({ theme }) => theme.spacings.large};
`;

const FiltersBox = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.small};
`;

function Banner() {
  return (
    <BannerLayout
      title={
        <FormattedMessage
          id="flashHub.brandVoice.banner.title"
          values={{
            highlight: (text: string) => <Highlighting>{text}</Highlighting>,
            br: <br />
          }}
        />
      }
      content={<FormattedMessage id="flashHub.brandVoice.banner.body" />}
      image={bannerImage}
      color="blue__500main"
    />
  );
}

const Highlighting = styled.span`
  color: ${({ theme }) => theme.colors.textPrimary};
`;
