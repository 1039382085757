import { GenerateTextConfig } from 'features/textGenerator/store/types';

import { AudiencesState } from '../store/types';
import getAudienceModelById from './getAudienceModelById';

const getAudienceModelFromConfig = (
  state: AudiencesState,
  config: GenerateTextConfig | undefined
) => {
  if (config) {
    return getAudienceModelById(state, config.audienceId);
  }

  return undefined;
};

export default getAudienceModelFromConfig;
