import HelpIcon from '@mui/icons-material/Help';
import { IconButton, Tooltip } from '@mui/material';
import { TutorialButton } from 'components/buttons/TutorialButton';
import { ColoredTypography } from 'components/ColoredTypography';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

export const Heading = (): ReactElement => {
  const translate = useTr();

  return (
    <>
      <TutorialButton videoId="ai_images.generator_page.tutorial_video.link" />
      <HeaderBox>
        <ColoredTypography variant="h4" fontWeight={400}>
          <FormattedMessage
            id="ai_images.generator_page.title"
            values={{
              b: (chunks: string) => <b>{chunks}</b>
            }}
          />
        </ColoredTypography>
        <SubtitleBox>
          <ColoredTypography color="textSecondary" variant="subtitle2">
            <FormattedMessage id="ai_images.generator_page.subtitle" />
          </ColoredTypography>
          <Tooltip title={translate('ai_images.generator_page.help_button.tooltip')}>
            <IconButton
              size="small"
              component="a"
              href={translate('ai_images.generator_page.help_button.link')}
              target="_blank"
              rel="noreferrer"
            >
              <ColoredIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </SubtitleBox>
      </HeaderBox>
    </>
  );
};

const HeaderBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.three};
`;

const SubtitleBox = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.one};
  align-items: center;
`;

const ColoredIcon = styled(HelpIcon)`
  color: ${({ theme }) => theme.colors.componentsIconInactive};
`;
