import { setInspirationsCurrentStep } from 'features/aiWriter/store/actions/config/actions';
import { AiWriterSidebarStep } from 'features/aiWriter/store/types';
import { useCallback } from 'react';
import { useAppDispatch } from 'store/hooks';

export default function useReturnToMainSeoStep() {
  const dispatch = useAppDispatch();

  return useCallback(() => {
    dispatch(
      setInspirationsCurrentStep({ step: AiWriterSidebarStep.seoAnalysis, subStep: 'main' })
    );
  }, [dispatch]);
}
