import { Link, Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import Toast from 'components/toasts/Toast';
import { Loader } from 'features/churnModal/loader';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { ErrorPage } from 'features/teamInvitation/ErrorPage';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { handleApiCommonErrors } from 'services/api/utils/handleApiCommonErrors';
import { TokenInfoDto } from 'services/backofficeIntegration/http/endpoints/teamMembers/httpGetTeamInvitationInfo';
import {
  InvitationErrorCode,
  invitationErrorCodes
} from 'services/backofficeIntegration/http/endpoints/teamMembers/httpTeamInvitationRegister';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

type Props = {
  children: ReactNode;
  inviteResponseErrorCode: InvitationErrorCode | null;
  isLoadingTeamInvitation: boolean;
  hasTeamInvitationError: boolean;
  teamInvitationErrorCode: string | null;
  teamInvitationInfo: TokenInfoDto | undefined;
};

export const BasePage = ({
  children,
  inviteResponseErrorCode,
  isLoadingTeamInvitation,
  teamInvitationErrorCode,
  hasTeamInvitationError,
  teamInvitationInfo
}: Props) => {
  const navigate = useNavigate();
  const translate = useTr();

  // This is mandatory for all messages
  if (isLoadingTeamInvitation) {
    return (
      <Root>
        <Loader />
      </Root>
    );
  }

  // Invite info request is done, but with errors
  if (hasTeamInvitationError) {
    if (teamInvitationErrorCode) {
      if (invitationErrorCodes.includes(teamInvitationErrorCode as InvitationErrorCode)) {
        return <ErrorPage errorCode={teamInvitationErrorCode as InvitationErrorCode} />;
      }

      Toast.backendError(handleApiCommonErrors(teamInvitationErrorCode));
      navigate('/login');
      return null;
    }

    Toast.commonError();
    navigate('/login');
    return null;
  }

  // This should never happen
  if (!teamInvitationInfo) {
    Toast.commonError();
    navigate('/login');
    return null;
  }

  if (inviteResponseErrorCode) {
    return (
      <ErrorPage
        errorCode={inviteResponseErrorCode}
        invitedByName={teamInvitationInfo.invited_by_name}
      />
    );
  }

  return (
    <Root>
      <Content>
        <Typography variant="h5">
          <FormattedMessage id="team.invite.headline" />
        </Typography>
        <Typography variant="body1">
          <FormattedMessage
            id="team.invite.message"
            values={{
              br: <br />,
              invited_by: teamInvitationInfo.invited_by_name,
              help: (value: string) => (
                <Link
                  href={translate('external_links.app_helpscout')}
                  rel="noreferrer"
                  target="_blank"
                >
                  {value}
                </Link>
              )
            }}
          />
        </Typography>

        {children}
      </Content>
    </Root>
  );
};

const Root = styled(FlexContainer).attrs({ alignItems: 'center', justifyContent: 'center' })`
  width: 100%;
`;

const Content = styled(FlexContainer)`
  margin-top: 80px;
  max-width: 368px;
  gap: ${({ theme }) => theme.spacings.medium};
`;
