import { MoreVert } from '@mui/icons-material';
import { Divider, Tooltip } from '@mui/material';
import { ELEMENT_OL, ELEMENT_UL } from '@udecode/plate-list';
import FlexContainer from 'components/FlexContainer';
import { MenuDropdown } from 'components/MenuDropdown/MenuDropdown';
import { AiImagesButton } from 'features/aiWriter/AiWriterTextEditor/AiImagesButton';
import { AlignTextDropdown } from 'features/aiWriter/AiWriterTextEditor/AlignTextDropdown';
import { EditorIconFormatButton } from 'features/aiWriter/AiWriterTextEditor/EditorIconFormatButton';
import { EditorListButton } from 'features/aiWriter/AiWriterTextEditor/EditorListButton';
import { IndentButton } from 'features/aiWriter/AiWriterTextEditor/IndentButton';
import { LineHeightButton } from 'features/aiWriter/AiWriterTextEditor/LineHeightButton';
import { OutdentButton } from 'features/aiWriter/AiWriterTextEditor/OutdentButton';
import { TableDropdown } from 'features/aiWriter/AiWriterTextEditor/TableDropdown';
import { TodoListButton } from 'features/aiWriter/AiWriterTextEditor/TodoListButton';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import useDropdown from 'utils/hooks/useDropdown';

export const EditorMoreButton = () => {
  const { anchorElement, isOpen, open, close } = useDropdown();

  return (
    <MenuDropdown
      trigger={
        <Tooltip arrow title={<FormattedMessage id="aiWriter.toolbar.more" />} placement="bottom">
          <EditorIconFormatButton onClick={open}>
            <MoreVert fontSize="small" />
          </EditorIconFormatButton>
        </Tooltip>
      }
      isOpen={isOpen}
      anchorElement={anchorElement}
      close={close}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      width="auto"
    >
      <IconsWrapper gap="small">
        <AlignTextDropdown onClose={close} />
        <LineHeightButton onClose={close} />
        <EditorListButton nodeType={ELEMENT_UL} onClose={close} />
        <EditorListButton nodeType={ELEMENT_OL} onClose={close} />
        <TodoListButton onClose={close} />
        <IndentButton onClose={close} />
        <OutdentButton onClose={close} />

        <Divider />

        <AiImagesButton onClose={close} />
        <TableDropdown onClose={close} />
      </IconsWrapper>
    </MenuDropdown>
  );
};

const IconsWrapper = styled(FlexContainer)`
  padding: ${({ theme }) => `0 ${theme.spacings.medium}`};
`;
