import { Table, TableBody, TableRow, Tooltip, Typography } from '@mui/material';
import {
  displayFeaturePresence,
  FirstCell,
  StyledCell,
  StyledTable,
  UnderlinedText
} from 'components/profile/components/pricing-plan-card/FeatureComparisonSection';
import { FormattedMessage } from 'react-intl';

const createContentGenerationCapTextData = (
  id: string | null,
  name: string,
  standard: string,
  pro: string,
  enterprise: string
) => {
  return { id, name, standard, pro, enterprise };
};

const contentGenerationCapTextData = [
  createContentGenerationCapTextData(
    null,
    'text_quality',
    'text_quality_standard',
    'text_quality_pro',
    'text_quality_enterprise'
  ),
  createContentGenerationCapTextData(
    'context_window',
    'context_window',
    'context_window_standard',
    'context_window_pro',
    'context_window_enterprise'
  ),
  createContentGenerationCapTextData(
    null,
    'information',
    'information_standard',
    'information_pro',
    'information_enterprise'
  ),
  createContentGenerationCapTextData(
    null,
    'brand_voice',
    'brand_voice_standard',
    'brand_voice_pro',
    'brand_voice_enterprise'
  )
];

const createContentGenerationCapData = (
  id: string | null,
  name: string,
  standard: boolean,
  pro: boolean,
  enterprise: boolean
) => {
  return { id, name, standard, pro, enterprise };
};

const contentGenerationCapData = [
  createContentGenerationCapData(null, 'document_editor', true, true, true),
  createContentGenerationCapData(null, 'pre-trained_text-types', true, true, true),
  createContentGenerationCapData(null, 'template_library', true, true, true),
  createContentGenerationCapData('flash_actions', 'flash_actions', true, true, true),
  createContentGenerationCapData('standard_workflows', 'standard_workflows', true, true, true),
  createContentGenerationCapData(null, 'image_dimension', false, true, true),
  createContentGenerationCapData('seo_workflow', 'seo_workflow', false, true, true)
];

export const ContentGenerationCapabilitiesTable = () => {
  return (
    <StyledTable>
      <Table>
        <TableBody>
          {contentGenerationCapTextData.map(row => (
            <TableRow key={row.name}>
              {row.id ? (
                <FirstCell component="th" scope="row">
                  <Tooltip
                    title={
                      <FormattedMessage
                        id={`features_comparison.content_generation_capabilities.${row.id}.tooltip`}
                      />
                    }
                    placement="top"
                  >
                    <UnderlinedText>
                      <FormattedMessage
                        id={`features_comparison.content_generation_capabilities.${row.name}`}
                      />
                    </UnderlinedText>
                  </Tooltip>
                </FirstCell>
              ) : (
                <FirstCell component="th" scope="row">
                  <FormattedMessage
                    id={`features_comparison.content_generation_capabilities.${row.name}`}
                  />
                </FirstCell>
              )}
              <StyledCell>
                <Typography variant="body1Bold">
                  <FormattedMessage
                    id={`features_comparison.content_generation_capabilities.${row.standard}`}
                  />
                </Typography>
              </StyledCell>
              <StyledCell>
                <Typography variant="body1Bold">
                  <FormattedMessage
                    id={`features_comparison.content_generation_capabilities.${row.pro}`}
                  />
                </Typography>
              </StyledCell>
              <StyledCell>
                <Typography variant="body1Bold">
                  <FormattedMessage
                    id={`features_comparison.content_generation_capabilities.${row.enterprise}`}
                  />
                </Typography>
              </StyledCell>
            </TableRow>
          ))}
          {contentGenerationCapData.map(row => (
            <TableRow key={row.name}>
              {row.id ? (
                <FirstCell component="th" scope="row">
                  <Tooltip
                    title={
                      <FormattedMessage
                        id={`features_comparison.content_generation_capabilities.${row.id}.tooltip`}
                      />
                    }
                    placement="top"
                  >
                    <UnderlinedText>
                      <FormattedMessage
                        id={`features_comparison.content_generation_capabilities.${row.name}`}
                      />
                    </UnderlinedText>
                  </Tooltip>
                </FirstCell>
              ) : (
                <FirstCell component="th" scope="row">
                  <FormattedMessage
                    id={`features_comparison.content_generation_capabilities.${row.name}`}
                  />
                </FirstCell>
              )}
              <StyledCell>{displayFeaturePresence(row.standard)}</StyledCell>
              <StyledCell>{displayFeaturePresence(row.pro)}</StyledCell>
              <StyledCell>{displayFeaturePresence(row.enterprise)}</StyledCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </StyledTable>
  );
};
