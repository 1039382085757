import { useMutation } from '@tanstack/react-query';
import LocalStorageKey from 'config/localStorageKey';
import { configureImageSearchTabThunk } from 'features/aiWriter/store/actions/tabs/thunks/configureImageSearchTabThunk';
import { initializeTabThunk } from 'features/aiWriter/store/actions/tabs/thunks/initializeTabThunk';
import { getPreferredAudience } from 'features/aiWriter/utils/getPreferredAudience';
import { unnamed } from 'features/aiWriter/utils/unnamed';
import { getUserAudiences } from 'features/audiences/store/selectors';
import { getEmbeddingModelsByLanguageAndAudience } from 'features/embeddingModels/store/selectors';
import { EmbeddingModel } from 'features/embeddingModels/store/types';
import getModelInitValue from 'features/embeddingModels/store/utils/getModelInitValue';
import { useGetDefaultOutputTypeByLanguageCached } from 'features/textGenerator/utils/useGetPromptsOthers';
import { httpGetDefaultPersonality } from 'services/backofficeIntegration/http/endpoints/personalities/httpGetDefaultPersonality';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const useCreateNewDocumentForImages = () => {
  const dispatch = useAppDispatch();

  const models = useAppSelector(getEmbeddingModelsByLanguageAndAudience);
  const audiences = useAppSelector(getUserAudiences);

  const defaultLanguage: EmbeddingModel = getModelInitValue(
    models,
    LocalStorageKey.AiWriterModel
  ) ??
    models[0] ?? {
      language: 'en',
      country: 'US',
      id: '',
      label: ''
    };
  const audience = getPreferredAudience({
    audiences,
    locale: { language: defaultLanguage.language, country: defaultLanguage.country }
  });

  const model = models.find(
    model =>
      model.language === defaultLanguage.language && model.country === defaultLanguage.country
  );

  const defaultOutputType = useGetDefaultOutputTypeByLanguageCached(model?.language || '');

  const { mutate: initializeTab } = useMutation({
    mutationFn: async () => {
      if (!model || !audience) return;

      const defaultPersonalityResult = await httpGetDefaultPersonality.callEndpoint({
        language: model.language,
        country: model.country
      });

      await dispatch(
        initializeTabThunk(
          {
            embeddingModelId: model.id,
            audienceId: audience.id,
            name: unnamed,
            isNewDocument: true,
            outputType: defaultOutputType,
            brief: '',
            keywords: '',
            keywords2: '',
            tonality: [],
            personalityId: defaultPersonalityResult?.id
          },
          configureImageSearchTabThunk
        )
      );
    }
  });

  return initializeTab;
};
