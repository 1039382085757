/* eslint-disable @typescript-eslint/no-explicit-any */
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { ToastBasicMessage } from 'components/toasts/ToastBasicMessage';
import { ToastErrorWithBugReportLink } from 'components/toasts/ToastErrorWithBugReportLink';
import { ToastErrorWithRequestId } from 'components/toasts/ToastErrorWithRequestId';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { IntlMessageKeys } from 'features/i18n/IntlMessageKeys';
import React, { ReactNode } from 'react';
import { toast, ToastOptions } from 'react-toastify';

const autoClose = 10000;

const Toast = {
  defaultAutoClose: autoClose,

  dispatch: (
    type: 'success' | 'warning' | 'error',
    message: string,
    msgValues?: Record<string, string>,
    config?: ToastOptions
  ) => Toast[type](message, msgValues, config),
  custom: (content: React.ReactNode, config?: ToastOptions) => toast.info(content, config),
  close: (id: string | number) => toast.dismiss(id),
  success: (message: IntlMessageKeys, msgValues?: Record<string, any>, config?: ToastOptions) =>
    toast.success(<ToastBasicMessage message={message} msgValues={msgValues} />, {
      ...config,
      autoClose: autoClose,
      icon: () => <CheckCircleIcon />
    }),
  info: (message: IntlMessageKeys, msgValues?: Record<string, any>, config?: ToastOptions) =>
    toast.info(<ToastBasicMessage message={message} msgValues={msgValues} />, config),
  warning: (message: IntlMessageKeys, msgValues?: Record<string, any>, config?: ToastOptions) =>
    toast.warn(<ToastBasicMessage message={message} msgValues={msgValues} />, config),
  error: (message: IntlMessageKeys, msgValues?: Record<string, any>, config?: ToastOptions) =>
    toast.error(
      <ToastErrorWithRequestId>
        <ToastBasicMessage message={message} msgValues={msgValues} />
      </ToastErrorWithRequestId>,
      {
        ...config,
        autoClose: autoClose,
        icon: () => <ErrorIcon />
      }
    ),
  commonError: () =>
    toast.error(
      <ToastErrorWithRequestId>
        <FormattedMessage id="common.error" />
      </ToastErrorWithRequestId>,
      {
        toastId: 'commonError',
        type: 'error',
        autoClose: autoClose,
        icon: () => <ErrorIcon />
      }
    ),
  backendError: (messageId: string, values?: Record<string, any>) =>
    messageId === 'app.api_error'
      ? Toast.apiError()
      : toast.error(
          <ToastErrorWithRequestId>
            <FormattedMessage id={messageId} values={{ br: <br />, ...values }} />
          </ToastErrorWithRequestId>,
          {
            toastId: messageId,
            autoClose: autoClose,
            icon: () => <ErrorIcon />
          }
        ),
  apiError: () =>
    toast.error(
      <ToastErrorWithRequestId>
        <ToastErrorWithBugReportLink />
      </ToastErrorWithRequestId>,
      {
        toastId: 'apiError',
        autoClose: autoClose,
        icon: () => <ErrorIcon />
      }
    ),
  backendErrorHTMLMessage: (message: string) =>
    toast.error(
      <ToastErrorWithRequestId>
        <ToastErrorWithBugReportLink
          message="app.server_error"
          // eslint-disable-next-line react/no-danger
          values={{ error: <span dangerouslySetInnerHTML={{ __html: message }} /> }}
        />
      </ToastErrorWithRequestId>,
      { toastId: 'serverError', autoClose: autoClose, icon: () => <ErrorIcon /> }
    ),
  customError: (component: ReactNode, config?: ToastOptions) =>
    toast.error(<ToastErrorWithRequestId>{component}</ToastErrorWithRequestId>, {
      ...config,
      autoClose: autoClose,
      icon: () => <ErrorIcon />
    })
};

export default Toast;
