import { getRoutePath } from 'config/routes';
import { unnamed } from 'features/aiWriter/utils/unnamed';
import { DocumentPageTopBar } from 'features/document-top-bar/DocumentPageTopBar';
import { useModulareWorkflowRunnerStore } from 'features/modular-workflow/runner/useModulareWorkflowRunnerStore';
import { noop } from 'lodash';
import { useNavigate } from 'react-router-dom';

export const ModularWorkflowRunnerPageTopBar = () => {
  const navigate = useNavigate();
  const workflow = useModulareWorkflowRunnerStore(state => state.workflow);
  const isSaving = useModulareWorkflowRunnerStore(state => state.isSaving);

  if (!workflow) {
    return null;
  }

  return (
    <DocumentPageTopBar
      activeDocumentId={workflow.id.toString(10)}
      documents={[{ id: workflow.id.toString(10), name: workflow.name }]}
      isSaving={isSaving}
      placeholderName={unnamed}
      withSaveIndicator={false}
      withNameChange={false}
      withLanguageChange={false}
      withUsageIndicator={false}
      onBack={() => navigate(getRoutePath('aiWriter'))}
      onRename={noop}
      renderName={name => (workflow.name.length > 0 ? name : unnamed)}
    />
  );
};
