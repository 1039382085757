import { CircularProgress } from '@mui/material';
import saveDoneIcon from 'assets/icon-save-done.svg';
import FlexContainer from 'components/FlexContainer';
import { ComponentPropsWithoutRef, forwardRef } from 'react';
import styled from 'styled-components';

const size = 18;

type Props = Omit<ComponentPropsWithoutRef<typeof FlexContainer>, 'children'> & {
  isSaving: boolean;
};

export const ProjectSaver = forwardRef<HTMLDivElement, Props>(({ isSaving, ...props }, ref) => (
  <Root {...props} alignItems="center" ref={ref}>
    {isSaving ? (
      <CircularProgress size={size} />
    ) : (
      <Icon>
        <img src={saveDoneIcon} alt="save" />
      </Icon>
    )}
  </Root>
));

const Root = styled(FlexContainer)`
  width: ${size}px;
  height: ${size}px;

  flex-shrink: 0;
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${size}px;
  height: ${size}px;

  img {
    width: 100%;
    height: 100%;
  }
`;
