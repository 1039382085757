import { useMutation } from '@tanstack/react-query';
import { useShowChatResetModal } from 'features/aiWriter/AiWriterSidebar/steps/chat/ChatResetModal';
import {
  getCurrentGptModel,
  syncStore
} from 'features/aiWriter/AiWriterSidebar/steps/chat/chatStore';
import { invalidateActiveConversationQuery } from 'features/aiWriter/AiWriterSidebar/steps/chat/useActiveConversationQuery';
import { getActiveTab } from 'features/aiWriter/store/selectors';
import { PersonalityDto } from 'services/backofficeIntegration/http/dtos/PersonalityDto';
import {
  CreateConversationProps,
  GptModel,
  httpCreateConversation
} from 'services/backofficeIntegration/http/endpoints/aiWriter/httpCreateConversation';
import { useAppSelector } from 'store/hooks';
import { createUuidV4 } from 'utils/createUuidV4';
import useTr from 'utils/hooks/useTr';

type CallbackProps = {
  personality: PersonalityDto | null;
  gptModel: GptModel | null;

  callback?: () => void;
};

export function useResetChat() {
  const conversationId = createUuidV4();

  const { id: projectId } = useAppSelector(getActiveTab);

  const { mutate } = useMutation({
    mutationFn: (props: CreateConversationProps) => httpCreateConversation.callEndpoint(props),
    onSuccess: (data, { personalityId, gptModel }) => {
      if (!data) {
        return;
      }

      const { conversation_id: conversationId } = data;
      const currentGptModel = getCurrentGptModel();
      invalidateActiveConversationQuery({ projectId });

      syncStore({
        projectId,
        conversationId,
        personalityId,
        gptModel: gptModel ?? currentGptModel ?? null,
        suggestions: []
      });
    }
  });

  return ({ personality, gptModel, callback }: CallbackProps) => {
    const personalityId = personality?.id ?? null;

    mutate({
      projectId,
      conversationId,
      personalityId,
      gptModel
    });

    callback?.();
  };
}

export const useWarnAboutChatReset = () => {
  const showChatResetWarningModal = useShowChatResetModal();
  const tr = useTr();

  return (props: { onAccept: () => void }) =>
    showChatResetWarningModal({
      headingMessage: tr('aiWriter.inspirations.settings.reset_chat_warning.heading'),
      explanationMessage: tr('aiWriter.inspirations.settings.reset_chat_warning.explanation'),
      onAccept: props.onAccept
    });
};
