import Toast from 'components/toasts/Toast';
import { getErrorCodeFromAxiosError } from 'features/teamInvitation/getErrorCodeFromAxiosError';
import { handleApiCommonErrors } from 'services/api/utils/handleApiCommonErrors';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { mutationEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

export const httpUpdateCommandTemplateUsage = mutationEndpoint({
  async callEndpoint({ templateId }: { templateId: number }) {
    await backofficeClient
      .post(`/text-generation/command-templates/${templateId}/customer-command-templates`)
      .then(response => {
        return response;
      })
      .catch(error => {
        const errorCode = getErrorCodeFromAxiosError(error);
        if (errorCode) {
          Toast.backendError(handleApiCommonErrors(errorCode));
        }
      });
  }
});
