import signOutIcon from 'assets/icon-sign-out.svg';
import { MenuDropdownItem } from 'components/MenuDropdown/MenuDropdownItem';
import { logout } from 'features/customer/store/actions';
import { FormattedMessage } from 'react-intl';
import gtmIds from 'services/tracking/GTMIds';
import { useAppDispatch } from 'store/hooks';

type Props = {
  onClose: () => void;
};

const SignOutItem = ({ onClose }: Props) => {
  const dispatch = useAppDispatch();

  return (
    <MenuDropdownItem
      gtmId={gtmIds.sidebar.accountMenuItem}
      icon={<img src={signOutIcon} alt="sign_out" />}
      onClose={onClose}
      onClick={() => dispatch(logout.request())}
    >
      <FormattedMessage id="layout.sidebar.actions.profile.sign_out" />
    </MenuDropdownItem>
  );
};

export default SignOutItem;
