import { useMutation } from '@tanstack/react-query';
import Toast from 'components/toasts/Toast';
import { invalidateGetTeamMembersQuery } from 'features/profilePage/useGetTeamMembers';
import { getErrorCodeFromAxiosError } from 'features/teamInvitation/getErrorCodeFromAxiosError';
import { handleApiCommonErrors } from 'services/api/utils/handleApiCommonErrors';
import {
  CancelInvitationParams,
  httpCancelInvitation
} from 'services/backofficeIntegration/http/endpoints/teamMembers/httpCancelInvitation';

export const useCancelInvitation = () =>
  useMutation({
    mutationFn: (params: CancelInvitationParams) => httpCancelInvitation.callEndpoint(params),
    onSuccess: () => {
      invalidateGetTeamMembersQuery();
      Toast.success('profile.users.invite_user.cancel.success');
    },
    onError: err => {
      const errorCode = getErrorCodeFromAxiosError(err);
      if (errorCode) {
        Toast.backendError(handleApiCommonErrors(errorCode));
      } else {
        Toast.error('profile.users.invite_user.cancel.error.generic');
      }
    }
  });
