import { uuid4 } from '@sentry/utils';
import { TagChipColors } from 'features/aiWriter/AiWriterProjectOverview/tags/AiWriterProjectTag';
import { useState } from 'react';
import styled from 'styled-components';

type Props = {
  onClick: (c: string) => void;
  color?: string;
};

export const TagsColorPicker = (props: Props) => {
  const { onClick, color } = props;
  const [selected, setSelected] = useState(color);

  const handleSelection = (color: string) => {
    setSelected(color);
    onClick(color);
  };

  return (
    <ColorPalette>
      {Object.keys(TagChipColors).map(color => {
        return (
          <ColorDot
            $color={color}
            key={uuid4()}
            $selected={selected === color}
            onClick={() => handleSelection(color)}
          />
        );
      })}
    </ColorPalette>
  );
};

const ColorPalette = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 8px;
  grid-row-gap: 6px;
  width: 118px;
`;

const ColorDot = styled.div<{ $color: string; $selected: boolean }>`
  height: 24px;
  width: 24px;
  border-radius: 50%;
  display: inline-block;
  background-color: ${({ $color }) => TagChipColors[$color] || TagChipColors.primary};
  outline: ${({ $selected }) => ($selected ? '1px solid rgba(0, 0, 0, 0.56)' : 'none')};
  outline-offset: 2px;
`;
