import { ApiErrors } from '../types';
import { helpCenter } from '../utils/errorHyperlinks';
import mkApiErrorMessage from '../utils/mkApiErrorMessage';
import mkHandleApiErrorMessage from '../utils/mkHandleApiErrorMessage';
import mkHandleApiErrorMessageWithHyperlink from '../utils/mkHandleErrorsWithHyperlink';
import { GetAllErrorCode, ModelsEndpoint as ModelsEmbeddingEndpoint } from './types';

const mkEmbeddingModelsApiErrors = (
  endpoint: ModelsEmbeddingEndpoint,
  errorCode: GetAllErrorCode
) => mkApiErrorMessage('models', endpoint, errorCode);

const embeddingModelsHyperlinks: Record<string, string> = {
  ERROR_MODEL_DOES_NOT_EXIST: helpCenter
};

const getAllEmbeddingModelsErrors: ApiErrors<GetAllErrorCode> = {
  ERROR_MODEL_DOES_NOT_EXIST: mkEmbeddingModelsApiErrors('getAll', 'ERROR_MODEL_DOES_NOT_EXIST')
};

export const handleGetAllEmbeddingModelsErrors = mkHandleApiErrorMessage(
  getAllEmbeddingModelsErrors
);

export const handleGetAllEmbeddingModelsErrorsWithHyperlink = mkHandleApiErrorMessageWithHyperlink(
  getAllEmbeddingModelsErrors,
  embeddingModelsHyperlinks
);
