import CheckIcon from '@mui/icons-material/Check';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { Button, Tooltip } from '@mui/material';
import banner from 'assets/profile/workspace-banner.png';
import FlexContainer from 'components/FlexContainer';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { IntlMessageKeys } from 'features/i18n/IntlMessageKeys';
import useSubscriptionState from 'features/pricing/hook/useSubscriptionState';
import { PricingPlan } from 'features/pricing/types';
import { useInviteUserModal } from 'features/profilePage/useInviteUserModal';
import { useIsTeamOwnerOrAdmin } from 'features/team/useIsTeamOwnerOrAdmin';
import useUpdateSubscriptionModal from 'features/updateSubscriptionModal/hook/useUpdateSubscriptionModal';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled from 'styled-components';

type PricingPlanTranslationKeys = {
  title: IntlMessageKeys;
  body?: IntlMessageKeys;
  perks: IntlMessageKeys[];
  button: IntlMessageKeys;
};

type TextVariants = 'free_standard' | 'pro_enterprise';

const PRICING_PLAN_TEXTS: Record<TextVariants, PricingPlanTranslationKeys> = {
  free_standard: {
    title: 'profile.users.info_card.free_standard.title',
    perks: [
      'profile.users.info_card.free_standard.perk_1',
      'profile.users.info_card.free_standard.perk_2',
      'profile.users.info_card.free_standard.perk_3',
      'profile.users.info_card.free_standard.perk_4',
      'profile.users.info_card.free_standard.perk_5'
    ],
    button: 'profile.users.info_card.free_standard.button'
  },
  pro_enterprise: {
    title: 'profile.users.info_card.pro_enterprise.title',
    body: 'profile.users.info_card.pro_enterprise.body',
    perks: [
      'profile.users.info_card.pro_enterprise.perk_1',
      'profile.users.info_card.pro_enterprise.perk_2',
      'profile.users.info_card.pro_enterprise.perk_3',
      'profile.users.info_card.pro_enterprise.perk_4'
    ],
    button: 'profile.users.info_card.pro_enterprise.button'
  }
};

export function UsersInfoCard() {
  const showUpgradeSubscriptionModal = useUpdateSubscriptionModal();
  const isTeamOwnerOrAdmin = useIsTeamOwnerOrAdmin();
  const { currentPricingPlan } = useSubscriptionState();

  const isFreeOrStandardPlan =
    currentPricingPlan.type === PricingPlan.free ||
    currentPricingPlan.type === PricingPlan.standard;

  const planGroup: TextVariants = isFreeOrStandardPlan ? 'free_standard' : 'pro_enterprise';

  const showInviteUserModal = useInviteUserModal();

  const handleUpgradeClick = () => {
    showUpgradeSubscriptionModal();
  };

  const handleInviteClick = () => {
    showInviteUserModal();
  };

  return (
    <Root>
      <DescriptionColumn>
        <div>
          <Title style={{ marginBottom: 3 }}>
            <FormattedMessage
              id={PRICING_PLAN_TEXTS[planGroup].title}
              values={{
                highlight: (chunks: string) => <TextHighlight>{chunks}</TextHighlight>
              }}
            />
          </Title>
          {PRICING_PLAN_TEXTS[planGroup].body && (
            <Body>
              <FormattedMessage id={PRICING_PLAN_TEXTS[planGroup].body ?? ''} />
            </Body>
          )}
        </div>

        <PerksBox>
          {PRICING_PLAN_TEXTS[planGroup].perks.map(perk => (
            <FlexContainer key={perk} gap="small" direction="row">
              <StyledCheckIcon fontSize="small" />
              <Body>
                <FormattedMessage id={perk} />
              </Body>
            </FlexContainer>
          ))}
        </PerksBox>

        <div>
          {isFreeOrStandardPlan ? (
            <ActionButton
              {...withGtmInteraction(gtmIds.profile.users.infoCard.upgrade)}
              variant="contained"
              onClick={handleUpgradeClick}
            >
              <FormattedMessage id={PRICING_PLAN_TEXTS[planGroup].button} />
            </ActionButton>
          ) : (
            <Tooltip
              title={<FormattedMessage id="common.teams_invite_disabled" />}
              disableHoverListener={isTeamOwnerOrAdmin}
            >
              <span>
                <ActionButton
                  {...withGtmInteraction(gtmIds.profile.users.infoCard.inviteTeammates)}
                  variant="contained"
                  startIcon={<PersonAddAlt1Icon />}
                  onClick={handleInviteClick}
                  disabled={!isTeamOwnerOrAdmin}
                >
                  <FormattedMessage id={PRICING_PLAN_TEXTS[planGroup].button} />
                </ActionButton>
              </span>
            </Tooltip>
          )}
        </div>
      </DescriptionColumn>
      <ImageColumn>
        <StyledBanner src={banner} />
      </ImageColumn>
    </Root>
  );
}

const Root = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 60% 40%;
  justify-content: space-between;
  background-color: #e9dfdd; //Brand beige/100 (main)
  border-radius: 30px;
`;

const DescriptionColumn = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-between;

  padding: 30px 10px 30px 30px;

  gap: 8px;
`;

const ImageColumn = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledBanner = styled.img`
  border-radius: 30px;
  object-fit: cover;
`;

const PerksBox = styled.div`
  margin-left: ${({ theme }) => theme.spacings.medium};

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.small};
`;

const StyledCheckIcon = styled(CheckIcon)`
  color: #21aebf;
`;

const ActionButton = styled(Button)`
  width: 17rem;

  margin-left: 3.5rem;
`;

const TextHighlight = styled.span`
  color: #21aebf;
`;

const Title = styled.div`
  /* typography/h4 */
  font-family: 'Metropolis';
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: 123.5%; /* 41.99px */
  letter-spacing: 0.25px;
`;

const Body = styled.div`
  /* typography/body2 */
  font-family: 'Metropolis';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%; /* 20.02px */
  letter-spacing: 0.17px;
`;
