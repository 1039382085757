import { Typography, TypographyVariant } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { IntlMessageKeys } from 'features/i18n/IntlMessageKeys';
import { ReactNode } from 'react';
import styled from 'styled-components';

type Props = {
  label?: IntlMessageKeys;
  icon?: ReactNode;
  titleVariant?: TypographyVariant;
  length: number;
  maxLength: number;
  children: ReactNode;
};

export const CharacterLimiterWithLabel = ({
  label,
  length,
  maxLength,
  titleVariant = 'subtitle2',
  children,
  icon
}: Props) => {
  return (
    <FlexContainer gap="one">
      <Content>
        <FlexContainer direction="row" gap="small">
          <Typography variant={titleVariant}>{label}</Typography>
          {icon}
        </FlexContainer>
        <Counter variant="caption">
          {length ?? 0} / {maxLength}
        </Counter>
      </Content>
      {children}
    </FlexContainer>
  );
};

const Content = styled.div`
  display: flex;

  justify-content: space-between;
`;

const Counter = styled(Typography)`
  color: ${({ theme }) => theme.colors.blackDisabled};
`;
