import AddIcon from '@mui/icons-material/Add';
import { Button, Chip, Typography, TypographyProps } from '@mui/material';
import { HelpLinkingButton } from 'components/HelpLinkingButton';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { InformationDto } from 'services/backofficeIntegration/http/endpoints/infomration/httpGetInformationList';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled from 'styled-components';

type Props = {
  informationList?: InformationDto[];
  gtmId?: string;
  fontVariant?: TypographyProps['variant'];
  hasError?: boolean;
  onApply: () => void;
  onRemove: (id: number) => void;
};

export const InformationField = ({
  fontVariant,
  gtmId,
  informationList,
  hasError,
  onApply,
  onRemove
}: Props) => (
  <Root $hasError={hasError}>
    <Header>
      <Typography variant={fontVariant ?? 'subtitle2'} color={hasError ? 'error' : undefined}>
        <FormattedMessage id="common.information" />
      </Typography>
      <HelpLinkingButton link="aiWriter.inspirations.aiwriter.main.information.help_link" />
    </Header>

    {informationList && informationList.length > 0 && (
      <InformationBox>
        {informationList.map(information => (
          <Chip
            key={information.id}
            label={information.name}
            onDelete={() => onRemove(information.id)}
          />
        ))}
      </InformationBox>
    )}
    <div>
      <Button
        {...withGtmInteraction(gtmId)}
        onClick={onApply}
        startIcon={<AddIcon />}
        variant="text"
      >
        <FormattedMessage id="information.apply_field.add" />
      </Button>
    </div>
  </Root>
);

const Root = styled.div<{ $hasError?: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ $hasError }) => $hasError && 'color: red'};

  gap: ${({ theme }) => theme.spacings.one};
`;

const Header = styled.div`
  display: flex;

  align-items: center;
  gap: ${({ theme }) => theme.spacings.small};
`;

const InformationBox = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.medium};

  align-items: center;

  overflow-x: scroll;
`;
