import { Button, CircularProgress } from '@mui/material';
import Toast from 'components/toasts/Toast';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { BasePage } from 'features/teamInvitation/BasePage';
import { getErrorCodeFromAxiosError } from 'features/teamInvitation/getErrorCodeFromAxiosError';
import { useTeamInvitation } from 'features/teamInvitation/useTeamInvitation';
import { useTeamInvitationAccept } from 'features/teamInvitation/useTeamInvitationAccept';
import { trackingWrapper } from 'features/tracking/wrapper/TrackingWrapper';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { handleApiCommonErrors } from 'services/api/utils/handleApiCommonErrors';
import {
  InvitationErrorCode,
  invitationErrorCodes
} from 'services/backofficeIntegration/http/endpoints/teamMembers/httpTeamInvitationRegister';
import { GAEvents } from 'services/tracking/GAEvents';

export const TeamInvitationExistingCustomerLandingpage = () => {
  const navigate = useNavigate();
  const [inviteResponseErrorCode, setInviteResponseErrorCode] =
    useState<InvitationErrorCode | null>(null);

  const {
    tokenParam,
    handleLogin,
    teamInvitationInfo: {
      data: teamInvitationInfo,
      isLoading: isLoadingTeamInvitation,
      hasError: hasTeamInvitationError,
      errorCode: teamInvitationErrorCode
    }
  } = useTeamInvitation();

  const teamInvitationAccept = useTeamInvitationAccept(tokenParam || '');

  const onSubmit = async () => {
    const response = await teamInvitationAccept.mutateAsync(undefined, {
      onSuccess: () => {
        GAEvents.teamInvitationAccepted({
          teamId: teamInvitationInfo?.team_id,
          teamName: teamInvitationInfo?.team_name,
          invitedBy: teamInvitationInfo?.invited_by_name
        });

        trackingWrapper.track('teamInvitationAccepted', {
          teamId: teamInvitationInfo?.team_id,
          teamName: teamInvitationInfo?.team_name,
          invitedBy: teamInvitationInfo?.invited_by_name
        });
      },
      onError: (error: unknown) => {
        const errorCode = getErrorCodeFromAxiosError(error);
        if (errorCode) {
          if (invitationErrorCodes.includes(errorCode as InvitationErrorCode)) {
            setInviteResponseErrorCode(errorCode as InvitationErrorCode);
            return;
          }

          Toast.backendError(handleApiCommonErrors(errorCode));
          return;
        }

        Toast.commonError();
      }
    });

    if (!response || !response.status) {
      return;
    }

    handleLogin(response.data);
  };

  if (!tokenParam) {
    navigate('/login');
    return null;
  }

  return (
    <BasePage
      inviteResponseErrorCode={inviteResponseErrorCode}
      isLoadingTeamInvitation={isLoadingTeamInvitation}
      teamInvitationErrorCode={teamInvitationErrorCode}
      hasTeamInvitationError={hasTeamInvitationError}
      teamInvitationInfo={teamInvitationInfo}
    >
      <Button
        variant="contained"
        color="primary"
        id="next-button"
        disabled={teamInvitationAccept.isLoading}
        startIcon={teamInvitationAccept.isLoading ? <CircularProgress size={16} /> : undefined}
        type="submit"
        fullWidth={true}
        onClick={onSubmit}
      >
        <FormattedMessage id="team.invite.accept_invitation" />
      </Button>
    </BasePage>
  );
};
