import { Button, Typography } from '@mui/material';
import { ReactComponent as IconNeuroflashLogoSVG } from 'assets/neuroflashLogo.svg';
import FlexContainer from 'components/FlexContainer';
import { getRoutePath } from 'config/routes';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { useNavigate } from 'react-router';
import styled from 'styled-components';

export const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <Root alignItems="center">
      <NeuroflashLogo />
      <Headline variant="h5">
        <FormattedMessage id="not_found_page.title" />
      </Headline>
      <SecondaryLine gap="xmedium">
        <Typography variant="h5">
          <FormattedMessage id="not_found_page.subtitle" />
        </Typography>
        <DescriptionText variant="body1">
          <FormattedMessage id="not_found_page.description" />
        </DescriptionText>
        <Button variant="contained" onClick={() => navigate(getRoutePath('home'))}>
          <FormattedMessage id="not_found_page.visit_home.button" />
        </Button>
      </SecondaryLine>
    </Root>
  );
};

const Root = styled(FlexContainer)`
  width: 100vw;
  height: 100vh;
`;

const NeuroflashLogo = styled(IconNeuroflashLogoSVG)`
  margin-top: 88px;
  margin-bottom: ${({ theme }) => theme.spacings.medium};
`;

const Headline = styled(Typography)`
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: ${({ theme }) => theme.spacings.xxxlarge};
`;

const DescriptionText = styled(Typography)`
  text-align: center;
  color: ${({ theme }) => theme.colors.blackMediumEmphasis};
`;

const SecondaryLine = styled(FlexContainer)`
  max-width: 500px;
  align-items: center;
`;
