import Toast from 'components/toasts/Toast';
import { useFetchOnPricingChange } from 'features/pricing/hook/useFetchOnPricingChange';
import { updateUnsplashImagesLimitations } from 'features/pricing/store/actions/limitations/actions';
import { useCallback } from 'react';
import UnsplashAPI from 'services/api/unsplash';
import { handleApiCommonErrors } from 'services/api/utils/handleApiCommonErrors';
import { useAppDispatch } from 'store/hooks';

export function useFetchImagesLimitations() {
  const dispatch = useAppDispatch();

  const fetchLimitations = useCallback(async () => {
    try {
      const response = await UnsplashAPI.limitations();

      if (response.status) {
        dispatch(updateUnsplashImagesLimitations(response.data));
      } else {
        const errorId = handleApiCommonErrors(response.data.message);

        Toast.backendError(errorId);
      }
    } catch (error) {
      Toast.apiError();
    }
  }, [dispatch]);

  useFetchOnPricingChange(fetchLimitations);
}
