import { IconButton } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { BasicInfoButton } from 'components/iconButtons/BasicInfoButton';
import { getRoutePath } from 'config/routes';
import { useAiWriterExpandedSidebarStore } from 'features/aiWriter/AiWriterSidebar/sidebar/aiWriterExpandedSidebarStore';
import { ExpandSidebarButton } from 'features/aiWriter/AiWriterSidebar/sidebar/ExpandSidebarButton';
import {
  ColoredFavoriteRoundedIcon,
  ColoredHistoryRoundedIcon
} from 'features/aiWriter/AiWriterSidebar/steps/extraIcons';
import { setInspirationsCurrentStep } from 'features/aiWriter/store/actions/config/actions';
import { changeOutputTypeThunk } from 'features/aiWriter/store/actions/config/thunks/changeOutputTypeThunk';
import { getCurrentModelLanguageAndCountry } from 'features/aiWriter/store/selectors';
import { AiWriterSidebarStep } from 'features/aiWriter/store/types';
import { CustomCategories } from 'features/textGenerator/outputTypeModal/CategoriesList';
import { useShowOutputTypeModal } from 'features/textGenerator/outputTypeModal/useShowOutputTypeModal';
import { useMemo } from 'react';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useCurrentRoutePath } from 'utils/hooks/useCurrentRoutePath';
import useTr from 'utils/hooks/useTr';

export const TextInspirationsStepHeader = () => {
  const pathname = useCurrentRoutePath();
  const translate = useTr();
  const dispatch = useAppDispatch();
  const showOutputTypeModal = useShowOutputTypeModal();

  const { currentModelLanguage, currentModelCountry } = useAppSelector(
    getCurrentModelLanguageAndCountry
  );

  const isSidebarExpanded = useAiWriterExpandedSidebarStore(state => state.isSidebarExpanded);
  const setIsSidebarExpanded = useAiWriterExpandedSidebarStore(state => state.setIsSidebarExpanded);

  const isWorkflowBuilderActive = useMemo(
    () => [getRoutePath('blogPostWorkflow'), getRoutePath('socialPostWorkflow')].includes(pathname),
    [pathname]
  );

  function handleClick() {
    window.open(translate('aiWriter.inspirations.text.info_button.link'), '_blank');
  }

  return (
    <FlexContainer direction="row" alignItems="center">
      <ExpandSidebarButton
        isExpanded={isSidebarExpanded}
        onExpand={() => setIsSidebarExpanded(true)}
        onShrink={() => setIsSidebarExpanded(false)}
      />
      {/* Hide favorites and history during workflow builders */}
      {!isWorkflowBuilderActive && (
        <>
          <IconButton
            {...withGtmInteraction(gtmIds.aiWriter.historyTab)}
            size="small"
            onClick={() => {
              dispatch(
                setInspirationsCurrentStep({
                  step: AiWriterSidebarStep.aiWriter,
                  subStep: 'history'
                })
              );
            }}
          >
            <ColoredHistoryRoundedIcon />
          </IconButton>
          <IconButton
            {...withGtmInteraction(gtmIds.aiWriter.favoriteTab)}
            size="small"
            onClick={() => {
              showOutputTypeModal({
                initCategory: CustomCategories.FAVORITES_CATEGORY,
                modelCountry: currentModelCountry,
                modelLanguage: currentModelLanguage,
                onOutputTypeChange: value => {
                  dispatch(changeOutputTypeThunk(value));
                }
              });
            }}
          >
            <ColoredFavoriteRoundedIcon />
          </IconButton>
        </>
      )}

      <BasicInfoButton
        onClick={handleClick}
        tooltip={translate('aiWriter.inspirations.text.info_button.tooltip')}
        size="small"
      />
    </FlexContainer>
  );
};
