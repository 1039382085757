import { Link } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import Toast from 'components/toasts/Toast';
import { useIsTeamOwner } from 'features/team/useIsTeamOwner';
import { getErrorCodeFromAxiosError } from 'features/teamInvitation/getErrorCodeFromAxiosError';
import useUpdateSubscriptionModal from 'features/updateSubscriptionModal/hook/useUpdateSubscriptionModal';
import {
  CreatePersonalityParams,
  httpCreatePersonality
} from 'services/backofficeIntegration/http/endpoints/personalities/httpCreatePersonality';
import { invalidatePersonalitiesLimitationsQuery } from 'services/backofficeIntegration/http/endpoints/personalities/httpGetPersonalityLimitations';

export const useCreateChatPersonality = () => {
  const showUpgradeSubscriptionModal = useUpdateSubscriptionModal();
  const isOwner = useIsTeamOwner();

  return useMutation({
    mutationFn: (params: CreatePersonalityParams) => httpCreatePersonality.callEndpoint(params),
    onSuccess: data => {
      invalidatePersonalitiesLimitationsQuery();
      return data;
    },
    onError: (error: unknown) => {
      const errorCode = getErrorCodeFromAxiosError(error);

      if (errorCode) {
        Toast.error(`brandVoice.error.${errorCode}`, {
          upgrade: (text: string) =>
            isOwner && <Link onClick={showUpgradeSubscriptionModal}>{text}</Link>
        });
        return;
      }

      Toast.apiError();
    }
  });
};
