import { configureScope } from '@sentry/react';

import { CustomerDataPayload } from '../customer/store/types';

export const updateSentryScopeWithCustomerData = (customerData: CustomerDataPayload) => {
  configureScope(scope => {
    scope.setTag('nf.customer.id', customerData.id);

    return scope;
  });
};
