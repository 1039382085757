import { textGeneratorTips, TipLoader } from 'components/TipLoader';
import styled from 'styled-components';

export const StepInProgressState = () => (
  <Root>
    <TipLoader tips={textGeneratorTips} />
  </Root>
);

const Root = styled.div`
  width: 100%;
  display: grid;
  justify-items: center;
`;
