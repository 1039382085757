import { Link } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { ColoredTypography } from 'components/ColoredTypography';
import FlexContainer from 'components/FlexContainer';
import { WarningHint } from 'components/profile/components/pricing-plan-card/WarningHint';
import { makeCurrentUserQueryKeyPrefix } from 'features/currentUser/currentUserQueries';
import { getCustomerRole, getHasToken } from 'features/customer/store/selectors';
import { CustomerRole } from 'features/customer/store/types';
import getFirstFreePlanId from 'features/pricing/utils/getFirstFreePlanId';
import { hasSubscriptionEnded } from 'features/pricing/utils/isSubscriptionEnded';
import { createStripeCheckoutUrlFromResponse } from 'features/stripe-portal/createStripeCheckoutUrlFromResponse';
import { FormattedMessage } from 'react-intl';
import CustomerAPI from 'services/api/customer';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';

export function HomeDeactivatedAccountBanner() {
  const customerRole = useAppSelector(getCustomerRole);
  const customerHasTeam = useAppSelector(state => state.customer.hasTeam);
  const { subscription: currentSubscriptionState } = useAppSelector(state => state.customer);

  const hintIsDisplayed =
    !!currentSubscriptionState && hasSubscriptionEnded(currentSubscriptionState);

  const isNotOwner = customerRole !== CustomerRole.OWNER;
  const showTeamHint = customerHasTeam && isNotOwner;

  const freePlanCheckoutUrl = useFreePlanCheckoutUrl({
    enabled: hintIsDisplayed && !showTeamHint
  });

  // We are aware of the shift layout and it is acceptable here
  if (!hintIsDisplayed) {
    return null;
  }

  if (showTeamHint) {
    return (
      <WarningHint>
        <ColoredTypography variant="subtitle2">
          <FormattedMessage id="home.deactivated_account_hint.teams.title" />
        </ColoredTypography>
        <ColoredTypography variant="body2">
          <FormattedMessage id="home.deactivated_account_hint.teams.body" />
        </ColoredTypography>
      </WarningHint>
    );
  }

  if (!freePlanCheckoutUrl) {
    return null;
  }

  return (
    <Root>
      <FormattedMessage
        id="home.deactivated_account_hint"
        values={{
          button: (value: string) => (
            <Link href={freePlanCheckoutUrl} color="primary">
              {value}
            </Link>
          )
        }}
      />
    </Root>
  );
}

function useFreePlanCheckoutUrl({ enabled }: { enabled: boolean }): string | undefined {
  const hasToken = useAppSelector(getHasToken);

  const { data } = useQuery({
    enabled: hasToken && enabled,
    queryKey: [...makeCurrentUserQueryKeyPrefix(), 'free-plan-checkout-url'],
    queryFn: async () => {
      const firstFreePlanId = await getFirstFreePlanId();
      if (!firstFreePlanId) {
        return;
      }

      // #tech-debt https://app.clickup.com/t/862jtwd0g
      const response = await CustomerAPI.createBillingPortalUrl({ pricingId: firstFreePlanId });

      if (response.status) {
        return response.data;
      }

      throw response;
    }
  });

  if (data) {
    return createStripeCheckoutUrlFromResponse(data, false, false);
  }
}

const Root = styled(FlexContainer)`
  display: block;
  padding: ${({ theme }) => `${theme.spacings.medium} ${theme.spacings.small}`};
  margin-bottom: ${({ theme }) => theme.spacings.medium};

  background: ${({ theme }) => theme.colors.primary50};
  border-radius: ${({ theme }) => theme.borderRadius.small};
`;
