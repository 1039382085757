import { Email } from '@mui/icons-material';
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';
import { withSentryReactRouterV6Routing } from '@sentry/react';
import { ReactComponent as AiImagesIcon } from 'assets/sidebar/icon-ai-images.svg';
import { ReactComponent as AiTesterIcon } from 'assets/sidebar/icon-ai-tester.svg';
import { ReactComponent as AiWriterIcon } from 'assets/sidebar/icon-ai-writer.svg';
import { ReactComponent as ConceptFlashIcon } from 'assets/sidebar/icon-explorer.svg';
import { ReactComponent as HomeIcon } from 'assets/sidebar/icon-home.svg';
import LocalStorageKey from 'config/localStorageKey';
import { CustomerRole, Team } from 'features/customer/store/types';
import React, { useMemo } from 'react';
import { Routes } from 'react-router-dom';
import { useCurrentRoutePath } from 'utils/hooks/useCurrentRoutePath';
import { isDevEnv } from 'utils/isDevEnv';

export const AppRoutes = withSentryReactRouterV6Routing(Routes);

export type RouteConfig = {
  path: string;
  url?: string;
  sideBarConfig?: SideBarConfig;
  auth: boolean;
  hasSidebar: boolean;
  groupRestriction?: boolean;
  forceHide?: boolean;
  hasToolbar?: boolean;
  /**
   * Is this route used for API customers
   */
  allowApiCustomers?: boolean;
  allowedRoles?: CustomerRole[];
  allowedTeamIds?: number[];
};

export type RouteName =
  | RestrictedRoute
  | 'login'
  | 'register'
  | 'registerSuccess'
  | 'free'
  | 'forgotPassword'
  | 'home'
  | 'aiWriterPreview'
  | 'resendMail'
  | 'resetPassword'
  | 'resetPasswordInvalid'
  | 'dimensionsManagement'
  | 'activationMail'
  | 'profileInfo'
  | 'profileReferrals'
  | 'profileDashboard'
  | 'profileBilling'
  | 'profileTeams'
  | 'profileUsers'
  | 'socialLoginCallback'
  | 'paymentSuccess'
  | 'bonusReferral'
  | 'verifyAccount'
  | 'aiImagesPreview'
  | 'teamInvitationNewCustomerLandingpage'
  | 'teamInvitationExistingCustomerLandingpage'
  | 'onboardingWelcome'
  | 'onboardingSurvey'
  | 'onboardingUsecase'
  | 'onboardingPersonalityCreation'
  | 'onboardingPersonalityFinished'
  | 'onboardingSelection'
  | 'socialRegister'
  | 'productInfoTesting'
  | 'productInfoExploring'
  | 'blogPostWorkflow'
  | 'socialPostWorkflow'
  | 'newDocument'
  | 'brandHubOverviewWorkflows'
  | 'brandHubOverviewInformation'
  | 'brandHubOverviewTemplates'
  | 'brandHubOverviewBrandVoice'
  | 'modularWorkflowCreateNew'
  | 'modularWorkflowEdit'
  | 'modularWorkflowRun'
  | 'campaign'
  | 'pageNotFound';

export type RestrictedRoute = 'exploring' | 'aiWriter' | 'aiTester' | 'subjectLines' | 'aiImages';

export const routesConfig: Record<RouteName, RouteConfig> = {
  /**
   * Not authenticated routes
   */

  login: {
    path: '/login',
    auth: false,
    hasSidebar: true,
    allowApiCustomers: true
  },
  register: {
    path: '/register',
    auth: false,
    hasSidebar: true
  },
  registerSuccess: {
    path: '/register/success',
    auth: false,
    hasSidebar: true
  },
  free: {
    path: '/free',
    auth: false,
    hasSidebar: true
  },
  activationMail: {
    path: '/activation-mail',
    auth: false,
    hasSidebar: true
  },
  forgotPassword: {
    path: '/forgot-password',
    auth: false,
    hasSidebar: true
  },
  resetPassword: {
    path: '/reset-password',
    auth: false,
    hasSidebar: true
  },
  resendMail: {
    path: '/resend-mail',
    auth: false,
    hasSidebar: true
  },
  resetPasswordInvalid: {
    path: '/invalid-reset-password',
    auth: false,
    hasSidebar: true
  },
  socialLoginCallback: {
    path: '/social/:provider/callback',
    auth: false,
    hasSidebar: true,
    allowApiCustomers: true
  },
  aiWriterPreview: {
    path: '/ai-writer/:hash/preview',
    auth: false,
    hasSidebar: true
  },
  aiImagesPreview: {
    path: '/ai-images/:hash/preview',
    auth: false,
    hasSidebar: false
  },
  verifyAccount: {
    path: '/verify-account',
    auth: false,
    hasSidebar: false
  },
  teamInvitationNewCustomerLandingpage: {
    path: '/team/invitation/new',
    auth: false,
    hasSidebar: false
  },
  teamInvitationExistingCustomerLandingpage: {
    path: '/team/invitation',
    auth: false,
    hasSidebar: false
  },

  productInfoTesting: {
    path: '/performanceflash/info',
    auth: true,
    hasSidebar: true
  },
  productInfoExploring: {
    path: '/researchflash/info',
    auth: true,
    hasSidebar: true
  },
  pageNotFound: {
    path: '/404',
    auth: false,
    hasSidebar: false
  },

  /**
   * Authenticated routes
   */

  home: {
    path: '/home',
    auth: true,
    hasSidebar: true,
    allowApiCustomers: true,
    sideBarConfig: {
      title: 'sidebar.item.home',
      icon: <HomeIcon />
    }
  },
  aiWriter: {
    path: '/aiWriter',
    auth: true,
    hasSidebar: true,
    sideBarConfig: {
      title: 'sidebar.item.ai_writer',
      icon: <AiWriterIcon />
    }
  },
  aiImages: {
    path: '/ai-images',
    auth: true,
    hasSidebar: true,
    sideBarConfig: {
      title: 'sidebar.item.ai_images',
      icon: <AiImagesIcon />
    }
  },
  aiTester: {
    path: '/testing',
    auth: true,
    hasSidebar: true,
    sideBarConfig: {
      title: 'sidebar.item.ai_tester',
      icon: <AiTesterIcon />
    },
    hasToolbar: true
  },
  exploring: {
    path: '/exploring',
    auth: true,
    hasSidebar: true,
    sideBarConfig: {
      title: 'sidebar.item.explorer',
      icon: <ConceptFlashIcon />
    },
    hasToolbar: true
  },
  subjectLines: {
    path: '/subject-lines/*',
    url: '/subject-lines',
    auth: true,
    hasSidebar: true,
    sideBarConfig: {
      title: 'sidebar.item.subject_lines',
      icon: <Email />
    }
  },
  profileInfo: {
    path: '/profile',
    auth: true,
    hasSidebar: true
  },
  profileDashboard: {
    path: '/profile/dashboard',
    auth: true,
    hasSidebar: true
  },
  profileReferrals: {
    path: '/profile/referrals',
    auth: true,
    hasSidebar: true
  },
  profileBilling: {
    path: '/profile/billing',
    auth: true,
    hasSidebar: true,
    allowedRoles: [CustomerRole.OWNER]
  },
  profileTeams: {
    path: '/profile/teams',
    auth: true,
    hasSidebar: true,
    forceHide: true
  },
  profileUsers: {
    path: '/profile/users',
    auth: true,
    hasSidebar: true
  },
  dimensionsManagement: {
    path: '/dimensions-management',
    auth: true,
    hasSidebar: true,
    hasToolbar: true
  },
  paymentSuccess: {
    path: '/payment-success',
    auth: true,
    hasSidebar: true
  },
  bonusReferral: {
    path: '/bonus-referral',
    auth: true,
    hasSidebar: true
  },
  onboardingWelcome: {
    path: '/onboarding/welcome',
    auth: true,
    hasSidebar: false
  },
  onboardingSurvey: {
    path: '/onboarding/survey',
    auth: true,
    hasSidebar: false
  },
  onboardingUsecase: {
    path: '/onboarding/usecase',
    auth: true,
    hasSidebar: false
  },
  onboardingPersonalityCreation: {
    path: '/onboarding/brand-voice',
    auth: true,
    hasSidebar: false
  },
  onboardingPersonalityFinished: {
    path: '/onboarding/brand-voice-finished',
    auth: true,
    hasSidebar: false
  },
  onboardingSelection: {
    path: '/onboarding/selection',
    auth: true,
    hasSidebar: false
  },
  socialRegister: {
    path: '/socialRegister',
    auth: true,
    hasSidebar: false
  },
  blogPostWorkflow: {
    path: '/workflow/new-blog',
    auth: true,
    hasSidebar: true
  },
  socialPostWorkflow: {
    path: '/workflow/new-social',
    auth: true,
    hasSidebar: true
  },
  newDocument: {
    path: '/aiWriter/new',
    auth: true,
    hasSidebar: true
  },
  brandHubOverviewWorkflows: {
    path: '/brand-hub/overview/workflows',
    auth: true,
    hasSidebar: true,
    allowedTeamIds: [isDevEnv() ? 7 : 77]
  },
  brandHubOverviewInformation: {
    path: '/brand-hub/overview/information',
    auth: true,
    hasSidebar: true
  },
  brandHubOverviewTemplates: {
    path: '/brand-hub/overview/templates',
    auth: true,
    hasSidebar: true
  },
  brandHubOverviewBrandVoice: {
    path: '/brand-hub/overview/brand-voice',
    auth: true,
    hasSidebar: true,
    sideBarConfig: {
      title: 'sidebar.item.flash_hub',
      icon: <ScatterPlotIcon />
    }
  },
  modularWorkflowCreateNew: {
    path: '/brand-hub/modular-workflow/create',
    auth: true,
    hasSidebar: true
  },
  modularWorkflowEdit: {
    path: '/brand-hub/modular-workflow/edit/:workflowId',
    auth: true,
    hasSidebar: true
  },
  modularWorkflowRun: {
    path: '/brand-hub/modular-workflow/run/:workflowId',
    auth: true,
    hasSidebar: true
  },
  campaign: {
    path: '/campaign/:campaignId',
    auth: true,
    hasSidebar: false
  }
};

export const routeNames = Object.keys(routesConfig) as RouteName[];

const canAccessRestrictedFeatures = (userGroupId: number | null): boolean =>
  userGroupId === Number(process.env.REACT_APP_PRIVILEGED_GROUP_ID || '') &&
  localStorage.getItem(LocalStorageKey.HideInternalFeatures) !== 'true';

/**
 * There is a high chance that we should use `getRouteUrl`
 * in most (if not all) places where `getRoutePath` is used.
 * This requires further investigation.
 * #tech-debt https://app.clickup.com/t/2y4bbjk
 */
export const getRoutePath = (routeName: RouteName, params?: Record<string, string | number>) => {
  let basePath = routesConfig[routeName].path;

  if (!params) {
    return basePath;
  }

  // Replace route params with values
  Object.entries(params).forEach(([key, value]) => {
    basePath = basePath.replace(`:${key}`, value.toString());
  });

  // Safety check for any remaining route params
  if (basePath.includes(':')) {
    throw new Error(`Route path ${basePath} still contains route params`);
  }

  return basePath;
};

export function getRouteUrl(routeName: RouteName) {
  const { path, url } = routesConfig[routeName];
  return url ?? path;
}

export const getPaths = (filterRule: (config: RouteConfig) => boolean | undefined) =>
  Object.values(routesConfig)
    .filter(filterRule)
    .map(config => config.path);

export const shouldPageBeVisible = (
  config: RouteConfig,
  userGroupId: number | null,
  userRole: CustomerRole,
  isApiCustomer = false,
  customerTeams: Team[]
) => {
  if (isApiCustomer) {
    return !!config.allowApiCustomers;
  }

  if (config.allowedRoles && config.allowedRoles.length > 0) {
    return config.allowedRoles.includes(userRole);
  }

  if (config.allowedTeamIds && config.allowedTeamIds.length > 0) {
    return customerTeams.some(team => config.allowedTeamIds?.includes(team.id));
  }

  if (config.groupRestriction) {
    if (!canAccessRestrictedFeatures(userGroupId)) {
      return false;
    }
  }

  return !config.forceHide;
};

export const useIsSidebarEnabled = (): boolean => {
  const pathname = useCurrentRoutePath();

  return useMemo(
    () =>
      Object.values(routesConfig).find(routeConfig => {
        const { url, path } = routeConfig;
        const routePathname = url ?? path;
        return routePathname === pathname;
      })?.hasSidebar ?? false,
    [pathname]
  );
};

interface SideBarConfig {
  title: string;
  icon: React.ReactNode;
}

export interface SideBarData extends SideBarConfig {
  path: string;
}

export function getSidebarMenu(
  userGroupId: number | null,
  restrictions: Record<RestrictedRoute, boolean>,
  customerRole: CustomerRole,
  isApiCustomer: boolean,
  customerTeams: Team[]
): Array<SideBarData | undefined> {
  return Object.entries(routesConfig).map(([name, routeConfig]) => {
    const { sideBarConfig } = routeConfig;
    const canShow =
      sideBarConfig &&
      shouldPageBeVisible(routeConfig, userGroupId, customerRole, isApiCustomer, customerTeams) &&
      !restrictions[name as RestrictedRoute];

    if (canShow) {
      return {
        ...sideBarConfig,
        path: routeConfig.url ?? routeConfig.path
      };
    }

    return undefined;
  });
}
