import { useMutation } from '@tanstack/react-query';
import Toast from 'components/toasts/Toast';
import { getCurrentUser } from 'features/customer/store/actions';
import { getErrorCodeFromAxiosError } from 'features/teamInvitation/getErrorCodeFromAxiosError';
import { handleApiCommonErrors } from 'services/api/utils/handleApiCommonErrors';
import {
  httpUpdateTeamName,
  UpdateTeamNameParams
} from 'services/backofficeIntegration/http/endpoints/teamMembers/httpUpdateTeamName';
import { useAppDispatch } from 'store/hooks';

export const useUpdateTeamName = () => {
  const dispatch = useAppDispatch();

  return useMutation({
    mutationFn: (params: UpdateTeamNameParams) => httpUpdateTeamName.callEndpoint(params),
    onSuccess: () => {
      Toast.success('profile.users.workspace.name.updated.success');

      // TODO: Once BE updated the response of the update request, remove this and use the response instead
      dispatch(getCurrentUser.request());
    },
    onError: err => {
      const errorCode = getErrorCodeFromAxiosError(err);
      if (errorCode) {
        Toast.backendError(handleApiCommonErrors(errorCode));
      } else {
        Toast.apiError();
      }
    }
  });
};
