import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/rootReducer';

export const getPrompts = (state: RootState) => state.textGenerator.prompts;

export const getGetPromptConfigByLanguageAndOutputType = createSelector(
  getPrompts,
  prompts => (modelLanguage: string, outputTypeId: string) =>
    prompts.find(p => p.language === modelLanguage && p.outputType.id === outputTypeId)
);

export const getGetPromptConfigByLanguageCountryAndOutputType = createSelector(
  getPrompts,
  prompts => (language: string, country: string, outputTypeId: string) =>
    prompts.find(
      p => p.language === language && p.country === country && p.outputType.id === outputTypeId
    )
);

// n-times is the same for specific output types, no matter of language
export const getGetNTimesByOutputType = createSelector(
  getPrompts,
  prompts => (outputType: string) => {
    const propmt = prompts.find(p => p.outputType.id === outputType);

    if (propmt) {
      return propmt.nTimes;
    }

    return 1;
  }
);

export const isOutputTypeSupported = createSelector(
  getPrompts,
  prompts =>
    ({
      outputType,
      country,
      language
    }: {
      outputType: string;
      language: string;
      country: string;
    }) => {
      const prompt = prompts.find(
        p => p.outputType.id === outputType && p.country === country && p.language === language
      );

      return !!prompt;
    }
);
