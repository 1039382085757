import { BriefConfigValues } from 'features/aiTester/modals/briefConfig/BriefConfigModal';
import {
  getCurrentModelLanguageAndCountry,
  getTesterActiveTab
} from 'features/aiTester/store/selectors';
import { SystemTonality } from 'features/aiWriter/tonality/SystemTonality';
import {
  useGetDefaultOutputTypeByLanguageCached,
  useGetPrefillValueByLanguageCountryAndOutputType
} from 'features/textGenerator/utils/useGetPromptsOthers';
import { useSelector } from 'react-redux';

export default function useInitialValues(): BriefConfigValues {
  const { generateTextConfig } = useSelector(getTesterActiveTab);
  const { currentModelLanguage, currentModelCountry } = useSelector(
    getCurrentModelLanguageAndCountry
  );

  const getPrefillValue = useGetPrefillValueByLanguageCountryAndOutputType();
  const outputType = useGetDefaultOutputTypeByLanguageCached(currentModelLanguage);

  return {
    text:
      generateTextConfig?.text ||
      getPrefillValue(currentModelLanguage, currentModelCountry, outputType, 'text') ||
      '',
    keywords:
      generateTextConfig?.keywords ||
      getPrefillValue(currentModelLanguage, currentModelCountry, outputType, 'keywords') ||
      '',
    keywords2:
      generateTextConfig?.brand ||
      getPrefillValue(currentModelLanguage, currentModelCountry, outputType, 'keywords2') ||
      '',
    outputType: generateTextConfig?.outputType || outputType,
    userTonality: generateTextConfig?.userTonality || [],
    systemTonality: (generateTextConfig?.systemTonality || []) as SystemTonality[],
    nTextItems: generateTextConfig?.nTextItems || 2,
    personalityId: generateTextConfig?.personalityId || undefined,
    informationList: generateTextConfig?.informationList || undefined
  };
}
