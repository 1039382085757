import { Table, TableBody, TableRow } from '@mui/material';
import {
  displayFeaturePresence,
  FirstCell,
  StyledCell,
  StyledTable
} from 'components/profile/components/pricing-plan-card/FeatureComparisonSection';
import { FormattedMessage } from 'react-intl';

const createCollaborationData = (
  name: string,
  standard: boolean,
  pro: boolean,
  enterprise: boolean
) => {
  return { name, standard, pro, enterprise };
};

const collaborationData = [
  createCollaborationData('history_favorites', true, true, true),
  createCollaborationData('invite_users', false, true, true),
  createCollaborationData('collaboration', false, true, true),
  createCollaborationData('workspace_roles', false, true, true)
];

export const CollaborationTable = () => {
  return (
    <StyledTable>
      <Table>
        <TableBody>
          {collaborationData.map(row => (
            <TableRow key={row.name}>
              <FirstCell component="th" scope="row">
                <FormattedMessage id={`features_comparison.collaboration.${row.name}`} />
              </FirstCell>

              <StyledCell>{displayFeaturePresence(row.standard)}</StyledCell>
              <StyledCell>{displayFeaturePresence(row.pro)}</StyledCell>
              <StyledCell>{displayFeaturePresence(row.enterprise)}</StyledCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </StyledTable>
  );
};
