import { CircularProgress, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const defaultTranslations = [
  'tip_loader.alternating_messages.average_time',
  'tip_loader.alternating_messages.creativity_progress'
];

type LoaderProps = {
  alternatingMessages?: string[];
};

const Loader = ({ alternatingMessages = defaultTranslations }: LoaderProps): React.ReactElement => {
  const [currentTranslationIndex, setCurrentTranslationIndex] = useState(0);
  const currentTranslation = alternatingMessages[currentTranslationIndex];

  useEffect(() => {
    const labelTimer = setInterval(() => {
      setCurrentTranslationIndex(prevCount => (prevCount + 1) % alternatingMessages.length);
    }, 3000);

    return () => {
      clearInterval(labelTimer);
    };
  }, [currentTranslationIndex, currentTranslation, alternatingMessages]);

  return (
    <LoaderRoot>
      <CircularProgress size={44} />
      <Typography component="div" variant="subtitle2" color="textSecondary">
        <FormattedMessage id={currentTranslation} />
      </Typography>
    </LoaderRoot>
  );
};

const commonTips = [
  createBasicTip({ tipId: 'tip_loader.tips.common.1' }),
  createBasicTip({ tipId: 'tip_loader.tips.common.2' }),
  createBasicTip({ tipId: 'tip_loader.tips.common.3' }),
  createBasicTip({ tipId: 'tip_loader.tips.common.4' }),
  createBasicTip({ tipId: 'tip_loader.tips.common.5' }),
  createBasicTip({ tipId: 'tip_loader.tips.common.6' }),
  createBasicTip({ tipId: 'tip_loader.tips.common.7' }),
  createBasicTip({ tipId: 'tip_loader.tips.common.8' }),
  createBasicTip({ tipId: 'tip_loader.tips.common.9' }),
  createBasicTip({ tipId: 'tip_loader.tips.common.10' }),
  createBasicTip({ tipId: 'tip_loader.tips.common.11' }),
  createBasicTip({ tipId: 'tip_loader.tips.common.12' }),
  createBasicTip({ tipId: 'tip_loader.tips.common.13' }),
  createBasicTip({ tipId: 'tip_loader.tips.common.14' }),
  createBasicTip({ tipId: 'tip_loader.tips.common.15' }),
  createBasicTip({ tipId: 'tip_loader.tips.common.16' }),
  createBasicTip({ tipId: 'tip_loader.tips.common.17' }),
  createBasicTip({ tipId: 'tip_loader.tips.common.18' }),
  createBasicTip({ tipId: 'tip_loader.tips.common.19' }),
  createBasicTip({ tipId: 'tip_loader.tips.common.20' })
];

export const imageGeneratorTips = [
  ...commonTips,
  createBasicTip({ tipId: 'tip_loader.tips.image_generation.1' }),
  createBasicTip({ tipId: 'tip_loader.tips.image_generation.2' }),
  createBasicTip({ tipId: 'tip_loader.tips.image_generation.3' }),
  createBasicTip({ tipId: 'tip_loader.tips.image_generation.4' })
];

export const textGeneratorTips = [
  createBasicTip({ tipId: 'tip_loader.tips.text_generation.1' }),
  ...commonTips
];

export const personalityTips = [
  createBasicTip({ tipId: 'tip_loader.tips.personality_generation.1' }),
  createBasicTip({ tipId: 'tip_loader.tips.personality_generation.2' }),
  createBasicTip({ tipId: 'tip_loader.tips.personality_generation.3' }),
  createBasicTip({ tipId: 'tip_loader.tips.personality_generation.4' }),
  createBasicTip({ tipId: 'tip_loader.tips.personality_generation.5' }),
  createBasicTip({ tipId: 'tip_loader.tips.personality_generation.6' }),
  createBasicTip({ tipId: 'tip_loader.tips.personality_generation.7' }),
  createBasicTip({ tipId: 'tip_loader.tips.personality_generation.8' }),
  createBasicTip({ tipId: 'tip_loader.tips.personality_generation.9' }),
  createBasicTip({ tipId: 'tip_loader.tips.personality_generation.10' })
];

function createBasicTip(props: { tipId: string }) {
  return (
    <FormattedMessage
      id={props.tipId}
      values={{
        b: (chunks: string) => <b>{chunks}</b>
      }}
    />
  );
}

function getRandomItem(items: JSX.Element[]) {
  const randomIndex = Math.floor(Math.random() * items.length);
  return items[randomIndex];
}

type TipLoaderProps = {
  alternatingMessages?: string[];
  tips: JSX.Element[];
};

export function TipLoader({ alternatingMessages, tips }: TipLoaderProps) {
  const tip = getRandomItem(tips);

  return (
    <LoaderContainer>
      <Loader alternatingMessages={alternatingMessages} />
      <TipContainer>
        <Typography variant="subtitle2" color="textSecondary" align="center">
          {tip}
        </Typography>
      </TipContainer>
    </LoaderContainer>
  );
}

const LoaderContainer = styled.div`
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.large};
`;

const TipContainer = styled.div`
  width: 80%;
`;

const LoaderRoot = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.medium};
`;
