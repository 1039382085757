import FormattedMessage from 'features/i18n/FormattedMessage';
import { IntlMessageKeys } from 'features/i18n/IntlMessageKeys';

type Props = {
  message: IntlMessageKeys;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  msgValues?: Record<string, any>;
};
export const ToastBasicMessage = ({ message, msgValues }: Props) => (
  <FormattedMessage id={message} values={msgValues} />
);
