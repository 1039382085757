import 'react-lazy-load-image-component/src/effects/blur.css';

import DownloadIcon from '@mui/icons-material/Download';
import Masonry from '@mui/lab/Masonry';
import { IconButton, ImageListItem, ImageListItemBar } from '@mui/material';
import Toast from 'components/toasts/Toast';
import { useInsertImageToEditor } from 'features/aiImages/AiImagesPage/useInsertImageToEditor';
import { useAiWriterExpandedSidebarStore } from 'features/aiWriter/AiWriterSidebar/sidebar/aiWriterExpandedSidebarStore';
import { useHasTooManyImagesInDocument } from 'features/aiWriter/hooks/useHasTooManyImagesInDocument';
import { getImages } from 'features/aiWriter/store/selectors';
import { AiWriterImage } from 'features/aiWriter/store/types';
import UnsplashAPI from 'services/api/unsplash';
import { handleApiCommonErrors } from 'services/api/utils/handleApiCommonErrors';
import gtmIds from 'services/tracking/GTMIds';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';

export const imageWidth = 197;
const expandedImageWidth = 306;

export const ImagesInspirations = () => {
  const images = useAppSelector(getImages);
  const { hasTooManyImagesInDocument, handleTooManyImages } = useHasTooManyImagesInDocument();
  const { insertImageToEditor } = useInsertImageToEditor();
  const isSidebarExpanded = useAiWriterExpandedSidebarStore(state => state.isSidebarExpanded);

  const addImage = async (image: AiWriterImage) => {
    if (hasTooManyImagesInDocument()) {
      handleTooManyImages();
      return;
    }

    insertImageToEditor(image.image);

    const response = await UnsplashAPI.trackDownloadImage({
      downloadLocation: image.trackDownloadLink,
      photoId: image.id
    });

    if (!response.status) {
      Toast.backendError(handleApiCommonErrors(response.message));
    }
    return response;
  };

  const DownloadButton = ({ link }: { link: string }) => (
    <HoverActions>
      <IconButton id={gtmIds.aiWriter.images.download} onClick={() => window.open(link, '_blank')}>
        <DownloadIcon htmlColor="#FFFFFF" />
      </IconButton>
    </HoverActions>
  );

  return (
    <Masonry columns={2} spacing={2}>
      {images.map(image => (
        <ImageListItem key={image.id}>
          <ImageCard id={gtmIds.aiWriter.images.selectImage} onClick={() => addImage(image)}>
            <Image width={isSidebarExpanded ? expandedImageWidth : imageWidth} src={image.thumb} />
          </ImageCard>
          <ImageBar
            position="bottom"
            title={
              <AuthorLink
                id={gtmIds.aiWriter.images.clickOnAuthorName}
                href={`${image.authorPage}?utm_source=neuroflash&utm_medium=referral`}
                target="_blank"
                rel="noreferrer"
              >
                {image.author}
              </AuthorLink>
            }
            actionIcon={<DownloadButton link={image.downloadLink} />}
          />
        </ImageListItem>
      ))}
    </Masonry>
  );
};

const HoverActions = styled.div``;

const ImageCard = styled.div`
  cursor: pointer;

  &:active {
    cursor: default;
  }
`;

const ImageBar = styled(ImageListItemBar)`
  border-radius: ${({ theme }) => theme.borderRadius.small};
`;

const AuthorLink = styled.a`
  color: inherit;
  text-decoration: none;
`;

const Image = styled.img`
  border-radius: ${({ theme }) => theme.borderRadius.small};
`;
