import { Button, MenuItem, Select, TextField, Typography } from '@mui/material';
import { Action } from '@reduxjs/toolkit';
import { SpinnerDimmer } from 'components/base/SpinnerDimmer';
import { ColoredTypography } from 'components/ColoredTypography';
import { CloseModal } from 'components/modals/types';
import timezones from 'components/TimezoneSelect/timezones.json';
import Toast from 'components/toasts/Toast';
import { updateCurrentUser } from 'features/customer/store/actions';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { Form, Formik } from 'formik';
import { useMemo } from 'react';
import { shallowEqual } from 'react-redux';
import { ThunkAction } from 'redux-thunk/src/types';
import { useAppSelector } from 'store/hooks';
import store from 'store/store';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';
import { passwordRules } from 'utils/validationRules';
import { object, ref, string } from 'yup';

import { AvatarInput } from './AvatarInput';
import { PasswordInput } from './PasswordInput';

type FormValues = {
  name: string;
  timezone: string;
  image: undefined | string;
  password: string;
  passwordConfirm: string;
};

type Props = CloseModal;

const EditProfileModal = ({ closeModal }: Props) => {
  const translate = useTr();
  const { username, timezone, isLoading } = useAppSelector(state => state.customer, shallowEqual);

  const validationSchema = useMemo(
    () =>
      object().shape({
        name: string().required().max(255),
        timezone: string().required(),
        password: passwordRules(translate).notRequired(),
        passwordConfirm: string().when('password', {
          is: (password: string) => password,
          then: string()
            .required('validation.field_required')
            .oneOf([ref('password')], translate('profile.passwords_dont_match')),
          otherwise: string().notRequired()
        })
      }),
    [translate]
  );

  return (
    <SpinnerDimmer active={isLoading} loader>
      <Root>
        <ColoredTypography variant="h6">
          <FormattedMessage id="profile.edit_profile" />
        </ColoredTypography>

        <Formik
          initialValues={{
            name: username,
            timezone,
            image: undefined,
            password: '',
            passwordConfirm: ''
          }}
          validationSchema={validationSchema}
          onSubmit={values => store.dispatch(updateAndCloseModalThunk(values))}
        >
          {({ values, handleChange, ...rest }) => (
            <FormContainer>
              <div>
                <Typography variant="subtitle2">
                  <FormattedMessage id="profile.username" />
                </Typography>
                <TextField
                  fullWidth
                  name="name"
                  onChange={handleChange}
                  value={values.name}
                  error={rest.touched.name && Boolean(rest.errors.name)}
                />
              </div>
              <div>
                <Typography variant="subtitle2">
                  <FormattedMessage id="register.picture" />
                </Typography>
                <AvatarInput name="image" />
              </div>
              <div>
                <Typography variant="subtitle2">
                  <FormattedMessage id="profile.timezone" />
                </Typography>
                <Select
                  fullWidth
                  name="timezone"
                  onChange={handleChange}
                  value={values.timezone}
                  error={rest.touched.timezone && Boolean(rest.errors.timezone)}
                >
                  {timezones.map(value => (
                    <MenuItem value={value} key={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div>
                <Typography variant="subtitle2">
                  <FormattedMessage id="profile.new_password" />
                </Typography>
                <PasswordInput name="password" />
              </div>
              <div>
                <Typography variant="subtitle2">
                  <FormattedMessage id="profile.confirm_password" />
                </Typography>
                <PasswordInput name="passwordConfirm" />
              </div>
              <ButtonContainer>
                <Button size="large" onClick={closeModal}>
                  <FormattedMessage id="common.cancel" />
                </Button>
                <Button size="large" type="submit" variant="contained" color="primary">
                  <FormattedMessage id="common.confirm" />
                </Button>
              </ButtonContainer>
            </FormContainer>
          )}
        </Formik>
      </Root>
    </SpinnerDimmer>
  );

  // #tech-debt: https://app.clickup.com/t/30378wn
  function updateAndCloseModalThunk(
    values: FormValues
  ): ThunkAction<void, unknown, unknown, Action> {
    return async dispatch => {
      dispatch(
        updateCurrentUser.request(
          {
            name: values.name,
            password: values.password || undefined,
            timezone: values.timezone,
            image: values.image
          },
          { thunk: true }
        )
      );

      Toast.success('profile.success');

      closeModal();
    };
  }
};

const Root = styled.div`
  padding: ${({ theme }) => theme.spacings.four};

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.three};
`;

const FormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.two};
`;

const ButtonContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacings.two};

  display: flex;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacings.two};
`;

export default EditProfileModal;
