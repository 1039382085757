import aiWriterIcon from 'assets/icons/ai-writer-sidebar/icon-ai-writer-primary.svg';
import aiWriterIconSelected from 'assets/icons/ai-writer-sidebar/icon-ai-writer-primary-selected.svg';
import flashScoreIcon from 'assets/icons/ai-writer-sidebar/icon-flash-score.svg';
import flashScoreIconSelected from 'assets/icons/ai-writer-sidebar/icon-flash-score-selected.svg';
import imageSearchIcon from 'assets/icons/ai-writer-sidebar/icon-unsplash.svg';
import imageSearchIconSelected from 'assets/icons/ai-writer-sidebar/icon-unsplash-selected.svg';
import categoryIcon from 'assets/icons/icon-category.svg';
import categoryIconSelected from 'assets/icons/icon-category-selected.svg';
import iconChat from 'assets/icons/icon-chat.svg';
import iconChatSelected from 'assets/icons/icon-chat-selected.svg';
import plagiarismIcon from 'assets/icons/icon-plagiarism.svg';
import plagiarismIconSelected from 'assets/icons/icon-plagiarism-selected.svg';
import SeoAnalysisIcon from 'assets/icons/icon-seo-analysis.svg';
import SeoAnalysisSelectedIcon from 'assets/icons/icon-seo-analysis-selected.svg';
import SelectableIconButton from 'components/buttons/SelectableIconButton';
import FlexContainer from 'components/FlexContainer';
import LocalStorageKey from 'config/localStorageKey';
import { useCalculateInspirationsStep } from 'features/aiWriter/AiWriterSidebar/hooks/useCalculateInspirationsStep';
import { DRAWER_BUTTONS_WIDTH } from 'features/aiWriter/AiWriterSidebar/sidebar/AiWriterSidebarDrawer';
import { mkInspirationStepTooltip } from 'features/aiWriter/AiWriterSidebar/steps/textInspirations/mkInspirationStepTooltip';
import { setInspirationsCurrentStep } from 'features/aiWriter/store/actions/config/actions';
import { getInspirationsCurrentStep } from 'features/aiWriter/store/selectors';
import { AiWriterSidebarStep } from 'features/aiWriter/store/types';
import { useIsFlashScoreEnabled } from 'features/flashScore/useIsFlashScoreEnabled';
import gtmIds from 'services/tracking/GTMIds';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { useLocalStorage } from 'utils/hooks/useLocalStorage';
import useTr from 'utils/hooks/useTr';

const sidebarButtonsContainerHeight = 340;
const sidebarButtonsContainerHeightWithoutFlashScore = 290;

type Props = {
  isDisabled?: boolean;
  onStepChange: (step: AiWriterSidebarStep) => void;
};

export const AiWriterSidebarButtons = ({ isDisabled = false, onStepChange }: Props) => {
  const dispatch = useAppDispatch();
  const translate = useTr();
  const [, setChatClicked] = useLocalStorage(LocalStorageKey.AiWriterSidebarChatClicked, 0);

  const inspirationsCurrentStep = useAppSelector(getInspirationsCurrentStep);

  const calculateInspirationsStep = useCalculateInspirationsStep();
  const isFlashScoreTabVisible = useIsFlashScoreEnabled();

  const buttonBarHeight = isFlashScoreTabVisible
    ? sidebarButtonsContainerHeight
    : sidebarButtonsContainerHeightWithoutFlashScore;

  const setInspirationsPanel = (step: AiWriterSidebarStep | undefined) => {
    if (step === inspirationsCurrentStep) {
      dispatch(setInspirationsCurrentStep(undefined));
      return;
    }

    const calculatedStep = calculateInspirationsStep(step);
    dispatch(setInspirationsCurrentStep(calculatedStep));
  };

  const handleChatClick = () => {
    setChatClicked(prevValue => Number(prevValue) + 1);
    handleChangeStep(AiWriterSidebarStep.chat);
  };

  const handleChangeStep = (step: AiWriterSidebarStep) => {
    onStepChange(step);
    setInspirationsPanel(step);
  };

  return (
    <ButtonsBar $height={buttonBarHeight}>
      <FlexContainer gap="two">
        <SelectableIconButton
          id={gtmIds.aiWriter.openTextInspirationsPanel}
          isSelected={inspirationsCurrentStep === AiWriterSidebarStep.aiWriter}
          icon={aiWriterIcon}
          tooltip={translate(mkInspirationStepTooltip(AiWriterSidebarStep.aiWriter))}
          selectedIcon={aiWriterIconSelected}
          onClick={() => handleChangeStep(AiWriterSidebarStep.aiWriter)}
          isDisabled={isDisabled}
        />
        <SelectableIconButton
          gtmId={gtmIds.aiWriter.chat.openTab}
          isSelected={inspirationsCurrentStep === AiWriterSidebarStep.chat}
          icon={iconChat}
          tooltip={translate(mkInspirationStepTooltip(AiWriterSidebarStep.chat))}
          selectedIcon={iconChatSelected}
          onClick={handleChatClick}
          isDisabled={isDisabled}
        />
        <SelectableIconButton
          id={gtmIds.aiWriter.images.openTab}
          isSelected={inspirationsCurrentStep === AiWriterSidebarStep.images}
          icon={imageSearchIcon}
          tooltip={translate(mkInspirationStepTooltip(AiWriterSidebarStep.images))}
          selectedIcon={imageSearchIconSelected}
          onClick={() => handleChangeStep(AiWriterSidebarStep.images)}
          isDisabled={isDisabled}
        />
        {isFlashScoreTabVisible && (
          <SelectableIconButton
            gtmId={gtmIds.aiWriter.flashScore.openTab}
            isSelected={inspirationsCurrentStep === AiWriterSidebarStep.flashScore}
            icon={flashScoreIcon}
            selectedIcon={flashScoreIconSelected}
            tooltip={translate(mkInspirationStepTooltip(AiWriterSidebarStep.flashScore))}
            onClick={() => handleChangeStep(AiWriterSidebarStep.flashScore)}
            isDisabled={isDisabled}
            isNewFeature={true}
          />
        )}
        <SelectableIconButton
          id={gtmIds.aiWriter.seo.openTab}
          isSelected={inspirationsCurrentStep === AiWriterSidebarStep.seoAnalysis}
          icon={SeoAnalysisIcon}
          selectedIcon={SeoAnalysisSelectedIcon}
          tooltip={translate(mkInspirationStepTooltip(AiWriterSidebarStep.seoAnalysis))}
          onClick={() => handleChangeStep(AiWriterSidebarStep.seoAnalysis)}
          isDisabled={isDisabled}
        />
        <SelectableIconButton
          gtmId={gtmIds.aiWriter.templates.openTab}
          isSelected={inspirationsCurrentStep === AiWriterSidebarStep.templates}
          icon={categoryIcon}
          selectedIcon={categoryIconSelected}
          tooltip={translate(mkInspirationStepTooltip(AiWriterSidebarStep.templates))}
          onClick={() => handleChangeStep(AiWriterSidebarStep.templates)}
          isDisabled={isDisabled}
        />
        <SelectableIconButton
          gtmId={gtmIds.aiWriter.plagiarism.openTab}
          isSelected={inspirationsCurrentStep === AiWriterSidebarStep.plagiarism}
          icon={plagiarismIcon}
          selectedIcon={plagiarismIconSelected}
          tooltip={translate(mkInspirationStepTooltip(AiWriterSidebarStep.plagiarism))}
          onClick={() => handleChangeStep(AiWriterSidebarStep.plagiarism)}
          isDisabled={isDisabled}
        />
      </FlexContainer>
    </ButtonsBar>
  );
};

const ButtonsBar = styled.div<{ $height: number }>`
  align-self: flex-start;

  position: relative;
  right: -1px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  flex: 0 1 ${DRAWER_BUTTONS_WIDTH}px;
  width: ${DRAWER_BUTTONS_WIDTH}px;
  height: ${({ $height }) => $height}px;
  // Push it down to make it look like it's connected to the sidebar views
  margin-top: 66px;
  padding: 0.5rem 0;

  border-radius: ${({ theme }) => theme.borderRadius.medium} 0 0
    ${({ theme }) => theme.borderRadius.medium};
  border-left: 0.5px solid ${({ theme }) => theme.colors.aiWriterSidebarBorderColor};
  border-bottom: 0.5px solid ${({ theme }) => theme.colors.aiWriterSidebarBorderColor};
  border-top: 0.5px solid ${({ theme }) => theme.colors.aiWriterSidebarBorderColor};
  border-right: 1px solid #fff;
`;
