import { rgba } from 'polished';
import { DefaultTheme } from 'styled-components';
import { NewThemeConfig } from 'styles/new-theme/newThemeConfig';

export const newStyledComponentsTheme = (theme: NewThemeConfig): DefaultTheme => ({
  fontFamily: theme.fontFamily,
  colors: {
    ...theme.colors,

    mainColor: theme.colors.primaryColorMain,

    primary: theme.colors.primaryColorMain,

    primary700: theme.colors.primary__700,
    primary600: theme.colors.primary__600,
    primary500: theme.colors.primary__500main,
    primary300: theme.colors.primary__300,
    primary200: theme.colors.primary__200,
    primary100: theme.colors.primary__100,
    primary50: theme.colors.primary__50,

    primaryHover: theme.colors.primaryColorHover,

    secondary: theme.colors.secondaryColorMain,

    secondary800: theme.colors.secondary__800,
    secondary700: theme.colors.secondary__700,
    secondary600: theme.colors.secondary__600,
    secondary500: theme.colors.secondary__500main,
    secondary200: theme.colors.secondary__200,
    secondary100: theme.colors.secondary__100,
    secondary50: theme.colors.secondary__50,

    secondaryDark: theme.colors.palette.pinkRose__700,
    secondaryDark200: theme.colors.palette.pinkRose__800,
    secondaryDark400: theme.colors.palette.pinkRose__900,

    lightGreen: theme.colors.palette.green__300,

    lightGreen900: theme.colors.palette.green__900,
    lightGreen800: theme.colors.palette.green__800,
    lightGreen700: theme.colors.palette.green__700,
    lightGreen600: theme.colors.palette.green__600,
    lightGreen500: theme.colors.palette.green__500main,
    lightGreen400: theme.colors.palette.green__400,
    lightGreen300: theme.colors.palette.green__300,
    lightGreen200: theme.colors.palette.green__200,
    lightGreen100: theme.colors.palette.green__100,
    lightGreen50: theme.colors.palette.green__50,

    textMuted: theme.colors.textDisabled,

    success: theme.colors.successMain,
    info: theme.colors.infoMain,
    warning: theme.colors.warningMain,
    danger: theme.colors.errorLight,
    error: theme.colors.errorMain,

    orange: theme.colors.palette.orange__500main,
    // TODO: Find best matching color from the style guide
    link: '#0086FF',
    white: theme.colors.palette.white,
    black: theme.colors.palette.black,
    backgroundBlue: theme.colors.componentsAlertInfoBackground,
    // TODO: Find best matching color from the style guide
    violet: '#a25cff',
    // TODO: Find best matching color from the style guide
    lightViolet: rgba(162, 92, 255, 0.17),

    whiteMediumEmphasis: theme.colors.commonWhiteMain,
    whiteDisabled: theme.colors.commonWhiteMain,

    blackHighEmphasis: theme.colors.textSecondary,
    blackMediumEmphasis: theme.colors.textSecondary,
    blackDisabled: theme.colors.textDisabled,
    blackInactive: theme.colors.textDisabled,

    surfaceCard: theme.colors.commonWhiteMain,
    surfaceCardHover: theme.colors.palette.dark__100,

    inputBackground: theme.colors.backgroundDefault,
    textTile: theme.colors.backgroundDefault,

    blackHoverOverlay: theme.colors.commonBlackHover,
    blackFocusOverlay: theme.colors.commonBlackFocus,
    blackPressedOverlay: theme.colors.commonBlackFocusvisible,

    // TODO: Find best matching color from the style guide
    emailNotificationToolbarBackground: '#A25CFF',
    conversationAiIconBackground: '#A25CFF',
    infoToolbarBackground: '#cbeeff',
    aiWriterSidebarBorderColor: '#d9d9d9',

    // TODO: Find best matching color from the style guide
    warningRed: '#E76307',
    warningYellow: '#FFF2B1'
  },

  typography: theme.typography,

  fontSizes: {
    h1: theme.fontSizes.h1,
    h2: theme.fontSizes.h2,
    h3: theme.fontSizes.h3,
    h4: theme.fontSizes.h4,
    h5: theme.fontSizes.h5,
    h6: theme.fontSizes.h6,

    body1: theme.fontSizes.body1,
    body2: theme.fontSizes.body2,

    caption: theme.fontSizes.caption
  },
  spacings: {
    ...theme.spacings,

    /**
     * @deprecated Use levels (like 1, 2, ... 12) from `spacings` instead
     */
    xxsmall: theme.spacings.one,
    /**
     * @deprecated Use levels (like 1, 2, ... 12) from `spacings` instead
     */
    xsmall: theme.spacings.two,
    /**
     * @deprecated Use levels (like 1, 2, ... 12) from `spacings` instead
     */
    small: theme.spacings.three,
    /**
     * @deprecated Use levels (like 1, 2, ... 12) from `spacings` instead
     */
    medium: theme.spacings.four,
    /**
     * @deprecated Use levels (like 1, 2, ... 12) from `spacings` instead
     */
    xmedium: theme.spacings.five,
    /**
     * @deprecated Use levels (like 1, 2, ... 12) from `spacings` instead
     */
    large: theme.spacings.six,
    /**
     * @deprecated Use levels (like 1, 2, ... 12) from `spacings` instead
     */
    xlarge: theme.spacings.seven,
    /**
     * @deprecated Use levels (like 1, 2, ... 12) from `spacings` instead
     */
    xxlarge: theme.spacings.eight,
    /**
     * @deprecated Use levels (like 1, 2, ... 12) from `spacings` instead
     */
    xxxlarge: theme.spacings.nine,
    /**
     * @deprecated Use levels (like 1, 2, ... 12) from `spacings` instead
     */
    xxxxlarge: theme.spacings.ten
  },
  borderRadius: {
    ...theme.borderRadius
  },
  container: {
    ...theme.container
  },
  shadow: {
    ...theme.shadow
  },
  transition: {
    ...theme.transition
  },
  sizes: {
    ...theme.sizes
  }
});
