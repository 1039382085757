import FlexContainer from 'components/FlexContainer';
import { CustomerAvatar } from 'features/customer/CustomerAvatar';
import { getUserImage, getUserName } from 'features/customer/store/selectors';
import { TimeGreeting } from 'features/homePage/HomeHeader/TimeGreeting';
import { InviteTeammatesButton } from 'features/homePage/HomeTeamSection/InviteTeammatesButton';
import useSubscriptionState from 'features/pricing/hook/useSubscriptionState';
import { PricingUpgradeButton } from 'features/pricing/PricingUpgradeButton';
import { PricingPlan } from 'features/pricing/types';
import { ReactElement } from 'react';
import gtmIds from 'services/tracking/GTMIds';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';

export const HomeHeader = (): ReactElement => {
  const name = useAppSelector(getUserName);
  const image = useAppSelector(getUserImage);
  const { currentPricingPlan } = useSubscriptionState();
  const isFreePlan = currentPricingPlan.type === PricingPlan.free;
  const customerHasTeam = useAppSelector(state => state.customer.hasTeam);

  return (
    <Root>
      <Content gap="medium" direction="row">
        <CustomerAvatar name={name} image={image ?? undefined} />

        <Welcome>
          <TimeGreeting name={name} />
        </Welcome>
      </Content>

      <ButtonsBox>
        <InviteTeammatesButton />
        {isFreePlan && !customerHasTeam && (
          <PricingUpgradeButton id={gtmIds.pricing.upgradePopUp} />
        )}
      </ButtonsBox>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Content = styled(FlexContainer)`
  align-items: center;
`;

const Welcome = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonsBox = styled.div`
  display: flex;
  align-items: center;

  gap: ${({ theme }) => theme.spacings.small};
`;
