import { ApiErrors } from '../types';
import mkApiErrorMessage from '../utils/mkApiErrorMessage';
import mkHandleApiErrorMessage from '../utils/mkHandleApiErrorMessage';
import { AudienceApiEndpoint, AudienceErrorCode } from './types';

const mkAudienceApiErrors = (endpoint: AudienceApiEndpoint, errorCode: AudienceErrorCode) =>
  mkApiErrorMessage('audience', endpoint, errorCode);

const audienceErrors: ApiErrors<AudienceErrorCode> = {
  ERROR_CANT_EDIT_AUDIENCES: mkAudienceApiErrors('update', 'ERROR_CANT_EDIT_AUDIENCES'),
  ERROR_CAN_ONLY_DELETE_AUDIENCES: mkAudienceApiErrors('delete', 'ERROR_CAN_ONLY_DELETE_AUDIENCES'),
  ERROR_AUDIENCE_NOT_FOUND: mkAudienceApiErrors('get', 'ERROR_AUDIENCE_NOT_FOUND'),
  ERROR_AUDIENCE_ACCESS_DENIED: mkAudienceApiErrors('get', 'ERROR_AUDIENCE_ACCESS_DENIED')
};

export const handleAudienceErrors = mkHandleApiErrorMessage(audienceErrors);
