import { updateTesterProjectInBackgroundThunk } from 'features/aiTester/store/actions/project/thunks/updateTesterProjectInBackgroundThunk';
import { removeText } from 'features/aiTester/store/actions/texts/actions';
import { RemoveTextPayload } from 'features/aiTester/store/actions/texts/types';
import getAudienceModelById from 'features/audiences/utils/getAudienceModelById';
import { trackingWrapper } from 'features/tracking/wrapper/TrackingWrapper';
import { AppThunk } from 'store/store';
import { getCurrentTab } from 'store/utils/tabUtils';

export const removeTextThunk =
  (payload: RemoveTextPayload): AppThunk<void> =>
  async (dispatch, getState) => {
    const state = getState();
    const currentTab = getCurrentTab(state.tester);
    if (!currentTab) {
      return;
    }

    if (payload.source === 'texts') {
      const { texts, generateTextConfig } = currentTab;
      if (!generateTextConfig) {
        return;
      }

      const { audienceId } = generateTextConfig;
      const audienceModelId = getAudienceModelById(state.audiences, audienceId);

      const text = texts.find(t => t.id === payload.id);

      trackingWrapper.track('aiTesterDeleteText', {
        documentId: currentTab.id,
        text: text?.text,
        textItemId: text?.textItemId,
        generationId: text?.generationId,
        audienceModelId
      });
    }

    dispatch(removeText(payload));
    dispatch(updateTesterProjectInBackgroundThunk());
  };
