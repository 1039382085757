export type UrlState = {
  uuid: string;
  newsletterSubscribed?: boolean;
  isPaid?: boolean;
  campaignUrl?: string;
};

export function mkEncodedSocialLoginState({ uuid, newsletterSubscribed, isPaid, campaignUrl }: UrlState) {
  return window.btoa(
    JSON.stringify({
      uuid,
      newsletterSubscribed,
      isPaid,
      campaignUrl
    })
  );
}

export function decodeSocialLoginState(encoded: string): UrlState {
  return JSON.parse(window.atob(encoded));
}
