import {
  FavoriteRounded,
  HistoryRounded,
  KeyboardArrowRightRounded,
  TrendingUpRounded
} from '@mui/icons-material';
import { Divider, ListItemIcon, Menu, MenuItem, PopoverProps } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { TextTypesItemsSubmenu } from 'features/textGenerator/EnhancedOutputTypeAutocomplete/TextTypesItemsSubmenu';
import { CustomCategories } from 'features/textGenerator/outputTypeModal/CategoriesList';
import { useGetCategoriesByLanguageCountry } from 'features/textGenerator/outputTypeModal/OutputTypeModal';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled from 'styled-components';
import { useMenu } from 'utils/hooks/useMenu';

const popoverProps: Partial<PopoverProps> = {
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left'
  },
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'right'
  }
};

type Props = {
  anchorEl: null | HTMLElement;
  isOpen: boolean;
  onMenuClose: () => void;
  onSelect: (value: string, label: string) => void;
  modelLanguage: string;
  modelCountry: string;
  onShowModal?: () => void;
};

export const TextTypesCategoriesMenu = ({
  anchorEl,
  isOpen,
  onMenuClose,
  onSelect,
  modelCountry,
  modelLanguage,
  onShowModal
}: Props) => {
  const [selectedCategoryName, setSelectedCategoryName] = useState<string>();
  const categories = useGetCategoriesByLanguageCountry(modelLanguage, modelCountry);

  const {
    anchorEl: submenuAnchorEl,
    isOpen: isSubmenuOpen,
    onTriggerClick: onSubmenuTriggerClick,
    onMenuClose: onSubmenuClose
  } = useMenu();

  if (!categories) {
    return null;
  }

  const handleCategoryClick = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    category: string
  ) => {
    setSelectedCategoryName(category);
    onSubmenuTriggerClick(event);
  };

  const handleTextTypeClick = (value: string, label: string) => {
    onSelect(value, label);
    onMenuClose();
  };

  const handleTextTypeModalClick = () => {
    onShowModal?.();
    onMenuClose();
  };

  const renderCustomCategories = () => {
    return (
      <div>
        <MenuItem
          onClick={event => handleCategoryClick(event, CustomCategories.MOST_RECENT_CATEGORY)}
          {...withGtmInteraction(gtmIds.textTypesLibrary.mostRecentCategoryClickedInMenu)}
        >
          <ListItemWithIcon>
            <FlexContainer direction="row" alignItems="center">
              <ListItemIcon>
                <HistoryRounded fontSize="small" />
              </ListItemIcon>
              <FormattedMessage id="output_type_categories_list.most_recent" />
            </FlexContainer>
            <KeyboardArrowRightRounded />
          </ListItemWithIcon>
        </MenuItem>
        <MenuItem
          onClick={event => handleCategoryClick(event, CustomCategories.FAVORITES_CATEGORY)}
          {...withGtmInteraction(gtmIds.textTypesLibrary.favoritesCategoryClickedInMenu)}
        >
          <ListItemWithIcon>
            <FlexContainer direction="row" alignItems="center">
              <ListItemIcon>
                <FavoriteRounded fontSize="small" />
              </ListItemIcon>
              <FormattedMessage id="output_type_categories_list.favorites" />
            </FlexContainer>
            <KeyboardArrowRightRounded />
          </ListItemWithIcon>
        </MenuItem>
        <MenuItem
          onClick={event => handleCategoryClick(event, CustomCategories.MOST_POPULAR_CATEGORY)}
          {...withGtmInteraction(gtmIds.textTypesLibrary.trendingCategoryClickedInMenu)}
        >
          <ListItemWithIcon>
            <FlexContainer direction="row" alignItems="center">
              <ListItemIcon>
                <TrendingUpRounded fontSize="small" />
              </ListItemIcon>
              <FormattedMessage id="output_type_categories_list.trending" />
            </FlexContainer>
            <KeyboardArrowRightRounded />
          </ListItemWithIcon>
        </MenuItem>
      </div>
    );
  };

  const renderContent = () => {
    return categories.map(category => (
      <MenuItem key={category.id} onClick={event => handleCategoryClick(event, category.id)}>
        <ListItem>
          {category.label}
          <KeyboardArrowRightRounded />
        </ListItem>
      </MenuItem>
    ));
  };

  return (
    <StyledMenu
      open={isOpen}
      anchorEl={anchorEl}
      onClose={onMenuClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      PaperProps={{
        style: {
          width: 326
        }
      }}
      autoFocus={false}
      disableAutoFocus={true}
      disableEnforceFocus={true}
      disableRestoreFocus={true}
    >
      {renderCustomCategories()}
      <Divider />
      {renderContent()}
      <Divider />
      <MenuItem
        onClick={handleTextTypeModalClick}
        {...withGtmInteraction(gtmIds.textTypesLibrary.openFromMenu)}
      >
        <ListItem>
          <FormattedMessage id="output_type_categories_list.text_types_modal.button" />
        </ListItem>
      </MenuItem>
      {selectedCategoryName && (
        <TextTypesItemsSubmenu
          anchorEl={submenuAnchorEl}
          open={isSubmenuOpen}
          onClose={onSubmenuClose}
          onParentClose={onMenuClose}
          categoryName={selectedCategoryName}
          onTextTypeClick={handleTextTypeClick}
          modelLanguage={modelLanguage}
          modelCountry={modelCountry}
          {...popoverProps}
        />
      )}
    </StyledMenu>
  );
};

const StyledMenu = styled(Menu)`
  width: 50%;
`;

const ListItemWithIcon = styled(FlexContainer).attrs({
  alignItems: 'center',
  direction: 'row',
  justifyContent: 'space-between'
})`
  width: 100%;
`;

const ListItem = styled(FlexContainer).attrs({
  alignItems: 'center',
  direction: 'row',
  justifyContent: 'space-between'
})`
  width: 100%;
  padding-left: ${({ theme }) => theme.spacings.large};
`;
