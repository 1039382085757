import { Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { resetLink } from 'styles/styledComponents/resets';

type Props = {
  id?: string;
  title: string;
  children: ReactNode;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
  menu?: ReactNode;
  footer?: ReactNode;
};

const ProjectCard = ({ id, title, menu, children, footer, onClick }: Props) => {
  return (
    <Root id={id} role="button" onClick={onClick} $hasMenu={!!menu}>
      <Header>
        <Ellipsis variant="subtitle1">{title}</Ellipsis>

        {menu}
      </Header>

      {children}
      {footer && <Footer>{footer}</Footer>}
    </Root>
  );
};

const Root = styled.div<{ $hasMenu?: boolean }>`
  position: relative;
  border: ${({ theme }) => `1px solid ${theme.colors.divider}`};
  border-radius: ${({ theme }) => theme.borderRadius.one};
  transition: ${({ theme }) => `box-shadow ${theme.transition.default}`};

  &:hover {
    box-shadow: ${({ theme }) => theme.shadow.card};
  }
  ${resetLink};

  display: flex;
  flex-direction: column;
  height: 180px;
  max-width: 304px;
  cursor: pointer;

  padding: ${({ theme, $hasMenu }) =>
    $hasMenu
      ? `${theme.spacings.three} 0 ${theme.spacings.three} ${theme.spacings.three}`
      : `${theme.spacings.three}`};
`;

const Header = styled(FlexContainer).attrs({
  gap: 'small',
  direction: 'row',
  alignItems: 'center',
  justifyContent: 'space-between'
})``;

const Ellipsis = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 18rem;
`;

const Footer = styled.div`
  position: absolute;
  bottom: ${({ theme }) => theme.spacings.three};
`;

export default ProjectCard;
