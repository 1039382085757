import { Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { IntlMessageKeys } from 'features/i18n/IntlMessageKeys';
import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { actionableCard } from 'styles/styledComponents/mixins';
import { resetLink } from 'styles/styledComponents/resets';

export enum ClickHandlerType {
  InternalLink,
  ExternalLink,
  Button
}

interface LinkHandler {
  type: ClickHandlerType.InternalLink | ClickHandlerType.ExternalLink;
  url: string;
}

interface ButtonHandler {
  type: ClickHandlerType.Button;
  onClick: () => void;
}

type ClickHandler = ButtonHandler | LinkHandler;

export type Props = {
  title: IntlMessageKeys;
  description: IntlMessageKeys;
  clickHandler: ClickHandler;
  linkId: string;
  badge?: ReactElement;
};

export function FeatureCard({
  title,
  description,
  clickHandler,
  linkId,
  badge
}: Props): ReactElement {
  const renderedContent = (
    <Container>
      <Content gap="small">
        <FlexContainer direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="body1">
            <FormattedMessage
              id={title}
              values={{
                b: text => <strong>{text}</strong>
              }}
            />
          </Typography>
          {badge && <div>{badge}</div>}
        </FlexContainer>
        <Description variant="body1">
          <FormattedMessage id={description} />
        </Description>
      </Content>
    </Container>
  );

  switch (clickHandler.type) {
    case ClickHandlerType.ExternalLink: {
      return (
        <ExternalLinkCard id={linkId} href={clickHandler.url} target="_blank" rel="noreferrer">
          {renderedContent}
        </ExternalLinkCard>
      );
    }
    case ClickHandlerType.InternalLink: {
      return (
        <InternalLinkCard id={linkId} to={clickHandler.url}>
          {renderedContent}
        </InternalLinkCard>
      );
    }
    case ClickHandlerType.Button: {
      return (
        <ButtonCard id={linkId} onClick={clickHandler.onClick}>
          {renderedContent}
        </ButtonCard>
      );
    }
  }
}

const InternalLinkCard = styled(Link)`
  ${resetLink};
  ${actionableCard};
  display: grid;
`;

const ExternalLinkCard = styled.a`
  ${resetLink};
  ${actionableCard};
  display: grid;
`;

const ButtonCard = styled.button`
  all: unset;
  box-sizing: border-box;
  cursor: pointer;
  ${actionableCard};
  display: grid;
`;

const Content = styled(FlexContainer)``;

const Description = styled(Typography)`
  color: ${({ theme }) => theme.colors.blackMediumEmphasis};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${({ theme }) => `${theme.spacings.four} ${theme.spacings.three}`};
`;
