import { WorkspacesRounded } from '@mui/icons-material';
import { Button } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import useUpdateSubscriptionModal from 'features/updateSubscriptionModal/hook/useUpdateSubscriptionModal';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
  id?: string;
  onClick?: () => void;
};

export const PricingUpgradeButton = ({ id, onClick }: Props) => {
  const showUpgradeSubscriptionModal = useUpdateSubscriptionModal();

  // Don't call useCallback() conditionally, therefore it's always defined as a default value
  const defaultCLickHandler = useCallback(
    () => showUpgradeSubscriptionModal(),
    [showUpgradeSubscriptionModal]
  );
  const handleClick = onClick ?? defaultCLickHandler;

  return (
    <Button
      id={id}
      onClick={handleClick}
      variant="contained"
      color="primary"
      startIcon={<WorkspacesRounded />}
    >
      <FlexContainer gap="small" direction="row">
        <FormattedMessage id="common.upgrade" />
      </FlexContainer>
    </Button>
  );
};
