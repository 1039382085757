import {
  ELEMENT_IMAGE,
  ELEMENT_LINK,
  ELEMENT_PARAGRAPH,
  ELEMENT_TABLE,
  ELEMENT_TR,
  TImageElement,
  TLinkElement,
  TTableElement,
  TTableRowElement
} from '@udecode/plate';
import {
  ELEMENT_DEFAULT,
  isElement,
  isText,
  TDescendant,
  TElement,
  TText,
  Value
} from '@udecode/plate-common';
import {
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  ELEMENT_H5,
  ELEMENT_H6
} from '@udecode/plate-heading';
import axios from 'axios';
import {
  AlignmentType,
  convertInchesToTwip,
  Document,
  ExternalHyperlink,
  HeadingLevel,
  ImageRun,
  LevelFormat,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  TextRun,
  WidthType
} from 'docx';
import { EDITOR_IMAGE_WIDTH } from 'features/aiImages/AiImagesPage/useInsertImageToEditor';
import { AiWriterTab } from 'features/aiWriter/store/types';
import { unnamed } from 'features/aiWriter/utils/unnamed';

/**
 * General rules:
 *  - section is a new page
 *  - paragraph is a node
 */

export async function getDocumentAsDocx({ text, name }: AiWriterTab) {
  const imageBuffers = await downloadAllProjectImages(text);

  const bodyContent = convertNodesToDocxSection(text, imageBuffers);
  const fileName = name || unnamed;

  const doc = new Document({
    title: name,
    numbering: {
      config: [
        {
          reference: 'my-numbering',
          levels: [
            {
              level: 0,
              format: LevelFormat.DECIMAL,
              text: '%1.',
              alignment: AlignmentType.START,
              style: {
                paragraph: {
                  indent: { left: convertInchesToTwip(0.5), hanging: convertInchesToTwip(0.25) }
                }
              }
            },
            {
              level: 1,
              format: LevelFormat.DECIMAL,
              text: '%2.',
              alignment: AlignmentType.START,
              style: {
                paragraph: {
                  indent: { left: convertInchesToTwip(1), hanging: convertInchesToTwip(0.25) }
                }
              }
            },
            {
              level: 2,
              format: LevelFormat.DECIMAL,
              text: '%3.',
              alignment: AlignmentType.START,
              style: {
                paragraph: {
                  indent: { left: convertInchesToTwip(1.5), hanging: convertInchesToTwip(0.25) }
                }
              }
            },

            {
              level: 3,
              format: LevelFormat.DECIMAL,
              text: '%4.',
              alignment: AlignmentType.START,
              style: {
                paragraph: {
                  indent: { left: convertInchesToTwip(2), hanging: convertInchesToTwip(0.25) }
                }
              }
            }
          ]
        }
      ]
    },
    sections: [
      {
        properties: {},
        children: bodyContent
      }
    ]
  });

  return { doc, fileName };
}

function convertNodesToDocxSection(text: TElement[], projectImages?: ProjectImages) {
  return text
    .map(node => convertElementToDocx(node, projectImages))
    .filter((content): content is Paragraph | Table => !!content);
}

function convertElementToDocx(
  node: TElement,
  projectImages?: ProjectImages
): Paragraph | Table | undefined {
  switch (node.type) {
    case ELEMENT_H1:
      return renderHeading(node, HeadingLevel.HEADING_1);
    case ELEMENT_H2:
      return renderHeading(node, HeadingLevel.HEADING_2);
    case ELEMENT_H3:
      return renderHeading(node, HeadingLevel.HEADING_3);
    case ELEMENT_H4:
      return renderHeading(node, HeadingLevel.HEADING_4);
    case ELEMENT_H5:
      return renderHeading(node, HeadingLevel.HEADING_5);
    case ELEMENT_H6:
      return renderHeading(node, HeadingLevel.HEADING_6);
    case ELEMENT_DEFAULT:
    case ELEMENT_PARAGRAPH:
    case 'paragraph':
      if ('listStyleType' in node && typeof node.listStyleType === 'string') {
        return renderList(node);
      }
      return renderParagraph(node);
    case ELEMENT_IMAGE:
      return renderImageElement(node as TImageElement, projectImages);
    case ELEMENT_TABLE:
      return renderTable(node, projectImages);
    default:
      return renderParagraph(node);
  }
}

const isTableRowElement = (node: TDescendant): node is TTableRowElement => node.type === ELEMENT_TR;

/**
 *  DXA - twentieths of a point,
 *  is a fixed measurement commonly used in document formats like DOCX.
 *  1px = 15dxa
 * */
function pxToDxa(px: number): number {
  return px * 15;
}

function renderTable(node: TTableElement, projectImages?: ProjectImages) {
  const rows = node.children
    .map(rowNode => {
      if (!isTableRowElement(rowNode)) {
        return undefined;
      }

      const cells = rowNode.children.filter(isElement).map(cellNode => {
        const paragraphs = renderChildren(cellNode, projectImages);

        return new TableCell({
          children: paragraphs
        });
      });

      return new TableRow({
        children: cells
      });
    })
    .filter((row): row is TableRow => row !== undefined);

  return new Table({
    width: {
      // known fact: auto width works in MS Word but no in Google Docs
      size: '100%',
      type: WidthType.AUTO
    },
    columnWidths: node.colSizes?.map(pxToDxa) ?? [],
    rows: rows
  });
}

function renderHeading(node: TElement, level: typeof HeadingLevel[keyof typeof HeadingLevel]) {
  return new Paragraph({
    children: renderChildren(node),
    heading: level
  });
}

function mapIndentLevel(node: TElement): `${number}in` | undefined {
  if (!node.indent || typeof node.indent !== 'number') {
    return undefined;
  }

  // in MS Word the indent as default is moved by 0.5inches
  return `${node.indent * 0.5}in`;
}

function renderParagraph(node: TElement, projectImages?: ProjectImages) {
  return new Paragraph({
    children: renderChildren(node, projectImages),
    alignment: node.align as typeof AlignmentType[keyof typeof AlignmentType],
    indent: {
      start: mapIndentLevel(node)
    }
  });
}

function renderHyperlink(node: TLinkElement) {
  const firstChildren = node.children[0];

  if (!firstChildren || !isText(firstChildren)) {
    return undefined;
  }

  return new ExternalHyperlink({
    children: [
      new TextRun({
        text: firstChildren.text,
        style: 'Hyperlink'
      })
    ],
    link: node.url
  });
}

function mapPlateListLevelToDocx(node: TElement) {
  if (typeof node.indent !== 'number') {
    return 0;
  }

  return node.indent - 1;
}

function renderList(node: TElement, projectImages?: ProjectImages) {
  if (node.listStyleType === 'disc') {
    // bullet points
    return new Paragraph({
      children: renderChildren(node, projectImages),
      bullet: {
        level: mapPlateListLevelToDocx(node)
      }
    });
  }

  return new Paragraph({
    children: renderChildren(node, projectImages),
    numbering: {
      reference: 'my-numbering',
      level: mapPlateListLevelToDocx(node)
    }
  });
}

function renderImageElement(node: TImageElement, projectImages?: ProjectImages) {
  if (!projectImages) {
    return undefined;
  }

  const image = projectImages.filter(image => image.url === node.url)[0];

  if (!image) {
    // eslint-disable-next-line no-console
    console.error(`Image with URL ${node.url} not found in projectImages.`);
    return undefined;
  }

  return new Paragraph({
    children: [
      new ImageRun({
        data: image.arraybuffer,
        transformation: {
          width: image.width,
          height: image.height
        }
      })
    ]
  });
}

function renderChildren(node: TElement, projectImages?: ProjectImages) {
  return node.children
    .map(child => {
      if (isText(child)) {
        return convertTextNodeToDocx(child);
      }

      if (child.type === ELEMENT_LINK) {
        return renderHyperlink(child as TLinkElement);
      }

      return convertElementToDocx(child, projectImages);
    })
    .filter((content): content is Paragraph => !!content);
}

function convertTextNodeToDocx(node: TText) {
  if (node.bold) {
    return new TextRun({
      text: node.text,
      bold: true
    });
  }

  if (node.underline) {
    return new TextRun({
      text: node.text,
      underline: {}
    });
  }

  if (node.italic) {
    return new TextRun({
      text: node.text,
      italics: true
    });
  }

  if (node.highlight) {
    return new TextRun({
      text: node.text,
      highlight: 'yellow'
    });
  }

  return new TextRun({
    text: node.text
  });
}

type ProjectImage = {
  url: string;
  arraybuffer: ArrayBuffer;
  width: number;
  height: number;
};
type ProjectImages = ProjectImage[];

const isString = (value: unknown): value is string => {
  return typeof value === 'string';
};

async function downloadAllProjectImages(document: Value): Promise<ProjectImages | undefined> {
  function collectImageUrls(node: TDescendant, images: Set<string>) {
    if (node.type === ELEMENT_IMAGE && isString(node.url)) {
      images.add(node.url);
    }
    if (Array.isArray(node.children)) {
      node.children.forEach(child => collectImageUrls(child, images));
    }
  }

  const images = new Set<string>();
  document.forEach(node => collectImageUrls(node, images));

  return (
    await Promise.all(
      Array.from(images).map(async url => {
        try {
          const response = await axios.get(url, { responseType: 'arraybuffer' });
          const arraybuffer = response.data;

          // get dimensions from image
          const img = new Image();
          img.src = url;

          const { width, height } = await new Promise<{ width: number; height: number }>(
            (resolve, reject) => {
              img.onload = () => {
                const aspectRatio = img.width / img.height;
                const width = EDITOR_IMAGE_WIDTH;
                const height = EDITOR_IMAGE_WIDTH / aspectRatio;
                resolve({ width, height });
              };
              img.onerror = reject;
            }
          );

          return { url, arraybuffer, width, height };
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(`Failed to fetch image from ${url}:`, error);
          return undefined;
        }
      })
    )
  ).filter((item): item is ProjectImage => item !== undefined);
}
