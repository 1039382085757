import { getGetEmbeddingModelLanguageById } from 'features/embeddingModels/store/selectors';
import { useGetOutputLabel } from 'features/textGenerator/utils/useGetPromptsOthers';
import { useCallback } from 'react';
import { useAppSelector } from 'store/hooks';
import useTr from 'utils/hooks/useTr';

export default function useMkProjectName() {
  const tr = useTr();

  const getOutputLabel = useGetOutputLabel();
  const getModelLanguageById = useAppSelector(getGetEmbeddingModelLanguageById);

  return useCallback(
    (embeddingModelId: string, outputType: string) => {
      const modelLanguage = getModelLanguageById(embeddingModelId);
      const outputTypeLabel = getOutputLabel(modelLanguage, outputType);

      return outputTypeLabel ?? tr('common.unnamed');
    },
    [tr, getModelLanguageById, getOutputLabel]
  );
}
