import { getRoutePath } from 'config/routes';
import { getIsGenerateConfigReady } from 'features/aiWriter/store/selectors';
import { AiWriterSidebarStep, InspirationsExtendedStep } from 'features/aiWriter/store/types';
import { useAppSelector } from 'store/hooks';
import { useCurrentRoutePath } from 'utils/hooks/useCurrentRoutePath';

export function useCalculateInspirationsStep(): (
  step: AiWriterSidebarStep | undefined
) => InspirationsExtendedStep | undefined {
  const pathname = useCurrentRoutePath();
  const isConfigReady = useAppSelector(getIsGenerateConfigReady);

  const isBlogPostBuilderRoute = () => pathname === getRoutePath('blogPostWorkflow');

  return (step: AiWriterSidebarStep | undefined) => {
    switch (step) {
      case AiWriterSidebarStep.aiWriter: {
        if (!isConfigReady) {
          return { step, subStep: 'editBrief' };
        }
        if (isBlogPostBuilderRoute()) {
          return { step, subStep: 'blogPost' };
        }
        return { step, subStep: 'main' };
      }
      case AiWriterSidebarStep.images: {
        return { step, subStep: 'aiImages' };
      }
      case AiWriterSidebarStep.seoAnalysis:
      case AiWriterSidebarStep.templates:
      case AiWriterSidebarStep.plagiarism:
      case AiWriterSidebarStep.chat:
      case AiWriterSidebarStep.flashScore: {
        return { step, subStep: 'main' };
      }
      default:
        return undefined;
    }
  };
}
