import profileIcon from 'assets/icon-profile.svg';
import { MenuDropdownItem } from 'components/MenuDropdown/MenuDropdownItem';
import { getRoutePath } from 'config/routes';
import { NavLink } from 'features/pageSidebar/sidebarItems/actions/account/UnstyledLink';
import { forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';
import gtmIds from 'services/tracking/GTMIds';

type Props = {
  onClose: () => void;
};

const ProfileItem = forwardRef<HTMLAnchorElement, Props>(({ onClose }, ref) => (
  <NavLink to={getRoutePath('profileInfo')} ref={ref}>
    <MenuDropdownItem
      gtmId={gtmIds.sidebar.accountMenuItem}
      icon={<img src={profileIcon} alt="profile" />}
      onClose={onClose}
    >
      <FormattedMessage id="layout.sidebar.actions.profile.profile" />
    </MenuDropdownItem>
  </NavLink>
));

export default ProfileItem;
