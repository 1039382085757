import { ClientRectObject, getDefaultBoundingClientRect } from '@udecode/plate';
import { PortalBody, toDOMRange, useComposedRef, Value } from '@udecode/plate-common';
import {
  flip,
  FloatingToolbarState,
  useFloatingToolbar,
  useFloatingToolbarState
} from '@udecode/plate-floating';
import useEditor from 'features/aiWriter/AiWriterTextEditor/hooks/useEditor';
import { Toolbar, ToolbarProps } from 'features/plate/components/plate-ui/toolbar';
import { EditorWithPrevSelection } from 'features/plate/customPlugins/createFakeSelectionPlugin';
import { ElementRef, forwardRef } from 'react';
import { Range } from 'slate';
import styled from 'styled-components';

const getBoundingClientRectFromSelection = (editor: EditorWithPrevSelection<Value>) => {
  const defaultRect = getDefaultBoundingClientRect();

  return (): ClientRectObject => {
    const selection = editor.selection || editor.prevSelection;
    if (!selection) {
      return defaultRect;
    }

    // The original code aligns the toolbar to the browser getSelection() method
    // https://github.com/udecode/plate/blob/dd4c210be402011cf44b24ec04da19832fb9833e/packages/floating/src/utils/getSelectionBoundingClientRect.ts

    // This code aligns the toolbar to the Slate selection because this stays
    // even if the editor doesn't have focus

    return toDOMRange(editor, selection as Range)?.getBoundingClientRect() || defaultRect;
  };
};

export interface FloatingToolbarProps extends ToolbarProps {
  state?: FloatingToolbarState;
}

const FloatingToolbar = forwardRef<ElementRef<typeof Toolbar>, FloatingToolbarProps>(
  ({ state, children, ...props }, componentRef) => {
    const editor = useEditor();
    const floatingToolbarState = useFloatingToolbarState({
      ...state,
      floatingOptions: {
        placement: 'bottom',
        middleware: [
          flip({
            fallbackPlacements: ['top']
          })
        ],
        ...state?.floatingOptions,
        getBoundingClientRect: getBoundingClientRectFromSelection(editor)
      }
    });

    const { ref: floatingRef, props: rootProps, hidden } = useFloatingToolbar(floatingToolbarState);

    const ref = useComposedRef<HTMLDivElement>(componentRef, floatingRef);

    if (hidden) {
      return null;
    }

    return (
      <PortalBody>
        <StyledToolbar ref={ref} {...rootProps} {...props}>
          {children}
        </StyledToolbar>
      </PortalBody>
    );
  }
);
FloatingToolbar.displayName = 'FloatingToolbar';

const StyledToolbar = styled(Toolbar)`
  border-radius: ${({ theme }) => theme.borderRadius.one};
  padding: ${({ theme }) => `${theme.spacings.one} ${theme.spacings.four}`};
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: ${({ theme }) => theme.colors.white};
  // make sure we cover other plate's components
  z-index: 999;
`;

export { FloatingToolbar };
