import { FavoriteBorderRounded, FavoriteRounded } from '@mui/icons-material';
import { Divider, IconButton, Menu, MenuItem, MenuProps } from '@mui/material';
import { changeOutputTypeThunk } from 'features/aiWriter/store/actions/config/thunks/changeOutputTypeThunk';
import { useAdvancedOptions } from 'features/textGenerator/EnhancedOutputTypeAutocomplete/useAdvancedOptions';
import { CustomCategories } from 'features/textGenerator/outputTypeModal/CategoriesList';
import { OutputTypeOption } from 'features/textGenerator/outputTypeModal/OutputTypesList';
import { useFavoriteOutputType } from 'features/textGenerator/outputTypeModal/useFavoriteOutputType';
import { useOutputTypesSuggestionIds } from 'features/textGenerator/outputTypeModal/useGetOutputTypesSuggestions';
import { useShowOutputTypeModal } from 'features/textGenerator/outputTypeModal/useShowOutputTypeModal';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';

type Props = MenuProps & {
  onTextTypeClick: (value: string, label: string) => void;
  categoryName: string;
  modelLanguage: string;
  modelCountry: string;
  searchQuery?: string;
  onParentClose?: () => void;
};

export const TextTypesItemsSubmenu = ({
  categoryName,
  modelCountry,
  modelLanguage,
  onTextTypeClick,
  searchQuery,
  onParentClose,
  ...props
}: Props) => {
  const dispatch = useAppDispatch();
  const showOutputTypeModal = useShowOutputTypeModal();

  const suggestedIdsResult = useOutputTypesSuggestionIds();
  const outputTypeSuggestionsIds = suggestedIdsResult.data ?? {
    frequentlyUsed: [],
    mostPopular: [],
    favorite: [],
    mostRecent: []
  };

  const favoriteOutputType = useFavoriteOutputType();

  const advancedOptions = useAdvancedOptions({
    selectedCategory: categoryName,
    query: searchQuery ?? '',
    modelLanguage,
    modelCountry,
    outputTypeSuggestionsIds
  });

  const handleItemClick = (item: OutputTypeOption) => {
    onTextTypeClick(item.id, item.label);
    props?.onClose?.({}, 'backdropClick');
  };

  const handleFavoriteClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: string,
    value: boolean
  ) => {
    event.stopPropagation();
    favoriteOutputType.mutate(
      {
        outputType: id,
        is_favorite: value
      },
      {
        onSuccess: () => {
          suggestedIdsResult.refetch();
        }
      }
    );
  };

  // decided by product team that we want to show only first 5 items in favorites category
  const optionsToDisplay =
    categoryName === CustomCategories.FAVORITES_CATEGORY
      ? advancedOptions.slice(0, 5)
      : advancedOptions;

  const renderOptions = () => {
    return (
      <>
        {optionsToDisplay.map(option => (
          <StyledMenuItem key={option.id} onClick={() => handleItemClick(option)}>
            {option.label}
            <IconButton
              onClick={e => handleFavoriteClick(e, option.id, !option.isFavorite)}
              size="small"
            >
              {option.isFavorite ? (
                <FavoriteRounded fontSize="small" />
              ) : (
                <FavoriteBorderRounded fontSize="small" className="favorite" />
              )}
            </IconButton>
          </StyledMenuItem>
        ))}
        {categoryName === CustomCategories.FAVORITES_CATEGORY && (
          <>
            <Divider />
            <StyledMenuItem
              key={CustomCategories.FAVORITES_CATEGORY}
              onClick={() => {
                props?.onClose?.({}, 'backdropClick');
                onParentClose?.();
                showOutputTypeModal({
                  initCategory: CustomCategories.FAVORITES_CATEGORY,
                  modelCountry: modelCountry,
                  modelLanguage: modelLanguage,
                  onOutputTypeChange: value => {
                    dispatch(changeOutputTypeThunk(value));
                  }
                });
              }}
            >
              <FormattedMessage id="output_type_categories_list.submenu.open_favorites" />
            </StyledMenuItem>
          </>
        )}
      </>
    );
  };

  return (
    <Menu
      {...props}
      PaperProps={{
        style: {
          width: 326
        }
      }}
      autoFocus={false}
      disableAutoFocus={true}
    >
      {advancedOptions.length === 0 ? (
        <MenuItem disabled style={{ whiteSpace: 'normal' }}>
          <FormattedMessage
            id={
              categoryName === CustomCategories.MOST_RECENT_CATEGORY
                ? `output_type_modal.no_options_placeholder.${categoryName}`
                : 'common.autocomplete.no_results'
            }
          />
        </MenuItem>
      ) : (
        renderOptions()
      )}
    </Menu>
  );
};

const StyledMenuItem = styled(MenuItem)`
  display: flex;
  justify-content: space-between;
  .favorite {
    visibility: hidden;
  }

  &:hover .favorite {
    visibility: visible;
  }
`;
