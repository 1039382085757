import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { IconButton, Tooltip } from '@mui/material';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { GAEvents } from 'services/tracking/GAEvents';
import styled from 'styled-components';

type Props = {
  isExpanded: boolean;
  onExpand: () => void;
  onShrink: () => void;
};

export const ExpandSidebarButton = ({ isExpanded, onExpand, onShrink }: Props) => {
  const handleExpandClick = () => {
    GAEvents.sidebarExpand();
    onExpand();
  };

  const handleShrinkClick = () => {
    GAEvents.sidebarShrink();
    onShrink();
  };

  return isExpanded ? (
    <Tooltip title={<FormattedMessage id="aiWriter.inspirations.chat.shrink_chat" />}>
      <IconButton onClick={handleShrinkClick} size="small">
        <StyledShrinkIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  ) : (
    <Tooltip title={<FormattedMessage id="aiWriter.inspirations.chat.expand_chat" />}>
      <IconButton onClick={handleExpandClick} size="small">
        <StyledExpandIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};

const StyledExpandIcon = styled(FullscreenIcon)`
  color: ${({ theme }) => theme.colors.componentsIconActive};
  :hover {
    cursor: pointer;
  }
`;
const StyledShrinkIcon = styled(FullscreenExitIcon)`
  color: ${({ theme }) => theme.colors.componentsIconActive};
  :hover {
    cursor: pointer;
  }
`;
