import { Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

type Props = { name?: string };

export const TimeGreeting = ({ name }: Props) => {
  const currentHour = new Date().getHours();
  let dayPart = 'evening';

  if (currentHour < 12) {
    dayPart = 'morning';
  } else if (currentHour < 18) {
    dayPart = 'afternoon';
  }

  return (
    <Typography variant="h6">
      <FormattedMessage id={`time_greeting.${dayPart}`} />
      &nbsp;
      {name ?? ''}
    </Typography>
  );
};
