import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLoginButton } from 'features/social/GoogleLogin/GoogleLoginButton';

type Props = {
  formName: 'login' | 'signup';
  newsletterSubscribed?: boolean;
  isPaid?: boolean;
  campaignUrl?: string;
};

export const GoogleLogin = ({ formName, newsletterSubscribed, isPaid, campaignUrl }: Props) => (
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_SOCIAL_LOGIN_CLIENT_ID}>
    <GoogleLoginButton
      formName={formName}
      newsletterSubscribed={newsletterSubscribed}
      isPaid={isPaid}
      campaignUrl={campaignUrl}
    />
  </GoogleOAuthProvider>
);
