import Toast from 'components/toasts/Toast';
import { aiWriterHistoryFromApi } from 'features/aiWriter/store/utils/mappers/aiWriterHistoryMappers';
import HistoryAPI from 'services/api/history';
import { handleApiCommonErrors } from 'services/api/utils/handleApiCommonErrors';
import { AppThunk } from 'store/store';
import { getCurrentTab } from 'store/utils/tabUtils';

import { fetchHistory } from '../actions';

export const fetchHistoryThunk = (): AppThunk<void> => async (dispatch, getState) => {
  dispatch(fetchHistory.request());

  const { aiWriter } = getState();
  const currentTab = getCurrentTab(aiWriter);
  if (!currentTab) {
    return;
  }

  const { id: projectId } = currentTab;

  try {
    const response = await HistoryAPI.getByProjectId(projectId);

    if (response.status) {
      dispatch(fetchHistory.success(response.data.map(aiWriterHistoryFromApi)));
    } else {
      Toast.backendError(handleApiCommonErrors(response.data.message));
    }
  } catch (e) {
    dispatch(fetchHistory.failure());
    Toast.commonError();
  }
};
