import {
  keywords2Field,
  keywordsField,
  textBodyField
} from 'features/aiTester/modals/setup/SetupModal';
import { getGetEmbeddingModelLanguageAndCountryById } from 'features/embeddingModels/store/selectors';
import { useGetPrefillValueByLanguageCountryAndOutputType } from 'features/textGenerator/utils/useGetPromptsOthers';
import { useSelector } from 'react-redux';
import { SetFieldValue } from 'types/Formik';

export default function useHandleOutputTypeChange() {
  const getModelLanguageCountryById = useSelector(getGetEmbeddingModelLanguageAndCountryById);

  const getPrefillValue = useGetPrefillValueByLanguageCountryAndOutputType();

  return (outputType: string, embeddingModelId: string, setFieldValue: SetFieldValue) => {
    const { language: modelLanguage, country: modalCountry } =
      getModelLanguageCountryById(embeddingModelId);

    setFieldValue(textBodyField, getPrefillValue(modelLanguage, modalCountry, outputType, 'text'));
    setFieldValue(
      keywordsField,
      getPrefillValue(modelLanguage, modalCountry, outputType, 'keywords')
    );
    setFieldValue(
      keywords2Field,
      getPrefillValue(modelLanguage, modalCountry, outputType, 'keywords2')
    );
  };
}
