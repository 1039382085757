import AttachFileIcon from '@mui/icons-material/AttachFile';
import { IconButton } from '@mui/material';
import { useShowApplyInformationModal } from 'features/information/apply-information/ApplyInformationModal';
import { InformationDto } from 'services/backofficeIntegration/http/endpoints/infomration/httpGetInformationList';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled from 'styled-components';

type Props = {
  value: InformationDto[] | undefined;
  onChange: (informationList: InformationDto[]) => void;
};

export function InformationButton(props: Props) {
  const showApplyInformationModal = useShowApplyInformationModal();

  const informationCount = props.value?.length ?? 0;

  const handleClick = () => {
    showApplyInformationModal({
      preselectedInformation: props.value,
      applyGtmId: gtmIds.aiWriter.projectOverview.chat.applyInformation,
      onApply: informationList => props.onChange(informationList)
    });
  };

  return (
    <StyledButton
      {...withGtmInteraction(gtmIds.aiWriter.projectOverview.chat.openInformationModal)}
      onClick={handleClick}
    >
      <AttachFileIcon />

      <Indicator>{informationCount}</Indicator>
    </StyledButton>
  );
}

const StyledButton = styled(IconButton)`
  position: relative;

  cursor: pointer;
  border: none;
  color: ${({ theme }) => theme.colors.componentsIconActive};

  &:hover {
    border: none;
    background-color: unset;
  }
`;

const Indicator = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  border-radius: 100%;
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  font-size: 0.5rem;

  width: 0.8rem;
  height: 0.8rem;

  display: flex;
  justify-content: center;
  align-items: center;
`;
