import useRedirectWhenAuthenticated from 'components/auth/hooks/useRedirectWhenAuthenticated';
import { RecaptchaProvider } from 'components/RecaptchaProvider';
import { FreeTrialPanel } from 'components/register/FreeTrialPanel';
import React from 'react';

export const FreeTrialPage = (): React.ReactElement => {
  useRedirectWhenAuthenticated();

  return (
    <RecaptchaProvider>
      <FreeTrialPanel />
    </RecaptchaProvider>
  );
};
