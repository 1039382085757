import { useMutation } from '@tanstack/react-query';
import Toast from 'components/toasts/Toast';
import { getErrorCodeFromAxiosError } from 'features/teamInvitation/getErrorCodeFromAxiosError';
import { handleApiCommonErrors } from 'services/api/utils/handleApiCommonErrors';
import {
  httpResendInvitation,
  ResendInvitationParams
} from 'services/backofficeIntegration/http/endpoints/teamMembers/httpResendInvitation';

export const useResendInvitation = () =>
  useMutation({
    mutationFn: (params: ResendInvitationParams) => httpResendInvitation.callEndpoint(params),
    onSuccess: data => {
      if (data.message === 'BE.team.too_many_requests') {
        Toast.error('profile.users.invite_user.resend.error.too_many_requests');
      } else if (data.data.status) {
        Toast.success('profile.users.invite_user.resend.success');
      }
    },
    onError: err => {
      const errorCode = getErrorCodeFromAxiosError(err);
      if (errorCode) {
        Toast.backendError(handleApiCommonErrors(errorCode));
      } else {
        Toast.error('profile.users.invite_user.resend.error.generic');
      }
    }
  });
