import { getCurrentLanguage } from 'features/language/store/selectors';
import { AppLanguage } from 'features/language/store/types';
import { planConfigs } from 'features/pricing/planConfigs';
import { PricingPlan } from 'features/pricing/types';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppSelector } from 'store/hooks';

function makeFormatter(language: AppLanguage) {
  return new Intl.NumberFormat(language, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });
}

type Props = {
  planType: PricingPlan;
  skipUnit?: boolean;
};

export function FormattedWordLimit({ planType, skipUnit = false }: Props) {
  const language = useAppSelector(getCurrentLanguage);

  const { wordsLimit, imagesLimit } = planConfigs[planType];

  if (Number.isFinite(wordsLimit)) {
    const formatter = makeFormatter(language);
    const formattedLimit = formatter.format(wordsLimit);

    if (skipUnit) {
      return <span>{formattedLimit}</span>;
    }

    if (imagesLimit) {
      const formattedImageLimit = formatter.format(imagesLimit);
      return (
        <FormattedMessage
          id="profile.plan.words_month"
          values={{
            limit: formattedLimit,
            imagesLimit: formattedImageLimit,
            b: (chunks: ReactNode) => <b>{chunks}</b>
          }}
          tagName="span"
        />
      );
    }

    return (
      <FormattedMessage
        id="profile.plan.words_month"
        values={{
          limit: formattedLimit,
          b: (chunks: ReactNode) => <b>{chunks}</b>
        }}
        tagName="span"
      />
    );
  }

  return (
    <FormattedMessage
      id="profile.plan.unlimited_writing"
      values={{
        b: (chunks: ReactNode) => <b>{chunks}</b>
      }}
    />
  );
}
