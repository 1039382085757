import { composeRefs, insertText, select, usePlateLeaf } from '@udecode/plate';
import { createSlotComponent, EText, PlateLeafProps, TText, Value } from '@udecode/plate-common';
import { FlashScoreEmotionalitySynonymsOutput } from 'features/flashScore/FlashScoreEmotionalitySynonymsOutput';
import { clearFakeSelection } from 'features/plate/customPlugins/createFakeSelectionPlugin';
import { EmotionalityLeafProps } from 'features/plate/customPlugins/createFlashScoreHighlightPlugin';
import { forwardRef, MouseEvent, ReactElement, RefAttributes, useRef, useState } from 'react';
import { GAEvents } from 'services/tracking/GAEvents';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled from 'styled-components';

type Props = PlateLeafProps<Value, TText & EmotionalityLeafProps>;

export const FlashScoreEmotionalityLeaf = forwardRef<HTMLSpanElement, Props>((props, ref) => {
  const {
    ref: rootRef,
    props: { onClick, ...rootProps }
  } = usePlateLeaf({ ...props, ref });

  const localRef = useRef<HTMLElement>(null);

  const [showFlashScorePopper, setShowFlashScorePopper] = useState(false);

  const handleNodeClick = (event: MouseEvent) => {
    event.preventDefault();

    setShowFlashScorePopper(prevState => !prevState);
    onClick?.();
  };

  const handleSynonymClick = (synonym: string) => {
    const editor = props.editor;

    GAEvents.flashScoreHighlightedWordReplace({ word: props.leaf.text, synonym });

    // Select the text under this node
    select(editor, props.leaf.range);
    // Insert the synonym
    insertText(editor, synonym);

    // Clear potential fake selection because the editor doesn't have the focus
    clearFakeSelection(editor);

    setShowFlashScorePopper(false);
  };

  const handleClose = () => {
    setShowFlashScorePopper(false);
  };

  /**
   * Usually you wanna use PlateElement or PlateLeaf and style the inner things.
   * But I had to style the actual plate element/node.
   * So I use a styled component with a ref to style the root element.
   *
   * To make React happy, we have to pass through the ref from forwardRef.
   * To make Plate happy, we have to pass through the ref from usePlateLeaf.
   * And as we need a ref for anchoring the popper, we have to use a local ref.
   * Therefore, we're using composeRefs to merge all refs.
   */

  return (
    <>
      <StyledRoot
        {...rootProps}
        {...withGtmInteraction(gtmIds.aiWriter.flashScore.emotionalityHighlightedWord)}
        onClick={handleNodeClick}
        ref={composeRefs(localRef, rootRef, ref)}
      />

      {showFlashScorePopper && localRef.current && (
        <FlashScoreEmotionalitySynonymsOutput
          onClose={handleClose}
          onSynonymClick={handleSynonymClick}
          nodeText={props.leaf.text}
          emotionalityRating={props.leaf.emotionality}
        />
      )}
    </>
  );
}) as (<V extends Value = Value, N extends TText = EText<V>>({
  className,
  ...props
}: PlateLeafProps<V, N> & RefAttributes<HTMLSpanElement>) => ReactElement) & {
  displayName?: string;
};
FlashScoreEmotionalityLeaf.displayName = 'FlashScoreEmotionalityLeaf';

const StyledRoot = styled(createSlotComponent('span'))`
  border-radius: ${({ theme }) => theme.borderRadius.small};
  padding: 1px;

  cursor: pointer;
`;
