import FlexContainer from 'components/FlexContainer';
import { planConfigs } from 'features/pricing/planConfigs';
import { PricingPlan } from 'features/pricing/types';
import { ReactElement } from 'react';
import styled from 'styled-components';

import PlanPerkSublist from './PlanPerkSublist';

interface Props {
  planType: PricingPlan;
}

const Root = styled(FlexContainer)`
  margin: 1rem 0;
`;

const PlanPerkList = ({ planType }: Props): ReactElement => {
  const { featurePerksAmount, requestPerksAmount } = planConfigs[planType];

  return (
    <Root gap="large">
      <PlanPerkSublist planType={planType} listType="request" perksAmount={requestPerksAmount} />

      <PlanPerkSublist
        planType={planType}
        header="profile.plan.perk_list"
        listType="feature"
        perksAmount={featurePerksAmount}
      />
    </Root>
  );
};

export default PlanPerkList;
