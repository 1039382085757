import { Button, CircularProgress, Typography } from '@mui/material';
import type { Location } from '@remix-run/router';
import FlexContainer from 'components/FlexContainer';
import Toast from 'components/toasts/Toast';
import { getRoutePath } from 'config/routes';
import { savePersonalityId } from 'features/aiWriter/AiWriterSidebar/steps/chat/chatStore';
import { PERSONALITY_DEFINITION_MAX_WORDS_LIMIT } from 'features/aiWriter/AiWriterSidebar/steps/chat/PersonalityAutomaticCreationView';
import { useCreateChatPersonality } from 'features/aiWriter/AiWriterSidebar/steps/chat/useCreateChatPersonality';
import { invalidatePersonalitiesQuery } from 'features/aiWriter/AiWriterSidebar/steps/chat/usePersonalitiesQuery';
import { useSetDefaultChatPersonality } from 'features/aiWriter/AiWriterSidebar/steps/chat/useSetDefaultPersonality';
import { SharingPermission } from 'features/aiWriter/store/types';
import { fallbackModelCountry } from 'features/aiWriter/store/utils/defaults/fallbackModelCountry';
import { fallbackModelLanguage } from 'features/aiWriter/store/utils/defaults/fallbackModelLanguage';
import { useCustomerPreferences } from 'features/customerPreferences/useCustomerPreferences';
import { useCompleteTask } from 'features/onboardingJourney/checklist/useOnboardingChecklist';
import { MobileOnboardingFooter } from 'features/onboardingJourney/MobileFooter';
import { OnboardingPersonalityCreationLocationState } from 'features/onboardingJourney/OnboardingPersonalityCreation';
import { Footer, NextButton, OnboardingRoot } from 'features/onboardingJourney/OnboardingRoot';
import { OnboardingStepper } from 'features/onboardingJourney/OnboardingStepper';
import { useIsDesktop } from 'features/onboardingJourney/useIsDesktop';
import { useDisableSidebarOnMount } from 'features/pageSidebar/useSidebarStore';
import { isObject } from 'lodash';
import { useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router';
import { TASK_TYPE } from 'services/backofficeIntegration/http/endpoints/onboardingChecklist/httpGetChecklist';
import { GAEvents } from 'services/tracking/GAEvents';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled, { css } from 'styled-components';
import { getWordCount } from 'utils/getWordCount';
import { useTimeout } from 'utils/hooks/useTimeout';
import useTr from 'utils/hooks/useTr';
import useWindowSize from 'utils/hooks/useWindowSize';

const showConfettiSeconds = 2;

export const OnboardingPersonalityFinished = () => {
  const tr = useTr();
  const navigate = useNavigate();
  const { state: locationState } =
    useLocation() as Location<OnboardingPersonalityCreationLocationState>;
  const { width, height } = useWindowSize();
  const [showConfetti, setShowConfetti] = useState(true);
  const isDesktop = useIsDesktop();

  useDisableSidebarOnMount();

  useEffect(() => {
    if (
      !locationState ||
      !isObject(locationState) ||
      !locationState.personalityText ||
      !locationState.personalityLanguage ||
      !locationState.personalityCountry
    ) {
      navigate(getRoutePath('onboardingSelection'));
    }
  }, [locationState, navigate]);

  useTimeout(() => setShowConfetti(false), showConfettiSeconds * 1000);

  const [personality] = useState(locationState?.personalityText || '...');

  const textWordCount = getWordCount(personality);

  const isInputError = textWordCount > PERSONALITY_DEFINITION_MAX_WORDS_LIMIT;
  const isInputValid = isInputError;

  const { mutateAsync: createPersonality, isLoading: creationInProgress } =
    useCreateChatPersonality();

  const completePersonalityTask = useCompleteTask(TASK_TYPE.CREATE_PERSONALITY);
  const { mutate: setDefaultPersonality } = useSetDefaultChatPersonality();

  const { data: customerPreferences } = useCustomerPreferences();
  const preferredLanguage = customerPreferences?.preferredLanguage ?? fallbackModelLanguage;
  const preferredCountry = customerPreferences?.preferredLanguageCountry ?? fallbackModelCountry;

  const handleNextClick = async () => {
    GAEvents.onboardingPersonalitySaveClick();
    await createPersonality(
      {
        language: locationState.personalityLanguage ?? preferredLanguage,
        country: locationState.personalityCountry ?? preferredCountry,
        sharing_permission: SharingPermission.private,
        label: tr('onboarding.personality_creation.label.field'),
        description: tr('onboarding.personality_creation.description.field'),
        definition: personality,
        is_active: true
      },
      {
        onSuccess: data => {
          completePersonalityTask();
          invalidatePersonalitiesQuery();
          savePersonalityId(data.id);
          setDefaultPersonality({
            language: customerPreferences?.preferredLanguage ?? fallbackModelLanguage,
            country: customerPreferences?.preferredLanguageCountry ?? fallbackModelCountry,
            personalityId: data.id
          });
        },
        onError: () => Toast.apiError()
      }
    );
    navigate(getRoutePath('onboardingSelection'));
  };

  const trackResultClick = () => {
    GAEvents.onboardingPersonalityResultInputClick();
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <OnboardingRoot
      title={tr('onboarding.personality_creation.finished.title')}
      subtitle={tr('onboarding.personality_creation.finished.subtitle')}
      stepper={<OnboardingStepper currentStep={2} />}
    >
      <PersonalityInput>
        <GenerationOutputBox onClick={trackResultClick}>
          <Typography variant="body1">{personality}</Typography>
        </GenerationOutputBox>

        <CounterBox $isError={isInputError}>
          <Typography variant="caption">
            {textWordCount}/{PERSONALITY_DEFINITION_MAX_WORDS_LIMIT}
          </Typography>
          {isInputError && (
            <Typography variant="caption">
              <FormattedMessage id="aiWriter.inspirations.chat.personalities.library_modal.automatic_creation.input_column.error" />
            </Typography>
          )}
        </CounterBox>
      </PersonalityInput>

      {isDesktop && (
        <Footer direction="column">
          <NextButton
            {...withGtmInteraction(gtmIds.personalitiesLibrary.personalityGenerated)}
            variant="contained"
            color="primary"
            onClick={handleNextClick}
            disabled={isInputValid}
            fullWidth={true}
          >
            {creationInProgress ? (
              <CircularProgress size={20} />
            ) : (
              <FormattedMessage id="onboarding.personality_creation.finished.next.button" />
            )}
          </NextButton>
          <Button
            onClick={handleBackClick}
            disabled={isInputValid || creationInProgress}
            fullWidth={true}
          >
            <FormattedMessage id="common.back" />
          </Button>
        </Footer>
      )}

      <Confetti width={width} height={height} numberOfPieces={1000} recycle={showConfetti} />

      {!isDesktop && (
        <MobileOnboardingFooter
          buttons={[
            <NextButton
              {...withGtmInteraction(gtmIds.onboardingJourney.survey.next)}
              color="primary"
              variant="contained"
              onClick={handleNextClick}
              disabled={isInputValid}
              fullWidth={true}
              key="nextButton"
            >
              {creationInProgress ? (
                <CircularProgress size={20} />
              ) : (
                <FormattedMessage id="onboarding.personality_creation.finished.next.button" />
              )}
            </NextButton>,
            <Button
              onClick={handleBackClick}
              disabled={isInputValid}
              fullWidth={true}
              key="skipButton"
            >
              <FormattedMessage id="common.back" />
            </Button>
          ]}
          step={2}
        />
      )}
    </OnboardingRoot>
  );
};

const PersonalityInput = styled(FlexContainer)`
  max-width: 400px;
  width: 100%;
`;

const INPUT_OUTPUT_HEIGHT = '13rem';

const OutputBox = css`
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  background-color: ${({ theme }) => theme.colors.surfaceCard};

  /* needed to add padding from textField to match height */
  height: calc(${INPUT_OUTPUT_HEIGHT} + 2 * 16.5px);
`;

const GenerationOutputBox = styled.div`
  ${OutputBox};
  overflow-y: auto;
  padding: ${({ theme }) => theme.spacings.medium};
`;

const CounterBox = styled.div<{ $isError: boolean }>`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;

  color: ${({ theme, $isError }) => $isError && theme.colors.danger};
`;
