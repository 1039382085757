import { Paper, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { insertText } from '@udecode/plate-common';
import { textGeneratorTips, TipLoader } from 'components/TipLoader';
import RewriteSetupStep, {
  RewriteFormValues
} from 'features/aiWriter/AiWriterTextEditor/EditorTopToolbar/RewriteSetupStep';
import useEditor from 'features/aiWriter/AiWriterTextEditor/hooks/useEditor';
import useGenerateAiWriterTexts from 'features/aiWriter/AiWriterTextEditor/hooks/useGenerateAiWriterTexts';
import { FlashActionsInputProps } from 'features/aiWriter/slashCommands/FlashActionsInput';
import {
  useFlashActionsSelectedText,
  useFlashActionsSelection
} from 'features/aiWriter/slashCommands/hooks/useFlashActionsStore';
import { RewriteActionSuggestion } from 'features/aiWriter/slashCommands/RewriteActionSuggestion';
import { getActiveTab, getCurrentModelLanguageAndCountry } from 'features/aiWriter/store/selectors';
import { TextInspiration } from 'features/aiWriter/store/types';
import {
  useShouldShowWordsLimitReachedModal,
  useShowWordsLimitReachedModal
} from 'features/pricing/hook/useYouRunOutOfWordsModal';
import { generatedTextsFromGenerateTextOutputEntries } from 'features/textGenerator/utils/generateTextEntryMapper';
import { trackingWrapper } from 'features/tracking/wrapper/TrackingWrapper';
import { ReactElement, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { GAEvents } from 'services/tracking/GAEvents';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';

const maxSelectedTextLength = 7000;

type RewriteStep = 'setup' | 'suggestions';

type Props = FlashActionsInputProps;

export const RewriteActionOutput = (props: Props): ReactElement => {
  const flashActionsSelectedText = useFlashActionsSelectedText();

  const editor = useEditor();
  const flashActionsSelection = useFlashActionsSelection();

  const [suggestions, setSuggestions] = useState<TextInspiration[]>([]);
  const [currentStep, setCurrentStep] = useState<RewriteStep>('setup');

  const generateTexts = useGenerateAiWriterTexts();

  const { shouldShowRunOutOfWordsModal } = useShouldShowWordsLimitReachedModal();
  const onlyShowModal = useShowWordsLimitReachedModal();

  const { generateTextConfig, id: tabId } = useAppSelector(getActiveTab);

  const { currentModelCountry: country, currentModelLanguage: language } = useAppSelector(
    getCurrentModelLanguageAndCountry
  );

  const { mutate: fetchSuggestions, isLoading: isSuggestionsLoading } = useMutation({
    mutationFn: ({
      text,
      systemTonality,
      userTonality
    }: {
      text: string;
      systemTonality: string[];
      userTonality: string[];
    }) => {
      trackingWrapper.track('aiWriterToolbarRewrite', {
        documentId: tabId,
        text: flashActionsSelectedText,
        audienceModelId: generateTextConfig.audienceId
      });

      GAEvents.flashActionExecuted({
        action: 'rewrite',
        language,
        country,
        isInline: true
      });

      return generateTexts({
        text,
        outputType: 'rewrite',
        systemTonality,
        userTonality
      });
    },
    onSuccess: response => {
      setSuggestions(
        response?.outputs ? generatedTextsFromGenerateTextOutputEntries(response.outputs) : []
      );
    }
  });

  const onSubmit = (values: RewriteFormValues) => {
    if (shouldShowRunOutOfWordsModal()) {
      onlyShowModal();
      return;
    }
    setCurrentStep('suggestions');
    fetchSuggestions({
      text: flashActionsSelectedText,
      ...values
    });
  };

  const handleSelectionReplace = (text: string) => {
    props.onClose();
    insertText(editor, text, { at: flashActionsSelection });
  };

  const handleInsertClick = (text: string) => {
    props.onClose();
    insertText(editor, text);
  };

  /**
   * TabIndex is a hack to prevent the loss of a
   * `fake selection` in the editor.
   */
  return <Root tabIndex={-1}>{renderContent()}</Root>;

  function renderContent() {
    const error = flashActionsSelectedText.length > maxSelectedTextLength;

    if (error) {
      return (
        <Error>
          <FormattedMessage
            id="aiWriter.selection_toolbar.more.no_long_selection"
            values={{ max: maxSelectedTextLength }}
          />
        </Error>
      );
    }

    if (isSuggestionsLoading) {
      return <TipLoader tips={textGeneratorTips} />;
    }

    switch (currentStep) {
      case 'setup':
        return <RewriteSetupStep onSubmit={onSubmit} />;
      case 'suggestions':
        return (
          <RewriteActionSuggestion
            suggestions={suggestions}
            isButtonDisabled={isSuggestionsLoading}
            onReplaceClick={handleSelectionReplace}
            onInsertClick={handleInsertClick}
          />
        );
    }
  }
};

const Root = styled(Paper).attrs({
  elevation: 1
})`
  margin-top: 1rem;
  padding: 1rem;
`;

const Error = styled(Typography)`
  color: ${({ theme }) => theme.colors.danger};
`;
