import {
  keywords2Field,
  keywordsField,
  numberOfSuggestionsField,
  outputTypeField,
  textField
} from 'features/aiWriter/AiWriterSidebar/steps/textInspirations/EditBriefSubStep';
import { getCurrentModelLanguageAndCountry } from 'features/aiWriter/store/selectors';
import { getFieldPrefillValue } from 'features/textGenerator/utils/getFieldPrefillValue';
import { getNTextItems } from 'features/textGenerator/utils/getNTextItems';
import { useGetPromptConfigByLanguageCountryAndOutputType } from 'features/textGenerator/utils/useGetPromptsOthers';
import { useSelector } from 'react-redux';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SetFieldValue = (field: string, value: any, shouldValidate?: boolean | undefined) => void;

export default function useHandleBriefOutputTypeChange() {
  const { currentModelLanguage, currentModelCountry } = useSelector(
    getCurrentModelLanguageAndCountry
  );

  const getPrompt = useGetPromptConfigByLanguageCountryAndOutputType();

  return (outputType: string, setFieldValue: SetFieldValue) => {
    /**
     * #tech-debt https://app.clickup.com/t/862hy4n1w
     * When output type changes we need to override
     * config with prefilled values. We have this logic
     * in multiple places. I don't like it but I don't know how to
     * make it better without a complete rewrite.
     */
    const prompt = getPrompt(currentModelLanguage, currentModelCountry, outputType);
    setFieldValue(numberOfSuggestionsField, getNTextItems(prompt));
    setFieldValue(outputTypeField, outputType);

    const text = getFieldPrefillValue(prompt, 'text');
    if (text) {
      setFieldValue(textField, text);
    }

    const keywords = getFieldPrefillValue(prompt, 'keywords');
    if (keywords) {
      setFieldValue(keywordsField, keywords);
    }

    const keywords2 = getFieldPrefillValue(prompt, 'keywords2');
    if (keywords2) {
      setFieldValue(keywords2Field, keywords2);
    }
  };
}
