import { Link } from '@mui/material';
import { hasInstalledBrowserExtension } from 'features/browser-extension/hasInstalledBrowserExtension';
import { isSupportedBrowser } from 'features/browser-extension/IsSupportedBrowser';
import { SupportedBrowserType } from 'features/browser-extension/SupportedBrowserType';
import { useBrowserExtensionAppStoreLink } from 'features/browser-extension/useBrowserExtensionAppStoreLink';
import { useBrowserExtensionHintStore } from 'features/browser-extension/useBrowserExtensionHintStore';
import { useCustomerPreferences } from 'features/customerPreferences/useCustomerPreferences';
import { useUpdateCustomerPreferences } from 'features/customerPreferences/useUpdateCustomerPreferences';
import { getCurrentLanguage } from 'features/language/store/selectors';
import { AppLanguage } from 'features/language/store/types';
import { ComponentProps, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppSelector } from 'store/hooks';
import { getBrowserName, getBrowserType } from 'utils/getBrowserType';
import useTr from 'utils/hooks/useTr';

const useShouldSeeBrowserExtensionHint = () => {
  const browserType = getBrowserType();

  return useMemo(
    () => !hasInstalledBrowserExtension() && browserType && isSupportedBrowser(browserType),
    [browserType]
  );
};

// currently product tour comes from navattic but we want to keep product tour name
export type NotificationCenterType =
  | 'browser_extension'
  | 'product_tour'
  | 'webinar'
  | 'languagetool';
// type of FormattedMessage values prop
type FormattedMessageValues = ComponentProps<typeof FormattedMessage>['values'];

export type NotificationCenterEntry = {
  type: NotificationCenterType;
  titleTranslationValues?: FormattedMessageValues;
  subtitleTranslationValues?: FormattedMessageValues;
  onClick?: () => void;
  onRemove: () => void;
  onActionButtonConfirm?: () => void;
  onActionButtonCancel?: () => void;
};

export const useNotificationCenterEntries = (): NotificationCenterEntry[] => {
  const tr = useTr();
  const { data: preferences } = useCustomerPreferences();
  const appLanguage = useAppSelector(getCurrentLanguage);

  const browserName = getBrowserName();
  const browserType = getBrowserType();

  const extensionStoreLink = useBrowserExtensionAppStoreLink(browserType as SupportedBrowserType);
  const { mutate: updatePreferences } = useUpdateCustomerPreferences();

  const hasRemovedBrowserExtensionHint = useBrowserExtensionHintStore(
    state => state.hasRemovedBrowserExtensionHint
  );
  const setHasRemovedBrowserExtensionHint = useBrowserExtensionHintStore(
    state => state.setHasRemovedBrowserExtensionHint
  );

  const shouldSeeBrowserExtensionNotification = useShouldSeeBrowserExtensionHint();
  const shouldSeeProductTourNotification = !preferences?.productTourSeen;
  const shouldSeeWebinarNotification =
    !preferences?.webinarSeen && appLanguage === AppLanguage.German;
  const shouldSeeLanguageToolNotification = !preferences?.languageToolSeen;

  const productTourLink = tr('layout.sidebar.actions.help.getting_started.tour.link');
  const webiarLink = tr('layout.sidebar.actions.notifications.webinar.link');
  const languageToolLink = tr('layout.sidebar.actions.notifications.languagetool.subtitle.link');

  const notifications = new Array<NotificationCenterEntry>();

  if (shouldSeeBrowserExtensionNotification && !hasRemovedBrowserExtensionHint) {
    notifications.push({
      type: 'browser_extension',
      titleTranslationValues: {
        browser: browserName
      },
      subtitleTranslationValues: {
        browser: browserName
      },
      onClick: () => {
        setHasRemovedBrowserExtensionHint(true);
        window.open(extensionStoreLink, '_blank');
      },
      onRemove: () => setHasRemovedBrowserExtensionHint(true)
    });
  }

  if (shouldSeeProductTourNotification) {
    notifications.push({
      type: 'product_tour',
      subtitleTranslationValues: {
        b: (text: string) => <strong>{text}</strong>,
        br: <br />
      },
      onClick: () => {
        updatePreferences({ productTourSeen: true });
        window.open(productTourLink, '_blank');
      },
      onRemove: () => {
        updatePreferences({ productTourSeen: true });
      }
    });
  }

  if (shouldSeeWebinarNotification) {
    notifications.push({
      type: 'webinar',
      subtitleTranslationValues: {
        b: (text: string) => <strong>{text}</strong>,
        br: <br />
      },
      onClick: () => {
        updatePreferences({ webinarSeen: true });
        window.open(webiarLink, '_blank');
      },
      onRemove: () => {
        updatePreferences({ webinarSeen: true });
      }
    });
  }

  if (shouldSeeLanguageToolNotification) {
    notifications.push({
      type: 'languagetool',
      subtitleTranslationValues: {
        a: (text: string) => (
          <Link href={languageToolLink} target="_blank">
            {text}
          </Link>
        )
      },
      onRemove: () => {
        updatePreferences({ languageToolSeen: true });
      },
      onActionButtonConfirm() {
        updatePreferences({ languageToolEnabled: true });
        updatePreferences({ languageToolSeen: true });
      },
      onActionButtonCancel() {
        updatePreferences({ languageToolEnabled: false });
        updatePreferences({ languageToolSeen: true });
      }
    });
  }

  return notifications;
};
