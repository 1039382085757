import { Button, Typography } from '@mui/material';
import { useModal } from 'components/modals';
import { useDeleteAccountPreventionModal } from 'components/profile/components/pricing-plan-card/DeleteAccountPreventionModal';
import { useCanDeleteAccount } from 'components/profile/components/pricing-plan-card/useCanDeleteAccount';
import { usePostHog } from 'posthog-js/react';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { ACLPricing } from 'services/api/customer/types';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled from 'styled-components';

interface Props {
  currentPricingPlan: ACLPricing;
  hasAutoRenewal: boolean;
}

const EndSubscriptionHint = ({ currentPricingPlan, hasAutoRenewal }: Props): ReactElement => {
  const { showModal } = useModal();
  const { canDeleteAccount, isLoading: isDeleteAccountLoading } = useCanDeleteAccount();
  const postHog = usePostHog();

  const showDeleteAccountPreventionModal = useDeleteAccountPreventionModal();

  const handleAccountDeleteClick = () => {
    if (!canDeleteAccount) {
      showDeleteAccountPreventionModal();
      return;
    }

    showModal('ACCOUNT_DELETE', { size: 900 });
  };

  const handleCancellationButtonClick = () => {
    postHog?.capture('Cancel subscription click');

    showModal('CANCEL_SUBSCRIPTION', { size: 900 });
  };

  return (
    <EndSubscriptionContainer>
      <LeftContainer>
        {!currentPricingPlan.isFree && hasAutoRenewal && (
          <>
            <Typography variant="subtitle1">
              <FormattedMessage id="profile.subscription_cancel_hint_title" />
            </Typography>
            <Typography variant="body2">
              <FormattedMessage
                id="profile.subscription_cancel_hint_message"
                values={{ br: <br /> }}
              />
            </Typography>
          </>
        )}
      </LeftContainer>
      <RightContainer>
        {!currentPricingPlan.isFree && hasAutoRenewal && (
          <Button
            {...withGtmInteraction(gtmIds.pricing.cancelSubscription)}
            color="primary"
            onClick={handleCancellationButtonClick}
          >
            <FormattedMessage id="profile.subscription_cancel" />
          </Button>
        )}

        <Button
          {...withGtmInteraction(gtmIds.pricing.deleteAccount)}
          color="secondary"
          onClick={handleAccountDeleteClick}
          disabled={isDeleteAccountLoading}
        >
          <FormattedMessage id="profile.delete" />
        </Button>
      </RightContainer>
    </EndSubscriptionContainer>
  );
};

const EndSubscriptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 80%;
`;

const RightContainer = styled(LeftContainer)`
  align-items: flex-end;
`;

export default EndSubscriptionHint;
