import ErrorIcon from '@mui/icons-material/Error';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Typography } from '@mui/material';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { ApiModularWorkflowStepReferenceIcons } from 'features/modular-workflow/builder/types-api';
import { RunnerError } from 'features/modular-workflow/runner/getRunnerErrorsFromResponse';
import { StepBox } from 'features/modular-workflow/runner/step-list/StepBox';
import { useModulareWorkflowRunnerStore } from 'features/modular-workflow/runner/useModulareWorkflowRunnerStore';
import { ComponentProps } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import styled from 'styled-components';
import { assertNonNullable } from 'utils/typescript/nonNullable';

type Props = {
  stepErrorList: RunnerError[];
};

export const ModularWorkflowStepList = ({ stepErrorList }: Props) => {
  const selectedStepIndex = useModulareWorkflowRunnerStore(state => state.selectedStepIndex);
  const workflow = useModulareWorkflowRunnerStore(state => state.workflow);
  const runningWorkflow = useModulareWorkflowRunnerStore(state => state.runningWorkflow);
  assertNonNullable(workflow, 'workflow must be loaded first');

  // Zero based
  const humanStepIndex = selectedStepIndex + 1;
  const humanStepCount = workflow.steps.length;

  return (
    <Root>
      <Typography variant="caption">
        <FormattedMessage
          id="modular_workflow.runner.step_list.current_step"
          values={{
            currentStep: humanStepIndex,
            totalSteps: humanStepCount
          }}
        />
      </Typography>

      <DndProvider backend={HTML5Backend}>
        <StepList>
          {workflow.steps.map((step, stepIndex) => {
            const currentStepErrorList = stepErrorList.filter(error => error.stepId === step.id);
            const runningStep = runningWorkflow?.steps[stepIndex];
            let boxVariant: ComponentProps<typeof StepBox>['variant'] = null;
            if (runningStep?.status === 'failed' || currentStepErrorList.length > 0) {
              boxVariant = 'error';
            } else if (runningStep?.status === 'completed') {
              boxVariant = 'completed';
            } else if (selectedStepIndex === stepIndex) {
              boxVariant = 'active';
            } else if (runningStep?.status === 'in_progress') {
              boxVariant = 'progress';
            }

            const hasProgressState = runningStep?.status === 'in_progress';

            let boxIcon = ApiModularWorkflowStepReferenceIcons[step.reference] ?? ErrorIcon;
            if (boxVariant === 'error') {
              boxIcon = ErrorIcon;
            } else if (boxVariant === 'progress' || hasProgressState) {
              boxIcon = RefreshIcon;
            }

            return (
              <StepBox
                key={`${step.reference}-${step.order}`}
                icon={boxIcon}
                name={step.name}
                variant={boxVariant}
                isInProgress={hasProgressState}
              />
            );
          })}
        </StepList>
      </DndProvider>
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.small};
  background-color: ${({ theme }) => theme.colors.textTile};
  padding: ${({ theme }) => theme.spacings.small};
  border-radius: ${({ theme }) => theme.borderRadius.small};
`;

const StepList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.medium};
  width: 100%;
  overflow-x: auto;
  // Provide some space for the potential scrollbar
  padding-bottom: ${({ theme }) => theme.spacings.medium};
`;
