import Toast from 'components/toasts/Toast';
import { getSeoSearchResultById } from 'features/aiWriter/store/selectors';
import getAudienceModelById from 'features/audiences/utils/getAudienceModelById';
import { setWordsLimitReached } from 'features/customer/store/actions';
import { generateTexts } from 'features/textGenerator/actions/generateTexts';
import { AppThunk } from 'store/store';
import { getCurrentTab } from 'store/utils/tabUtils';

import { rewriteSeoSearchResult } from '../actions';
import { RewriteSeoSearchResultThunkPayload } from '../types';

// The result returned for `rewrite_seo` output type should be formatted like `${title}\n${description}
// https://regex101.com/r/hAOasz/1
const rewriteOutputRegExp = /(?<title>.*?)\n(?<description>.*)/s;

export const rewriteSeoSearchResultThunk =
  ({ searchResultId }: RewriteSeoSearchResultThunkPayload): AppThunk<void> =>
  async (dispatch, getState) => {
    const state = getState();

    const { aiWriter: aiWriterState, audiences: audiencesState } = state;
    const currentTab = getCurrentTab(aiWriterState);

    if (!currentTab) {
      Toast.commonError();
      return;
    }

    const searchResult = getSeoSearchResultById(state)(searchResultId);

    if (!searchResult) {
      Toast.commonError();
      return;
    }

    const {
      id: tabId,
      generateTextConfig: { audienceId }
    } = currentTab;

    const audienceModelId = getAudienceModelById(audiencesState, audienceId);

    if (!audienceModelId) {
      Toast.commonError();
      return;
    }

    const nTimes = 1;

    const rewriteText = (text: string, outputType: string) =>
      generateTexts(
        {
          projectId: tabId,
          audienceModelId,
          outputType,
          nTimes
        },
        { text },
        {
          onCustomError: [
            'ERROR_ALL_WORDS_USED',
            () => {
              dispatch(setWordsLimitReached(true));
            }
          ]
        }
      );

    const { title, description } = searchResult;

    // The error case is already managed at top-level generateTexts function
    const rewriteTextResponse = await rewriteText(`${title}\n${description}`, 'rewrite_seo');
    const { title: rewrittenText, description: rewrittenDescription } =
      rewriteOutputRegExp.exec(rewriteTextResponse.outputs?.[0].text)?.groups ?? {};

    if (!rewrittenText || !rewrittenDescription) {
      Toast.commonError();
      return;
    }

    dispatch(
      rewriteSeoSearchResult({ tabId, searchResultId, rewrittenText, rewrittenDescription })
    );
  };
