import Toast from 'components/toasts/Toast';
import { TestingContent } from 'features/aiTester/store/types';
import filterWordAttributes from 'features/aiTester/store/utils/filters/filterWordAttributes';
import { getCurrentTabText } from 'features/aiTester/store/utils/getters/getCurrentTabText';
import { getExtendWithScoreByOpenRate } from 'features/aiTester/store/utils/getters/getExtendWithScoreByOpenRate';
import mkMapScoreTextData from 'features/aiTester/store/utils/mappers/mapScoreTextsData';
import prepareLegend from 'features/aiTester/store/utils/preparators/prepareLegend';
import prepareOrdering from 'features/aiTester/store/utils/preparators/prepareOrdering';
import prepareScoreTextConfig from 'features/aiTester/store/utils/preparators/prepareScoreTextConfig';
import getAudienceModelById from 'features/audiences/utils/getAudienceModelById';
import WordEmbeddingAPI from 'services/api/wordEmbedding';
import { handleScoreWordsErrors } from 'services/api/wordEmbedding/errors';
import { AppThunk } from 'store/store';
import { getCurrentTab } from 'store/utils/tabUtils';

import { sortByScore } from '../../general/actions';
import { updateTesterProjectInBackgroundThunk } from '../../project/thunks/updateTesterProjectInBackgroundThunk';
import { scoreWordsThunk } from '../../words/thunks/scoreWordsThunk';
import { scoreSingleText } from '../actions';
import { ScoreSingleTextRequestPayload } from '../types';
import { countPrevalence } from '../utils/countPrevalence';
import { isTextAvailable } from '../utils/isTextAvailable';

export const scoreSingleTextThunk =
  (payload: ScoreSingleTextRequestPayload): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const { tester: testerState, audiences: audiencesState } = state;
    const currentTab = getCurrentTab(testerState);
    const text = getCurrentTabText({ state: testerState, textId: payload.id });

    if (!currentTab) {
      return;
    }

    if (text?.text) {
      dispatch(scoreSingleText.request({ ...payload }));

      const {
        id: tabId,
        embeddingModelId,
        mapperId,
        wordAttributes,
        dimensions,
        manualDimensions,
        sortByScore: sortByScoreValue,
        generateTextConfig,
        legend: prevLegend
      } = currentTab;

      const audienceModelId = getAudienceModelById(audiencesState, generateTextConfig?.audienceId);

      const filteredWordAttributes = filterWordAttributes(wordAttributes, ['model_rank_score']);
      const wordAttributesValues = filteredWordAttributes.map(attr => attr.value);

      const extendWithScoreByOpenRate = getExtendWithScoreByOpenRate({ state });

      const { goalConfig, apiDimensions, mappers } = prepareScoreTextConfig(
        {
          audienceModelId,
          mapperId,
          wordAttributes: filteredWordAttributes,
          dimensions,
          manualDimensions
        },
        { extendWithScoreByOpenRate }
      );

      const { dimensionsOrder, wordAttributesOrder, legendOrder } = prepareOrdering({
        dimensions,
        manualDimensions,
        wordAttributes: wordAttributesValues,
        extendWithScoreByOpenRate
      });

      try {
        const scoreTextResponse = await WordEmbeddingAPI.scoreTexts({
          texts: [text.text],
          dimensions: apiDimensions,
          mappers,
          goalConfig,
          embeddingModelId: embeddingModelId
        });

        if (scoreTextResponse.status) {
          const mapScoreData = mkMapScoreTextData(
            audienceModelId,
            wordAttributesOrder,
            dimensionsOrder
          );

          const scoreDetails = scoreTextResponse.data.map(mapScoreData);

          const { model_rank_score: modelRankScore, goal_score: goalScore } =
            scoreTextResponse.data[0];

          const prevalence = countPrevalence(modelRankScore ?? undefined, wordAttributesValues);
          const isAvailable = isTextAvailable(text.words);

          const scoredText: TestingContent = {
            ...text,
            scoreDetails: scoreDetails[0],
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            goalScore: goalScore!,
            prevalence,
            isAvailable
          };

          dispatch(
            scoreSingleText.success({
              id: payload.id,
              tabId,
              scoredText,
              legend: prepareLegend(prevLegend, scoredText.scoreDetails),
              legendOrder
            })
          );

          dispatch(scoreWordsThunk({ tabId, textIds: [text.id] }));

          if (sortByScoreValue) {
            dispatch(sortByScore());
          }

          dispatch(updateTesterProjectInBackgroundThunk());

          return Promise.resolve();
        } else {
          dispatch(scoreSingleText.failure({ id: payload.id, tabId }));

          Toast.backendError(handleScoreWordsErrors(scoreTextResponse.data.message));

          return Promise.reject(scoreTextResponse.message);
        }
      } catch (error) {
        dispatch(scoreSingleText.failure({ id: payload.id, tabId }));
        Toast.apiError();
        return Promise.reject((error as Error).toString());
      }
    }

    return Promise.resolve();
  };
