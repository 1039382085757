import Toast from 'components/toasts/Toast';
import AiTesterAPI from 'services/api/aiTester';
import { handleApiCommonErrors } from 'services/api/utils/handleApiCommonErrors';
import { AppThunk } from 'store/store';

import { deleteProject } from '../actions';

export const deleteProjectThunk =
  (id: string): AppThunk<void> =>
  async (dispatch) => {
    dispatch(deleteProject.request({ id }));

    try {
      const response = await AiTesterAPI.delete(id);

      if (response.status) {
        dispatch(deleteProject.success({ id }));
      } else {
        const errorId = handleApiCommonErrors(response.data.message);

        Toast.backendError(errorId);
        dispatch(deleteProject.failure({ id }));
      }
    } catch (e) {
      Toast.apiError();
    }
  };
