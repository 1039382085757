import FlexContainer from 'components/FlexContainer';
import { EditorContinueWritingButton } from 'features/aiWriter/AiWriterTextEditor/EditorContinueWritingButton';
import { FlashScoreButton } from 'features/aiWriter/AiWriterTextEditor/FlashScoreButton';
import { useIsFlashScoreEnabled } from 'features/flashScore/useIsFlashScoreEnabled';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled from 'styled-components';

export const EditorBottomToolbar = () => {
  const isFlashScoreEnabled = useIsFlashScoreEnabled();

  return (
    <Root>
      <EditorContinueWritingButton />
      {isFlashScoreEnabled && (
        <BorderedFlashScoreButton
          {...withGtmInteraction(gtmIds.aiWriter.flashScore.bottomToolbar)}
          styleVariant="icon"
        />
      )}
    </Root>
  );
};

const Root = styled(FlexContainer).attrs({ direction: 'row', gap: 'one' })`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  // this value allows us to cover the options menu coming from image ("edit link" and "delete")
  z-index: 1001;
`;

const BorderedFlashScoreButton = styled(FlashScoreButton)`
  border: 1px solid ${({ theme }) => theme.colors.primary};

  // Hack to align icon button size with the EditorContinueWritingButton
  min-height: 31.5px;
  min-width: 31.5px;
`;
