import {
  Button,
  Chip,
  CircularProgress,
  ClickAwayListener,
  Fade,
  Paper,
  Popper,
  Typography
} from '@mui/material';
import Dot from 'components/base/Dot';
import FlexContainer from 'components/FlexContainer';
import { fetchWordSynonymsThunk } from 'features/aiTester/store/actions/words/thunks/fetchWordSynonymsThunk';
import { getGetTokenData, getTesterActiveTab } from 'features/aiTester/store/selectors';
import { getGetIsFeatureAvailable } from 'features/features/store/selectors';
import { readableColor } from 'polished';
import { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { resetButton } from 'styles/styledComponents/resets';
import useDropdown from 'utils/hooks/useDropdown';
import { graphColorScale } from 'utils/palettes';
import { formatToPercentage } from 'utils/wordUtils';

type Props = {
  wordId: string;
  textId: string;
  token: string;
  word: string;
  synonyms: string[];
  isFaulty: boolean;
  isLoading?: boolean;

  onSynonymClick: (synonym: string) => void;
};

const tokenPalette = graphColorScale('redGreen');

const WordTooltip = ({
  wordId,
  token,
  word,
  isFaulty,
  textId,
  synonyms,
  isLoading,

  onSynonymClick
}: Props) => {
  const dispatch = useAppDispatch();
  const { anchorElement, isOpen, open, close } = useDropdown();

  const { legend } = useAppSelector(getTesterActiveTab);
  const getTokenData = useAppSelector(getGetTokenData);

  const tokenData = useMemo(() => getTokenData(textId)[token] || [], [textId, token, getTokenData]);

  const isHighlightWordsEnabled = useAppSelector(getGetIsFeatureAvailable)('highlightWords');
  const isGeneratingSynonymsEnabled = useAppSelector(getGetIsFeatureAvailable)('synonyms');

  const finalTokenData = useMemo(
    () => (isHighlightWordsEnabled ? tokenData : []),
    [isHighlightWordsEnabled, tokenData]
  );

  const markerStyle = useMemo(() => {
    const goalScoreValue = finalTokenData.find(i => i.id === 'goal_score');

    if (goalScoreValue) {
      const backgroundColor = tokenPalette(goalScoreValue.value);
      const textColor = readableColor(backgroundColor);

      return {
        backgroundColor,
        color: textColor
      };
    }

    return undefined;
  }, [finalTokenData]);

  const handleGenerateSynonyms = useCallback(() => {
    const fetchMoreSynonyms = anchorElement && isGeneratingSynonymsEnabled;

    if (fetchMoreSynonyms) {
      dispatch(fetchWordSynonymsThunk({ textId, wordId }));
    }
  }, [anchorElement, dispatch, isGeneratingSynonymsEnabled, textId, wordId]);

  const getDotColor = useCallback(
    (id: string | number) => {
      if (legend[id]) {
        return legend[id].color;
      }

      if (id === 'model_rank_score' && legend.readability) {
        return legend.readability.color;
      }

      return undefined;
    },
    [legend]
  );

  return (
    <>
      <TokenMarker
        isFaulty={isFaulty}
        onClick={e => (anchorElement ? close() : open(e))}
        $color={markerStyle?.color}
        $backgroundColor={markerStyle?.backgroundColor}
      >
        {word}
      </TokenMarker>

      {isOpen && (
        <ClickAwayListener onClickAway={close}>
          <Popper anchorEl={anchorElement} open={true} placement="bottom-start" transition={true}>
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Paper elevation={6}>
                  <Container>
                    {finalTokenData.length > 0 && (
                      <Section>
                        <Header variant="subtitle1">
                          <FormattedMessage id="testing.word_tooltip.score" />
                        </Header>
                        <Content>
                          {finalTokenData.map(item => {
                            if (finalTokenData.length === 2 && item.id === 'goal_score') {
                              return null;
                            }

                            const dotColor = getDotColor(item.id);

                            return (
                              <Score key={item.id}>
                                {dotColor && <Dot color={dotColor} />}{' '}
                                <Value variant="body2">
                                  {item.label ? (
                                    item.label
                                  ) : (
                                    <FormattedMessage id={`testing.variable.${item.id}`} />
                                  )}
                                  : {formatToPercentage(item.value)}
                                </Value>
                              </Score>
                            );
                          })}
                        </Content>
                      </Section>
                    )}

                    {isGeneratingSynonymsEnabled && (
                      <Button onClick={handleGenerateSynonyms} disabled={isLoading}>
                        <FormattedMessage id="testing.word_tooltip.get_synonyms" />
                      </Button>
                    )}

                    {isLoading ? (
                      <FlexContainer alignItems="center">
                        <CircularProgress size={24} />
                      </FlexContainer>
                    ) : (
                      <Content>
                        {synonyms.map(synonym => (
                          <Chip
                            key={synonym}
                            onClick={() => {
                              onSynonymClick(synonym);
                            }}
                            label={synonym}
                          />
                        ))}
                      </Content>
                    )}
                  </Container>
                </Paper>
              </Fade>
            )}
          </Popper>
        </ClickAwayListener>
      )}
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.medium};

  max-width: 460px;
  padding: ${({ theme }) => theme.spacings.medium};
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.small};
`;

const Header = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.blackMediumEmphasis};
    font-weight: 500;
  }
`;

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacings.small};
`;

const TokenMarker = styled.button<{
  isFaulty?: boolean;
  $color?: string;
  $backgroundColor?: string;
}>`
  ${resetButton};

  padding: ${({ $backgroundColor }) => ($backgroundColor ? '2px 3px' : '0')};

  color: ${({ isFaulty, $color }) => (isFaulty ? '#ccc' : $color)};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  border-radius: ${({ theme }) => theme.borderRadius.small};

  cursor: ${({ $backgroundColor }) => ($backgroundColor ? 'pointer' : 'default')};
`;

const Score = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.small};
  align-items: center;
`;

const Value = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.blackHighEmphasis};
  }
`;

export default WordTooltip;
