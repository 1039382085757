import HelpIcon from '@mui/icons-material/Help';
import { InputAdornment, Tooltip, Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { HelpLinkingButton } from 'components/HelpLinkingButton';
import { PersonalityCommonSelector } from 'components/personality/PersonalityCommonSelector';
import { PersonalityIcon } from 'components/personality/PersonalityIcon';
import { FormValues } from 'features/aiWriter/AiWriterBlogPostBuilder/types';
import { useUpdateAiWriterAndDefaultPersonality } from 'features/aiWriter/AiWriterSidebar/steps/chat/useUpdateAiWriterAndDefaultPersonality';
import { isAiWriterSupportedLanguageAndCountryCode } from 'features/aiWriter/aiWriterSupportedLanguageAndCountryCodes';
import { TonalitySelect } from 'features/aiWriter/tonality/TonalitySelect';
import { getEmbeddingModelDataSelector } from 'features/embeddingModels/store/selectors';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { useShowApplyInformationModal } from 'features/information/apply-information/ApplyInformationModal';
import { InformationField } from 'features/information/apply-information/InformationField';
import { useFormikContext } from 'formik';
import { PersonalityDto } from 'services/backofficeIntegration/http/dtos/PersonalityDto';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

export function TonalityStep() {
  const getModelById = useAppSelector(getEmbeddingModelDataSelector);
  const tr = useTr();

  const { values, setFieldValue } = useFormikContext<FormValues>();

  const model = getModelById(values.language);
  const languageAndCountryCode = `${model.language}_${model.country}`;

  const tonalitySupportedInLanguage =
    isAiWriterSupportedLanguageAndCountryCode(languageAndCountryCode);

  const updatePersonality = useUpdateAiWriterAndDefaultPersonality();

  const handlePersonalityChange = (personality: PersonalityDto | undefined | null) => {
    updatePersonality({ personality });

    setFieldValue('personality', personality);
  };

  const showApplyInformationModal = useShowApplyInformationModal();

  const handleInformationApply = () => {
    showApplyInformationModal({
      preselectedInformation: values.information,
      onApply: informationList => {
        setFieldValue('information', informationList);
      }
    });
  };

  const handleInformationRemove = (id: number) => {
    setFieldValue(
      'information',
      values.information?.filter(information => information.id !== id)
    );
  };

  if (!tonalitySupportedInLanguage) {
    return null;
  }

  return (
    <Root>
      <TonalitySelect
        label={tr('blog_post_builder.tonality_step.label')}
        icon={
          <Tooltip placement="top" title={tr('blog_post_builder.tonality_step.tooltip')}>
            <HelpIcon color="action" />
          </Tooltip>
        }
        systemTonality={values.systemTonality}
        userTonality={values.userTonality}
        maxLength={2}
        languageAndCountryCode={languageAndCountryCode}
        onChange={(newSystemTonalities, newUserTonalities) => {
          setFieldValue('systemTonality', newSystemTonalities);
          setFieldValue('userTonality', newUserTonalities);
        }}
        onInputChange={inputValue => {
          // Disable nex step if user entered a tonality but didn't confirmed it
          setFieldValue('disableNextStep', inputValue.length > 0);
        }}
        gtmAttributes={withGtmInteraction(gtmIds.blogPostBuilder.tonality)}
      />
      <PersonalityBox>
        <FlexContainer direction="row" gap="small">
          <Typography variant="subtitle1">
            <FormattedMessage id={tr('common.brandVoice')} />
          </Typography>

          <HelpLinkingButton
            link="aiWriter.inspirations.aiwriter.main.personality.help_link"
            tooltip={<FormattedMessage id="blog_post_builder.tonality_step.personality.tooltip" />}
          />
        </FlexContainer>
        <PersonalitySelectorBox>
          <PersonalityCommonSelector
            value={values.personality}
            onChange={handlePersonalityChange}
            preselectedModelId={model.id}
            isPropsLoading={false}
            size="small"
            fullWidth
            startAdornment={
              <InputAdornment position="start">
                <PersonalityIcon />
              </InputAdornment>
            }
          />
        </PersonalitySelectorBox>
      </PersonalityBox>
      <InformationField
        informationList={values.information}
        onApply={handleInformationApply}
        onRemove={handleInformationRemove}
        fontVariant="subtitle1"
      />
    </Root>
  );
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.medium};
`;

const PersonalityBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PersonalitySelectorBox = styled.div`
  width: 10rem;
`;
