import { Button, Paper, Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';

const Root = styled(Paper)`
  padding: ${({ theme }) => theme.spacings.four};
  display: grid;
  gap: ${({ theme }) => theme.spacings.three};
  justify-items: start;
  align-items: start;
`;

export function ReferralCard(props: PropsWithChildren<unknown>) {
  return <Root variant="outlined">{props.children}</Root>;
}

ReferralCard.Logo = styled.img`
  height: 40px;
`;

ReferralCard.Header = (props: { icon: ReactNode; text: ReactNode }) => (
  <FlexContainer direction="row" alignItems="center" gap="one">
    {props.icon}
    <Typography variant="h5">{props.text}</Typography>
  </FlexContainer>
);

ReferralCard.Explanation = styled(Typography).attrs({ variant: 'body1' })``;

ReferralCard.CtaExternalLink = (props: { href: string; labelId: string }) => (
  <Button component="a" variant="outlined" href={props.href} target="_blank" rel="noreferrer">
    <FormattedMessage id={props.labelId} />
  </Button>
);

ReferralCard.SubmitInfo = styled(Typography).attrs({ variant: 'caption' })`
  margin-top: auto;
`;
