import { Search } from '@mui/icons-material';
import {
  Button,
  Divider,
  InputAdornment,
  LinearProgress,
  Menu,
  TextField,
  Typography
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import FlexContainer from 'components/FlexContainer';
import Toast from 'components/toasts/Toast';
import {
  AiWriterProjectTag,
  AiWriterUnassignedProjectTag,
  TagChipColors
} from 'features/aiWriter/AiWriterProjectOverview/tags/AiWriterProjectTag';
import { useGetAllTags } from 'features/aiWriter/AiWriterProjectOverview/tags/useGetAllTags';
import { useShowTagsModal } from 'features/aiWriter/AiWriterProjectOverview/tags/useShowTagsModal';
import { topBarDropdownAlignmentConfig } from 'features/aiWriter/AiWriterTextEditor/utils/topBarDropdownAlignmentConfig';
import { AiWriterProjectWithShortText } from 'features/aiWriter/store/types';
import { isEmpty } from 'lodash';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { AiWriterProjectTagType } from 'services/api/aiWriter/types';
import { httpAssignTag } from 'services/backofficeIntegration/http/endpoints/aiWriter/httpAssignTag';
import {
  httpCreateTag,
  MAX_TAG_NAME_LENGTH
} from 'services/backofficeIntegration/http/endpoints/aiWriter/httpCreateTag';
import { TagsPaginatedListParams } from 'services/backofficeIntegration/http/endpoints/aiWriter/httpGetAllTags';
import { invalidateProjectsQuery } from 'services/backofficeIntegration/http/endpoints/aiWriter/httpGetProjects';
import { httpUnassignTag } from 'services/backofficeIntegration/http/endpoints/aiWriter/httpUnassignTag';
import { GAEvents } from 'services/tracking/GAEvents';
import styled from 'styled-components';
import { useDebounce } from 'use-debounce/lib';
import useTr from 'utils/hooks/useTr';
import { isObject } from 'utils/isObject';

const DEBOUNCE_DELAY = 300;

type Props = {
  anchorEl: null | HTMLElement;
  isOpen: boolean;
  handleCloseMenu: () => void;
  onManageButtonClick?: () => void;
  onCreateTag?: () => void;
  tags?: AiWriterProjectTagType[];
  project?: AiWriterProjectWithShortText;
};

export const AiWriterProjectTagsDropdownMenu = ({
  anchorEl,
  isOpen,
  handleCloseMenu,
  onManageButtonClick,
  onCreateTag,
  tags,
  project
}: Props) => {
  const translate = useTr();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [debouncedSearchQuery] = useDebounce(searchQuery, DEBOUNCE_DELAY);
  const [isTextTooLong, setIsTextTooLong] = useState(false);
  const assignedTagIds = useMemo(() => tags?.map(tag => tag.id), [tags]);
  const isAnAssignedTag = useCallback(
    (tagId: number) => assignedTagIds?.includes(tagId),
    [assignedTagIds]
  );

  const onSearchChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchQuery(e.target.value);
    setIsTextTooLong(e.target.value.length > MAX_TAG_NAME_LENGTH);
  };

  const showManageTagsModal = useShowTagsModal();

  const { mutate: assignTag } = useMutation({
    mutationFn: httpAssignTag.callEndpoint,
    onSuccess: async () => {
      onCreateTag?.();
      if (project) {
        invalidateProjectsQuery();
      }
      GAEvents.tagAssignedToDocument();
    }
  });

  const { mutate: unassignTag } = useMutation({
    mutationFn: httpUnassignTag.callEndpoint,
    onSuccess: async () => {
      onCreateTag?.();
      if (project) {
        invalidateProjectsQuery();
      }
      GAEvents.tagUnassignedFromDocument();
    }
  });

  const { mutate: createTag, isLoading: isCreateLoading } = useMutation({
    mutationFn: httpCreateTag.callEndpoint,
    onSuccess: data => {
      Toast.success('aiWriter.document.tags.creation.success');
      assignTag({ projectId: Number(project?.id), tagId: data.data.id });
      GAEvents.tagCreatedInTagsDropdownMenu();
    },
    onError: (error: unknown) => {
      const statusCode =
        isAxiosError(error) && isObject(error.response?.data) && error.response?.data.statusCode;
      if (statusCode === 422) {
        Toast.error('aiWriter.document.tags.creation.error');
      }
    }
  });

  const qParam = debouncedSearchQuery !== '' ? debouncedSearchQuery : undefined;
  const allTagsParams: TagsPaginatedListParams = { q: qParam };
  if (project && debouncedSearchQuery === '') {
    // since we want to search both unassigned and assigned tags, we need to fetch all tags
    // only if the search query is empty we fetch only the unassigned tags
    allTagsParams.without_ai_writer_project_ids = [Number(project.id)];
  }
  const unassignedTags = useGetAllTags(allTagsParams, isOpen);
  const noUnassignedTags = unassignedTags.data?.pages.map(page => page.data).flat(1).length === 0;

  if (!project) {
    return null;
  }

  const handleCloseMenuAndClearSearch = () => {
    handleCloseMenu();
    setSearchQuery('');
  };

  const handleManageClick = async () => {
    await handleCloseMenuAndClearSearch();
    onManageButtonClick?.();
    showManageTagsModal();
    GAEvents.manageButtonClickedInTagsDropdownMenu();
  };

  const handleTagAssign = (tag: AiWriterProjectTagType) => {
    assignTag({ projectId: Number(project.id), tagId: tag.id });
  };

  const handleTagUnassign = (id: number) => {
    unassignTag({ projectId: Number(project.id), tagId: id });
  };

  const handleSearchAndCreateTag = async () => {
    if (isTextTooLong) {
      return;
    }
    await createTag({ color: TagChipColors.primary, name: searchQuery });
    setSearchQuery('');
  };

  const renderUnassignedTags = () => {
    if (noUnassignedTags && searchQuery !== '' && !isCreateLoading) {
      return (
        <AddTagButton onClick={handleSearchAndCreateTag}>{`Add "${searchQuery}"`}</AddTagButton>
      );
    }
    // we render the unassigned and assigned tags separately one list below the other only if the search query is empty
    // otheriwse we render both assigned and unassigned that match the search query and need to differentiate which ones are assigned
    if (searchQuery !== '') {
      return unassignedTags.data?.pages
        .map(page => page.data)
        .flat(1)
        .map(tag => {
          if (isAnAssignedTag(tag.id)) {
            return (
              <AiWriterProjectTag
                tag={tag}
                key={tag.id}
                onDelete={() => handleTagUnassign(tag.id)}
              />
            );
          }
          return (
            <AiWriterUnassignedProjectTag
              tag={tag}
              key={tag.id}
              onClick={() => handleTagAssign(tag)}
            />
          );
        });
    }

    return unassignedTags.data?.pages
      .map(page => page.data)
      .flat(1)
      .map(tag => (
        <AiWriterUnassignedProjectTag tag={tag} key={tag.id} onClick={() => handleTagAssign(tag)} />
      ));
  };

  const renderAssignedTags = () => {
    if (tags && !isEmpty(tags) && debouncedSearchQuery === '') {
      return tags.map(tag => (
        <AiWriterProjectTag tag={tag} key={tag.id} onDelete={() => handleTagUnassign(tag.id)} />
      ));
    }
  };

  const renderRetry = (messageId: string) => {
    return (
      <FlexContainer alignItems="center" gap="medium">
        <Typography variant="body2" textAlign="center">
          <FormattedMessage id={messageId} />
        </Typography>
        <Button
          variant="contained"
          onClick={() => {
            unassignedTags.refetch();
          }}
        >
          <FormattedMessage id="common.refresh" />
        </Button>
      </FlexContainer>
    );
  };

  const renderQueryControls = () => {
    if (unassignedTags.isFetching && unassignedTags.data) {
      return (
        <FlexContainer>
          <LinearProgress />
        </FlexContainer>
      );
    }

    if (unassignedTags.isLoadingError) {
      return renderRetry('aiWriter.document.tags.errors.load');
    }

    if (unassignedTags.isRefetchError && !unassignedTags.hasNextPage) {
      return renderRetry('aiWriter.document.tags.errors.refetch');
    }

    if (unassignedTags.hasNextPage) {
      return (
        <Button
          fullWidth
          onClick={() => {
            unassignedTags.fetchNextPage();
          }}
        >
          <FormattedMessage id="aiWriter.document.tags.moreButton" />
        </Button>
      );
    }

    if (searchQuery === '' && noUnassignedTags && (!tags || isEmpty(tags)) && !isCreateLoading) {
      return (
        <Typography>
          <FormattedMessage id="aiWriter.document.tags.dropdown.noTags" />
        </Typography>
      );
    }
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={isOpen}
      onClose={handleCloseMenuAndClearSearch}
      anchorOrigin={topBarDropdownAlignmentConfig.leftAlignment.anchorOrigin}
      transformOrigin={topBarDropdownAlignmentConfig.leftAlignment.transformOrigin}
      PaperProps={{
        style: {
          width: 280,
          maxHeight: 366,
          overflow: 'hidden'
        }
      }}
      disableAutoFocus={true}
    >
      <SearchField
        autoFocus={true}
        variant="standard"
        fullWidth
        value={searchQuery}
        placeholder={translate('aiWriter.document.tags.dropdown.search.placeholder')}
        onChange={onSearchChange}
        onKeyDown={async e => {
          e.stopPropagation();
          if (e.key === 'Enter' && searchQuery !== '') {
            handleSearchAndCreateTag();
          }
        }}
        InputProps={{
          disableUnderline: true,
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          )
        }}
      />
      <Divider />
      <TagsList $shorten={isTextTooLong}>
        {renderAssignedTags()}
        {renderUnassignedTags()}
        {renderQueryControls()}
      </TagsList>
      {isTextTooLong && (
        <CreationError variant="caption" color="error">
          <FormattedMessage id="aiWriter.document.tags.creation.error.too_long_name" />
        </CreationError>
      )}
      <Divider />
      <ManageButton onClick={handleManageClick}>
        <Typography variant="body1">
          <FormattedMessage id="aiWriter.document.tags.dropdown.manage.button" />
        </Typography>
      </ManageButton>
    </Menu>
  );
};

const SearchField = styled(TextField)`
  padding: ${({ theme }) => `${theme.spacings.xsmall} ${theme.spacings.medium}`};
`;

const TagsList = styled.div<{ $shorten: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.small};
  padding: ${({ theme }) => `${theme.spacings.small} ${theme.spacings.medium}`};
  max-height: ${({ $shorten }) => ($shorten ? '195px' : '220px')};
  overflow: auto;
`;

const ManageButton = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => `${theme.spacings.small} ${theme.spacings.medium}`};
  cursor: pointer;
`;

const CreationError = styled(Typography)`
  padding-left: ${({ theme }) => theme.spacings.medium};
`;

const AddTagButton = styled(Typography)`
  cursor: pointer;
`;
