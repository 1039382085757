import { ModularWorkflowDataAndStructureProvider } from 'features/modular-workflow/runner/ModularWorkflowDataAndStructureProvider';
import { ModularWorkflowRunnerPageTopBar } from 'features/modular-workflow/runner/ModularWorkflowRunnerPageTopBar';
import { ModularWorkflowRunView } from 'features/modular-workflow/runner/ModularWorkflowRunView';
import { useEnableSidebarOnMount } from 'features/pageSidebar/useSidebarStore';
import styled from 'styled-components';

const ModulareWorkflowRunPage = () => {
  useEnableSidebarOnMount();

  return (
    <Root>
      <ModularWorkflowRunnerPageTopBar />

      <ModularWorkflowDataAndStructureProvider>
        <ModularWorkflowRunView />
      </ModularWorkflowDataAndStructureProvider>
    </Root>
  );
};

export default ModulareWorkflowRunPage;

const Root = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xmedium};
`;
