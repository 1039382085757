import LogoAnimation from 'assets/loadingAnimation/logo-animation.lottie';
import { HalfMask } from 'components/AppLoader';
import FullMask from 'components/FullMask';
import { LottieComponent } from 'features/lottie/LottieComponent';
import { useModulareWorkflowStructureStore } from 'features/modular-workflow/useModulareWorkflowStructureStore';
import { useModularWorkflowStructure } from 'features/modular-workflow/useModularWorkflowStructure';
import { ReactNode, useEffect } from 'react';

type Props = {
  children: ReactNode;
  forceShowLoader?: boolean;
};

export const ModularWorkflowStructureProvider = ({ children, forceShowLoader = false }: Props) => {
  const { data: workflowStructure } = useModularWorkflowStructure();
  const setStructure = useModulareWorkflowStructureStore(state => state.setStructure);

  useEffect(() => {
    if (workflowStructure) {
      setStructure(workflowStructure);
    }
  }, [workflowStructure, setStructure]);

  // Show loader until the workflow structure is loaded
  if (!workflowStructure || forceShowLoader) {
    return (
      <FullMask
        $backgroundColor="#fff"
        $zIndex={40000}
        key="mask"
        style={{ opacity: 1 }}
        initial={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <HalfMask>
          <LottieComponent src={LogoAnimation} options={{ speed: 1.5 }} />
        </HalfMask>
      </FullMask>
    );
  }

  return <>{children}</>;
};
