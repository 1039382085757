import { IconButton } from '@mui/material';
import { ReactComponent as SidebarlogoIcon } from 'assets/icon-neuroflash-logo-sidebar.svg';
import { useSidebarStore } from 'features/pageSidebar/useSidebarStore';
import React from 'react';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled from 'styled-components';

export const SidebarLogo = (): React.ReactElement => {
  const toggleSidebar = useSidebarStore(state => state.toggleSidebar);

  return (
    <StyledIconButton
      {...withGtmInteraction(gtmIds.sidebar.neuroflashIcon)}
      onClick={toggleSidebar}
    >
      <SidebarlogoIcon />
    </StyledIconButton>
  );
};

const StyledIconButton = styled(IconButton)`
  min-width: 40px;
  min-height: 40px;
  padding: 0;
  margin-left: ${({ theme }) => theme.spacings.one};
`;
