import { Alert, CardContent, CircularProgress, Typography } from '@mui/material';
import { focusEditor } from '@udecode/plate-common';
import FlexContainer from 'components/FlexContainer';
import { CombinedEmotionalityAndReadabilityCard } from 'features/aiWriter/AiWriterSidebar/steps/flashScore/CombinedEmotionalityAndReadabilityCard';
import { EmotionalityCard } from 'features/aiWriter/AiWriterSidebar/steps/flashScore/EmotionalityCard';
import { GeneralStatisticsCard } from 'features/aiWriter/AiWriterSidebar/steps/flashScore/GeneralStatisticsCard';
import { SuggestionType } from 'features/aiWriter/AiWriterSidebar/steps/flashScore/GetSuggestionsButton';
import { GetSuggestionsCard } from 'features/aiWriter/AiWriterSidebar/steps/flashScore/GetSuggestionsCard';
import { OneTimeInfoCard } from 'features/aiWriter/AiWriterSidebar/steps/flashScore/OneTimeInfoCard';
import { ReadabilityCard } from 'features/aiWriter/AiWriterSidebar/steps/flashScore/ReadabilityCard';
import { RefreshScoringCard } from 'features/aiWriter/AiWriterSidebar/steps/flashScore/RefreshScoringCard';
import { SuggestionCard } from 'features/aiWriter/AiWriterSidebar/steps/flashScore/SuggestionCard';
import { useFlashScoreSidebarState } from 'features/aiWriter/AiWriterSidebar/steps/flashScore/useFlashScoreSidebarState';
import { useGenerateTextSuggestion } from 'features/aiWriter/AiWriterSidebar/steps/flashScore/useGenerateTextSuggestion';
import { useHasForbiddenDocumentLanguage } from 'features/aiWriter/AiWriterSidebar/steps/flashScore/useHasForbiddenDocumentLanguage';
import useEditor from 'features/aiWriter/AiWriterTextEditor/hooks/useEditor';
import { useEditorWordsCount } from 'features/aiWriter/AiWriterTextEditor/hooks/useEditorWordsCount';
import {
  getCurrentModelLanguageAndCountry,
  getGeneratingTextInEditor
} from 'features/aiWriter/store/selectors';
import { useScoreEditorText } from 'features/flashScore/useScoreEditorText';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { languageLabelSelector } from 'features/language/store/selectors';
import { useEffect } from 'react';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';

// Defined by product requirements
const minWordCount = 5;
// If the document contains more words, we only show a refresh button instead of auto-updating
const maxWordCountForAutoRefresh = 2000;

export const FlashScoreAnalyzeView = () => {
  const editor = useEditor();

  const { currentModelLanguage: documentLanguage } = useAppSelector(
    getCurrentModelLanguageAndCountry
  );
  const getLanguageLabel = useAppSelector(languageLabelSelector);
  const isInEditorGenerationActive = !!useAppSelector(getGeneratingTextInEditor);

  const setWordRating = useFlashScoreSidebarState(state => state.setWordRating);
  const selectedSuggestionType = useFlashScoreSidebarState(state => state.selectedSuggestionType);
  const setSelectedSuggestionType = useFlashScoreSidebarState(
    state => state.setSelectedSuggestionType
  );
  const lastGeneratedSuggestion = useFlashScoreSidebarState(state => state.lastGeneratedSuggestion);
  const setLastGeneratedSuggestion = useFlashScoreSidebarState(
    state => state.setLastGeneratedSuggestion
  );
  const setAutoFetchState = useFlashScoreSidebarState(state => state.setAutoFetchState);
  const autoFetchState = useFlashScoreSidebarState(state => state.autoFetchState);

  const editorTextWordCount = useEditorWordsCount();
  const hasForbiddingLanguage = useHasForbiddenDocumentLanguage();

  const hasLessThanMinWordCount = editorTextWordCount < minWordCount;
  const hasMoreThanMaxWordCount = editorTextWordCount > maxWordCountForAutoRefresh;
  const isAutoScoringDisabled =
    hasLessThanMinWordCount ||
    hasMoreThanMaxWordCount ||
    hasForbiddingLanguage ||
    isInEditorGenerationActive;

  const {
    refetch: refetchScoring,
    text: { isError, data: scoringData, isFetching: isFetchingTextRating },
    words: { data: scoringWordsData, isFetching: isFetchingWordsRating }
  } = useScoreEditorText({
    enabled: !isAutoScoringDisabled
  });

  const {
    data: generatedSuggestion,
    isFetching: isFetchingSuggestion,
    refetch: refetchSuggestion
  } = useGenerateTextSuggestion(selectedSuggestionType);

  // Update the scoring result on every change
  useEffect(() => {
    setWordRating(scoringWordsData ?? []);
  }, [scoringWordsData, setWordRating]);

  // Once we got results, ensure to focus the editor to show the decorated words
  // Note: This doesn't seem to work all the time, but it's better than nothing
  useEffect(() => {
    if (scoringData) {
      focusEditor(editor);
    }
  }, [editor, scoringData]);

  // Store the last generated suggestion to prevent re-fetching on every mount
  useEffect(() => {
    if (generatedSuggestion) {
      setLastGeneratedSuggestion(generatedSuggestion);
    }
  }, [generatedSuggestion, setLastGeneratedSuggestion, setSelectedSuggestionType]);

  // Update store with current fetch state
  useEffect(() => {
    if (isFetchingTextRating || isFetchingWordsRating) {
      setAutoFetchState('fetching');
      return;
    }

    if (hasMoreThanMaxWordCount) {
      setAutoFetchState('disabled');
      return;
    }

    setAutoFetchState('updated');
  }, [hasMoreThanMaxWordCount, isFetchingTextRating, isFetchingWordsRating, setAutoFetchState]);

  const handleSuggestionSelected = (suggestionType: SuggestionType) => {
    setSelectedSuggestionType(suggestionType);

    refetchSuggestion();
  };

  const handleRefreshScoringClick = () => {
    refetchScoring();
  };

  if (hasForbiddingLanguage) {
    return (
      <Alert severity="info" color="error">
        <FormattedMessage
          id="aiWriter.inspirations.flashScore.initial_view.error_forbidden_language"
          values={{
            language: getLanguageLabel(documentLanguage),
            languageCode: documentLanguage
          }}
        />
      </Alert>
    );
  }

  if (hasLessThanMinWordCount) {
    return (
      <Alert severity="info" color="error">
        <FormattedMessage
          id="aiWriter.inspirations.flashScore.initial_view.error_min_word_count"
          values={{ minWordCount, wordCount: editorTextWordCount }}
        />
      </Alert>
    );
  }

  if (isError) {
    return (
      <ProgressRootContainer>
        <Typography align="center" color="red">
          <FormattedMessage id="aiWriter.inspirations.flashScore.error_analyze_failed" />
        </Typography>
      </ProgressRootContainer>
    );
  }

  if (!scoringData) {
    return (
      <ProgressRootContainer>
        <CircularProgress size={100} thickness={1} />

        <Typography align="center">
          <FormattedMessage id="aiWriter.inspirations.flashScore.analyze_in_progress" />
        </Typography>
      </ProgressRootContainer>
    );
  }

  const { readability, emotionality } = scoringData;

  return (
    <FlexContainer direction="column" gap="small">
      {hasMoreThanMaxWordCount && (
        <RefreshScoringCard
          isRefreshing={autoFetchState === 'fetching'}
          wordCount={editorTextWordCount}
          onRefreshClick={handleRefreshScoringClick}
        />
      )}

      <OneTimeInfoCard />

      <CardContainer>
        <CombinedEmotionalityAndReadabilityCard
          emotionality={emotionality}
          readability={readability}
        />

        <GeneralStatisticsCard />
      </CardContainer>

      <EmotionalityCard emotionality={emotionality} />

      <ReadabilityCard readability={readability} />

      {selectedSuggestionType ? (
        <SuggestionCard
          onSuggestionSelected={handleSuggestionSelected}
          selectedSuggestionType={selectedSuggestionType}
          generatedSuggestion={lastGeneratedSuggestion}
          isFetching={isFetchingSuggestion}
        />
      ) : (
        <GetSuggestionsCard onSuggestionSelected={handleSuggestionSelected} />
      )}
    </FlexContainer>
  );
};

const ProgressRootContainer = styled(FlexContainer).attrs({
  direction: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 'large'
})`
  width: 100%;
  margin-top: 40px;
`;

const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${({ theme }) => theme.spacings.small};
  grid-template-rows: auto;
`;

export const StretchedCardContent = styled(CardContent)`
  height: 100%;

  &:last-child {
    padding-bottom: ${({ theme }) => theme.spacings.medium};
  }
`;
