import { captureException } from '@sentry/react';
import { CaptureContext } from '@sentry/types/types/scope';
import axios from 'axios';

export const reportErrors = <E extends Error>(type: 'component' | 'saga', error: E) => {
  if (!axios.isAxiosError(error)) {
    const sentryContext: CaptureContext = {
      tags: {
        'nf.error.scope': type
      }
    };

    captureException(error, sentryContext);
  }
};
