/**
 * See https://docs.churnkey.co/installing-churnkey for more information
 */
import { useQuery } from '@tanstack/react-query';
import toast from 'components/toasts/Toast';
import { appId } from 'features/churnKey/ChurnKeyLoader';
import { useGetChurnStatisticQuery } from 'features/churnModal/useGetChurnStatisticQuery';
import { getCurrentLanguage } from 'features/language/store/selectors';
import { useEffect, useState } from 'react';
import { httpGetChurnKeyHash } from 'services/backofficeIntegration/http/endpoints/customer/httpGetChurnKeyHash';
import { useAppSelector } from 'store/hooks';
import { isDevEnv } from 'utils/isDevEnv';

/**
 * Used to describe where the user is coming from, either from "delete account" or "cancel subscription"
 */
type FlowSourceAttribute = 'delete' | 'cancel';

type Options = {
  listeners?: {
    /**
     * Will be triggered once the flow has been finished.
     * Note: The flow has been finished if the user either wants to cancel or delete the account.
     */
    onCancel?: (customer: unknown) => void;
    onPause?: (customer: unknown) => void;
    onDiscount?: (customer: unknown) => void;
    onPlanChange?: (customer: unknown) => void;
  };
  handlers?: {
    /**
     * Instead of ChurnKey logic this will be executed once the user clicked through the flow.
     */
    handleCancel?: () => Promise<void>;
  };

  customerAttributes?: {
    churnType?: FlowSourceAttribute;
  };
};

export const useChurnKeyModal = (options?: Options) => {
  const { stripeId: stripeCustomerId } = useAppSelector(state => state.customer);
  const language = useAppSelector(getCurrentLanguage);
  const [shouldShowModal, setShouldShowModal] = useState(false);

  const { isLoading: isLoadingChurnStatistic, data: churnStatistic } = useGetChurnStatisticQuery();

  const {
    data: churnKeyHash,
    isLoading: isLoadingChurnKeyHash,
    error
  } = useQuery({
    queryKey: httpGetChurnKeyHash.makeQueryKey(),
    queryFn: () => httpGetChurnKeyHash.callEndpoint()
  });

  useEffect(() => {
    if (!shouldShowModal || isLoadingChurnKeyHash || isLoadingChurnStatistic) {
      return;
    }

    if (!churnKeyHash || error || !stripeCustomerId || !churnStatistic) {
      toast.commonError();
      return;
    }

    window.churnkey?.init?.('show', {
      customerId: stripeCustomerId,
      authHash: churnKeyHash,
      appId,
      mode: isDevEnv() ? 'test' : 'live',
      provider: 'stripe',
      i18n: {
        lang: language,
        messages: {
          de: {
            day: 'Tag | Tage',
            month: 'Monat | Monate',
            year: 'Jahr | Jahre'
          }
        }
      },
      customerAttributes: {
        language,
        words_used: churnStatistic.used_words_count,
        churn_type: options?.customerAttributes?.churnType,
        environment: isDevEnv() ? 'development' : 'production'
      },
      onCancel: options?.listeners?.onCancel,
      onPause: options?.listeners?.onPause,
      onDiscount: options?.listeners?.onDiscount,
      onPlanChange: options?.listeners?.onPlanChange,
      handleCancel: options?.handlers?.handleCancel
    });
  }, [
    churnKeyHash,
    stripeCustomerId,
    isLoadingChurnKeyHash,
    error,
    language,
    shouldShowModal,
    isLoadingChurnStatistic,
    churnStatistic,
    options
  ]);

  return () => {
    setShouldShowModal(true);
  };
};
