import { PricingPlan } from 'features/pricing/types';

type PowerPlanConfig = {
  featurePerksAmount: number;
  requestPerksAmount: number;
  wordsLimit: number;
  imagesLimit?: number;
};

export const planConfigs: Record<PricingPlan, PowerPlanConfig> = {
  [PricingPlan.free]: {
    featurePerksAmount: 1,
    requestPerksAmount: 4,
    wordsLimit: 2_000
  },
  [PricingPlan.basic]: {
    featurePerksAmount: 1,
    requestPerksAmount: 4,
    wordsLimit: 20_000
  },
  [PricingPlan.power]: {
    featurePerksAmount: 6,
    requestPerksAmount: 4,
    wordsLimit: 200_000
  },
  [PricingPlan.powerLower]: {
    // we use power perks instead
    featurePerksAmount: 0,
    requestPerksAmount: 0,
    wordsLimit: 100_000
  },
  [PricingPlan.powerHigher]: {
    // we use power perks instead
    featurePerksAmount: 0,
    requestPerksAmount: 0,
    wordsLimit: 400_000
  },
  [PricingPlan.powerVip]: {
    // this is legacy plan that is not selectable by user
    featurePerksAmount: 0,
    requestPerksAmount: 0,
    wordsLimit: 0
  },
  [PricingPlan.premium]: {
    featurePerksAmount: 7,
    requestPerksAmount: 4,
    wordsLimit: Infinity
  },
  [PricingPlan.enterprise]: {
    featurePerksAmount: 0,
    requestPerksAmount: 5,
    wordsLimit: Infinity
  },
  [PricingPlan.pro]: {
    featurePerksAmount: 0,
    requestPerksAmount: 4,
    wordsLimit: Infinity
  },
  [PricingPlan.business]: {
    featurePerksAmount: 0,
    requestPerksAmount: 4,
    wordsLimit: Infinity
  },
  [PricingPlan.standard]: {
    featurePerksAmount: 0,
    requestPerksAmount: 5,
    wordsLimit: 30_000,
    imagesLimit: 30
  },
  [PricingPlan.ignore]: {
    // this plan can not be selected by user
    featurePerksAmount: 0,
    requestPerksAmount: 0,
    wordsLimit: 0
  }
};
