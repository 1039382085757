import React from 'react';
import styled from 'styled-components';

type Props = {
  href: string;
  children: React.ReactNode;
};

export const ExternalLink = ({ href, children }: Props) => {
  return (
    <Root href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </Root>
  );
};

const Root = styled.a`
  color: ${({ theme }) => theme.colors.primary};
`;
