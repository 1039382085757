import checklistCompletedImage from 'assets/onboarding/checklistCompleted.svg';
import { getUserName } from 'features/customer/store/selectors';
import { Checklist, TaskList } from 'features/onboardingJourney/checklist/Checklist';
import { TaskItem } from 'features/onboardingJourney/checklist/TaskItem';
import { useMkTaskProperties } from 'features/onboardingJourney/checklist/useMkTaskProperties';
import { useOnboardingChecklistStore } from 'features/onboardingJourney/checklist/useOnboardingChecklist';
import { FormattedMessage } from 'react-intl';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';

type Props = {
  onClose: () => void;
};

export function OnboardingChecklist({ onClose }: Props) {
  const tasks = useOnboardingChecklistStore(state => state.tasks);
  const isChecklistComplete = useOnboardingChecklistStore(state => state.isChecklistComplete);
  const finishedTasksCount = useOnboardingChecklistStore(state => state.finishedTaskCount);
  const taskListCount = useOnboardingChecklistStore(state => state.taskCount);

  const username = useAppSelector(getUserName);

  const progress = Math.round((finishedTasksCount / taskListCount) * 100);

  const tasksProps = useMkTaskProperties();

  if (isChecklistComplete) {
    return (
      <Checklist
        title={<FormattedMessage id="onboarding_checklist.completed.title" />}
        bodyText={
          <FormattedMessage
            id="onboarding_checklist.completed.text"
            values={{ b: (text: string) => <b>{text}</b> }}
          />
        }
        onClose={onClose}
        progress={progress}
      >
        <ImageBox>
          <img src={checklistCompletedImage} alt="" />
        </ImageBox>
      </Checklist>
    );
  }

  return (
    <Checklist
      title={
        <FormattedMessage
          id="onboarding_checklist.title"
          values={{
            name: username
          }}
        />
      }
      bodyText={
        <FormattedMessage
          id="onboarding_checklist.text"
          values={{ b: (text: string) => <b>{text}</b> }}
        />
      }
      onClose={onClose}
      progress={progress}
    >
      <TaskList>
        {tasks.map(task => (
          <TaskItem
            key={task.id}
            isCompleted={task.isCompleted}
            title={tasksProps[task.alias].title}
            tooltipMessage={tasksProps[task.alias].tooltipMessage}
            onClick={tasksProps[task.alias].handleAction}
          />
        ))}
      </TaskList>
    </Checklist>
  );
}

const ImageBox = styled.div`
  display: flex;
  justify-content: center;

  margin: 3rem 0;
`;
