import { TutorialButton } from 'components/buttons/TutorialButton';
import FlexContainer from 'components/FlexContainer';
import { CreateChatForm } from 'features/aiWriter/AiWriterProjectOverview/chatForm/CreateChatForm';
import { NewProjectsSection } from 'features/aiWriter/AiWriterProjectOverview/NewProjectsSection';
import { RecentDocuments } from 'features/aiWriter/AiWriterProjectOverview/recent-documents/RecentDocuments';
import { TagsBanner } from 'features/aiWriter/AiWriterProjectOverview/TagsBanner';
import { WorkflowButtons } from 'features/aiWriter/AiWriterProjectOverview/WorkflowButtons';
import { InviteTeammatesButton } from 'features/homePage/HomeTeamSection/InviteTeammatesButton';
import useSubscriptionState from 'features/pricing/hook/useSubscriptionState';
import { PricingUpgradeButton } from 'features/pricing/PricingUpgradeButton';
import { PricingPlan } from 'features/pricing/types';
import { ProjectOverview } from 'features/projects/ProjectOverview';
import useUpdateSubscriptionModal from 'features/updateSubscriptionModal/hook/useUpdateSubscriptionModal';
import { usePostHog } from 'posthog-js/react';
import gtmIds from 'services/tracking/GTMIds';
import styled from 'styled-components';

const tutorialVideoId = 'aiWriter.project_overview.tutorial_button.videoId';

export const AiWriterProjectOverview = () => {
  const { currentPricingPlan } = useSubscriptionState();
  const showUpgradeModal = useUpdateSubscriptionModal();
  const posthog = usePostHog();

  const isProUser = currentPricingPlan.type === PricingPlan.pro;

  const upgradeButtonClickedPosthogEvent = () => {
    posthog?.capture('upgradeButtonClicked');
  };

  const handleUpgradeButtonClick = () => {
    upgradeButtonClickedPosthogEvent();
    showUpgradeModal();
  };

  const rightCornerButtons = (
    <>
      <RightCornerButton>
        {isProUser ? (
          <InviteTeammatesButton onClick={upgradeButtonClickedPosthogEvent} />
        ) : (
          <PricingUpgradeButton
            id={gtmIds.pricing.upgradePopUp}
            onClick={handleUpgradeButtonClick}
          />
        )}
      </RightCornerButton>
      <TutorialButton gtmId={gtmIds.aiWriter.watchTutorial} videoId={tutorialVideoId} />
    </>
  );

  return (
    <ProjectOverview
      title="aiWriter.project_overview.title"
      recentDocuments={<RecentDocuments />}
      actions={
        <FlexContainer gap="medium">
          <WorkflowButtons />

          <CreateChatForm />
        </FlexContainer>
      }
      rightCornerButtons={rightCornerButtons}
    >
      <>
        <TagsBanner />
        <NewProjectsSection />
      </>
    </ProjectOverview>
  );
};

const RightCornerButton = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.spacings.small};
  right: 190px;
`;
