import { useModal } from 'components/modals';
import { CloseModal, ModalProps } from 'components/modals/types';
import { PersonalityAutomaticCreationView } from 'features/aiWriter/AiWriterSidebar/steps/chat/PersonalityAutomaticCreationView';
import { PersonalityChoiceView } from 'features/aiWriter/AiWriterSidebar/steps/chat/PersonalityChoiceView';
import { PersonalityEditOrCreateView } from 'features/aiWriter/AiWriterSidebar/steps/chat/PersonalityEditOrCreateView';
import { useCustomerPreferences } from 'features/customerPreferences/useCustomerPreferences';
import { getEmbeddingModelsByLanguageAndAudience } from 'features/embeddingModels/store/selectors';
import { EmbeddingModel } from 'features/embeddingModels/store/types';
import { AnalysisView } from 'features/personality/creation/AnalysisView';
import { CreateFromInformationView } from 'features/personality/creation/CreateFromInformationView';
import { ShareUrlView } from 'features/personality/creation/ShareUrlView';
import { useState } from 'react';
import { PersonalityDto } from 'services/backofficeIntegration/http/dtos/PersonalityDto';
import { useAppSelector } from 'store/hooks';
import { safeEnum } from 'utils/safeEnum';

const ViewMode = safeEnum.make({
  create_or_edit: 'create_or_edit',
  choice: 'choice',
  create_automatic: 'create_automatic',
  create_from_information: 'create_from_information',
  analysis_completed: 'analysis_completed',
  share_url: 'share_url'
} as const);

type ViewMode = safeEnum.infer<typeof ViewMode>;
export { ViewMode };

export const CREATION_METHODS = {
  MANUAL: 'manual',
  COPY_PASTE: 'copyPaste',
  FROM_INFORMATION: 'fromInformation',
  SHARE_URL: 'shareURL'
} as const;

export type CreationMethod = typeof CREATION_METHODS[keyof typeof CREATION_METHODS];

export type PersonalityModalProps = {
  onPersonalitySelect?: (personality: PersonalityDto | null) => void;
  initialViewMode?: ViewMode;
  preselectedLanguageModelId?: string;
  personalityToEdit?: PersonalityDto;
  isLanguageEditable?: boolean;
  closeOnBack?: boolean;
};

type PersonalityCreationModalProps = CloseModal & PersonalityModalProps;

export type ContentToAnalyze = string | number | undefined;

export const personalityCreationModalHeight = 720;

export const PersonalityCreationModal = ({
  closeModal,
  onPersonalitySelect,
  initialViewMode,
  preselectedLanguageModelId,
  personalityToEdit,
  closeOnBack
}: PersonalityCreationModalProps) => {
  const [creationMethod, setCreationMethod] = useState<CreationMethod | undefined>(undefined);
  const [view, setView] = useState<ViewMode>(initialViewMode ?? ViewMode.choice);

  const [currentPersonality, setCurrentPersonality] = useState<PersonalityDto | null>(
    personalityToEdit ?? null
  );

  const [contentToAnalyze, setContentToAnalyze] = useState<ContentToAnalyze>();
  const [shouldAnalyze, setShouldAnalyze] = useState(false);

  const [analyzedPersonality, setAnalyzedPersonality] = useState<string | undefined>();

  const { data: customerPreferences } = useCustomerPreferences();
  const models = useAppSelector(getEmbeddingModelsByLanguageAndAudience);
  const defaultModel = models.find(
    model =>
      model.language === customerPreferences?.preferredLanguage &&
      model.country === customerPreferences.preferredLanguageCountry
  );

  const preselectedModel = models.find(model => model.id === preselectedLanguageModelId);
  const [languageModel, setLanguageModel] = useState<EmbeddingModel>(
    preselectedModel ?? defaultModel ?? models[0]
  );

  const isLanguageChangeable = !preselectedLanguageModelId;

  const handleBackClick = () => {
    if (closeOnBack) {
      closeModal();
    }

    setCurrentPersonality(null);
  };

  const handlePersonalitySelect = (personality: PersonalityDto | null) => {
    closeModal();

    onPersonalitySelect?.(personality);
  };

  const handleResetState = () => {
    setView(ViewMode.choice);
    setCreationMethod(undefined);
    setCurrentPersonality(null);
    setContentToAnalyze(undefined);
    setShouldAnalyze(false);
    setAnalyzedPersonality(undefined);
  };

  return (
    <>
      {view === ViewMode.choice && (
        <PersonalityChoiceView
          setView={setView}
          setCreationMethod={setCreationMethod}
          closeModal={closeModal}
        />
      )}

      {view === ViewMode.create_or_edit && (
        <PersonalityEditOrCreateView
          defaultModel={languageModel}
          personality={currentPersonality}
          isLanguageEditable={isLanguageChangeable}
          closeOnBack={closeOnBack}
          onCancelClick={handleBackClick}
          setView={setView}
          closeModal={closeModal}
          onPersonalitySelect={handlePersonalitySelect}
          contentToAnalyze={contentToAnalyze}
          setContentToAnalyze={setContentToAnalyze}
          analyzedPersonality={analyzedPersonality}
        />
      )}

      {view === ViewMode.create_automatic && (
        <PersonalityAutomaticCreationView
          languageModel={languageModel}
          setLanguageModel={setLanguageModel}
          contentToAnalyze={contentToAnalyze}
          setContentToAnalyze={setContentToAnalyze}
          setShouldAnalyze={setShouldAnalyze}
          setView={setView}
          closeModal={closeModal}
          isLanguageEditable={isLanguageChangeable}
          onBackClick={handleResetState}
        />
      )}

      {view === ViewMode.create_from_information && (
        <CreateFromInformationView
          contentToAnalyze={contentToAnalyze}
          setContentToAnalyze={setContentToAnalyze}
          setShouldAnalyze={setShouldAnalyze}
          closeModal={closeModal}
          setView={setView}
          onBackClick={handleResetState}
        />
      )}

      {view === ViewMode.analysis_completed && (
        <AnalysisView
          languageModel={languageModel}
          shouldAnalyze={shouldAnalyze}
          contentToAnalyze={contentToAnalyze}
          creationMethod={creationMethod}
          setShouldAnalyze={setShouldAnalyze}
          closeModal={closeModal}
          setView={setView}
          analyzedPersonality={analyzedPersonality}
          setAnalyzedPersonality={setAnalyzedPersonality}
        />
      )}

      {view === ViewMode.share_url && (
        <ShareUrlView
          contentToAnalyze={contentToAnalyze as string}
          setContentToAnalyze={setContentToAnalyze}
          setShouldAnalyze={setShouldAnalyze}
          setView={setView}
          closeModal={closeModal}
          onBackClick={handleResetState}
        />
      )}
    </>
  );
};

export const useShowPersonalityCreationModal = () => {
  const { showModal } = useModal();

  return (props: PersonalityModalProps & ModalProps) => {
    showModal('PERSONALITY_CREATION', { ...props, size: 600 });
  };
};
