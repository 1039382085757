import { ApiErrors } from '../types';
import { contact, profilePage, termsOfUse } from '../utils/errorHyperlinks';
import mkApiErrorMessage from '../utils/mkApiErrorMessage';
import mkHandleApiErrorMessage from '../utils/mkHandleApiErrorMessage';
import mkHandleApiErrorMessageWithHyperlink from '../utils/mkHandleErrorsWithHyperlink';
import { GenerateTextErrorCode, ScoreWordsErrorCode, WordEmbeddingEndpoint } from './types';

const mkWordEmbeddingApiErrors = (
  endpoint: WordEmbeddingEndpoint,
  errorCode: ScoreWordsErrorCode | GenerateTextErrorCode
) => mkApiErrorMessage('wordEmbedding', endpoint, errorCode);

const scoreWordsErrors: ApiErrors<ScoreWordsErrorCode> = {
  ERROR_SCORING_AUDIENCE_SUCCESS_PREDICTOR_UNAVAILABLE: mkWordEmbeddingApiErrors(
    'scoreWords',
    'ERROR_SCORING_AUDIENCE_SUCCESS_PREDICTOR_UNAVAILABLE'
  ),
  ERROR_SCORING_EMBEDDING_MODEL_LANGUAGES_MISMATCH: mkWordEmbeddingApiErrors(
    'scoreWords',
    'ERROR_SCORING_EMBEDDING_MODEL_LANGUAGES_MISMATCH'
  ),
  ERROR_SCORING_EMBEDDING_MODEL_DOES_NOT_EXIST: mkWordEmbeddingApiErrors(
    'scoreWords',
    'ERROR_SCORING_EMBEDDING_MODEL_DOES_NOT_EXIST'
  ),
  ERROR_SCORING_NO_GOALCONFIG_FACTORS_SET: mkWordEmbeddingApiErrors(
    'scoreWords',
    'ERROR_SCORING_NO_GOALCONFIG_FACTORS_SET'
  ),
  ERROR_SCORING_UNABLE_TO_RETRIEVE_DIMENSION: mkWordEmbeddingApiErrors(
    'scoreWords',
    'ERROR_SCORING_UNABLE_TO_RETRIEVE_DIMENSION'
  ),
  ERROR_MATCH_TEXTS_UNSUPPORTED_LANGUAGES: mkWordEmbeddingApiErrors(
    'scoreWords',
    'ERROR_MATCH_TEXTS_UNSUPPORTED_LANGUAGES'
  ),
  ERROR_SERVICE_TIMEOUT: mkWordEmbeddingApiErrors('scoreWords', 'ERROR_SERVICE_TIMEOUT'),
  ERROR_NEUROAPI: mkWordEmbeddingApiErrors('scoreWords', 'ERROR_NEUROAPI'),
  ERROR_SERVICE_FAILED: mkWordEmbeddingApiErrors('scoreWords', 'ERROR_SERVICE_FAILED')
};

const generateTextErrors: ApiErrors<GenerateTextErrorCode> = {
  ERROR_GENERATE_TEXT_NO_SUCH_OUTPUT_TYPE: mkWordEmbeddingApiErrors(
    'generateText',
    'ERROR_GENERATE_TEXT_NO_SUCH_OUTPUT_TYPE'
  ),
  ERROR_GENERATE_TEXT_LANGUAGE_MISMATCH_INPUT_TO_GENERATION: mkWordEmbeddingApiErrors(
    'generateText',
    'ERROR_GENERATE_TEXT_LANGUAGE_MISMATCH_INPUT_TO_GENERATION'
  ),
  ERROR_GENERATE_TEXT_SENSITIVE_CONTENT_FILTER: mkWordEmbeddingApiErrors(
    'generateText',
    'ERROR_GENERATE_TEXT_SENSITIVE_CONTENT_FILTER'
  ),
  ERROR_GENERATE_TEXT_QUALITY_FILTER: mkWordEmbeddingApiErrors(
    'generateText',
    'ERROR_GENERATE_TEXT_QUALITY_FILTER'
  ),
  ERROR_GENERATE_TEXT_UNABLE_TO_GENERATE_TEXT: mkWordEmbeddingApiErrors(
    'generateText',
    'ERROR_GENERATE_TEXT_UNABLE_TO_GENERATE_TEXT'
  ),
  ERROR_SERVICE_TIMEOUT: mkWordEmbeddingApiErrors('generateText', 'ERROR_SERVICE_TIMEOUT'),
  ERROR_NEUROAPI: mkWordEmbeddingApiErrors('generateText', 'ERROR_NEUROAPI'),
  ERROR_SERVICE_FAILED: mkWordEmbeddingApiErrors('generateText', 'ERROR_SERVICE_FAILED'),
  ERROR_ALL_WORDS_USED: mkWordEmbeddingApiErrors('generateText', 'ERROR_ALL_WORDS_USED'),
  ERROR_ACCESS_DISABLED: mkWordEmbeddingApiErrors('generateText', 'ERROR_ACCESS_DISABLED'),
  ERROR_TEXT_GENERATION_FAIR_USAGE_REACHED: mkWordEmbeddingApiErrors('generateText', 'ERROR_TEXT_GENERATION_FAIR_USAGE_REACHED')
};

export const handleScoreWordsErrors = mkHandleApiErrorMessage(scoreWordsErrors);

export const handleGenerateTextErrorsWithHyperlink = mkHandleApiErrorMessageWithHyperlink(
  generateTextErrors,
  {
    ERROR_ALL_WORDS_USED: profilePage,
    ERROR_ACCESS_DISABLED: termsOfUse,
    ERROR_GENERATE_TEXT_NO_SUCH_OUTPUT_TYPE: contact,
    ERROR_GENERATE_TEXT_SENSITIVE_CONTENT_FILTER: termsOfUse,
    ERROR_NEUROAPI: contact,
    ERROR_TEXT_GENERATION_FAIR_USAGE_REACHED: termsOfUse
  }
);
