import { Typography } from '@mui/material';
import iconDropDown from 'assets/icons/icon-drop-down.svg';
import iconDropUp from 'assets/icons/icon-drop-up.svg';
import FlexContainer from 'components/FlexContainer';
import { PropsWithChildren, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import gtmIds from 'services/tracking/GTMIds';
import styled from 'styled-components';
import { resetLink } from 'styles/styledComponents/resets';

const AdvancedSettingsButton = ({
  isExpanded = false,
  children
}: PropsWithChildren & { isExpanded?: boolean }) => {
  const [extend, setExtend] = useState(isExpanded);

  return (
    <FlexContainer gap="medium">
      <div>
        <SettingsButton
          onClick={() => setExtend(prevVal => !prevVal)}
          id={gtmIds.aiWriter.advancedSettings}
        >
          <Typography variant="subtitle2">
            <FormattedMessage id="common.advanced_settings" />
          </Typography>
          <img src={extend ? iconDropUp : iconDropDown} alt="iconDropDown" />
        </SettingsButton>
      </div>
      {extend && children}
    </FlexContainer>
  );
};

const SettingsButton = styled.a`
  ${resetLink};

  display: flex;
  align-items: center;
  cursor: pointer;
`;

export default AdvancedSettingsButton;
