import { CategoryRangeFilterComplexity } from 'components/Table/filterComponents/CategoryRangeFilter';
import { NumberRangeFilterPercentage } from 'components/Table/filterComponents/NumberRangeFilter';
import { SelectCheckboxContainer } from 'components/Table/TableComponents';
import { select, selectAll } from 'd3-selection';
import { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { CellProps, Column, Row, TableState } from 'react-table';
import { AdwordsAttributeType } from 'services/api/adwords/types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { formatter, formatWordClassName } from 'utils/wordUtils';

import Checkbox from '../../components/base/Checkbox';
import Icon from '../../components/base/Icon';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import Table from '../../components/Table/Table';
import Tooltip from '../../components/tooltips/Tooltip';
import { getActiveTab, getActiveTabData } from './store/selectors';
import { removeWord, selectRow, setTableState } from './store/slice';
import { ConceptFlashTabData } from './store/types';
import { columnCategoriesDict } from './store/utils';
import TableActions from './TableActions';

const negativeValuesAllowed = ['valence', 'dominance', 'arousal'];

const makeColumn = (
  accessor: string,
  label?: string,
  oppositeValue?: boolean,
  dimensionType?: 'dimension' | 'manual'
): Column<ConceptFlashTabData> => ({
  // I tried to make the `accessor` type type more strict but could not make that work. :(
  // @ts-expect-error #tech-debt https://app.clickup.com/t/862jpmqgy
  accessor,
  Header: () => {
    if (label) {
      if (dimensionType) {
        dimensionType = dimensionType || 'dimension';

        return (
          <Tooltip
            content={
              dimensionType === 'manual' ? (
                <FormattedMessage id="words_table.manual_dimension" values={{ label }} />
              ) : (
                <FormattedMessage id="goal_score.dimension.with_label" values={{ label }} />
              )
            }
          >
            <span>{label}</span>
          </Tooltip>
        );
      } else {
        return (
          <Tooltip content={<FormattedMessage id={`word_param.${label}.tooltip`} />}>
            <span>
              <FormattedMessage id={`word_param.${label}`} />
            </span>
          </Tooltip>
        );
      }
    } else {
      return (
        <Tooltip content={<FormattedMessage id={`word_param.${accessor}.tooltip`} />}>
          <span>
            <FormattedMessage id={`word_param.${accessor}`} />
          </span>
        </Tooltip>
      );
    }
  },
  Cell: ({ cell: { value } }: CellProps<ConceptFlashTabData, number>) => (
    <ProgressBar
      value={value}
      categories={columnCategoriesDict[accessor]}
      single={!negativeValuesAllowed.includes(accessor)}
      oppositeValue={oppositeValue}
    />
  ),
  minWidth: 170,
  filter: 'between',
  Filter: columnCategoriesDict[accessor]
    ? CategoryRangeFilterComplexity
    : NumberRangeFilterPercentage,
  sortType: 'basic'
});

const makeSeoColumn = (type: AdwordsAttributeType): Column<ConceptFlashTabData> => {
  const common = {
    Header: () => <FormattedMessage id={`seo.${type.toLowerCase()}`} />,
    minWidth: 170,
    filter: 'between',
    sortType: 'basic'
  };

  if (type === 'competition') {
    return {
      ...common,
      accessor: 'competition',
      Cell: ({ cell: { value } }) => (value != null ? <ProgressBar value={value} single /> : null),
      Filter: NumberRangeFilterPercentage
    };
  } else if (type === 'averagecpc') {
    return {
      ...common,
      accessor: 'average_cpc',
      Cell: ({ cell: { value } }) =>
        value ? <div className="w-100 text-center">{formatter('$,.2f')(value)}</div> : null,
      Filter: NumberRangeFilterPercentage
    };
  } else {
    return {
      ...common,
      accessor: 'search_volume',
      Cell: ({ cell: { value } }) => (
        <div className="w-100 text-center">{value ? formatter(',d')(value) : ''}</div>
      ),
      Filter: NumberRangeFilterPercentage
    };
  }
};

const WordsTable = () => {
  const tab = useAppSelector(getActiveTab);
  const tabData = useAppSelector(getActiveTabData);
  const dispatch = useAppDispatch();

  const rowEvents = useMemo(
    () => ({
      onClick: (row: Row<ConceptFlashTabData>) => {
        dispatch(selectRow(row.id));
      },
      onMouseEnter: (row: Row<ConceptFlashTabData>) => {
        selectAll('.bubble-chart__bubble-circle').classed('selected', false);
        selectAll('.bubble-chart__info').classed('selected-text', false);
        select(`.bubble-chart__bubble-circle[data-word="${row.original.word}"]`).classed(
          'selected',
          true
        );
        select(`.bubble-chart__info[data-word="${row.original.word}"]`).classed(
          'selected-text',
          true
        );
      },
      onMouseLeave: () => {
        selectAll('.bubble-chart__bubble-circle').classed('selected', false);
        selectAll('.bubble-chart__info').classed('selected-text', false);
      }
    }),
    [dispatch]
  );

  const columns = useMemo(() => {
    const { wordAttributes, dimensions, manualDimensions, fromList, seoParams } = tab;
    const cols: Array<Column<ConceptFlashTabData>> = [
      {
        accessor: 'select',
        Header: () => <Icon name="check-circle" />,
        Cell: ({ row }) => (
          <SelectCheckboxContainer>
            <Checkbox
              checked={row.isSelected}
              onChange={event => {
                event.stopPropagation();
                dispatch(selectRow(row.id));
              }}
            />
          </SelectCheckboxContainer>
        ),
        headerStyles: {
          padding: '0.5rem 0.25rem'
        },
        disableFilters: true,
        width: 42
      },
      {
        id: 'trash',
        Header: '',

        Cell: ({ row }: CellProps<ConceptFlashTabData>) => (
          <Icon
            vAlign
            name="trash"
            onClick={event => {
              event.stopPropagation();
              dispatch(removeWord(row.original.word));
            }}
          />
        ),
        width: 24,
        headerStyles: {
          padding: '0.5rem 0'
        },
        cellStyles: () => ({
          padding: '0.5rem 0.25rem'
        }),
        disableFilters: true,
        disableSortBy: true
      },
      {
        accessor: 'word',
        Header: () => <FormattedMessage id="word_param.word" />,
        minWidth: 100,
        ellipsis: true
      },
      {
        accessor: 'translation_en',
        Header: () => <FormattedMessage id="word_param.translation" />,
        minWidth: 100,
        ellipsis: true
      }
    ];

    cols.push(makeColumn('goal_score'));

    wordAttributes.forEach(attr => {
      const label = attr.label || attr.option?.value;
      attr.option
        ? cols.push(
            makeColumn(attr.value, label, attr.option.oppositeValue && attr.option.value !== 'sad')
          )
        : cols.push(makeColumn(attr.value, label));
    });

    dimensions.forEach(dim =>
      cols.push(makeColumn(`dimension_scores.${dim.id}`, dim.label, false, 'dimension'))
    );

    manualDimensions.forEach(dim =>
      cols.push(makeColumn(`dimension_scores.${dim.id}_manual`, dim.label, false, 'manual'))
    );

    seoParams.forEach(param => cols.push(makeSeoColumn(param)));

    if (fromList) {
      return cols.filter(col => col.accessor !== 'seed_score');
    }

    return cols;
  }, [tab, dispatch]);

  const storeTableState = useCallback(
    (state: Partial<TableState>) => {
      dispatch(setTableState(state));
    },
    [dispatch]
  );

  const rowClasses = useCallback(
    (row: Row<ConceptFlashTabData>) => `words-table-row-${formatWordClassName(row.original.word)}`,
    []
  );

  const tableStateOverrides = useMemo(
    () => ({
      selectedRowIds: tab.selectedRows.reduce(
        (acc, selectedRow) => ({ ...acc, [selectedRow]: true }),
        {}
      ),
      hiddenColumns: tab.translationEnabled ? ['word'] : ['translation_en']
    }),
    [tab.selectedRows, tab.translationEnabled]
  );

  return (
    <Table
      minHeight="16rem"
      height="calc(100vh - 18rem)"
      data={tabData}
      columns={columns}
      pagination
      manualRowSelectedKey="select"
      rowEvents={rowEvents}
      persistState={storeTableState}
      initialState={tab.tableState}
      tableActions={TableActions}
      rowClasses={rowClasses}
      stateOverrides={tableStateOverrides}
    />
  );
};

export default WordsTable;
