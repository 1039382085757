import { useQuery } from '@tanstack/react-query';
import { httpGetActiveConversation } from 'services/backofficeIntegration/http/endpoints/aiWriter/httpGetActiveConversation';
import { queryClient } from 'utils/ReactQueryClient';

export function useActiveConversationQuery(props: { projectId: string }) {
  const { projectId } = props;

  return useQuery({
    queryKey: httpGetActiveConversation.makeQueryKey({ projectId }),
    queryFn: () => httpGetActiveConversation.callEndpoint({ projectId })
  });
}

export function invalidateActiveConversationQuery(props: { projectId: string }) {
  const { projectId } = props;
  const queryKey = httpGetActiveConversation.makeQueryKey({ projectId });
  queryClient.invalidateQueries({ queryKey });
}
