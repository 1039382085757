import WarningIcon from '@mui/icons-material/Warning';
import { Box, Link, Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import useUpdateSubscriptionModal from 'features/updateSubscriptionModal/hook/useUpdateSubscriptionModal';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

export const PricingRestrictionHint = () => {
  const showUpgradeSubscriptionModal = useUpdateSubscriptionModal();

  return (
    <WarningBox>
      <WarningIcon fontSize="medium" />
      <Box>
        <Typography variant="h6" fontSize="16px" mb="4px">
          <FormattedMessage id="aiWriter.inspirations.no_access.headline" />
        </Typography>
        <Typography variant="body2">
          <FormattedMessage
            id="aiWriter.inspirations.no_access.message"
            values={{
              a: (content: string) => (
                <Link sx={{ cursor: 'pointer' }} onClick={() => showUpgradeSubscriptionModal()}>
                  {content}
                </Link>
              )
            }}
          />
        </Typography>
      </Box>
    </WarningBox>
  );
};

const WarningBox = styled(FlexContainer).attrs({
  alignItems: 'flex-start',
  justifyContent: 'center',
  gap: 'small'
})`
  background-color: ${({ theme }) => theme.colors.warning};
  flex-direction: row;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  padding: ${({ theme }) => `${theme.spacings.small} ${theme.spacings.medium}`};
`;
