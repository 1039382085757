import { Link, Typography } from '@mui/material';
import { ColoredTypography } from 'components/ColoredTypography';
import FlexContainer from 'components/FlexContainer';
import { useModal } from 'components/modals';
import { StyledDivider } from 'components/profile/components/common';
import PlanConfirmButton from 'components/profile/components/pricing-plan-card/PlanConfirmButton';
import PlanPerkList from 'components/profile/components/pricing-plan-card/PlanPerkList';
import { usePricingDowngradePreventionModal } from 'components/profile/components/pricing-plan-card/PricingDowngradePreventionModal';
import { usePlanConfirmMutation } from 'components/profile/components/pricing-plan-card/usePlanConfirmMutation';
import { useTeamDowngradePrevention } from 'components/profile/components/pricing-plan-card/useTeamDowngradePrevention';
import { CouponCode } from 'features/pricing/CouponCode';
import { FormattedPricing } from 'features/pricing/FormattedPricing';
import { FormattedWordLimit } from 'features/pricing/FormattedWordLimit';
import { PricingPlan } from 'features/pricing/types';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { ACLPricing, CustomerActiveSubscriptionState } from 'services/api/customer/types';
import styled from 'styled-components';
import { resetLink } from 'styles/styledComponents/resets';

interface Props {
  pricingPlan: ACLPricing;
  isBillingInfoPresent: boolean;
  isActivePlan: boolean;
  subscriptionState: CustomerActiveSubscriptionState;
  coupon?: CouponCode;
  fromPopupModal?: boolean;
  detailsComponent?: React.ReactNode;
  onScrollToSectionClick?: () => void;
}

// #tech-debt: https://app.clickup.com/t/862j5ed7u
export const PricingPlanCard = ({
  pricingPlan,
  isBillingInfoPresent,
  isActivePlan,
  subscriptionState,
  coupon,
  onScrollToSectionClick
}: Props): ReactElement => {
  const { hideModal } = useModal();

  const { mutate: confirmPlan, isLoading } = usePlanConfirmMutation();

  const { isDowngradePrevented, isLoading: isDowngradePreventionLoading } =
    useTeamDowngradePrevention(pricingPlan);

  const showPricingDowngradePreventionModal = usePricingDowngradePreventionModal();

  const handlePlanConfirmClick = () => {
    if (isDowngradePrevented) {
      showPricingDowngradePreventionModal();
      return;
    }

    confirmPlan({ pricingId: pricingPlan.id, isActivePlan, subscriptionState });
    hideModal();
  };

  return (
    <PlanCard $isProPlan={pricingPlan.type === PricingPlan.pro}>
      <HeadlineContainer>
        <Typography variant="h5">{pricingPlan.name}</Typography>
      </HeadlineContainer>
      <PricingContainer>
        <Typography variant="h3">
          <FormattedPricing pricing={pricingPlan} discount={coupon} />
        </Typography>

        {!pricingPlan.isFree && (
          <ColoredTypography variant="caption" color="textSecondary">
            <FormattedMessage
              id={`profile.plan.${pricingPlan.type}_subscription_period`}
              defaultMessage=""
            />
          </ColoredTypography>
        )}
      </PricingContainer>
      <PaymentHint>
        <ColoredTypography variant="caption" color="textSecondary">
          <FormattedMessage id={`profile.plan.${pricingPlan.type}_credit_card_hint`} />
        </ColoredTypography>
      </PaymentHint>
      <SublineContainer>
        <SublineTypography variant="subtitle1">
          <FormattedMessage id={`profile.plan.${pricingPlan.type}_subline`} />
        </SublineTypography>
      </SublineContainer>
      <StyledDivider />
      <FlexContainer gap="three">
        <Headline variant={pricingPlan.type === PricingPlan.pro ? 'subtitle2' : 'body2'}>
          <FormattedWordLimit planType={pricingPlan.type} />
        </Headline>
        <PlanConfirmButton
          isBillingInfoPresent={isBillingInfoPresent}
          isActivePlan={isActivePlan}
          isLoading={isLoading || isDowngradePreventionLoading}
          subscriptionState={subscriptionState}
          isFreePlan={pricingPlan.isFree}
          onCtaClick={handlePlanConfirmClick}
          isProPlan={pricingPlan.type === PricingPlan.pro}
          isTrial={pricingPlan.isTrial}
        />
        {onScrollToSectionClick && (
          <StyledLink onClick={onScrollToSectionClick}>
            <FormattedMessage id="profile.plan.compare_button" />
          </StyledLink>
        )}

        <PlanPerkList planType={pricingPlan.type} />
      </FlexContainer>
    </PlanCard>
  );
};

export const PricingLink = styled.a`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #35353f;
`;

export const PlanCard = styled.div<{ $isProPlan?: boolean }>`
  padding: ${({ theme }) => theme.spacings.three};

  background: ${({ theme, $isProPlan }) =>
    $isProPlan ? 'transparent' : theme.colors.primaryColorHover};
  border-radius: ${({ theme }) => theme.borderRadius.one};

  border: ${({ $isProPlan }) => ($isProPlan ? '2px solid #212121' : 'none')};

  // Prepare for 3 plans but also allow more
  flex-basis: 30%;
  text-align: center;
  z-index: 1;
  position: relative;
`;

export const HeadlineContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacings.three};
`;

export const SublineContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacings.three};
`;

export const SublineTypography = styled(Typography)`
  position: relative;
`;

export const PricingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.one};
`;

export const PaymentHint = styled.div`
  margin-bottom: ${({ theme }) => theme.spacings.three};
`;

export const Headline = styled(Typography)`
  display: flex;
  align-items: center;
  /**
    #tech-debt: https://app.clickup.com/t/862j5ed7u 
    the height is hardcoded due to power plans card 
    and have to match the height of the dropdown   
  */
  height: 2.6rem;
  justify-content: center;
  color: ${({ theme }) => theme.colors.commonBrandColorsBrandOrange};
`;

export const StyledLink = styled(Link)`
  ${resetLink};

  cursor: pointer;

  ${({ theme }) => ({
    ...theme.typography.body2
  })}
  color: ${({ theme }) => theme.colors.textPrimary};
  text-decoration: underline;
`;
