import { Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { ReactElement, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { SubscriptionPeriod } from 'services/api/customer/types';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled from 'styled-components';

type Props = {
  subscriptionPeriod: SubscriptionPeriod;
  onSubscriptionPeriodChange: (subscriptionPeriod: SubscriptionPeriod) => void;
};

export const PricingPeriodSelect = ({
  subscriptionPeriod,
  onSubscriptionPeriodChange
}: Props): ReactElement => {
  const handleNewSwitchChange = useCallback(() => {
    onSubscriptionPeriodChange(subscriptionPeriod === 'month' ? 'year' : 'month');
  }, [onSubscriptionPeriodChange, subscriptionPeriod]);

  return (
    <div {...withGtmInteraction(gtmIds.modals.periodSwitcher)} onClick={handleNewSwitchChange}>
      <Root direction="col" justifyContent="center" alignItems="center">
        <NewSwitchContent gap="medium" direction="row">
          <NewOption variant="body2" fontWeight="500" $isSelected={subscriptionPeriod === 'month'}>
            <FormattedMessage id="register.pricing.subscriptionPeriod.month_billing" />
          </NewOption>

          <NewOptionRight
            variant="body2"
            fontWeight="500"
            $isSelected={subscriptionPeriod === 'year'}
          >
            <FormattedMessage id="register.pricing.subscriptionPeriod.year_billing" />
          </NewOptionRight>

          <StyledBadge>
            <Typography variant="caption">
              <FormattedMessage id="register.pricing.subscriptionPeriod.save_money" />
            </Typography>
          </StyledBadge>

          <MovingBackground isLeft={subscriptionPeriod === 'month'} />
        </NewSwitchContent>
      </Root>
    </div>
  );
};

const Root = styled(FlexContainer)`
  flex: 1 0 0;
`;

const StyledBadge = styled.div`
  background: ${({ theme }) => theme.colors.palette.yellow__500main};
  color: ${({ theme }) => theme.colors.palette.black};
  display: flex;

  width: 64px;
  height: 17px;
  position: absolute;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.borderRadius.two};
  right: 57px;
  bottom: -8.5px;
  padding-top: 1px;
  z-index: 2;
`;

const Content = styled(FlexContainer)`
  position: relative;

  margin-bottom: ${({ theme }) => theme.spacings.one};
`;

const NewSwitchContent = styled(Content)`
  width: 345px;
  height: 46px;
  border: 1px solid ${({ theme }) => theme.colors.componentsInputfieldOutlinedEnabledborder};
  border-radius: 50px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const MovingBackground = styled.div<{ isLeft: boolean }>`
  position: absolute;
  width: 50%;
  background: ${({ theme }) => theme.colors.primaryColorMain};
  left: ${({ isLeft }) => (isLeft ? '4px' : '168px')};
  height: 36px;
  border-radius: 50px;
  transition: all 0.5s ease;
`;

const Option = styled(Typography)<{ $isSelected: boolean }>`
  color: ${({ theme, $isSelected }) =>
    $isSelected ? theme.colors.palette.white : theme.colors.primaryColorMain};
`;

const NewOption = styled(Option)`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease;
  position: relative;
  z-index: 1;
`;

const NewOptionRight = styled(NewOption)`
  position: relative;
  right: 10px;
`;
