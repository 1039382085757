import { ErrorBoundary as SentryErrorBoundary, Scope } from '@sentry/react';
import { ErrorFallback } from 'features/react-error-boundary/ErrorFallback';
import { isAutomaticPageTranslationActive } from 'features/react-error-boundary/utils/isAutomaticPageTranslationActive';
import React, { useCallback } from 'react';

const GeneralErrorFallback = () => <ErrorFallback message="error_boundary.error.general" />;
const TranslationErrorFallback = () => (
  <ErrorFallback message="error_boundary.error.automatic_page_translation" />
);

type Props = {
  children: React.ReactNode;
};

export const ErrorBoundary = ({ children }: Props) => {
  const handleBeforeCapture = useCallback((scope: Scope) => {
    scope.setTag('error-scope', 'component');
  }, []);

  const fallbackElement = isAutomaticPageTranslationActive() ? (
    <TranslationErrorFallback />
  ) : (
    <GeneralErrorFallback />
  );

  return (
    <SentryErrorBoundary fallback={fallbackElement} beforeCapture={handleBeforeCapture}>
      {children}
    </SentryErrorBoundary>
  );
};
