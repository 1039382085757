import { Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { getRoutePath } from 'config/routes';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled from 'styled-components';
import { mobileWindowWidthCSS } from 'utils/hooks/useMobileVersion';
import useTr from 'utils/hooks/useTr';

type Props = {
  onClose?: () => void;
};

export const PricingPlanFooter = ({ onClose }: Props) => {
  const translate = useTr();
  const navigate = useNavigate();

  return (
    <Footer justifyContent="space-between" alignItems="center" direction="row">
      <FooterHintContainer direction="col">
        <FooterHint variant="caption">
          <FormattedMessage
            id="change_pricing_modal.footer.pricing_plans_hint"
            values={{
              a: (text: string) => (
                <StyledLink
                  href={translate('change_pricing_modal.footer.pricing_plans_hint_video_link')}
                  target="_blank"
                  rel="noreferrer"
                >
                  {text}
                </StyledLink>
              )
            }}
          />
        </FooterHint>
        <FooterHint variant="caption">
          <FormattedMessage
            id="change_pricing_modal.footer.compare_plans_hint"
            values={{
              a: (msg: string) => (
                <StyledLink
                  {...withGtmInteraction(gtmIds.modals.pricingDetailsLink)}
                  href={translate('external_links.app_landingpage_pricing_plans')}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {msg}
                </StyledLink>
              )
            }}
          />
        </FooterHint>
      </FooterHintContainer>
      <FooterHint variant="caption">
        <FormattedMessage
          id="change_pricing_modal.footer.get_bonus_words"
          values={{
            a: (msg: string) => (
              <LinkButton
                {...withGtmInteraction(gtmIds.modals.bonusLink)}
                onClick={() => {
                  onClose && onClose();
                  navigate(getRoutePath('bonusReferral'));
                }}
              >
                {msg}
              </LinkButton>
            )
          }}
        />
      </FooterHint>
    </Footer>
  );
};

const Footer = styled(FlexContainer)`
  width: 100%;
  margin-top: ${({ theme }) => theme.spacings.medium};

  @media (max-width: ${mobileWindowWidthCSS}) {
    flex-direction: column;
    gap: ${({ theme }) => theme.spacings.two};
    text-align: center;
  }
`;

const FooterHintContainer = styled(FlexContainer)`
  flex: 0 0 auto;
`;

const FooterHint = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.textSecondary};
  }
`;

const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.textSecondary};
  text-decoration: underline;
`;

const LinkButton = styled.a`
  color: ${({ theme }) => theme.colors.textSecondary};
  text-decoration: underline;
  cursor: pointer;
`;
