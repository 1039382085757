import { Link, Paper, Typography } from '@mui/material';
import backgroundDoodleOrnament from 'assets/background-doodle.png';
import { ReactComponent as ErrorImage } from 'assets/drawings/ai-images-preview-error.svg';
import { ReactComponent as NeuroflashLogo } from 'assets/neuroflashLogo.svg';
import FlexContainer from 'components/FlexContainer';
import { Ornament } from 'components/Ornament';
import { getRoutePath } from 'config/routes';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';
import styled from 'styled-components';

export function SharedImageError() {
  const navigate = useNavigate();

  return (
    <Root>
      <NeuroflashLogo />
      <Container>
        <FlexContainer gap="medium" alignItems="center">
          <Typography variant="h6">
            <FormattedMessage id="ai_images.preview.error.title" />
          </Typography>

          <ErrorImage />

          <Typography variant="body2">
            <FormattedMessage
              id="ai_images.preview.error.footer"
              values={{
                a: (msg: string) => (
                  <Link onClick={() => navigate(getRoutePath('free'))}>{msg}</Link>
                )
              }}
            />
          </Typography>
        </FlexContainer>

        <Ornament src={backgroundDoodleOrnament} bottom="0%" right="0%" />
      </Container>
    </Root>
  );
}
const Root = styled(FlexContainer).attrs({
  alignItems: 'center',
  gap: 'medium'
})`
  margin: ${({ theme }) => `${theme.spacings.xxxxlarge} auto ${theme.spacings.xxxxlarge} auto`};
`;

const Container = styled(Paper)`
  position: relative;
  height: fit-content;
  width: 650px;
  padding: ${({ theme }) => theme.spacings.large};
`;
