import { Checkbox, FormControlLabel, FormGroup, Link, Typography } from '@mui/material';
import { ColoredTypography } from 'components/ColoredTypography';
import FlexContainer from 'components/FlexContainer';
import { WarningHint } from 'components/profile/components/pricing-plan-card/WarningHint';
import { HelperTooltipIcon } from 'features/aiWriter/AiWriterBlogPostBuilder/steps/components/HelperTooltipIcon';
import { Elements, FormValues } from 'features/aiWriter/AiWriterBlogPostBuilder/types';
import { useFetchImagesLimitations } from 'features/aiWriter/AiWriterSidebar/steps/images/ImagesRequestsIndicator/useFetchImagesLimitations';
import { useFetchSeoLimitations } from 'features/aiWriter/AiWriterSidebar/steps/seoAnalysis/useFetchSeoLimitations';
import { useShowRunOutOfCreditsModal } from 'features/pricing/modals/FreeCreditsLimitReachedModal';
import {
  getPricingPlan,
  getSeoLimitations,
  getUnsplashImagesLimitations
} from 'features/pricing/store/selectors';
import { useField, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';

import * as CommonStyled from './common.styles';

const allElementsArray = [
  'images',
  'youtube_video',
  'seobility',
  'what_people_asked',
  'external_links'
];

const ProChipIcon = styled.div`
  background-color: ${({ theme }) => theme.colors.emailNotificationToolbarBackground};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.white};
  font-size: 10px;
  font-weight: 500;
  padding: 2px 8px;
`;

const ProChip = () => <ProChipIcon>Pro</ProChipIcon>;

export function ElementsStep(props: { onGoToKeywordsClick: () => void }) {
  const { onGoToKeywordsClick } = props;

  const { setFieldValue, values } = useFormikContext<FormValues>();
  const showRunOutOfCreditsModal = useShowRunOutOfCreditsModal();

  const pricingPlan = useAppSelector(getPricingPlan);

  const isUserOnFreePlan = ['free'].includes(pricingPlan);
  const [showProWarning, setShowProWarning] = useState(true);

  useFetchImagesLimitations();

  const imagesLimitations = useAppSelector(getUnsplashImagesLimitations);
  const isImagesOutOfCredits =
    imagesLimitations &&
    !imagesLimitations.isUnlimited &&
    imagesLimitations?.used >= imagesLimitations?.limit;

  useFetchSeoLimitations();
  const seoLimitations = useAppSelector(getSeoLimitations);

  const isSeoOutOfCredits =
    seoLimitations && !seoLimitations.isUnlimited && seoLimitations?.used >= seoLimitations?.limit;

  const isLimitedFeature = isImagesOutOfCredits || isSeoOutOfCredits;

  const isKeywordsNotProvided = values.keywords.length === 0;

  useEffect(() => {
    if (!isSeoOutOfCredits && !isLimitedFeature && !isKeywordsNotProvided) {
      setFieldValue('elements', allElementsArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function renderHint() {
    if (isKeywordsNotProvided) {
      return (
        <WarningHint color="primary100">
          <ColoredTypography>
            <FormattedMessage
              id="blog_post_builder.steps.elements.no_keywords_hint"
              values={{
                button: (text: string) => (
                  <BackLink onClick={onGoToKeywordsClick} type="button">
                    {text}
                  </BackLink>
                ),
                br: <br />
              }}
            />
          </ColoredTypography>
        </WarningHint>
      );
    }
    if (isLimitedFeature) {
      return (
        <WarningHint>
          <ColoredTypography color="warningRed">
            <FormattedMessage
              id="blog_post_builder.steps.elements.limit_hit_hint"
              values={{
                button: (text: string) => (
                  <UpgradeButton onClick={showRunOutOfCreditsModal}>{text}</UpgradeButton>
                )
              }}
            />
          </ColoredTypography>
        </WarningHint>
      );
    }
    if (isUserOnFreePlan && showProWarning) {
      return (
        <WarningHint color="primary100" onClose={() => setShowProWarning(false)} icon={<ProChip />}>
          <FormattedMessage
            id="blog_post_builder.steps.elements.upgrade_hint"
            values={{
              button: (text: string) => (
                <UpgradeText onClick={showRunOutOfCreditsModal}>{text}</UpgradeText>
              ),
              b: (text: string) => <b>{text}</b>
            }}
          />
        </WarningHint>
      );
    }
  }

  return (
    <Root>
      <HintContainer>{renderHint()}</HintContainer>
      <FlexContainer gap="medium">
        <CommonStyled.Title variant="subtitle1">
          <FormattedMessage id="blog_post_builder.steps.elements.title" />
        </CommonStyled.Title>
        <MultimediaSection
          imagesDisabled={isImagesOutOfCredits}
          videoDisabled={isKeywordsNotProvided}
          isFreePlan={isUserOnFreePlan}
        />
        <SeoSection
          featureDisabled={isKeywordsNotProvided}
          seoDisabled={isSeoOutOfCredits || isKeywordsNotProvided}
          isFreePlan={isUserOnFreePlan}
        />
        <ReferencesSection
          isFeatureDisabled={isKeywordsNotProvided}
          isFreePlan={isUserOnFreePlan}
        />
      </FlexContainer>
    </Root>
  );
}

const Root = styled.div`
  position: relative;
`;

const HintContainer = styled.div`
  position: absolute;
  top: -130px;
`;

const UpgradeButton = styled.button`
  background: none;
  text-decoration: underline;
  border: none;
  padding: 0;
  color: ${({ theme }) => theme.colors.warningRed};
`;

const UpgradeText = styled.button`
  background: none;
  text-decoration: underline;
  border: none;
  padding: 0;
  font-size: 1rem;
  color: #444444;
`;

const ProLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

const BackLink = styled(Link)`
  cursor: pointer;
`;

function MultimediaSection(props: {
  featureDisabled?: boolean;
  imagesDisabled?: boolean;
  videoDisabled?: boolean;
  isFreePlan: boolean;
}) {
  const { featureDisabled, imagesDisabled, videoDisabled, isFreePlan } = props;

  const isImagesDisabled = imagesDisabled;
  const isVideoDisabled = featureDisabled || videoDisabled;

  return (
    <section>
      <FlexContainer gap="small" direction="row">
        <Typography variant="subtitle1">
          <FormattedMessage id="blog_post_builder.steps.elements.multimedia" />
        </Typography>
        <HelperTooltipIcon tooltipId="blog_post_builder.steps.elements.multimedia.info" />
      </FlexContainer>
      <CheckboxGroup>
        <FormControlLabel
          control={
            <FieldCheckbox fieldName="elements" value="images" disabled={isImagesDisabled} />
          }
          label={
            <Typography variant="body1">
              <FormattedMessage id="blog_post_builder.steps.elements.multimedia.image_check" />
            </Typography>
          }
        />
        <FormControlLabel
          control={
            <FieldCheckbox fieldName="elements" value="youtube_video" disabled={isVideoDisabled} />
          }
          label={
            <ProLabelContainer>
              <Typography variant="body1">
                <FormattedMessage id="blog_post_builder.steps.elements.multimedia.video_check" />
              </Typography>
              {isFreePlan && <ProChip />}
            </ProLabelContainer>
          }
        />
      </CheckboxGroup>
    </section>
  );
}

function SeoSection(props: {
  featureDisabled?: boolean;
  seoDisabled?: boolean;
  isFreePlan: boolean;
}) {
  const { featureDisabled, seoDisabled, isFreePlan } = props;

  const disabled = featureDisabled || seoDisabled;

  return (
    <section>
      <FlexContainer gap="small" direction="row">
        <Typography variant="subtitle1">
          <FormattedMessage id="blog_post_builder.steps.elements.seo" />
        </Typography>
        <HelperTooltipIcon tooltipId="blog_post_builder.steps.elements.seo.info" />
      </FlexContainer>
      <CheckboxGroup>
        <FormControlLabel
          control={<FieldCheckbox fieldName="elements" value="seobility" disabled={disabled} />}
          label={
            <ProLabelContainer>
              <Typography variant="body1">
                <FormattedMessage id="blog_post_builder.steps.elements.seo_check" />
              </Typography>
              {isFreePlan && <ProChip />}
            </ProLabelContainer>
          }
        />
        <FormControlLabel
          control={
            <FieldCheckbox fieldName="elements" value="what_people_asked" disabled={disabled} />
          }
          label={
            <ProLabelContainer>
              <Typography variant="body1">
                <FormattedMessage id="blog_post_builder.steps.elements.references.others_check" />
              </Typography>
              {isFreePlan && <ProChip />}
            </ProLabelContainer>
          }
        />
      </CheckboxGroup>
    </section>
  );
}

function ReferencesSection(props: { isFeatureDisabled?: boolean; isFreePlan: boolean }) {
  const { isFeatureDisabled, isFreePlan } = props;
  const disabled = isFeatureDisabled;
  return (
    <section>
      <FlexContainer gap="small" direction="row">
        <Typography variant="subtitle1">
          <FormattedMessage id="blog_post_builder.steps.elements.references" />
        </Typography>
        <HelperTooltipIcon tooltipId="blog_post_builder.steps.elements.references.info" />
      </FlexContainer>
      <CheckboxGroup>
        <FormControlLabel
          control={
            <FieldCheckbox fieldName="elements" value="external_links" disabled={disabled} />
          }
          label={
            <ProLabelContainer>
              <Typography variant="body1">
                <FormattedMessage id="blog_post_builder.steps.elements.references.ref_check" />
              </Typography>
              {isFreePlan && <ProChip />}
            </ProLabelContainer>
          }
        />
      </CheckboxGroup>
    </section>
  );
}

function FieldCheckbox(props: { fieldName: string; value: Elements; disabled?: boolean }) {
  const { fieldName, value, disabled } = props;
  const [field] = useField(fieldName);

  return (
    <Checkbox {...field} checked={field.value.includes(value)} value={value} disabled={disabled} />
  );
}
const CheckboxGroup = styled(FormGroup)`
  margin-left: ${({ theme }) => theme.spacings.medium};
`;
