import { Table, TableBody, TableRow, Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import {
  FirstCell,
  StyledCell,
  StyledTable
} from 'components/profile/components/pricing-plan-card/FeatureComparisonSection';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const createUsageCreditsData = (
  name: string,
  standardUsage: string,
  proUsage: string,
  enterpriseUsage: string
) => {
  return { name, standardUsage, proUsage, enterpriseUsage };
};

const usageCreditsData = [
  createUsageCreditsData('words', 'standard_usage ', 'pro_usage ', 'enterprise_usage '),
  createUsageCreditsData('images', 'standard_usage ', 'pro_usage ', 'enterprise_usage ')
];

export const UsageCreditsTable = () => {
  return (
    <StyledTable>
      <Table>
        <TableBody>
          {usageCreditsData.map(row => (
            <TableRow key={row.name}>
              <FirstCell component="th" scope="row">
                <FormattedMessage id={`features_comparison.usage_credits.${row.name}`} />
              </FirstCell>
              <StyledCell>
                <FlexContainer direction="row" justifyContent="center">
                  <Typography variant="body1Bold">
                    <FormattedMessage id="features_comparison.usage_credits.standard_usage" />
                  </Typography>
                  &nbsp;
                  <DisabledText variant="body1">
                    <FormattedMessage id="features_comparison.usage_credits.per_month" />
                  </DisabledText>
                </FlexContainer>
              </StyledCell>
              <StyledCell>
                <FlexContainer direction="row" justifyContent="center">
                  <Typography variant="body1Bold">
                    <FormattedMessage id="features_comparison.usage_credits.pro_usage" />
                  </Typography>
                  &nbsp;
                  <DisabledText variant="body1">
                    <FormattedMessage id="features_comparison.usage_credits.per_month" />
                  </DisabledText>
                </FlexContainer>
              </StyledCell>
              <StyledCell>
                <FlexContainer direction="row" justifyContent="center">
                  <Typography variant="body1Bold">
                    <FormattedMessage id="features_comparison.usage_credits.enterprise_usage" />
                  </Typography>
                  &nbsp;
                  <DisabledText variant="body1">
                    <FormattedMessage id="features_comparison.usage_credits.per_month" />
                  </DisabledText>
                </FlexContainer>
              </StyledCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </StyledTable>
  );
};

const DisabledText = styled(Typography)`
  color: ${({ theme }) => theme.colors.blackDisabled};
`;
