import { PopoverProps } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { ContentImagesTabs } from 'features/aiWriter/AiWriterSidebar/steps/ImagesStep';
import {
  getInspirationsCurrentStep,
  getInspirationsCurrentSubStep
} from 'features/aiWriter/store/selectors';
import { AiWriterSidebarStep } from 'features/aiWriter/store/types';
import { getGetIsFeatureAvailable } from 'features/features/store/selectors';
import { UsageIndicator } from 'features/usageIndicator/UsageIndicator';
import { UsageIndicatorPopover } from 'features/usageIndicator/UsageIndicatorPopover';
import { UsageIndicatorType } from 'features/usageIndicator/UsageIndicatorType';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { useMenu } from 'utils/hooks/useMenu';

type IndicatorConfiguration = {
  type: UsageIndicatorType;
  hasAccess: boolean;
};

const useGetCurrentIndicatorConfiguration = (): IndicatorConfiguration => {
  const hasAccessPlagiarismChecker = useAppSelector(getGetIsFeatureAvailable)('plagiarismChecker');
  const hasAccessSeoAnalysis = useAppSelector(getGetIsFeatureAvailable)('seoAnalysis');

  const currentSidebarStep = useAppSelector(getInspirationsCurrentStep);
  const currentSidebarSubStep = useAppSelector(getInspirationsCurrentSubStep);
  switch (currentSidebarStep) {
    case AiWriterSidebarStep.aiWriter:
      return { type: UsageIndicatorType.textGeneration, hasAccess: true };
    case AiWriterSidebarStep.chat:
      return { type: UsageIndicatorType.chat, hasAccess: true };
    case AiWriterSidebarStep.images: {
      if (currentSidebarSubStep === ContentImagesTabs.unsplashImages) {
        return { type: UsageIndicatorType.unsplashImages, hasAccess: true };
      }
      return { type: UsageIndicatorType.aiImages, hasAccess: true };
    }
    case AiWriterSidebarStep.seoAnalysis:
      return { type: UsageIndicatorType.seoAnalysis, hasAccess: hasAccessSeoAnalysis };
    case AiWriterSidebarStep.plagiarism:
      return { type: UsageIndicatorType.plagiarismCheck, hasAccess: hasAccessPlagiarismChecker };
    case AiWriterSidebarStep.flashScore:
      return { type: UsageIndicatorType.textGeneration, hasAccess: true };
  }

  // Words are also the fallback
  return { type: UsageIndicatorType.textGeneration, hasAccess: true };
};

type Props = Omit<PopoverProps, 'anchorEl' | 'open' | 'onClose'>;

export const UsageIndicatorWithPopover = (props: Props) => {
  const { anchorEl, isOpen, onMenuClose, onTriggerClick } = useMenu();

  const { type: indicatorType, hasAccess } = useGetCurrentIndicatorConfiguration();

  return (
    <>
      <UsageIndicatorContainer
        {...withGtmInteraction(gtmIds.aiWriter.limitDropdown.open)}
        alignItems="center"
        justifyContent="center"
        onClick={onTriggerClick}
      >
        <UsageIndicator type={indicatorType} hasAccess={hasAccess} />
      </UsageIndicatorContainer>

      <UsageIndicatorPopover {...props} anchorEl={anchorEl} open={isOpen} onClose={onMenuClose} />
    </>
  );
};

const UsageIndicatorContainer = styled(FlexContainer)`
  min-width: 144px;
  cursor: pointer;
`;
