import ArticleIcon from '@mui/icons-material/Article';
import SchoolIcon from '@mui/icons-material/School';
import { Button, Link } from '@mui/material';
import CapterraImage from 'assets/bonus-referral-images/referral-capterra.png';
import G2Image from 'assets/bonus-referral-images/referral-g2.png';
import InstagramImage from 'assets/bonus-referral-images/referral-instagram.png';
import LinkedinImage from 'assets/bonus-referral-images/referral-linkedin.png';
import OmrImage from 'assets/bonus-referral-images/referral-omr.png';
import TiktiokImage from 'assets/bonus-referral-images/referral-tiktok.png';
import TrustpilotImage from 'assets/bonus-referral-images/referral-trustpilot.png';
import YoutubeImage from 'assets/bonus-referral-images/referral-youtube.png';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { ReferralCard } from 'pages/bonusReferral/ReferalCard';
import useTr from 'utils/hooks/useTr';

const withBoldSupport = {
  b: (msg: string) => <strong>{msg}</strong>
};

const WriteReviewLink = (props: { href: string }) => (
  <ReferralCard.CtaExternalLink
    href={props.href}
    labelId="bonus_referral.cards.review_button.label"
  />
);

const SharePostLink = (props: { href: string }) => (
  <ReferralCard.CtaExternalLink
    href={props.href}
    labelId="bonus_referral.cards.post_button.label"
  />
);

const withEmailLink = {
  mail: (msg: string) => (
    <Link href={`mailto:${msg}`} target="_blank" rel="noreferrer">
      {msg}
    </Link>
  )
};

const PostSubmitInfo = () => (
  <ReferralCard.SubmitInfo>
    <FormattedMessage id="bonus_referral.cards.post_submit_info" values={withEmailLink} />
  </ReferralCard.SubmitInfo>
);

const ReviewSubmitInfo = () => (
  <ReferralCard.SubmitInfo>
    <FormattedMessage id="bonus_referral.cards.review_submit_info" values={withEmailLink} />
  </ReferralCard.SubmitInfo>
);

export const OmrCard = () => (
  <ReferralCard>
    <ReferralCard.Logo src={OmrImage} alt="OMR Reviews" />
    <ReferralCard.Explanation>
      <FormattedMessage
        id="bonus_referral.cards.omr_reviews.explanation"
        values={withBoldSupport}
      />
    </ReferralCard.Explanation>
    <WriteReviewLink href="https://omr.com/de/reviews/product/neuroflash" />
    <ReviewSubmitInfo />
  </ReferralCard>
);

export const TrustpilotCard = () => {
  const translate = useTr();
  return (
    <ReferralCard>
      <ReferralCard.Logo src={TrustpilotImage} alt="Trustpilot" />
      <ReferralCard.Explanation>
        <FormattedMessage
          id="bonus_referral.cards.trustpilot.explanation"
          values={withBoldSupport}
        />
      </ReferralCard.Explanation>
      <WriteReviewLink href={translate('bonus_referral.cards.trustpilot.cta.url')} />
      <ReviewSubmitInfo />
    </ReferralCard>
  );
};

export const G2Card = () => (
  <ReferralCard>
    <ReferralCard.Logo src={G2Image} alt="G2" />
    <ReferralCard.Explanation>
      <FormattedMessage id="bonus_referral.cards.g2.explanation" values={withBoldSupport} />
    </ReferralCard.Explanation>
    <WriteReviewLink href="https://www.g2.com/products/neuroflash/take_survey" />
    <ReviewSubmitInfo />
  </ReferralCard>
);

export const CapterraCard = () => (
  <ReferralCard>
    <ReferralCard.Logo src={CapterraImage} alt="Capterra" />
    <ReferralCard.Explanation>
      <FormattedMessage id="bonus_referral.cards.capterra.explanation" values={withBoldSupport} />
    </ReferralCard.Explanation>
    <WriteReviewLink href="https://reviews.capterra.com/new/207149" />
    <ReviewSubmitInfo />
  </ReferralCard>
);

export const LinkedinCard = () => (
  <ReferralCard>
    <ReferralCard.Logo src={LinkedinImage} alt="LinkedIn" />
    <ReferralCard.Explanation>
      <FormattedMessage id="bonus_referral.cards.linkedin.explanation" values={withBoldSupport} />
    </ReferralCard.Explanation>
    <SharePostLink href="https://www.linkedin.com/company/neuroflash/" />
    <PostSubmitInfo />
  </ReferralCard>
);

export const InstagramCard = () => (
  <ReferralCard>
    <ReferralCard.Logo src={InstagramImage} alt="Instagram" />
    <ReferralCard.Explanation>
      <FormattedMessage id="bonus_referral.cards.instagram.explanation" values={withBoldSupport} />
    </ReferralCard.Explanation>
    <SharePostLink href="https://www.instagram.com/" />
    <PostSubmitInfo />
  </ReferralCard>
);

export const TiktokCard = () => (
  <ReferralCard>
    <ReferralCard.Logo src={TiktiokImage} alt="TikTok" />
    <ReferralCard.Explanation>
      <FormattedMessage id="bonus_referral.cards.tiktok.explanation" values={withBoldSupport} />
    </ReferralCard.Explanation>
    <SharePostLink href="https://www.tiktok.com/" />
    <PostSubmitInfo />
  </ReferralCard>
);

export const YoutubeCard = () => (
  <ReferralCard>
    <ReferralCard.Logo src={YoutubeImage} alt="YouTube" />
    <ReferralCard.Explanation>
      <FormattedMessage id="bonus_referral.cards.youtube.explanation" values={withBoldSupport} />
    </ReferralCard.Explanation>
    <SharePostLink href="https://www.youtube.com/" />
    <PostSubmitInfo />
  </ReferralCard>
);

export const BlogArticleCard = () => (
  <ReferralCard>
    <ReferralCard.Header
      icon={<ArticleIcon />}
      text={<FormattedMessage id="bonus_referral.cards.blog_article.heading" />}
    ></ReferralCard.Header>
    <ReferralCard.Explanation>
      <FormattedMessage
        id="bonus_referral.cards.blog_article.explanation"
        values={withBoldSupport}
      />
    </ReferralCard.Explanation>
    <PostSubmitInfo />
  </ReferralCard>
);

export const BootcampCard = () => {
  const translate = useTr();

  return (
    <ReferralCard>
      <ReferralCard.Header
        icon={<SchoolIcon />}
        text={<FormattedMessage id="bonus_referral.cards.bootcamp.heading" />}
      ></ReferralCard.Header>
      <ReferralCard.Explanation>
        <FormattedMessage id="bonus_referral.cards.bootcamp.explanation" values={withBoldSupport} />
      </ReferralCard.Explanation>
      <Button
        variant="outlined"
        onClick={() =>
          window.open(translate('bonus_referral.cards.bootcamp.certificate.link'), '_blank')
        }
      >
        <FormattedMessage id="bonus_referral.cards.bootcamp.certificate.button" />
      </Button>
      <ReferralCard.SubmitInfo>
        <FormattedMessage
          id="bonus_referral.cards.bootcamp.post_submit_info"
          values={withEmailLink}
        />
      </ReferralCard.SubmitInfo>
    </ReferralCard>
  );
};
