import brandvoiceDeImage from 'assets/onboarding/de/brandvoice.png';
import chatDeImage from 'assets/onboarding/de/chat.png';
import imagesDeImage from 'assets/onboarding/de/images.png';
import textTemplatesDeImage from 'assets/onboarding/de/text-templates.png';
import workflowsDeImage from 'assets/onboarding/de/workflows.png';
import brandvoiceEnImage from 'assets/onboarding/en/brandvoice.png';
import chatEnImage from 'assets/onboarding/en/chat.png';
import imagesEnImage from 'assets/onboarding/en/images.png';
import textTemplatesEnImage from 'assets/onboarding/en/text-templates.png';
import workflowsEnImage from 'assets/onboarding/en/workflows.png';
import brandvoiceEsImage from 'assets/onboarding/es/brandvoice.png';
import chatEsImage from 'assets/onboarding/es/chat.png';
import imagesEsImage from 'assets/onboarding/es/images.png';
import textTemplatesEsImage from 'assets/onboarding/es/text-templates.png';
import workflowsEsImage from 'assets/onboarding/es/workflows.png';
import { getRoutePath } from 'config/routes';
import useShowSetupModal from 'features/aiWriter/modals/setup/useSetupModal';
import { setBuilderInitLanguage } from 'features/aiWriter/store/actions/builder/actions';
import {
  setInitTextLibraryCategory,
  setInspirationsCurrentStep
} from 'features/aiWriter/store/actions/config/actions';
import { useUpdateCustomerPreferences } from 'features/customerPreferences/useUpdateCustomerPreferences';
import { getCurrentLanguage } from 'features/language/store/selectors';
import { AppLanguage } from 'features/language/store/types';
import { setOnboardingUseCase } from 'features/onboardingJourney/checklist/useOnboardingChecklist';
import { useSurveyAnswers } from 'features/onboardingJourney/newUserSurvey/useSurveyAnswers';
import { useCreateNewDocumentForImages } from 'features/onboardingJourney/useCreateNewDocumentForImages';
import { useLanguageCountryFromSurveyAnswer } from 'features/onboardingJourney/useLanguageCountryFromSurveyAnswer';
import { useSidebarStore } from 'features/pageSidebar/useSidebarStore';
import { trackingWrapper } from 'features/tracking/wrapper/TrackingWrapper';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { GAEvents } from 'services/tracking/GAEvents';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { AiWriterWorkflowsType } from 'types/AiWriterWorkflows';
import useTr from 'utils/hooks/useTr';

export const Steps = {
  brandPersonality: 'brandPersonality',
  workflows: 'workflows',
  chatflash: 'chatflash',
  prompts: 'prompts',
  aiImages: 'aiImages'
};

type Action = {
  title: string;
  onClick?: () => void;
};

export type ActionImageSection = {
  type: string;
  title: string;
  subtitle?: string;
  images: string[];
  imageAlt: string;
  actions: Action[];
};

export const useActionImageSectionTile = (): ActionImageSection[] => {
  const translate = useTr();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const showSetupModal = useShowSetupModal();
  const surveyAnswers = useSurveyAnswers();
  const createNewDocumentForImages = useCreateNewDocumentForImages();
  const collapseSidebar = useSidebarStore(state => state.collapseSidebar);
  const selectedLanguageAndCountry = useLanguageCountryFromSurveyAnswer();

  const { mutateAsync: updateCustomerPreferences } = useUpdateCustomerPreferences();

  const translateImages = useTranslatedImages();

  // This is needed to be done like this to make sure that we don’t run into a timing issue with navigating
  // a user back to onboarding too quickly (happens in useNavigateToOnboarding hook) after the preferences
  // update in onboarding-selection step.
  useEffect(() => {
    updateCustomerPreferences({ newUserSurveySeen: true });
  }, [updateCustomerPreferences]);

  const handleBuilderClick = (workflow: AiWriterWorkflowsType, trackingEvent: string) => {
    trackingWrapper.track(trackingEvent);
    dispatch(
      setBuilderInitLanguage({
        builder: workflow,
        language: selectedLanguageAndCountry ?? undefined
      })
    );

    const routeName =
      workflow === AiWriterWorkflowsType.BlogPost ? 'blogPostWorkflow' : 'socialPostWorkflow';
    navigate(getRoutePath(routeName));
    dispatch(setInspirationsCurrentStep(undefined));
    collapseSidebar();
  };
  const handleBlogPostWorkflowClick = () => {
    GAEvents.onboardingSelectionBlogWorkflowClick();
    setOnboardingUseCase('blogPostWorkflow');
    handleBuilderClick(AiWriterWorkflowsType.BlogPost, 'aiWriterOverviewSeoBlogBuilderStarted');
  };

  const handleSocialMediaWorkflowClick = () => {
    GAEvents.onboardingSelectionSocialWorkflowClick();
    setOnboardingUseCase('socialPostWorkflow');
    handleBuilderClick(
      AiWriterWorkflowsType.SocialPost,
      'aiWriterOverviewSocialMediaBuilderStarted'
    );
  };

  const handleChatFlashClick = () => {
    GAEvents.onboardingSelectionChatflashClick();
    setOnboardingUseCase('chatFlash');
    navigate(`${getRoutePath('newDocument')}#noEmptyDocumentMenu&chatFlash`);
  };

  const handleImageFlashClick = () => {
    GAEvents.onboardingSelectionAiImagesClick();
    setOnboardingUseCase('imageFlash');
    navigate(`${getRoutePath('newDocument')}#noEmptyDocumentMenu&imageFlash`);
    createNewDocumentForImages();
  };

  const handlePromptLibraryClick = () => {
    GAEvents.onboardingSelectionPromptLibraryClick();
    setOnboardingUseCase('promptLibrary');
    // Note: This won't work if the category is named different that the use case
    // e.g. "Social Media Posts" as use case but "Social Media" as category name in the modal
    dispatch(setInitTextLibraryCategory(surveyAnswers.data?.content_type));
    navigate(`${getRoutePath('newDocument')}#noEmptyDocumentMenu&promptLibrary`);
    showSetupModal();
  };

  const handlePersonalityClick = () => {
    GAEvents.onboardingSelectionUsePersonalityClick();
    setOnboardingUseCase('personality');
    navigate(`${getRoutePath('newDocument')}#noEmptyDocumentMenu&chatFlash`);
  };

  return [
    {
      type: Steps.brandPersonality,
      images: translateImages({
        [AppLanguage.English]: [brandvoiceEnImage],
        [AppLanguage.German]: [brandvoiceDeImage],
        [AppLanguage.Spanish]: [brandvoiceEsImage]
      }),
      imageAlt: translate('onboarding.selection.brandPersonality.imageAlt'),
      title: translate('onboarding.selection.brandPersonality.title'),
      subtitle: translate('onboarding.selection.brandPersonality.subtitle'),
      actions: [
        {
          title: translate('onboarding.selection.brandPersonality.button'),
          onClick: handlePersonalityClick
        }
      ]
    },
    {
      type: Steps.workflows,
      images: translateImages({
        [AppLanguage.English]: [workflowsEnImage],
        [AppLanguage.German]: [workflowsDeImage],
        [AppLanguage.Spanish]: [workflowsEsImage]
      }),
      imageAlt: translate('onboarding.selection.workflows.imageAlt'),
      title: translate('onboarding.selection.workflows.title'),
      subtitle: translate('onboarding.selection.workflows.subtitle'),
      actions: [
        {
          title: translate('onboarding.selection.workflows.blog.button'),
          onClick: handleBlogPostWorkflowClick
        },
        {
          title: translate('onboarding.selection.workflows.social.button'),
          onClick: handleSocialMediaWorkflowClick
        }
      ]
    },
    {
      type: Steps.chatflash,
      images: translateImages({
        [AppLanguage.English]: [chatEnImage],
        [AppLanguage.German]: [chatDeImage],
        [AppLanguage.Spanish]: [chatEsImage]
      }),
      imageAlt: translate('onboarding.selection.chatflash.imageAlt'),
      title: translate('onboarding.selection.chatflash.title'),
      subtitle: translate('onboarding.selection.chatflash.subtitle'),
      actions: [
        {
          title: translate('onboarding.selection.chatflash.button'),
          onClick: handleChatFlashClick
        }
      ]
    },
    {
      type: Steps.prompts,
      images: translateImages({
        [AppLanguage.English]: [textTemplatesEnImage],
        [AppLanguage.German]: [textTemplatesDeImage],
        [AppLanguage.Spanish]: [textTemplatesEsImage]
      }),
      imageAlt: translate('onboarding.selection.prompts.imageAlt'),
      title: translate('onboarding.selection.prompts.title'),
      subtitle: translate('onboarding.selection.prompts.subtitle'),
      actions: [
        {
          title: translate('onboarding.selection.prompts.button'),
          onClick: handlePromptLibraryClick
        }
      ]
    },
    {
      type: Steps.aiImages,
      images: translateImages({
        [AppLanguage.English]: [imagesEnImage],
        [AppLanguage.German]: [imagesDeImage],
        [AppLanguage.Spanish]: [imagesEsImage]
      }),
      imageAlt: translate('onboarding.selection.aiImages.imageAlt'),
      title: translate('onboarding.selection.aiImages.title'),
      subtitle: translate('onboarding.selection.aiImages.subtitle'),
      actions: [
        {
          title: translate('onboarding.selection.aiImages.button'),
          onClick: handleImageFlashClick
        }
      ]
    }
  ];
};

export const useStartFromScratchStep = () => {
  const translate = useTr();
  const navigate = useNavigate();

  const handleExplore = () => {
    GAEvents.onboardingSelectionExploreClick();
    navigate(getRoutePath('home'));
  };

  return {
    title: translate('onboarding.selection.fromScratch.title'),
    action: handleExplore
  };
};

const useTranslatedImages = () => {
  const appLanguage = useAppSelector(getCurrentLanguage);

  return (imageSets: Partial<Record<AppLanguage, string[]>>) => {
    return imageSets[appLanguage] ?? imageSets[AppLanguage.English] ?? [];
  };
};
