import { PromptFieldType } from 'features/textGenerator/store/types';
import { getFieldPrefillValue } from 'features/textGenerator/utils/getFieldPrefillValue';
import { useGetPrompts } from 'features/textGenerator/utils/useGetPrompts';
import { useMemo } from 'react';

export const useGetOutputTypeOptionsByLanguageAndCountry = (language: string, country: string) => {
  const prompts = useGetPrompts();

  return useMemo(
    () =>
      prompts
        .filter(p => p.displayInSelect && p.language === language && p.country === country)
        .map(p => ({
          outputType: {
            ...p.outputType,
            youTubeVideoId: p.videoId ?? undefined
          },
          category: p.outputTypeCategory,
          description: p.outputTypeDescription
        })),
    [prompts, language, country]
  );
};

// Possible to use in generate text logic only when the remaining redux-related logic can be removed
// export const useGetPromptConfigByLanguageAndOutputType = (
//   modelLanguage: string,
//   outputTypeId: string
// ) => {
//   const { data } = useGetPrompts();
//   const prompts = data?.data.map(promptFromApi) ?? [];

//   return prompts.find(p => p.language === modelLanguage && p.outputType.id === outputTypeId);
// };

export const useGetPromptConfigByLanguageCountryAndOutputType = () => {
  const prompts = useGetPrompts();

  return (language: string, country: string, outputTypeId: string) => {
    return prompts.find(
      p => p.language === language && p.country === country && p.outputType.id === outputTypeId
    );
  };
};

// TODO: further refactor to cache version of the prompts can be done
export const useGetPromptConfigByLanguageCountryAndOutputTypeCached = (
  language: string,
  country: string,
  outputTypeId: string
) => {
  const prompts = useGetPrompts();

  return useMemo(
    () =>
      prompts.find(
        p => p.language === language && p.country === country && p.outputType.id === outputTypeId
      ),
    [prompts, language, country, outputTypeId]
  );
};

export const useGetOutputLabel = () => {
  const prompts = useGetPrompts();

  return (modelLanguage: string, outputType: string) => {
    return (
      prompts.find(p => p.outputType.id === outputType && p.language === modelLanguage)?.outputType
        .label ?? undefined
    );
  };
};

export const useGetDefaultOutputTypeByLanguage = () => {
  const prompts = useGetPrompts();
  const defaultOutputType = 'product_description_descriptive';

  return (language: string) => {
    return (
      prompts.find(p => p.language === language && p.outputType.id === defaultOutputType)
        ?.outputType.id ?? prompts[0]?.outputType.id
    );
  };
};

export const useGetDefaultOutputTypeByLanguageCached = (language: string) => {
  const prompts = useGetPrompts();
  const defaultOutputType = 'product_description_descriptive';

  return useMemo(
    () =>
      prompts.find(p => p.language === language && p.outputType.id === defaultOutputType)
        ?.outputType.id ?? prompts[0]?.outputType.id,
    [language, prompts]
  );
};

export const useGetDefaultOutputTypeByLanguageCountry = () => {
  const prompts = useGetPrompts();
  const defaultOutputType = 'product_description_descriptive';

  return (language: string, country: string) =>
    prompts.find(
      p => p.language === language && p.country === country && p.outputType.id === defaultOutputType
    )?.outputType.id ?? prompts[0]?.outputType.id;
};

export const useGetPrefillValueByLanguageAndOutputType = () => {
  const prompts = useGetPrompts();

  return (modelLanguage: string, outputType: string, fieldType: PromptFieldType) => {
    const prompt = prompts.find(
      p => p.language === modelLanguage && p.outputType.id === outputType
    );

    return getFieldPrefillValue(prompt, fieldType);
  };
};

export const useGetPrefillValueByLanguageCountryAndOutputType = () => {
  const prompts = useGetPrompts();

  return (
    modelLanguage: string,
    modelCountry: string,
    outputType: string,
    fieldType: PromptFieldType
  ) => {
    const prompt = prompts.find(
      p =>
        p.language === modelLanguage && p.country === modelCountry && p.outputType.id === outputType
    );

    return getFieldPrefillValue(prompt, fieldType);
  };
};

export const useGetNTimesByOutputType = () => {
  const prompts = useGetPrompts();

  return (outputType: string) => prompts.find(p => p.outputType.id === outputType)?.nTimes ?? 1;
};

export const useGetNTimesByOutputTypeCached = (outputType: string) => {
  const prompts = useGetPrompts();

  return useMemo(
    () => prompts.find(p => p.outputType.id === outputType)?.nTimes ?? 1,
    [outputType, prompts]
  );
};

export const useIsOutputTypeSupported = () => {
  const prompts = useGetPrompts();

  return ({
    outputType,
    country,
    language
  }: {
    outputType: string;
    language: string;
    country: string;
  }) =>
    prompts.some(
      p => p.outputType.id === outputType && p.language === language && p.country === country
    );
};
