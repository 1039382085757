import { NewThemeConfig } from 'styles/new-theme/newThemeConfig';
import { newThemeConfigDarkMode } from 'styles/new-theme/newThemeConfigDarkMode';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type AppThemeStoreType = {
  themeMode: 'dark' | 'light';
  themeConfig: NewThemeConfig;
};

type AppThemeStoreActions = {
  setThemeMode: (themeMode: 'dark' | 'light') => void;
  setThemeConfig: (themeConfig: NewThemeConfig) => void;
};

export const useAppThemeStore = create<AppThemeStoreType & AppThemeStoreActions>()(
  persist(
    set => ({
      themeMode: 'light',
      themeConfig: newThemeConfigDarkMode,
      setThemeMode: themeMode => set({ themeMode }),
      setThemeConfig: themeConfig => set({ themeConfig })
    }),
    {
      name: 'app-theme-store'
    }
  )
);
