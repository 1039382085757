import { connect, FormikProps } from 'formik';
import { debounce } from 'lodash';
import { Component } from 'react';
import isEqual from 'react-fast-compare';

type Props = {
  formik: FormikProps<Record<string, unknown>>;
  waitTime?: number;
  disableOnUpdate?: boolean;
};

class AutoSaveFormik extends Component<Props> {
  changed = debounce((props: FormikProps<Record<string, unknown>>) => {
    props.submitForm();
  }, this.props.waitTime || 300);

  componentDidMount() {
    if (this.props.formik.validateOnMount) {
      this.changed(this.props.formik);
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (
      !isEqual(prevProps.formik.values, this.props.formik.values) &&
      !this.props.disableOnUpdate
    ) {
      this.changed(this.props.formik);
    }
  }

  render() {
    return null;
  }
}

export default connect<{ waitTime?: number; disableOnUpdate?: boolean }>(AutoSaveFormik);
