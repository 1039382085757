import { Typography } from '@mui/material';
import { StyledTextareaAutosize } from 'components/StyledTextareaAutosize';
import { BaseField } from 'features/modular-workflow/runner/fields/BaseField';
import { FieldRendererContext } from 'features/modular-workflow/runner/fields/FieldRenderer';
import { useContext, useRef, useState } from 'react';
import styled from 'styled-components';

const maxInputLength = 3000;

export const TextInputField = () => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const { input, onChange, inputErrorList } = useContext(FieldRendererContext);

  const [localValue, setLocalValue] = useState<string>((input.value as string) || '');

  const hasError = inputErrorList.length > 0;

  return (
    <BaseField>
      {elementId => (
        <>
          <StyledTextareaAutosize
            ref={textAreaRef}
            id={elementId}
            defaultValue={input.value as string}
            placeholder={input.description}
            // Matches roughly 400px
            maxRows={15}
            onChange={event => {
              const newValue = event.target.value;
              if (newValue.length > maxInputLength) {
                return;
              }

              setLocalValue(newValue);
              onChange(input, newValue);
            }}
            $hasError={hasError}
          />
          <CounterBox>
            {inputErrorList.length > 0 ? (
              <div>
                {inputErrorList.map(({ errorMessage }) => (
                  <Typography key={Math.random()} variant="caption" color="error">
                    {errorMessage}
                  </Typography>
                ))}
              </div>
            ) : (
              <div />
            )}
            <Typography variant="caption" color={hasError ? 'error' : undefined}>
              {localValue.length}/{maxInputLength}
            </Typography>
          </CounterBox>
        </>
      )}
    </BaseField>
  );
};

const CounterBox = styled.div`
  display: flex;
  justify-content: space-between;
`;
