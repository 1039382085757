import AddIcon from '@mui/icons-material/Add';
import { Button, CircularProgress, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import bannerImage from 'assets/brandHub/template-banner.png';
import { SearchField } from 'components/SearchField';
import { TemplatesFiltering } from 'features/aiWriter/commandTemplates/TemplatesFiltering';
import { useShowTemplatesModal } from 'features/aiWriter/commandTemplates/useShowTemplatesModal';
import { EmbeddingModel } from 'features/embeddingModels/store/types';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { BannerLayout } from 'pages/brand-hub/BannerLayout';
import { FilterButton } from 'pages/brand-hub/FilterButton';
import { LanguageFilter } from 'pages/brand-hub/languageFilter';
import { BrandHubTemplatesTable } from 'pages/brand-hub/templatesTable';
import { ChangeEvent, useState } from 'react';
import {
  FilteringTypes,
  GetCommandTemplatesParams,
  httpGetCommandTemplates,
  SortingTypes
} from 'services/backofficeIntegration/http/endpoints/textGeneration/commandTemplates/httpGetCommandTemplates';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled from 'styled-components';
import { useDebounce } from 'use-debounce';

export function BrandHubTemplatesTab() {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const offset = limit * page;

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [debouncedSearchQuery] = useDebounce(searchQuery, 300);

  const [isFilteringActive, setIsFilteringActive] = useState(false);
  const [filteredLocale, setFilteredLocale] = useState<EmbeddingModel | undefined>();
  const [selectedFiltering, setSelectedFiltering] = useState<FilteringTypes>(FilteringTypes.ANY);

  const queryParams: GetCommandTemplatesParams = {
    offset,
    limit,
    title: debouncedSearchQuery,
    language: filteredLocale?.language,
    country: filteredLocale?.country,
    filtering: selectedFiltering,
    sorting: SortingTypes.RECENT
  };

  const { data, isLoading, isError } = useQuery({
    queryKey: httpGetCommandTemplates.makeQueryKey(queryParams),
    queryFn: () => httpGetCommandTemplates.callEndpoint(queryParams),
    keepPreviousData: true
  });

  const showTemplatesModal = useShowTemplatesModal();

  function handleChangePage(_event: unknown | null, page: number) {
    setPage(page);
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  function handleSearchChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setSearchQuery(e.target.value);
    setPage(0);
  }

  const handleToggleFiltering = () => {
    setIsFilteringActive(!isFilteringActive);
  };

  const handleCreateTemplateClick = () => {
    showTemplatesModal({
      preselectedView: 'create',
      closeOnBackClick: true
    });
  };

  if (isError) {
    return null;
  }

  if (isLoading) {
    return (
      <CentredBox>
        <CircularProgress />
      </CentredBox>
    );
  }

  const templates = data.data;
  const total = data.total;

  return (
    <Root>
      <Banner />
      <TableHeader>
        <Typography variant="subtitle1">
          <FormattedMessage id="flashHub.templates.table_title" />
        </Typography>
        <ActionBox>
          <FilterButton
            isFilteringActive={isFilteringActive}
            onToggleFilteringClick={handleToggleFiltering}
          />
          <SearchField value={searchQuery} onChange={handleSearchChange} />
          <Button
            {...withGtmInteraction(gtmIds.brandHub.templates.intentToCreateTemplate)}
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleCreateTemplateClick}
          >
            <FormattedMessage id="flashHub.templates.create_button" />
          </Button>
        </ActionBox>
      </TableHeader>

      {isFilteringActive && (
        <FiltersBox>
          <LanguageFilter value={filteredLocale} setValue={setFilteredLocale} />
          <TemplatesFiltering
            size="small"
            value={selectedFiltering}
            onChange={setSelectedFiltering}
          />
        </FiltersBox>
      )}

      <BrandHubTemplatesTable
        templates={templates}
        limit={limit}
        page={page}
        total={total}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Root>
  );
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.small};
`;

const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ActionBox = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.medium};
`;

const FiltersBox = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.small};
  align-items: center;
`;

const CentredBox = styled.div`
  display: flex;
  justify-content: center;

  align-items: center;
`;

function Banner() {
  return (
    <BannerLayout
      title={
        <FormattedMessage
          id="flashHub.templates.banner.title"
          values={{
            highlight: (text: string) => <Highlighting>{text}</Highlighting>,
            br: <br />
          }}
        />
      }
      content={<FormattedMessage id="flashHub.templates.banner.body" />}
      image={bannerImage}
      color="green__500main"
    />
  );
}

const Highlighting = styled.span`
  color: ${({ theme }) => theme.colors.palette.white};
`;
