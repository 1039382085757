import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import CustomerAPI from 'services/api/customer';
import { RecaptchaAction } from 'services/api/customer/types';

export default function useExecuteRecaptcha(action: RecaptchaAction) {
  const { executeRecaptcha } = useGoogleReCaptcha();

  return async (customerId: number) => {
    if (executeRecaptcha) {
      const token = await executeRecaptcha(action);
      const response = await CustomerAPI.recaptcha({ token, expectedAction: action, customerId });

      if (response.status) {
        return response.data;
      }
    }
    return Promise.resolve('Recaptcha is not working');
  };
}
