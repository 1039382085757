import { motion } from 'framer-motion';
import styled from 'styled-components';

const animations = {
  active: {
    boxShadow:
      '0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2)',
    x: 0
  },
  hidden: {
    x: '16.5rem'
  }
};

const WidgetWrapper = styled(motion.div).attrs(() => ({
  transition: { duration: 0.15 },
  variants: animations,
  initial: 'hidden',
  exit: 'hidden',
  animate: 'active'
}))`
  background-color: white;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: fixed;
  height: 100vh;
  right: 0;
  top: 0;
  z-index: 98;
  padding: 5rem 0.75rem 0;
  width: 16.5rem;

  @media (max-width: 992px) {
    transform: translateY(-60px);
  }
`;

export default WidgetWrapper;
