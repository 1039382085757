import { getCurrentModelLanguageAndCountry } from 'features/aiWriter/store/selectors';
import { useAppSelector } from 'store/hooks';

// Chinese, Turkish, Swedish, Portuguese, Polish, Dutch, Hungarian, Croatian, Czech
const forbiddenLanguages = ['zh', 'tr', 'sv', 'pt', 'pl', 'nl', 'hu', 'hr', 'cs'];

export const useHasForbiddenDocumentLanguage = () => {
  const { currentModelLanguage: documentLanguage } = useAppSelector(
    getCurrentModelLanguageAndCountry
  );

  return hasForbiddenDocumentLanguage(documentLanguage);
};

export const hasForbiddenDocumentLanguage = (documentLanguage: string) =>
  forbiddenLanguages.includes(documentLanguage);
