import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { ColoredTypography } from 'components/ColoredTypography';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

export const KeywordsHint = () => (
  <KeywordsHintRoot>
    <ColoredTypography variant="caption">
      <FormattedMessage id="blog_post_builder.steps.keywords.helper_text" />
    </ColoredTypography>
    <KeyboardReturnIcon />
  </KeywordsHintRoot>
);

const KeywordsHintRoot = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 20rem;
  padding: ${({ theme }) => theme.spacings.small};
  margin-top: ${({ theme }) => theme.spacings.small};

  background-color: ${({ theme }) => theme.colors.primary50};
  border-radius: ${({ theme }) => theme.borderRadius.small};
`;
