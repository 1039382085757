import backgroundDoodleOrnament from 'assets/background-doodle.png';
import { AuthCenteredPanel } from 'components/auth/AuthCenteredPanel';
import { Ornament } from 'components/Ornament';
import { getRoutePath } from 'config/routes';
import { RegisterSuccessCard } from 'features/authentication/RegisterSuccessCard';
import { ReactElement } from 'react';
import Confetti from 'react-confetti';
import { useNavigate } from 'react-router';
import useWindowSize from 'utils/hooks/useWindowSize';

export const RegisterSuccessPage = (): ReactElement => {
  const { width, height } = useWindowSize();
  const navigate = useNavigate();

  return (
    <>
      <AuthCenteredPanel
        slogan="register.success.paid.slogan"
        ornaments={<Ornament src={backgroundDoodleOrnament} bottom="0%" right="0%" />}
      >
        <RegisterSuccessCard
          resendButton={
            <RegisterSuccessCard.ResendButton
              onClick={() => {
                navigate(getRoutePath('resendMail'));
              }}
            />
          }
        />
      </AuthCenteredPanel>

      <Confetti
        width={width}
        height={height}
        numberOfPieces={1000}
        tweenDuration={40000}
        recycle={false}
      />
    </>
  );
};
