import { Typography } from '@mui/material';
import { ReactComponent as CheckSuccessCircleIcon } from 'assets/icons/icon-check-success-circle.svg';
import LocalStorageKey from 'config/localStorageKey';
import { getRoutePath } from 'config/routes';
import { getUserName } from 'features/customer/store/selectors';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { ReactElement, useEffect, useRef } from 'react';
import Confetti from 'react-confetti';
import { useNavigate } from 'react-router';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { useLocalStorage } from 'utils/hooks/useLocalStorage';
import useWindowSize from 'utils/hooks/useWindowSize';

const autoLoginTime = 2 * 1000;

function useAutoRedirect() {
  const navigate = useNavigate();
  const timeoutRef = useRef<number>();
  const [socialRegisterFlag, setSocialRegisterFlag] = useLocalStorage(
    LocalStorageKey.IsSocialRegisterRoute,
    false
  );

  useEffect(() => {
    if (!timeoutRef.current) {
      timeoutRef.current = window.setTimeout(() => {
        // user can come from social login registration
        if (socialRegisterFlag) {
          navigate(getRoutePath('aiWriter'));
          setSocialRegisterFlag(false);
          return;
        }

        navigate(getRoutePath('profileInfo'));
      }, autoLoginTime);
    }
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [navigate, setSocialRegisterFlag, socialRegisterFlag]);
}

const PaymentSuccessPage = (): ReactElement => {
  const { width, height } = useWindowSize();
  const user = useAppSelector(getUserName);

  useAutoRedirect();

  return (
    <Root>
      <StyledCheckSuccessCircleIcon />
      <Typography variant="h4">
        <FormattedMessage id="register_success.headline" />
      </Typography>
      <Typography variant="h5">
        <FormattedMessage id="register_success.subline" values={{ user }} />
      </Typography>

      <Confetti width={width} height={height} numberOfPieces={1000} />
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.medium};
  width: 100%;
  min-height: 100vh;
`;

const StyledCheckSuccessCircleIcon = styled(CheckSuccessCircleIcon)`
  width: 170px;
  height: 170px;
`;

export default PaymentSuccessPage;
