import Box from '@mui/material/Box';
import Toast from 'components/toasts/Toast';
import { BasePage } from 'features/teamInvitation/BasePage';
import { getErrorCodeFromAxiosError } from 'features/teamInvitation/getErrorCodeFromAxiosError';
import {
  NewUserRegisterForm,
  RegisterFormValues
} from 'features/teamInvitation/NewUserRegisterForm';
import { useTeamInvitation } from 'features/teamInvitation/useTeamInvitation';
import { useTeamInvitationRegister } from 'features/teamInvitation/useTeamInvitationRegister';
import { trackingWrapper } from 'features/tracking/wrapper/TrackingWrapper';
import { FormikHelpers } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { handleApiCommonErrors } from 'services/api/utils/handleApiCommonErrors';
import {
  InvitationErrorCode,
  invitationErrorCodes
} from 'services/backofficeIntegration/http/endpoints/teamMembers/httpTeamInvitationRegister';
import { GAEvents } from 'services/tracking/GAEvents';

export const TeamInvitationNewCustomerLandingpage = () => {
  const navigate = useNavigate();
  const [inviteResponseErrorCode, setInviteResponseErrorCode] =
    useState<InvitationErrorCode | null>(null);

  const {
    emailParam,
    tokenParam,
    handleLogin,
    teamInvitationInfo: {
      data: teamInvitationInfo,
      isLoading: isLoadingTeamInvitation,
      hasError: hasTeamInvitationError,
      errorCode: teamInvitationErrorCode
    }
  } = useTeamInvitation();

  const teamInvitationRegister = useTeamInvitationRegister(tokenParam || '');

  const handleRegisterSubmit = async (
    values: RegisterFormValues,
    helpers: FormikHelpers<RegisterFormValues>
  ) => {
    helpers.setSubmitting(true);

    const response = await teamInvitationRegister.mutateAsync(values, {
      onSuccess: () => {
        GAEvents.teamInvitationAccepted({
          email: emailParam,
          teamId: teamInvitationInfo?.team_id,
          teamName: teamInvitationInfo?.team_name,
          invitedBy: teamInvitationInfo?.invited_by_name
        });

        trackingWrapper.track('teamInvitationAccepted', {
          teamId: teamInvitationInfo?.team_id,
          teamName: teamInvitationInfo?.team_name,
          invitedBy: teamInvitationInfo?.invited_by_name,
          email: emailParam
        });
      },
      onError: (error: unknown) => {
        helpers.setSubmitting(false);

        const errorCode = getErrorCodeFromAxiosError(error);
        if (errorCode) {
          if (invitationErrorCodes.includes(errorCode as InvitationErrorCode)) {
            setInviteResponseErrorCode(errorCode as InvitationErrorCode);
            return;
          }

          Toast.backendError(handleApiCommonErrors(errorCode));
          return;
        }

        Toast.commonError();
      }
    });

    helpers.setSubmitting(false);

    if (!response || !response.status) {
      return;
    }

    handleLogin(response.data);
  };

  if (!emailParam || !tokenParam) {
    navigate('/login');
    return null;
  }

  return (
    <BasePage
      inviteResponseErrorCode={inviteResponseErrorCode}
      isLoadingTeamInvitation={isLoadingTeamInvitation}
      teamInvitationErrorCode={teamInvitationErrorCode}
      hasTeamInvitationError={hasTeamInvitationError}
      teamInvitationInfo={teamInvitationInfo}
    >
      <Box sx={{ marginTop: 2 }}>
        <NewUserRegisterForm email={emailParam} onSubmit={handleRegisterSubmit} />
      </Box>
    </BasePage>
  );
};
