import HelpIcon from '@mui/icons-material/Help';
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  Tooltip,
  Typography
} from '@mui/material';
import MobileStepper from '@mui/material/MobileStepper';
import { useQuery } from '@tanstack/react-query';
import { ColoredTypography } from 'components/ColoredTypography';
import FlexContainer from 'components/FlexContainer';
import { PersonalityCommonSelector } from 'components/personality/PersonalityCommonSelector';
import { PersonalityIcon } from 'components/personality/PersonalityIcon';
import { default as NotificationHint } from 'components/profile/components/pricing-plan-card/NotificationHint';
import { textGeneratorTips, TipLoader } from 'components/TipLoader';
import LocalStorageKey from 'config/localStorageKey';
import { getRoutePath } from 'config/routes';
import { TextInputField } from 'features/aiWriter/AiWriterBlogPostBuilder/steps/components/TextInputField';
import { AiWriterSidebar } from 'features/aiWriter/AiWriterSidebar/AiWriterSidebar';
import { useSetDefaultChatPersonality } from 'features/aiWriter/AiWriterSidebar/steps/chat/useSetDefaultPersonality';
import { isAiWriterSupportedLanguageAndCountryCode } from 'features/aiWriter/aiWriterSupportedLanguageAndCountryCodes';
import {
  FormValues,
  SocialPlatform,
  SocialPostBuilderWrapper
} from 'features/aiWriter/socialPostBuilder/SocialPostBuilderWrapper';
import { useCreateDocument } from 'features/aiWriter/socialPostBuilder/useCreateDocument';
import { useCreateDocumentStructure } from 'features/aiWriter/socialPostBuilder/useCreateDocumentStructure';
import { SocialBuilder } from 'features/aiWriter/socialPostBuilder/WorkflowSchema';
import { setBuilderInitLanguage } from 'features/aiWriter/store/actions/builder/actions';
import { TonalitySelect } from 'features/aiWriter/tonality/TonalitySelect';
import { ModelAutocomplete } from 'features/embeddingModels/ModelAutocomplete';
import {
  getEmbeddingModelDataSelector,
  getEmbeddingModelsByLanguageAndAudience
} from 'features/embeddingModels/store/selectors';
import { useShowApplyInformationModal } from 'features/information/apply-information/ApplyInformationModal';
import { InformationField } from 'features/information/apply-information/InformationField';
import { useEnableSidebarOnMount } from 'features/pageSidebar/useSidebarStore';
import { Form, useField, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router';
import { PersonalityDto } from 'services/backofficeIntegration/http/dtos/PersonalityDto';
import { httpGetDefaultPersonality } from 'services/backofficeIntegration/http/endpoints/personalities/httpGetDefaultPersonality';
import { GAEvents } from 'services/tracking/GAEvents';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { AiWriterWorkflowsType } from 'types/AiWriterWorkflows';
import useTr from 'utils/hooks/useTr';

enum Steps {
  Language,
  SocialPlatforms,
  Tonality,
  Outline,
  GenerateStep
}

export function SocialPostBuilder() {
  const navigate = useNavigate();
  const location = useLocation();
  const hashParams = new URLSearchParams(location.hash.length > 0 ? location.hash.slice(1) : '');
  const skipDocumentCreation = hashParams.has('documentMenu');
  const initialStep = skipDocumentCreation ? Steps.SocialPlatforms : Steps.Language;
  const [currentStep, setCurrentStep] = useState<Steps>(initialStep);

  useEnableSidebarOnMount();

  function handleNext() {
    setCurrentStep(currentStep + 1);
  }

  function handleBack() {
    // While going back from the second step, stop the builder
    if (currentStep === Steps.SocialPlatforms) {
      handleExit();
      return;
    }

    setCurrentStep(currentStep - 1);
  }

  function handleExit() {
    navigate(getRoutePath('aiWriter'));
  }

  function renderStep() {
    switch (currentStep) {
      case Steps.Language:
        return <LanguageStep onNext={handleNext} onBack={handleExit} />;
      case Steps.SocialPlatforms:
        return <SocialPlatformsStep onNext={handleNext} onBack={handleBack} />;
      case Steps.Tonality:
        return <TonalityStep onNext={handleNext} onBack={handleBack} />;
      case Steps.Outline:
        return (
          <OutlineStep
            onNext={handleNext}
            onBack={handleBack}
            skipDocumentCreation={skipDocumentCreation}
          />
        );
      case Steps.GenerateStep:
        return <GenerateStep />;
      default:
        return null;
    }
  }

  return (
    <Container direction="row">
      <Wrapper>
        <Content>
          <SocialPostBuilderWrapper>
            <Form>
              {renderStep()}
              <StepperContainer>
                <MobileStepper
                  steps={Object.keys(Steps).length / 2}
                  activeStep={currentStep}
                  position="static"
                  backButton={null}
                  nextButton={null}
                />
              </StepperContainer>
            </Form>
          </SocialPostBuilderWrapper>
        </Content>
      </Wrapper>
      <AiWriterSidebar isDisabled={true} />
    </Container>
  );
}

// https://app.clickup.com/t/866auywp0 hook created for an onboarding process to skip the language step
function useOnboardingLanguageSetup(onNext: () => void) {
  const models = useAppSelector(getEmbeddingModelsByLanguageAndAudience);
  const dispatch = useAppDispatch();

  const { setFieldValue } = useFormikContext<FormValues>();

  const { language: onboardingLanguage } = useAppSelector(
    state => state.aiWriter.builder.socialPost
  );

  const onboardingModel = onboardingLanguage
    ? models.find(
        model =>
          model.language === onboardingLanguage.language &&
          model.country === onboardingLanguage.country
      )
    : undefined;

  useEffect(() => {
    if (onboardingModel) {
      localStorage.setItem(LocalStorageKey.AiWriterModel, onboardingModel.id);
      setFieldValue('language', onboardingModel.id);
      dispatch(
        setBuilderInitLanguage({
          builder: AiWriterWorkflowsType.SocialPost,
          language: undefined
        })
      );
      setFieldValue('showOnboardingHint', true);
      onNext();
    }
  }, [dispatch, models, onNext, onboardingLanguage, onboardingModel, setFieldValue]);
}

function LanguageStep(props: { onNext: () => void; onBack: () => void }) {
  const { onNext, onBack } = props;

  const models = useAppSelector(getEmbeddingModelsByLanguageAndAudience);

  useOnboardingLanguageSetup(onNext);

  return (
    <SocialBuilder.Schema
      label={<SocialBuilder.Label labelId="social_post_builder.language_step.label" />}
      input={
        <ModelAutocomplete
          name="language"
          models={models}
          onSelect={value => {
            localStorage.setItem(LocalStorageKey.AiWriterModel, value);
          }}
        />
      }
      backButton={<SocialBuilder.BackButton onBack={onBack} />}
      nextButton={<SocialBuilder.NextButton onNext={onNext} />}
    />
  );
}

function SocialPlatformsStep(props: { onNext: () => void; onBack: () => void }) {
  const { onNext, onBack } = props;
  const [field] = useField('socialPlatforms');
  const { values } = useFormikContext<FormValues>();

  return (
    <div>
      {values.showOnboardingHint && (
        <NotificationHint>
          <ColoredTypography color="primary700">
            <FormattedMessage id="social_post_builder.social_platforms_step.onboarding_hint" />
          </ColoredTypography>
        </NotificationHint>
      )}
      <SocialBuilder.Schema
        label={
          <SocialBuilder.Label
            labelId="social_post_builder.social_platforms_step.label"
            tooltipId="social_post_builder.social_platforms_step.tooltip"
          />
        }
        input={
          <FormGroup>
            <SocialPlatformCheckbox platform={SocialPlatform.Facebook} />
            <SocialPlatformCheckbox platform={SocialPlatform.Instagram} />
            <SocialPlatformCheckbox platform={SocialPlatform.LinkedIn} />
            <SocialPlatformCheckbox platform={SocialPlatform.Twitter} />
            <SocialPlatformCheckbox platform={SocialPlatform.YouTube} />
          </FormGroup>
        }
        backButton={<SocialBuilder.BackButton onBack={onBack} />}
        nextButton={
          <SocialBuilder.NextButton onNext={onNext} disabled={field.value.length === 0} />
        }
      />
    </div>
  );
}

function SocialPlatformCheckbox(props: { platform: SocialPlatform }) {
  const { platform } = props;
  const [field] = useField('socialPlatforms');

  return (
    <FormControlLabel
      control={<Checkbox {...field} value={platform} />}
      label={
        <Typography variant="body1">
          <FormattedMessage id={`social_post_builder.${platform}.headline`} />
        </Typography>
      }
      checked={field.value.includes(platform)}
      id={gtmIds.socialPostBuilder.socialChannel}
      data-social-channel={platform.toLowerCase()}
    />
  );
}

function TonalityStep(props: { onNext: () => void; onBack: () => void }) {
  const { onNext, onBack } = props;
  const getModelById = useAppSelector(getEmbeddingModelDataSelector);
  const tr = useTr();

  const { values, setFieldValue } = useFormikContext<FormValues>();

  const model = getModelById(values.language);
  const languageAndCountryCode = `${model.language}_${model.country}`;

  const tonalitySupportedInLanguage =
    isAiWriterSupportedLanguageAndCountryCode(languageAndCountryCode);

  const { mutate: setDefaultPersonality } = useSetDefaultChatPersonality();

  const params = { language: model.language, country: model.country };
  const defaultPersonalityQuery = useQuery({
    queryKey: httpGetDefaultPersonality.makeQueryKey(params),
    queryFn: () => httpGetDefaultPersonality.callEndpoint(params),
    onSuccess: data => {
      if (data && !values.personalityId) {
        setFieldValue('personalityId', data);
      }
    }
  });

  const handlePersonalityChange = (personality: PersonalityDto | undefined | null) => {
    if (personality) {
      setDefaultPersonality({
        personalityId: personality.id,
        language: model.language,
        country: model.country
      });
    }

    setFieldValue('personalityId', personality);
  };

  const showApplyInformationModal = useShowApplyInformationModal();

  const handleApplyInformation = () => {
    showApplyInformationModal({
      preselectedInformation: values.informationList,
      onApply: informationList => {
        setFieldValue('informationList', informationList);
      }
    });
  };

  const handleInformationRemove = (id: number) => {
    setFieldValue(
      'informationList',
      values.informationList?.filter(information => information.id !== id)
    );
  };

  const handleNext = () => {
    onNext();
  };

  if (tonalitySupportedInLanguage) {
    return (
      <SocialBuilder.Schema
        input={
          <>
            <TonalitySelect
              label={tr('social_post_builder.tonality_step.label')}
              icon={
                <Tooltip placement="top" title={tr('social_post_builder.tonality_step.tooltip')}>
                  <HelpIcon color="action" />
                </Tooltip>
              }
              systemTonality={values.systemTonality}
              userTonality={values.userTonality}
              maxLength={2}
              languageAndCountryCode={languageAndCountryCode}
              onChange={(newSystemTonalities, newUserTonalities) => {
                setFieldValue('systemTonality', newSystemTonalities);
                setFieldValue('userTonality', newUserTonalities);
              }}
              onInputChange={inputValue => {
                // Disable nex step if user entered a tonality but didn't confirmed it
                setFieldValue('disableNextStep', inputValue.length > 0);
              }}
              gtmAttributes={withGtmInteraction(gtmIds.socialPostBuilder.tonality)}
            />
            <PersonalityBox>
              <SocialBuilder.Label
                labelId={tr('common.brandVoice')}
                tooltipId="social_post_builder.tonality_step.brandVoice.tooltip"
                linkId="aiWriter.inspirations.aiwriter.main.personality.help_link"
              />
              <PersonalitySelectorBox>
                <PersonalityCommonSelector
                  value={values.personalityId}
                  onChange={handlePersonalityChange}
                  preselectedModelId={model.id}
                  isPropsLoading={defaultPersonalityQuery.isLoading}
                  size="small"
                  fullWidth
                  startAdornment={
                    <InputAdornment position="start">
                      {defaultPersonalityQuery.isLoading ? (
                        <Box sx={{ display: 'grid' }}>
                          <CircularProgress size={16} />
                        </Box>
                      ) : (
                        <PersonalityIcon />
                      )}
                    </InputAdornment>
                  }
                />
              </PersonalitySelectorBox>
            </PersonalityBox>
            <InformationField
              informationList={values.informationList}
              onApply={handleApplyInformation}
              onRemove={handleInformationRemove}
              fontVariant="subtitle1"
            />
          </>
        }
        backButton={<SocialBuilder.BackButton onBack={onBack} />}
        nextButton={<SocialBuilder.NextButton onNext={handleNext} />}
      />
    );
  } else {
    handleNext();

    return null;
  }
}

const PersonalityBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PersonalitySelectorBox = styled.div`
  width: 10rem;
`;

function OutlineStep(props: {
  onNext: () => void;
  onBack: () => void;
  skipDocumentCreation?: boolean;
}) {
  const { onBack, onNext, skipDocumentCreation } = props;
  const tr = useTr();
  const fieldName = 'outline';
  const minimumLength = 3;

  const [field] = useField(fieldName);

  const { mutateAsync: createDocument } = useCreateDocument();

  const handleNext = async () => {
    if (skipDocumentCreation) {
      onNext();
      return;
    }
    // Create document here in order to allow accessing the tabId in the next step
    await createDocument();

    onNext();
  };

  return (
    <SocialBuilder.Schema
      label={
        <SocialBuilder.Label
          labelId="social_post_builder.outline_step.label"
          tooltipId="social_post_builder.outline_step.tooltip"
        />
      }
      input={
        <TextInputField
          name={fieldName}
          placeholder={tr('social_post_builder.outline_step.placeholder')}
        />
      }
      backButton={<SocialBuilder.BackButton onBack={onBack} />}
      nextButton={
        <SocialBuilder.NextButton
          onNext={handleNext}
          disabled={field.value.trim().length < minimumLength}
        />
      }
    />
  );
}

function GenerateStep() {
  const { mutate: createDocumentStructure } = useCreateDocumentStructure();

  useEffect(() => {
    createDocumentStructure();

    GAEvents.reportCustomEvent(gtmIds.socialPostBuilder.generate);
    GAEvents.workflowFinished({
      name: 'social post builder'
    });
  }, [createDocumentStructure]);

  return (
    <FlexContainer alignItems="center" justifyContent="center">
      <TipLoader
        alternatingMessages={[
          'blog_post_builder.steps.outline.loader.average_time',
          'blog_post_builder.steps.outline.loader.creativity_progress'
        ]}
        tips={textGeneratorTips}
      />
    </FlexContainer>
  );
}

const StepperContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 150px;
`;

const Wrapper = styled.div`
  margin: 160px auto 0 auto;
  flex: 0 0 auto;
`;

const Container = styled(FlexContainer)`
  height: 100%;
`;

const Content = styled.div`
  width: 550px;
`;
