export const darkModeColorPalette = {
  black: '#ffffff',
  white: '#1e1e1e',

  beige__50: '#1e1e1e',
  beige__100main: '#3f3f3f',
  beige__200: '#5e5e5e',
  beige__300: '#717171',
  beige__400: '#9a9a9a',
  beige__500: '#bebdbd',

  blue__50: '#1c2f67',
  blue__100: '#283f86',
  blue__200: '#2b4594',
  blue__300: '#3563b3',
  blue__400: '#3771c8',
  blue__500main: '#54a0ed',
  blue__600: '#6eb1f0',
  blue__700: '#97c8f3',
  blue__800: '#bfddf8',
  blue__900: '#e4f2fc',

  dark__50: '#1e1e1e',
  dark__100: '#3f3f3f',
  dark__200: '#5e5e5e',
  dark__300: '#717171',
  dark__400: '#9a9a9a',
  dark__500: '#bebdbd',
  dark__600: '#d2d2d2',
  dark__700: '#e1dfdf',
  dark__800: '#f2eeee',
  dark__900main: '#f7f4f4',

  green__50: '#0f5159',
  green__100: '#116364',
  green__200: '#19868f',
  green__300: '#1d9aa6',
  green__400: '#0eaec1',
  green__500main: '#0fbfd4',
  green__600: '#2cc9d9',
  green__700: '#50d3e0',
  green__800: '#b3ecf1',
  green__900: '#e6f4f1',

  orange__50: '#935019',
  orange__100: '#aa5e1b',
  orange__200: '#cc680f',
  orange__300: '#dd6f0f',
  orange__400: '#fe7904',
  orange__500main: '#fda42e',
  orange__600: '#febc34',
  orange__700: '#fed53b',
  orange__800: '#fff39d',
  orange__900: '#fffde7',

  pinkRose__50: '#99159e',
  pinkRose__100: '#a934ad',
  pinkRose__200: '#b95abc',
  pinkRose__300: '#cf88d1',
  pinkRose__400: '#e4b7e4',
  pinkRose__500main: '#efd3ef',
  pinkRose__600: '#f0d9f0',
  pinkRose__700: '#f5e9f5',
  pinkRose__800: '#fcf4fc',
  pinkRose__900: '#fffbff',

  red__50: '#811313',
  red__100: '#ce0929',
  red__200: '#da2223',
  red__300: '#e80c3c',
  red__400: '#fd173e',
  red__500main: '#ff4559',
  red__600: '#f66e7b',
  red__700: '#fb98a0',
  red__800: '#ffcdd7',
  red__900: '#ffebf0',

  yellow__50: '#fd8e26',
  yellow__100: '#fd9f28',
  yellow__200: '#fdb12b',
  yellow__300: '#febf2f',
  yellow__400: '#fed404',
  yellow__500main: '#fed35b',
  yellow__600: '#fedf88',
  yellow__700: '#ffebb6',
  yellow__800: '#fff8e2',
  yellow__900: '#fffaec'
};
