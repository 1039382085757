import { Button } from '@mui/material';
import backgroundDoodleOrnament from 'assets/background-doodle.png';
import FlexContainer from 'components/FlexContainer';
import { BoolCheckboxField } from 'components/forms/BoolCheckboxField';
import EmailField from 'components/forms/customFields/EmailField';
import NameField from 'components/forms/customFields/NameField';
import PasswordField from 'components/forms/customFields/PasswordField';
import { Ornament } from 'components/Ornament';
import { PrivacyPolicy } from 'components/PrivacyPolicy';
import {
  emailField,
  nameField,
  newsletterSubscribedField,
  passwordField
} from 'components/register/CreateAccountStep';
import { CentredRegistrationFormContent } from 'components/register/RegistrationFormContent/CentredRegistrationFormContent';
import { RegistrationFormContentData } from 'components/register/RegistrationFormContent/RegistrationFormContent';
import { RegistrationReturnToLogin } from 'components/register/RegistrationReturnToLogin';
import emailValidationCheck from 'features/customer/utils/emailValidationCheck';
import { SocialLoginsForm } from 'features/social/SocialLoginsForm';
import { useField } from 'formik';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

type Props = RegistrationFormContentData & {
  goToNextStep: () => void;
};

export const BasicDataStep = ({ status, isValid, goToNextStep }: Props) => {
  const tr = useTr();

  const [field] = useField(newsletterSubscribedField);
  const newsletterSubscribed = field.value;

  return (
    <CentredRegistrationFormContent
      header="register.basic_data_step.header.paid"
      status={status}
      extra={<RegistrationReturnToLogin />}
      ornaments={<Ornament src={backgroundDoodleOrnament} bottom="0%" right="0%" />}
      socials={
        <SocialLoginsForm
          formName="signup"
          newsletterSubscribed={newsletterSubscribed}
          isPaid={true}
        />
      }
    >
      <NameField name={nameField} />
      <EmailField name={emailField} validate={(email: string) => emailValidationCheck(email, tr)} />
      <PasswordField name={passwordField} />
      <FlexContainer gap="xmedium">
        <BoolCheckboxField
          name={newsletterSubscribedField}
          label={tr('register.mail_subscription')}
        />
        <PrivacyPolicy />
        <Actions>
          <Button
            fullWidth={true}
            variant="contained"
            color="primary"
            size="large"
            id="next-button"
            disabled={!isValid}
            onClick={goToNextStep}
          >
            <FormattedMessage id="register.basic_data_step.create_an_account" />
          </Button>
        </Actions>
      </FlexContainer>
    </CentredRegistrationFormContent>
  );
};

const Actions = styled.div`
  display: flex;
  justify-content: center;
`;
