import { useMutation } from '@tanstack/react-query';
import Toast from 'components/toasts/Toast';
import CustomerAPI from 'services/api/customer';
import { handleApiCommonErrors } from 'services/api/utils/handleApiCommonErrors';

export function useSendEmailInvitationMutation() {
  return useMutation({
    mutationFn: async (email: string): Promise<boolean> => {
      const response = await CustomerAPI.inviteFriendByEmail(email);
      if (!response?.status) {
        Toast.error(handleApiCommonErrors(response?.message));
      }
      return response?.status ?? false;
    },
    onError: () => {
      Toast.commonError();
    }
  });
}
