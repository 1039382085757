import { useMutation } from '@tanstack/react-query';
import { getActiveTab, getCurrentModelLanguageAndCountry } from 'features/aiWriter/store/selectors';
import {
  getEmotionalityTranslationLabel,
  getReadabilityAndEmotionalityGrade
} from 'features/flashScore/getEmotionalityTranslationLabel';
import { getReadabilityTranslationLabel } from 'features/flashScore/getReadabilityTranslationLabel';
import { useGetEmotionalityMapperName } from 'features/flashScore/useGetEmotionalityMapperName';
import { FlashScoreTextRatingResult } from 'features/flashScore/useScoreTexts';
import { httpFlashScoreWords } from 'services/backofficeIntegration/http/endpoints/flashScore/httpFlashScoreWords';
import { httpTokenizeText } from 'services/backofficeIntegration/http/endpoints/flashScore/httpTokenizeText';
import { useAppSelector } from 'store/hooks';
import { removeDuplicates } from 'utils/utils';

export type FlashScoreWordRating = Omit<FlashScoreTextRatingResult, 'text'> & {
  word: string;
};

type Options = {
  text: string;
};

export const useTokenizeAndScoreProvidedTextMutation = () => {
  const { embeddingModelId } = useAppSelector(getActiveTab);
  const { currentModelLanguage: documentLanguage } = useAppSelector(
    getCurrentModelLanguageAndCountry
  );
  const mapperName = useGetEmotionalityMapperName(embeddingModelId);

  return useMutation<FlashScoreWordRating[], Error, Options>(async options => {
    // First, we've to tokenize the text
    const tokenizerResponse = await httpTokenizeText.callEndpoint({
      text: options.text,
      language: documentLanguage
    });

    // The response also contains synonyms
    const tokens = tokenizerResponse.data
      .filter(({ token, is_stop }) => {
        if (!token || is_stop) {
          return false;
        }

        const trimmedToken = token.trim();
        if (!trimmedToken) {
          return false;
        }

        // Not sure about this, copied from PerformanceFlash tokenizer
        return !['<', 'u', '>', '/', '/u'].includes(trimmedToken);
      })
      .map(({ token }) => token)
      .filter((token): token is string => !!token);

    // Extract the unique word list from the tokens
    const uniqueTokens = removeDuplicates(tokens);

    // Finally, score the words for emotionality
    return httpFlashScoreWords
      .callEndpoint({
        embedding_model_id: embeddingModelId,
        mappers: [
          { id: 'readability', name: 'readability', variable: 'readability' },
          { id: 'emotionality', name: mapperName, variable: 'emotionality' }
        ],
        seed_words: uniqueTokens,
        whitelist_words: uniqueTokens
      })
      .then(response => {
        if (!response || !response.status) {
          throw new Error('Failed to fetch data');
        }

        return response.data.scored_words.map<FlashScoreWordRating>(result => {
          const readability =
            result.mapper_scores.find(({ id }) => id === 'readability')?.score ?? 0;
          const emotionality =
            result.mapper_scores.find(({ id }) => id === 'emotionality')?.score ?? 0;
          const readabilityGrade = getReadabilityAndEmotionalityGrade(readability);
          const emotionalityGrade = getReadabilityAndEmotionalityGrade(emotionality);

          return {
            word: result.word,
            readability: {
              grade: readabilityGrade,
              score: readability,
              percentage: Math.round(readability * 100),
              label: getReadabilityTranslationLabel(readabilityGrade)
            },
            emotionality: {
              grade: emotionalityGrade,
              score: emotionality,
              percentage: Math.round(emotionality * 100),
              label: getEmotionalityTranslationLabel(emotionalityGrade)
            }
          };
        });
      });
  });
};
