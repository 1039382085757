import useTr from 'utils/hooks/useTr';

import * as CommonStyled from './common.styles';
import { TextInputField } from './components/TextInputField';

export const SelectTitleStep = () => {
  const tr = useTr();

  return (
    <>
      <CommonStyled.Title variant="subtitle1">
        {tr('blog_post_builder.steps.title.title')}
      </CommonStyled.Title>
      <TextInputField name="title" placeholder={tr('blog_post_builder.steps.title.placeholder')} />
    </>
  );
};
