import TextInputField from 'components/forms/TextInputField';
import { CharacterLimiterWithLabel } from 'components/inputs/CharacterLimiterWithLabel';
import GenerateTextExplanation from 'features/textGenerator/GenerateTextExplanation';
import { useLanguageMismatchWarning } from 'features/textGenerator/languageValidation/useLanguageMismatchWarning';
import { useGetPromptConfigByLanguageCountryAndOutputTypeCached } from 'features/textGenerator/utils/useGetPromptsOthers';
import { useField } from 'formik';
import React from 'react';

type Props = {
  name: string;
  modelLanguage: string;
  modelCountry: string;
  outputType: string;
  isHidden?: boolean;
};

const BriefField: React.FC<Props> = ({
  name,
  modelLanguage,
  modelCountry,
  outputType,
  isHidden
}) => {
  const [field] = useField<string>(name);
  const text = field.value;
  const languageWarning = useLanguageMismatchWarning({ text, modelLanguage });
  const config = useGetPromptConfigByLanguageCountryAndOutputTypeCached(
    modelLanguage,
    modelCountry,
    outputType
  )?.text;

  if (isHidden || !config) {
    return null;
  }

  const maxLength = config.characterLimit;

  return (
    <CharacterLimiterWithLabel
      label={config.label}
      length={text.length}
      maxLength={maxLength}
      icon={<GenerateTextExplanation />}
    >
      <TextInputField
        name={name}
        variant="outlined"
        fullWidth={true}
        placeholder={config.placeholder}
        inputProps={{ maxLength }}
        multiline={true}
        helperText={languageWarning}
      />
    </CharacterLimiterWithLabel>
  );
};

export default BriefField;
