import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { setRequestIdHeader } from 'services/backofficeIntegration/http/client/useRequestIdHeaderStore';

const getRequestIdHeader = (response: AxiosResponse<unknown> | undefined) => {
  const requestId = response?.headers['x-request-id'];
  if (!requestId) {
    return null;
  }
  return requestId;
};

export function withRequestIdSupport(client: AxiosInstance) {
  client.interceptors.response.use(undefined, async (error: unknown) => {
    if (!axios.isAxiosError(error)) {
      throw error;
    }

    const requestId = getRequestIdHeader(error.response);
    if (requestId === null) {
      throw error;
    }

    setRequestIdHeader(requestId);

    throw error;
  });
}
