import {
  AiWriterSupportedLanguageAndCountryCode,
  aiWriterSupportedLanguageAndCountryCodes
} from 'features/aiWriter/aiWriterSupportedLanguageAndCountryCodes';

type ExampleData = {
  title: string;
  text: string;
};

export const welcomeMessage: Record<AiWriterSupportedLanguageAndCountryCode, ExampleData> = {
  [aiWriterSupportedLanguageAndCountryCodes.de_de]: {
    title: '👋 Hallo',
    text: '\n\nIch bin hier, um dich zu unterstützen! \nHier sind einige Beispiele, was ich für dich machen kann:\n· Texte umschreiben\n· auf E-Mails antworten\n· mit dir zusammen brainstormen\n... \n\nGib mir einfach einen Kontext und sag mir, was ich machen soll.'
  },
  [aiWriterSupportedLanguageAndCountryCodes.de_at]: {
    title: '👋 Hallo',
    text: '\n\nIch bin hier, um dich zu unterstützen! \nHier sind einige Beispiele, was ich für dich machen kann:\n· Texte umschreiben\n· auf E-Mails antworten\n· mit dir zusammen brainstormen\n... \n\nGib mir einfach einen Kontext und sag mir, was ich machen soll.'
  },
  [aiWriterSupportedLanguageAndCountryCodes.de_ch]: {
    title: '👋 Hallo',
    text: '\n\nIch bin hier, um dich zu unterstützen! \nHier sind einige Beispiele, was ich für dich machen kann:\n· Texte umschreiben\n· auf E-Mails antworten\n· mit dir zusammen brainstormen\n... \n\nGib mir einfach einen Kontext und sag mir, was ich machen soll.'
  },
  [aiWriterSupportedLanguageAndCountryCodes.en_us]: {
    title: '👋 Hello',
    text: '\n\nLet me support you. \nHere are some examples of what I can do for you:\n· rewrite text\n· write answers to e-mails\n· brainstorm with you\n... \n\nJust give me the context and tell me what to do.'
  },
  [aiWriterSupportedLanguageAndCountryCodes.en_uk]: {
    title: '👋 Hello',
    text: '\n\nLet me support you. \nHere are some examples of what I can do for you:\n· rewrite text\n· write answers to e-mails\n· brainstorm with you\n... \n\nJust give me the context and tell me what to do.'
  },
  [aiWriterSupportedLanguageAndCountryCodes.fr_fr]: {
    title: '👋 Salut',
    text: '\n\nJe suis là pour te soutenir! \nVoici quelques exemples de ce que je peux faire pour toi:\n· réécrire des textes\n· répondre à des e-mails\n· faire un brainstorming avec toi\n... \n\nDonne-moi simplement un contexte et dis-moi ce que je dois faire.'
  },
  [aiWriterSupportedLanguageAndCountryCodes.fr_ch]: {
    title: '👋 Salut',
    text: '\n\nJe suis là pour te soutenir! \nVoici quelques exemples de ce que je peux faire pour toi:\n· réécrire des textes\n· répondre à des e-mails\n· faire un brainstorming avec toi\n... \n\nDonne-moi simplement un contexte et dis-moi ce que je dois faire.'
  },
  [aiWriterSupportedLanguageAndCountryCodes.es_es]: {
    title: '👋 Hola',
    text: '\n\nestoy aquí para ayudarte! \nAquí hay algunos ejemplos de las cosas que puedo hacer por ti:\n· Reescribir textos\n· Contestar e-mails\n· Hacer un brainstorming\n... \n\nSimplemente dame el contexto y dime lo que tengo que hacer.'
  },
  [aiWriterSupportedLanguageAndCountryCodes.ca_es]: {
    title: '👋 Hola',
    text: "\n\nestic aquí per ajudar-te! \nAquí tens alguns exemples de les coses que puc fer per tu:\n· Reescriure textos\n· Contestar e-mails\n· Fer un brainstorming\n... \n\nSimplement dona'm el context i digues-me què he de fer."
  },
  [aiWriterSupportedLanguageAndCountryCodes.nl_nl]: {
    title: '👋 Hallo',
    text: '\n\nIk ben hier om je te ondersteunen! \nHier zijn enkele voorbeelden van wat ik voor je kan doen:\n· teksten herschrijven\n· e-mails beantwoorden\n· met je brainstormen\n... \n\nGeef me gewoon een context en vertel me wat ik moet doen.'
  },
  [aiWriterSupportedLanguageAndCountryCodes.it_it]: {
    title: '👋 Ciao',
    text: '\n\nSono qui per sostenerti! \nEcco alcuni esempi di ciò che posso fare per te:\n· riscrivere testi\n· rispondere alle e-mail\n· fare brainstorming con te\n... \n\nDatemi un contesto e ditemi cosa fare.'
  },
  [aiWriterSupportedLanguageAndCountryCodes.pl_pl]: {
    title: '👋 Witaj',
    text: '\n\nJestem tutaj, aby Cię wspierać! \nOto kilka przykładów tego, co mogę dla Ciebie zrobić:\n· przepisywać teksty\n· odpowiadać na e-maile\n· przeprowadzić z Tobą burzę mózgów\n... \n\nPo prostu podaj mi kontekst i powiedz, co mam zrobić.'
  },
  [aiWriterSupportedLanguageAndCountryCodes.pt_pt]: {
    title: '👋 Olá',
    text: '\n\nEstou aqui para te ajudar! \nAqui estão alguns exemplos do que posso fazer por ti:\n· Reescrever textos\n· Responder a e-mails\n· Fazer brainstorming contigo\n... \n\nBasta dar-me um contexto e dizer-me o que devo fazer.'
  },
  [aiWriterSupportedLanguageAndCountryCodes.pt_br]: {
    title: '👋 Olá',
    text: '\n\nEstou aqui para te ajudar! \nAqui estão alguns exemplos do que posso fazer por você:\n· Reescrever textos\n· Responder a e-mails\n· Fazer brainstorming com você\n... \n\nBasta me dar um contexto e dizer o que devo fazer.'
  },
  [aiWriterSupportedLanguageAndCountryCodes.es_mx]: {
    title: '👋 Hola',
    text: '\n\nEstoy aquí para ayudarte! \nAquí tienes algunos ejemplos de lo que puedo hacer por ti:\n· Reescribir textos\n· Responder correos electrónicos\n· Hacer brainstorming contigo\n... \n\nSolo dame un contexto y dime qué debo hacer.'
  },
  [aiWriterSupportedLanguageAndCountryCodes.sv_se]: {
    title: '👋 Hej',
    text: '\n\nJag är här för att hjälpa dig! \nHär är några exempel på vad jag kan göra för dig:\n· Omskriva texter\n· Svara på e-postmeddelanden\n· Brainstorma med dig\n... \n\nBara ge mig sammanhanget och säg vad jag ska göra.'
  },
  [aiWriterSupportedLanguageAndCountryCodes.cs_cz]: {
    title: '👋 Ahoj',
    text: '\n\nJsem tu, abych ti pomohl! \nTady jsou některé příklady toho, co pro tebe mohu udělat:\n· Přepsat texty\n· Odpovědět na e-maily\n· Spolu s tebou brainstormovat\n... \n\nJen mi dej kontext a řekni mi, co mám dělat.'
  },
  [aiWriterSupportedLanguageAndCountryCodes.hu_hu]: {
    title: '👋 Helló',
    text: '\n\nItt vagyok, hogy segítsek neked! \nItt vannak néhány példa arra, hogy mit tehetek érted:\n· Szövegeket újraírni\n· Válaszolni az e-mailekre\n· Együtt brainstormolni veled\n... \n\nCsak adj nekem egy kontextust és mond el, mit kell csinálnom.'
  },
  [aiWriterSupportedLanguageAndCountryCodes.hr_hr]: {
    title: '👋 Bok',
    text: '\n\nOvdje sam da vam pomognem! \nEvo nekoliko primjera što mogu učiniti za vas:\n· Prepisivanje tekstova\n· Odgovaranje na e-mailove\n· Brainstorming s vama\n... \n\nSamo mi dajte kontekst i recite mi što trebam raditi.'
  },
  [aiWriterSupportedLanguageAndCountryCodes.zh_cn]: {
    title: '👋 你好',
    text: '\n\n我在这里帮助你！ \n以下是我可以为你做的一些示例：\n· 重写文本\n· 回复电子邮件\n· 与你一起头脑风暴\n... \n\n只需提供一个背景，并告诉我应该做什么。'
  },
  [aiWriterSupportedLanguageAndCountryCodes.tr_tr]: {
    title: '👋 Merhaba',
    text: '\n\nSana yardımcı olmak için buradayım! \nİşte senin için yapabileceğim bazı örnekler:\n· Metinleri yeniden yazma\n· E-postalara cevap verme\n· Seninle birlikte beyin fırtınası yapma\n... \n\nSadece bana bir bağlam ver ve ne yapmam gerektiğini söyle.'
  }
};
