import makeStyles from '@mui/styles/makeStyles';
import { ColoredTypography } from 'components/ColoredTypography';
import FlexContainer from 'components/FlexContainer';
import NotificationHint from 'components/profile/components/pricing-plan-card/NotificationHint';
import * as CommonStyled from 'features/aiWriter/AiWriterBlogPostBuilder/steps/common.styles';
import { KeywordsHint } from 'features/aiWriter/AiWriterBlogPostBuilder/steps/components/KeywordsHint';
import { KeywordsLengthExceededHint } from 'features/aiWriter/AiWriterBlogPostBuilder/steps/components/KeywordsLengthExceededHint';
import { TextInputField } from 'features/aiWriter/AiWriterBlogPostBuilder/steps/components/TextInputField';
import { FormValues } from 'features/aiWriter/AiWriterBlogPostBuilder/types';
import { UserTonalityInput } from 'features/aiWriter/tonality/UserTonalityInput';
import { useFormikContext } from 'formik';
import { usePostHog } from 'posthog-js/react';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import theme from 'styles/styledComponents/theme';
import { getWordCount } from 'utils/getWordCount';
import useTr from 'utils/hooks/useTr';

const useStyles = makeStyles({
  customerTonalityRoot: {
    padding: '0 !important'
  },
  customerTonalityInputRoot: {
    padding: '0 !important',
    border: 'none',
    color: theme.colors.blackMediumEmphasis,
    fontSize: theme.fontSizes.h3,
    fontWeight: 300,
    lineHeight: theme.fontSizes.h1
  },
  customerTonalityInput: {
    padding: '0 !important'
  },
  customerTonalityInputNotchedOutline: {
    border: 'none !important'
  }
});

const maxAmount = 1;
const maxTextLength = 50;

export const SelectGoalStep = () => {
  const classes = useStyles();
  const translate = useTr();
  const postHog = usePostHog();

  const { setFieldValue, values, errors } = useFormikContext<FormValues>();
  const [hasExceededTextLength, setHasExceededTextLength] = useState(false);

  useEffect(() => {
    if (errors.goal) {
      setFieldValue('disableNextStep', getWordCount(values.goal) > maxTextLength);
    }
  }, [errors.goal, setFieldValue, values.goal]);

  function validateGoal(value: string) {
    if (getWordCount(value) > maxTextLength) {
      return translate('blog_post_builder.steps.goal.word_limit_exceeded');
    }
  }

  const handleChange = (newChips: string[]) => {
    postHog?.capture('No of selected tone of voice', {
      amount: newChips.length,
      tonalities: newChips
    });

    if (newChips.join('').length > maxTextLength) {
      setHasExceededTextLength(true);
      return;
    }

    setHasExceededTextLength(false);
    setFieldValue('keywords', newChips);

    if (newChips.length === 0) {
      setFieldValue('elements', []);
    }
  };

  return (
    <FlexContainer gap="medium">
      {values.startedFromOnboarding && (
        <NotificationHint>
          <ColoredTypography color="primary700">
            <FormattedMessage id="blog_post_builder.steps.goal.onboarding_hint" />
          </ColoredTypography>
        </NotificationHint>
      )}

      <div>
        <CommonStyled.Title variant="subtitle1">
          {translate('blog_post_builder.steps.goal.title')}
        </CommonStyled.Title>
        <TextInputField
          name="goal"
          placeholder={translate('blog_post_builder.steps.goal.placeholder')}
          autoComplete="none"
          validate={validateGoal}
        />
      </div>

      <FlexContainer>
        <CommonStyled.Title variant="subtitle1">
          <FormattedMessage id="blog_post_builder.steps.keywords.title" />
        </CommonStyled.Title>

        <UserTonalityInput
          maxAmount={maxAmount}
          userTonalities={values.keywords}
          onChange={handleChange}
          onInputChange={inputValue => {
            // Disable nex step if user entered a tonality but didn't confirmed it
            setFieldValue('disableNextStep', inputValue.length > 0);
          }}
          classes={{
            root: classes.customerTonalityRoot,
            inputRoot: classes.customerTonalityInputRoot
          }}
          textFieldProps={{
            placeholder: translate('blog_post_builder.steps.keywords.placeholder'),
            InputProps: {
              classes: {
                input: classes.customerTonalityInput,
                notchedOutline: classes.customerTonalityInputNotchedOutline
              }
            }
          }}
        />
        {hasExceededTextLength ? <KeywordsLengthExceededHint /> : <KeywordsHint />}
      </FlexContainer>
    </FlexContainer>
  );
};
