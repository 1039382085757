import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Box, Button, Typography } from '@mui/material';
import EmailField from 'components/forms/customFields/EmailField';
import Toast from 'components/toasts/Toast';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { useSendEmailInvitationMutation } from 'features/inviteFriends/byEmail';
import { useReferralLinkButtonBehavior } from 'features/inviteFriends/referralLink';
import { Form, Formik, FormikConfig } from 'formik';
import { useMemo } from 'react';
import styled from 'styled-components';
import { getValidationForField } from 'utils/getValidationForField';
import useTr from 'utils/hooks/useTr';
import { useStableCallback } from 'utils/react/useStableCallback';
import { object, string } from 'yup';

const ReferralLinkButton = () => {
  const { onClick, label, loading } = useReferralLinkButtonBehavior();

  return (
    <GrayButton
      size="large"
      variant="outlined"
      onClick={onClick}
      startIcon={<ContentCopyIcon />}
      disabled={loading}
    >
      <FormattedMessage id={label} />
    </GrayButton>
  );
};

const emailField = 'email';

type FormValues = {
  [emailField]: string;
};

const initialValues: FormValues = {
  [emailField]: ''
};

function InvitationForm() {
  const translate = useTr();
  const invitationMutation = useSendEmailInvitationMutation();

  const validationSchema = useMemo(
    () =>
      object().shape({
        [emailField]: string()
          .trim()
          .email()
          .required(translate(getValidationForField('email')))
      }),
    [translate]
  );

  const onSubmit: FormikConfig<FormValues>['onSubmit'] = useStableCallback(
    async ({ [emailField]: email }, { resetForm }) => {
      const response = await invitationMutation.mutateAsync(email);
      if (response) {
        resetForm();

        Toast.success('profile.invite.sent');
      }
    }
  );

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      <FormBox>
        <Box flex="1 1 auto">
          <EmailField
            name={emailField}
            label="bonus_referral.cards.invite_friends.email_field.label"
            placeholder=""
            variant="standard"
            fullWidth={true}
          />
        </Box>
        <Button
          size="large"
          type="submit"
          variant="contained"
          disabled={invitationMutation.isLoading}
        >
          <FormattedMessage id="bonus_referral.cards.invite_friends.invite_button.label" />
        </Button>
        <ReferralLinkButton />
      </FormBox>
    </Formik>
  );
}

type Props = {
  isHighlighted?: boolean;
};

export const InviteFriendsCard = ({ isHighlighted = true }: Props) => {
  return (
    <Root>
      {isHighlighted && (
        <HighlightLabelBox>
          <FormattedMessage id="bonus_referral.cards.invite_friends.highlight_label" />
        </HighlightLabelBox>
      )}
      <HighlightedCard $isHighlighted={isHighlighted}>
        <Typography variant="h4">
          <FormattedMessage id="bonus_referral.cards.invite_friends.heading" />
        </Typography>

        <BodyTextContainer>
          <Typography variant="body1">
            <FormattedMessage
              id="bonus_referral.cards.invite_friends.explanation"
              values={{
                br: <br />,
                b: (msg: string) => <strong>{msg}</strong>
              }}
            />
          </Typography>
        </BodyTextContainer>
        <InvitationForm />
      </HighlightedCard>
    </Root>
  );
};

const Root = styled.div`
  position: relative;
  isolation: isolate;
`;

const BodyTextContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacings.three};
  margin-bottom: ${({ theme }) => theme.spacings.four};
`;

const HighlightedCard = styled.div<{ $isHighlighted: boolean }>`
  padding: ${({ theme }) => `${theme.spacings.five} ${theme.spacings.four}`};
  border-radius: ${({ theme }) => theme.borderRadius.one};
  background-color: ${({ theme }) => theme.colors.backgroundDefault};

  border: var(--none, 1px) solid
    ${({ theme, $isHighlighted }) =>
      $isHighlighted ? theme.colors.primary__300 : theme.colors.divider};

  box-shadow: ${({ theme, $isHighlighted }) =>
    $isHighlighted ? theme.shadow.inviteFriendCard : 'none'};
  display: grid;
  justify-items: start;
`;

const HighlightLabelBox = styled.div`
  position: absolute;
  top: -30px;
  left: 20px;
  z-index: -1;

  color: ${({ theme }) => theme.colors.commonWhiteMain};
  padding: ${({ theme }) => theme.spacings.one} ${({ theme }) => theme.spacings.two};
  background-color: ${({ theme }) => theme.colors.primary__500main};
  border-top-left-radius: ${({ theme }) => theme.borderRadius.three};
  border-top-right-radius: ${({ theme }) => theme.borderRadius.three};
`;

const FormBox = styled(Form)`
  justify-self: stretch;
  display: flex;
  gap: ${({ theme }) => theme.spacings.small};
  align-items: center;
`;

const GrayButton = styled(Button)`
  border-color: ${({ theme }) => theme.colors.actionActive};
  color: ${({ theme }) => theme.colors.textSecondary};
`;
