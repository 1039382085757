import { CircularProgress } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { AiWriterProjectTile } from 'features/aiWriter/AiWriterProjectOverview/AiWriterProjectTile';
import { useRecentDocuments } from 'features/aiWriter/AiWriterProjectOverview/recent-documents/useRecentDocuments';

export function RecentDocuments() {
  const { isLoading: areProjectsFetching, data, isError } = useRecentDocuments();

  if (areProjectsFetching) {
    return (
      <FlexContainer justifyContent="center" alignItems="center">
        <CircularProgress />
      </FlexContainer>
    );
  }

  if (isError) {
    return null;
  }

  const projects = data;

  if (projects.length === 0) {
    return null;
  }

  return (
    <>
      {projects.map(project => (
        <AiWriterProjectTile
          isRecentSection={true}
          key={project.id}
          project={project}
          isFetching={areProjectsFetching}
        />
      ))}
    </>
  );
}
