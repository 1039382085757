import { Chip, CircularProgress, ListItemIcon, MenuItem, Switch } from '@mui/material';
import Menu, { MenuProps } from '@mui/material/Menu';
import { useCopyLinkToClipboard } from 'features/aiWriter/AiWriterWorkspace/header/useCopyLinkToClipboard';
import { useTogglePublicSharing } from 'features/aiWriter/AiWriterWorkspace/header/useTogglePublicSharing';
import { useToggleTeamSharing } from 'features/aiWriter/AiWriterWorkspace/header/useToggleTeamSharing';
import useSubscriptionState from 'features/pricing/hook/useSubscriptionState';
import { PricingPlan } from 'features/pricing/types';
import useUpdateSubscriptionModal from 'features/updateSubscriptionModal/hook/useUpdateSubscriptionModal';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled from 'styled-components';

type Props = MenuProps;

export const ShareMenu = ({ ...props }: Props) => {
  const { currentPricingPlan } = useSubscriptionState();
  const hasNoTeam =
    currentPricingPlan.type === PricingPlan.free ||
    currentPricingPlan.type === PricingPlan.standard;
  const showUpgradeSubscriptionModal = useUpdateSubscriptionModal();
  const [isCopied, setIsCopied] = useState(false);

  const {
    isShared: isPublicShared,
    isLoading: isPublicSharingLoading,
    toggle: togglePublicSharing
  } = useTogglePublicSharing({
    callback: () => setIsCopied(false)
  });

  const { isShared: isTeamShared, toggle: toggleTeamSharing } = useToggleTeamSharing();

  const copyLinkToClipboard = useCopyLinkToClipboard({
    callback: () => setIsCopied(true)
  });

  const handleUpgradeClick = () => {
    showUpgradeSubscriptionModal();
    props.onClose?.({}, 'backdropClick');
  };

  const handleTeamSharingClick = () => {
    if (!hasNoTeam) {
      return;
    }
    handleUpgradeClick();
  };

  return (
    <StyledMenu {...props} $hasCopyLink={isPublicShared}>
      <ToggleMenuItem onClick={handleTeamSharingClick}>
        <ListItemIcon>
          <Switch
            {...withGtmInteraction(gtmIds.aiWriter.shareDocument.team)}
            size="small"
            color="primary"
            checked={isTeamShared}
            onChange={toggleTeamSharing}
            disabled={hasNoTeam}
          />
        </ListItemIcon>
        <FormattedMessage id="aiWriter.share.modal.share_with_team" />
        {hasNoTeam && (
          <Chip
            color="primary"
            label={<FormattedMessage id="ai_images.generator_page.quality.upgrade_hint" />}
            onClick={handleUpgradeClick}
          />
        )}
      </ToggleMenuItem>
      <ToggleMenuItem onClick={togglePublicSharing}>
        <MinSpacedListItemIcon>
          {isPublicSharingLoading ? (
            <CircularProgress size={24} />
          ) : (
            <Switch
              {...withGtmInteraction(gtmIds.aiWriter.shareDocument.team)}
              id={gtmIds.aiWriter.share.enable}
              size="small"
              color="primary"
              checked={isPublicShared}
              onChange={togglePublicSharing}
            />
          )}
        </MinSpacedListItemIcon>
        <FormattedMessage id="aiWriter.share.modal.switch.label" />
      </ToggleMenuItem>
      {isPublicShared && (
        <CopyLinkButtonMenuItem id={gtmIds.aiWriter.share.copy} onClick={copyLinkToClipboard}>
          <FormattedMessage
            id={isCopied ? 'aiWriter.share.modal.coppied' : 'aiWriter.share.modal.copy_link'}
          />
        </CopyLinkButtonMenuItem>
      )}
    </StyledMenu>
  );
};

const StyledMenu = styled(Menu)<{ $hasCopyLink: boolean }>`
  .MuiList-root {
    padding-bottom: ${({ theme, $hasCopyLink }) => ($hasCopyLink ? '0' : theme.spacings.small)};
  }
`;

const ToggleMenuItem = styled(MenuItem)`
  padding-block: ${({ theme }) => theme.spacings.medium};
  gap: ${({ theme }) => theme.spacings.medium};
`;

const MinSpacedListItemIcon = styled(ListItemIcon)`
  &.MuiListItemIcon-root {
    // Prevent layout jumps if switch is replaced with loading indicator
    min-width: 40px;

    align-items: center;
    justify-content: center;
  }
`;

const CopyLinkButtonMenuItem = styled(MenuItem)`
  width: 100%;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.primary};

  font-size: ${({ theme }) => theme.fontSizes.body2};
  color: ${({ theme }) => theme.colors.white};
  text-align: center;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
  }
`;
