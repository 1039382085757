/* eslint-disable @typescript-eslint/no-explicit-any */
import { CustomerRole, Team } from 'features/customer/store/types';
import { AppLanguage } from 'features/language/store/types';
import { PricingPlan } from 'features/pricing/types';
import { PricingContextParams } from 'services/api/pricing';
import { APIResponse } from 'types/APIResponse';

import { BOACLPricing } from '../pricing/types';
import { ApiCommonErrorCode, ApiExtendedResponse } from '../types';
import CustomerAPI from '.';

export type CustomerDomain = 'customer';

export type CustomerApiEndpoint = keyof typeof CustomerAPI;

export type LoginErrorCode =
  | 'ERROR_WRONG_EMAIL'
  | 'ERROR_UNAUTHORIZED'
  | 'ERROR_CUSTOMER_NOT_VERIFIED'
  | 'ERROR_CUSTOMER_ALREADY_LOGGED_IN'
  | 'ERROR_CUSTOMER_IS_NOT_ACTIVE'
  | 'ERROR_CUSTOMER_DISABLED'
  | 'ERROR_WRONG_SESSION'
  | 'ERROR_AUTH';

export type LoginReponse = ApiExtendedResponse<CustomerData, LoginErrorCode>;
export type UnsubscribeResponse = ApiExtendedResponse<CustomerData, ApiCommonErrorCode>;
export type GetGroupResponse = ApiExtendedResponse<GroupResponse, ApiCommonErrorCode>;

export interface Product {
  id: number;
  name: string;
  label?: string;
  created_at?: string;
  updated_at?: string;
  deleted_at?: any;
}

export interface Feature {
  id: number;
  name: string;
  pricing_id: number;
  deleted_at?: any;
  created_at: string;
  updated_at: string;
}

export interface Subscription {
  customer_id: number;
  feature_restriction_id: number;
  entities_left?: any;
  requests_left?: any;
  trial_ends_at?: Date;
}

export interface FeaturesRestriction {
  id: number;
  endpoint: string;
  http_method: string;
  feature_id: number;
  related_restriction_id?: any;
  requests_per_period?: any;
  max_entities?: any;
  is_enabled?: number;
  created_at: string;
  updated_at: string;
  deleted_at?: any;
  subscription: Subscription;
}

/*
 * Customer data
 */

export type SubscriptionStatus =
  // The subscription has been canceled, but has still some time left in its duration
  | 'grace'
  // The subscription has been canceled and the grace period has ended
  | 'ended'
  // The subscription is active and not cancelled
  | 'subscribed'
  // No active subscription
  | 'not subscribed'
  | 'on trial'
  | 'on trial grace';

export type SubscriptionPaymentStatus = 'fulfilled' | 'pending';

export type CustomerActiveSubscriptionState = {
  status: SubscriptionStatus;
  paymentStatus: SubscriptionPaymentStatus;
};

export type StripeCustomerCoupon = {
  id: string;
  duration: string;
  amount_off: number;
  duration_in_months: number;
  max_redemptions: number;
  name: string;
  percent_off: number;
  valid: boolean;
};

export type CustomerData = {
  id: number;
  name: string;
  email: string;
  api_token?: string;
  api_customer_token: string | null;
  is_active: number;
  timezone: string;
  created_at: string;
  updated_at: string;
  service_id: number;
  verified: number;
  group_id?: any;
  stripe_id?: string;
  expiry_date: string;
  activated_date: string;
  pricing_id: number;
  is_recurring_subscription: number;
  is_api_customer: boolean;
  image_url: string;
  pricing: BOACLPricing;
  features_restrictions: FeaturesRestriction[];
  address: string;
  city: string;
  postal_code: string;
  country: string;
  phone: string;
  pm_type: string;
  pm_last_four: string;
  first_login?: number;
  is_first_login: number;
  // Customer has a default OWNER role thus this field is not nullable
  team_role: CustomerRole;
  teams: Team[];
  company_name: string | null;
  company_invoice_email: string | null;
  use_company_name: number | boolean | null;
  sepa_payment_failed?: number;
  first_promoter_referral_link: string;
  words_used_monthly: number;
  words_shown_monthly: number;
  currency: string;
};

export type CustomerDataResponse = APIResponse<CustomerData | string[]>;
export type CustomerBaseDataResponse = ApiExtendedResponse<CustomerData | string[]>;

export interface AuthRequest {
  email: string;
  password: string;
}

export interface AuthWithSessionRequest {
  session_id: string;
}

export interface CreateAccountRequest {
  email: string;
  name: string;
  password: string;
  newsletterSubscribed: boolean;
  timezone: string;
  image?: string;
  pricing: number;
  address?: string;
  postalCode?: string;
  city?: string;
  country?: string;
  phone?: string;
  taxType?: string;
  taxId?: string;
  use_company_name?: boolean;
  company_name?: string;
  referralId?: string | null;
  bid?: string;
  locale?: AppLanguage;
}

export type CreateAccountResponse = {
  id: number;
  intent?: {
    client_secret: string;
  };
};

export type CreateCheckoutAccountResponse = {
  id: number;
  checkout_url: string;
};

export const isCreateCheckoutResponse = (
  response: CreateAccountResponse | CreateCheckoutAccountResponse
): response is CreateCheckoutAccountResponse => 'checkout_url' in response;

export type GroupResponse = {
  details: {
    id: string;
    name: string;
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
    we_mapper: WeMapperResponse[];
  };
  members: GroupMemberResponse[];
};

export type WeMapperResponse = {
  id: string;
  label: string;
  country: string;
  language: string;
  is_generic: string;
  friendly_country: string;
  friendly_name: string;
  friendly_language: string;
  variables: string[];
};

export interface GroupMemberResponse {
  id: number;
  name: string;
  last_login: string;
  group_start_date: string;
  image_url: string;
  timezone: string;
}

export interface CreateBillingPortalRequest {
  pricingId?: number;
  referralId?: string | null;
  context?: PricingContextParams['context'];
}

export interface CreateBillingPortalResponseForBillingPortal {
  billing_portal_url: string;
  subscription_id?: string;
  price_id?: string;
}

export interface CreateBillingPortalResponseForCheckoutUrl {
  checkout_url: string;
}

export type CreateBillingPortalResponse =
  | CreateBillingPortalResponseForBillingPortal
  | CreateBillingPortalResponseForCheckoutUrl;

export interface ApplyCouponCodeRequest {
  coupon: string;
}

export interface ApplyCouponCodeResponse {
  success: true;
  coupon: StripeCustomerCoupon;
}

export type SubscriptionPeriod = 'year' | 'month';

export interface ACLPricing {
  id: number;
  name: string;
  type: PricingPlan;
  cost: string;
  currency: string;
  period: number;
  periodName: SubscriptionPeriod;
  isFree: boolean;
  description?: string;
  isTrial: boolean;
}

export type RecaptchaAction = 'login' | 'register';

// TODO recaptcha change response based on backend
export type RecaptchaResponse = any;

export type ApiCustomerSubscriptionData = {
  subscription_status: SubscriptionStatus;
  subscription_is_incomplete: number;
};

export type GetCustomerSubscriptionResponse = ApiExtendedResponse<
  ApiCustomerSubscriptionData,
  ApiCommonErrorCode
>;

interface CouponAnnual {
  duration: 'once';
  percent_off: number;
}

interface CouponMonthly {
  duration: 'repeating';
  duration_in_months: number;
  percent_off: number;
}

export type Coupon = CouponAnnual | CouponMonthly;

export type GetHasChurnDiscountData = {
  is_churn_discount_available: boolean;
  coupon: Coupon;
};

export type GetHasChurnDiscountResponse = ApiExtendedResponse<
  GetHasChurnDiscountData,
  ApiCommonErrorCode
>;

export type GetChurnStatisticData = {
  document_count: number;
  used_words_count: number;
  available_words_count: number;
  has_unlimited_words: boolean;
  registration_date: string;
};

export type GetChurnStatisticResponse = ApiExtendedResponse<
  GetChurnStatisticData,
  ApiCommonErrorCode
>;

/**
 * #tech-debt https://app.clickup.com/t/863g53crf
 * Remove backwards compatible fields when API has been deployed
 */
export type WordsCurrentUsage = {
  /**
   * -1: unlimited amount of words
   *  0: no words left
   * >0: X words left
   *
   * So to check if no words are left, use === 0 instead of <= 0.
   */
  words_available: number;
  /**
   * The actual limit, without bonus words
   */
  words_limit: number;
  /**
   * The amount of words used in the current period
   */
  words_used_current_period: number;
  /**
   * The amount of bonus words available
   */
  bonus_words_available: number;
  words_renewal_date: Date | null;
  /**
   * Specific flags for general text generation
   */
  text_generation: {
    is_unlimited: boolean;
    is_available: boolean;
  };
  /**
   * Specific flags for chat conversions
   */
  project_conversation: {
    is_unlimited: boolean;
    is_available: boolean;
  };

  gpt4_project_conversation?: {
    is_unlimited: boolean;
    is_available: boolean;
  };
};

export type GetWordsCurrentUsageResponse = ApiExtendedResponse<
  WordsCurrentUsage,
  ApiCommonErrorCode
>;

export type WordsUsageHistory = {
  [key in string]: number;
};

export type GetWordsUsageHistorySuccessData = {
  usageHistory: WordsUsageHistory;
};

export type GetWordsUsageHistoryResponse = ApiExtendedResponse<
  GetWordsUsageHistorySuccessData,
  ApiCommonErrorCode
>;

export type CreateReferralLinkResponse = ApiExtendedResponse<
  {
    first_promoter_referral_link: string;
  },
  ApiCommonErrorCode
>;

export type ApplyPromoCodeResponse = ApiExtendedResponse<
  {
    coupon: StripeCustomerCoupon;
  },
  ApiCommonErrorCode
>;
