import LogoAnimation from 'assets/loadingAnimation/logo-animation.lottie';
import FlexContainer from 'components/FlexContainer';
import FullMask from 'components/FullMask';
import { getIsAppReady } from 'features/customer/store/selectors';
import { LottieComponent } from 'features/lottie/LottieComponent';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';

type Props = {
  children: React.ReactElement;
};

export const AppLoader = ({ children }: Props) => {
  const isReady = useAppSelector(getIsAppReady);
  const [shouldShowLoader, setShouldShowLoader] = useState(false);

  useEffect(() => {
    if (!shouldShowLoader) {
      setTimeout(() => setShouldShowLoader(true), 1000);
    }
  }, [shouldShowLoader]);

  return (
    <AnimatePresence>
      {isReady ? (
        <motion.div
          key="children"
          exit={{}}
          style={{
            width: '100%',
            height: '100%'
          }}
        >
          {children}
        </motion.div>
      ) : (
        <FullMask
          $backgroundColor="#fff"
          $zIndex={40000}
          key="mask"
          style={{ opacity: 1 }}
          initial={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          {shouldShowLoader && (
            <HalfMask>
              <LottieComponent src={LogoAnimation} options={{ speed: 1.5 }} />
            </HalfMask>
          )}
        </FullMask>
      )}
    </AnimatePresence>
  );
};

export const HalfMask = styled(FlexContainer)`
  width: 50%;
  height: 50%;
  align-items: center;
  justify-content: center;
`;
