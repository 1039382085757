import { getCurrentLanguage } from 'features/language/store/selectors';
import { AppLanguage } from 'features/language/store/types';
import { sample } from 'lodash';
import { useAppSelector } from 'store/hooks';

export const useSelectRandomImageExample = () => {
  const appLanguage = useAppSelector(getCurrentLanguage);

  return () => sample(PromptExampleList[appLanguage]) as string;
};

type PromptExample = Record<AppLanguage, Array<string>>;

const PromptExampleList: PromptExample = {
  [AppLanguage.English]: [
    'Stock photo, close-up: Man sitting at a lake with a dog on a leash. Styled like Norman Rockwell.',
    'Realistic photo, wide shot: Car on display at a trade show. Styled like modern automotive photography.',
    "Illustration, bird's-eye view: People waiting at a bus stop. Styled like Edward Hopper.",
    'Graphic, side view: Stressed office worker with papers piled high. Styled like Roy Lichtenstein.',
    'Product presentation, close-up: Vintage radio on a wooden table. Styled like Bauhaus.',
    'Stock photo, navel perspective: Family having a picnic in a park. Styled like David Hockney.',
    'Realistic photo, central: Kids playing soccer in a field. Styled like Henri Cartier-Bresson.',
    'Illustration, frog perspective: Cat sitting on a windowsill with flowers. Styled like Henri Matisse.',
    'Graphic, top-down view: Coffee shop counter with pastries and coffee cups. Styled like Wayne Thiebaud.',
    'Product presentation, medium long shot: Stylish handbag on a display stand. Styled like high fashion photography.',
    "Stock photo, close-up: Chef preparing food in a restaurant kitchen. Styled like Julia Child's culinary scenes.",
    'Realistic photo, wide shot: Concert crowd with stage lights. Styled like modern concert photography.',
    'Illustration, central: Old couple sitting on a park bench. Styled like Grant Wood.',
    'Graphic, navel perspective: Gamer playing on a console with neon lights. Styled like Cyberpunk.',
    'Product presentation, close-up: Elegant watch on a marble surface. Styled like luxury product photography.',
    "Stock photo, bird's-eye view: Farmers working in a field. Styled like agrarian realism.",
    'Realistic photo, frog perspective: Hiker standing on a mountain peak. Styled like Ansel Adams.',
    'Illustration, side view: Child reading a book under a tree. Styled like Beatrix Potter.',
    'Graphic, close-up: Vintage bicycle leaning against a brick wall. Styled like urban street art.',
    'Product presentation, wide shot: Modern kitchen appliances on a counter. Styled like contemporary design.',
    'Stock photo, medium long shot: Friends playing board games at a table. Styled like Norman Rockwell.',
    'Realistic photo, close-up: Doctor examining a patient. Styled like medical photography.',
    'Illustration, central: Dog playing with a ball in a yard. Styled like Charles Schulz.',
    'Graphic, top-down view: Office desk with computer, coffee, and stationery. Styled like minimalist design.',
    'Product presentation, side view: New smartphone on a sleek surface. Styled like tech advertising.',
    'Stock photo, navel perspective: Beach scene with people sunbathing and swimming. Styled like Slim Aarons.',
    'Realistic photo, frog perspective: Cyclist on a city street. Styled like Henri Cartier-Bresson.',
    'Illustration, wide shot: Children flying kites in a park. Styled like Mary Blair.',
    'Graphic, close-up: Musician playing guitar on a stage. Styled like Andy Warhol.',
    'Product presentation, medium long shot: Luxury car parked on a city street. Styled like high-end automotive photography.',
    'Stock photo, central: Friends enjoying a barbecue in the backyard. Styled like lifestyle photography.',
    "Realistic photo, bird's-eye view: Busy street market with vendors and shoppers. Styled like street photography.",
    'Illustration, frog perspective: Cat lounging in a sunbeam. Styled like Tasha Tudor.',
    'Graphic, top-down view: Desk with art supplies and sketches. Styled like creative workspace.',
    'Product presentation, close-up: Designer sunglasses on a beach towel. Styled like fashion photography.',
    'Stock photo, side view: Person jogging on a trail at sunrise. Styled like fitness photography.',
    'Realistic photo, medium long shot: People dancing at a wedding reception. Styled like wedding photography.',
    'Illustration, central: Child drawing with crayons on the floor. Styled like Quentin Blake.',
    'Graphic, close-up: Retro car dashboard with classic instruments. Styled like vintage automotive art.',
    'Product presentation, wide shot: Modern sofa in a living room. Styled like interior design photography.',
    "Stock photo, bird's-eye view: People kayaking on a river. Styled like adventure photography.",
    'Realistic photo, navel perspective: Commuters reading newspapers on a train. Styled like everyday realism.',
    'Illustration, side view: Woman practicing yoga in a studio. Styled like serene art.',
    'Graphic, top-down view: Table with breakfast spread and coffee. Styled like Wayne Thiebaud.',
    'Product presentation, close-up: Smartwatch displaying fitness stats. Styled like tech advertising.',
    'Stock photo, frog perspective: Kids riding bicycles down a street. Styled like playful photography.',
    'Realistic photo, medium long shot: Shoppers browsing in a mall. Styled like commercial photography.',
    'Illustration, central: Cat and dog sleeping together. Styled like Beatrix Potter.',
    'Graphic, wide shot: Vintage film camera with photos spread out. Styled like nostalgic art.',
    'Product presentation, close-up: Luxury perfume bottle with flowers. Styled like high-end product photography.',
    'Stock photo, close-up: Woman painting on a canvas. Styled like artistic photography.',
    'Realistic photo, wide shot: Farmers market with colorful produce. Styled like vibrant photography.',
    "Illustration, bird's-eye view: People ice skating on a frozen pond. Styled like Currier and Ives.",
    'Graphic, side view: Teenager listening to music with headphones. Styled like pop culture art.',
    'Product presentation, close-up: High-end laptop on a sleek desk. Styled like tech advertising.',
    'Stock photo, navel perspective: Family decorating a Christmas tree. Styled like holiday photography.',
    'Realistic photo, close-up: Business meeting with people shaking hands. Styled like corporate photography.',
    'Illustration, frog perspective: Child blowing bubbles in a garden. Styled like Maurice Sendak.',
    'Graphic, top-down view: Collection of colorful vintage toys. Styled like nostalgic pop art.',
    'Product presentation, side view: New electric car charging. Styled like sustainable technology.',
    "Stock photo, bird's-eye view: Beach volleyball game in action. Styled like sports photography.",
    'Realistic photo, medium long shot: Couple walking hand in hand in a park. Styled like romantic photography.',
    'Illustration, central: Astronaut floating in space. Styled like science fiction art.',
    "Graphic, close-up: Artist's palette with paint and brushes. Styled like creative workspace.",
    'Product presentation, wide shot: High-tech home office setup. Styled like modern productivity.',
    'Stock photo, frog perspective: Skater performing tricks at a skatepark. Styled like urban action photography.',
    'Realistic photo, navel perspective: Tourists taking photos at a landmark. Styled like travel photography.',
    'Illustration, side view: Children building a sandcastle on the beach. Styled like playful art.',
    'Graphic, top-down view: Vintage kitchen with retro appliances. Styled like nostalgic home art.',
    'Product presentation, close-up: Stylish sneakers on a wooden floor. Styled like fashion photography.',
    'Stock photo, central: Friends toasting drinks at a party. Styled like social event photography.',
    "Realistic photo, bird's-eye view: Cityscape at night with illuminated buildings. Styled like urban photography.",
    'Illustration, frog perspective: Squirrel climbing a tree. Styled like nature illustration.',
    'Graphic, side view: Classic car driving on a country road. Styled like vintage automotive art.',
    'Product presentation, medium long shot: Modern home with smart technology. Styled like futuristic home design.',
    'Stock photo, close-up: Barista making coffee at a café. Styled like lifestyle photography.',
    'Realistic photo, wide shot: Hikers crossing a suspension bridge. Styled like adventure photography.',
    "Illustration, bird's-eye view: Children playing hopscotch on a sidewalk. Styled like playful illustration.",
    'Graphic, navel perspective: Record player with vinyl records. Styled like retro music art.',
    'Product presentation, side view: High-quality camera on a tripod. Styled like professional photography.',
    'Stock photo, medium long shot: Family walking their dog in the park. Styled like family photography.',
    'Realistic photo, central: Businesswoman giving a presentation. Styled like corporate photography.',
    'Illustration, top-down view: Picnic spread with a variety of foods. Styled like cozy illustration.',
    'Graphic, close-up: Old-fashioned typewriter with paper. Styled like vintage office art.',
    'Product presentation, wide shot: Electric bike on a scenic trail. Styled like eco-friendly product photography.',
    'Stock photo, frog perspective: Boy flying a drone in a field. Styled like tech lifestyle photography.',
    'Realistic photo, navel perspective: Street artist painting a mural. Styled like urban art photography.',
    'Illustration, side view: Grandma knitting in a rocking chair. Styled like homey illustration.',
    "Graphic, bird's-eye view: Open suitcase with travel essentials. Styled like travel art.",
    'Product presentation, close-up: Premium headphones on a stand. Styled like modern tech advertising.',
    "Stock photo, close-up: Child playing with building blocks on the floor. Styled like children's photography.",
    'Realistic photo, wide shot: Surfer riding a large wave. Styled like action photography.',
    "Illustration, bird's-eye view: People sitting in an open-air cinema. Styled like Quentin Blake.",
    'Graphic, side view: Woman reading a book in a cozy chair. Styled like illustrative art.',
    'Product presentation, close-up: Modern water bottle on a rock. Styled like outdoor advertising.',
    'Stock photo, navel perspective: People sitting in a café, chatting. Styled like lifestyle photography.',
    'Realistic photo, central: Child petting a goat on a farm. Styled like rural photography.',
    'Illustration, frog perspective: Parrot sitting on a branch in the jungle. Styled like animal illustration.',
    'Graphic, top-down view: Breakfast table with pancakes and fruit. Styled like Wayne Thiebaud.',
    'Product presentation, medium long shot: Elegant candlesticks on a dining table. Styled like luxury photography.',
    'Stock photo, close-up: Musician playing violin in an orchestra. Styled like classical music photography.',
    'Realistic photo, wide shot: Hikers standing on a mountain peak looking into the valley. Styled like landscape photography.',
    "Illustration, central: Children crafting with paper and scissors. Styled like children's book illustration.",
    'Graphic, navel perspective: Chef preparing sushi in a kitchen. Styled like culinary art.',
    'Product presentation, close-up: Stylish wristwatch on a marble table. Styled like tech advertising.',
    "Stock photo, bird's-eye view: People paddling in canoes on a calm lake. Styled like adventure photography.",
    'Realistic photo, frog perspective: Ballerina practicing in a studio. Styled like dance photography.',
    'Illustration, side view: Boy playing on a swing in the garden. Styled like cheerful illustration.',
    'Graphic, close-up: Old rotary phone on a table. Styled like vintage art.',
    'Product presentation, wide shot: Modern bedroom with chic decor. Styled like interior design photography.',
    'Stock photo, medium long shot: Friends celebrating a birthday party outdoors. Styled like event photography.',
    'Realistic photo, close-up: Doctor examining an X-ray. Styled like medical photography.',
    'Illustration, central: Dog running along the beach. Styled like playful illustration.',
    'Graphic, top-down view: Desk with computer and art supplies. Styled like creative workspace.',
    'Product presentation, side view: New smartphone on a wooden table. Styled like tech advertising.',
    'Stock photo, navel perspective: People having a picnic in a city park. Styled like lifestyle photography.',
    'Realistic photo, frog perspective: Mountain biker riding down a steep trail. Styled like action photography.',
    'Illustration, wide shot: Children playing at a fairground. Styled like vibrant illustration.',
    'Graphic, close-up: Vintage camera on a table with old photos. Styled like nostalgic art.',
    'Product presentation, wide shot: Modern kitchen with sleek appliances. Styled like interior design photography.',
    'Stock photo, close-up: Woman doing yoga on a beach at sunrise. Styled like wellness photography.',
    'Realistic photo, wide shot: Group of friends hiking in a forest. Styled like nature photography.',
    "Illustration, bird's-eye view: Farmers harvesting crops in a field. Styled like agrarian realism.",
    "Graphic, side view: Child drawing with crayons. Styled like children's art.",
    'Product presentation, close-up: High-end headphones on a desk. Styled like tech advertising.',
    'Stock photo, navel perspective: People dancing at a music festival. Styled like event photography.',
    'Realistic photo, central: Elderly couple holding hands on a bench. Styled like romantic photography.',
    'Illustration, frog perspective: Cat chasing a butterfly in a garden. Styled like whimsical art.',
    'Graphic, top-down view: Workspace with laptop, coffee, and notebooks. Styled like minimalist design.',
    'Product presentation, medium long shot: Luxury handbag on a display. Styled like fashion photography.',
    'Stock photo, close-up: Chef garnishing a plate in a restaurant. Styled like culinary photography.',
    'Realistic photo, wide shot: Cyclists riding through a city. Styled like urban photography.',
    'Illustration, central: Girl reading under a tree. Styled like serene art.',
    'Graphic, navel perspective: Musician playing guitar on stage. Styled like pop culture art.',
    'Product presentation, close-up: Elegant watch on a wooden surface. Styled like high-end product photography.',
    "Stock photo, bird's-eye view: Kids playing soccer on a field. Styled like sports photography.",
    'Realistic photo, frog perspective: Dog running through a field. Styled like action photography.',
    'Illustration, side view: Family decorating a Christmas tree. Styled like holiday illustration.',
    'Graphic, close-up: Retro typewriter with paper. Styled like vintage office art.',
    'Product presentation, wide shot: Modern living room with stylish furniture. Styled like interior design photography.',
    'Stock photo, medium long shot: Friends having a barbecue in the backyard. Styled like lifestyle photography.',
    "Realistic photo, close-up: Nurse checking a patient's blood pressure. Styled like medical photography.",
    'Illustration, central: Children flying kites in a park. Styled like whimsical illustration.',
    'Graphic, top-down view: Coffee table with books and a plant. Styled like minimalist design.',
    'Product presentation, side view: New tablet on a modern desk. Styled like tech advertising.',
    'Stock photo, navel perspective: People enjoying a sunny day at the beach. Styled like summer photography.',
    'Realistic photo, frog perspective: Kids playing in a puddle after the rain. Styled like candid photography.',
    'Illustration, wide shot: Parents reading to children at bedtime. Styled like cozy illustration.',
    'Graphic, close-up: Vintage radio on a shelf. Styled like nostalgic art.',
    'Product presentation, wide shot: Modern bathroom with elegant fixtures. Styled like interior design photography.',
    'Stock photo, close-up: Woman practicing calligraphy. Styled like artistic photography.',
    'Realistic photo, wide shot: Campers setting up tents in the woods. Styled like adventure photography.',
    "Illustration, bird's-eye view: People ice skating on a frozen pond. Styled like winter scene art.",
    'Graphic, side view: Teenager playing video games. Styled like pop culture art.',
    'Product presentation, close-up: Stylish sneakers on a wooden floor. Styled like fashion photography.',
    'Stock photo, navel perspective: Family playing board games at home. Styled like cozy photography.',
    'Realistic photo, frog perspective: Child blowing out birthday candles. Styled like candid photography.',
    'Illustration, central: Couple dancing under the stars. Styled like romantic illustration.',
    'Graphic, top-down view: Desk with art supplies and sketches. Styled like creative workspace.',
    'Product presentation, medium long shot: Designer lamp on a bedside table. Styled like modern product photography.',
    'Stock photo, close-up: Woman cooking in a modern kitchen. Styled like culinary photography.',
    'Realistic photo, wide shot: People exploring a farmers market. Styled like vibrant photography.',
    'Illustration, central: Children playing in the autumn leaves. Styled like seasonal illustration.',
    'Graphic, navel perspective: Musician playing drums on stage. Styled like concert art.',
    'Product presentation, close-up: Premium laptop on a sleek desk. Styled like tech advertising.',
    "Stock photo, bird's-eye view: Friends having a picnic in a meadow. Styled like lifestyle photography.",
    'Realistic photo, frog perspective: Dog jumping to catch a ball. Styled like action photography.',
    'Illustration, side view: Child playing with a toy train. Styled like playful illustration.',
    'Graphic, close-up: Vintage clock on a wooden shelf. Styled like nostalgic art.',
    'Product presentation, wide shot: Modern office with stylish furniture. Styled like interior design photography.',
    'Stock photo, medium long shot: Friends toasting drinks at a rooftop party. Styled like social event photography.',
    'Realistic photo, close-up: Doctor writing notes on a clipboard. Styled like medical photography.',
    'Illustration, central: Family building a snowman. Styled like winter illustration.',
    'Graphic, top-down view: Workspace with a computer and coffee mug. Styled like minimalist design.',
    'Product presentation, side view: Elegant fountain pen on a desk. Styled like luxury product photography.',
    'Stock photo, navel perspective: People playing volleyball on the beach. Styled like summer photography.',
    'Realistic photo, frog perspective: Kids playing in the leaves in autumn. Styled like candid photography.',
    'Illustration, wide shot: Couples dancing at a ball. Styled like romantic illustration.',
    'Graphic, close-up: Retro camera with old photos. Styled like nostalgic art.',
    'Product presentation, wide shot: Stylish dining room with modern furniture. Styled like interior design photography.',
    'Stock photo, close-up: Man painting on a canvas in a studio. Styled like artistic photography.',
    'Realistic photo, wide shot: Hikers crossing a bridge over a river. Styled like adventure photography.',
    "Illustration, bird's-eye view: Kids playing hopscotch on a sidewalk. Styled like playful art.",
    'Graphic, side view: Woman meditating by a lake. Styled like serene art.',
    'Product presentation, close-up: Luxury perfume bottle with flowers. Styled like high-end product photography.',
    'Stock photo, navel perspective: Friends enjoying a bonfire at night. Styled like lifestyle photography.',
    'Realistic photo, frog perspective: Child picking flowers in a meadow. Styled like candid photography.',
    'Illustration, central: Squirrel climbing a tree. Styled like nature illustration.',
    'Graphic, top-down view: Table with breakfast and coffee. Styled like Wayne Thiebaud.',
    'Product presentation, medium long shot: Modern home with smart technology. Styled like futuristic design.',
    'Stock photo, close-up: Barista making coffee in a café. Styled like lifestyle photography.',
    'Realistic photo, wide shot: Friends walking on a beach at sunset. Styled like travel photography.',
    'Illustration, central: Children playing with bubbles in a garden. Styled like cheerful art.',
    'Graphic, navel perspective: Retro car dashboard. Styled like vintage automotive art.',
    'Product presentation, side view: High-end camera on a tripod. Styled like professional photography.',
    "Stock photo, bird's-eye view: Family playing soccer in the backyard. Styled like lifestyle photography.",
    'Realistic photo, frog perspective: Skater doing tricks in a skatepark. Styled like action photography.',
    'Graphic, close-up: Record player with vinyl records. Styled like retro music art.',
    'Product presentation, wide shot: Modern kitchen with sleek appliances. Styled like interior design photography.',
    'Stock photo, medium long shot: Friends laughing at a café. Styled like social photography.    '
  ],
  [AppLanguage.German]: [
    'Stockfoto, Nahaufnahme: Mann sitzt an einem See mit einem Hund an der Leine. Im Stil von Norman Rockwell.',
    'Realistisches Foto, Weitwinkel: Auto auf einer Messe ausgestellt. Im Stil moderner Automobilfotografie.',
    'Illustration, Vogelperspektive: Menschen warten an einer Bushaltestelle. Im Stil von Edward Hopper.',
    'Grafik, Seitenansicht: Gestresster Büroangestellter mit einem Berg von Papieren. Im Stil von Roy Lichtenstein.',
    'Produktpräsentation, Nahaufnahme: Vintage-Radio auf einem Holztisch. Im Stil des Bauhaus.',
    'Stockfoto, Nabelperspektive: Familie beim Picknick im Park. Im Stil von David Hockney.',
    'Realistisches Foto, Zentral: Kinder spielen Fußball auf einem Feld. Im Stil von Henri Cartier-Bresson.',
    'Illustration, Froschperspektive: Katze sitzt auf einer Fensterbank mit Blumen. Im Stil von Henri Matisse.',
    'Grafik, Draufsicht: Kaffeetheke mit Gebäck und Kaffeetassen. Im Stil von Wayne Thiebaud.',
    'Produktpräsentation, Halbnahe: Stilvolle Handtasche auf einem Displayständer. Im Stil der High Fashion Fotografie.',
    "Stockfoto, Nahaufnahme: Koch bereitet Essen in einer Restaurantküche zu. Im Stil von Julia Child's Kulinarischen Szenen.",
    'Realistisches Foto, Weitwinkel: Konzertpublikum mit Bühnenlichtern. Im Stil moderner Konzertfotografie.',
    'Illustration, Zentral: Älteres Paar sitzt auf einer Parkbank. Im Stil von Grant Wood.',
    'Grafik, Nabelperspektive: Gamer spielt auf einer Konsole mit Neonlichtern. Im Stil von Cyberpunk.',
    'Produktpräsentation, Nahaufnahme: Elegante Uhr auf einer Marmoroberfläche. Im Stil der Luxus-Produktfotografie.',
    'Stockfoto, Vogelperspektive: Bauern arbeiten auf einem Feld. Im Stil des agrarischen Realismus.',
    'Realistisches Foto, Froschperspektive: Wanderer steht auf einem Berggipfel. Im Stil von Ansel Adams.',
    'Illustration, Seitenansicht: Kind liest ein Buch unter einem Baum. Im Stil von Beatrix Potter.',
    'Grafik, Nahaufnahme: Vintage-Fahrrad lehnt an einer Ziegelmauer. Im Stil von urbaner Straßenkunst.',
    'Produktpräsentation, Weitwinkel: Moderne Küchengeräte auf einer Theke. Im Stil des zeitgenössischen Designs.',
    'Stockfoto, Halbnahe: Freunde spielen Brettspiele an einem Tisch. Im Stil von Norman Rockwell.',
    'Realistisches Foto, Nahaufnahme: Arzt untersucht einen Patienten. Im Stil der medizinischen Fotografie.',
    'Illustration, Zentral: Hund spielt mit einem Ball im Garten. Im Stil von Charles Schulz.',
    'Grafik, Draufsicht: Schreibtisch mit Computer, Kaffee und Schreibutensilien. Im minimalistischen Design.',
    'Produktpräsentation, Seitenansicht: Neues Smartphone auf einer eleganten Oberfläche. Im Stil der Tech-Werbung.',
    'Stockfoto, Nabelperspektive: Strandlandschaft mit Sonnenbadenden und Schwimmern. Im Stil von Slim Aarons.',
    'Realistisches Foto, Froschperspektive: Radfahrer auf einer Stadtstraße. Im Stil von Henri Cartier-Bresson.',
    'Illustration, Weitwinkel: Kinder lassen Drachen im Park steigen. Im Stil von Mary Blair.',
    'Grafik, Nahaufnahme: Musiker spielt Gitarre auf einer Bühne. Im Stil von Andy Warhol.',
    'Produktpräsentation, Halbnahe: Luxusauto geparkt auf einer Stadtstraße. Im Stil der High-End-Automobilfotografie.',
    'Stockfoto, Zentral: Freunde genießen eine Grillparty im Hinterhof. Im Stil der Lifestyle-Fotografie.',
    'Realistisches Foto, Vogelperspektive: Belebter Straßenmarkt mit Verkäufern und Käufern. Im Stil der Straßenfotografie.',
    'Illustration, Froschperspektive: Katze sonnt sich auf einem Fensterbrett. Im Stil von Tasha Tudor.',
    'Grafik, Draufsicht: Schreibtisch mit Kunstbedarf und Skizzen. Im Stil des kreativen Arbeitsbereichs.',
    'Produktpräsentation, Nahaufnahme: Designer-Sonnenbrille auf einem Strandtuch. Im Stil der Modefotografie.',
    'Stockfoto, Seitenansicht: Person joggt auf einem Pfad bei Sonnenaufgang. Im Stil der Fitnessfotografie.',
    'Realistisches Foto, Halbnahe: Menschen tanzen auf einer Hochzeitsfeier. Im Stil der Hochzeitsfotografie.',
    'Illustration, Zentral: Kind malt mit Buntstiften auf dem Boden. Im Stil von Quentin Blake.',
    'Grafik, Nahaufnahme: Retro-Autodashboard mit klassischen Instrumenten. Im Stil der Vintage-Automobilkunst.',
    'Produktpräsentation, Weitwinkel: Modernes Sofa in einem Wohnzimmer. Im Stil der Innendesignfotografie.',
    'Stockfoto, Vogelperspektive: Menschen beim Kajakfahren auf einem Fluss. Im Stil der Abenteuerfotografie.',
    'Realistisches Foto, Nabelperspektive: Pendler lesen Zeitungen in einem Zug. Im alltäglichen Realismus.',
    'Illustration, Seitenansicht: Frau praktiziert Yoga in einem Studio. Im Stil der ruhigen Kunst.',
    'Grafik, Draufsicht: Tisch mit Frühstück und Kaffee. Im Stil von Wayne Thiebaud.',
    'Produktpräsentation, Nahaufnahme: Smartwatch zeigt Fitnessstatistiken an. Im Stil der Tech-Werbung.',
    'Stockfoto, Froschperspektive: Kinder fahren auf Fahrrädern die Straße entlang. Im Stil der spielerischen Fotografie.',
    'Realistisches Foto, Halbnahe: Käufer stöbern in einem Einkaufszentrum. Im Stil der kommerziellen Fotografie.',
    'Illustration, Zentral: Katze und Hund schlafen zusammen. Im Stil von Beatrix Potter.',
    'Grafik, Weitwinkel: Vintage-Filmkamera mit ausgebreiteten Fotos. Im Stil der nostalgischen Kunst.',
    'Produktpräsentation, Nahaufnahme: Luxusparfümflasche mit Blumen. Im Stil der High-End-Produktfotografie.',
    'Stockfoto, Nahaufnahme: Frau malt auf einer Leinwand. Im Stil der künstlerischen Fotografie.',
    'Realistisches Foto, Weitwinkel: Bauernmarkt mit buntem Obst und Gemüse. Im Stil der lebendigen Fotografie.',
    'Illustration, Vogelperspektive: Menschen beim Schlittschuhlaufen auf einem gefrorenen Teich. Im Stil von Currier und Ives.',
    'Grafik, Seitenansicht: Teenager hört mit Kopfhörern Musik. Im Stil der Popkulturkunst.',
    'Stockfoto, Nahaufnahme: Junge spielt mit Bauklötzen auf dem Boden. Im Stil der Kinderfotografie.',
    'Realistisches Foto, Weitwinkel: Surfer reitet eine große Welle. Im Stil der Actionfotografie.',
    'Illustration, Vogelperspektive: Menschen sitzen in einem Open-Air-Kino. Im Stil von Quentin Blake.',
    'Grafik, Seitenansicht: Frau liest ein Buch in einem gemütlichen Sessel. Im Stil von Norman Rockwell.',
    'Produktpräsentation, Nahaufnahme: Moderne Wasserflasche auf einem Felsen. Im Stil der Outdoor-Werbung.',
    'Stockfoto, Nabelperspektive: Menschen sitzen in einem Café und unterhalten sich. Im Stil der Lifestyle-Fotografie.',
    'Realistisches Foto, Zentral: Kind streichelt eine Ziege auf einem Bauernhof. Im Stil der ländlichen Fotografie.',
    'Illustration, Froschperspektive: Papagei sitzt auf einem Ast im Dschungel. Im Stil von Henri Rousseau.',
    'Grafik, Draufsicht: Frühstückstisch mit Pfannkuchen und Obst. Im Stil von Wayne Thiebaud.',
    'Produktpräsentation, Halbnahe: Elegante Kerzenständer auf einem Esstisch. Im Stil von Gustav Klimt.',
    'Stockfoto, Nahaufnahme: Musiker spielt Violine in einem Orchester. Im Stil der klassischen Musikfotografie.',
    'Realistisches Foto, Weitwinkel: Wanderer stehen auf einem Berggipfel und schauen ins Tal. Im Stil der Landschaftsfotografie.',
    'Illustration, Zentral: Kinder basteln mit Papier und Schere. Im Stil von Beatrix Potter.',
    'Grafik, Nabelperspektive: Koch bereitet Sushi in einer Küche zu. Im Stil von Hokusai.',
    'Produktpräsentation, Nahaufnahme: Modische Armbanduhr auf einem Marmortisch. Im Stil der Tech-Werbung.',
    'Stockfoto, Vogelperspektive: Menschen paddeln in Kanus auf einem ruhigen See. Im Stil der Abenteuerfotografie.',
    'Realistisches Foto, Froschperspektive: Tänzerin übt Ballett in einem Studio. Im Stil von Edgar Degas.',
    'Illustration, Seitenansicht: Junge spielt auf einer Schaukel im Garten. Im Stil von Mary Cassatt.',
    'Grafik, Nahaufnahme: Altes Telefon mit Wählscheibe auf einem Tisch. Im Stil von Edward Hopper.',
    'Produktpräsentation, Weitwinkel: Modernes Schlafzimmer mit schicker Einrichtung. Im Stil von Bauhaus.',
    'Stockfoto, Halbnahe: Freunde feiern eine Geburtstagsparty im Freien. Im Stil von Norman Rockwell.',
    'Realistisches Foto, Nahaufnahme: Arzt untersucht einen Röntgenbild. Im Stil der medizinischen Fotografie.',
    'Illustration, Zentral: Hund läuft am Strand entlang. Im Stil von Charles Schulz.',
    'Grafik, Draufsicht: Schreibtisch mit Computer und Zeichenmaterialien. Im Stil von Picasso.',
    'Produktpräsentation, Seitenansicht: Neues Smartphone auf einem hölzernen Tisch. Im Stil der Tech-Werbung.',
    'Stockfoto, Nabelperspektive: Menschen picknicken in einem Stadtpark. Im Stil der Lifestyle-Fotografie.',
    'Realistisches Foto, Froschperspektive: Mountainbiker fährt einen steilen Pfad hinunter. Im Stil der Actionfotografie.',
    'Illustration, Weitwinkel: Kinder spielen auf einem Rummelplatz. Im Stil von Maurice Sendak.',
    'Grafik, Nahaufnahme: Vintage-Kamera auf einem Tisch mit alten Fotos. Im Stil der nostalgischen Kunst.',
    'Produktpräsentation, Weitwinkel: Moderne Küche mit eleganten Geräten. Im Stil von Bauhaus.',
    'Stockfoto, Nahaufnahme: Frau macht Yoga am Strand bei Sonnenaufgang. Im Stil der Wellness-Fotografie.',
    'Realistisches Foto, Weitwinkel: Gruppe von Freunden wandert durch einen Wald. Im Stil von Ansel Adams.',
    'Illustration, Vogelperspektive: Bauern ernten Feldfrüchte. Im Stil von Vincent van Gogh.',
    'Grafik, Seitenansicht: Kind malt mit Buntstiften. Im Stil von Joan Miró.',
    'Produktpräsentation, Nahaufnahme: Hochwertige Kopfhörer auf einem Schreibtisch. Im Stil der Tech-Werbung.',
    'Stockfoto, Nabelperspektive: Menschen tanzen auf einem Musikfestival. Im Stil der Eventfotografie.',
    'Realistisches Foto, Zentral: Älteres Paar hält sich an den Händen auf einer Bank. Im Stil der romantischen Fotografie.',
    'Illustration, Froschperspektive: Katze jagt einen Schmetterling im Garten. Im Stil von Claude Monet.',
    'Grafik, Draufsicht: Arbeitsplatz mit Laptop, Kaffee und Notizbüchern. Im minimalistischen Design.',
    'Produktpräsentation, Halbnahe: Luxus-Handtasche auf einem Display. Im Stil der Modefotografie.',
    'Stockfoto, Nahaufnahme: Koch verziert ein Gericht in einem Restaurant. Im Stil der kulinarischen Fotografie.',
    'Realistisches Foto, Weitwinkel: Radfahrer fahren durch die Stadt. Im Stil der urbanen Fotografie.',
    'Illustration, Zentral: Mädchen liest unter einem Baum. Im Stil von Beatrix Potter.',
    'Grafik, Nabelperspektive: Musiker spielt Gitarre auf der Bühne. Im Stil von Andy Warhol.',
    'Produktpräsentation, Nahaufnahme: Elegante Uhr auf einer Holzoberfläche. Im Stil der Luxus-Produktfotografie.',
    'Stockfoto, Vogelperspektive: Kinder spielen Fußball auf einem Feld. Im Stil der Sportfotografie.',
    'Realistisches Foto, Froschperspektive: Hund rennt durch ein Feld. Im Stil der Actionfotografie.',
    'Illustration, Seitenansicht: Familie schmückt einen Weihnachtsbaum. Im Stil von Currier und Ives.',
    'Grafik, Nahaufnahme: Retro-Schreibmaschine mit Papier. Im Stil von René Magritte.',
    'Produktpräsentation, Weitwinkel: Modernes Wohnzimmer mit stilvollen Möbeln. Im Stil von Bauhaus.',
    'Stockfoto, Halbnahe: Freunde grillen im Hinterhof. Im Stil der Lifestyle-Fotografie.',
    'Realistisches Foto, Nahaufnahme: Krankenschwester misst den Blutdruck eines Patienten. Im Stil der medizinischen Fotografie.',
    'Illustration, Zentral: Kinder lassen Drachen steigen im Park. Im Stil von Maurice Sendak.',
    'Grafik, Draufsicht: Kaffeetisch mit Büchern und einer Pflanze. Im minimalistischen Design.',
    'Produktpräsentation, Seitenansicht: Neues Tablet auf einem modernen Schreibtisch. Im Stil der Tech-Werbung.',
    'Stockfoto, Nabelperspektive: Menschen genießen einen sonnigen Tag am Strand. Im Stil von Slim Aarons.',
    'Realistisches Foto, Froschperspektive: Kinder spielen in einer Pfütze nach dem Regen. Im Stil der spontanen Fotografie.',
    'Illustration, Weitwinkel: Eltern lesen Kindern vor dem Schlafengehen vor. Im Stil von Quentin Blake.',
    'Grafik, Nahaufnahme: Vintage-Radio auf einem Regal. Im Stil von Edward Hopper.',
    'Produktpräsentation, Weitwinkel: Modernes Badezimmer mit eleganten Armaturen. Im Stil von Bauhaus.',
    'Stockfoto, Nahaufnahme: Frau übt Kalligraphie. Im Stil der künstlerischen Fotografie.',
    'Realistisches Foto, Weitwinkel: Camper bauen Zelte im Wald auf. Im Stil von Ansel Adams.',
    'Illustration, Vogelperspektive: Menschen laufen auf einem gefrorenen Teich Schlittschuh. Im Stil von Currier und Ives.',
    'Grafik, Seitenansicht: Teenager spielt Videospiele. Im Stil der Popkulturkunst.',
    'Produktpräsentation, Nahaufnahme: Stilvolle Turnschuhe auf einem Holzboden. Im Stil der Modefotografie.',
    'Stockfoto, Nabelperspektive: Familie spielt Brettspiele zu Hause. Im Stil der gemütlichen Fotografie.',
    'Realistisches Foto, Froschperspektive: Kind bläst Geburtstagskerzen aus. Im Stil der spontanen Fotografie.',
    'Illustration, Zentral: Paar tanzt unter den Sternen. Im Stil von Marc Chagall.',
    'Grafik, Draufsicht: Schreibtisch mit Kunstmaterialien und Skizzen. Im Stil von Jackson Pollock.',
    'Produktpräsentation, Halbnahe: Designerlampe auf einem Nachttisch. Im modernen Produktfotografiestil.',
    'Stockfoto, Nahaufnahme: Frau kocht in einer modernen Küche. Im Stil der kulinarischen Fotografie.',
    'Realistisches Foto, Weitwinkel: Menschen erkunden einen Bauernmarkt. Im Stil der lebendigen Fotografie.',
    'Illustration, Zentral: Kinder spielen im Herbstlaub. Im Stil von Quentin Blake.',
    'Grafik, Nabelperspektive: Musiker spielt Schlagzeug auf der Bühne. Im Stil von Keith Haring.',
    'Produktpräsentation, Nahaufnahme: Premium-Laptop auf einem eleganten Schreibtisch. Im Stil der Tech-Werbung.',
    'Stockfoto, Vogelperspektive: Freunde machen ein Picknick auf einer Wiese. Im Stil der Lifestyle-Fotografie.',
    'Realistisches Foto, Froschperspektive: Hund springt, um einen Ball zu fangen. Im Stil der Actionfotografie.',
    'Illustration, Seitenansicht: Kind spielt mit einem Spielzeugzug. Im Stil von Richard Scarry.',
    'Grafik, Nahaufnahme: Vintage-Uhr auf einem Holzregal. Im Stil von Salvador Dalí.',
    'Produktpräsentation, Weitwinkel: Modernes Büro mit stilvollen Möbeln. Im Stil von Bauhaus.',
    'Stockfoto, Halbnahe: Freunde stoßen auf einer Dachterrassenparty an. Im Stil der Eventfotografie.',
    'Realistisches Foto, Nahaufnahme: Arzt notiert sich etwas auf einem Klemmbrett. Im Stil der medizinischen Fotografie.',
    'Produktpräsentation, Seitenansicht: Elektroauto an einer Ladestation. Im Stil der nachhaltigen Technologie.',
    'Stockfoto, Vogelperspektive: Beachvolleyballspiel in Aktion. Im Stil der Sportfotografie.',
    'Realistisches Foto, Halbnahe: Paar spaziert Hand in Hand im Park. Im Stil der romantischen Fotografie.',
    'Illustration, Zentral: Astronaut schwebt im Weltraum. Im Stil der Science-Fiction-Kunst.',
    'Grafik, Nahaufnahme: Künstlerpalette mit Farben und Pinseln. Im Stil des kreativen Arbeitsbereichs.',
    'Produktpräsentation, Weitwinkel: High-Tech-Heimbüroeinrichtung. Im Stil der modernen Produktivität.',
    'Stockfoto, Froschperspektive: Skater führt Tricks im Skatepark aus. Im Stil der urbanen Actionfotografie.',
    'Realistisches Foto, Nabelperspektive: Touristen machen Fotos an einem Wahrzeichen. Im Stil der Reisefotografie.',
    'Illustration, Seitenansicht: Kinder bauen eine Sandburg am Strand. Im spielerischen Stil.',
    'Grafik, Draufsicht: Vintage-Küche mit Retro-Geräten. Im nostalgischen Heimkunststil.',
    'Produktpräsentation, Nahaufnahme: Stilvolle Turnschuhe auf einem Holzboden. Im Stil der Modefotografie.',
    'Stockfoto, Zentral: Freunde stoßen bei einer Party mit Getränken an. Im Stil der sozialen Eventfotografie.',
    'Realistisches Foto, Vogelperspektive: Stadtbild bei Nacht mit beleuchteten Gebäuden. Im Stil der urbanen Fotografie.',
    'Illustration, Froschperspektive: Eichhörnchen klettert auf einen Baum. Im Stil der Naturillustration.',
    'Grafik, Seitenansicht: Klassisches Auto fährt auf einer Landstraße. Im Stil der Vintage-Automobilkunst.',
    'Produktpräsentation, Halbnahe: Modernes Zuhause mit smarter Technologie. Im Stil des futuristischen Heimdesigns.',
    'Stockfoto, Nahaufnahme: Barista macht Kaffee in einem Café. Im Stil der Lifestyle-Fotografie.',
    'Realistisches Foto, Weitwinkel: Wanderer überqueren eine Hängebrücke. Im Stil der Abenteuerfotografie.',
    'Illustration, Vogelperspektive: Kinder spielen Himmel und Hölle auf dem Gehweg. Im spielerischen Illustrationsstil.',
    'Grafik, Nabelperspektive: Plattenspieler mit Schallplatten. Im Stil der Retro-Musikkunst.',
    'Produktpräsentation, Seitenansicht: Hochwertige Kamera auf einem Stativ. Im Stil der professionellen Fotografie.',
    'Stockfoto, Halbnahe: Familie geht mit dem Hund im Park spazieren. Im Stil der Familienfotografie.',
    'Realistisches Foto, Zentral: Geschäftsfrau hält eine Präsentation. Im Stil der Unternehmensfotografie.',
    'Illustration, Draufsicht: Picknick mit verschiedenen Speisen. Im gemütlichen Illustrationsstil.',
    'Grafik, Nahaufnahme: Alte Schreibmaschine mit Papier. Im nostalgischen Kunststil.',
    'Produktpräsentation, Weitwinkel: Elektrisches Fahrrad auf einem malerischen Weg. Im Stil der umweltfreundlichen Produktfotografie.',
    'Stockfoto, Froschperspektive: Junge fliegt eine Drohne auf einem Feld. Im Stil der Tech-Lifestyle-Fotografie.',
    'Realistisches Foto, Nabelperspektive: Straßenkünstler malt ein Wandbild. Im urbanen Kunststil.',
    'Illustration, Seitenansicht: Großmutter strickt in einem Schaukelstuhl. Im heimeligen Illustrationsstil.',
    'Grafik, Vogelperspektive: Offener Koffer mit Reiseutensilien. Im Reisekunststil.',
    'Produktpräsentation, Nahaufnahme: Premium-Kopfhörer auf einem Ständer. Im modernen Tech-Werbestil.',
    'Stockfoto, Nahaufnahme: Junge spielt mit Bauklötzen auf dem Boden. Im Stil der Kinderfotografie.',
    'Realistisches Foto, Weitwinkel: Surfer reitet eine große Welle. Im Stil der Actionfotografie.',
    'Illustration, Vogelperspektive: Menschen sitzen in einem Open-Air-Kino. Im Stil von Quentin Blake.',
    'Grafik, Seitenansicht: Frau liest ein Buch in einem gemütlichen Sessel. Im Stil der illustrativen Kunst.',
    'Produktpräsentation, Nahaufnahme: Moderne Wasserflasche auf einem Felsen. Im Stil der Outdoor-Werbung.',
    'Stockfoto, Nabelperspektive: Menschen sitzen in einem Café und unterhalten sich. Im Stil der Lifestyle-Fotografie.',
    'Realistisches Foto, Zentral: Kind streichelt eine Ziege auf einem Bauernhof. Im Stil der ländlichen Fotografie.',
    'Illustration, Froschperspektive: Papagei sitzt auf einem Ast im Dschungel. Im Stil der Tierillustration.',
    'Grafik, Draufsicht: Frühstückstisch mit Pfannkuchen und Obst. Im Stil von Wayne Thiebaud.',
    'Produktpräsentation, Halbnahe: Elegante Kerzenständer auf einem Esstisch. Im Stil der Luxusfotografie.',
    'Stockfoto, Nahaufnahme: Musiker spielt Violine in einem Orchester. Im Stil der klassischen Musikfotografie.',
    'Realistisches Foto, Weitwinkel: Wanderer stehen auf einem Berggipfel und schauen ins Tal. Im Stil der Landschaftsfotografie.',
    'Illustration, Zentral: Kinder basteln mit Papier und Schere. Im Stil der Kinderbuchillustration.',
    'Grafik, Nabelperspektive: Koch bereitet Sushi in einer Küche zu. Im Stil der kulinarischen Kunst.',
    'Produktpräsentation, Nahaufnahme: Modische Armbanduhr auf einem Marmortisch. Im Stil der Tech-Werbung.',
    'Stockfoto, Vogelperspektive: Menschen paddeln in Kanus auf einem ruhigen See. Im Stil der Abenteuerfotografie.',
    'Realistisches Foto, Froschperspektive: Tänzerin übt Ballett in einem Studio. Im Stil der Tanzfotografie.',
    'Illustration, Seitenansicht: Junge spielt auf einer Schaukel im Garten. Im Stil der fröhlichen Illustration.',
    'Grafik, Nahaufnahme: Altes Telefon mit Wählscheibe auf einem Tisch. Im Stil der Vintage-Kunst.',
    'Produktpräsentation, Weitwinkel: Modernes Schlafzimmer mit schicker Einrichtung. Im Stil der Innenarchitekturfotografie.',
    'Stockfoto, Halbnahe: Freunde feiern eine Geburtstagsparty im Freien. Im Stil der Eventfotografie.',
    'Realistisches Foto, Nahaufnahme: Arzt untersucht einen Röntgenbild. Im Stil der medizinischen Fotografie.',
    'Illustration, Zentral: Hund läuft am Strand entlang. Im Stil der spielerischen Illustration.',
    'Grafik, Draufsicht: Schreibtisch mit Computer und Zeichenmaterialien. Im Stil des kreativen Arbeitsbereichs.',
    'Produktpräsentation, Seitenansicht: Neues Smartphone auf einem hölzernen Tisch. Im Stil der Tech-Werbung.',
    'Stockfoto, Nabelperspektive: Menschen picknicken in einem Stadtpark. Im Stil der Lifestyle-Fotografie.',
    'Realistisches Foto, Froschperspektive: Mountainbiker fährt einen steilen Pfad hinunter. Im Stil der Actionfotografie.',
    'Illustration, Weitwinkel: Kinder spielen auf einem Rummelplatz. Im Stil der lebendigen Illustration.',
    'Grafik, Nahaufnahme: Vintage-Kamera auf einem Tisch mit alten Fotos. Im Stil der nostalgischen Kunst.',
    'Produktpräsentation, Weitwinkel: Moderne Küche mit eleganten Geräten. Im Stil der Innendesignfotografie.',
    'Stockfoto, Nabelperspektive: Menschen sitzen in einem Café und unterhalten sich. Im Stil der Lifestyle-Fotografie. ',
    'Realistisches Foto, Zentral: Kind streichelt eine Ziege auf einem Bauernhof. Im Stil der ländlichen Fotografie. ',
    'Illustration, Froschperspektive: Papagei sitzt auf einem Ast im Dschungel. Im Stil der Tierillustration.'
  ],
  [AppLanguage.Spanish]: [
    'Stockfoto, primer plano: Hombre sentado junto a un lago con un perro con correa. Al estilo de Norman Rockwell.	',
    'Foto realista, gran angular: Coche exhibido en una feria. Al estilo de fotografía de automóviles moderna.	',
    'Ilustración, vista de pájaro: Gente esperando en una parada de autobús. Al estilo de Edward Hopper.	',
    'Gráfico, vista lateral: Empleado de oficina estresado con una montaña de papeles. Al estilo de Roy Lichtenstein.	',
    'Presentación de producto, primer plano: Radio vintage sobre una mesa de madera. Al estilo Bauhaus.	',
    'Stockfoto, vista de ombligo: Familia haciendo picnic en el parque. Al estilo de David Hockney.	',
    'Foto realista, vista central: Niños jugando al fútbol en un campo. Al estilo de Henri Cartier-Bresson.	',
    'Ilustración, vista de rana: Gato sentado en el alféizar de una ventana con flores. Al estilo de Henri Matisse.	',
    'Gráfico, vista superior: Mostrador de café con pasteles y tazas de café. Al estilo de Wayne Thiebaud.	',
    'Presentación de producto, plano medio: Bolso de mano elegante en un soporte de exhibición. Al estilo de fotografía de alta moda.	',
    'Stockfoto, primer plano: Chef preparando comida en la cocina de un restaurante. Al estilo de las escenas culinarias de Julia Child.	',
    'Foto realista, gran angular: Público de un concierto con luces de escenario. Al estilo de fotografía de conciertos moderna.	',
    'Ilustración, vista central: Pareja mayor sentada en un banco del parque. Al estilo de Grant Wood.	',
    'Gráfico, vista de ombligo: Jugador de videojuegos con luces de neón. Al estilo de Cyberpunk.	',
    'Presentación de producto, primer plano: Reloj elegante sobre una superficie de mármol. Al estilo de fotografía de productos de lujo.	',
    'Stockfoto, vista de pájaro: Agricultores trabajando en un campo. Al estilo del realismo agrario.	',
    'Foto realista, vista de rana: Senderista en la cima de una montaña. Al estilo de Ansel Adams.	',
    'Ilustración, vista lateral: Niño leyendo un libro bajo un árbol. Al estilo de Beatrix Potter.	',
    'Gráfico, primer plano: Bicicleta vintage apoyada en una pared de ladrillo. Al estilo del arte urbano.	',
    'Presentación de producto, gran angular: Electrodomésticos modernos en un mostrador. Al estilo del diseño contemporáneo.	',
    'Stockfoto, plano medio: Amigos jugando juegos de mesa en una mesa. Al estilo de Norman Rockwell.	',
    'Foto realista, primer plano: Doctor examinando a un paciente. Al estilo de la fotografía médica.	',
    'Ilustración, vista central: Perro jugando con una pelota en el jardín. Al estilo de Charles Schulz.	',
    'Gráfico, vista superior: Escritorio con computadora, café y útiles de escritura. Al estilo minimalista.	',
    'Presentación de producto, vista lateral: Nuevo smartphone en una superficie elegante. Al estilo de la publicidad tecnológica.	',
    'Stockfoto, vista de ombligo: Escena de playa con personas tomando el sol y nadando. Al estilo de Slim Aarons.	',
    'Foto realista, vista de rana: Ciclista en una calle de la ciudad. Al estilo de Henri Cartier-Bresson.	',
    'Ilustración, gran angular: Niños volando cometas en el parque. Al estilo de Mary Blair.	',
    'Gráfico, primer plano: Músico tocando la guitarra en un escenario. Al estilo de Andy Warhol.	',
    'Presentación de producto, plano medio: Coche de lujo estacionado en una calle de la ciudad. Al estilo de fotografía de automóviles de alta gama.	',
    'Stockfoto, vista central: Amigos disfrutando de una parrillada en el patio trasero. Al estilo de la fotografía de estilo de vida.	',
    'Foto realista, vista de pájaro: Mercado callejero concurrido con vendedores y compradores. Al estilo de la fotografía callejera.	',
    'Ilustración, vista de rana: Gato tomando el sol en el alféizar de una ventana. Al estilo de Tasha Tudor.	',
    'Gráfico, vista superior: Escritorio con materiales de arte y bocetos. Al estilo del espacio de trabajo creativo.	',
    'Presentación de producto, primer plano: Gafas de sol de diseñador sobre una toalla de playa. Al estilo de la fotografía de moda.	',
    'Stockfoto, vista lateral: Persona corriendo en un sendero al amanecer. Al estilo de la fotografía de fitness.	',
    'Foto realista, plano medio: Personas bailando en una fiesta de bodas. Al estilo de la fotografía de bodas.	',
    'Ilustración, vista central: Niño pintando con crayones en el suelo. Al estilo de Quentin Blake.	',
    'Gráfico, primer plano: Tablero de instrumentos de coche retro con instrumentos clásicos. Al estilo del arte automotriz vintage.	',
    'Presentación de producto, gran angular: Sofá moderno en una sala de estar. Al estilo de la fotografía de diseño de interiores.	',
    'Stockfoto, vista de pájaro: Personas haciendo kayak en un río. Al estilo de la fotografía de aventura.	',
    'Foto realista, vista de ombligo: Commuters leyendo periódicos en un tren. Al estilo del realismo cotidiano.	',
    'Ilustración, vista lateral: Mujer practicando yoga en un estudio. Al estilo del arte sereno.	',
    'Gráfico, vista superior: Mesa con desayuno y café. Al estilo de Wayne Thiebaud.	',
    'Presentación de producto, primer plano: Smartwatch mostrando estadísticas de fitness. Al estilo de la publicidad tecnológica.	',
    'Stockfoto, vista de rana: Niños montando bicicletas por la calle. Al estilo de la fotografía lúdica.	',
    'Foto realista, plano medio: Clientes explorando en un centro comercial. Al estilo de la fotografía comercial.	',
    'Ilustración, vista central: Gato y perro durmiendo juntos. Al estilo de Beatrix Potter.	',
    'Gráfico, gran angular: Cámara de cine vintage con fotos extendidas. Al estilo del arte nostálgico.	',
    'Presentación de producto, primer plano: Botella de perfume de lujo con flores. Al estilo de la fotografía de productos de alta gama.	',
    'Stockfoto, primer plano: Mujer pintando en un lienzo. Al estilo de la fotografía artística.	',
    'Foto realista, gran angular: Mercado agrícola con frutas y verduras coloridas. Al estilo de la fotografía vibrante.	',
    'Ilustración, vista de pájaro: Personas patinando sobre hielo en un estanque congelado. Al estilo de Currier y Ives.	',
    'Gráfico, vista lateral: Adolescente escuchando música con auriculares. Al estilo del arte pop.	',
    'Presentación de producto, primer plano: Laptop de alta calidad en un escritorio elegante. Al estilo de la publicidad tecnológica.	',
    'Stockfoto, vista de ombligo: Familia decorando un árbol de Navidad. Al estilo de la fotografía festiva.	',
    'Foto realista, primer plano: Reunión de negocios con apretón de manos. Al estilo de la fotografía corporativa.	',
    'Ilustración, vista de rana: Niño soplando burbujas en el jardín. Al estilo de Maurice Sendak.	',
    'Gráfico, vista superior: Colección de juguetes vintage coloridos. Al estilo del arte pop nostálgico.	',
    'Presentación de producto, vista lateral: Nuevo coche eléctrico cargándose. Al estilo de la tecnología sostenible.	',
    'Stockfoto, vista de pájaro: Juego de voleibol de playa en acción. Al estilo de la fotografía deportiva.	',
    'Foto realista, plano medio: Pareja caminando de la mano en el parque. Al estilo de la fotografía romántica.	',
    'Ilustración, vista central: Astronauta flotando en el espacio. Al estilo del arte de ciencia ficción.	',
    'Gráfico, primer plano: Paleta de artista con pinturas y pinceles. Al estilo del espacio de trabajo creativo.	',
    'Presentación de producto, gran angular: Oficina en casa de alta tecnología. Al estilo de la productividad moderna.	',
    'Stockfoto, vista de rana: Patinador haciendo trucos en un parque de patinaje. Al estilo de la fotografía de acción urbana.	',
    'Foto realista, vista de ombligo: Turistas tomando fotos en un punto de referencia. Al estilo de la fotografía de viajes.	',
    'Ilustración, vista lateral: Niños construyendo un castillo de arena en la playa. Al estilo lúdico.	',
    'Gráfico, vista superior: Cocina vintage con electrodomésticos retro. Al estilo del arte hogareño nostálgico.	',
    'Presentación de producto, primer plano: Zapatillas elegantes en un piso de madera. Al estilo de la fotografía de moda.	',
    'Stockfoto, vista central: Amigos brindando en una fiesta. Al estilo de la fotografía de eventos sociales.	',
    'Foto realista, vista de pájaro: Vista de la ciudad de noche con edificios iluminados. Al estilo de la fotografía urbana.	',
    'Ilustración, vista de rana: Ardilla trepando a un árbol. Al estilo de la ilustración de la naturaleza.	',
    'Gráfico, vista lateral: Coche clásico conduciendo por una carretera rural. Al estilo del arte automotriz vintage.	',
    'Presentación de producto, plano medio: Hogar moderno con tecnología inteligente. Al estilo del diseño futurista del hogar.	',
    'Stockfoto, primer plano: Barista preparando café en una cafetería. Al estilo de la fotografía de estilo de vida.	',
    'Foto realista, gran angular: Excursionistas cruzando un puente colgante. Al estilo de la fotografía de aventura.	',
    'Ilustración, vista de pájaro: Niños jugando a la rayuela en la acera. Al estilo de la ilustración lúdica.	',
    'Gráfico, vista de ombligo: Tocadiscos con discos de vinilo. Al estilo del arte retro musical.	',
    'Presentación de producto, vista lateral: Cámara de alta calidad en un trípode. Al estilo de la fotografía profesional.	',
    'Foto realista, vista central: Mujer de negocios dando una presentación. Al estilo de la fotografía corporativa.	',
    'Ilustración, vista superior: Picnic con una variedad de alimentos. Al estilo de la ilustración acogedora.	',
    'Gráfico, primer plano: Máquina de escribir antigua con papel. Al estilo del arte de oficina vintage.	',
    'Presentación de producto, gran angular: Bicicleta eléctrica en un sendero pintoresco. Al estilo de la fotografía ecológica.	',
    'Stockfoto, vista de rana: Niño volando un dron en un campo. Al estilo de la fotografía de estilo de vida tecnológico.	',
    'Foto realista, vista de ombligo: Artista callejero pintando un mural. Al estilo de la fotografía de arte urbano.	',
    'Ilustración, vista lateral: Abuela leyendo a sus nietos. Al estilo de la ilustración conmovedora.	',
    'Gráfico, vista de pájaro: Maleta abierta con elementos esenciales de viaje. Al estilo del arte de viaje.	',
    'Presentación de producto, primer plano: Reloj de diseñador sobre una superficie de mármol. Al estilo de la fotografía de productos de lujo.	',
    'Stockfoto, vista de ombligo: Amigos haciendo senderismo en las montañas. Al estilo de la fotografía de aventura.	',
    'Foto realista, vista de rana: Gato tomando el sol. Al estilo de la fotografía espontánea.	',
    'Ilustración, gran angular: Niños jugando con globos de agua. Al estilo del arte lúdico.	',
    'Gráfico, vista superior: Escritorio con cuaderno de bocetos y suministros de arte. Al estilo del espacio de trabajo creativo.	',
    'Presentación de producto, vista lateral: Altavoz de alta gama en una estantería moderna. Al estilo de la publicidad tecnológica.	',
    'Stockfoto, primer plano: Niño jugando con bloques de construcción en el suelo. Al estilo de la fotografía infantil.	',
    'Foto realista, gran angular: Surfista montando una gran ola. Al estilo de la fotografía de acción.	',
    'Ilustración, vista de pájaro: Personas sentadas en un cine al aire libre. Al estilo de Quentin Blake.	',
    'Gráfico, vista lateral: Mujer leyendo un libro en una silla acogedora. Al estilo del arte ilustrativo.	',
    'Presentación de producto, primer plano: Botella de agua moderna sobre una roca. Al estilo de la publicidad al aire libre.	',
    'Stockfoto, vista de ombligo: Personas sentadas en una cafetería, charlando. Al estilo de la fotografía de estilo de vida.	',
    'Foto realista, vista central: Niño acariciando una cabra en una granja. Al estilo de la fotografía rural.	',
    'Ilustración, vista de rana: Loro sentado en una rama en la selva. Al estilo de la ilustración de animales.	',
    'Gráfico, vista superior: Mesa de desayuno con panqueques y frutas. Al estilo de Wayne Thiebaud.	',
    'Presentación de producto, plano medio: Candelabros elegantes en una mesa de comedor. Al estilo de la fotografía de lujo.	',
    'Stockfoto, primer plano: Músico tocando el violín en una orquesta. Al estilo de la fotografía de música clásica.	',
    'Foto realista, gran angular: Excursionistas en la cima de una montaña mirando al valle. Al estilo de la fotografía de paisajes.	',
    'Ilustración, vista central: Niños haciendo manualidades con papel y tijeras. Al estilo de la ilustración de libros infantiles.	',
    'Gráfico, vista de ombligo: Chef preparando sushi en una cocina. Al estilo del arte culinario.	',
    'Presentación de producto, primer plano: Reloj de pulsera elegante sobre una mesa de mármol. Al estilo de la publicidad tecnológica.	',
    'Stockfoto, vista de pájaro: Personas remando en canoas en un lago tranquilo. Al estilo de la fotografía de aventura.	',
    'Foto realista, vista de rana: Bailarina practicando en un estudio. Al estilo de la fotografía de danza.	',
    'Ilustración, vista lateral: Niño jugando en un columpio en el jardín. Al estilo de la ilustración alegre.	',
    'Gráfico, primer plano: Teléfono antiguo con dial en una mesa. Al estilo del arte vintage.	',
    'Presentación de producto, gran angular: Dormitorio moderno con decoración elegante. Al estilo de la fotografía de diseño de interiores.	',
    'Stockfoto, plano medio: Amigos celebrando una fiesta de cumpleaños al aire libre. Al estilo de la fotografía de eventos.	',
    'Foto realista, primer plano: Doctor examinando una radiografía. Al estilo de la fotografía médica.	',
    'Ilustración, vista central: Perro corriendo por la playa. Al estilo de la ilustración lúdica.	',
    'Gráfico, vista superior: Escritorio con computadora y materiales de arte. Al estilo del espacio de trabajo creativo.	',
    'Presentación de producto, vista lateral: Nuevo smartphone sobre una mesa de madera. Al estilo de la publicidad tecnológica.	',
    'Stockfoto, vista de ombligo: Personas disfrutando de un día soleado en la playa. Al estilo de la fotografía de verano.	',
    'Foto realista, vista de rana: Niños jugando en un charco después de la lluvia. Al estilo de la fotografía espontánea.	',
    'Ilustración, gran angular: Padres leyendo a los niños antes de dormir. Al estilo de la ilustración acogedora.	',
    'Gráfico, primer plano: Radio vintage en un estante. Al estilo del arte nostálgico.	',
    'Presentación de producto, gran angular: Baño moderno con accesorios elegantes. Al estilo de la fotografía de diseño de interiores.	',
    'Stockfoto, primer plano: Mujer practicando caligrafía. Al estilo de la fotografía artística.	',
    'Foto realista, gran angular: Campistas montando tiendas en el bosque. Al estilo de la fotografía de aventura.	',
    'Ilustración, vista de pájaro: Personas patinando sobre hielo en un estanque congelado. Al estilo del arte de escenas invernales.	',
    'Gráfico, vista lateral: Adolescente jugando videojuegos. Al estilo del arte pop.	',
    'Presentación de producto, primer plano: Zapatillas elegantes sobre un piso de madera. Al estilo de la fotografía de moda.	',
    'Stockfoto, vista de ombligo: Familia jugando juegos de mesa en casa. Al estilo de la fotografía acogedora.	',
    'Foto realista, vista de rana: Niño soplando velas de cumpleaños. Al estilo de la fotografía espontánea.	',
    'Ilustración, vista central: Pareja bailando bajo las estrellas. Al estilo de la ilustración romántica.	',
    'Gráfico, vista superior: Escritorio con materiales de arte y bocetos. Al estilo del espacio de trabajo creativo.	',
    'Presentación de producto, plano medio: Lámpara de diseñador en una mesita de noche. Al estilo de la fotografía de productos modernos.	',
    'Stockfoto, primer plano: Mujer cocinando en una cocina moderna. Al estilo de la fotografía culinaria.	',
    'Foto realista, gran angular: Personas explorando un mercado de agricultores. Al estilo de la fotografía vibrante.	',
    'Ilustración, vista central: Niños jugando en las hojas de otoño. Al estilo de la ilustración estacional.	',
    'Gráfico, vista de ombligo: Músico tocando la batería en el escenario. Al estilo del arte de conciertos.	',
    'Presentación de producto, primer plano: Laptop premium en un escritorio elegante. Al estilo de la publicidad tecnológica.	',
    'Stockfoto, vista de pájaro: Amigos haciendo un picnic en un prado. Al estilo de la fotografía de estilo de vida.	',
    'Foto realista, vista de rana: Perro saltando para atrapar una pelota. Al estilo de la fotografía de acción.	',
    'Ilustración, vista lateral: Niño jugando con un tren de juguete. Al estilo de la ilustración lúdica.	',
    'Gráfico, primer plano: Reloj antiguo en una estantería de madera. Al estilo del arte nostálgico.	',
    'Presentación de producto, gran angular: Oficina moderna con muebles elegantes. Al estilo de la fotografía de diseño de interiores.	',
    'Stockfoto, plano medio: Amigos brindando en una fiesta en la azotea. Al estilo de la fotografía de eventos sociales.	',
    'Foto realista, primer plano: Doctor tomando notas en un portapapeles. Al estilo de la fotografía médica.	',
    'Ilustración, vista central: Familia construyendo un muñeco de nieve. Al estilo de la ilustración invernal.	',
    'Gráfico, vista superior: Escritorio con computadora y taza de café. Al estilo del diseño minimalista.	',
    'Presentación de producto, vista lateral: Pluma estilográfica elegante en un escritorio. Al estilo de la fotografía de productos de lujo.	',
    'Stockfoto, vista de ombligo: Personas jugando voleibol en la playa. Al estilo de la fotografía de verano.	',
    'Foto realista, vista de rana: Niños jugando en las hojas en otoño. Al estilo de la fotografía espontánea.	',
    'Ilustración, gran angular: Parejas bailando en un baile. Al estilo de la ilustración romántica.	',
    'Gráfico, primer plano: Cámara retro con fotos antiguas. Al estilo del arte nostálgico.	',
    'Presentación de producto, gran angular: Comedor elegante con muebles modernos. Al estilo de la fotografía de diseño de interiores.	',
    'Stockfoto, primer plano: Hombre pintando en un lienzo en un estudio. Al estilo de la fotografía artística.	',
    'Foto realista, gran angular: Excursionistas cruzando un puente sobre un río. Al estilo de la fotografía de aventura.	',
    'Ilustración, vista de pájaro: Niños jugando a la rayuela en la acera. Al estilo del arte lúdico.	',
    'Gráfico, vista lateral: Mujer meditando junto a un lago. Al estilo del arte sereno.	',
    'Presentación de producto, primer plano: Botella de perfume de lujo con flores. Al estilo de la fotografía de productos de alta gama.	',
    'Stockfoto, vista de ombligo: Amigos disfrutando de una fogata por la noche. Al estilo de la fotografía de estilo de vida.	',
    'Ilustración, vista central: Ardilla trepando a un árbol. Al estilo de la ilustración de la naturaleza.	',
    'Gráfico, vista superior: Mesa con desayuno y café. Al estilo de Wayne Thiebaud.	',
    'Presentación de producto, plano medio: Hogar moderno con tecnología inteligente. Al estilo del diseño futurista.	',
    'Stockfoto, primer plano: Barista preparando café en una cafetería. Al estilo de la fotografía de estilo de vida.	',
    'Foto realista, gran angular: Amigos caminando por la playa al atardecer. Al estilo de la fotografía de viajes.	',
    'Ilustración, vista central: Niños jugando con burbujas en el jardín. Al estilo del arte alegre.	',
    'Gráfico, vista de ombligo: Tablero de instrumentos de coche retro. Al estilo del arte automotriz vintage.	',
    'Presentación de producto, vista lateral: Cámara de alta gama en un trípode. Al estilo de la fotografía profesional.	',
    'Stockfoto, vista de pájaro: Familia jugando fútbol en el jardín. Al estilo de la fotografía de estilo de vida.	',
    'Foto realista, vista de rana: Patinador haciendo trucos en un parque de patinaje. Al estilo de la fotografía de acción.	',
    'Ilustración, vista lateral: Abuela tejiendo en una mecedora. Al estilo de la ilustración acogedora.	',
    'Gráfico, primer plano: Tocadiscos con discos de vinilo. Al estilo del arte retro musical.	',
    'Presentación de producto, gran angular: Cocina moderna con electrodomésticos elegantes. Al estilo de la fotografía de diseño de interiores.	',
    'Stockfoto, plano medio: Amigos riendo en una cafetería. Al estilo de la fotografía social.	',
    'Foto realista, vista central: Mujer de negocios dando una presentación. Al estilo de la fotografía corporativa.	',
    'Ilustración, vista superior: Picnic con una variedad de alimentos. Al estilo de la ilustración acogedora.	',
    'Gráfico, primer plano: Máquina de escribir antigua con papel. Al estilo del arte de oficina vintage.	',
    'Presentación de producto, gran angular: Bicicleta eléctrica en un sendero pintoresco. Al estilo de la fotografía ecológica.	',
    'Stockfoto, vista de rana: Niño volando un dron en un campo. Al estilo de la fotografía de estilo de vida tecnológico.	',
    'Foto realista, vista de ombligo: Artista callejero pintando un mural. Al estilo de la fotografía de arte urbano.	',
    'Ilustración, vista lateral: Abuela leyendo a sus nietos. Al estilo de la ilustración conmovedora.	',
    'Gráfico, vista de pájaro: Maleta abierta con elementos esenciales de viaje. Al estilo del arte de viaje.	',
    'Presentación de producto, primer plano: Reloj de diseñador sobre una superficie de mármol. Al estilo de la fotografía de productos de lujo.	',
    'Stockfoto, vista de ombligo: Amigos haciendo senderismo en las montañas. Al estilo de la fotografía de aventura.	',
    'Foto realista, vista de rana: Gato tomando el sol. Al estilo de la fotografía espontánea.	',
    'Ilustración, gran angular: Niños jugando con globos de agua. Al estilo del arte lúdico.	',
    'Gráfico, vista superior: Escritorio con cuaderno de bocetos y suministros de arte. Al estilo del espacio de trabajo creativo.	',
    'Presentación de producto, vista lateral: Altavoz de alta gama en una estantería moderna. Al estilo de la publicidad tecnológica.	',
    'Stockfoto, primer plano: Niño jugando con bloques de construcción en el suelo. Al estilo de la fotografía infantil.	',
    'Foto realista, gran angular: Surfista montando una gran ola. Al estilo de la fotografía de acción.	',
    'Ilustración, vista de pájaro: Personas sentadas en un cine al aire libre. Al estilo de Quentin Blake.	',
    'Gráfico, vista lateral: Mujer leyendo un libro en una silla acogedora. Al estilo del arte ilustrativo.	',
    'Presentación de producto, primer plano: Botella de agua moderna sobre una roca. Al estilo de la publicidad al aire libre.	',
    'Stockfoto, vista de ombligo: Personas sentadas en una cafetería, charlando. Al estilo de la fotografía de estilo de vida.	',
    'Foto realista, vista central: Niño acariciando una cabra en una granja. Al estilo de la fotografía rural.	',
    'Ilustración, vista de rana: Loro sentado en una rama en la selva. Al estilo de la ilustración de animales.	',
    'Gráfico, vista superior: Mesa de desayuno con panqueques y frutas. Al estilo de Wayne Thiebaud.	',
    'Presentación de producto, plano medio: Candelabros elegantes en una mesa de comedor. Al estilo de la fotografía de lujo.	',
    'Stockfoto, primer plano: Músico tocando el violín en una orquesta. Al estilo de la fotografía de música clásica.	',
    'Foto realista, gran angular: Excursionistas en la cima de una montaña mirando al valle. Al estilo de la fotografía de paisajes.	'
  ]
};
