import logoAnimation from 'assets/loadingAnimation/logo-animation.lottie';
import { HalfMask } from 'components/AppLoader';
import FlexContainer from 'components/FlexContainer';
import FullMask from 'components/FullMask';
import LocalStorageKey from 'config/localStorageKey';
import { getRoutePath } from 'config/routes';
import AiWriterBlogPostBuilder from 'features/aiWriter/AiWriterBlogPostBuilder/AiWriterBlogPostBuilder';
import { useCreateDocument } from 'features/aiWriter/AiWriterBlogPostBuilder/hooks/useCreateDocument';
import { FormValues } from 'features/aiWriter/AiWriterBlogPostBuilder/types';
import { AiWriterSidebar } from 'features/aiWriter/AiWriterSidebar/AiWriterSidebar';
import { setBuilderInitLanguage } from 'features/aiWriter/store/actions/builder/actions';
import { getCurrentModelLanguageAndCountry } from 'features/aiWriter/store/selectors';
import { getStoredUserTonality } from 'features/aiWriter/tonality/getStoredUserTonality';
import { getEmbeddingModelsByLanguageAndAudience } from 'features/embeddingModels/store/selectors';
import getModelInitValue from 'features/embeddingModels/store/utils/getModelInitValue';
import { LottieComponent } from 'features/lottie/LottieComponent';
import { useEnableSidebarOnMount } from 'features/pageSidebar/useSidebarStore';
import { Formik } from 'formik';
import { noop } from 'lodash';
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { httpGetDefaultPersonality } from 'services/backofficeIntegration/http/endpoints/personalities/httpGetDefaultPersonality';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { AiWriterWorkflowsType } from 'types/AiWriterWorkflows';

export const BlogPostFromDocumentMenuRoute = `${getRoutePath('blogPostWorkflow')}#documentMenu`;

export const AiWriterBlogPostBuilderWrapper = () => {
  const dispatch = useAppDispatch();
  const { createDocument } = useCreateDocument();
  const location = useLocation();

  useEnableSidebarOnMount();

  const hashParams = new URLSearchParams(location.hash.length > 0 ? location.hash.slice(1) : '');
  const startedFromDocumentMenu = hashParams.has('documentMenu');
  const models = useAppSelector(getEmbeddingModelsByLanguageAndAudience);
  const defaultLanguage = getModelInitValue(models, LocalStorageKey.AiWriterModel);
  const { language: onboardingLanguage } = useAppSelector(state => state.aiWriter.builder.blogPost);
  const { currentModelLanguage, currentModelCountry } = useAppSelector(
    getCurrentModelLanguageAndCountry
  );
  const currentLanguageModel = models.find(
    model => model.language === currentModelLanguage && model.country === currentModelCountry
  );

  const userTonality = getStoredUserTonality();

  const onboardingModel = onboardingLanguage
    ? models.find(
        model =>
          model.language === onboardingLanguage.language &&
          model.country === onboardingLanguage.country
      )
    : undefined;
  const startedFromOnboarding = onboardingModel !== undefined;

  // Clear onboarding state after mount
  useEffect(() => {
    if (startedFromOnboarding) {
      localStorage.setItem(LocalStorageKey.AiWriterModel, onboardingModel.id);
      dispatch(
        setBuilderInitLanguage({
          builder: AiWriterWorkflowsType.BlogPost,
          language: undefined
        })
      );

      const create = async () => {
        const defaultPersonality = await httpGetDefaultPersonality.callEndpoint({
          language: onboardingModel.language,
          country: onboardingModel.country
        });

        // this is needed when we skip language step in onboarding during which we create a new document
        createDocument(onboardingModel.id, defaultPersonality ?? undefined);
      };

      create();
    }
  }, [
    createDocument,
    dispatch,
    models,
    onboardingLanguage,
    onboardingModel,
    startedFromOnboarding
  ]);

  const initialValues: FormValues = {
    title: '',
    language: currentLanguageModel?.id ?? onboardingModel?.id ?? defaultLanguage?.id ?? '',
    systemTonality: [],
    userTonality: userTonality ?? [],
    personality: undefined,
    information: undefined,
    elements: [],
    goal: '',
    keywords: [],
    outline: '',
    generatedContent: [],
    disableNextStep: false,
    startedFromOnboarding
  };

  // We need the embedding models to be loaded before we can render the builder
  if (models.length === 0) {
    return (
      <Container>
        <FullMask
          $backgroundColor="#fff"
          $zIndex={40000}
          key="mask"
          style={{ opacity: 1 }}
          initial={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <HalfMask>
            <LottieComponent src={logoAnimation} options={{ speed: 1.5 }} />
          </HalfMask>
        </FullMask>
      </Container>
    );
  }

  return (
    <Container direction="row">
      <Wrapper>
        <Content>
          <Formik<FormValues>
            validateOnChange
            validateOnBlur
            onSubmit={noop}
            initialValues={initialValues}
          >
            <AiWriterBlogPostBuilder
              skipLanguageStep={startedFromOnboarding || startedFromDocumentMenu}
            />
          </Formik>
        </Content>
      </Wrapper>
      <AiWriterSidebar isDisabled={true} />
    </Container>
  );
};

const Wrapper = styled.div`
  margin: 160px auto 0 auto;
  flex: 0 0 auto;
`;

const Container = styled(FlexContainer)`
  height: 100%;
`;

const Content = styled.div`
  width: 550px;
`;
