import { Typography } from '@mui/material';
import { AppLanguage } from 'features/language/store/types';
import { BuilderError } from 'features/modular-workflow/builder/getBuilderErrorsFromResponse';
import { DataTypeBoolean } from 'features/modular-workflow/builder/settings/DataTypeBoolean';
import { DataTypeBrandVoice } from 'features/modular-workflow/builder/settings/DataTypeBrandVoice';
import { DataTypeCheckbox } from 'features/modular-workflow/builder/settings/DataTypeCheckbox';
import { DataTypeDestinationFormat } from 'features/modular-workflow/builder/settings/DataTypeDestinationFormat';
import { DataTypeDocument } from 'features/modular-workflow/builder/settings/DataTypeDocument';
import { DataTypeImageCount } from 'features/modular-workflow/builder/settings/DataTypeImageCount';
import { DataTypeImageQuality } from 'features/modular-workflow/builder/settings/DataTypeImageQuality';
import { DataTypeImageRatio } from 'features/modular-workflow/builder/settings/DataTypeImageRatio';
import { DataTypeInformationCollection } from 'features/modular-workflow/builder/settings/DataTypeInformationCollection';
import { DataTypeLanguage } from 'features/modular-workflow/builder/settings/DataTypeLanguage';
import { DataTypeLargeText } from 'features/modular-workflow/builder/settings/DataTypeLargeText';
import { DataTypeLengthOfOutput } from 'features/modular-workflow/builder/settings/DataTypeLengthOfOutput';
import { DataTypeLocalizableText } from 'features/modular-workflow/builder/settings/DataTypeLocalizableText';
import { DataTypeText } from 'features/modular-workflow/builder/settings/DataTypeText';
import { DataTypeTextGenerationModel } from 'features/modular-workflow/builder/settings/DataTypeTextGenerationModel';
import { DataTypeTonality } from 'features/modular-workflow/builder/settings/DataTypeTonality';
import {
  ApiModularWorkflowStepSettings,
  ApiModularWorkflowStepSettingsValue
} from 'features/modular-workflow/builder/types-api';
import {
  BuilderModularWorkflowStep,
  BuilderModularWorkflowStepInput,
  BuilderModularWorkflowStepOutput
} from 'features/modular-workflow/builder/types-builder';
import { useModulareWorkflowCreationStore } from 'features/modular-workflow/builder/useModulareWorkflowCreationStore';
import { createContext } from 'react';

type InputRendererContextType = {
  step: BuilderModularWorkflowStep;
  stepIndex: number;
  input: BuilderModularWorkflowStepInput;
  inputValue: BuilderModularWorkflowStepInput['value'];
  inputOutputs: BuilderModularWorkflowStepOutput[];
  inputSettings: ApiModularWorkflowStepSettings;
  inputErrorList: BuilderError[];
  currentLanguage: AppLanguage;
  onChange: (
    input: BuilderModularWorkflowStepInput,
    value: ApiModularWorkflowStepSettingsValue
  ) => void;
  onOutputChange: (outputIndex: number, newName: string) => void;
  onIsChangeableChange: (input: BuilderModularWorkflowStepInput, isChangeable: boolean) => void;
};

export const InputRendererContext = createContext<InputRendererContextType>(
  {} as unknown as InputRendererContextType
);

type Props = {
  step: BuilderModularWorkflowStep;
  input: BuilderModularWorkflowStepInput;
  localInputValue: InputRendererContextType['inputValue'];
  stepSettings: ApiModularWorkflowStepSettings[];
  errorList: BuilderError[];
  onChange: InputRendererContextType['onChange'];
  onOutputChange: InputRendererContextType['onOutputChange'];
  onIsChangeableChange: InputRendererContextType['onIsChangeableChange'];
};

export const InputRenderer = ({
  input,
  localInputValue,
  stepSettings,
  errorList,
  step,
  onChange,
  onOutputChange,
  onIsChangeableChange
}: Props) => {
  const inputSettings = stepSettings.find(setting => setting.reference === input.reference);
  const inputOutputs = step.outputs.filter(
    output => output.related_input_reference === input.reference
  );
  const stepIndex = useModulareWorkflowCreationStore(state => state.selectedStepIndex);
  const currentLanguage = useModulareWorkflowCreationStore(state => state.currentLanguage);

  if (!inputSettings) {
    // eslint-disable-next-line no-console
    console.error(`Failed to find settings for input ${input.reference}`);
    return null;
  }

  return (
    <InputRendererContext.Provider
      value={{
        step,
        stepIndex,
        input,
        inputValue: localInputValue,
        inputOutputs,
        inputSettings,
        inputErrorList: errorList,
        currentLanguage,
        onChange,
        onOutputChange,
        onIsChangeableChange
      }}
    >
      {(() => {
        switch (inputSettings.data_type.type) {
          case 'text':
            return <DataTypeText />;
          case 'localizable_text':
            return <DataTypeLocalizableText />;
          case 'large_text':
            return <DataTypeLargeText />;
          case 'boolean':
            return <DataTypeBoolean />;
          case 'checkbox':
            return <DataTypeCheckbox />;
          case 'length_of_output':
            return <DataTypeLengthOfOutput />;
          case 'image_count':
            return <DataTypeImageCount />;
          case 'image_ratio':
            return <DataTypeImageRatio />;
          case 'image_quality':
            return <DataTypeImageQuality />;
          case 'brand_voice':
            return <DataTypeBrandVoice />;
          case 'text_generation_model':
            return <DataTypeTextGenerationModel />;
          case 'destination_format':
            return <DataTypeDestinationFormat />;
          case 'tonality':
            return <DataTypeTonality />;
          case 'language':
            return <DataTypeLanguage />;
          case 'document':
            return <DataTypeDocument />;
          case 'information_collection':
            return <DataTypeInformationCollection />;
        }

        return (
          <Typography color="red" fontWeight="bold">
            {`Unsupported data type: ${input.reference}`}
          </Typography>
        );
      })()}
    </InputRendererContext.Provider>
  );
};
