import { ReactComponent as InfoIcon } from 'assets/icon-info.svg';
import { IconButton } from 'components/iconButtons/IconButton';
import { useModal } from 'components/modals';
import { OutputTypeOption } from 'features/textGenerator/OutputTypeAutocomplete/OutputTypeAutocomplete';
import React, { useCallback } from 'react';
import GTMIds from 'services/tracking/GTMIds';
import styled from 'styled-components';

type Props = {
  option: OutputTypeOption;
  props: React.HTMLAttributes<HTMLLIElement>;
};

export const OutputTypeOptionComponent = ({ option, props }: Props) => {
  const { showModal } = useModal();

  const handleClick = useCallback(
    () =>
      showModal('YOUTUBE_VIDEO', {
        size: 1100,
        videoId: option.youTubeVideoId
      }),
    [option.youTubeVideoId, showModal]
  );

  return (
    <Option {...props} id={GTMIds.tester.outputType}>
      <div>{option.label}</div>

      {option.youTubeVideoId && (
        <IconButton size="small" icon={<InfoIcon />} onClick={handleClick} />
      )}
    </Option>
  );
};

const Option = styled.li`
  && {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;
