import { CloseModal } from 'components/modals/types';
import ModelSelectStep from 'features/explorer/modals/steps/ModelSelectStep';
import { useAppSelector } from 'store/hooks';
import { object, string } from 'yup';

import StepperModal, { ModalStep } from '../../../components/modals/StepperModal';
import { getCurrentTabModelData, getExplorerTabMapperId } from '../store/selectors';
import { ModelValues } from '../store/types';

const modelStep: ModalStep[] = [
  {
    title: 'exploring.setup.models.title',
    description: 'exploring.setup.models.description'
  }
];

const validationSchema = object().shape({
  model: object().shape({
    modelId: string().required(),
    modelCountry: string().required(),
    modelMapper: string()
  })
});

type Props = {
  onSubmit: (values: ModelValues) => void;
} & CloseModal;

const ModelModal = ({ closeModal, onSubmit }: Props) => {
  const model = useAppSelector(getCurrentTabModelData);
  const mapper = useAppSelector(getExplorerTabMapperId);

  const initialValues: ModelValues = {
    model: {
      modelId: model ? model.id : '',
      modelMapper: mapper || '',
      modelCountry: model ? model.country : ''
    }
  };

  return (
    <StepperModal
      steps={modelStep}
      stepComponent={() => <ModelSelectStep />}
      initialValues={initialValues}
      onCancel={() => {
        closeModal();
      }}
      onSubmit={values => {
        onSubmit(values);
        closeModal();
      }}
      validationSchema={validationSchema}
    />
  );
};

export default ModelModal;
