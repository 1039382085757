import { Button } from '@mui/material';
import { ReactComponent as TutorialIcon } from 'assets/icons/icon-watch-tutorial.svg';
import { useModal } from 'components/modals';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

type Props = {
  videoId: string;
  gtmId?: string;
};

export const TutorialButton = ({ videoId, gtmId }: Props) => {
  const { showModal } = useModal();
  const translate = useTr();

  const handleClick = useCallback(
    () =>
      showModal('YOUTUBE_VIDEO', {
        size: 1100,
        videoId: translate(videoId)
      }),
    [showModal, translate, videoId]
  );

  return (
    <Root
      {...withGtmInteraction(gtmId)}
      variant="outlined"
      startIcon={<StyledIcon />}
      onClick={handleClick}
    >
      <FormattedMessage id="common.tutorial_button" />
    </Root>
  );
};

const Root = styled(Button)`
  &&& {
    position: absolute;
    top: ${({ theme }) => theme.spacings.small};
    right: ${({ theme }) => theme.spacings.medium};
    width: 158px;
  }
`;

const StyledIcon = styled(TutorialIcon)`
  path {
    fill: ${({ theme }) => theme.colors.primaryColorMain};
    fill-opacity: 1;
  }
`;
