import { Typography } from '@mui/material';
import neuroflashLogo from 'assets/neuroflashLogo.svg';
import { IntlMessageKeys } from 'features/i18n/IntlMessageKeys';
import { CSSProperties } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { mobileWindowWidthCSS } from 'utils/hooks/useMobileVersion';
import useTr from 'utils/hooks/useTr';

type Props = {
  sloganKey?: IntlMessageKeys;
  align?: CSSProperties['alignItems'];
  className?: string;
};

export function LogoWithSlogan({ sloganKey, align, className }: Props) {
  const translate = useTr();
  return (
    <Root className={className} align={align}>
      <Logo src={neuroflashLogo} alt={translate('common.neuroflash')} $isSlogan={!!sloganKey} />

      {sloganKey && (
        <Slogan variant="h5">
          <FormattedMessage id={sloganKey} />
        </Slogan>
      )}
    </Root>
  );
}

const Root = styled.div<{ align?: Props['align'] }>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.three};
  align-items: ${({ align }) => align};

  @media (max-width: ${mobileWindowWidthCSS}) {
    align-items: center;
  }
`;

const Logo = styled.img<{ $isSlogan?: boolean }>`
  width: 460px;

  margin-bottom: ${({ theme, $isSlogan }) => ($isSlogan ? '0' : theme.spacings.three)};
`;

const Slogan = styled(Typography)`
  color: ${({ theme }) => theme.colors.primaryColorMain};

  @media (max-width: ${mobileWindowWidthCSS}) {
    display: none;
  }
`;
