import HelpIcon from '@mui/icons-material/Help';
import { CircularProgress, IconButton, Link, Paper, Tooltip, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import FlexContainer from 'components/FlexContainer';
import { useModal } from 'components/modals';
import LocalStorageKey from 'config/localStorageKey';
import { predefineMessageDraft } from 'features/aiWriter/AiWriterSidebar/steps/chat/chatStore';
import { allowedPricingPlans } from 'features/aiWriter/chat/GptSelect';
import { OverviewMessageInput } from 'features/aiWriter/chat/OverviewMessageInput';
import { configureChatTabThunk } from 'features/aiWriter/store/actions/tabs/thunks/configureChatTabThunk';
import { initializeTabThunk } from 'features/aiWriter/store/actions/tabs/thunks/initializeTabThunk';
import { fallbackModelCountry } from 'features/aiWriter/store/utils/defaults/fallbackModelCountry';
import { fallbackModelLanguage } from 'features/aiWriter/store/utils/defaults/fallbackModelLanguage';
import { getPreferredAudience } from 'features/aiWriter/utils/getPreferredAudience';
import { unnamed } from 'features/aiWriter/utils/unnamed';
import useGetModelAudiences from 'features/audiences/hooks/useGetModelAudiences';
import { getGetAudienceByLanguageAndCountry } from 'features/audiences/store/selectors';
import getAudienceInitValue from 'features/audiences/utils/getAudienceInitValue';
import { usePreferredLocaleQuery } from 'features/customerPreferences/usePreferredLocaleQuery';
import { ModelAutocomplete } from 'features/embeddingModels/ModelAutocomplete';
import { getEmbeddingModelsByLanguageAndAudience } from 'features/embeddingModels/store/selectors';
import { EmbeddingModel } from 'features/embeddingModels/store/types';
import { getPricingPlan } from 'features/pricing/store/selectors';
import { useGetDefaultOutputTypeByLanguage } from 'features/textGenerator/utils/useGetPromptsOthers';
import { Form, Formik, useField, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { PersonalityDto } from 'services/backofficeIntegration/http/dtos/PersonalityDto';
import {
  GPT_MODELS,
  GptModel
} from 'services/backofficeIntegration/http/endpoints/aiWriter/httpCreateConversation';
import { InformationDto } from 'services/backofficeIntegration/http/endpoints/infomration/httpGetInformationList';
import gtmIds from 'services/tracking/GTMIds';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';
import { assertNonNullable } from 'utils/typescript/nonNullable';

type ModelAndAudience = {
  modelId: string;
  audienceId: number;
};

function useModelAndAudience() {
  const [modelAndAudience, setModelAndAudience] = useState<ModelAndAudience>();
  const preferencesResult = usePreferredLocaleQuery();
  const preferredLocale = preferencesResult.data;
  const language = preferredLocale?.language ?? fallbackModelLanguage;
  const country = preferredLocale?.country ?? fallbackModelCountry;
  const models = useAppSelector(getEmbeddingModelsByLanguageAndAudience);
  const initialModelId = models.find(
    model => model.language === language && model.country === country
  )?.id;
  const getAudienceByLanguageAndCountry = useAppSelector(getGetAudienceByLanguageAndCountry);
  const audiences = getAudienceByLanguageAndCountry(language, country);
  const initialAudienceId = getPreferredAudience({ audiences, locale: { language, country } })?.id;

  const canSetInitialData = !modelAndAudience && preferencesResult.isFetched;
  useEffect(() => {
    if (canSetInitialData) {
      setModelAndAudience({
        modelId: initialModelId ?? '',
        audienceId: initialAudienceId ?? 0
      });
    }
  }, [canSetInitialData, initialAudienceId, initialModelId]);

  return {
    modelAndAudience,
    setModelAndAudience,
    isModelAndAudienceLoading: preferencesResult.isLoading
  };
}

interface Props {
  videoId: string;
  helpCenterId: string;
  textId: string;
}

const HelpCenter = ({ textId, videoId, helpCenterId }: Props) => {
  const { showModal } = useModal();
  const translate = useTr();

  function handleVideoClick() {
    showModal('YOUTUBE_VIDEO', {
      size: 1100,
      videoId: translate(videoId)
    });
  }

  return (
    <Tooltip
      title={
        <FormattedMessage
          id={textId}
          values={{
            br: <br />,
            video: (content: string) => (
              <StyledTooltipLink component="button" onClick={handleVideoClick}>
                {content}
              </StyledTooltipLink>
            ),
            help: (content: string) => (
              <StyledTooltipLink href={translate(helpCenterId)} rel="noreferrer" target="_blank">
                {content}
              </StyledTooltipLink>
            )
          }}
        />
      }
      placement="top"
    >
      <IconButton>
        <HelpIcon />
      </IconButton>
    </Tooltip>
  );
};

export type MessageConfig = {
  text: string;
  personality?: PersonalityDto | null;
  gptModel: GptModel;
  informationList?: InformationDto[];
};

export type Language = {
  language?: string;
  country?: string;
};

const LanguageField = (props: {
  name: string;
  models: EmbeddingModel[];
  onSubmit: (values: Language) => void;
}) => {
  const { name, models, onSubmit } = props;
  const [field] = useField(name);
  const { setFieldValue } = useFormikContext<Language>();

  const handleLanguage = (model: string) => {
    setFieldValue('language', model);
    onSubmit({ language: model });
  };

  const textFieldStyles = { '.MuiOutlinedInput-notchedOutline': { border: 'none !important' } };
  const autocompleteStyles = { '.MuiAutocomplete-endAdornment': { display: 'none' } };

  return (
    <ModelAutocomplete
      models={models}
      {...field}
      onSelect={handleLanguage}
      size="small"
      isSaveAsDefaultShown={true}
      customTextFieldStyles={textFieldStyles}
      customAutocompleteStyles={autocompleteStyles}
    />
  );
};

export function CreateChatForm() {
  const { modelAndAudience, setModelAndAudience, isModelAndAudienceLoading } =
    useModelAndAudience();
  const models = useAppSelector(getEmbeddingModelsByLanguageAndAudience);

  const initialValues: Language = {
    language: modelAndAudience?.modelId,
    country: fallbackModelCountry
  };

  const storedGptModel =
    (localStorage.getItem(LocalStorageKey.DefaultGptModel) as GptModel) ?? GPT_MODELS.GPT_3_5;

  const pricingPlan = useAppSelector(getPricingPlan);
  const isAllowedToSelectGpt4 = allowedPricingPlans.includes(pricingPlan);

  const initGptModel = isAllowedToSelectGpt4 ? storedGptModel : GPT_MODELS.GPT_3_5;

  const initialMessageConfig: MessageConfig = {
    text: '',
    gptModel: initGptModel
  };

  const [messageConfig, setMessageConfig] = useState<MessageConfig>(initialMessageConfig);

  const getDefaultOutputType = useGetDefaultOutputTypeByLanguage();
  const dispatch = useAppDispatch();

  const { mutate: initializeTab, isLoading: isSending } = useMutation({
    mutationFn: async (embeddingModelId: string) => {
      const audience = getModelAudiences(embeddingModelId)[0];
      assertNonNullable(audience.language, 'Audience language not found');

      await dispatch(
        initializeTabThunk(
          {
            embeddingModelId: embeddingModelId,
            audienceId: audience.id,
            name: unnamed,
            isNewDocument: true,
            outputType: getDefaultOutputType(audience.language),
            brief: '',
            keywords: '',
            keywords2: '',
            tonality: [],
            personalityId: messageConfig.personality?.id
          },
          configureChatTabThunk
        )
      );
    }
  });

  async function onSubmit(text: string) {
    const { gptModel, personality, informationList } = messageConfig;
    if (!modelAndAudience) {
      return;
    }

    localStorage.setItem(LocalStorageKey.DefaultGptModel, gptModel);
    predefineMessageDraft({
      text,
      gptModel,
      personalityId: personality?.id ?? null,
      informationList: informationList
    });

    const embeddingModelId = modelAndAudience?.modelId;
    initializeTab(embeddingModelId);
  }

  const getModelAudiences = useGetModelAudiences();

  const onModelSubmit = (values: Language) => {
    const embeddingModelId = models.find(model => model.id === values.language)?.id;

    assertNonNullable(embeddingModelId, 'Model not found');

    const audiences = getModelAudiences(embeddingModelId);
    const audienceId = getAudienceInitValue(audiences, LocalStorageKey.AiWriterProject) ?? 0;

    setModelAndAudience({ modelId: embeddingModelId, audienceId });
  };

  if (!modelAndAudience) {
    return null;
  }

  return (
    <Root>
      <Content>
        <Header>
          <FlexContainer direction="row" gap="small" alignItems="center">
            <Typography variant="body1">
              <FormattedMessage id="aiWriter.project_overview.chat.title" />
            </Typography>
            <HelpCenter
              textId="aiWriter.project_overview.chat.tooltip"
              videoId="aiWriter.project_overview.chat.video_link_id"
              helpCenterId="aiWriter.project_overview.chat.helpcenter_link_id"
            />
          </FlexContainer>
          {isModelAndAudienceLoading ? (
            <CircularProgress size={20} />
          ) : (
            <Formik initialValues={initialValues} onSubmit={onModelSubmit}>
              <Form>
                <ModelInputContainer>
                  <LanguageField models={models} name="language" onSubmit={onModelSubmit} />
                </ModelInputContainer>
              </Form>
            </Formik>
          )}
        </Header>
        <Paper elevation={0}>
          <OverviewMessageInput
            modelId={modelAndAudience.modelId}
            sendButtonConfig={{
              gtmId: gtmIds.aiWriter.projectOverview.chat.sendMessage,
              disabled: !modelAndAudience
            }}
            isSending={isSending}
            value={messageConfig}
            onChange={message => setMessageConfig(message)}
            onSend={onSubmit}
          />
        </Paper>
      </Content>
    </Root>
  );
}

const Root = styled.div`
  width: 100%;

  padding: ${({ theme }) => theme.spacings.four};
  border-radius: ${({ theme }) => theme.borderRadius.one};
  background-color: ${({ theme }) => theme.colors.commonBrandColorsBrandBeige};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.medium};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xxxlarge};
`;

const ModelInputContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  width: 15rem;
`;

export const StyledTooltipLink = styled(Link)`
  color: ${({ theme }) => theme.colors.primaryColorLight};
  &:hover {
    color: ${({ theme }) => theme.colors.primaryColorLight};
  }
` as typeof Link;
