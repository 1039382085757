import HelpIcon from '@mui/icons-material/Help';
import { Tooltip } from '@mui/material';
import { FormattedMessage } from 'react-intl';

export function HelperTooltipIcon(props: { tooltipId: string }) {
  const { tooltipId } = props;

  return (
    <Tooltip placement="top" title={<FormattedMessage id={tooltipId} />}>
      <HelpIcon color="action" />
    </Tooltip>
  );
}
