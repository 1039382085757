import Toast from 'components/toasts/Toast';
import {
  updateSeoAnalysis,
  updateSeoConfig
} from 'features/aiWriter/store/actions/seoAnalysis/actions';
import {
  AnalyseTextPayload,
  defaultSeoTextLength
} from 'features/aiWriter/store/actions/seoAnalysis/types';
import { SeoTermSuggestions, TermSuggestion } from 'features/aiWriter/store/types';
import { updateSeoLimitations } from 'features/pricing/store/actions/limitations/actions';
import SeoAnalysisAPI from 'services/api/seoAnalysis';
import { invalidateSeoAnalysisUsageQueries } from 'services/api/seoAnalysis/invalidateSeoAnalysisUsageQueries';
import { ApiSuggestedAmount, SeoAnalysisRequest } from 'services/api/seoAnalysis/types';
import { handleApiCommonErrors } from 'services/api/utils/handleApiCommonErrors';
import { invalidateCustomerAllLimitationsQueries } from 'services/backofficeIntegration/http/endpoints/customer/httpGetAllLimitations';
import { AppThunk } from 'store/store';

export const analyseTextThunk =
  ({
    wordsAmount,
    keyword,
    searchEngine,
    text,
    countWordOccurrence
  }: AnalyseTextPayload): AppThunk<void> =>
  async dispatch => {
    const commonPayload: SeoAnalysisRequest = {
      keyword,
      searchengine: searchEngine,
      ai_writer_id: 'ai_writer_id'
    };

    const analysePayload: SeoAnalysisRequest = text
      ? {
          ...commonPayload,
          text
        }
      : { ...commonPayload, textlength: defaultSeoTextLength };

    try {
      const response = await SeoAnalysisAPI.analyse(analysePayload);

      invalidateSeoAnalysisUsageQueries();
      invalidateCustomerAllLimitationsQueries();

      if (response.status) {
        const mkTermSuggestions = (
          words: string[],
          suggestedAmount: Record<string, ApiSuggestedAmount>
        ): TermSuggestion[] =>
          words.map(word => ({
            word,
            maxUse: suggestedAmount[word].max_keyword_use,
            minUse: suggestedAmount[word].min_keyword_use,
            occurrence: countWordOccurrence(word)
          }));

        const { termsuggestions } = response.data;

        const termSuggestions: SeoTermSuggestions = {
          less: mkTermSuggestions(termsuggestions.less, termsuggestions.suggestedamount),
          more: mkTermSuggestions(termsuggestions.more, termsuggestions.suggestedamount),
          ok: mkTermSuggestions(termsuggestions.ok, termsuggestions.suggestedamount)
        };

        dispatch(updateSeoAnalysis({ ...response.data, wordsAmount, termSuggestions }));
        dispatch(updateSeoConfig({ keyword, searchEngine }));
        dispatch(updateSeoLimitations(response.data.limitations));
      } else {
        const errorId = handleApiCommonErrors(response.data.message);

        Toast.backendError(errorId);
      }
    } catch (e) {
      Toast.apiError();
    }
  };
