import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Button, CircularProgress } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import AlertError from 'components/forms/AlertError';
import { getRoutePath } from 'config/routes';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { FieldRenderer } from 'features/modular-workflow/runner/fields/FieldRenderer';
import { RunnerError } from 'features/modular-workflow/runner/getRunnerErrorsFromResponse';
import { isStepInProgress } from 'features/modular-workflow/runner/isStepInProgress';
import { InputRenderer } from 'features/modular-workflow/runner/settings/InputRenderer';
import { StepInProgressState } from 'features/modular-workflow/runner/StepInProgressState';
import {
  RunnerModularWorkflowStep,
  RunnerModularWorkflowStepInput
} from 'features/modular-workflow/runner/types-runner';
import {
  useModulareWorkflowRunnerStore,
  useRunningWorkflowSelectedStep
} from 'features/modular-workflow/runner/useModulareWorkflowRunnerStore';
import { useGetModulareWorkflowStepStructureByReference } from 'features/modular-workflow/useModulareWorkflowStructureStore';
import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { assertNonNullable } from 'utils/typescript/nonNullable';

type Props = {
  step: RunnerModularWorkflowStep;
  stepErrorList: RunnerError[];
  onNextStep: (updatedStep: RunnerModularWorkflowStep) => Promise<void>;
};

export const StepContainer = ({ step, stepErrorList, onNextStep }: Props) => {
  // We create a local state with current values to enable "save changes only on confirm click"
  const [inputValues, setInputValues] = useState<
    Record<RunnerModularWorkflowStepInput['reference'], RunnerModularWorkflowStepInput['value']>
  >({});
  const getStepStructure = useGetModulareWorkflowStepStructureByReference();
  const runningWorkflowStep = useRunningWorkflowSelectedStep();

  const workflow = useModulareWorkflowRunnerStore(state => state.workflow);
  const resetWorkflow = useModulareWorkflowRunnerStore(state => state.reset);

  const [isProcessingNextStep, setIsProcessingNextStep] = useState(false);

  const isCurrentStepInProgress = isStepInProgress(runningWorkflowStep);
  const hasCurrentStepFailed = runningWorkflowStep?.status === 'failed';

  const hasEditableFields = useMemo(
    () => step?.inputs.filter(input => input.is_editable) ?? false,
    [step]
  );

  assertNonNullable(workflow, 'workflow must be loaded first');

  // Prefill local state object with current values
  useEffect(() => {
    const valueStateObject: typeof inputValues = {};
    step.inputs.forEach(input => {
      valueStateObject[input.reference] = input.value;
    });

    setInputValues(valueStateObject);

    // On unmount, reset the input values
    return () => setInputValues({});
  }, [step, step.inputs]);

  const handleInputChange = (
    input: RunnerModularWorkflowStepInput,
    value: RunnerModularWorkflowStep['inputs'][number]['value']
  ) => {
    if (input.value === value) {
      return;
    }

    setInputValues({
      ...inputValues,
      [input.reference]: value
    });
  };

  const handleNextClick = async () => {
    // Copy all gathered input changes to the current step
    const updatedStep = { ...step };
    updatedStep.inputs.forEach(input => {
      if (!(input.reference in inputValues)) {
        // eslint-disable-next-line no-console
        console.error(`Failed to find matching input for ${input.reference}. Values:`, inputValues);
        return;
      }

      input.value = inputValues[input.reference];
    });

    setIsProcessingNextStep(true);
    await onNextStep(updatedStep);
    setIsProcessingNextStep(false);
  };

  const stepStructure = getStepStructure(step.reference);
  if (!stepStructure) {
    return null;
  }

  if (isCurrentStepInProgress) {
    return (
      <Root>
        <StepInProgressState />
      </Root>
    );
  }

  return (
    <Root>
      {step.inputs
        .filter(input => input.is_editable)
        .map((input, index) => {
          if (input.is_field) {
            return (
              <FieldRenderer
                key={`${input.reference}-${step.order}`}
                input={input}
                step={step}
                errorList={stepErrorList.filter(error => error.inputIndex === index)}
                onChange={handleInputChange}
              />
            );
          }

          return (
            <InputRenderer
              key={`${input.reference}-${step.order}`}
              input={input}
              step={step}
              stepSettings={stepStructure.settings}
              errorList={stepErrorList.filter(error => error.inputIndex === index)}
              onChange={handleInputChange}
            />
          );
        })}

      {hasCurrentStepFailed && (
        <AlertError>
          <FormattedMessage
            id="modular_workflow.runner.error_general"
            values={{
              restart: (chunks: string) => (
                <a
                  onClick={() => {
                    resetWorkflow();
                    location.replace(
                      getRoutePath('modularWorkflowRun', { workflowId: workflow.id })
                    );
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  {chunks}
                </a>
              )
            }}
          />
        </AlertError>
      )}

      {hasEditableFields && (
        <FooterControls direction="row">
          <div />

          <Button
            variant="contained"
            color="primary"
            startIcon={
              !isProcessingNextStep ? <ChevronRightIcon /> : <CircularProgress size={24} />
            }
            onClick={handleNextClick}
            disabled={isProcessingNextStep || hasCurrentStepFailed}
          >
            <FormattedMessage id="modular_workflow.runner.steps.next" />
          </Button>
        </FooterControls>
      )}
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacings.medium};
  padding: ${({ theme }) => theme.spacings.small};
`;

const FooterControls = styled(FlexContainer)`
  width: 100%;

  & > * {
    flex: 1;
  }
`;
