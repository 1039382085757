import { isEditorFocused, isHotkey, PlateContent, select } from '@udecode/plate-common';
import Toast from 'components/toasts/Toast';
import useEditor from 'features/aiWriter/AiWriterTextEditor/hooks/useEditor';
import { useEditorSettingsStore } from 'features/aiWriter/AiWriterTextEditor/store/useEditorSettingsStore';
import { ACTION_HOTKEYS } from 'features/aiWriter/AiWriterTextEditor/utils/consts';
import { manipulateClipboardData } from 'features/aiWriter/AiWriterTextEditor/utils/manipulateClipboardData';
import { plateImageCaptionWorkaround } from 'features/aiWriter/plateImageCaptionWorkaround';
import { setSelection } from 'features/aiWriter/store/actions/editor/actions';
import { getGeneratingTextInEditor } from 'features/aiWriter/store/selectors';
import { CursorOverlay } from 'features/plate/components/plate-ui/cursor-overlay';
import { usePostHog } from 'posthog-js/react';
import { memo, ReactElement, SyntheticEvent, useCallback, useEffect, useRef } from 'react';
import { GAEvents } from 'services/tracking/GAEvents';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import styled from 'styled-components';

export const editorWidth = '780px';

export const AiWriterTextEditor = memo((): ReactElement => {
  const containerRef = useRef(null);
  const trackedEditorClickOnce = useRef(false);

  const editor = useEditor();
  const dispatch = useAppDispatch();
  const focusedNode = useAppSelector(state => state.aiWriter.selection);
  const activeTextGeneration = useAppSelector(getGeneratingTextInEditor);
  const postHog = usePostHog();

  const editorBackgroundColor = useEditorSettingsStore(state => state.backgroundColor);
  const hideEditorBorder = useEditorSettingsStore(state => state.hideBorder);

  // We need to clear this up so it does not happen next time we open the editor.
  useEffect(() => {
    if (focusedNode && editor) {
      select(editor, focusedNode);
      dispatch(setSelection(undefined));
    }
    // We only want to trigger this if the tabId changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, editor, focusedNode]);

  // Identifies whether the user has used the hotkey without focusing the editor
  useEffect(() => {
    const keyDownHandler = (event: globalThis.KeyboardEvent) => {
      const hotKeyUsed = Object.values(ACTION_HOTKEYS).some(hotkey => isHotkey(hotkey, event));

      if (editor && hotKeyUsed && !isEditorFocused(editor)) {
        Toast.info('aiWriter.editor.actions.toast_focus_editor');
      }
    };

    const copyHandler = (event: globalThis.ClipboardEvent) => {
      if (manipulateClipboardData(event.clipboardData)) {
        event.preventDefault();
      }
    };

    document.addEventListener('keydown', keyDownHandler);
    document.addEventListener('copy', copyHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
      document.removeEventListener('copy', copyHandler);
    };
  }, [editor]);

  /**
   * Preventing default behavior of mouse down event kills all events related
   * to selection. That way we can block user from changing the caret position
   * when we are generating text in the editor.
   */
  const preventSelectionChange = useCallback((event: SyntheticEvent) => {
    event.preventDefault();
  }, []);

  const handleEditorClick = useCallback(() => {
    if (trackedEditorClickOnce.current) {
      return;
    }

    GAEvents.editorClickedOnce();
    postHog?.capture('editor_clicked_once', { tabId: editor?.id });
    trackedEditorClickOnce.current = true;
  }, [editor?.id, postHog]);

  return (
    <Container
      onMouseDown={activeTextGeneration ? preventSelectionChange : undefined}
      $hideBorder={hideEditorBorder}
      $backgroundColor={editorBackgroundColor}
    >
      <div
        ref={containerRef}
        style={{
          // Required for the optionally rendered absolute positioned cursor in the CursorOverlay below
          // They are used after selecting something and dragging it around
          position: 'relative',
          height: '100%'
        }}
      >
        <Editor>
          <PlateContent
            autoFocus={true}
            readOnly={activeTextGeneration}
            onClick={trackedEditorClickOnce.current ? undefined : handleEditorClick}
          />
        </Editor>

        <CursorOverlay containerRef={containerRef} />
      </div>
    </Container>
  );
});

const Container = styled.div<{ $hideBorder?: boolean; $backgroundColor: string }>`
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  padding-block: ${({ theme }) => theme.spacings.large};
  padding-inline: ${({ theme }) => theme.spacings.medium};
  // More left padding to keep space for the drag handle
  padding: ${({ theme }) => `${theme.spacings.five} ${theme.spacings.four}`};
  margin: ${({ theme }) => `0 auto ${theme.spacings.xxxlarge} auto`};
  border: ${({ $hideBorder }) => ($hideBorder ? 0 : 0.5)}px solid
    ${({ theme }) => theme.colors.aiWriterSidebarBorderColor};
  border-radius: ${({ theme }) => theme.borderRadius.medium};

  background-color: ${({ $backgroundColor }) => $backgroundColor};

  max-width: ${editorWidth};
  min-width: 400px;

  width: 100%;
`;

const Editor = styled.div`
  ${plateImageCaptionWorkaround};
  margin: 0 auto;
  height: 100%;

  .slate-SelectionArea {
    /* This is required to make sure plate selection area don't overlay the editor textarea */
    padding: 5px;
    height: 100%;
  }

  [role='textbox'] {
    height: 100%;
  }
`;
