import { captureException } from '@sentry/react';
import { AxiosError, AxiosResponse } from 'axios';
import { transactionIdHeaderName } from 'features/sentry/transactionIdHeaderName';
import { isObject } from 'utils/isObject';

function responseStatusValue(response: AxiosResponse | undefined) {
  const status = response?.status;
  return status ? String(status) : 'no-response';
}

/**
 * Tries to get the x-trans-id from the axios request configuration,
 * which is added to the error thrown by axios
 */
export function tryGetTransactionIdFromAxiosError(error: AxiosError) {
  if (isObject(error.config?.headers)) {
    const transactionIdHeader = error.config?.headers[transactionIdHeaderName];
    if (typeof transactionIdHeader === 'string' && transactionIdHeader.length > 0) {
      return transactionIdHeader;
    }
  }

  return undefined;
}

export function captureAxiosError(error: AxiosError) {
  const url = error.config?.url;
  const method = error.config?.method;
  const responseStatus = responseStatusValue(error.response);

  captureException(error, {
    tags: {
      'nf.error.scope': 'http',
      'nf.error.http.url': url,
      'nf.error.http.method': method,
      'nf.error.http.status': responseStatus,
      'nf.error.transaction_id': tryGetTransactionIdFromAxiosError(error)
    },
    /**
     * All axios errors are put into one bag by default. It's nice to have them
     * separated based on a status. Maybe In the future we could introduce
     * even mor fine grained separation, but i thing that good enough for now.
     */
    fingerprint: ['{{ default }}', responseStatus]
  });
}
