import { Button as MUIButton, Typography } from '@mui/material';
import { ReactComponent as DiamondIcon } from 'assets/icons/diamond.svg';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { useSidebarStore } from 'features/pageSidebar/useSidebarStore';
import useUpdateSubscriptionModal from 'features/updateSubscriptionModal/hook/useUpdateSubscriptionModal';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled from 'styled-components';

export const SidebarItemUpgradeButton = () => {
  const isSidebarExpanded = useSidebarStore(state => state.isExpanded);
  const showUpgradeSubscriptionModal = useUpdateSubscriptionModal();

  const handleClick = () => {
    showUpgradeSubscriptionModal();
  };

  // Note: We don't use the common SidebarItem component because the button layout is much different
  if (!isSidebarExpanded) {
    return null;
  }

  return (
    <Root>
      <Button
        {...withGtmInteraction(gtmIds.sidebar.upgrade)}
        variant="contained"
        startIcon={<YellowDiamondIcon />}
        onClick={handleClick}
        size='large'
      >
        <Typography variant="subtitle2">
          <FormattedMessage id="common.upgrade" />
        </Typography>
      </Button>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Button = styled(MUIButton)`
  background-color: ${({ theme }) => theme.colors.secondaryColorMain};

  width: 100%;
  margin: ${({ theme }) => `0 ${theme.spacings.xmedium}  ${theme.spacings.medium}`};
`;

const YellowDiamondIcon = styled(DiamondIcon)`
  color: ${({ theme }) => theme.colors.accentYellowMain};
`;