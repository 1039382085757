import { Typography } from '@mui/material';
import bannerImage from 'assets/home/home-banner.png';
import { hasInstalledBrowserExtension } from 'features/browser-extension/hasInstalledBrowserExtension';
import { SupportedBrowserType } from 'features/browser-extension/SupportedBrowserType';
import { useBrowserExtensionAppStoreLink } from 'features/browser-extension/useBrowserExtensionAppStoreLink';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { BANNER_BREAKING_POINT, BANNER_HIDE_BREAKING_POINT } from 'pages/brand-hub/BannerLayout';
import { useMemo } from 'react';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled from 'styled-components';
import { resetLink } from 'styles/styledComponents/resets';
import { getBrowserType } from 'utils/getBrowserType';

export const useShouldShowBrowserExtensionHint = () =>
  useMemo(() => !hasInstalledBrowserExtension(), []);

export const HomeBrowserExtensionBanner = () => {
  const browserType = getBrowserType();
  const extensionStoreLink = useBrowserExtensionAppStoreLink(browserType as SupportedBrowserType);

  return (
    <Root
      href={extensionStoreLink}
      target="_blank"
      {...withGtmInteraction(gtmIds.home.browserExtensionHint)}
    >
      <Body>
        <HeaderText>
          <FormattedMessage
            id="home.sections.browserExtension.title"
            values={{
              highlight: (text: string) => <Highlighting>{text}</Highlighting>
            }}
          />
          <BodyText>
            <FormattedMessage id="home.sections.browserExtension.description" />
          </BodyText>
        </HeaderText>
      </Body>
      <ImageContainer>
        <Image src={bannerImage} />
      </ImageContainer>
    </Root>
  );
};

const Highlighting = styled.span`
  color: ${({ theme }) => theme.colors.white};
`;

const Root = styled.a`
  ${resetLink}
  min-height: 160px;
  max-height: 220px;
  border-radius: ${({ theme }) => theme.borderRadius.one};
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.palette.pinkRose__500main};

  display: flex;
  align-items: center;

  @media (max-width: ${BANNER_BREAKING_POINT}px) {
    gap: ${({ theme }) => theme.spacings.one};
  }

  @media (max-width: ${BANNER_HIDE_BREAKING_POINT}px) {
    display: none;
  }
`;

const Body = styled.div`
  flex: 110%;
  padding: ${({ theme }) => theme.spacings.six};

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${({ theme }) => theme.spacings.three};

  @media (max-width: ${BANNER_BREAKING_POINT}px) {
    gap: ${({ theme }) => theme.spacings.one};
  }
`;

const HeaderText = styled(Typography).attrs({
  variant: 'h4',
  color: 'black'
})`
  @media (max-width: ${BANNER_BREAKING_POINT}px) {
    ${({ theme }) => ({
      ...theme.typography.h5
    })}
  }
`;

const BodyText = styled(Typography).attrs({
  variant: 'body1',
  color: 'black'
})`
  @media (max-width: ${BANNER_BREAKING_POINT}px) {
    ${({ theme }) => ({
      ...theme.typography.body2
    })}
  }
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  /* overflow: hidden; */

  @media (max-width: 660px) {
    display: none;
  }
`;

const Image = styled.img`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  max-width: unset;
`;
