import { isText, TDescendant, TElement } from '@udecode/plate-common';
import useEditor from 'features/aiWriter/AiWriterTextEditor/hooks/useEditor';
import { useMemo } from 'react';

export default function useEditorText(joinCharacter = ' ') {
  const editor = useEditor();

  return useMemo(() => {
    // We could also use Editor.text(editor, []) but that would not include the
    // text of void nodes nor whitespaces between blocks.
    const textNodes = getTextArrayFromNodes(editor?.children ?? []);

    return textNodes.join(joinCharacter);
  }, [editor.children, joinCharacter]);
}

function getTextArrayFromNodes(nodes: TDescendant[]) {
  const nodesAsText: Array<string> = [];

  nodes.forEach(node => {
    if (isText(node)) {
      nodesAsText.push(node.text);
    }

    if (!node.children) {
      return;
    }

    nodesAsText.push(...getTextArrayFromNodes((node as TElement).children));
  });

  return nodesAsText.filter(content => content.length > 0);
}
