import { useQuery } from '@tanstack/react-query';
import { getPreferredAudience } from 'features/aiWriter/utils/getPreferredAudience';
import { getUserAudiences } from 'features/audiences/store/selectors';
import { makeCurrentUserQueryKeyPrefix } from 'features/currentUser/currentUserQueries';
import { setWordsLimitReached } from 'features/customer/store/actions';
import { useCustomerPreferences } from 'features/customerPreferences/useCustomerPreferences';
import { generateTexts } from 'features/textGenerator/actions/generateTexts';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const usePromptOptimizer = () => {
  const dispatch = useAppDispatch();
  const audiences = useAppSelector(getUserAudiences);

  const [doRequest, setDoRequest] = useState(false);
  const [prompt, setPrompt] = useState('');
  const [initialPrompt, setInitialPrompt] = useState('');
  const [keywords, setKeywords] = useState<string | undefined>();

  const { data: customerPreferences } = useCustomerPreferences();

  const {
    data: optimizedPrompt,
    isFetching,
    isError,
    remove
  } = useQuery({
    enabled: doRequest,
    retry: false,
    queryKey: [...makeCurrentUserQueryKeyPrefix(), 'promptOptimizer'],
    queryFn: async () => {
      const language = customerPreferences?.preferredLanguage ?? 'en';
      const country = customerPreferences?.preferredLanguageCountry ?? 'us';
      if (!language || !country) {
        throw new Error('Language or country not found');
      }

      const audience = getPreferredAudience({ audiences, locale: { language, country } });
      if (!audience || !audience.model_id) {
        throw new Error('Audience not found');
      }

      const result = await generateTexts(
        {
          audienceModelId: audience.model_id,
          nTimes: 1,
          nTextItems: 1,
          outputType: 'picprompt_optimizer'
        },
        { text: prompt, keywords },
        {
          onCustomError: [
            'ERROR_ALL_WORDS_USED',
            () => {
              dispatch(setWordsLimitReached(true));
            }
          ]
        }
      );

      if (result && result.outputs.length > 0) {
        return result.outputs[0].text;
      }

      // The error case is already managed at top-level
    },
    onSettled: () => {
      setDoRequest(false);
    }
  });

  const undoPromptOptimization = () => {
    setPrompt(initialPrompt);
    setInitialPrompt('');
    setDoRequest(false);
    remove();
  };

  const optimizePrompt = async (keywords?: string) => {
    if (!initialPrompt) {
      setInitialPrompt(prompt);
    }

    setKeywords(keywords);

    setDoRequest(true);
  };

  useEffect(() => {
    if (optimizedPrompt) {
      setPrompt(optimizedPrompt);
    }
  }, [optimizedPrompt]);

  return {
    optimizedPrompt,
    isFetching,
    isError,
    optimizePrompt,
    initialPrompt,
    prompt,
    setPrompt,
    setInitialPrompt,
    undoPromptOptimization
  };
};
