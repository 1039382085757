import {
  getCurrentGptModel,
  getInformationList,
  syncStore,
  useIsProjectSynced,
  useShouldSendMessageInstantly,
  useStoredActiveConversationId,
  useStoredPersonalityId
} from 'features/aiWriter/AiWriterSidebar/steps/chat/chatStore';
import { useActiveConversationQuery } from 'features/aiWriter/AiWriterSidebar/steps/chat/useActiveConversationQuery';
import { useDefaultPersonalityQuery } from 'features/aiWriter/AiWriterSidebar/steps/chat/useDefaultPersonalityQuery';
import { getActiveTab } from 'features/aiWriter/store/selectors';
import { useLayoutEffect, useMemo } from 'react';
import { InformationDto } from 'services/backofficeIntegration/http/endpoints/infomration/httpGetInformationList';
import { useAppSelector } from 'store/hooks';

export function useStoreSyncGuard(props: { projectId: string }) {
  const { projectId } = props;
  const projectSynced = useIsProjectSynced(projectId);
  const storedConversationId = useStoredActiveConversationId();
  const storedPersonalityId = useStoredPersonalityId();

  const tab = useAppSelector(getActiveTab);
  const { personalityId: documentPersonalityId } = tab.generateTextConfig;

  const storedGptModel = getCurrentGptModel();
  const activeConversationResult = useActiveConversationQuery({ projectId });
  const defaultPersonalityResult = useDefaultPersonalityQuery();
  const storedInformationList = getInformationList();
  const instantSendingEnabled = useShouldSendMessageInstantly();

  const activeConversationId = activeConversationResult.data?.conversation_id ?? null;
  const activePersonalityId = activeConversationResult.data?.personality_id ?? null;
  const defaultPersonalityId = defaultPersonalityResult.data?.id ?? null;
  const activeInformationList = activeConversationResult.data?.informations ?? [];

  let personalityId = defaultPersonalityId;
  if (activeConversationId) {
    personalityId = activePersonalityId;
  } else if (documentPersonalityId) {
    personalityId = documentPersonalityId;
  } else if (instantSendingEnabled) {
    /*
     * Here is the case when message comes from an overview page as a message draft
     */
    personalityId = storedPersonalityId;
  }

  let personalitySynced = false;
  if (activeConversationId) {
    personalitySynced = activePersonalityId === storedPersonalityId;
  } else if (documentPersonalityId) {
    personalitySynced = documentPersonalityId === storedPersonalityId;
  } else if (instantSendingEnabled) {
    personalitySynced = true;
  } else {
    personalitySynced = defaultPersonalityId === storedPersonalityId;
  }

  const activeGptModel = activeConversationId
    ? activeConversationResult.data?.current_model
    : storedGptModel;

  const gptModelSynced = activeGptModel === storedGptModel;

  const chatSuggestions = useMemo(
    () => activeConversationResult.data?.suggestions ?? [],
    [activeConversationResult.data?.suggestions]
  );

  let informationList: InformationDto[];
  let informationSynced;

  if (activeInformationList.length !== 0) {
    informationList = activeInformationList;
    informationSynced = true;
  } else if (instantSendingEnabled && storedInformationList?.length !== 0) {
    /**
     * Here is the case when message comes from an overview page as a message draft
     */
    informationList = storedInformationList;
    informationSynced = true;
  } else {
    informationList = [];
    informationSynced = true;
  }

  /**
   * We assume that that conversation is in sync while query is fetching. This
   * is important in case when first message is added to new conversation. In
   * that case instead of updating the store, we should wait for query to
   * refetch the data.
   */

  const conversationSynced =
    activeConversationResult.isFetching || activeConversationId === storedConversationId;
  const storeSynced =
    projectSynced && conversationSynced && personalitySynced && gptModelSynced && informationSynced;
  /**
   * We wait for query to finish fetching so we don't have to sync data twice:
   * 1) for project mismatch, 2) for conversation mismatch
   */
  const shouldSync =
    !storeSynced && !activeConversationResult.isFetching && !defaultPersonalityResult.isFetching;
  const isSyncing =
    activeConversationResult.isLoading ||
    /**
     * We must wait until store is in sync to display anything. Otherwise, we
     * may display data related to different conversation.
     */
    !storeSynced;

  useLayoutEffect(() => {
    if (shouldSync) {
      syncStore({
        projectId,
        conversationId: activeConversationId,
        personalityId,
        gptModel: activeGptModel,
        suggestions: chatSuggestions,
        informationList
      });
    }
  }, [
    shouldSync,
    projectId,
    activeConversationId,
    activePersonalityId,
    defaultPersonalityId,
    personalityId,
    activeGptModel,
    chatSuggestions,
    informationList
  ]);

  return {
    isSyncing,
    isError: activeConversationResult.isError || defaultPersonalityResult.isError,
    refetchFailed: () => {
      if (activeConversationResult.isError) {
        activeConversationResult.refetch();
      }
      if (defaultPersonalityResult.isError) {
        defaultPersonalityResult.refetch();
      }
    }
  };
}
