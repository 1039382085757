import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Alert } from '@mui/material';
import FormattedMessage from 'features/i18n/FormattedMessage';
import styled from 'styled-components';

export function PdfFileWarning() {
  return (
    <StyledAlert variant="filled" icon={<StyledWarningAmberIcon />}>
      <FormattedMessage id="information.modal.upload.pdf_warning" />
    </StyledAlert>
  );
}

const StyledAlert = styled(Alert)`
  && {
    background-color: ${({ theme }) => theme.colors.componentsAlertWarningBackground};
    color: ${({ theme }) => theme.colors.componentsAlertWarningColor};
  }
`;

const StyledWarningAmberIcon = styled(WarningAmberIcon)`
  color: ${({ theme }) => theme.colors.componentsAlertWarningColor};
`;
